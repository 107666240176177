import React, { Component } from 'react';
import { AbilityContext } from './ability-context.jsx';
// import { withStyles } from '@mui/styles';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withSnackbar } from 'notistack';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import '!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css'
import NewCalendarEntryDialog from './NewCalendarEntryDialog.jsx';
import EditCalendarEntryDialog from './EditCalendarEntryDialog.jsx';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import ability from './ability';


const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

// const allViews = Object.keys(Calendar.Views).map(k => Calendar.Views[k])

class BigCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {calendarEntries: props.calendar_entries, specialCalendarEntries: props.special_calendar_entries, newCalendarEntryOpen: false, editCalendarEntryOpen: false, selectedEntryId: null, user: props.user};
    // this.setState({trial: this.props.trial});
    this.form = React.createRef();
  }

  eventStyleGetter = (ce, start, end, isSelected) => {
    const type = ce.type;
    let color = "#3174AD";
      switch (type){
        case "vacation":
          color = red[200];
          lighter = red[100];
          break;
        case "absent":
          color = blue[200];
          lighter = blue[100];
          break;
        case "keep_free":
          color = green[200];
          lighter = green[100];
          break;
        case "holiday":
          color = yellow[200];
          lighter = yellow[100];
          break;
        case "school_holiday":
          color = yellow[100];
          lighter = yellow[50];
          break;
        case "planned_vacation":
          color = orange[200];
          lighter = orange[100];
          break;
        case "appointment":
          color = blue[500];
          lighter = blue[300];
          break;
      }
    var style = {
        backgroundColor: isSelected ? lighter : color,
        borderRadius: '0px',
        opacity: 1,
        color: 'black',
        border: '0px',
        display: 'block'
    };
    return {
        style: style
    };
  }

  handleNewCalendarEntryOpen = event => {
    this.setState({newCalendarEntryOpen: true});
  }

  handleNewCalendarEntryClose = event => {
    this.setState({newCalendarEntryOpen: false});
  }

  handleEditCalendarEntryOpen = (event) => {
    this.setState({editCalendarEntryOpen: true});
  }

  handleEditCalendarEntryClose = event => {
    this.setState({editCalendarEntryOpen: false});
  }

  handleRefresh = event => {
    let currentComponent = this;
    fetch('/calendars/get_calendar_entries', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.calendar_entries) {
        currentComponent.setState({ calendarEntries: data.calendar_entries });
      }
      if (data.special_calendar_entries) {
        currentComponent.setState({ specialCalendarEntries: data.special_calendar_entries });
      }
    });
  }

  handleDeleteCalendarEntry = event => {
    let currentComponent = this;
    fetch('/calendars/' + currentComponent.state.selectedEntryId + '/delete_calendar_entry', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleNewCalendarEntrySave = obj => {
    let currentComponent = this;
    fetch('/calendars/create_calendar_entry', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        calendar_entry: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleNewCalendarEntryClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditCalendarEntrySave = obj => {
    let currentComponent = this;
    fetch('/calendars/edit_calendar_entry', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        calendar_entry: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleEditCalendarEntryClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleCalendarEntriesReceived = message => {
    console.log("received Calendar Entry");
    console.log(message);
    if (message.new_calendar_entry) {
      if (message.user.id == this.state.user.id){
        const newCalendarEntries = [].concat(this.state.calendarEntries);
        newCalendarEntries.push(message.new_calendar_entry);
        this.setState({ calendarEntries: newCalendarEntries });
        this.props.enqueueSnackbar('Neuer Kalendereintrag wurde von Benutzer ' + message.user.name + ' erstellt.', {variant: 'success'});
      }
    }
    if (message.new_special_calendar_entry) {
        let newSpecialCalendarEntries = [].concat(this.state.specialCalendarEntries);
        newSpecialCalendarEntries.push(message.new_special_calendar_entry);
        this.setState({ specialCalendarEntries: newSpecialCalendarEntries });
        this.props.enqueueSnackbar('Neuer Kalendereintrag wurde von Benutzer ' + message.user.name + ' erstellt.', {variant: 'success'});
    }
    if (message.calendar_entry) {
      
      const ceInd = this.state.calendarEntries.findIndex(ce => ce.id == message.calendar_entry.id);
      const sceInd = this.state.specialCalendarEntries.findIndex(ce => ce.id == message.calendar_entry.id);
      if (ceInd != -1) {
        let newCalendarEntries = [].concat(this.state.calendarEntries);
        newCalendarEntries[ceInd] = message.calendar_entry;
        this.setState({calendarEntries: newCalendarEntries});
      }
      if (sceInd != -1) {
        let newSpecialCalendarEntries = [].concat(this.state.specialCalendarEntries);
        newSpecialCalendarEntries[sceInd] = message.calendar_entry;
        this.setState({specialCalendarEntries: newSpecialCalendarEntries});
      }
      this.props.enqueueSnackbar('Kalendereintrag wurde von Benutzer ' + message.user.name + ' editiert.', {variant: 'success'});
    }
    if (message.special_calendar_entry) {
      let newSpecialCalendarEntries = [].concat(this.state.specialCalendarEntries);
      const ceInd = newSpecialCalendarEntries.find(ce => ce.id == message.special_calendar_entry.id);
      newSpecialCalendarEntries[ceInd] = message.special_calendar_entry;
      this.setState({specialCalendarEntries: newSpecialCalendarEntries});
      this.props.enqueueSnackbar('Kalendereintrag wurde von Benutzer ' + message.user.name + ' editiert.', {variant: 'success'});
    }
    if (message.deleted_calendar_entry_id) {
      let newCalendarEntries = [].concat(this.state.calendarEntries).filter(ce => ce.id != message.deleted_calendar_entry_id);
      let newSpecialCalendarEntries = [].concat(this.state.specialCalendarEntries).filter(ce => ce.id != message.deleted_calendar_entry_id)
      if (this.state.selectedEntryId == message.deleted_calendar_entry_id) {
        this.setState({selectedEntryId: null});
      }
      this.setState({ calendarEntries: newCalendarEntries, specialCalendarEntries: newSpecialCalendarEntries });
      this.props.enqueueSnackbar('Kalendereintrag wurde von Benutzer ' + message.user.name + ' gelöscht.', {variant: 'success'});
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  handleSelect = (entry, event) => {
    this.setState({selectedEntryId: entry.id});
  }

  handleDoubleClick = (entry, event) => {
    this.setState({selectedEntryId: entry.id});
    this.handleEditCalendarEntryOpen();
  }

  render () {
    // const { classes } = this.props;
    const { calendarEntries, specialCalendarEntries } = this.state;
    let events = calendarEntries.map(ce => {
      let ret = {id: ce.id, title: ce.name, start: ce.start_time, end: ce.end_time, allDay: ce.whole_day_event, type: ce.entry_type };
      return ret
    });
    events = events.concat(specialCalendarEntries.map(ce => {
      let ret = {id: ce.id, title: ce.name, start: ce.start_time, end: ce.end_time, allDay: ce.whole_day_event, type: ce.entry_type };
      return ret
    }));
    let typeOptions = [];

    if (ability.can('create', 'special_calendar_entry')) {
      typeOptions = [{value: "vacation", label: "Urlaub/Gleittag", color: "#e33434"}, {value: "absent", label: "Dienstlich/Anderweitig Abwesend", color: "#e33434"}, {value: "keep_free", label: "Termin frei halten", color: "#e33434"}, {value: "planned_vacation", label: "Planung Urlaub", color: "#e33434"}, {value: "holiday", label: "Feiertag", color: "#e33434"}, {value: "school_holiday", label: "Schulferien", color: "#fafca9"}, {value: "appointment", label: "Termin", color: "#fafca9"}];
    } else {
      typeOptions = [{value: "vacation", label: "Urlaub/Gleittag", color: "#e33434"}, {value: "absent", label: "Dienstlich/Anderweitig Abwesend", color: "#e33434"}, {value: "planned_vacation", label: "Planung Urlaub", color: "#e33434"}, {value: "appointment", label: "Termin", color: "#fafca9"}];
    }
    const typeList = typeOptions.map(to => to.value);
    const selectedEntry = calendarEntries.concat(specialCalendarEntries).find(event => event.id == this.state.selectedEntryId);

    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <ActionCableConsumer
          channel={{channel: "CalendarEntriesChannel"}}
          onReceived={this.handleCalendarEntriesReceived}
          onConnected={this.handleConnected}
        />
        { (this.state.newCalendarEntryOpen) &&
        <NewCalendarEntryDialog
          open={this.state.newCalendarEntryOpen}
          handleClose={this.handleNewCalendarEntryClose}
          handleSave={this.handleNewCalendarEntrySave}
          typeOptions={typeOptions}
        />}
        { (this.state.editCalendarEntryOpen) &&
        <EditCalendarEntryDialog
          open={this.state.editCalendarEntryOpen}
          handleClose={this.handleEditCalendarEntryClose}
          handleSave={this.handleEditCalendarEntrySave}
          selectedEntry={selectedEntry}
          typeOptions={typeOptions}
        />}
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
            <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
              Kalender
            </Typography>
            <Typography style={{color: 'red', fontSize: '20px'}}>
              Hinweis: Termine editieren mit Doppelklick ist Aufgrund eines Bugs im Kalender momentan nicht möglich. Termin selektieren und Edit Button benutzen.
            </Typography>
            <div >
              <Tooltip title="Hinzufügen" >
                <IconButton aria-label="Add" onClick={this.handleNewCalendarEntryOpen}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              { (this.state.selectedEntryId) &&
              <Tooltip title="Editieren" >
                <IconButton aria-label="Edit" onClick={this.handleEditCalendarEntryOpen}>
                  <EditIcon />
                </IconButton>
              </Tooltip>}
              { (this.state.selectedEntryId) &&
              <Tooltip title="Löschen" >
                <IconButton aria-label="Add" onClick={this.handleDeleteCalendarEntry}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
              <Tooltip title="Refresh">
                <IconButton aria-label="Refresh" onClick={this.handleRefresh}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {/* <Calendar
            style={{ height: '80vh' }}
            localizer={localizer}
            events={events}
            step={60}
            views={["month", "agenda"]}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={this.eventStyleGetter}
            onSelectEvent={this.handleSelect}
            onDoubleClickEvent={this.test}
          /> */}
        </Grid>
      </Grid>
    )
  }
}

BigCalendar.contextType = AbilityContext;

export default withSnackbar(BigCalendar)
