import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';

import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
// import MUIDataTable from "mui-datatables";
import { withSnackbar } from 'notistack';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import NewKlifoDialog from './NewKlifoDialog.jsx';
import EditKlifoDialog from './EditKlifoDialog.jsx';
import InfoKlifoDialog from './InfoKlifoDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';
import SearchBar from './SearchBar.jsx';

const styles = {
  overrides: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableCell: {
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiPaper: {
      elevation24: {
        height: 800,
        width: 800,
      },
    },
  },
  palette: {
    primary: {main: blue[500]},
    secondary: {main: blue[800]}
  },

};

class KlifosIndex extends React.Component {
  constructor(props) {
    super(props);
    const klifoColumns = [
      {
       id: "id",
       label: "ID",
       numeric: false,
       padding: 'none',
      },
      {
        id: "topic",
        label: "Kurztitel",
        numeric: false,
        padding: 'none',
      },
      // {
      //   id: "speaker",
      //   label: "Vortrag",
      //   numeric: false,
      //   padding: 'none',
      //   // type: "stri",
      // },
    ];
    this.state = {klifos: props.klifos, selectedKlifos: [], selectedKlifoIds: [], selectedKlifoIndices: [], newKlifoOpen: false, editKlifoOpen: false, infoKlifoOpen: false, klifoColumns: klifoColumns, user: props.user};
    this.form = React.createRef();
  }

  updateKlifos = () => {
    let currentComponent = this;
    fetch('/klifos/get_klifos', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({ klifos: data.klifos });
    });
  }

  handleKlifoChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedKlifos: allObjectsSelected,
      selectedKlifoIds: allRowsSelected,
      selectedKlifoIndices: this.state.klifos.findIndex(object => object.id === allRowsSelected[0]),
    });
  };
  // TODO
  handleNewKlifoSave = object => {
    let currentComponent = this;
    this.setState({ newKlifoOpen: false});
    // TODO
    fetch('/klifo_discussions/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        klifo_discussion: object,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1){
        currentComponent.props.enqueueSnackbar('Klifo erfolgreich erstellt.', {variant: 'success'});
        // var trials = [].concat(currentComponent.state.trials);
        // trials.push(data.trial_board_submission);
        // currentComponent.setState({ trials: trials });
      } else {
        currentComponent.props.enqueueSnackbar('Klifo konnte nicht erstellt werden.', {variant: 'success'});
      }
    });
  };

  handleEditKlifoSave = (trial) => {
    let currentComponent = this;
    // console.log(site);
    this.setState({ editKlifoOpen: false});
    fetch('/klifo_discussions/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        // TODO
        ...trial,
        trial_id: trial.id,
        show: 1,
        site_status_id: 1,

        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1){
        currentComponent.props.enqueueSnackbar('KliFo erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('KliFo konnte nicht editiert werden.', {variant: 'success'});
      }
    });
    // this.updateNotes
  }

  handleNewKlifoClose = event => {
    this.setState({ newKlifoOpen: false });
  };

  handleNewKlifoOpen = event => {
    this.setState({ newKlifoOpen: true });
  };

  handleEditKlifoOpen = event => {
    // console.log("clicked edit");
    // this.setState({ editTrialBoardOpen: true});
    window.location.assign('/trial_boards/' + this.state.selectedKlifoIds[0] + '/');
  };

  handleEditKlifoClose = event => {
    this.setState({ editKlifoOpen: false});
  };

  handleInfoKlifoOpen = event => {
    // console.log("clicked edit");
    this.setState({ infoKlifoOpen: true});
  };

  handleDoubleClick = (event, obj) => {
    window.location.assign('/trial_boards/' + this.state.selectedKlifoIds[0] + '/');
  }

  handleInfoKlifoClose = event => {
    this.setState({ infoKlifoOpen: false});
  };

  handleDeleteKlifoClick = event => {
    let currentComponent = this;
    let newKlifos = [];
    newKlifos = newKlifos.concat(currentComponent.state.klifos);
    fetch('/klifo_discussions/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        klifo_ids: this.state.selectedKlifoIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      const klifos = currentComponent.state.klifos;

      for (let i = 0, l = currentComponent.state.selectedKlifoIds.length; i < l; i += 1) {
        // console.log(currentComponent.state.selectedSiteIds[i]);
        // console.log(sites);
        const currentId = currentComponent.state.selectedKlifoIds[i];
        const selectedIndex = newKlifos.findIndex(obj => obj.id === currentId);
        if (selectedIndex === -1) { //not clicked before
          console.log("index does not exist");
        } else {
          // newSites = newSites.concat(
          //   sites.slice(0, selectedIndex),
          //   sites.slice(selectedIndex + 1),
          // );
          newKlifos.splice(selectedIndex,1);
        }
      }
      // console.log(newSites);
      currentComponent.setState({ klifos: newKlifos, selectedKlifos: [], selectedKlifoIds: [] });
    });
    // this.updateSites
  };

  onSearchChange = event => {
    this.setState({searchTerm: event.target.value});
    // this.handleFilterUpdate();
  }

  handleClear = event => {
    // console.log("clear");
    let currentComponent = this;
    currentComponent.setState({selectedKlifos: [], selectedKlifoIds: [], selectedKlifoIndices: [], searchTerm: ""});
  }

  handleSearchClear = event => {
    // console.log("clear");
    let currentComponent = this;
    currentComponent.setState({searchTerm: ""});
  }

  handleRefresh = event => {
    let currentComponent = this;
    fetch('/trial_boards/refresh_klifos', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        console.log(data.klifos);
        currentComponent.setState ({klifos: data.klifos});
      }
    });
  }

  handleKlifoReceived = message => {
    console.log("received Klifo Submission");
    console.log(message);
    if (message.new_klifo_discussion) {
      let klifos = [].concat(this.state.klifos);
      klifos.push(message.new_klifo_discussion);
      this.setState({ klifos: klifos });
      this.props.enqueueSnackbar('KliFoDi wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_klifo_discussion.topic, {variant: 'success'});
    }
    if (message.klifo) {
      let klifos = [].concat(this.state.klifos);
      const ind = klifos.findIndex(klifo => klifo.id == message.klifo.id);
      klifos[ind] = message.klifo;
      this.setState({ klifos: klifos });
      this.props.enqueueSnackbar('KliFoDi wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.klifo.topic, {variant: 'success'});
    }
    if (message.deleted_klifos) {
      let klifos = [].concat(this.state.klifos);
      let klifoTopics = [];
      message.deleted_klifos.forEach(function (klifo_id, index) {
        const ind = klifos.findIndex(t => t.id == klifo_id);
        klifoTopics.push(klifos[ind].topic);
        delete klifos[ind];
      });
      // delete trialBoards[message.deleted_trial_board_ids];
      this.setState({ klifos: klifos });
      this.props.enqueueSnackbar('KliFoDi(s) wurde(n) von Benutzer ' + message.username + ' gelöscht: ' + klifoTopics, {variant: 'success'});
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    let filteredKlifos = (this.state.searchTerm && this.state.searchTerm.length > 0) ? this.state.klifos.filter(klifo => klifo.topic.toLowerCase().includes(this.state.searchTerm.toLowerCase())) : this.state.klifos;
    return (
      // <ThemeProvider theme={theme}>
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "KlifoDiscussionsChannel"}}
            onReceived={this.handleKlifoReceived}
            onConnected={this.handleConnected}
          />
          <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" name="position" row>
              {/* <SearchBar
                onChange={this.onSearchChange}
              /> */}
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
                <SearchBar
                  onChange={this.onSearchChange}
                  handleClear={this.handleSearchClear}
                  searchTerm={this.state.searchTerm}
                />
                {/* <FormControlLabel
                  value={this.state.multipleSelectMode}
                  control={<Switch color="primary" />}
                  label="Mehrfachauswahl-Modus"
                  labelPlacement="end"
                  onChange={this.handleMultipleSelectChange}
                /> */}
                <Tooltip title="Auswahl löschen">
                  <IconButton aria-label="Auswahl löschen" onClick={this.handleClear}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Refresh">
                  <IconButton aria-label="Refresh" onClick={this.handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <NewKlifoDialog
                open={this.state.newKlifoOpen}
                handleClose={this.handleNewKlifoClose}
                handleSave={this.handleNewKlifoSave}
              />
              <EnhancedTable
                title={"KliFoDis"}
                data={filteredKlifos}
                // singleSelect={true}
                selectedIds={this.state.selectedKlifoIds}
                selectedObjects={this.state.selectedKlifos}
                columns={this.state.klifoColumns}
                handleAddClick={this.handleNewKlifoOpen}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleKlifoChange(rowData, allRowsSelected, allObjectsSelected)}
                // handleEditClick={this.handleEditKlifoOpen}
                handleDeleteClick={this.handleDeleteKlifoClick}
                // textToCopy={copyText}
                // onDoubleClick={this.handleDoubleClick}
                rowsPerPage={30}
                order="desc"
                orderBy="short"
                expanded
                selectable
                autoFill
                controller="klifo_discussions"
                objectType="KlifoDiscussion"
              />
            </Grid>
          </Grid>
        </Grid>
      // </ThemeProvider>
    )
  }
}

export default withStyles(styles)(withTheme(withSnackbar(KlifosIndex)));
