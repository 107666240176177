import React from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import {withStyles} from '@mui/styles'
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';

import logo from '../../assets/images/Nct_01.svg.png';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const styles = {}

class PasswordReset extends React.Component {

  constructor(props) {
    super(props);
    this.state = {email: props.email, id: props.id, password: null, passwordRepeat: null};
    this.form = React.createRef();
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleLogin = event => {
    event.preventDefault();
    const currentComponent = this;
    fetch('/login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        session: {
          email: this.state.email,
          id: this.state.id,
          password: this.state.password
        },
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        window.location.assign('/');
        // currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Erfolgreicher Login.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Benutzer wurde noch nicht aktiviert. Bitte sehen Sie in ihrem E-Mail Postfach nach.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Inkorrekte Email/Passwort Kombination.', {variant: 'error'});
      }
    });
  }

  handleReset = event => {
    // event.preventDefault();
    const currentComponent = this;
    fetch('/password_resets/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        user: {
          email: this.state.email,
          password: this.state.password
        },
        id: this.state.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // window.location.assign('/');
        // currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Passwort Erfolgreich geändert.', {variant: 'success'});
        // await new Promise(r => setTimeout(r, 2000));
        window.location.assign("/")
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Benutzer wurde nicht gefunden.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Passwortänderung fehlgeschlagen. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  render(props) {
    const saveable = (this.state.password == this.state.passwordRepeat);
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6} style={{padding: 10}} justify="center" >
          <Box display="flex" flexDirection="row" justifyContent="center" flexShrink={true} flexWrap="nowrap">
            {/* <img src={logo} alt="NCT Studienzentrale" style={{width:90, height:35}}/> */}
            <Typography variant="h5" color="inherit" noWrap style={{marginLeft: 5}}>
              Bitte geben Sie ein neues Passwort an
            </Typography>
          </Box>
          <form onSubmit={() => this.handleReset(this.state)}>
            <FormControl fullWidth>
              <TextField
                id="password"
                label="Passwort"
                type="password"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange}
                style={{paddingBottom: '10px'}}
                // disabled={props.statusDateNotEditable}
              />
              <TextField
                id="passwordRepeat"
                label="Passwort (Wdh.)"
                type="password"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange}
                error={this.state.password != this.state.passwordRepeat}
                // disabled={props.statusDateNotEditable}
                style={{paddingBottom: '10px'}}
              />
            </FormControl>
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="wrap">
              <Button
                // type="submit"
                disabled={!saveable}
                onClick={() => this.handleReset(this.state)}
                variant="contained"
                color="primary">
                  Passwort setzen
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(withTheme(withSnackbar(PasswordReset)));