import React from 'react';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CreatableSelect from 'react-select/creatable';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import StarBorder from '@mui/icons-material/StarBorder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import DepartmentSelector from './DepartmentSelector.jsx';
import AddDepartmentGlobalToSiteDialog from './AddDepartmentGlobalToSiteDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';


const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class DocumentsOverviewDialog extends React.Component {
  constructor(props) {
    super(props);
    let versionsOpen = {};
    if (this.props.documents) {
      const docsNames = this.props.documents.map(document => document.name);
      for (var name in docsNames) {
        versionsOpen[name] = false;
      }
    }
    this.state = {selectedDepartment: this.props.selectedDepartment, addDepartmentGlobalOpen: false, addDepartmentGlobalName: "", inputValue: this.props.inputValue, open: this.props.open, versionsOpen: versionsOpen}
    this.form = React.createRef();
  }

  handleSave = ids => {
    // let currentComponent = this;
    // this.setState({ addDepartmentOpen: false});
    // fetch('/trials/'+this.state.trialId+'/site_selection/add_department', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'X-Transaction': 'POST Example',
    //     'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    //   },
    //   body: JSON.stringify({
    //     department_id: ids.department_id,
    //     site_id: ids.site_id,
    //   }),
    //   credentials: 'include',
    // })
    // .then(function(response){return response.json();})
    // .then(function(data){
    //   if (data.response == 1) {
    //     // console.log("test close");
    //     // currentComponent.setState({open: false});
    //     currentComponent.props.handleAddDepartment(data.department, data.assoc);
    //     currentComponent.props.handleClose();
    //   } else if (data.response == 0) {
    //     currentComponent.props.enqueueSnackbar('Es ist ein Fehler beim hinzufügen aufgetreten.', {variant: 'error'});
    //   } else if (data.response == 2) {
    //     currentComponent.props.enqueueSnackbar('Die Abteilung existiert bereits in diesem Zentrum.', {variant: 'error'});
    //   }
    // });
  }

  // handleClose = event => {
  //   this.props.handleClose;
  // }

  handleOpen = event => {
    this.props.handleOpen;
  }

  handleClick = name => {
    // setOpen(!open);
    let newVersionsOpen = this.state.versionsOpen;
    if (this.state.versionsOpen[name]) {
      newVersionsOpen[name] = false;
    } else {
      newVersionsOpen[name] = true;
    }
    this.setState({versionsOpen: newVersionsOpen})
  };

  formatCreate = (inputValue) => {
    return (<p> {inputValue} im gesamten Adressbuch suchen</p>);
  };

  handleNewClose = event => {
    this.setState({newOpen: false});
  }

  handleNewOpen = event => {
    this.setState({newOpen: true});
  }

  handleEditOpen = name => event => {
    this.setState({editOpen: true, selectedMilestone: name});
  }

  handleEditClose = event => {
    this.setState({editOpen: false});
  }

  handleNewSave = vals => event => {
    this.props.handleNewSave(vals);
    this.setState({newOpen: false, open: true});
  }

  handleEditSave = vals => event => {
    this.props.handleEditSave(vals);
    this.setState({editOpen: false, open: true});
  }

  render(props) {
    // let list = [];
    // if (this.props.documents) {
    //   list = this.props.documents.map(document => {
    //     let versions = [];
    //     for (var version in document.versions) {
    //       const v = version;
    //       versions.push(
    //         <Collapse in={this.state.versionsOpen[document.name]} timeout="auto" unmountOnExit>
    //           <List component="div" disablePadding>
    //             <ListItem button style={{paddingLeft: '30px'}}>
    //               <ListItemIcon>
    //                 <ArrowRightIcon />
    //               </ListItemIcon>
    //               <ListItemText primary={v + ": " + document.versions[v]["version_name"]} />
    //               <ListItemText primary={"Gültig ab: " + document.versions[v]["date_start"]} />
    //               <ListItemSecondaryAction>
    //                 <Tooltip title="Diese Version editieren">
    //                   <IconButton aria-label="edit" onClick={() => this.props.handleEditDocumentVersionClick(document.name, v)}>
    //                     <EditIcon />
    //                   </IconButton>
    //                 </Tooltip>
    //                 <Tooltip title="Diese Version entfernen">
    //                   <IconButton aria-label="delete" onClick={() => this.props.handleDeleteDocumentVersionClick(document.name, v)}>
    //                     <DeleteIcon />
    //                   </IconButton>
    //                 </Tooltip>
    //               </ListItemSecondaryAction>
    //             </ListItem>
    //           </List>
    //         </Collapse>
    //       )
    //     }
    //     return (
    //       <>

    //           <ListItem button onClick={() => this.handleClick(document.name)}>
    //             <ListItemIcon>
    //               {this.state.versionsOpen[document.name] ? <ExpandLess/> : <ExpandMore/>}
    //             </ListItemIcon>
    //               <ListItemText primary={document.name} />
    //               <ListItemText primary={"(" + document.roles + ")"} />
    //               <ListItemSecondaryAction>
    //                 <Tooltip title="Neue Version dieses Dokuments anlegen">
    //                   <IconButton edge="left" aria-label="Add" id={document.name + "_newVersion"} name={document.name} onClick={() => this.props.handleNewDocumentVersionClick(document.name)}>
    //                     <AddIcon />
    //                   </IconButton>
    //                 </Tooltip>
    //                 <Tooltip title="Dieses Dokument editieren">
    //                   <IconButton aria-label="edit" onClick={() => this.props.handleEditDocumentClick(document.name)}>
    //                     <EditIcon />
    //                   </IconButton>
    //                 </Tooltip>
    //                 <Tooltip title="Dieses Dokument entfernen">
    //                   <IconButton aria-label="delete" onClick={() => this.props.handleDeleteDocumentClick(document.name)}>
    //                     <DeleteIcon />
    //                   </IconButton>
    //                 </Tooltip>
    //               </ListItemSecondaryAction>

    //           </ListItem>

    //         {versions}
    //       </>
    //     )
    //   })
    // }
    // console.log(this.props.columns);
    return (
      <>
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={true}
        // maxWidth="md"
        // fullWidth
        // PaperProps={{style: {height: '900px'}}}
      >
        {/* <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
          <DialogTitle id="form-dialog-title">Dokumentenübersicht</DialogTitle>
          <Tooltip title="Neues Dokument für die Studie anlegen">
            <IconButton aria-label="Add" onClick={this.props.handleNewDocumentClick}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box> */}
        <DialogContent>
          <EnhancedTable
            title={"Dokumentenübersicht"}
            data={this.props.data}
            // singleSelect={true}
            selectedIds={[]}
            // selectedObjects={this.state.selectedNotes}
            columns={this.props.columns}
            // handleAddClick={this.handleNewDocumentClick}
            handleListClick={this.props.handleListClick}
            // handleOverviewClick={this.props.handleDocumentsOverviewOpen}
            handleLockChange={this.props.handleLockChange}
            unlocked={this.props.unlocked}
            onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.props.handleDocumentTrackingChange(rowData, allRowsSelected, allObjectsSelected)}
            handleUpdateDocuments={(values) => this.props.handleUpdateDocuments(values)}
            // handleLockChange={this.props.handleLockChange}
            rowsPerPage={this.props.rowsPerPage}
            handleChange={this.props.handleDocumentsChange}
            collapseEmpty
            expanded={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
  }
}

DocumentsOverviewDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(DocumentsOverviewDialog));
