import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcbopProOdiFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
    <>
      <Typography variant="h6">QLQ C30</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
          <TextField
          margin="dense"
          id="odi_01"
          label="odi_01"
          value={obj.odi_01}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_02"
          label="odi_02"
          value={obj.odi_02}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_03"
          label="odi_03"
          value={obj.odi_03}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_04"
          label="odi_04"
          value={obj.odi_04}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_05"
          label="odi_05"
          value={obj.odi_05}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_06"
          label="odi_06"
          value={obj.odi_06}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_07"
          label="odi_07"
          value={obj.odi_07}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_08"
          label="odi_08"
          value={obj.odi_08}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_09"
          label="odi_09"
          value={obj.odi_09}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="odi_10"
          label="odi_10"
          value={obj.odi_10}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        /> 
      </Box>
    </>
  );
}
