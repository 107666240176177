import React from 'react';
import Select from 'react-select';
import chroma from 'chroma-js';
import TextField from './TextField.jsx';
import Skeleton from '@mui/material/Skeleton';


const selectStyles = { menu: { zIndex: 999 } };

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', disabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

const customControlStyles = {
    width: "200px",
};

const testselected = { value: '9', label: 'test1 (ID: 9)' }

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

export default class TrialSelect extends React.Component {

  constructor(props) {
    super(props);
    let currentComponent = this;
    // this.trials= this.props.trials;
    // this.state: {value: this.props.selectedTrial};
    // this.selectedTrial= this.props.selectedTrial;
    // fetch('/trials/get_trials')
    // .then(function(response){return response.json();})
    // .then(function(data){
    //   currentComponent.setState({ trials: data.trials, selectedTrial: currentComponent.props.selectedTrial });
    // });
    this.state = {selectedTrial: {}, trialsList: []};
    // this.onChange = this.onChange.bind(this);
    this.form = React.createRef();
    // this.state = this.props.selectedTrial;
  }

  onChange = (event) => {
    console.log("onChange")
    console.log(event);
    this.setState({selectedTrial: event});
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
    if (!this.props.noSubmit) {
      this.onSubmit(event);
    }
    // this.onSubmit(event)
    // this.form.submit()
    // this.refs.parentForm.submit();
    // { this.form.submit()}
    // handleSubmit(event);
  }
  // componentDidUpdate() {
  //   this.onSubmit()
  // }

  componentDidMount = (props) => {
    let currentComponent = this;
    if (!this.props.trials) {
      currentComponent.updateTrials();
    }
  }

  updateTrials = (props) => {
    let currentComponent = this;
    let trialsList = [];
    fetch('/trials/index_shorts', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // let trialsList = [];
      // console.log(data); 
      for (var i = 0; i < data.trial_shorts.length; i++) {
        trialsList.push({value: data.trial_shorts[i][0], label: data.trial_shorts[i][1] + "(" + data.trial_shorts[i][0] + ")"});
        if (currentComponent.props.selectedTrialId == data.trial_shorts[i][0]) {
          currentComponent.setState({selectedTrial: {value: data.trial_shorts[i][0], label: data.trial_shorts[i][1] + "(" + data.trial_shorts[i][0] + ")", color: '#36B37E' }});
        }
      }
      currentComponent.setState({ trials: data.trial_shorts, trialsList: trialsList });
      // currentComponent.setState({ trials: data.trial_shorts });
    });
  }

  onSubmit = (event) => {
    console.log(event, this.props.action);
    if ((this.props.action != 'show') && (this.props.action != undefined) && (this.props.action != 'index')) {
      window.location.assign('/' + this.props.controller + '/' + event.target.value + '/' + this.props.action);
    } else if (this.props.onSubmit) {
      this.props.onSubmit();
    }
    else {
      window.location.assign('/' + this.props.controller + '/' + event.target.value);
    }
    // <Redirect to='/target' />
    // this.props.history.push(`/target`)
    // const data = new FormData(this.state.value);
    // this.form.current.submit(data);
    // event.preventDefault();
  }

  render() {
    // const trialsList = [];
    // for (var i = 0; i < this.state.trials.length; i++) {
    //   trialsList.push({value: this.state.trials[i].id.toString(), label: this.state.trials[i].short + "(" + this.state.trials[i].id + ")"});
    // }
    console.log(this.state.selectedTrial);
    if (this.state.trials && this.state.trials.length > 0) {
      return (
          <div style={{width: 200, zIndex: 999}}>
            <form ref={this.form} >
            <TextField
              margin="dense"
              id="trial_id"
              label="Studie"
              value={this.props.selectedTrialId}
              fullWidth
              variant="outlined"
              style={{zIndex: 989}}
              handleChange={this.onChange}
              // required={props.requiredFields.includes("short")}
              InputProps={{
                readOnly: this.props.disabled,
              }}
              type="select"
              options={this.state.trialsList}
              size="small"
              data-cy="trialSelect"
            />
            {/* <input id="id" ref="inputField" type="hidden"  />
              <Select
                value={ this.state.selectedTrial }
                // defaultValue={"Bitte wählen"}
                onChange={this.onChange}
                options={this.state.trialsList}
                style={selectStyles}
                // styles={colourStyles}
                // menuIsOpen={true}
                // className={"bg-warning"}
                InputProps={{
                  readOnly: this.props.disabled,
                }}
                // disabled={this.props.disabled}
              /> */}
            </form>
          </div>
          
        
        // <div> trials </div>
      );
    } else {
      return (
        <Skeleton variant="rectangular" width={200} height={40} />
      )
    }
  }
}
