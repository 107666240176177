import React, {useState} from 'react';
// import classNames from 'classnames';
import PropTypes, { object } from 'prop-types';
import { useTheme } from '@mui/styles';
// import { makeStyles, createStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import NativeSelect from '@mui/material/NativeSelect';
import Skeleton from '@mui/material/Skeleton';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DuplicateIcon from '@mui/icons-material/FilterNone';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import BallotIcon from '@mui/icons-material/Ballot';
import EmailIcon from '@mui/icons-material/Email';
import LockOpen from '@mui/icons-material/LockOpen';
import Lock from '@mui/icons-material/Lock';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from "@mui/icons-material/Add";
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCardIcon from '@mui/icons-material/AddCard';
import ListIcon from "@mui/icons-material/List";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import InformationIcon from "@mui/icons-material/Info";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import StarIcon from '@mui/icons-material/Star';
import UpIcon from '@mui/icons-material/ArrowDropUp';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import RightIcon from '@mui/icons-material/ArrowRight';
import LeftIcon from '@mui/icons-material/ArrowLeft';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LoopIcon from '@mui/icons-material/Loop';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import Moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import TimelineIcon from '@mui/icons-material/Timeline';
import BiotechIcon from '@mui/icons-material/Biotech';
import PatternIcon from '@mui/icons-material/Pattern';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import MedicationIcon from '@mui/icons-material/Medication';
import HealingIcon from '@mui/icons-material/Healing';
import SpokeIcon from '@mui/icons-material/Spoke';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, indigo, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FilterPopper from './FilterPopper.jsx';
import TextField from './TextField.jsx';
import { Can } from './Can';
import { getPaginationItemUtilityClass } from '@mui/material';
import { FileUpload, ThirtyFpsSelect } from '@mui/icons-material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Link from '@mui/material/Link';import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

// const styles = {
//   tableCheckbox: {
//     padding: 0,

//   },
//   root: {
//     width: '100%',
//     // marginTop: theme.spacing.unit * 3,
//     MuiIconButton: {
//       root: {
//         padding: '2',
//       },
//     },
//   },
//   // table: {
//   //   minWidth: 1020,
//   // },
//   tableWrapper: {
//     overflowX: 'auto',
//   },
//   tableRow: {
//     borderBottomColor: 'black',
//     borderTopColor: 'black',
//   },
//   tableCell: {
//     borderBottomColor: 'black',
//     borderTopColor: 'black',
//   },
//   tableHeaderCheckbox: {
//     padding: '2',
//   },
//   pagination: {
//     toolbar: {
//       minHeight: 25,
//     },
//   },
//   shard: {
//     backgroundColor: blue[200],
//     marginLeft: '10px',
//     paddingLeft: '5px',
//     paddingRight: '5px',
//     paddingTop: '2px',
//     paddingBottom: '2px',
//   },
//   primaryshard: {
//     backgroundColor: blue[500],
//     marginLeft: '10px',
//     paddingLeft: '5px',
//     paddingRight: '5px',
//     paddingTop: '2px',
//     paddingBottom: '2px',
//   }
//   // palette: {
//   //   primary: {main: blue[500]},
//   //   secondary: {main: blue[800]}
//   // },
// }

function desc(a, b, orderBy) {
  // console.log(a);
  // console.log(b);
  // console.log(orderBy);
  if (b[orderBy] == null || b[orderBy] == undefined) {
    return 1
  }
  if (a[orderBy] == null || a[orderBy] == undefined) {
    return -1
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  // console.log("stableSort");
  // console.log(array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class ToolbarIcon extends React.Component {

  render(props) {
    // const { classes } = this.props;
    if (this.props.rend){
      return (
          <Tooltip title={this.props.title}>
            <IconButton onClick={this.props.handleAddClick}>
              {this.props.content}
            </IconButton>
          </Tooltip>
      );
    } else {
      return null
    }
  }
}

const tableHeadStyles = theme => ({
  checkbox: {
    padding: "0px",
  },
  head: {
    paddingBottom: "9px",
  },
});

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, columns, selectable, uploadDates } = this.props;
    const transTable = {
      pseudonym: "sarc_patients",
      master_pseudonym: "master_pseudony",
      heroes_aya: "heroes_aya",
      sarc_diagnoses_count: "sarc_diagnoses",
      sarc_p_tnm_inquiries_count: "sarc_p_tnm_inquiries",
      sarc_stadia_count: "sarc_stadia",
      sarc_operations_count: "sarc_operations",
      sarc_systemic_therapies_count: "sarc_systemic_therapies",
      sarc_miscellaneous_therapies_count: "sarc_miscellaneous_therapies",
      sarc_radiotherapies_count: "sarc_radiotherapies",
      sarc_nuclear_therapies_count: "sarc_nuclear_therapies",
      sarc_progressions_count: "sarc_progressions",
      vital_statuses_count: "vital_statuses",
      sarc_blood_samples_count: "sarc_blood_samples",
      sarc_tissue_samples_count: "sarc_tissue_samples",
      has_socio: "sarc_socios",
      pro_counter_sum: "sarcbop_pro_eortcs",
      has_gene_checklist: "sarcbop_gene_checklists",
      study_identifiers: "study_identifiers",
      sarc_bado_questionnaires_count: "sarc_bado_questionnaires",

    }
    // console.log(uploadDates);
    var uploadDatesColumns = columns.map(column => {
      return(
        <TableCell
          key={column.id}
          // align={row.numeric ? 'right' : 'left'}
          padding='none'
          // sortDirection={orderBy === column.id ? order : false}
        >
          <Tooltip
            // title="Sort"
            placement={'bottom-end'}
            enterDelay={300}
            style={{padding: '5px'}}
          >
              {uploadDates ? uploadDates[transTable[column.id]] : null}
              
          </Tooltip>
        </TableCell>
      )
    })
    console.log(uploadDates);
    return (
      <TableHead>
        <TableRow>
          { (selectable) &&
            <TableCell padding="checkbox">

            </TableCell>
          }
          {uploadDatesColumns}
        </TableRow>
        <TableRow>
          { (selectable) &&
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                color="primary"
                // className={classes.checkbox}
              />
            </TableCell>
          }
          {columns.map(
            row =>
              { if (row.type != "hidden") return (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                // padding={row.disablePadding ? 'none' : 'default'}
                padding='none'
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                    // className={classes.head}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )},
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.isRequired,
};

EnhancedTableHead = withTheme(EnhancedTableHead);

// const toolbarStyles = makeStyles((theme) => createStyles({
//   root: {
//     paddingRight: theme.spacing,
//     display: "flex",
//     // flex: '0 1 auto',
//     // flex: 1,
//     flexBasis: '100%',
//     flexDirection: 'row',
//     flexGrow: 0,
//     flexShrink: 1,
//     flexWrap: 'nowrap',
//     justifyContent: 'space-between',
//     // alignItems: 'flex-start',
//   },
//   highlight:
//     theme.palette.type === 'light'
//       ? {
//           color: theme.palette.primary.light,
//           backgroundColor: blueGray[200],
//         }
//       : {
//           color: theme.palette.primary.main,
//           backgroundColor: theme.palette.primary.main,
//         },
//   spacer: {
//     flex: '1 1 100%',
//   },
//   actions: {
//     color: theme.palette.primary.main,
//     align: "center",
//   },
//   title: {
//     flex: '0 0 auto',
//   },
// }));

let EnhancedTableToolbar = props => {
  // console.log(props);
  // const [expanded, setExpanded] = useState((props.expanded != null) ? props.expanded : true);
  const { numSelected, selectedObjects, title, handleAddClick, handleAddClickHeroesAya, handleNewClick, handleInfoClick, handleEditClick, handleRemoveClick, handleDeleteClick,handleFilterClick, handleDownloadClick, alwaysDownloadable, personTable, handleMailTo, data, allData, columns, handleListClick, handleOverviewClick, unlocked, handleLockChange, handleUpClick, handleUploadClick, handleDownClick, handleRightClick, handleLeftClick, handleParentSelectClick, handleDuplicateClick, handleDoneClick, multipleSelectMode, handleMultipleSelectChange, objectName, expanded, handleExpandChange, handleNewObjectClick } = props;
  // console.log(handleAddClick);
  const theme = useTheme();
  // const classes = toolbarStyles(props.theme);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleFilterOpen(event) {
    // console.log("handleFilterOpen");
    setFilterOpen(true);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }
  function handleFilterToggle(event) {
    // console.log("handleFilterToggle");
    setFilterOpen(prevFilterOpen => !prevFilterOpen);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    handleFilterClick(!filterOpen);
  }

  function handleFilterClose(event) {
    setFilterOpen(false);
  }
  let addresses_string = "mailto:";
  if (handleMailTo) {
    const addresses = selectedObjects.map(object => object.email);
    addresses.forEach(address => {
      addresses_string += address + ";";
    })
  }
  var abilityType = null;
  var abilityAction = null;
  if (props.objectType) {
    abilityType = props.objectType
  } else if (props.superType) {
    abilityType = props.superType
    abilityAction = "update"
  }
  return (
    <Toolbar
      variant='dense'
      style={{paddingRight: '10px', paddingLeft: '10px', backgroundColor: (numSelected > 0) ? theme.palette.primary.main : theme.palette.neutral.main}}
      data-cy={"table_toolbar_" + title}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" style={{width: '100%'}}>
        <div>
          {numSelected > 0 ? (
            <Box display="flex" flexDirection="row" justifyContent="left" flexShrink={true} flexWrap="nowrap">
              <Typography variant="h6" id="tableTitle" onClick={() => props.handleExpandChange(!expanded)} textColor="neutral">
                {expanded ? <ExpandMore/> : <ExpandLess/>} {title}
              </Typography>
              <Typography variant="h6" id="tableTitle" onClick={() => props.handleExpandChange(!expanded)}>
                {"(" + numSelected.toString() + ")"}
              </Typography>
            </Box>
          ) : (
            <Box display="flex" flexDirection="row" justifyContent="left" flexShrink={true} flexWrap="nowrap">
              <Typography variant="h6" id="tableTitle" onClick={() => props.handleExpandChange(!expanded)}>
                {expanded ? <ExpandMore/> : <ExpandLess/>} {title}
              </Typography>
              <Typography variant="h6" id="tableTitle" onClick={() => props.handleExpandChange(!expanded)}>
                
              </Typography>
            </Box>
          )}
        </div>
        <div >
          <>
            {(handleMultipleSelectChange) ? (
              <FormControlLabel
                value={multipleSelectMode}
                control={<Switch checked={multipleSelectMode} color="primary" />}
                label={<BallotIcon />}
                labelPlacement="end"
                onChange={handleMultipleSelectChange}
                style={{marginLeft: '5px', marginRight: '5px'}}
              />
            ) : (
              null
            )}
            {(numSelected == 1 && handleInfoClick) ? (
              <Tooltip title="Information">
                <IconButton aria-label="Info" onClick={handleInfoClick} >
                  <InformationIcon />
                </IconButton>
              </Tooltip>
            ): (
              <>
              {(handleInfoClick) ? 
                <Tooltip title="Information">
                  <IconButton aria-label="Info" onClick={handleInfoClick} disabled>
                    <InformationIcon />
                  </IconButton>
                </Tooltip>
              
                : null
              }
              </>
            )}
            {(numSelected == 1 && handleEditClick) ? (
              <Can I={abilityAction || "update"} a={abilityType}>
                <Tooltip title="Editieren">
                  <IconButton aria-label="Edit" onClick={handleEditClick} >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            ) : (
              <>
              {(handleEditClick) ? 
                <Can I={abilityAction || "update"} a={abilityType}>
                  <Tooltip title="Editieren">
                    <IconButton aria-label="Edit" onClick={handleEditClick} disabled>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
              
                : null
              }
              </>
            )}
            {(numSelected > 0 && handleParentSelectClick) ? (
              <Can I={abilityAction || "update"} a={abilityType}>
                <Tooltip title="Übergeordnete Elemente selektieren / Suche löschen">
                  <IconButton aria-label="up" onClick={handleParentSelectClick} >
                    <AccountTreeIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            ) : (
              <>
              {(handleParentSelectClick) ? 
                <Can I={abilityAction || "update"} a={abilityType}>
                  <Tooltip title="Übergeordnete Elemente selektieren / Suche löschen">
                    <IconButton aria-label="up" onClick={handleParentSelectClick}  disabled>
                      <AccountTreeIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
              
                : null
              }
              </>
            )}
            {(numSelected == 1 && handleUpClick) ? (
              <Can I={abilityAction || "update"} a={abilityType}>
                <Tooltip title="hoch rücken">
                  <IconButton aria-label="up" onClick={handleUpClick} >
                    <UpIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            ) : (
              <>
              {(handleUpClick) ? 
                <Can I={abilityAction || "update"} a={abilityType}>
                  <Tooltip title="hoch rücken">
                    <IconButton aria-label="up" onClick={handleUpClick} >
                      <UpIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
              
                : null
              }
              </>
            )}
            {(numSelected == 1 && handleDownClick) ? (
              <Can I={abilityAction || "update"} a={abilityType}>
                <Tooltip title="runter rücken">
                  <IconButton aria-label="up" onClick={handleDownClick} >
                    <DownIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            ) : (
              <>
              {(handleDownClick) ? 
                <Can I={abilityAction || "update"} a={abilityType}>
                  <Tooltip title="runter rücken">
                    <IconButton aria-label="up" onClick={handleDownClick} disabled>
                      <DownIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
              
                : null
              }
              </>
            )}
            {(numSelected == 1 && handleRightClick) ? (
              <Can I={abilityAction || "update"} a={abilityType}>
                <Tooltip title="einrücken">
                  <IconButton aria-label="up" onClick={handleRightClick} >
                    <RightIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            ) : (
              <>
              {(handleRightClick) ? 
                <Can I={abilityAction || "update"} a={abilityType}>
                  <Tooltip title="einrücken">
                    <IconButton aria-label="up" onClick={handleRightClick} disabled>
                      <RightIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
              
                : null
              }
              </>
            )}
            {(numSelected == 1 && handleLeftClick) ? (
              <Can I={abilityAction || "update"} a={abilityType}>
                <Tooltip title="heraus rücken">
                  <IconButton aria-label="up" onClick={handleLeftClick} >
                    <LeftIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            ) : (
              <>
              {(handleLeftClick) ? 
                <Can I={abilityAction || "update"} a={abilityType}>
                <Tooltip title="heraus rücken">
                  <IconButton aria-label="up" onClick={handleLeftClick} disabled>
                    <LeftIcon />
                  </IconButton>
                </Tooltip>
              </Can>
              
                : null
              }
              </>
            )}
            {(numSelected > 0 && handleRemoveClick) ? (
              <Can I={abilityAction || "update"} a={abilityType}>
                <Tooltip title="Entfernen">
                  <IconButton aria-label="Delete" >
                    <RemoveIcon onClick={handleRemoveClick}/>
                  </IconButton>
                </Tooltip>
              </Can>
            ) : (
              <>
              {(handleRemoveClick) ? 
                <Can I={abilityAction || "update"} a={abilityType}>
                  <Tooltip title="Entfernen">
                    <IconButton aria-label="Delete" disabled>
                      <RemoveIcon onClick={handleRemoveClick}/>
                    </IconButton>
                  </Tooltip>
                </Can>
              
                : null
              }
              </>
            )}
            {(numSelected > 0 && handleDoneClick) ? (
                <Tooltip title="Als erledigt markieren">
                  <IconButton aria-label="Delete" onClick={handleDoneClick} >
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
            ) : (
              <>
              {(handleDoneClick) ? 
                <Tooltip title="Als erledigt markieren">
                  <IconButton aria-label="Delete" onClick={handleDoneClick} disabled>
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
              
                : null
              }
              </>
            )}
            {/* {(numSelected > 0 && handleUploadClick) ? (
                <Tooltip title="Datei hochladen">
                  <IconButton aria-label="Upload" onClick={handleUploadClick} >
                    <FileUpload />
                  </IconButton>
                </Tooltip>
            ) : (
              <>
              {(handleUploadClick) ? 
                <Tooltip title="Datei hochladen">
                  <IconButton aria-label="Upload" onClick={handleUploadClick} disabled>
                    <FileUpload />
                  </IconButton>
                </Tooltip>
              
                : null
              }
              </>
            )} */}
            {(handleUploadClick) ?
              (
                <Can I={abilityAction || "create"} a={abilityType}>
                  <Tooltip title="Datei hochladen">
                    <IconButton aria-label="Upload" onClick={handleUploadClick} data-cy="file_upload">
                      <FileUpload />
                    </IconButton>
                  </Tooltip>
                </Can>
              ) : (
                null
            )}
            {(numSelected > 0 && handleDuplicateClick) ? (
                <Tooltip title="Duplizieren">
                  <IconButton aria-label="Delete" onClick={handleDuplicateClick} >
                    <DuplicateIcon />
                  </IconButton>
                </Tooltip>
            ) : (
              <>
              {(handleDuplicateClick) ? 
                <Tooltip title="Duplizieren">
                  <IconButton aria-label="Delete" onClick={handleDuplicateClick} disabled>
                    <DuplicateIcon />
                  </IconButton>
                </Tooltip>
              
                : null
              }
              </>
            )}
            {(numSelected > 0 && handleDeleteClick) ? (
              <Can I={abilityAction || "delete"} a={abilityType}>
                <Tooltip title="Löschen">
                  <IconButton aria-label="Delete" onClick={handleDeleteClick} data-cy={title + "deletebutton"} >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            ) : (
              <>
              {(handleDeleteClick) ? 
                <Can I={abilityAction || "delete"} a={abilityType}>
                    <IconButton aria-label="Delete" onClick={handleDeleteClick} data-cy={title + "deletebutton"} disabled>
                      <DeleteIcon />
                    </IconButton>
                </Can>
              
                : null
              }
              </>
            )}
            {(((!alwaysDownloadable && numSelected > 0) && handleDownloadClick) || (alwaysDownloadable && handleDownloadClick)) ? (
              <Tooltip title="Download als tsv">
                <IconButton aria-label="filter list" >
                  <CloudDownloadIcon onClick={handleDownloadClick} />
                </IconButton>
              </Tooltip>
            ) : (
              <>
              {(handleDownloadClick) ? 
                <Tooltip title="Download als tsv">
                  <IconButton aria-label="filter list" disabled>
                    <CloudDownloadIcon onClick={handleDownloadClick} />
                  </IconButton>
                </Tooltip>
              
                : null
              }
              </>
            )}
            {(numSelected > 0 && props.textToCopy) ? (
              <CopyToClipboard text={props.textToCopy}>
                <Tooltip title="In die Zwischenablage kopieren">
                  <IconButton aria-label="Edit" >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            ) : (
              <>
              {(props.textToCopy) ? 
                  <Tooltip title="In die Zwischenablage kopieren">
                    <IconButton aria-label="Edit" disabled>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
              
                : null
              }
              </>
            )}
            {(handleAddClick) ? (
              <>
              {(props.objectType) ?
                <Can I={abilityAction || "create"} a={abilityType}>
                  <Tooltip title={(objectName) ? objectName + " hinzufügen" : title + " hinzufügen"}>
                    <IconButton onClick={handleAddClick} data-cy={title + "addbutton"}>
                      <AddIcon/>
                    </IconButton>
                  </Tooltip>
                </Can>
                : null
              }
              {(props.superType) ?
                <Can I={abilityAction || "update"} a={abilityType}>
                  <Tooltip title={title + " hinzufügen"}>
                    <IconButton onClick={handleAddClick}  data-cy={title + "addbutton"}>
                      <AddIcon/>
                    </IconButton>
                  </Tooltip>
                </Can>
                : null
              }
            
              </>
            ) : ( 
              <>
              { ((props.noAddIcon == null) || (props.noAddIcon == false)) ? (
                <>
                {(props.objectType) ?
                  <Can I={abilityAction || "create"} a={abilityType}>
                    <Tooltip title={(objectName) ? objectName + " hinzufügen" : title - " hinzufügen"}>
                      <IconButton onClick={handleAddClick}  data-cy={title + "addbutton"} disabled>
                      <AddIcon/>
                      </IconButton>
                    </Tooltip>
                  </Can>
                  : null
                }
                {(props.superType) ?
                  <Can I={abilityAction || "update"} a={abilityType}>
                    <Tooltip title={title + " hinzufügen"}>
                      <IconButton onClick={handleAddClick} disabled data-cy={title + "addbutton"}>
                        <AddIcon/>
                      </IconButton>
                    </Tooltip>
                  </Can>
                  : null
                }
                </>
              ) : null}
              </>
            )}
          
            {(handleMailTo && numSelected > 0) ? (
              <Tooltip title="Email versenden">
                <IconButton
                  // onClick={() => handleMailTo()}
                  href={addresses_string}
                  data-cy={title + "mailtobutton"}
                >
                  <EmailIcon/>
                </IconButton>
              </Tooltip>
            ) : (
              <>
              {(handleMailTo && numSelected == 0) ? 
                <Tooltip title="Email versenden">
                  <IconButton
                    // onClick={() => handleMailTo()}
                    href={addresses_string}
                    disabled
                    data-cy={title + "mailtobutton"}
                  >
                    <EmailIcon/>
                  </IconButton>
                </Tooltip>
                : null
              }
              </>
            )}
            {(handleOverviewClick) ? (
              <Tooltip title="Volle Ansicht">
                <IconButton aria-label="full view">
                  <BorderAllIcon onClick={handleOverviewClick} />
                </IconButton>
              </Tooltip>
            ) : (
              null
            )}
            {(handleListClick) ? (
              <Tooltip title="Dokumente und Versionen">
                <IconButton aria-label="filter list">
                  <ListIcon onClick={handleListClick} />
                </IconButton>
              </Tooltip>
            ) : (
              null
            )}
            {(handleFilterClick) ? (
              <>
                <Tooltip title="Filter" style={{zIndex: 1}}>
                  <IconButton aria-label="filter list" >
                    <FilterListIcon onClick={handleFilterToggle} />
                  </IconButton>
                </Tooltip>
                <FilterPopper open={filterOpen} anchorEl={anchorEl} allData={allData} columns={columns} onChange={props.onFilterChange} filters={props.filters} searchTerm={props.searchTerm} />
              </>
            ) : (
              null
            )}
            {(handleLockChange) &&
              <FormControlLabel
                control={
                  <Switch
                    checked={unlocked}
                    onChange={handleLockChange}
                    color="primary"
                  />
                }
                label= {unlocked ? <LockOpen /> : <Lock /> }
                style={{marginRight: 0, alignSelf: 'flex-end'}}
              />
            }
          </>
        </div>
      </Box>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  // classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

EnhancedTableToolbar = withTheme(EnhancedTableToolbar);

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order,
      orderBy: this.props.orderBy,
      // selectedIds: [],
      // selectedObjects: [],
      data: this.props.data,
      page: (this.props.page) ? this.props.page : 0,
      // uploadDates: this.props.uploadDates,
      rowsPerPage: this.props.rowsPerPage,
      multipleSelectMode: false,
      expanded: (this.props.expanded != null) ? this.props.expanded : true,
    };
    this.form = React.createRef();
  }

  handleExpandChange = (newState) => {
    this.setState({expanded: newState});
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    // console.log(event.target);
    let newSelectedIds = [];
    let newSelectedObjects = []
    if (event.target.checked) {
      // this.setState({ selectedIds: this.props.data.map(n => n.id), selectedObjects: this.props.data});
      newSelectedIds = this.props.data.map(n => n.id);
      newSelectedObjects = this.props.data;
    } else {
      // this.setState({ selectedIds: [], selectedObjects: [] });
      newSelectedIds = [];
      newSelectedObjects = [];
    }
    const selectedIndex = 0;
    this.props.onRowsSelect(selectedIndex, newSelectedIds, newSelectedObjects);
  };

  handleClick = (event, obj) => {
    let newSelectedIds = [];
    let newSelectedObjects = [];
    const { selectedIds, selectedObjects } = this.props;
    const selectedIndex = selectedIds ? selectedIds.indexOf(obj.id) : -1;
    if (this.state.multipleSelectMode) {
      if (selectedIndex === -1) { //not clicked before
        newSelectedIds = newSelectedIds.concat(selectedIds, obj.id);
        newSelectedObjects = newSelectedObjects.concat(selectedObjects, obj);
      } else if (selectedIndex === 0) { //first selected before
        newSelectedIds = newSelectedIds.concat(selectedIds.slice(1));
        newSelectedObjects = newSelectedObjects.concat(selectedObjects.slice(1));
      } else if (selectedIndex === selectedIds.length - 1) { //last selected before
        newSelectedIds = newSelectedIds.concat(selectedIds.slice(0, -1));
        newSelectedObjects = newSelectedObjects.concat(selectedObjects.slice(0, -1));
      } else if (selectedIndex > 0) { // selected somewhere in between before
        newSelectedIds = newSelectedIds.concat(
          selectedIds.slice(0, selectedIndex),
          selectedIds.slice(selectedIndex + 1),
        );
        newSelectedObjects = newSelectedObjects.concat(
          selectedObjects.slice(0, selectedIndex),
          selectedObjects.slice(selectedIndex + 1),
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelectedIds.push(obj.id);
        newSelectedObjects.push(obj);
      }
    }

    // this.setState({ selectedIds: newSelectedIds, selectedObjects: newSelectedObjects });
    if (this.props.onRowsSelect) {
      this.props.onRowsSelect(selectedIndex, newSelectedIds, newSelectedObjects);
    }
  };

  handleDoubleClick = (event, obj) => {
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick(event, obj);
    } else {
      if (this.props.controller) {
        if (this.props.baseName) {
          window.location.assign('/' + this.props.baseName + '/' + this.props.controller + '/' + obj.id);
        } else {
          window.location.assign('/' + this.props.controller + '/' + obj.id);
        }
      }
    }
    
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.props.selectedIds ? this.props.selectedIds.indexOf(id) !== -1 : false;

  createTableCells = (rowData, style, loading) => {
    // console.log(rowData);
    const ret = this.props.columns.map((cellInfo) =>
      // <>
      {
        const cellData = rowData[cellInfo.id]
        var type = cellInfo.type;
        if (loading) {
          type = "loading"
        }
        // console.log(loading, type);
        switch(type) {
          case "loading":
            return (
              <TableCell style={style} scope="row" padding="none" align="left" data-cy={"tablecell" + cellInfo.id}>
                <Skeleton animation="wave" variant="text"/>
              </TableCell>
              
            )
          case "date":
            // console.log(rowData[cellInfo.id]);
            if (rowData[cellInfo.id]) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left" width={110} data-cy={"tablecell" + cellInfo.id}>{Moment(rowData[cellInfo.id]).format('dd DD.MM.YYYY')}</TableCell>
              )
            } else {
              // console.log(rowData[cellInfo.id]);
              return (
                <TableCell style={style} scope="row" padding="none" align="left" width={110} >Keins</TableCell>
              )
            }
          case "duration":
            if (rowData.deadline_days != null) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left"><b>{rowData[cellInfo.id]}</b></TableCell>
              )
            } else if (rowData.planned_duration != null) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left"><i>{rowData[cellInfo.id]}</i></TableCell>
              )
            } else {
              return (
                <TableCell style={style} scope="row" padding="none" align="left">Keins</TableCell>
              )
            }
          case "duration_date":
            if (rowData.deadline_days != null) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left" width={110} ><b>{Moment(rowData[cellInfo.id]).format('dd DD.MM.YYYY')}</b></TableCell>
              )
            } else if (rowData.planned_duration != null) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left" width={110} ><i>{Moment(rowData[cellInfo.id]).format('dd DD.MM.YYYY')}</i></TableCell>
              )
            }
          case "objectStatus":
            // console.log(rowData[cellInfo.id]);
            if (rowData.tooltipText) {
              return (
                <Tooltip title={rowData.tooltipText}>
                  <TableCell style={{backgroundColor: rowData.statusColor}} scope="row" padding="none" align="left">{rowData.statusText}</TableCell>
                </Tooltip>
              )
            } else {
              return (
                <TableCell style={{backgroundColor: rowData.statusColor}} scope="row" padding="none" align="left">{rowData.statusText}</TableCell>
              )
            }
          case "statusSelection":
              console.log(rowData[cellInfo.id]);
              if (rowData[cellInfo.id]) {
                return (
                  <Tooltip title={rowData[cellInfo.id]["tooltipText"]}>
                    <TableCell style={{backgroundColor: rowData[cellInfo.id]["backgroundColor"]}} scope="row" padding="none" align="left">{rowData[cellInfo.id]["label"]}</TableCell>
                  </Tooltip>
                )
              } else {
                return (
                  <TableCell></TableCell>
                )
              }
          // case "trialStatus":
          //   return (
          //     <Tooltip title={rowData.status.text}>
          //       <TableCell style={{backgroundColor: rowData.status.color}} scope="row" padding="none" align="left">{rowData.status.text}</TableCell>
          //     </Tooltip>
          //   )
          case "colorText":
            return (
              <TableCell style={{backgroundColor: rowData[cellInfo.id][1]}} scope="row" padding="none" align="left">{rowData[cellInfo.id][0]}</TableCell>
            )
          case "starMark":
            // console.log(rowData[cellInfo.id]);
            return (
              <Tooltip title="Primärer Ansprechpartner">
                <TableCell style={style} scope="row" padding="none" align="left" width={30} >
                { (rowData.primary_contact) &&
                  <StarIcon style={{color: "orange"}}/>
                }
                </TableCell>
              </Tooltip>
            )
          
          case "subObject":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">{rowData[cellInfo.id][cellInfo.secondary]}</TableCell>
            )
          case "shards":
            const shards = (rowData[cellInfo.id]) ? rowData[cellInfo.id].map(element => {
              // if rowData[cellInfo.id]
              
              if ((rowData["mainRole"]) && rowData["mainRole"].name == element) {
                return (
                  <Paper style={{padding: '2px', margin: '2px', backgroundColor: red[100]}}>
                    {element}
                  </Paper>
                )
              } else {
                return (
                  <Paper style={{padding: '2px', margin: '2px', backgroundColor: blue[500]}}>
                    {element}
                  </Paper>
                )
              }
            }) : null;
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                <Box display="flex" flexDirection="row" justifyContent="flex-start" flexShrink={true} flexWrap="nowrap" >
                  {shards}
                </Box>
              </TableCell>
            )
          case "boolean":
            return (
              <TableCell style={style} scope="row" padding="none" align="left" width={30} >
              { (rowData[cellInfo.id]) &&
                <CheckIcon style={{color: "green"}}/>
              }
              </TableCell>
            )
          case "hidden":
            return null;
          case "nztfSelect":
            let background = 'blue';
            // let val = null;
            let optValue = null;
            const left = (rowData.left && (rowData.leave_date <= cellInfo.date_start));
            const before = (rowData.join_date >= cellInfo.date_end);
            const notDateApplies = (left || before);
            // console.log(rowData, cellInfo, before);
            // console.log(rowData.leave_date, cellInfo.date_start, (rowData.leave_date < cellInfo.date_start));
            const appliesToRole = cellInfo.site_functions.includes(rowData.site_function);
            if (!appliesToRole) {
              // console.log("role does not apply");
              background = 'white';
              optValue = {value: null, label: ""};
              // val = null;
            } else {
              if (rowData.documents != null) {
                if (rowData.documents[cellInfo.id]) { // not null
                  // value = parseInt(rowData.documents[cellInfo.id][cellInfo.version]);
                  let v = null;
                  if (notDateApplies) {
                    background = 'gray';
                  }
                  if (rowData.documents[cellInfo.id][cellInfo.version]) {
                    v = parseInt(rowData.documents[cellInfo.id][cellInfo.version]);
                  } else {
                    v = null;
                  }
                  switch(v) {
                    case 1:
                      if (!notDateApplies) {
                        background = '#76d658';
                      }
                      optValue = {value: 1, label: "da"};
                      // val = 1;
                      break;
                    case 2:
                      if (!notDateApplies) {
                        background = '#76d658';
                      }
                      optValue = {value: 2, label: "da geprüft"};
                      // val = 2;
                      break;
                    case 3:
                      if (!notDateApplies) {
                        background = red[400],0.5;
                      }
                      optValue = {value: 3, label: "unvollständig"};
                      // val = 3;
                      break;
                    case 0:
                      if (!notDateApplies) {
                        background = red[400],0.5;
                        optValue = {value: 0, label: "fehlt"};
                        // val = 0;
                      } else {
                        background = 'gray';
                        optValue = {value: -1, label: "n.z."};
                        // val = -1;
                      }
                      break;
                    case -1:
                      if (!notDateApplies) {
                        background = 'white';
                      }
                      optValue = {value: -1, label: "n.z."};
                      // val = -1;
                      break;
                    case 4:
                      if (!notDateApplies) {
                        background = 'white';
                      }
                      optValue = {value: 4, label: "nicht verfügbar"}
                      // val = 4;
                      break;
                    default:
                      if (!notDateApplies) {
                        background = red[400];
                        optValue = {value: 0, label: "fehlt"}
                      } else {
                        // console.log(rowData.documents[cellInfo.id]);
                        optValue = {value: -1, label: "n.z."};
                      }
                      // val = 5;
                      break;
                  }
                } else {// null
                  // console.log(rowData, disable);
                  if (!notDateApplies) {
                    background = red[400];
                    optValue = {value: 0, label: "fehlt"}
                    // val = 0;
                  } else {
                    // console.log("should work");
                    background = 'gray';
                    optValue = {value: -1, label: "n.z."}
                    // val = -1;
                  }
                  // val = 0;
                }

              } else { // no values in documents
                if (notDateApplies) {
                  background = 'gray';
                  optValue = {value: -1, label: "n.z."}
                  // val = -1;
                } else {
                  background = red[400];
                  optValue = {value: 0, label: "fehlt"};
                  // val = 0;
                }
              }
            }
            // if (optValue.label == "fehlt") {
            //   console.log(background, optValue, rowData, value);
            // }
            // if (appliesToRole && left) {
            //   console.log(background, optValue);
            // }
            const options = [{value: 0, label: "fehlt", color: "blue"}, {value: 1, label: "da"}, {value: 2, label: "da geprüft"}, {value: 3, label: "unvollständig"}, {value: 4, label: "nicht verfügbar"}, {value: 5, label: "unbekannt"}, {value: -1, label: "n.z."}];
            // console.log(optValue);
            // const selectedOption = options.find(opt => opt.value == optValue);
            return (
              <TableCell style={{backgroundColor: background}} scope="row" padding="none" align="left">
                <>
                {(this.props.unlocked) &&
                <TextField
                  value={optValue}
                  onChange={this.props.handleUpdateDocuments({name: cellInfo.id, spa_id: rowData.id, value: optValue.value, version: cellInfo.version})}
                  // backGroundColor={background}
                  disabled={(!(this.props.unlocked))}
                  type="select"
                  options={options}
                />}
                {(!this.props.unlocked) &&
                optValue.label}
                </>
              </TableCell>
            )
          case "milestoneSelect":
            const opt = [{value: 0, label: "", backgroundColor: "#F5B556"}, {value: 1, label: "Done", backgroundColor: "#5cba3f"}, {value: -1, label: "N.Z.", backgroundColor: "#adadad"}, {value: 2, label: "Niemals", backgroundColor: "#adadad"}];
            let bg = "#F5B556";
            let val = 0;
            if (rowData[cellInfo.id] == "1") {
              bg = "#5cba3f";
              val = 1;
            } else if (rowData[cellInfo.id] == "-1") {
              bg = "#adadad";
              val = -1;
            } else if (rowData[cellInfo.id] == "2") {
              bg = "#adadad";
              val = 2;
            }
            return (
              <TableCell style={{backgroundColor: bg}} scope="row" padding="none" align="left">
                <Select
                  native
                  value={val}
                  onChange={this.props.handleUpdateMilestones({name: cellInfo.label, site_id: rowData["site_id"]})}
                  // style={{backGroundColor: 'white'}}
                  disabled={(!(this.props.unlocked))}
                >
                  <option value="0"></option>
                  <option value="1">Done</option>
                  <option value="-1">N.Z.</option>
                  <option value="2">Niemals</option>
                </Select>
                <TextField
                  value={val}
                  onChange={this.props.handleUpdateMilestones({name: cellInfo.label, site_id: rowData["site_id"]})}
                  backGroundColor={bg}
                  disabled={(!(this.props.unlocked))}
                  type="select"
                  options={opt}
                />
              </TableCell>
            )
          case "decimaltime":
            var rounded = 0.0;
            if (rowData[cellInfo.id] != null) {
              rounded = Math.round(rowData[cellInfo.id] * 100) / 100;
            }
            return (
              <TableCell style={style} scope="row" padding="none" align="left">{rounded + "h"}</TableCell>
            )
          case "time":
            // const rounded = Math.round(rowData[cellInfo.id] * 100) / 100;
            let shownTime = "";
            const hoursString = "" + rowData[cellInfo.id];
            let separatorIndex = hoursString.indexOf('.');
            const hh = (separatorIndex != -1) ? hoursString.substring(0, separatorIndex) : hoursString;
            let mm = (separatorIndex && separatorIndex != -1 && separatorIndex != hoursString.length) ? hoursString.substring(separatorIndex + 1, hoursString.length) : "00";
            mm = Math.round((("0." + mm.padEnd(2,"0")) * 60)).toString().padStart(2, "0");
            shownTime = hh + "h " + mm + "m";
            return (
              <TableCell style={style} scope="row" padding="none" align="left">{shownTime}</TableCell>
            )
          case "stringList":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">{rowData[cellInfo.id]}</TableCell>
            )
          case "textNoBreak":
            if (rowData[cellInfo.id]) {
              return (
                <Tooltip title={rowData[cellInfo.id]}>
                  <TableCell style={style} scope="row" padding="none" align="left">{rowData[cellInfo.id] ? rowData[cellInfo.id].substring(0,20) : null}</TableCell>
                </Tooltip>
              )
            } else {
              return (
                <TableCell style={style} scope="row" padding="none" align="left">{rowData[cellInfo.id] ? rowData[cellInfo.id].substring(0,20) : null}</TableCell>
              )
            }
          case "text": // needs to be array with lines!
              // rowData[cellInfo.id].forEach(line => {
              //   return (

              //     <br>
              //   )
              // })
              return (
                <Tooltip title={rowData[cellInfo.id]}>
                  <TableCell style={style} scope="row" padding="none" align="left">
                    {rowData[cellInfo.id].map((line, idx) => 
                      <p key={idx}>{rowData[cellInfo.id][idx]}</p>
                    )}
                    {/* {rowData[cellInfo.id] ? rowData[cellInfo.id].substring(0,20) : null} */}
                  </TableCell>
                </Tooltip>
              )
          case "linkButton":
            if (rowData[cellInfo.id]) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left">
                  <IconButton onClick={() => window.location.assign(rowData[cellInfo.id])}>
                    <ExitToAppIcon/>
                  </IconButton>
                </TableCell>
              )
            } else {
              <TableCell style={style} scope="row" padding="none" align="left"></TableCell>
            }
          case "link":
            if (rowData[cellInfo.id]) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left">
                  <Link href="" underline='hover' fullWidth>
                    {rowData[cellInfo.id]}
                  </Link>
                </TableCell>
              )
            } else {
              <TableCell style={style} scope="row" padding="none" align="left"></TableCell>
            }
          case "attributes":
            if (rowData[cellInfo.id]) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left">
                  { (rowData[cellInfo.id].includes("important")) &&
                    <PriorityHighIcon/>}
                  { (rowData[cellInfo.id].includes("reminder")) &&
                    <AccessAlarmIcon/>}
                  { (rowData[cellInfo.id].includes("recurring")) &&
                    <LoopIcon/>}
                </TableCell>
              )
            } else {
              <TableCell style={style} scope="row" padding="none" align="left"></TableCell>
            }
          case "usericon":
            if (rowData[cellInfo.id]) {
              return (
                <TableCell style={style} scope="row" padding="none" align="left">
                  { (rowData[cellInfo.id]) &&
                    <PersonIcon/>}
                </TableCell>
              )
            } else {
              <TableCell style={style} scope="row" padding="none" align="left"></TableCell>
            }
          case "patient_reported_outcomes":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0)
                  && <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], ["pro_data_registries", "sarcbop_pro_mstsus", "sarcbop_pro_mstsos", "sarcbop_pro_odis", "sarcbop_pro_efas", "sarcbop_pro_oks", "sarcbop_pro_ohs", "sarcbop_pro_dashes", "sarcbop_pro_eortcs"], "viewPRO")}>
                    <><ArticleIcon/> {rowData[cellInfo.id]}</> 
                  </IconButton>
                }
              </TableCell>
            )
          case "gene_checklist":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                 <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarcbop_gene_checklists", "viewGeneChecklist")}>
                    <><FingerprintIcon/> </> 
                  </IconButton>
                }
              </TableCell>
            )
          case "socio":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_socios", "viewSocio")}>
                    <><SummarizeIcon/> </>
                  </IconButton>
                }
              </TableCell>
            )
          case "diagnosis":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_diagnoses", "viewDiagnosis")}>
                    <><MedicalInformationIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
            case "heroes_aya_patient":
              console.log(rowData[cellInfo.id]);
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] >= 1) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "heroes_aya_patient_data", "heroeayaPatientData")}>
                    <><AddCardIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
                { (rowData[cellInfo.id] == 0) &&
                  <IconButton onClick={this.props.handleOpenNewObject(rowData["id"], "heroeayaNewPatientData")}>
                    <><AddIcon/></>
                  </IconButton>
                }
              </TableCell>
            )
          case "p_tnm":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_p_tnm_inquiries", "viewPTnm", "pTnmData")}>
                    <><PatternIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          
          case "stadia":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_stadia", "viewStadium", "stadiumData")}>
                    <><WaterfallChartIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "study_identifiers":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                    {rowData[cellInfo.id].map(x => x.title).join(", ")}
              </TableCell>
            )
          case "surgery":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_operations", "viewOperation")}>
                    <><ContentCutIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "systemic_therapy":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_systemic_therapies", "viewSystemicTherapy")}> 
                    <><MedicationIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "miscellaneous_therapy":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_miscellaneous_therapies", "viewMiscellaneousTherapy")}>
                    <><HealingIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "master_pseudonym":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                    {rowData[cellInfo.id].map(x => x.master_pseudonym).join(", ")}
              </TableCell>
            )
          case "nuclear_therapy":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_nuclear_therapies", "viewNuclearTherapy")}>
                    <><SpokeIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "radiotherapy":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_radiotherapies", "viewRadiotherapy")}>
                    <><VerticalAlignCenterIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "progression":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_progressions", "viewProgression")}>
                    <><TimelineIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "extraction":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_extraction", "viewExtraction")}>
                    <><BiotechIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "vital_status":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "vital_statuses", "viewVitalStatus")}>
                    <><MonitorHeartIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "sarc_blood_samples":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_blood_samples", "viewBloodSample")}>
                    <><ContentCutIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
          case "sarc_tissue_samples":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_tissue_samples", "viewTissueSample")}>
                    <><DragIndicatorIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
            case "sarc_bado_questionnaires":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                { (rowData[cellInfo.id] > 0) &&
                  <IconButton onClick={() => this.props.handleViewObjects(rowData["id"], "sarc_bado_questionnaires", "viewBadoQuestionnaire")}>
                    <><DragIndicatorIcon/> {rowData[cellInfo.id]}</>
                  </IconButton>
                }
              </TableCell>
            )
            case "heroes_aya_case":
            //  console.log(rowData["id"]);
            return (
              
                <TableCell style={style} scope="row" padding="none" align="left" width={30} >
                { (rowData[cellInfo.id] == 1) &&
                  <Tooltip title="eingewilligt"><CheckIcon style={{color: "green"}}/></Tooltip>}
                 { (rowData[cellInfo.id] == 2) &&
                   <Tooltip title="abgelehnt"><CheckIcon style={{color: "red"}}/></Tooltip>}
                 { (rowData[cellInfo.id] == 3) &&
                   <Tooltip title="offen "><CheckIcon style={{color: "orange"}}/></Tooltip>
                }
                </TableCell>
            )  
          case "choseWhetherOverwrite":
            return (
              <TableCell style={style} scope="row" padding="none" align="left">
                {
                  <RadioGroup
                    row={true}
                    defaultValue={true}
                    // name={rowData["sarc_patient_id"]}
                    onChange={(event) => this.props.handleChangeRowEntry(event, rowData["ind"], rowData["column_name"])}
                  >
                    <FormControlLabel value={false} control={<Radio />} label="" />
                    <FormControlLabel value={true} control={<Radio />} label="" />
                  </RadioGroup>
                }
              </TableCell>
            )
        default:
            return (
              <TableCell style={style} scope="row" padding="none" align="left" data-cy={"tablecell" + cellInfo.id}>{rowData[cellInfo.id]}</TableCell>
            )
          }
      }
    )
    return ret;
  }
  createEmptyTableRows = (emptyRows, columns, loading) => {
    if (loading) {
      return [...Array(emptyRows).keys()].map( rowNr => {
        for (let rowNr = 0; rowNr < emptyRows; ++rowNr) {
          return (
            <TableRow>
              {this.createTableSkeletonCells(columns.length + 1, rowNr)}
            </TableRow>
          )
        }
      }) 
    } else {
      return [...Array(emptyRows).keys()].map( rowNr => {
        for (let rowNr = 0; rowNr < emptyRows; ++rowNr) {
          return (
            <TableRow>
              {this.createEmptyTableCells(columns.length + 1, rowNr)}
            </TableRow>
          )
        }
      }) 
    }
  }

  createTableSkeletonCells = (number_of_cells, rowNr) => {
    return [...Array(number_of_cells).keys()].map( cellNr => {
      return (
        <TableCell height='28px' scope="row" padding="none" align="left" data-cy={"skeleton_tablecell" + "_" + rowNr + "_" + cellNr}>
          <Skeleton animation="wave" variant="text"/>
        </TableCell>
      )
    })
  }

  createEmptyTableCells = (number_of_cells, rowNr) => {
    return [...Array(number_of_cells).keys()].map( cellNr => {
      return (
        <TableCell height='28px' scope="row" padding="none" align="left" data-cy={"empty_tablecell" + "_" + rowNr + "_" + cellNr}/>
      )
    })
  }

  createTableRows = (data, selectable, order, orderBy, rowsPerPage, page, emptyRows, multipleSelectMode, markedIds, loading)  => {
    return (
      stableSort(data, getSorting(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(n => {
        const isSelected = this.isSelected(n.id);
        let backgroundColor = {};
        if (markedIds) {
          // console.log(markedIds);
          if ((markedIds.includes(n.id))) {
            backgroundColor = {backgroundColor: blueGrey[200]};
          }
          // console.log(backgroundColor);
        }
        let backgroundColorCells = {};
        if (n.missingRow) {
          backgroundColorCells = {backgroundColor: red[300]};
          return (
            <Tooltip title="Bitte über den Button '+' zur Studie hinzufügen. Doppelklick funktioniert nicht.">
              <TableRow
                hover
                onClick={event => this.handleClick(event, n)}
                onDoubleClick={event => this.handleDoubleClick(event, n)}
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                key={n.id}
                selected={isSelected}
                // className={this.props.classes.tableRow}
              >
                { (selectable) &&
                  <TableCell padding="checkbox" width={36} style={backgroundColor}>
                    <Checkbox checked={isSelected} color="primary" />
                  </TableCell>
                }
                
                {this.createTableCells(n, backgroundColorCells, loading)}
              </TableRow>
            </Tooltip>
          )
        } else {
          return (
            <TableRow
              hover
              onClick={event => this.handleClick(event, n)}
              onDoubleClick={event => this.handleDoubleClick(event, n)}
              role="checkbox"
              aria-checked={isSelected}
              tabIndex={-1}
              key={n.id}
              selected={isSelected}
              // className={this.props.classes.tableRow}
            >
              { (selectable) &&
                <TableCell padding="checkbox" width={36} style={backgroundColor} >
                  <Checkbox checked={isSelected} color="primary" />
                </TableCell>
              }
              {this.createTableCells(n, backgroundColorCells, loading)}
            </TableRow>
          )
        }
      })

    )
  }

  handleMultipleSelectChange = event => {
    if (this.state.multipleSelectMode) {
      this.setState({multipleSelectMode: false});
    } else {
      this.setState({multipleSelectMode: true});
    }
  }

  test = (tf, title) => {
    console.log("test");
    console.log(tf);
    console.log(title);
  }

  // handleMailTo = ()  => {
  //
  //   console.log(this.props.selectedObjects.map(data => data.email));
  // }

  render() {
    const { data, allData, selectedIds, selectedObjects, columns, markedIds, theme, selectable, autoFill, collapseEmpty, unlocked, loading } = this.props;
    const { order, orderBy, rowsPerPage, page, multipleSelectMode } = this.state;
    var emptyRows = rowsPerPage;
    if (data) {
      emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    }
    Moment.locale('de');
    // console.log(this.createEmptyTableRows(emptyRows, this.props.columns));
    // console.log(emptyRows, autoFill, loading);
    return (
      <Paper style={{borderStyle: "solid", width: '100%', borderWidth: "1px"}}>
        <EnhancedTableToolbar
          title={this.props.title}
          numSelected={selectedIds ? selectedIds.length : 0}
          selectedObjects={selectedObjects}
          handleAddClick={this.props.handleAddClick}
          handleAddClickHeroesAya={this.props.handleAddClickHeroesAya}
          handleNewClick={this.props.handleNewClick}
          handleInfoClick={this.props.handleInfoClick}
          handleEditClick={this.props.handleEditClick}
          handleRemoveClick={this.props.handleRemoveClick}
          handleDeleteClick={this.props.handleDeleteClick}
          handleMailTo={this.props.handleMailTo}
          handleListClick={this.props.handleListClick}
          handleOverviewClick={this.props.handleOverviewClick}
          handleParentSelectClick={this.props.handleParentSelectClick}
          handleDuplicateClick={this.props.handleDuplicateClick}
          handleDoneClick={this.props.handleDoneClick}
          handleUploadClick={this.props.handleUploadClick}
          handleNewObjectClick={this.props.handleNewObjectClick}
          handleUpClick={this.props.handleUpClick}
          handleDownClick={this.props.handleDownClick}
          handleRightClick={this.props.handleRightClick}
          handleLeftClick={this.props.handleLeftClick}
          selectable={selectable}
          expanded={this.state.expanded}
          handleExpandChange={this.handleExpandChange}
          // handleMailTo={this.handleMailTo}
          handleFilterClick={this.props.handleFilterClick}
          handleDownloadClick={this.props.handleDownloadClick}
          alwaysDownloadable={this.props.alwaysDownloadable}
          textToCopy={this.props.textToCopy}
          allData={allData}
          columns={columns}
          onFilterChange={this.props.onFilterChange}
          filters={this.props.filters}
          unlocked={this.props.unlocked}
          handleLockChange={this.props.handleLockChange}
          onClick={this.test}
          objectType={this.props.objectType}
          superType={this.props.superType}
          multipleSelectMode={(this.props.multipleSelectable) ? multipleSelectMode : null}
          handleMultipleSelectChange={(this.props.multipleSelectable) ? this.handleMultipleSelectChange : null}
          objectName={this.props.objectName}
          noAddIcon={this.props.noAddIcon}
          loading={this.state.loading}
          uploadDates={this.props.uploadDates}
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          { ((data.length > 0) || !(collapseEmpty)) &&
            <div >
              <Table aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selectedIds ? selectedIds.length : 0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  columns={this.props.columns}
                  selectable={selectable}
                  uploadDates={this.props.uploadDates}
                />
                <TableBody>
                  {this.createTableRows(data, selectable, order, orderBy, rowsPerPage, page, emptyRows, multipleSelectMode, markedIds, loading)}
                  {((emptyRows > 0) && autoFill) && 
                    this.createEmptyTableRows(emptyRows, this.props.columns, loading)
                  }

                </TableBody>
              </Table>
            </div>
          }
          { ((data.length > rowsPerPage) || (rowsPerPage != this.props.rowsPerPage)) &&
            <TablePagination
              rowsPerPageOptions={[3,5,10,15,25,50]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
                style: {padding: '3px'}
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
                style: {padding: '3px'}
              }}
              // className={classes.pagination}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          }
        </Collapse>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(EnhancedTable);
