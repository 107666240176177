import React from 'react';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import { flexbox } from '@mui/system';
import PropTypes from 'prop-types';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'
// import EnhancedTable from './EnhancedTable.jsx';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  content: {
      display: "flex",
      flexBasis: '100%',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-evenly',
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class InfoPeopleTrialsAssociation extends React.Component {

  constructor(props) {
    super(props);
    // console.log("test");
    let selectedGender = {};
    if (this.props.selectedPerson.gender == "m") {
      selectedGender = {label: "Männlich", value: "m"}
    } else if (this.props.selectedPerson.gender == "w") {
      selectedGender = {label: "Weiblich", value: "w"}
    } else if (this.props.selectedPerson.gender == "?") {
      selectedGender = {label: "Unbekannt", value: "?"}
    }
    let departments = [];
    if (this.props.selectedPerson.sites_people_associations) {
      const department_ids = this.props.selectedPerson.sites_people_associations.filter(sites_people_association => {
        return sites_people_association.site_id == this.props.selectedSite.id;
      }).map(sites_people_association => sites_people_association.department_id);
      departments = this.props.selectedPerson.site_departments.filter(department => {
        return department_ids.includes(department.id);
      }).filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i);
    } else {
      departments = this.props.departments;
    }
    let salutation = "";
    if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
      salutation = "Sehr geehrter Herr";
    } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
      salutation = "Sehr geehrte Frau";
    } else if (this.props.selectedPerson.gender == "?") {
      salutation = "Sehr geehrte(r) Frau/Herr";
    }
    this.state = {open: this.props.open, last_name: this.props.selectedPerson.last_name, first_name: this.props.selectedPerson.first_name, title: this.props.selectedPerson.title, phone_number: this.props.selectedPerson.phone_number, email: this.props.selectedPerson.email, function: this.props.selectedPerson.function, mobile_number: this.props.selectedPerson.mobile_number, fax_number: this.props.selectedPerson.fax_number, unlocked: false, gender: this.props.selectedPerson.gender, selectedGender: selectedGender, comment: this.props.selectedPerson.comment, spa_comment: this.props.selectedPerson.spa_comment, site_function: this.props.selectedPerson.site_function, primary_contact: this.props.selectedPerson.primary_contact, institution_name: this.props.selectedPerson.institution_name, departments: departments, left: this.props.selectedPerson.left, join_date: this.props.selectedPerson.join_date, leave_date: this.props.selectedPerson.leave_date, addressSelection: {title: true, address: true, phone_number: true, mobile_number: true, institution: true, department: true, fax_number: true, name: true, email: true, salutation: true}, salutation: salutation, storyboardOpen: false};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPerson !== prevProps.selectedPerson) {
      // this.getDepartments();
      let departments = [];
      if (this.props.selectedPerson.sites_people_associations) {
        const department_ids = this.props.selectedPerson.sites_people_associations.filter(sites_people_association => {
          return sites_people_association.site_id == this.props.selectedSite.id;
        }).map(sites_people_association => sites_people_association.department_id);
        departments = this.props.selectedPerson.site_departments.filter(department => {
          return department_ids.includes(department.id);
        }).filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i);
      } else {
        departments = this.props.departments;
      }
      let salutation = "";
      if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
        salutation = "Sehr geehrter Herr";
      } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
        salutation = "Sehr geehrte Frau";
      } else if (this.props.selectedPerson.gender == "?") {
        salutation = "Sehr geehrte(r) Frau/Herr";
      }
      let site_function = "";
      if (this.props.selectedPerson.site_function != null) {
        site_function = this.props.selectedPerson.site_function;
      }
      this.setState({ last_name: this.props.selectedPerson.last_name, first_name: this.props.selectedPerson.first_name, title: this.props.selectedPerson.title, phone_number: this.props.selectedPerson.phone_number, email: this.props.selectedPerson.email, function: this.props.selectedPerson.function, mobile_number: this.props.selectedPerson.mobile_number, fax_number: this.props.selectedPerson.fax_number, unlocked: false, selectedGender: {value: this.props.selectedPerson.gender}, comment: this.props.selectedPerson.comment, spa_comment: this.props.selectedPerson.spa_comment, site_function: site_function, primary_contact: this.props.selectedPerson.primary_contact, institution_name: this.props.selectedPerson.institution_name, departments: departments, active: this.props.selectedPerson.active, join_date: this.props.selectedPerson.join_date, leave_date: this.props.selectedPerson.leave_date, addressSelection: {title: true, address: true, phone_number: true, mobile_number: true, institution: true, department: true, fax_number: true, name: true, email: true, salutation: true }, left: this.props.selectedPerson.left, salutation: salutation});
    }
  }

  // getDepartments = () => {
  //   let currentComponent = this;
  //   // departments = this.this.props.peopleDepartmentsAssociations.filter(assoc => assoc.person_id == this.props.selectedPerson.id).map(assoc => assoc.department_id);
  //   currentComponent.setState({ departments: this.props.departments });
  //   // let currentComponent = this;
  //   // // const departments = this.state.departments;
  //   // fetch('/people/'+this.props.selectedPerson.id+'/get_departments', {
  //   //   credentials: 'include',
  //   // })
  //   // .then(function(response){return response.json();})
  //   // .then(function(data){
  //   //   // console.log(data);
  //   //   currentComponent.setState({ departments: data.departments, institutions: data.institutions });
  //   // });
  // }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleChange2 = event => {
    let currentComponent = this;
    // const target = event.target;
    // const value = target.value;
    // const name = target.id;
    let newAddressSelection = Object.assign({}, this.state.addressSelection);
    // console.log(event.target.value);
    newAddressSelection[event.target.value] = newAddressSelection[event.target.value] ? false : true;
    currentComponent.setState({ addressSelection: newAddressSelection });
  };

  handleRemoveDepartment = (department_id) => {
    // console.log(department_id);
    let currentComponent = this;
    fetch('/people/'+this.props.selectedPerson.id+'/remove_from_department', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        department_id: department_id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        let newDepartments = [].concat(currentComponent.state.departments);
        const departmentIndex = newDepartments.findIndex(newDepartment => newDepartment.id == department_id);
        newDepartments.splice(departmentIndex,1);
        currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  };

  handleDoubleClickDepartment = event => {
    console.log(event);
    console.log("doubleClick")
  };

  handleStatusboxLockChange = event => {
    if (this.state.unlocked == false) {
      this.setState({unlocked: true});
    } else {
      this.setState({unlocked: false});
    }
  }

  handleGenderChange = event => {
    this.setState({ selectedGender: event, gender: event.value });
  }

  handleClose = event => {
    this.setState({lastName: this.props.selectedPerson.last_name, firstName: this.props.selectedPerson.first_name, title: this.props.selectedPerson.title, phoneNumber: this.props.selectedPerson.phone_number, email: this.props.selectedPerson.email, function: this.props.selectedPerson.function, mobileNumber: this.props.selectedPerson.mobile_number, faxNumber: this.props.selectedPerson.fax_number, unlocked: false, selectedGender: {value: this.props.selectedPerson.gender}});
    this.props.handleClose();
  }

  handleStoryboardExpandChange = (val, title) => {
    this.setState({storyboardOpen: val});
  }

  render(props) {
    const { classes, departments } = this.props;
    const genderOptions = [
      {label: "Unbekannt", value: "?"},
      {label: "Männlich", value: "m"},
      {label: "Weiblich", value: "w"},
    ];
    var departmentChips = null;
    var handleRemoveDepartment = null;
    let addresses = [];
    if (departments) {
      if (departments.length > 0) {
        departmentChips = departments.map((department) =>
          <Chip
            className={classes.chip}
            size="small"
            label={department.name}
            onDoubleClick={this.handleDoubleClickDepartment}
            onDelete={this.state.unlocked ? () => this.handleRemoveDepartment(department.id) : null}
            clickable
            color="primary"
          />
        );
        addresses = this.state.departments.map((department) => {
          let address = "\n";
          if (this.state.addressSelection.salutation && this.state.salutation) {
            address += this.state.salutation + " ";
          }
          if (this.state.addressSelection.title && this.state.title) {
            address += this.state.title + " ";
          }
          if (this.state.addressSelection.name) {
            address += this.state.first_name + " " + this.state.last_name + "\n";
          }
          const institution = this.props.institutions.find(institution => institution.id == department.institution_id);
          if (this.state.addressSelection.institution && institution) {
            address += institution.name + "\n";
          }
          if (this.state.addressSelection.department) {
            address += department.name + "\n";
          }
          if (this.state.addressSelection.address) {
            address += department.street + "\n" + department.zip + " " + department.city + "\n";
          }
          if (this.state.addressSelection.phone_number && this.state.phone_number) {
            address += "Tel.: " + this.state.phone_number + "\n";
          }
          if (this.state.addressSelection.mobile_number && this.state.mobile_number) {
            address += "Mobil: " + this.state.mobile_number + "\n";
          }
          if (this.state.addressSelection.fax_number && this.state.fax_number) {
            address += "Fax: " + this.state.fax_number + "\n";
          }
          if (this.state.addressSelection.email && this.props.selectedPerson.email) {
            address += "E-Mail: " + this.state.email + "\n";
          }
          return (
            <TextField
              margin="dense"
              id="addresses"
              label="Adressen"
              onChange={this.handleChange}
              fullWidth
              multiline
              variant="outlined"
              value={address}
              // error={!this.state.function}
              InputProps={{
                readOnly: true,
              }}
              type="text"
            />
          )
        })
      }
    }
    return (
            <>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                <Typography className={classes.title} variant="h8" color="inherit" noWrap style={{marginLeft: 5}}>
                  Studienspezifisch
                </Typography>
                <Tooltip title="Verbindung mit Studie editieren">
                  <IconButton aria-label="Edit" onClick={this.props.handleEditClick}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
                <div style={{margin: "10px", backgroundColor: 'white', padding: '10px',}}>
                <TextField
                  margin="dense"
                  id="trial_role"
                  label="Funktion"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.props.selectedPerson.trial_role || ""}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <FormControlLabel
                  value="overview_show"
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.props.selectedPerson.overview_show}
                      disabled={true}
                    />
                  }
                  label="Auf Studienübersicht angezeigt"
                  labelPlacement="end"
                />
                <FormControlLabel
                  style={{zIndex: 996}}
                  control={
                    <Checkbox
                      checked={this.props.selectedPerson.primary_contact}
                      onChange={this.handleChange2}
                      disabled={true}
                    />
                  }
                  name="primary_contact"
                  label="Primärer Ansprechpartner"
                />
                <TextField
                  id="join_date"
                  label="Eintrittsdatum"
                  type="date"
                  defaultValue={this.props.selectedPerson.join_date}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  style={{zIndex: 1}}
                />
                <FormControlLabel
                  style={{zIndex: 1}}
                  control={
                    <Checkbox
                      checked={this.props.selectedPerson.left}
                      onChange={this.handleChange2}
                      disabled={true}
                    />
                  }
                  label="Ausgeschieden"
                  name="left"
                />
                <TextField
                  id="leave_date"
                  label="Austrittsdatum"
                  type="date"
                  // defaultValue={this.props.leave_date}
                  value={this.props.selectedPerson.leave_date}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={(!(this.props.selectedPerson.leave_date) && this.props.selectedPerson.left)}
                  disabled
                  style={{zIndex: 1}}
                />
                <TextField
                  id="comment"
                  label="Kommentar"
                  onChange={this.handleChange}
                  multiline
                  // className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={this.props.selectedPerson.pta_comment || ""}
                  fullWidth
                  style={{zIndex: 1}}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="text"
                />
              </div>
            </>
    )
  }
}

InfoPeopleTrialsAssociation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoPeopleTrialsAssociation);
