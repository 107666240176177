import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes, { string } from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import { save } from 'save-file';

import MomentUtils from "@date-io/moment";
import moment from 'moment'
import "moment/locale/de";

// import MUIDataTable from "mui-datatables";

import { withSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import EnhancedTable from './EnhancedTable.jsx';
import TimeRegistrationDialog from './TimeRegistrationDialog.jsx';
import TimeRegistrationFilterFields from './TimeRegistrationFilterFields.jsx';
import TimeRegistrationStatsDialog from './TimeRegistrationStatsDialog.jsx';
import Select from 'react-select';

import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import Moment from 'moment';

moment.locale('de');

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

const servicesColumns = [
  {
   id: "roleNames",
   label: "Rolle(n)",
   numeric: false,
   disablePadding: false,
   type: "stringList",
   filterable: true,
  },
  {
   id: "name",
   label: "Leistungsart",
   numeric: false,
   disablePadding: false,
   type: "string",
  },
  {
    id: "annotation",
    label: "Beschreibung",
    numeric: false,
    disablePadding: false,
    type: "string",
   },
 ];

class TimeRegistrationStats extends React.Component {
  constructor(props) {
    super(props);
    // const user = JSON.parse(props.user);
    let earliestDate = moment();
    let rolesSet = new Set();
    props.time_registrations.forEach(tr => {
      if (moment(tr.entry_date) < earliestDate) {
        earliestDate = moment(tr.entry_date);
      }
      rolesSet.add(tr.role_id);
    })
    this.state = { obj: {role_ids: [], filter_start_date: earliestDate.toISOString().substr(0,10), filter_end_date: moment().toISOString().substr(0,10), service_ids: [], trialIds: [], rangeType: "monthly", statsType: "timespan"}, time_registrations: props.time_registrations, user: props.user, trial: (props.trial) ? props.trial : null, openDialogs: {details: false}, time_str: null, trial: this.props.trial, timeRegistrationsFilters: [], rolesSet: rolesSet, selectedTimeRegistrations: []};
    this.form = React.createRef();
  }

  handleTimeRegistrationChange = (rowData, allRowsSelected, allObjectsSelected) => {
    // const trIds = allObjectsSelected.map(tr => tr.id);
    // const selectedInstitutions = this.state.institutions.filter(institution => institutionIds.includes(institution.id));
    this.setState({
      selectedTimeRegistrations: allObjectsSelected,
      selectedTimeRegistrationIds: allRowsSelected,
      selectedTimeRegistrationIndices: this.state.time_registrations.findIndex(object => object.id === allRowsSelected[0]),
    });
    // this.updateDepartments(allRowsSelected);
  };

  handleChange = (value, meta) => {
    console.log(value, meta);
    const target = value.target;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = target.value;
    this.setState({obj: newObj});
  }

  handleChange2 = (value, meta) => {
    console.log(value, meta);
    const newVal = value.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = newVal;
    this.setState({obj: newObj});
  }

  handleChangeMulti = (value, meta) => {
    // const newVal = value.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = (value) ? value.map(val => val.value) : [];
    this.setState({obj: newObj});
  }

  renderHours = (input, decimalSelected) => {
    let ret = null;
    const value = input;
    const dot = /\d*\.\d{0,2}/;
    const comma = /\d*\,{1}\d{0,2}/;
    const colon = /\d*\:\d{0,2}/;
    const num = /\d+/;
    let newHour = null;
    const hh = value.substring(0,value.length-2);
    const mm = value.substring(value.length-2, value.length);
    if (value.match(num) && value.match(num)[0] == value) {
      if (value.match(num)) {
        let hh = "";
        let mm = "";
        if (value.length > 2) {
          hh = value.substring(0,value.length-2);
          mm = value.substring(value.length-2, value.length);
        } else {
          hh = "0";
          mm = value.padStart(2, "0");
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + (parseFloat(mm)/60.0);
        }
      }
    }
    if (value.match(dot) && value.match(dot)[0] == value) {
      if (value.match(dot)) {
        const dotIndex = value.indexOf(".");
        let hh = value.substring(0,dotIndex);
        if (!hh) {
          hh = "0";
        }
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!mm) {
          mm = "00";
        } else {
          mm = mm.padEnd("0",2);
        }
        // console.log(hh,mm);
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          // console.log(parseFloat(mm)/0.6);
          newHour = parseFloat(hh) +  ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
        // console.log(newHour);
      }
    }
    if (value.match(comma) && value.match(comma)[0] == value) {
      if (value.match(comma)) {
        const dotIndex = value.indexOf(",");
        let hh = value.substring(0,dotIndex);
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!hh) {
          hh = "0";
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
      }
    }
    if (value.match(colon) && value.match(colon)[0] == value) {
      if (value.match(colon)) {
        const dotIndex = value.indexOf(":");
        let hh = value.substring(0,dotIndex);
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!hh) {
          hh = "0";
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
      }
    }
    ret = newHour;
    return ret;
  }

  handleChangeTime = (event) => {
    // console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    // const any = /\d+\:\d{2}|\d*\,{1}\d+|\d+\.\d+|\d+/;
    // const dot = /\d*\.\d{0,2}/;
    // const comma = /\d*\,{1}\d{0,2}/;
    // const colon = /\d*\:\d{0,2}/;
    // const num = /\d+/;
    // // console.log(value.match(dot), value.match(comma), value.match(colon), value.match(num), value.match(any));
    // // console.log(value.match(re), value, value.match(re)[0] == value);
    // let newHour = null;
    // const hh = value.substring(0,value.length-2);
    // const mm = value.substring(value.length-2, value.length);
    // // console.log(value.match(dot));
    // console.log(value )
    if (name == "time_str") {
      newObj["time_str"] = value;
    //   if (value.match(num) && value.match(num)[0] == value) {
    //     if (value.match(num)) {
    //       let hh = "";
    //       let mm = "";
    //       if (value.length > 2) {
    //         hh = value.substring(0,value.length-2);
    //         mm = value.substring(value.length-2, value.length);
    //       } else {
    //         hh = "0";
    //         mm = value.padStart(2, "0");
    //       }
    //       if (this.state.obj.decimalSelected) {
    //         newHour = parseFloat(hh + "." + mm);
    //       } else {
    //         newHour = parseFloat(hh) + (parseFloat(mm)/60.0);
    //       }
    //     }
    //   }
    //   if (value.match(dot) && value.match(dot)[0] == value) {
    //     if (value.match(dot)) {
    //       const dotIndex = value.indexOf(".");
    //       let hh = value.substring(0,dotIndex);
    //       if (!hh) {
    //         hh = "0";
    //       }
    //       let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
    //       if (!mm) {
    //         mm = "00";
    //       } else {
    //         mm = mm.padEnd("0",2);
    //         console.log(mm);
    //       }
    //       console.log(hh,mm);
    //       if (this.state.obj.decimalSelected) {
    //         newHour = parseFloat(hh + "." + mm);
    //       } else {
    //         console.log(parseFloat(mm)/0.6);
    //         newHour = parseFloat(hh) +  ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
    //       }
    //       console.log(newHour);
    //     }
    //   }
    //   if (value.match(comma) && value.match(comma)[0] == value) {
    //     if (value.match(comma)) {
    //       const dotIndex = value.indexOf(",");
    //       let hh = value.substring(0,dotIndex);
    //       let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
    //       if (!hh) {
    //         hh = "0";
    //       }
    //       if (this.state.obj.decimalSelected) {
    //         newHour = parseFloat(hh + "." + mm);
    //       } else {
    //         newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
    //       }
    //     }
    //   }
    //   if (value.match(colon) && value.match(colon)[0] == value) {
    //     if (value.match(colon)) {
    //       const dotIndex = value.indexOf(":");
    //       let hh = value.substring(0,dotIndex);
    //       let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
    //       if (!hh) {
    //         hh = "0";
    //       }
    //       if (this.state.obj.decimalSelected) {
    //         newHour = parseFloat(hh + "." + mm);
    //       } else {
    //         newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
    //       }
    //     }
    //   }
    }
    newObj["hours"] = this.renderHours(value, this.state.obj.decimalSelected);
    this.setState({ obj: newObj, time_str: value });
  }

  handlePrevDay = event => {
    // console.log(date, start_time);
    let newObj = {...this.state.obj};
    newObj["entry_date"] = newObj.entry_date.startOf("day").subtract(1, 'day').add(2, 'hour');
    this.setState({obj: newObj});
  };

  handleNextDay = event => {
    // console.log(date, start_time);
    let newObj = {...this.state.obj};
    newObj["entry_date"] = newObj.entry_date.startOf("day").add(1, 'day').add(2, 'hour');
    this.setState({obj: newObj});
  };

  handleDecimalSelectedChange = (event) => {
    let newObj = {...this.state.obj};
    if (newObj["decimalSelected"]) {
      newObj["decimalSelected"] = false;
    } else {
      newObj["decimalSelected"] = true;
    }
    if (this.state.obj.time_str) {
      // const dot = /\d*\.\d{0,2}/;
      // const comma = /\d*\,{1}\d{0,2}/;
      // const colon = /\d*\:\d{0,2}/;
      // const num = /\d+/;
      // let newHour = null;
      // const hh = value.substring(0,value.length-2);
      // const mm = value.substring(value.length-2, value.length);
      // if (this.state.obj.time_str.length > 2) {
      //   hh = this.state.obj.time_str.substring(0,this.state.obj.time_str.length-2);
      //   mm = this.state.obj.time_str.substring(this.state.obj.time_str.length-2, this.state.obj.time_str.length);
      // } else {
      //   hh = "0";
      //   mm = this.state.obj.time_str.padStart(2, "0");
      // }
      // // const hh = this.state.obj.time_str.substring(0,this.state.obj.time_str.length-2);
      // // const mm = this.state.obj.time_str.substring(this.state.obj.time_str.length-2, this.state.obj.time_str.length);
      // if (!this.state.obj.decimalSelected) {
      //   newHour = parseFloat(hh + "." + mm);
      // } else {
      //   newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
      // }
      newObj["hours"] = this.renderHours(this.state.obj.time_str, newObj["decimalSelected"]);
    }
    this.setState({ obj: newObj });

    // if (this.state.obj.decimalSelected) {
    //   this.setState({decimalSelected: false});
    // } else {
    //   this.setState({decimalSelected: true});
    // }
  }

  handleDetailsOpen = (event, obj) => {
    let newOpenDialogs = [].concat(this.state.openDialogs);
    newOpenDialogs["details"] = true;
    var allObjectsSelected = [obj];
    // console.log(allObjectsSelected);
    var selectedObjectIds = [obj.id];
    var selectedObjectIndices = this.state.time_registrations.findIndex(object => object.id === obj.id);
    this.setState({
      selectedTimeRegistrations: allObjectsSelected,
      selectedTimeRegistrationIds: selectedObjectIds,
      selectedTimeRegistrationIndices: selectedObjectIndices,
      openDialogs: newOpenDialogs,
    });
  }

  handleDetailsClose = (event, obj) => {
    let newOpenDialogs = [].concat(this.state.openDialogs);
    newOpenDialogs["details"] = false;
    this.setState({
      openDialogs: newOpenDialogs, selectedTimeRegistrations: [], selectedTimeRegistrationIds: []
    });
  }

  // handleEditTimeRegistrationSave = event => {
  //   let currentComponent = this;
  //   fetch('/time_registration/'+this.state.trial.id+'/update', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       time_registration_ids: this.state.selectedTimeRegistrationIds,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     if (data.response == 1) {
  //       currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
  //       this.handleEditTimeRegistrationClose();
  //     } else {
  //       currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  //   // this.updateSites
  // };

  handleTimeRegistrationReceived = message => {
    console.log("received Time Registration");
    console.log(message);
    if (message.trial_id == this.state.trial.id && message.new_time_registration) {
      let newTimeRegistrations = [].concat(this.state.time_registrations);
      newTimeRegistrations.push(message.new_time_registration);  
      this.setState({ time_registrations: newTimeRegistrations });
      this.props.enqueueSnackbar('Eine neue Zeit wurde erfasst.', {variant: 'success'});
    }
    if (message.trial_id == this.state.trial.id && message.time_registration) {
      let newTimeRegistrations = [].concat(this.state.time_registrations);
      const ind = newTimeRegistrations.findIndex(tr => tr.id == message.time_registration.id);  
      newTimeRegistrations[ind] = message.time_registration;
      this.setState({ time_registrations: newTimeRegistrations });
      this.props.enqueueSnackbar('Eine Zeit wurde geändert.', {variant: 'success'});
    }
    if (message.deleted_time_registration_ids) {
      let newTimeRegistrations = [].concat(this.state.time_registrations);
      newTimeRegistrations = newTimeRegistrations.filter(tr => !message.deleted_time_registration_ids.includes(tr.id))
      // newTimeRegistrtions = newTimeRegistrations.filter(tr => !message.deleted_triaL_ids.includes(tr.id));
      let newSelectedTimeRegistrations = [].concat(this.state.selectedTimeRegistrations);
      newSelectedTimeRegistrations = newSelectedTimeRegistrations.filter(str => !message.deleted_time_registration_ids.includes(str.id))
      const newSelectedTimeRegistrationIds = newSelectedTimeRegistrations.map(str => str.id);
      this.setState({time_registrations: newTimeRegistrations, selectedTimeRegistrations: newSelectedTimeRegistrations, selectedTimeRegistrationIds: newSelectedTimeRegistrationIds});
      this.props.enqueueSnackbar('Eine Zeit wurde gelöscht.', {variant: 'success'})
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  filterTimeRegistrations = trs => {
    let result = trs;
    if (this.state.obj.trialIds.length > 0) {
      result = result.filter(tr => this.state.obj.trialIds.includes(tr.trial_id));
    }
    if (this.state.obj.role_ids.length > 0) {
      result = result.filter(tr => this.state.obj.role_ids.includes(tr.role_id));
    }
    if (this.state.obj.service_ids.length > 0) {
      result = result.filter(tr => this.state.obj.service_ids.includes(tr.service_id));
    }
    if (this.state.obj.filter_start_date) {
      console.log((this.state.obj.filter_start_date));
      result = result.filter(tr => moment(this.state.obj.filter_start_date).isSameOrBefore(tr.entry_date));
    }
    if (this.state.obj.filter_end_date) {
      result = result.filter(tr => moment(this.state.obj.filter_end_date).isSameOrAfter(tr.entry_date));
    }
    return result;
  }

  timeBracketTimeRegistrations = (trs, rangeType) => {
    let result = [];
    let transformed = {};
    trs.forEach((tr, ind, arr) => {
      var range = ""
      const roleName = this.props.roles.find(role => role.id == tr.role_id).name;
      if (rangeType == "monthly") {
        range = tr.entry_date.substring(0,7);
      } else if (rangeType == "quarterly") {
        const month = parseInt(tr.entry_date.substring(5,7));
        if (month < 4) {
          range = tr.entry_date.substring(0,4) + "_Q1"
        } else if (month >=4 && month < 7) {
          range = tr.entry_date.substring(0,4) + "_Q2"
        } else if (month >=7 && month < 10) {
          range = tr.entry_date.substring(0,4) + "_Q3"
        } else if (month >= 10) {
          range = tr.entry_date.substring(0,4) + "_Q4"
        }
      } else if (rangeType == "yearly") {
        range = tr.entry_date.substring(0,4)
      } else if (rangeType == "weekly") {
        range = tr.entry_date.substring(0,4) + "_" + moment(tr.entry_date).isoWeek();
      } else if (rangeType == "all") {
        range = "Gesamt";
      }
      if (transformed[range]) {
        if (transformed[range][roleName + "_hours"]) {
          transformed[range][roleName + "_hours"] += parseFloat(tr.hours);
        } else {
          transformed[range][roleName + "_hours"] = parseFloat(tr.hours);
        }
      } else {
        transformed[range] = {};
        transformed[range][roleName + "_hours"] = parseFloat(tr.hours);
      }
    })
    Object.keys(transformed).forEach((ind) => {
      // const roleTimes = transformed[ind].map(() => {

      // })
      result.push({...transformed[ind], entryRange: ind})
    })
    return result;
  }

  serviceBracketTimeRegistrations = (trs, rangeType) => {
    let result = [];
    let transformed = {};
    trs.forEach((tr, ind, arr) => {
      const roleName = this.props.roles.find(role => role.id == tr.role_id).name + "_hours";
      var range = ""
      range = this.props.services.find(service => service.id == tr.service_id).name;
      if (transformed[range]) {
        if (transformed[range][roleName]) {
          transformed[range][roleName] += parseFloat(tr.hours);
        } else {
          transformed[range][roleName] = parseFloat(tr.hours);
        }
      } else {
        transformed[range] = {};
        transformed[range][roleName] = parseFloat(tr.hours);
      }
    })
    console.log(transformed);
    Object.keys(transformed).forEach((ind) => {  
      result.push({...transformed[ind], entryRange: ind})
    })
    // console.log(result);
    return result;
  }

  handleTimeRegistrationsFilterChange = (filters, meta) => {
    if ((meta.action == "select-option") || (meta.action == "remove-value")) {
      let newTimeRegistrationsFilters = {...this.state.timeRegistrationsFilters};
      newTimeRegistrationsFilters[meta["name"]] = filters;
      this.setState({timeRegistrationsFilters: newTimeRegistrationsFilters});
    } else if (meta.action == "clear") {

      let newTimeRegistrationsFilters = {...this.state.timeRegistrationsFilters};
      delete newTimeRegistrationsFilters[meta["name"]];
      this.setState({timeRegistrationsFilters: newTimeRegistrationsFilters});
    }
  }

  handleTimeRegistrationsFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({timeRegistrationsFilters: {}});
    }
  }

  filterServices = services => {
    let result = services;
    if (this.state.servicesFilters["roleNames"]) {
      const roleNames = this.state.servicesFilters["roleNames"].map(roleName => roleName.value);
      result = result.filter(service => roleNames.some(roleName => service.roleNames.split(",").includes(roleName)));
    }
    return result;
  }

  handleServicesFilterChange = (filters, meta) => {
    if ((meta.action == "select-option") || (meta.action == "remove-value")) {
      let newServicesFilters = {...this.state.servicesFilters};
      newServicesFilters[meta["name"]] = filters;
      this.setState({servicesFilters: newServicesFilters});
    } else if (meta.action == "clear") {
      let newServicesFilters = {...this.state.servicesFilters};
      delete newServicesFilters[meta["name"]];
      this.setState({servicesFilters: newServicesFilters});
    }
  }

  handleServicesFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({servicesFilters: {}});
    }
  }

  handleDownloadClick  = (columns, data, filename) => {
    let currentComponent = this;
    let ret = "";
    if (this.state.obj.statsType == "timespan") {
      ret = "Zeitspanne"
    } else if (this.state.obj.statsType == "service") {
      ret = "Leistungsart"
    }
    columns.forEach(column => {
      if (column.label != "Zeitspanne" && column.label != "Leistungsart") {
        ret += "\t" + column.label;
      }
    })
    ret += "\n";
    data.forEach(row => {
      ret += row["entryRange"]
      columns.forEach(column => {
        if (column.label != "Zeitspanne" && column.label != "Leistungsart") {
          const rounded = Math.round(row[column.id] * 100) / 100;
          const withComma = (rounded) ? rounded.toString().replace('.',',') : "0,0";
          ret += "\t" + withComma;
        }
      })
      ret += "\n";
    })
    // const data = to_json(this.state.selectedPeople);
    // console.log(ret);
    save(ret, filename);
  }

  render() {
    const { classes, roles, services, trial_services, sras } = this.props;
    const { openDialogs, trial, obj, time_registrations, user, selectedTimeRegistrations, selectedTimeRegistrationIds } = this.state;
    
    let timeRegistrationStandardColumns = []
    if (this.state.obj.statsType == "timespan") {
      timeRegistrationStandardColumns = [
        {
          id: "entryRange",
          // secondary: "Datum (s)",
          label: "Zeitspanne",
          numeric: false,
          disablePadding: false,
          type: "string",
        },
      ];
    } else if (this.state.obj.statsType == "service") {
      timeRegistrationStandardColumns = [
        {
          id: "entryRange",
          // secondary: "Datum (s)",
          label: "Leistungsart",
          numeric: false,
          disablePadding: false,
          type: "string",
        },
      ];
    }

    const seenRoleIds = new Set(time_registrations.map(tr => tr.role_id));
    const seenServiceIds = new Set(time_registrations.map(tr => tr.service_id));
    const roleOptions = roles.filter(role => seenRoleIds.has(role.id)).map(role => {
      return {value: role.id, label: role.name}
    });
    const serviceOptions = services.filter(service => seenServiceIds.has(service.id)).map(service => {
      return {value: service.id, label: service.name}
    });

    const rangeTypeOptions = [{value: "weekly", label: "Woche"}, {value: "monthly", label: "Monat"}, {value: "quarterly", label: "Quartal"}, {value: "yearly", label: "Jahr"}, {value: "all", label: "Gesamt"}];
    const statsTypeOptions = [{value: "timespan", label: "Zeitspanne"}, {value: "service", label: "Leistungsart"}];

    const fgvr = (time_registrations) ? time_registrations.map(tr => {
      const serviceName = this.props.services.find(service => service.id == tr.service_id).name;
      const roleName = this.props.roles.find(role => role.id == tr.role_id).name;
      return {...tr, serviceName: serviceName, roleName: roleName }
    }) : [];
    const filteredTimeRegistrations = this.filterTimeRegistrations(time_registrations);
    var rangedTimeRegistrations = [];
    var timeRegistrationColumns = [];
    const userRoleIds = this.state.user.roles.map(role => role.id);
    const leitungRole = this.state.user.roles.map(role => role.name).includes("Leitung");
    const showingRoleIds = Array.from(seenRoleIds).filter(roleId => (leitungRole || userRoleIds.includes(roleId)));
    if (this.state.obj.statsType == "timespan") {
      rangedTimeRegistrations = this.timeBracketTimeRegistrations(filteredTimeRegistrations, this.state.obj.rangeType);
      if (obj.role_ids.length > 0) {
        timeRegistrationColumns = timeRegistrationStandardColumns.concat(
          showingRoleIds.filter(srid => obj.role_ids.includes(srid)).map(srid => {
            const role = roles.find(role => role.id == srid)
            return ({
              id: role.name + "_hours",
              label: role.name,
              numeric: false,
              disablePadding: false,
              type: "decimaltime",
              precision: 2,
            })
          })
        )
      } else {
        timeRegistrationColumns = timeRegistrationStandardColumns.concat(
          showingRoleIds.map(srid => {
            const role = roles.find(role => role.id == srid)
            // if (this.state.user.roles.map(role => role.name).includes(role.name)) {
              return ({
                id: role.name + "_hours",
                label: role.name,
                numeric: false,
                disablePadding: false,
                type: "decimaltime",
                precision: 2,
              })
            // }
          })
        )
      }
    }
    if (this.state.obj.statsType == "service") {
      rangedTimeRegistrations = this.serviceBracketTimeRegistrations(filteredTimeRegistrations, this.state.obj.rangeType);
      if (obj.role_ids.length > 0) {
        timeRegistrationColumns = timeRegistrationStandardColumns.concat(
          showingRoleIds.filter(srid => obj.role_ids.includes(srid)).map(srid => {
            const role = roles.find(role => role.id == srid)
            return ({
              id: role.name + "_hours",
              label: role.name,
              numeric: false,
              disablePadding: false,
              type: "decimaltime",
              precision: 2,
            })
          })
        )
      } else {
        timeRegistrationColumns = timeRegistrationStandardColumns.concat(
          showingRoleIds.map(srid => {
            const role = roles.find(role => role.id == srid)
            return ({
              id: role.name + "_hours",
              label: role.name,
              numeric: false,
              disablePadding: false,
              type: "decimaltime",
              precision: 2,
            })
          })
        )
      }
    }
    console.log(rangedTimeRegistrations);
    let allTimes = {};
    rangedTimeRegistrations.forEach(tr => {
      for(var key in tr) {
        if (key.indexOf("_hours") != -1) {
          if (allTimes[key]) {
            allTimes[key] += tr[key];
          } else {
            allTimes[key] = tr[key];
          }
        }
      }
    })
    // console.log(allTimes);
    rangedTimeRegistrations.push({entryRange: "Gesamt", ...allTimes})
    // if (this.state.obj.statsType == "trial") {
    //   rangedTimeRegistrations = this.trialBracketTimeRegistrations(filteredTimeRegistrations, this.state.obj.rangeType);
      
    //   timeRegistrationColumns = timeRegistrationStandardColumns.concat(
    //     [...seenRoleIds].map(srid => {
    //       const role = roles.find(role => role.id == srid)
    //       return ({
    //         id: role.name + "_hours",
    //         label: role.name,
    //         numeric: false,
    //         disablePadding: false,
    //         type: "decimaltime",
    //         precision: 2,
    //       })
    //     })
    //   )
    // }

    const downloadName = "Zeiterfassung_" + moment().format('YYMMDD') + ".tsv";
    let selectedDetails = [];
    let detailsColumns = [];
    let timeRegistrationsDetails = [];
    if (this.state.selectedTimeRegistrations) {
      // console.log(this.state.selectedTimeRegistrations)
      if (this.state.obj.statsType == "timespan") {
        detailsColumns = [{
          id: "entryRange",
          // secondary: "Datum (s)",
          label: "Leistungsart",
          numeric: false,
          disablePadding: false,
          type: "string",
        }];
        detailsColumns = detailsColumns.concat(
          showingRoleIds.map(srid => {
            const role = roles.find(role => role.id == srid)
            return ({
              id: role.name + "_hours",
              label: role.name,
              numeric: false,
              disablePadding: false,
              type: "decimaltime",
              precision: 2,
            })
          })
        )
        if (this.state.selectedTimeRegistrations.length > 0) {
          const entryRange = this.state.selectedTimeRegistrations[0].entryRange;
          if (this.state.obj.rangeType == "weekly"){
            selectedDetails = this.state.time_registrations.filter(tr => tr.entry_date.substring(0,4) + "_" + moment(tr.entry_date).isoWeek() == entryRange);
          }
          if (this.state.obj.rangeType == "monthly"){
            selectedDetails = this.state.time_registrations.filter(tr => tr.entry_date.substring(0,7) == entryRange);
          }
          if (this.state.obj.rangeType == "quarterly"){
            // console.log(this.state.time_registrations.map(tr => tr.entry_date));
            selectedDetails = this.state.time_registrations.filter(tr => (tr.entry_date.substring(0,4) == entryRange.substring(0,4)) && (((tr.entry_date.substring(5,7) < 4) && (entryRange.substring(5,7) == "Q1")) || ((tr.entry_date.substring(5,7) < 4) && (entryRange.substring(5,7) == "Q2")) || ((tr.entry_date.substring(5,7) < 4) && (entryRange.substring(5,7) == "Q3")) || ((tr.entry_date.substring(5,7) < 4) && (entryRange.substring(5,7) == "Q4"))));
          }
          if (this.state.obj.rangeType == "yearly"){
            selectedDetails = this.state.time_registrations.filter(tr => tr.entry_date.substring(0,7) == entryRange);
          }
          if (this.state.obj.rangeType == "all"){
            selectedDetails = this.state.time_registrations;
          }
          timeRegistrationsDetails = this.serviceBracketTimeRegistrations(selectedDetails, "monthly");
        } else {
          // console.log("test");
          timeRegistrationsDetails = [];
        }
      } else if (this.state.obj.statsType == "service") {
        if (this.state.selectedTimeRegistrations.length > 0) {
          const serviceId = services.find(service => service.name == this.state.selectedTimeRegistrations[0].entryRange).id;
          selectedDetails = this.state.time_registrations.filter(tr => tr.service_id == serviceId);
          detailsColumns = [
            {
              id: "entryRange",
              // secondary: "Datum (s)",
              label: "Zeitspanne",
              numeric: false,
              disablePadding: false,
              type: "string",
            },
          ];
          detailsColumns = detailsColumns.concat(
            showingRoleIds.map(srid => {
              const role = roles.find(role => role.id == srid)
              return ({
                id: role.name + "_hours",
                label: role.name,
                numeric: false,
                disablePadding: false,
                type: "decimaltime",
                precision: 2,
              })
            })
          )
          timeRegistrationsDetails = this.timeBracketTimeRegistrations(selectedDetails, "monthly");
        } else {
          timeRegistrationsDetails = [];
        }
      }
    }
    // console.log(this.state.user.roles.map(role => role.name));
    return (
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "TimeRegistrationsChannel"}}
            onReceived={this.handleTimeRegistrationReceived}
            onConnected={this.handleConnected}
          />
          { (!trial) &&
          <Grid item xs={12} >
            <Typography style={{color: 'red', fontSize: '20px'}}>
              Hinweis: Durch Doppelklick können Details zu einzelnen Einträgen angezeigt werden.
            </Typography>
          </Grid>}
          { (this.state.openDialogs.details) &&
          <TimeRegistrationStatsDialog
            open={this.state.openDialogs.details}
            timeRegistrations={timeRegistrationsDetails}
            handleClose={this.handleDetailsClose}
            detailsColumns={detailsColumns}
          />}

          <Grid item xs={12} >
            <TimeRegistrationFilterFields
              obj={obj}
              trials={this.props.trials}
              handleChange={this.handleChange}
              handleChange2={this.handleChange2}
              handleChangeMulti={this.handleChangeMulti}
              handleChangeTime={this.handleChangeTime}
              handleChangeDate={this.handleChangeDate}
              handlePrevDay={this.handlePrevDay}
              handleNextDay={this.handleNextDay}
              // handleSave={(this.props.obj) ? this.handleEditSave : this.handleSave}
              roleOptions={roleOptions}
              serviceOptions={serviceOptions}
              statsTypeOptions={statsTypeOptions}
              rangeTypeOptions={rangeTypeOptions}
              handleDecimalSelectedChange={this.handleDecimalSelectedChange}
              // decimalSelected={decimalSelected}
            />
          </Grid>
          
          <Grid item xs={12} >
            <EnhancedTable
              title={"Zeiterfassungsdaten"}
              data={rangedTimeRegistrations}
              columns={timeRegistrationColumns}
              // handleFilterClick={this.handleTimeRegistrationsFilterClick}
              // filters={this.state.timeRegistrationsFilters}
              // onFilterChange={this.handleTimeRegistrationsFilterChange}
              singleSelect={true}
              selectedIds={(selectedTimeRegistrationIds) ? selectedTimeRegistrationIds : []}
              selectedObjects={[selectedTimeRegistrations]}
              // onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleTimeRegistrationChange(rowData, allRowsSelected, allObjectsSelected)}
              // handleAddClick={this.handleNewProcessOpen}
              // handleDeleteClick={this.handleDeleteTimeRegistrationClick}
              rowsPerPage={20}
              orderBy="created_at"
              order="desc"
              onDoubleClick={this.handleDetailsOpen}
              allData={rangedTimeRegistrations}
              handleDownloadClick={() => this.handleDownloadClick(timeRegistrationColumns, rangedTimeRegistrations, downloadName)}
              selectable={false}
              expanded={true}
              objectType="TimeRegistration"
              noAddIcon={true}
              alwaysDownloadable
            />
          </Grid>
        </Grid>

    )
  }
}

TimeRegistrationStats.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(TimeRegistrationStats));
