import React from 'react';
import CreatableSelect from 'react-select/creatable';

class PersonSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {people: [], peopleOptions: [], newPersonOpen: false, inputValue: this.props.inputValue}
    this.form = React.createRef();
  }

  componentDidMount(props) {
    let currentComponent = this;
    if (this.props.departmentId != null) {
      fetch('/trials/'+this.props.trialId+'/site_selection/get_department_people?'+$.param({department_id: this.props.departmentId}), {
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        const people = data.department_people;
        const peopleOptions = people.map((person) => {
          return (
            {value: person.id, label: (person.last_name + ", " + person.first_name + " (" + (person.email || "Keine Email hinterlegt") + ")")}
          )
        })
        currentComponent.setState({ peopleOptions: peopleOptions});
      });
    }
  }

  formatCreate = (inputValue) => {
    return (<p> Global suchen: {inputValue}</p>);
  };

  showAdd = (inputValue, selectValue, selectOptions, props) => {
    if (selectOptions.length == 0){
      return true;
    } else {
      return false;
    }
  };

  test = (inputValue) => {
    console.log("onCreateOption");
    console.log(inputValue);
  };

  render(props) {
    if (this.state.peopleOptions) {
      return (
        <CreatableSelect
          value={this.props.selectedPerson}
          onChange={this.props.handleChange}
          options={this.state.peopleOptions}
          onCreateOption={this.props.onCreateOption}
          formatCreateLabel={this.formatCreate}
          inputValue={this.props.inputValue}
          onInputChange={(newValue, actionMeta) => this.props.handleInputValueChange(newValue, actionMeta)}
          autoFocus={this.props.autoFocus}
          defaultMenuIsOpen={true}
          onBlur={null}
          // isValidNewOption={this.showAdd}
        />
      )
    }
    else {
      return null;
    }
  }
}

export default PersonSelector;
