import React from 'react';
import CreatableSelect from 'react-select/creatable';
import chroma from 'chroma-js';

const standardFunctionOptions = ["LKP", "Prüfer", "Stellvertreter", "SP", "äMP", "näMP", "SN", "CTA", "Studienkoordinator", "PM", "BM", "DM"];
const createOption = (label) => ({
  label,
  value: label,
  backgroundColor: 'white',
});
const standardOptions = {label: "StandardRollen", options: standardFunctionOptions.map(option => createOption(option))};

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    let color = chroma('black');
    if (data.color) {
      color = chroma(data.color);
    }
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

class SitePersonFunctionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { functionOptions: [standardOptions]  }
    this.form = React.createRef();
  }

  componentDidMount(props) {
    let currentComponent = this;
    fetch('/site_selection/get_site_roles', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      const newFunctionOptions = {label: "Spezialrollen", options: data.functions.filter(f => !standardFunctionOptions.includes(f)).map(option => createOption(option))};
      // console.log(data.functions);
      // currentComponent.setState({ functionOptions: peopleOptions});
      currentComponent.setState({ functionOptions: [standardOptions, newFunctionOptions]});
    });
  }

  formatCreate = (inputValue) => {
    return (<p> Neue Spezialfunktion hinzufügen: {inputValue}</p>);
  };

  showAdd = (inputValue, selectValue, selectOptions, props) => {
    if (selectOptions.length == 0){
      return true;
    } else {
      return false;
    }
  };

  test = (inputValue) => {
    console.log("onCreateOption");
    console.log(inputValue);
  };

  onCreateOption = event => {
    // console.log("create new option");
    let newSpecialFunctionsOptions = [].concat(this.state.functionOptions)
    newSpecialFunctionsOptions[1]["options"] = [...newSpecialFunctionsOptions[1]["options"], createOption(event)];
    const newFunctionOptions = [standardOptions, newSpecialFunctionsOptions]
    // console.log(newSpecialFunctionsOptions[1]["options"]);
    this.props.handleChange(createOption(event));
    this.setState({functionOptions: newFunctionOptions});
  }

  render(props) {
    if (this.state.functionOptions) {
      return (
        <CreatableSelect
          value={this.props.selectedFunction}
          onChange={this.props.handleChange}
          options={this.state.functionOptions}
          onCreateOption={this.onCreateOption}
          formatCreateLabel={this.formatCreate}
          styles={colourStyles}
          // inputValue={this.props.inputValue}
          // onInputChange={(newValue, actionMeta) => this.props.handleInputValueChange(newValue, actionMeta)}
          autoFocus={this.props.autoFocus}
          // defaultMenuIsOpen={true}
          // onBlur={null}
          // isValidNewOption={this.showAdd}
        />
      )
    }
    else {
      return null;
    }
  }
}

export default SitePersonFunctionSelector;
