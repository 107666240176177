import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

class SiteSelector extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {sites: []}
    this.form = React.createRef();
  }

  render(props) {
    return (
      <TextField
        select
        value={this.props.selectedSite}
        margin="dense"
        id="selectedInstitution"
        label="Zentrum"
        onChange={this.props.handleChange}
        fullWidth
        variant="outlined"
        type="string"
      >
      {this.props.sites.map(site => (
        <MenuItem key={site.id} value={site}>
          {site.inst_description}
        </MenuItem>
      ))}
      </TextField>
    )
  }
}

export default SiteSelector;
