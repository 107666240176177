import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EnhancedTable from './EnhancedTable.jsx';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import ContractFields from './ContractFields.jsx';

export default function TimeRegistrationStatsDialog (props) {
  

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{style: {height: '1000px'}}}
    >
      {/* <DialogTitle id="form-dialog-title">Details</DialogTitle> */}
      <DialogContent>
        <EnhancedTable
          title={"Details"}
          data={props.timeRegistrations}
          columns={props.detailsColumns}
          singleSelect={true}
          rowsPerPage={50}
          orderBy="entryRange"
          order="asc"
          // onDoubleClick={this.handleDetailsOpen}
          allData={props.timeRegistrations}
          // handleDownloadClick={() => this.handleDownloadClick(timeRegistrationColumns, rangedTimeRegistrations, downloadName)}
          selectable={false}
          expanded={true}
          objectType="TimeRegistration"
          noAddIcon={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
