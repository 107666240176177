import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import SiteSelector from './SiteSelector.jsx'

class EditDepartmentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, costCenter: this.props.selectedDepartment.cost_center, name: this.props.selectedDepartment.name, email: this.props.selectedDepartment.email, street: this.props.selectedDepartment.street, zip: this.props.selectedDepartment.zip, city: this.props.selectedDepartment.city, country: this.props.selectedDepartment.country, phone_number: this.props.selectedDepartment.phone_number, fax_number: this.props.selectedDepartment.fax_number, website: this.props.selectedDepartment.website, comment: this.props.selectedDepartment.comment};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDepartment.id !== prevProps.selectedDepartment.id) {
      this.setState({ costCenter: this.props.selectedDepartment.cost_center, name: this.props.selectedDepartment.name, email: this.props.selectedDepartment.email, street: this.props.selectedDepartment.street, zip: this.props.selectedDepartment.zip, city: this.props.selectedDepartment.city, country: this.props.selectedDepartment.country, phone_number: this.props.selectedDepartment.phone_number, fax_number: this.props.selectedDepartment.fax_number, website: this.props.selectedDepartment.website, comment: this.props.selectedDepartment.comment});
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleClose = event => {
    this.setState({costCenter: this.props.selectedDepartment.cost_center, name: this.props.selectedDepartment.name, email: this.props.selectedDepartment.email, street: this.props.selectedDepartment.street, zip: this.props.selectedDepartment.zip, city: this.props.selectedDepartment.city, country: this.props.selectedDepartment.country, phone_number: this.props.selectedDepartment.phone_number, fax_number: this.props.selectedDepartment.fax_number, website: this.props.selectedDepartment.website, comment: this.props.selectedDepartment.comment});
    this.props.handleClose();
  }

  render(props) {
    const onlyNumber = /\d+/;
    const onlyNumberMatch = (this.state.zip && this.state.zip.toString().match(onlyNumber)) ? this.state.zip.toString().match(onlyNumber)[0] == this.state.zip : false;
    const zipIsOnlyNumber = (this.state.zip == null || this.state.zip == "" || onlyNumberMatch);
    const saveable = this.state.name && zipIsOnlyNumber;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Abteilung editieren</DialogTitle>
        <DialogContent >
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: green[500]}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">

                <Typography variant="h8" color="inherit" noWrap style={{marginLeft: 5}}>
                  Adressbuch
                </Typography>
              </Box>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Name"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.name}
                  error={!this.state.name}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="street"
                  label="Straße und Hausnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.street}
                  // error={!this.state.street}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="zip"
                  label="Postleitzahl"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.zip}
                  error={!zipIsOnlyNumber}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="city"
                  label="Stadt"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.city}
                  // error={!this.state.city}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="country"
                  label="Land"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.country}
                  // error={!this.state.country}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="E-Mail"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.email}
                  // error={!this.state.email}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="phone_number"
                  label="Telefonnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.phone_number}
                  // error={!this.state.country}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="fax_number"
                  label="Faxnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.fax_number}
                  // error={!this.state.country}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="website"
                  label="Webseite"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.website}
                  // error={!this.state.country}
                  type="string"
                />
                <TextField
                  id="comment"
                  label="Kommentar"
                  onChange={this.handleChange}
                  multiline
                  // className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={this.state.comment}
                  fullWidth
                  style={{zIndex: 1}}
                  type="text"
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={!saveable}
            onClick={() => this.props.handleSave({id: this.props.selectedDepartment.id, costCenter: this.state.costCenter, name: this.state.name, email: this.state.email, street: this.state.street, city: this.state.city, zip: this.state.zip, country: this.state.country, phone_number: this.state.phone_number, fax_number: this.state.fax_number, website: this.state.website, comment: this.state.comment })} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditDepartmentDialog;
