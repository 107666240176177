import React from 'react';
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import SarcPatientFields from './SarcPatientFields.jsx';
import { Toolbar, Box, Grid, Typography, IconButton, Tooltip, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from "./Can.js";
import SarcPatientSelect from './SarcPatientSelect.jsx';
import SarcbobFileKindFields from './SarcbobFileKindFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import ViewDialog from './ViewDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';

const datapointColumns = [
  {
   id: "name",
   label: "Typ",
   numeric: false,
   disablePadding: false,
   searchable: true,
  },
  {
    id: "shownKeyInExport",
    label: "Parameter",
    numeric: false,
    disablePadding: false,
    searchable: false,
  },
  {
    id: "content",
    label: "Datenpunkt",
    numeric: false,
    disablePadding: false,
    searchable: true,
  }
]

const shownEntryColumns = [
  {
    id: "nameInExport",
    label: "Parameter"
  },
  {
    id: "content",
    label: "Wert"
  }
]

function Border(props) {
  return (
    <Paper style={{ marginTop: '10px', borderStyle: "solid", width: '100%', borderWidth: "1px" }}>
      {props.children}
    </Paper>
  );
}

class SarcPatientOverview extends React.Component {

  constructor(props) {
    super(props);
    let user = this.props.user;


    this.state = {
      patient: this.props.patient,
      openDialogs: { editPatient: false, newEntryType: false },
      expandedTable: { patient: true, data: true, selected_data: true },
      user: user,
      shownEntries: []
    };
  

  }

  

  componentDidMount(props) {
    let currentComponent = this;
    fetch('/sarcbob_file_kind', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({sarcbobFileKind: data.sarcbob_file_kind});
    });
    fetch('/institutions/index_all', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({institutions: data.institutions});
    });
  }

  // Dialogs Open and Close
  handleOpen = (name) => (event) => {
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs[name] = true;
    this.setState({ openDialogs: newOpenDialogs });
    console.log(this.state);
  }
  handleClose = (name) => (event) => {
    console.log("here we are");
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs[name] = false;
    this.setState({ openDialogs: newOpenDialogs });
  }
  handlePatientSave = (patient) => {
    console.log(patient)
    let currentComponent = this;
    fetch('/sarcbop_patient_data/' + patient.id + '/update_sarc_patient', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        sarc_patient: patient,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log("uiae", data);
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Update erfolgreich.', {variant: 'success'});
        // currentComponent.setState({patient: data.patient});
        let newOpenDialogs = { ... currentComponent.state.openDialogs };
        newOpenDialogs['editPatient'] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs });
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }
  handleExpandChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({ expandedTable: newExpandState });
  }
  handleDatapointChange = (rowData, allRowsSelected, allObjectsSelected) => {
    var shownEntries = [];

    if (allObjectsSelected.length) {
      const data = this.props.patient[allObjectsSelected[0].table_name].find(x => x.id === allObjectsSelected[0].objId);
      console.log(this.props.patient[allObjectsSelected[0].table_name], allObjectsSelected[0].objId);
      const filekindId = this.props.filekind_tables.find(x => x.table_name === allObjectsSelected[0].table_name).id;
      const bijections = this.props.bijections.filter(x => x.sarcbob_file_kind_id === filekindId);

      for (var element in data) {
        console.log(element);
        var neededBijection = bijections.find(x => x.name_in_db === element);
        if (neededBijection !== undefined) {
          shownEntries.push({
            nameInDb: element,
            nameInExport: neededBijection.name_in_export,
            content: data[element]
          })
        }
      }
    }

    this.setState({
      // selectedDatapoints: allObjectsSelected,
      selectedDatapointIds: allRowsSelected,
      shownEntries: shownEntries,
    });
  };

  handleAddClick = (obj) => {
    const currentComponent = this;

    fetch('/sarcbob_patient_data/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        obj: obj,
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      if (handledData.response == 1) {
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        // currentComponent.props.handleFileUpload(handledData);
        var patients = [... currentComponent.state.patients];
        patients.push(handledData.new_patient);
        currentComponent.setState({patients});
        currentComponent.handleClose("editPatient");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    })

  }

  handleSarcPatientReceived = message => {
    console.log("received Sarc Patient");
    console.log(message);
    if (message.sarc_patient) {
      if ((this.state.patient.id == message.sarc_patient.id)) {
        this.setState({ patient: message.sarc_patient });
        this.props.enqueueSnackbar('Der Patient wurde von Benutzer ' + message.username + ' editiert.', {variant: 'success'});
      }
    }
    // if (message.deleted_process_instance_ids) {
    //   let newTrial = {... this.state.trial};
    //   let deletedProcessNames = [];
    //   this.state.trial.process_dummies.forEach((process_dummy, ind) => {
    //     const newProcesses = process_dummy.process_instances.filter(pi => (!message.deleted_process_instance_ids.includes(pi.id)));
    //     deletedProcessNames = deletedProcessNames.concat(process_dummy.process_instances.filter(pi => (message.deleted_process_instance_ids.includes(pi.id))).map(pi => pi.name));
    //     newTrial.process_dummies[ind]["process_instances"] = newProcesses;
    //   })
    //   // newTrial.process_dummies.find(pd => pd.name == message.process_instance.process_category).process_instances = newTrial.process_dummies.find(pd => pd.name == message.process_instance.process_category).process_instances.filter(pi => (!message.deleted_process_instance_ids.includes(pi.id)));
    //   if (message.deleted_process_instance_ids.includes(this.state.selectedProcess.id)) {
    //     this.setState({selectedProcess: null});
    //   }
    //   this.setState({ trial: newTrial });
    //   this.props.enqueueSnackbar('Ein Prozess wurde von Benutzer ' + message.username + ' gelöscht: ' + deletedProcessNames[0], {variant: 'success'});
    // }
  }

  render() {
    var id = 0;
    var datapoints = [];
    for (var i = 0; i < this.props.filekind_tables.length; ++i) {
      var shownKey = this.props.bijections.filter(x => x.sarcbob_file_kind_id == this.props.filekind_tables[i].id);
      shownKey = shownKey.find(x => x.name_in_db == this.props.filekind_tables[i].shown_key);
      if (shownKey !== undefined) {
        const table_name = this.props.filekind_tables[i].table_name;
        const shownKeyInDb = shownKey.name_in_db;
        shownKey = shownKey.name_in_export;

        try {
          if (this.state.patient[table_name].length > 0) {
            const name = this.props.filekind_tables.find(x => x.table_name == table_name).name;
            for (var element in this.state.patient[table_name]) {
              datapoints.push({
                id: id,
                objId: this.state.patient[table_name][element].id,
                table_name: table_name,
                name: name,
                shownKeyInExport: shownKey,
                content: this.state.patient[table_name][element][shownKeyInDb]
              })
              id++;
            }
          }
        } catch {
          console.log("bar",table_name);
        }
      }
    }

    var filekindTableOptions = (this.state.sarcbobFileKind) ? this.state.sarcbobFileKind.map(filekindTable => {
      return {label: filekindTable.name, value: filekindTable.id}
    }) : null;
    filekindTableOptions = (filekindTableOptions) ? filekindTableOptions.filter(
      x => x.label != "Grundlegende Patientendaten"
    ) : null;

    var institutionOptions = (this.state.institutions) ? this.state.institutions.map(institution => {
      return {label: institution.name, value: institution.id}
    }) : null;

    var diagnosisOptions = (this.props.patient.sarc_diagnoses) ? this.props.patient.sarc_diagnoses.map(diagnosis => {
      return {label: diagnosis.tumorid + " - " + diagnosis.icd_o3_histology_text, value: diagnosis.id}
    }) : null;
    console.log(diagnosisOptions);

    const selectedDatapoints = (this.state.selectedDatapointIds) ? datapoints.filter(dp => this.state.selectedDatapointIds.includes(dp.id)) : [];

    const requiredPatientFields = ["pseudonym", "gender"]

    return (
      <Grid container direction="row" justify="center" alignItems="top">
        <ActionCableConsumer
          // channel={{channel: "SarcPatientsChannel" + this.state.patient.id}}
          channel={{channel: "SarcPatientsChannel", patient_id: this.state.patient.id}}
          onReceived={this.handleSarcPatientReceived}
          // onConnected={this.handleConnected}
        />
        {/* {console.log(this.state.patient.primary_sarcdiagnosisid.tumorid)} */}
        {/* Patient */}
        <Grid item xs={12}>
          <Border>
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
              <Typography variant="h6" id="tableTitle" onClick={() => this.handleExpandChange(!this.state.expandedTable.patient, 'patient')}>
                {this.state.expandedTable.patient ? <ExpandMore /> : <ExpandLess />} {'Patient'} {this.state.patient.pseudonym}
              </Typography>
              <SarcPatientSelect selectedPatientId={parseInt(this.state.patient.id)} controller={this.props.controller} action={this.props.action} style={{ zIndex: 999 }}></SarcPatientSelect>
              <Toolbar variant='dense'>
                <Can I="update" a="SarcPatient">
                  <Tooltip title="Patient bearbeiten">
                    <IconButton aria-label="Edit" onClick={this.handleOpen('editPatient')} data-testid="Edit">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title="Daten hinzufügen">
                    <IconButton aria-label="Add Data" data-testid="Add Data">
                      <AddIcon />
                    </IconButton>
                  </Tooltip> */}
                </Can>
              </Toolbar>
            </Box>
            <Collapse in={this.state.expandedTable.patient} timeout="auto" unmountOnExit>
              <SarcPatientFields
                selectOptions={{institutionOptions: institutionOptions || [], diagnosisOptions: diagnosisOptions || []}}
                obj={this.state.patient}
                readOnly={true}
                requiredFields={requiredPatientFields}
              />
            </Collapse>
          </Border>
        </Grid>
        {/* Data */}
        <Grid item xs={7}>
          <Border>
            {/* <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
              <Typography variant="h6" id="tableTitle" onClick={() => this.handleExpandChange(!this.state.expandedTable.data, 'data')}>
                {this.state.expandedTable.data ? <ExpandMore /> : <ExpandLess />} {'Datenpunkte'}
              </Typography>
            </Box> */}
            <Collapse in={this.state.expandedTable.data} timeout="auto" unmountOnExit>
              {/* <div>Datenpunkte</div> */}
              <EnhancedTable
                title={"Datenpunkte"}
                data={datapoints}
                // singleSelect={!this.state.multipleSelectMode}
                // multipleSelectable
                selectedIds={this.state.selectedDatapointIds}
                selectedObjects={selectedDatapoints}
                columns={datapointColumns}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleDatapointChange(rowData, allRowsSelected, allObjectsSelected)}
                // handleMailTo={this.handleMailToDepartments}
                // handleAddClick={handleAddDepartmentOpen}
                // handleEditClick={this.handleEditDepartmentClick}
                // // handleRemoveClick={handleRemoveDepartmentClick}
                // handleDeleteClick={this.handleDeleteDepartmentClick}
                // handleFilterClick={this.handleDepartmentsFilterClick}
                // filters={this.state.departmentFilters}
                // onFilterChange={this.handleDepartmentsFilterChange}
                rowsPerPage={(datapoints.length >= 25) ? 25 : datapoints.length}
                // onDoubleClick={this.handleEditPatientOpen}
                // handleViewDiagnosis={this.handleViewDiagnosis}
                // handleViewPTnm={this.handleViewPTnm}
                // handleViewStadium={this.handleViewStadium}
                // handleViewOperation={this.handleViewOperation}
                // handleViewSystemicTherapy={this.handleViewSystemicTherapy}
                // handleViewMiscellaneousTherapy={this.handleViewMiscellaneousTherapy}
                // handleViewRadiotherapy={this.handleViewRadiotherapy}
                // handleViewNuclearTherapy={this.handleViewNuclearTherapy}
                // handleViewProgression={this.handleViewProgression}
                // handleViewVitalStatus={this.handleViewVitalStatus}
                // handleViewSocio={this.handleViewSocio}
                // handleViewPRO={this.handleViewPRO}
                // markedIds={this.state.selectedPeopleDepartmentsIds}
                orderBy="content"
                order="desc"
                selectable
                autoFill
                expanded
                // handleExpandChange={this.handleExpandChange}
                // handleUploadClick={this.handleOpen("fileUpload")}
                handleAddClick={this.handleOpen("newEntryType")}
                objectType="SarcPatients"
                // handleParentSelectClick={this.handleDepartmentParentSelectClick}
              />
            </Collapse>
          </Border>
        </Grid>
        {/* Selected Data */}
        <Grid item xs={5}>
          <Border>
            {/* <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
              <Typography variant="h6" id="tableTitle" onClick={() => this.handleExpandChange(!this.state.expandedTable.selected_data, 'selected_data')}>
                {this.state.expandedTable.selected_data ? <ExpandMore /> : <ExpandLess />} {'this.state.selected_data'}
              </Typography>
            </Box> */}
            <Collapse in={this.state.expandedTable.selected_data} timeout="auto" unmountOnExit>
              {/* <div>Ausgewählte Daten</div> */}
              {/* {(this.state.shownEntries.length ? */}
              {/* {(42 ? */}
                <EnhancedTable
                title={"Ausgewählte Daten"}
                data={this.state.shownEntries}
                // singleSelect={!this.state.multipleSelectMode}
                // multipleSelectable
                // selectedIds={this.state.selectedDatapointIds}
                // selectedObjects={this.state.selectedDatapoints}
                columns={shownEntryColumns}
                // onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleDatapointChange(rowData, allRowsSelected, allObjectsSelected)}
                // handleMailTo={this.handleMailToDepartments}
                // handleAddClick={handleAddDepartmentOpen}
                // handleEditClick={this.handleEditDepartmentClick}
                // // handleRemoveClick={handleRemoveDepartmentClick}
                // handleDeleteClick={this.handleDeleteDepartmentClick}
                // handleFilterClick={this.handleDepartmentsFilterClick}
                // filters={this.state.departmentFilters}
                // onFilterChange={this.handleDepartmentsFilterChange}
                rowsPerPage={25}
                // onDoubleClick={this.handleEditPatientOpen}
                // handleViewDiagnosis={this.handleViewDiagnosis}
                // handleViewPTnm={this.handleViewPTnm}
                // handleViewStadium={this.handleViewStadium}
                // handleViewOperation={this.handleViewOperation}
                // handleViewSystemicTherapy={this.handleViewSystemicTherapy}
                // handleViewMiscellaneousTherapy={this.handleViewMiscellaneousTherapy}
                // handleViewRadiotherapy={this.handleViewRadiotherapy}
                // handleViewNuclearTherapy={this.handleViewNuclearTherapy}
                // handleViewProgression={this.handleViewProgression}
                // handleViewVitalStatus={this.handleViewVitalStatus}
                // handleViewSocio={this.handleViewSocio}
                // handleViewPRO={this.handleViewPRO}
                // markedIds={this.state.selectedPeopleDepartmentsIds}
                // orderBy="pseudonym"
                // order="asc"
                // selectable
                autoFill
                expanded
                // handleExpandChange={this.handleExpandChange}
                // handleUploadClick={this.handleOpen("fileUpload")}
                // handleAddClick={this.handleOpen("addPatient")}
                // objectType="SarcPatients"
                // handleParentSelectClick={this.handleDepartmentParentSelectClick}
              />
                {/* : ""
              )} */}
            </Collapse>
          </Border>
        </Grid>
        {/* Dialogs */}
        {(this.state.openDialogs.editPatient) ?
          <EditDialog
            title="Patient editieren"
            obj={this.state.patient}
            open={this.state.openDialogs.editPatient}
            handleSave={this.handlePatientSave}
            handleClose={this.handleClose('editPatient')}
            handleChange={this.handleChange}
            Fields={SarcPatientFields}
            selectOptions={{institutionOptions: institutionOptions || [], diagnosisOptions: diagnosisOptions || []}}
            requiredFields={requiredPatientFields}
          />
          : null}
        {this.state.openDialogs.newEntryType ?
          <NewDialog
            title="Datenpunkttyp auswählen"
            handleClose={this.handleClose("newEntryType")}
            open={this.state.openDialogs.newEntryType}
            obj={{sarcbobFileKindId: 1}}
            Fields={SarcbobFileKindFields}
            selectOptions={dataPointSelectOptions}
          />  
          : null
        }
      </Grid >

    )
  }
}
export default withTheme(withSnackbar(SarcPatientOverview));