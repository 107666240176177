import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { withSnackbar } from 'notistack';

class PersonGlobalSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {people: [], peopleOptions: [], newPersonOpen: false, inputValue: this.props.inputValue}
    this.form = React.createRef();
  }

  componentDidMount(props) {
    let currentComponent = this;
    // if (currentComponent.props.selectedPerson) {
    //   currentComponent.setState({selectedPerson: {value: currentComponent.props.selectedPerson.id}});
    // }
    if (currentComponent.props.people && currentComponent.props.people.length > 0) {
      // console.log(currentComponent.props.people);
      let peopleOptions = currentComponent.props.people.map(person => {return {value: person.id, label: (person.last_name + ", " + person.first_name + " (" + (person.email || "Keine Email hinterlegt") + ")")}});
      currentComponent.setState({ people: currentComponent.props.people, peopleOptions: peopleOptions});
    } else {
      fetch('/people', {
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        const people = data.people;
        const peopleOptions = people.map((person) => {
          return (
            {value: person.id, label: (person.last_name + ", " + person.first_name + " (" + person.email + ")")}
          )
        })
        currentComponent.setState({ people: people, peopleOptions: peopleOptions});
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.people) {
      if (this.props.people != prevProps.people) {
        let peopleOptions = this.props.people.map(person => {return {value: person.id, label: (person.last_name + ", " + person.first_name + " (" + (person.email || "Keine Email hinterlegt") + ")")}});
        this.setState({ people: this.props.people, peopleOptions: peopleOptions});
      }
    }
    if (this.state.people) {
      if (this.state.people != prevState.people) {
        let peopleOptions = this.state.people.map(person => {return {value: person.id, label: (person.last_name + ", " + person.first_name + " (" + (person.email || "Keine Email hinterlegt") + ")")}});
        this.setState({ peopleOptions: peopleOptions});
      }
    }
    // if (this.props.selectedPerson != prevProps.selectedPerson) {
    //
    // }
  }

  formatCreate = (inputValue) => {
    if (this.props.onCreateOption) {
      return (<p> Neu anlegen: {inputValue}</p>);
    } else {
      return (<p> Adressbuch öffnen </p>);
    }
  };

  onCreateOption = event => {
    if (this.props.onCreateOption) {
      this.props.onCreateOption(event);
    } else {
      window.open("/address_book");
    }
  }

  showAdd = (inputValue, selectValue, selectOptions, props) => {
    if (selectOptions.length == 0){
      return true;
    } else {
      return false;
    }
  };

  handlePeopleReceived = message => {
    console.log("received Person");
    console.log(message);
    if (message.new_person) {
      let newPeople = [].concat(this.state.people);
      newPeople.push(message.new_person);
      this.setState({ people: newPeople });
      this.props.enqueueSnackbar('Eine neue Person wurde von Benutzer ' + message.username + ' hinzugefügt: ' + message.full_name, {variant: 'success'});
    }

  }

  render(props) {
    // console.log("Personselector test");
    // console.log(this.props.inputValue);
    if (this.state.peopleOptions) {
      return (
        <>
          <ActionCableConsumer
            channel={{channel: "PeopleChannel"}}
            onReceived={this.handlePeopleReceived}
            onConnected={this.handleConnected}
          />
          <CreatableSelect
            value={this.props.selectedPerson}
            onChange={this.props.handleChange}
            options={this.state.peopleOptions}
            onCreateOption={this.onCreateOption}
            formatCreateLabel={this.formatCreate}
            inputValue={this.props.inputValue}
            onInputChange={(newValue, actionMeta) => this.props.handleInputValueChange(newValue, actionMeta)}
            autoFocus={this.props.autoFocus}
            defaultMenuIsOpen={true}
            onBlur={null}
            // isValidNewOption={this.showAdd}
          />
        </>
      )
    }
    else {
      return null;
    }
  }
}

export default withSnackbar(PersonGlobalSelector);
