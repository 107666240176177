import React from 'react';
import classNames from 'classnames';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import TextField from './TextField.jsx';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import Save from '@mui/icons-material/Save';
import AddIcon from "@mui/icons-material/Add";
import LockOpen from '@mui/icons-material/LockOpen';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
// import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import Moment from 'moment';

// import TrialBoardSubmission from './TrialBoardSubmission.jsx';
import TrialBoardFileUploadDialog from './TrialBoardFileUploadDialog.jsx';
import ListOfFiles from './ListOfFiles.jsx';
import SubmissionResolutionList from './SubmissionResolutionList.jsx';
import PersonGlobalSelector from './PersonGlobalSelector.jsx'

// const styles = theme => ({

// });

class ShowTrialBoardSubmission extends React.Component {
  constructor(props) {
    super(props);
    const selectedPotentialPisIds = props.trial.trial_boards_submissions_people_associations.map(tbspa => tbspa.person_id);
    const tbs_status = props.tbs_statuses.find(tbs_status => tbs_status.id == props.trial.trial_board_submission_status_id);
    const status = {value: tbs_status.id, label: tbs_status.text};
    this.state = {trial: props.trial, selectedPotentialPis: selectedPotentialPisIds, selectedTrialType: {value: props.trial.trial_type, label: props.trial.trial_type}, selectedStatus: status, selectedPhase: {value: props.trial.phase, label: props.trial.phase}, openDialogs: {fileUpload: false, editSubmissionResolution: {}}, locked: true, user: props.user};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.trial !== prevProps.trial) {
      const selectedPotentialPisIds = this.props.trial.trial_boards_submissions_people_associations.map(tbspa => tbspa.person_id);
      const tbs_status = this.props.tbs_statuses.find(tbs_status => tbs_status.id == this.props.trial.trial_board_submission_status_id);
      const status = {value: tbs_status.id, label: tbs_status.text};
      this.setState({trial: this.props.trial, selectedPotentialPis: selectedPotentialPisIds, selectedTrialType: {value: this.props.trial.trial_type, label: this.props.trial.trial_type}, selectedStatus: status, selectedPhase: {value: this.props.trial.phase, label: this.props.trial.phase}});
    }
  }

  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = event => {
    let currentComponent = this;
    // console.log(event.target.checked);
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newTrial = {...this.state.trial}
    newTrial[name] = value;
    currentComponent.setState({ trial: newTrial });
  };

  handleChange2 = (event, checked) => {
    let currentComponent = this;
    let newTrial = {...this.state.trial}
    newTrial[event.target.name] = checked;
    currentComponent.setState({ trial: newTrial });
  }

  handleTrialTypeChange = (event) => {
    this.setState({selectedTrialType: event});
  }

  handlePhaseChange = (event) => {
    this.setState({selectedPhase: event});
  }

  handlePotentialPisChange = (event) => {
    const newPotentialPisIds = event.map(person => {
      return (
        person.value
      )
    });
    this.setState({selectedPotentialPis: newPotentialPisIds});
  }

  handleStatusChange = (event) => {
    this.setState({selectedStatus: event});
  }

  componentDidMount = (props) => {
    let currentComponent = this;
    if (this.props.trial.trial_type) {
      currentComponent.setState({selectedTrialType: {value: this.props.trial.trial_type, label: this.props.trial.trial_type}, selectedPhase: {value: this.props.trial.phase, label: this.props.trial.phase}});
    }
  }

  handleLockChange = event => {
    if (this.state.locked == false) {
      this.setState({locked: true})
    } else {
      this.setState({locked: false})
    }
  }

  // handleNewSubmissionSave = event => {
  //   const currentComponent = this;
  //   fetch('/trial_board_submissions/create', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       ...this.state.trial,
  //       trial_type: this.state.selectedTrialType.value,
  //       phase: this.state.selectedPhase.value,
  //       trial_board_submission_status_id: this.state.selectedStatus.value,
  //       // trial_id: this.state.trial.id,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     if (data.response == 1) {
  //       console.log(data.trial);
  //       const newTrial = data.trial;
  //       currentComponent.setState({ trial: newTrial, locked: true });
  //       currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
  //     } else {
  //       // currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  // }

  handleSave = event => {
    const currentComponent = this;
    fetch('/trial_board_submissions/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...this.state.trial,
        trial_type: this.state.selectedTrialType.value,
        phase: this.state.selectedPhase.value,
        people_ids: this.state.selectedPotentialPis,
        trial_board_submission_status_id: this.state.selectedStatus.value,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // console.log(data.trial);
        // const newTrial = data.trial;
        currentComponent.setState({ locked: true });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        // currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleFileUploadOpen = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["fileUpload"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleFileUploadClose = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["fileUpload"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleFileUpload = (trial, event) => {
    // const newTrial = trial;
    // this.setState({trial: newTrial});
    this.handleFileUploadClose();
  }

  handleFileDelete = file => {
    const currentComponent = this;
    fetch('/trial_board_submissions/' + this.state.trial.id + '/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ trial: newTrial });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditResolutionClick = assocId => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["editSubmissionResolution"][assocId] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditResolutionSave = assoc => {
    const currentComponent = this;
    fetch('/trial_board_submissions/' + assoc.assoc_id + '/update_board_resolution', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...assoc,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleEditResolutionClose(assoc.assoc_id);
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditResolutionClose = assocId => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["editSubmissionResolution"][assocId] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleTrialBoardSubmissionReceived = message => {
    console.log("received Trial Board Submission");
    console.log(message);
    if (message.trial && message.trial.id == this.state.trial.id) {
      let trial = message.trial;
      this.setState({ trial: trial });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
    }
    if (message.deleted_trials && message.deleted_trials.includes(this.state.trial.id)) {
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' gelöscht: ' + this.state.trial.short, {variant: 'success'});
      window.location.assign('/trial_boards/index_trials');
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    const { people } = this.props;
    const trial = this.state.trial;
    const trialTypeList = [{value: "IIT", label: "IIT"}, {value: "IIS", label: "IIS"}];
    const phaseList = [{value: "I", label: "I"}, {value: "I/II", label: "I/II"}, {value: "II", label: "II"}, {value: "III", label: "III"}, {value: "IV", label: "IV"}, {value: "V", label: "V"}]
    const statusList = this.props.tbs_statuses.map(status => {
      return {value: status.id, label: status.text}
    });
    // console.log(trial);
    const files = trial.files;
    const allPeopleOptions = people.map(person => {
      return (
        {value: person.id, label: person.first_name + " " + person.last_name}
      )
    });
    const pis = allPeopleOptions.filter(person => this.state.selectedPotentialPis.includes(person.value));
    const status = statusList.find(statusAssArr => statusAssArr.value == trial.trial_board_submission_status_id);
    return (
      <>
      <Grid container >
        <ActionCableConsumer
          channel={{channel: "TrialBoardSubmissionsChannel"}}
          onReceived={this.handleTrialBoardSubmissionReceived}
          onConnected={this.handleConnected}
        />
        <Grid item xs={12} style={{paddingBottom: '0px'}}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" flexShrink={true} flexWrap="nowrap">
            { (!this.state.locked) &&
              <Tooltip title="Speichern">
                <IconButton aria-label="Save" onClick={this.handleSave}>
                  <Save />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title="Studie editieren">
              <FormControlLabel
                control={
                  <Switch
                    checked={!this.state.locked}
                    onChange={this.handleLockChange}
                    color="primary"
                  />
                }
                label= {this.state.locked ? <Lock /> : <LockOpen /> }
                style={{marginRight: 0, alignSelf: 'flex-end'}}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={4} style={{paddingTop: '0px'}}>
          <Grid container justify="center">
            <TextField
              margin="dense"
              id="short"
              label="Akronym"
              onChange={this.handleChange}
              value={this.state.trial.short}
              fullWidth
              error={!this.state.trial.short}
              variant="outlined"
              // disabled={this.state.locked}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.short != null) }}
              style={{zIndex: 1}}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="title"
              label="Titel"
              onChange={this.handleChange}
              value={this.state.trial.title}
              fullWidth
              error={!this.state.trial.title}
              variant="outlined"
              // disabled={this.state.locked}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.title != null) }}
              multiline
              style={{zIndex: 1}}
              type="string"
              size="small"
            />
            { this.state.locked &&
              <>
                <TextField
                  margin="dense"
                  id="trial_status"
                  label="Status"
                  onChange={this.handleChange}
                  value={status.label}
                  fullWidth
                  error={!status.label}
                  variant="outlined"
                  // disabled={this.state.locked}
                  InputProps={{
                    readOnly: this.state.locked,
                  }}
                  InputLabelProps={{ shrink: (status.label != null) }}
                  style={{zIndex: 1}}
                  type="string"
                  size="small"
                />
              </>
            }
            { !this.state.locked &&
              <>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
                  <InputLabel filled animated shrink margin="dense" variant="outlined" >Status</InputLabel>
                  <Select
                    id="trial_board_submission_status_id"
                    value={ this.state.selectedStatus }
                    onChange={this.handleStatusChange}
                    options={statusList}
                    style={{width: "100%"}}
                    isDisabled={this.state.locked}
                  />
                </FormControl>
              </>
            }
            <TextField
              margin="dense"
              id="date_received"
              label="Datum erhalten"
              type="date"
              defaultValue={this.state.trial.date_received}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: true }}
              style={{zIndex: 1}}
              size="small"
            />
            <TextField
              margin="dense"
              id="sponsor_cro"
              label="CRO"
              onChange={this.handleChange}
              value={this.state.trial.sponsor_cro}
              fullWidth
              variant="outlined"
              // disabled={this.state.locked}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.sponsor_cro != null) }}
              style={{zIndex: 1}}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="legal_sponsor"
              label="Sponsor"
              onChange={this.handleChange}
              value={this.state.trial.legal_sponsor}
              fullWidth
              variant="outlined"
              // disabled={this.state.locked}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.legal_sponsor != null) }}
              style={{zIndex: 1}}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="indication"
              label="Indikation"
              onChange={this.handleChange}
              value={this.state.trial.indication}
              fullWidth
              variant="outlined"
              // disabled={this.state.locked}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.indication != null) }}
              multiline
              // rows={4}
              style={{zIndex: 1}}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="imp"
              label="IMP"
              onChange={this.handleChange}
              value={this.state.trial.imp}
              fullWidth
              variant="outlined"
              // disabled={this.state.locked}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.imp != null) }}
              style={{zIndex: 1}}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="target"
              label="Target"
              onChange={this.handleChange}
              value={this.state.trial.target}
              fullWidth
              variant="outlined"
              // disabled={this.state.locked}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.target != null) }}
              style={{zIndex: 1}}
              type="string"
              size="small"
            />
            { this.state.locked &&
              <>
                <TextField
                  margin="dense"
                  id="trial_type"
                  label="Studientyp"
                  onChange={this.handleChange}
                  value={this.state.selectedTrialType.value}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    readOnly: this.state.locked,
                  }}
                  InputLabelProps={{ shrink: (this.state.selectedTrialType.value != null) }}
                  style={{zIndex: 1}}
                  type="string"
                  size="small"
                />
                <TextField
                  margin="dense"
                  id="phase"
                  label="Phase"
                  onChange={this.handleChange}
                  value={this.state.selectedPhase.value}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    readOnly: this.state.locked,
                  }}
                  InputLabelProps={{ shrink: (this.state.selectedPhase.value != null) }}
                  style={{zIndex: 1}}
                  type="string"
                  size="small"
                />
              </>
            }
            { !this.state.locked &&
              <>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 998}}>
                  <InputLabel filled animated shrink margin="dense" variant="outlined" >Studientyp</InputLabel>
                  <Select
                    id="trial_type"
                    value={ this.state.selectedTrialType }
                    onChange={this.handleTrialTypeChange}
                    options={trialTypeList}
                    style={{width: "100%"}}
                    isDisabled={this.state.locked}
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 997}}>
                  <InputLabel filled animated shrink margin="dense" variant="outlined" >Phase</InputLabel>
                  <Select
                    id="phase"
                    value={ this.state.selectedPhase }
                    onChange={this.handlePhaseChange}
                    options={phaseList}
                    style={{width: "100%"}}
                    isDisabled={this.state.locked}
                  />
                </FormControl>
              </>
            }
            <TextField
              margin="dense"
              id="contact_name"
              label="Kontakt Name"
              onChange={this.handleChange}
              value={this.state.trial.contact_name}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.contact_name != null) }}
              style={{zIndex: 1}}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="contact_email"
              label="Kontakt Email"
              onChange={this.handleChange}
              value={this.state.trial.contact_email}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.contact_email != null) }}
              style={{zIndex: 1}}
              type="email"
              size="small"
            />
            <TextField
              margin="dense"
              id="comment"
              label="Kommentar"
              onChange={this.handleChange}
              value={this.state.trial.comment}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.trial.comment != null) }}
              multiline
              style={{zIndex: 1}}
              type="text"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid item xs={4} style={{paddingTop: '0px'}}>
          <Grid container justify="center">
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 997}}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Potentielle PIs</InputLabel>
              <Select
                // defaultValue={}
                isMulti
                name="potential_pis"
                options={allPeopleOptions}
                onChange={this.handlePotentialPisChange}
                className="basic-multi-select"
                classNamePrefix="select"
                isDisabled={this.state.locked}
                value={pis}
              />
            </FormControl>
            <TextField
              margin="dense"
              id="synopsis_in"
              label="Synopsis Eingang"
              type="date"
              defaultValue={this.state.trial.synopsis_in}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: true }}
              style={{zIndex: 1}}
              size="small"
            />
            <TextField
              margin="dense"
              id="budget_in"
              label="Budget Eingang"
              type="date"
              defaultValue={this.state.trial.budget_in}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: true }}
              style={{zIndex: 1}}
              size="small"
            />
            <TextField
              margin="dense"
              id="FQ_in"
              label="FQ Eingang"
              type="date"
              defaultValue={this.state.trial.FQ_in}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: true }}
              style={{zIndex: 1}}
              size="small"
            />
            <FormControl variant="outlined" margin="dense" fullWidth>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" flexShrink={true} flexWrap="nowrap">
                <InputLabel filled animated shrink margin="dense" variant="outlined" >Übertragene Dateien</InputLabel>
                { (!this.state.locked) &&
                  <Tooltip title="Neue Datei hochladen">
                    <IconButton aria-label="Upload" onClick={this.handleFileUploadOpen} size="small">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                }
              </Box>
            </FormControl>
            
            <TrialBoardFileUploadDialog
              open={this.state.openDialogs.fileUpload}
              trialId={this.state.trial.id}
              handleFileUpload={this.handleFileUpload}
              handleClose={this.handleFileUploadClose}
            />
            <ListOfFiles files={files} handleDelete={this.handleFileDelete} disabled={this.state.locked} controller="trial_board_submissions"/>
          </Grid>
          <Grid container justify="center">
            <SubmissionResolutionList assocs={trial.trial_boards_trial_boards_submissions_associations} editOpen={this.state.openDialogs.editSubmissionResolution} handleEditClick={this.handleEditResolutionClick} handleClose={this.handleEditResolutionClose} handleSave={this.handleEditResolutionSave} locked={this.state.locked} />
          </Grid>
        </Grid>
        <Grid item xs={4} style={{paddingTop: '0px'}}>
          <Grid container justify="center">
            <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
              <Toolbar
                variant='dense'
              >
                <div>
                  <Typography variant="h6" id="CDAPaper" component="div" >
                    CDA
                  </Typography>
                </div>
              </Toolbar>
              <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
                <FormControlLabel
                  style={{zIndex: 1}}
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.state.trial.cda_necessary || false}
                      onChange={this.handleChange2}
                      disabled={this.state.locked}
                    />
                  }
                  label="CDA erforderlich"
                  name="cda_necessary"
                />
              </FormControl>
              
              { (this.state.trial.cda_necessary) &&
                <>
                  <TextField
                    margin="dense"
                    id="cda_in"
                    label="CDA Eingang"
                    type="date"
                    defaultValue={this.state.trial.cda_in}
                    fullWidth
                    variant="outlined"
                    onChange={this.handleChange}
                    InputProps={{
                      readOnly: this.state.locked,
                    }}
                    InputLabelProps={{ shrink: true }}
                    style={{zIndex: 1}}
                    size="small"
                  />
                  <TextField
                    margin="dense"
                    id="cda_legal_dept_out"
                    label="CDA an Rechtsabteilung zur Prüfung"
                    type="date"
                    defaultValue={this.state.trial.cda_legal_dept_out}
                    fullWidth
                    variant="outlined"
                    onChange={this.handleChange}
                    InputProps={{
                      readOnly: this.state.locked,
                    }}
                    InputLabelProps={{ shrink: true }}
                    style={{zIndex: 1}}
                    size="small"
                  />
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center" style={{width: '100%'}}>
                    <TextField
                      margin="dense"
                      id="cda_legal_dept_ret"
                      label="1. Rückmeldung RA"
                      type="date"
                      defaultValue={this.state.trial.cda_legal_dept_ret}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputProps={{
                        readOnly: this.state.locked,
                      }}
                      InputLabelProps={{ shrink: true }}
                      style={{zIndex: 1}}
                      size="small"
                    />
                    <TextField
                      margin="dense"
                      id="cda_legal_dept_time"
                      label="Zeit 1. Rückmeldung RA"
                      value={Moment(this.state.trial.cda_legal_dept_ret).diff(this.state.trial.cda_legal_dept_out, 'days') + " Tage"}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      style={{zIndex: 1}}
                      type="string"
                      size="small"
                    />
                  </Box>
                  <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
                    <FormControlLabel
                      style={{zIndex: 1}}
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.state.trial.second_evaluation_necessary || false}
                          onChange={this.handleChange2}
                          disabled={this.state.locked}
                        />
                      }
                      label="2. Prüfung erforderlich"
                      name="second_evaluation_necessary"
                    />
                  </FormControl>
                  {(this.state.trial.second_evaluation_necessary) &&
                    <>
                      <TextField
                        margin="dense"
                        id="date_sponsor_feedback_in"
                        label="Rückmeldung Sponsor nach 1. Prüfung"
                        type="date"
                        defaultValue={this.state.trial.date_sponsor_feedback_in}
                        fullWidth
                        variant="outlined"
                        onChange={this.handleChange}
                        InputProps={{
                          readOnly: this.state.locked,
                        }}
                        InputLabelProps={{ shrink: true }}
                        style={{zIndex: 1}}
                        size="small"
                      />
                      <TextField
                        margin="dense"
                        id="date_second_legal_dept_out"
                        label="2. Weiterleitung an RA"
                        type="date"
                        defaultValue={this.state.trial.date_second_legal_dept_out}
                        fullWidth
                        variant="outlined"
                        onChange={this.handleChange}
                        InputProps={{
                          readOnly: this.state.locked,
                        }}
                        InputLabelProps={{ shrink: true }}
                        style={{zIndex: 1}}
                        size="small"
                      />
                      <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center" style={{width: '100%'}}>
                        <TextField
                          margin="dense"
                          id="date_second_legal_dept_in"
                          label="2. Rückmeldung Rechtsabteilung (RA)"
                          type="date"
                          defaultValue={this.state.trial.date_second_legal_dept_in}
                          fullWidth
                          variant="outlined"
                          onChange={this.handleChange}
                          InputProps={{
                            readOnly: this.state.locked,
                          }}
                          InputLabelProps={{ shrink: true }}
                          style={{zIndex: 1}}
                          size="small"
                        />
                        <TextField
                          margin="dense"
                          id="cda_legal_dept_time2"
                          label="Zeit 2. Rückmeldung RA"
                          value={Moment(this.state.trial.date_second_legal_dept_in).diff(this.state.trial.date_second_legal_dept_out, 'days') + " Tage"}
                          fullWidth
                          variant="outlined"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled
                          style={{zIndex: 1}}
                          type="string"
                          size="small"
                        />
                      </Box>
                    </>
                  }
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center" style={{width: '100%'}}>
                    <TextField
                      margin="dense"
                      id="cda_signed"
                      label="CDA Unterschrift"
                      type="date"
                      defaultValue={this.state.trial.cda_signed}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputProps={{
                        readOnly: this.state.locked,
                      }}
                      InputLabelProps={{ shrink: true }}
                      style={{zIndex: 1}}
                      size="small"
                    />
                  </Box>
                  {(this.state.trial.cda_signed && this.state.trial.cda_legal_dept_ret) &&
                  <TextField
                    margin="dense"
                    id="cda_signature_time1"
                    label="Zeit Unterschrift nach JP1"
                    value={Moment(this.state.trial.cda_signed).diff(this.state.trial.cda_legal_dept_ret, 'days') + " Tage"}
                    fullWidth
                    variant="outlined"
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    style={{zIndex: 1}}
                    type="string"
                    size="small"
                  />}
                  {(this.state.trial.cda_signed && this.state.trial.cda_legal_dept_out) &&
                    <TextField
                      margin="dense"
                      id="cda_legal_dept_time_all1"
                      label="Gesamtzeit JP1"
                      value={Moment(this.state.trial.cda_signed).diff(this.state.trial.cda_legal_dept_out, 'days') + " Tage"}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputLabelProps={{ 
                        shrink: true,
                      }}
                      disabled
                      style={{zIndex: 1}}
                      type="string"
                      size="small"
                    />
                  }
                  {(this.state.trial.second_evaluation_necessary) &&
                  <>
                    <TextField
                      margin="dense"
                      id="cda_signature_time2"
                      label="Zeit Unterschrift nach JP2"
                      value={Moment(this.state.trial.cda_signed).diff(this.state.trial.date_second_legal_dept_in, 'days') + " Tage"}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputLabelProps={{ 
                        shrink: true,
                      }}
                      disabled
                      style={{zIndex: 1}}
                      type="string"
                      size="small"
                    />
                    {(this.state.trial.cda_signed && this.state.trial.date_second_legal_dept_in) &&
                    <TextField
                      margin="dense"
                      id="cda_legal_dept_time_all2"
                      label="Gesamtzeit JP2"
                      value={Moment(this.state.trial.cda_signed).diff(this.state.trial.date_second_legal_dept_out, 'days') + " Tage"}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputLabelProps={{ 
                        shrink: true,
                      }}
                      disabled
                      style={{zIndex: 1}}
                      type="string"
                      size="small"
                    />
                    }
                  </>
                  }
                  {(this.state.trial.cda_signed && this.state.trial.cda_legal_dept_out) &&
                  <TextField
                    margin="dense"
                    id="time_to_sig_legal_dept"
                    label="Zeit JP gesamt"
                    value={Moment(this.state.trial.cda_signed).diff(this.state.trial.cda_legal_dept_out, 'days') + " Tage"}
                    fullWidth
                    variant="outlined"
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    style={{zIndex: 1}}
                    type="string"
                    size="small"
                  />}
                </>
              }
            </Paper>
            <TextField
              margin="dense"
              id="protocol_request"
              label="Protokoll Anforderung"
              type="date"
              defaultValue={this.state.trial.protocol_request}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: true }}
              style={{zIndex: 1}}
              size="small"
            />
            <TextField
              margin="dense"
              id="protocol_in"
              label="Protokoll Eingang"
              type="date"
              defaultValue={this.state.trial.protocol_in}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: true }}
              style={{zIndex: 1}}
              size="small"
            />
            <TextField
              margin="dense"
              id="protocol_forwarded"
              label="Protokoll weiter geleitet"
              type="date"
              defaultValue={this.state.trial.protocol_forwarded}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: true }}
              style={{zIndex: 1}}
              size="small"
            />
            <TextField
              margin="dense"
              id="budget_requested"
              label="Budget Anfrage"
              type="date"
              defaultValue={this.state.trial.budget_requested}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: true }}
              style={{zIndex: 1}}
              size="small"
            />
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.trial.budget_reviewed || false}
                    onChange={this.handleChange2}
                    disabled={this.state.locked}
                  />
                }
                label="Budget geprüft"
                name="budget_reviewed"
              />
            </FormControl>
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.trial.viable || false}
                    onChange={this.handleChange2}
                    disabled={this.state.locked}
                  />
                }
                label="Durchführbar"
                name="viable"
              />
            </FormControl>
            { this.state.trial.viable &&
              <>
                <TextField
                  margin="dense"
                  id="approval_date"
                  label="Zustimmungsdatum"
                  type="date"
                  defaultValue={this.state.trial.approval_date}
                  fullWidth
                  variant="outlined"
                  onChange={this.handleChange}
                  InputProps={{
                    readOnly: this.state.locked,
                  }}
                  InputLabelProps={{ shrink: true }}
                  style={{zIndex: 1}}
                  size="small"
                />
                { this.state.trial.approval_date &&
                  <>
                    <TextField
                      margin="dense"
                      id="trial_team_out"
                      label="Übergabe an Studienteam(s)"
                      type="date"
                      defaultValue={this.state.trial.trial_team_out}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputProps={{
                        readOnly: this.state.locked,
                      }}
                      InputLabelProps={{ shrink: true }}
                      style={{zIndex: 1}}
                      size="small"
                    />
                    <TextField
                      margin="dense"
                      id="initiation_date_hd"
                      label="Initiirungsdatum Heidelberg"
                      type="date"
                      defaultValue={this.state.trial.initiation_date_hd}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputProps={{
                        readOnly: this.state.locked,
                      }}
                      InputLabelProps={{ shrink: true }}
                      style={{zIndex: 1}}
                      size="small"
                    />
                    <TextField
                      margin="dense"
                      id="trial_team_psv"
                      label="Studienteam(s) PSV"
                      type="date"
                      defaultValue={this.state.trial.trial_team_psv}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputProps={{
                        readOnly: this.state.locked,
                      }}
                      InputLabelProps={{ shrink: true }}
                      style={{zIndex: 1}}
                      size="small"
                    />
                    <TextField
                      margin="dense"
                      id="cuno"
                      label="CUNO Nummer"
                      defaultValue={this.state.trial.cuno}
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange}
                      InputProps={{
                        readOnly: this.state.locked,
                      }}
                      InputLabelProps={{ shrink: true }}
                      style={{zIndex: 1}}
                      type="string"
                      size="small"
                    />
                  </>
                }
              </>
            }
            { !this.state.trial.viable &&
              <>
                <TextField
                  margin="dense"
                  id="inquiry_denied"
                  label="Ablehnungsdatum"
                  type="date"
                  defaultValue={this.state.trial.inquiry_denied}
                  fullWidth
                  variant="outlined"
                  onChange={this.handleChange}
                  InputProps={{
                    readOnly: this.state.locked,
                  }}
                  InputLabelProps={{ shrink: true }}
                  style={{zIndex: 1}}
                  size="small"
                />
                { this.state.trial.inquiry_denied &&
                  <TextField
                    margin="dense"
                    id="reason_non_viable"
                    label="Ablehnungsgrund"
                    defaultValue={this.state.trial.reason_non_viable}
                    fullWidth
                    variant="outlined"
                    onChange={this.handleChange}
                    InputProps={{
                      readOnly: this.state.locked,
                    }}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    style={{zIndex: 1}}
                    type="text"
                    size="small"
                  />
                }
              </>
            }
          </Grid>
        </Grid>
      </Grid>
      </>
    )
  }
}

ShowTrialBoardSubmission.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default (withTheme(withSnackbar(ShowTrialBoardSubmission)));
