import React from 'react';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';

class DeleteConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.form = React.createRef();
  }

  handleSave = event => {
    this.props.handleSave();
    this.props.handleClose();
  }

  render(props) {
    const mainListItems = this.props.mainNames.map(obj => {
      return (<ListItem>
        {obj}
      </ListItem>)
    })
    let subListItems = [];
    console.log("subNames", this.props.subNames)
    Object.keys(this.props.subNames).forEach((key, ind) => {
      let subSubNames = [];
      Object.keys(this.props.subNames[key]).forEach((key2,ind2) => {
        subSubNames.push(
          <>
          <ListSubheader sx={{paddingLeft: '32px'}} >{key2}</ListSubheader>
          {this.props.subNames[key][key2].map(subsub => {
            return (
              <ListItem sx={{paddingLeft: '32px'}}>
                {subsub}
              </ListItem>
            )
          })}
          </>
        )
      })
      subListItems.push( 
      <>
      <ListItem sx={{paddingTop: '2px'}}>{key}</ListItem>
      {subSubNames}
      <Divider />
      </>
      )
    })
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={true}
      >
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Dies löscht die folgenden Objekte vollständig. Sie können danach nur noch über den Audit Trail wiederhergestellt werden:
          </DialogContentText>
          <DialogContentText>
            <List>
              {subListItems}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.props.handleSave()} color="primary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DeleteConfirmationDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withSnackbar(DeleteConfirmationDialog);
