import React, { Component } from 'react';
import { render, hydrate } from 'react-dom';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can';
import Moment from 'moment'

import { save } from 'save-file';
import PDFDownloadLink from '@react-pdf/renderer';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';

import EcrfUserFields from './EcrfUserFields.jsx';
import EcrfApplicationFields from './EcrfApplicationFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import FileUploadDialog from './FileUploadDialog.jsx';
import ListOfFiles from './ListOfFiles.jsx';
import ListOfEcrfApplications from './ListOfEcrfApplications.jsx';
import { EcrfApplicationPDF } from './EcrfApplicationPDF.jsx';
import { ThirtyFpsRounded } from '@mui/icons-material';


class EcrfUserOverview extends React.Component {

  constructor(props) {
    super(props);
    let user = this.props.user;
    if (user.consent == null) {
      user["consent"] = false;
    }
    this.state = {user: user, expanded: null, openDialogs: {editUser: false}};
    this.form = React.createRef();
  }

  componentDidMount(props) {
  }

  handlePanelChange = panel => (event, expanded, complete) => {
    let newExpanded = expanded;
    if (!complete) {
      newExpanded = "user_details";
    } else if (!this.state.user.submitted_file_ids && expanded == "application") {
      newExpanded = "documents";
    }
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  handleOpen = name => (event) => {
    console.log(name, event);
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleImport = (event) => {
    const currentComponent = this;
    fetch('/ecrf_management/' + currentComponent.state.user.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_user: {
          id: currentComponent.state.user.id,
          name: currentComponent.state.user.person.name,
          first_name: currentComponent.state.user.person.first_name,
          // gender: currentComponent.state.user.person.gender,
          title: currentComponent.state.user.person.title,
          email: currentComponent.state.user.person.email,
          phone_number: currentComponent.state.user.person.phone_number,
          fax_number: currentComponent.state.user.person.fax_number,
          institution_name: currentComponent.state.user.person.departments[0].institution.name,
          department_name: currentComponent.state.user.person.departments[0].name,
          street: currentComponent.state.user.person.departments[0].street,
          zip: currentComponent.state.user.person.departments[0].zip,
          city: currentComponent.state.user.person.departments[0].city,
          consent: false
        },
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("editUser");
        currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditEcrfUserSave = user => {
    const currentComponent = this;
    fetch('/ecrf_management/' + user.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_user: user,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("editUser");
        currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleNewApplicationSave = application => {
    console.log(application);
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/create_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_application: application,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("newApplication");
        console.log(data.ecrf_user);
        currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich erstellt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleFileUpload = event => {
    this.setState({user: event.ecrf_user});
    this.handleClose("fileUpload");
  }

  handleApplicationFileUpload = user => {
    this.setState({user: user});
    this.props.enqueueSnackbar('Erfolgreich hochgeladen.', {variant: 'success'});
    // this.handleClose("fileUpload");
  }

  handleFileDelete = file => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        ecrf_user_id: currentComponent.state.user.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else if (data.response == 2) {
        if (data.ecrf_user) {
          currentComponent.setState({ user: data.ecrf_user });
        }
        currentComponent.props.enqueueSnackbar('Diese Aktion ist nicht möglich, da die Datei bereits geprüft und akzeptiert wurde.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  // handleApplicationFileDelete = file => {
  //   const currentComponent = this;
  //   fetch('/ecrf_management/' + this.state.user.id + '/delete_file', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       file_id: file.id,
  //       // trial_id: this.state.trial.id,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     console.log(data);
  //     if (data.response == 1) {
  //       // var newTrial = data.trial;
  //       currentComponent.setState({ user: data.ecrf_user });
  //       currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
  //     } else {
  //       currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  // }

  handleApplicationFileDelete = file => {
    const currentComponent = this;
    // console.log(application);
    fetch('/ecrf_management/delete_application_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        console.log(data);
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else if (data.response == 2) {
        if (data.ecrf_user) {
          currentComponent.setState({ user: data.ecrf_user });
        }
        currentComponent.props.enqueueSnackbar('Diese Aktion ist nicht möglich, da die Datei bereits geprüft und akzeptiert wurde.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleApplicationDelete = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + application.id + '/delete_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // console.log(data);
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleApplicationRemove = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/remove_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich Löschung beantragt.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich Löschantrag zurückgezogen.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  // handlePdfDownload = application => {
  //   ReactPDF.render(<EcrfApplicationPDF />, `${__dirname}/example.pdf`);
  // }

  handleChangeSubmitted = file_id => {
    const currentComponent = this;
    console.log(this.state.user);
    if (this.state.user.submitted_file_ids && this.state.user.submitted_file_ids.includes(file_id)){
      fetch('/ecrf_management/' + this.state.user.id + '/mark_file_unsubmitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        // console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Freigabe zur Prüfung erfolgreich zurückgenommen.', {variant: 'success'});
        } else if (data.response == 2) {
          if (data.ecrf_user) {
            currentComponent.setState({ user: data.ecrf_user });
          }
          currentComponent.props.enqueueSnackbar('Diese Aktion ist nicht möglich, da die Datei bereits geprüft und akzeptiert wurde.', {variant: 'error'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/ecrf_management/' + this.state.user.id + '/mark_file_submitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        // console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich zur Prüfung freigegeben.', {variant: 'success'});
        } else if (data.response == 2) {
          if (data.ecrf_user) {
            currentComponent.setState({ user: data.ecrf_user });
          }
          currentComponent.props.enqueueSnackbar('Diese Aktion ist nicht möglich, da die Datei bereits geprüft und akzeptiert wurde.', {variant: 'error'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  testFunction = (event) => {
    console.log("test");
  }

  handleChangeSubmittedApplication = (file_id) => {
    const currentComponent = this;
    console.log(this.state.user.ecrf_applications[0].files.map(file => file.id).includes(file_id));
    const application = this.state.user.ecrf_applications.find(app => (app.files.map(file => file.id).includes(file_id)));
    console.log(file_id, application, this.state.user.ecrf_applications);
    if (application.submitted_file_ids && application.submitted_file_ids.includes(file_id)){
      fetch('/ecrf_management/' + this.state.user.id + '/mark_application_file_unsubmitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          application_id: application.id,
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        // console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Freigabe zur Prüfung erfolgreich zurückgenommen.', {variant: 'success'});
        } else if (data.response == 2) {
          if (data.ecrf_user) {
            currentComponent.setState({ user: data.ecrf_user });
          }
          currentComponent.props.enqueueSnackbar('Diese Aktion ist nicht möglich, da die Datei bereits geprüft und akzeptiert wurde.', {variant: 'error'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/ecrf_management/' + this.state.user.id + '/mark_application_file_submitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          application_id: application.id,
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich zur Prüfung freigegeben.', {variant: 'success'});
        } else if (data.response == 2) {
          if (data.ecrf_user) {
            currentComponent.setState({ user: data.ecrf_user });
          }
          currentComponent.props.enqueueSnackbar('Diese Aktion ist nicht möglich, da die Datei bereits geprüft und akzeptiert wurde.', {variant: 'error'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleEcrfUserReceived = message => {
    console.log(message);
    // if (message.ecrf_user) {
    //   let 
    // }
  }

  render() {
    const { openDialogs } = this.state;
    const files = this.state.user.files;
    const requiredFields = ["name", "first_name", "email", "institution_name", "street", "zip", "city", "consent", "phone_number"];
    const applicationRetuiredFields = ["ecrf_trial_configuration_id", "ecrf_trial_function_id", "ecrf_trial_role_id", ]
    const disabledFields = ["date_submitted", "status"]
    const hiddenFields = ["username"];
    const appliedTrialIds = (this.state.user.ecrf_applications) ? this.state.user.ecrf_applications.map(appl => appl.ecrf_trial_configuration.id) : [];
    const trialsOptions = this.props.trials.filter(trial => !(appliedTrialIds.includes(trial.id))).map(trial => {return {value: trial.id, label: trial.short}});
    const personStatusOptions = [{value: "in preparation", label: "in Vorbereitung", tooltipText: "Antragsteller bereitet Antrag/Daten vor", backgroundColor: "#FFFFFF"}, {value: "checking", label: "zu prüfen durch DM", tooltipText: "Datensatz muss von DM geprüft werden", backgroundColor: "#E29393"}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltipText: "Antragsteller muss Antrag überarbeiten", backgroundColor: "#37ADF5"}, {value: "all complete", label: "Alles vollständig", tooltipText: "Alle Angaben und Nachweise der Person sind vollständig", backgroundColor: "#76d658"}, {value: "misc", label: "Sonstiges", tooltipText: "Bitte wenden Sie sich an das DM für Fragen.", backgroundColor: "#ADADAD"}];
    // const rolesOptions = [{value: "TestRolle1", label: "TestRolle1"}, {value: "TestRolle2", label: "TestRolle2"}, {value: "TestRolle3", label: "TestRolle3"}, {value: "TestRolle4", label: "TestRolle4"}]
    // const functionsOptions = [{value: "Prüfstelle", label: "Prüfstelle"}, {value: "TestFunction2", label: "TestFunction2"}, {value: "TestFunction3", label: "TestFunction3"}, {value: "TestFunction4", label: "TestFunction4"}]
    let functionsOptions = [];
    this.props.trials.forEach(conf => {
      // let ret = [];
      const func_ret = conf.ecrf_trial_functions.map(func => {
        return ({value: func.id, label: func.name});
      });
      functionsOptions = {...functionsOptions, [conf.id]: func_ret};
    })
    let rolesOptions = {};
    this.props.trials.forEach(conf => {
      let func_ret = [];
      conf.ecrf_trial_functions.forEach(func => {
        const roles_ret = func.ecrf_trial_roles.map(role => {
          return ({value: role.id, label: role.name});
        })
        func_ret = {...func_ret, [func.id]: roles_ret};
      });
      rolesOptions[conf.id] = func_ret;
    })
    console.log(rolesOptions);
    let sitesOptions = {}
    this.props.trials.forEach(conf => {
      const sites = conf.trial.sites.map(site => {
        return {value: site.id, label: site.inst_description};
      })
      sitesOptions[conf.id] = sites;
    })
    // console.log(sitesOptions);
    // [{value: "TestSite1", label: "TestSite1"}, {value: "TestSite2", label: "TestSite2"}, {value: "TestSite3", label: "TestSite3"}, {value: "TestSite4", label: "TestSite4"}]
    // const statusOptions = [{value: "applied", label: "Eingereicht", stepnr: 2}, {value: "granted", label: "Genehmigt", stepnr: 3}, {value: "denied", label: "Abgelehnt", stepnr: 3}, {value: "waiting", label: "Warten auf Antragsupload", stepnr: 1}]
    const accountStatusOptions =  [{value: "in preparation", label: "in Vorbereitung", tooltipText: "Antragsteller bereitet Antrag/Daten vor", statusColor: "#FFFFFF", stepnr: 1}, {value: "training", label: "Trainingsrolle aktiviert", tooltipText: "Der Account des Benutzers wurde ohne Berechtigungen zu Trainingszwecken aktiviert", statusColor: "#FFFFFF", stepnr: 2}, {value: "checking", label: "zu prüfen durch DM", tooltipText: "Datensatz muss von DM geprüft werden", statusColor: "#E29393", stepnr: 3}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltipText: "Antragsteller muss Antrag überarbeiten", statusColor: "#37ADF5", stepnr: 3}, {value: "ready", label: "Bereit", tooltipText: "Antrag ist genehmigt, aber Prüfzentrum oder Studie ist nicht freigeschaltet", statusColor: "#f5b556", stepnr: 3}, {value: "active", label: "Aktiv", tooltipText: "eCRF-Account ist freigeschaltet und aktiv.", statusColor: "#76d658", stepnr: 4}, {value: "locked", label: "Gesperrt", tooltipText: "eCRF-Account ist vom DM gesperrt worden", statusColor: "#FF4917", stepnr: 4}, {value: "misc", label: "Sonstiges", tooltipText: "Bitte wenden Sie sich an das DM für Fragen.", statusColor: "#ADADAD", stepnr: 4}];
    const applicationSelectOptions = {trial_id: trialsOptions || "", role: rolesOptions, site: sitesOptions, function: functionsOptions, status: accountStatusOptions}
    let expanded = null;
    console.log(applicationSelectOptions);
    if (!this.state.expanded) {
      // if (this.state.user.name == null || this.state.user.name == "") {
      //   expanded = "user_details"
      // } else {
      //   expanded = "documents";
      // }
      expanded = "user_details";
    } else {
      expanded = this.state.expanded;
    }
    const complete = Math.min(...requiredFields.map(field => {
      return (this.state.user[field] != null && this.state.user[field] != undefined && this.state.user[field] != "")
    }));
    console.log(complete);
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <ActionCableConsumer
          channel={{channel: "EcrfUsersChannel"}}
          onReceived={this.handleEcrfUserReceived}
        />
        { (openDialogs.editUser) &&
        <EditDialog
          title="Benutzerdaten editieren"
          obj={this.state.user}
          open={openDialogs.editUser}
          handleSave={this.handleEditEcrfUserSave}
          handleClose={() => this.handleClose("editUser")}
          Fields={EcrfUserFields}
          requiredFields={requiredFields}
          disabled={(this.state.user.person_status == "all complete" || this.state.user.person_status == "misc")}
          selectOptions={{person_status: personStatusOptions}}
        />}
        { (openDialogs.newApplication) &&
        <NewDialog
          title="Studiendaten"
          obj={{date_submitted: Moment().toISOString().substr(0,10), status: "in preparation"}}
          open={openDialogs.newApplication}
          handleSave={this.handleNewApplicationSave}
          handleClose={() => this.handleClose("newApplication")}
          Fields={EcrfApplicationFields}
          requiredFields={applicationRetuiredFields}
          selectOptions={applicationSelectOptions}
          disabledFields={disabledFields}
          hiddenFields={hiddenFields}
          admin={false}
        />}
        { (openDialogs.editApplication) &&
          <EditDialog
            title="Studiendaten editieren"
            obj={application}
            open={openDialogs.editApplication}
            handleSave={this.handleEditApplicationSave}
            handleClose={() => this.handleClose("editApplication")}
            Fields={EcrfApplicationFields}
            requiredFields={applicationRetuiredFields}
            admin={false}
          />
        }
        { (openDialogs.fileUpload) &&
        <FileUploadDialog
          open={openDialogs.fileUpload}
          saveUrl={'/ecrf_management/' + this.state.user.id + '/upload_file'}
          handleFileUpload={this.handleFileUpload}
          handleClose={() => this.handleClose("fileUpload")}
        />}
        { (!complete) &&
          <Grid item xs={12}>
            <Typography style={{color: 'red', fontSize: '20px'}}>
              Hinweis: Die Felder in rot sind Pflichtfelder und müssen ausgefüllt werden. Dies können Sie über einen Klick auf das Stift-Icon rechts erledigen.
            </Typography>
          </Grid>
        }
        <Grid item xs={12}>
          <Paper style={{marginTop: '10px', width: '100%', borderStyle: "solid", borderWidth: "1px"}} >
            <Accordion expanded={expanded === "user_details"} onChange={this.handlePanelChange("user_details", complete)} >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Persönliche Daten {this.state.user.title} {this.state.user.first_name} {this.state.user.name} </Typography>
                { (complete) &&
                  <CheckIcon color="success"/>
                }
                { (!complete) &&
                  <CloseIcon color="error"/>
                }
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                  <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                    {/* Persönliche Daten */}
                  </Typography>
                  <Toolbar
                    variant='dense'
                  >
                    { (!(this.state.user.person_status == "all complete") && !(this.state.user.person_status == "misc")) &&
                      <Tooltip title="Editieren">
                        <IconButton aria-label="Edit" size="large">
                        <Button 
                            variant="contained"
                            onClick={this.handleOpen("editUser")}
                            size="small"
                          > 
                            Daten bearbeiten
                          </Button>
                        </IconButton>
                      </Tooltip>
                    }
                    { (this.state.user.person && !complete) &&
                      <Tooltip title="Sie können die Daten aus dem TOPAS Adressbuch importieren">
                        <Button
                          // type="submit"
                          // disabled={!valid}
                          onClick={() => this.handleImport(this.state)}
                          variant="contained"
                          color="primary">
                            Daten aus Adressbuch importieren
                        </Button>
                      </Tooltip>
                    }
                  </Toolbar>
                </Box>
                { (this.state.user.person && !complete) &&
                <Typography style={{color: 'red', fontSize: '20px'}}>
                  Hinweis: Ihre Kontaktdaten sind (teilweise) bereits in unserer Adressdatenbank hinterlegt. Sie wurden bereits eingefügt. Bitte prüfen Sie die Daten und korrigieren Sie diese, wenn nötig.
                </Typography>
                }
                <EcrfUserFields obj={this.state.user} readOnly requiredFields={requiredFields} selectOptions={{person_status: personStatusOptions}}/>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "application"} onChange={this.handlePanelChange("application", complete)} >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Studienspezifische Zugangsanträge und Nachweise</Typography>
                { (this.state.user.files_checked && this.state.user.checked_file_ids == this.state.user.submitted_file_ids) &&
                  <Tooltip title="Die hochgeladenen Dokumente wurden erfolgreich geprüft.">
                    <CheckIcon color="success"/>
                  </Tooltip>
                }
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                  <Typography color="blue" style={{fontWeight: '300', fontSize: '20px', marginLeft: 5}}>
                    Über den Button „Neuen Antrag anlegen“ können Sie einen Zugangsantrag zum eCRF erstellen. Bitte wählen Sie zunächst aus, für welche Studie Sie Zugang benötigen und welche Funktion und Rolle Sie übernehmen sollen.
                  </Typography>
                  <Toolbar
                    variant='dense'
                  >
                    <Tooltip title="Neuer Antrag" style={{marginRight: 5}}>
                      <IconButton aria-label="Edit" >
                        <Button 
                          variant="contained"
                          onClick={this.handleOpen("newApplication")}
                          size="small"
                        > 
                          Neuen Antrag anlegen
                        </Button>
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                </Box>
                <Grid item xs={12}>
                  <ListOfEcrfApplications
                    user={this.state.user}
                    trials={this.props.trials}
                    // applications={this.state.user.ecrf_applications}
                    statusOptions={accountStatusOptions}
                    handleApplicationFileUpload={this.handleApplicationFileUpload}
                    // handlePdfDownload={this.handlePdfDownload}
                    handleFileDelete={this.handleApplicationFileDelete}
                    handleDialogOpen={this.handleOpen}
                    handleDelete={this.handleApplicationDelete}
                    handleRemove={this.handleApplicationRemove}
                    handleChangeSubmitted={this.handleChangeSubmittedApplication}
                  />
                </Grid>

              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "documents"} onChange={this.handlePanelChange("documents", complete)} >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Allgemeine Nachweise</Typography>
              { (this.state.user.checked_file_ids && this.state.user.submitted_file_ids && this.state.user.checked_file_ids.length == this.state.user.submitted_file_ids.length && this.state.user.accepted_certificates) &&
                <Tooltip title="Die hochgeladenen Dokumente wurden erfolgreich geprüft.">
                  <CheckIcon color="success"/>
                </Tooltip>
              }
              { (this.state.user.submitted_file_ids && this.state.user.submitted_file_ids.length > 0 && ((this.state.user.checked_file_ids && this.state.user.checked_file_ids.length < this.state.user.submitted_file_ids.length) || this.state.user.checked_file_ids == null)  || (!this.state.user.accepted_certificates)) &&
                <Tooltip title="Es müssen noch hochgeladene Dokumente geprüft werden.">
                  <CheckingIcon color="warning"/>
                </Tooltip>
              }
              { ((!this.state.user.submitted_file_ids || this.state.user.submitted_file_ids == 0) && !this.state.user.accepted_certificates ) &&
                <Tooltip title="Es wurden noch keine Dokumente zur Prüfung hochgeladen.">
                  <CloseIcon color="error"/>
                </Tooltip>
              }
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                  <Typography color="blue" style={{fontWeight: '300', fontSize: '20px', marginLeft: 5, width: '90%'}}>
                    Laden Sie hier, falls noch nicht vorhanden, einen Nachweis für die allgemeine Marvin-Schulung hoch. Hierzu verweisen wir auf das e-Learning Modul „Marvin for Sites“ auf der Marvin-Webseite. Nach Abschluss dieses Trainings erhalten Sie ein Zertifikat, das Sie an dieser Stelle hochladen können.
                  </Typography>
                  <Toolbar
                    variant='dense'
                  >
                    <Tooltip title="Datei hochladen">
                      <IconButton aria-label="Edit" onClick={this.handleOpen("fileUpload")}>
                        <Button 
                          variant="contained"
                          onClick={this.handleOpen("fileUpload")}
                          size="small"
                        > 
                          Nachweis hochladen
                        </Button>
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                </Box>
                <ListOfFiles files={files} handleDelete={this.handleFileDelete} disabled={false} submittable submitted_file_ids={this.state.user.submitted_file_ids} checkable checked_file_ids={this.state.user.checked_file_ids} handleChangeSubmitted={this.handleChangeSubmitted} controller="ecrf_management" action="download_ecrf_file"/>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

EcrfUserOverview.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(EcrfUserOverview));
