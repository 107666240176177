import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcbobFileKindFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;
  console.log(props);

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <TextField
            margin="dense"
            id="sarcbobFileKindId"
            label="Name"
            value={obj.sarcbobFileKindId}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: false,
            }}
            options={props.selectOptions["sarcbobFileKindId"]}
            type="select"
            size="small"
          />
        </Grid>
  );
}
