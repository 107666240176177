import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import CreatableSelect from 'react-select/creatable';
import chroma from 'chroma-js';

const standardFunctionOptions = ["LKP", "Prüfer", "Stellvertreter", "SP", "äMP", "näMP", "SN", "CTA", "Studienkoordinator", "PM", "BM", "DM", "MO"];
const createOption = (label) => ({
  label,
  value: label,
  backgroundColor: 'white',
});
const standardOptions = {label: "StandardRollen", options: standardFunctionOptions.map(option => createOption(option))};

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    let color = chroma('black');
    if (data.color) {
      color = chroma(data.color);
    }
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

class TrialPersonFunctionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { functionOptions: [standardOptions]  }
    this.form = React.createRef();
  }

  componentDidMount(props) {
    let currentComponent = this;
    fetch('/trials/get_trial_roles', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      const newFunctionOptions = {label: "Spezialrollen", options: data.trial_roles.filter(f => !standardFunctionOptions.includes(f)).map(option => createOption(option))};
      // currentComponent.setState({ functionOptions: peopleOptions});
      currentComponent.setState({ functionOptions: [standardOptions, newFunctionOptions]});
    });
  }

  formatCreate = (inputValue) => {
    return (<p> Neue Spezialrollen hinzufügen: {inputValue} </p>);
  };

  showAdd = (inputValue, selectValue, selectOptions, props) => {
    if (selectOptions.length == 0){
      return true;
    } else {
      return false;
    }
  };

  test = (inputValue) => {
    console.log("onCreateOption");
    console.log(inputValue);
  };

  onCreateOption = event => {
    let newSpecialFunctionsOptions = {...this.state.functionOptions[1]};
    newSpecialFunctionsOptions["options"].push(createOption(event));
    const newFunctionOptions = [standardOptions, newSpecialFunctionsOptions]
    this.props.handleChange(event);
    this.setState({functionOptions: newFunctionOptions});
  }

  handleChange = (value, event, test1, test2) => {
    if (this.props.isMulti) {
      const valueArray = value.map(val => val.value);
      this.props.handleChange(valueArray, event);
    } else {
      this.props.handleChange(value.value, event);
    }
  }

  render(props) {
    const allOptions = [].concat.apply([], this.state.functionOptions.map(type => {
      return type.options;
    }));
    let selectedFunction = null;
    if (this.props.isMulti && this.props.selectedFunction) {
      selectedFunction = allOptions.filter(option => {
        return this.props.selectedFunction.includes(option.value);
      });
    } else {
      selectedFunction = allOptions.find(option => {
        return option.value == this.props.selectedFunction;
      });
    }
    // const selectedFunction = {label: "LKP", value: "LKP"};
    // console.log(selectedFunction);
    if (this.state.functionOptions) {
      return (
        <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 995}}>
          <InputLabel
            filled
            animated
            shrink
            margin="dense"
            variant="outlined"
            // error={!this.state.obj.trial_role}
          >
            {this.props.title}
          </InputLabel>
          <CreatableSelect
            value={selectedFunction}
            // onChange={this.props.handleChange}
            onChange={this.handleChange}
            options={this.state.functionOptions}
            onCreateOption={this.onCreateOption}
            formatCreateLabel={this.formatCreate}
            styles={colourStyles}
            // inputValue={this.props.inputValue}
            // onInputChange={(newValue, actionMeta) => this.props.handleInputValueChange(newValue, actionMeta)}
            autoFocus={this.props.autoFocus}
            // defaultMenuIsOpen={true}
            // onBlur={null}
            // isValidNewOption={this.showAdd}
            isMulti={this.props.isMulti}
            isDisabled={this.props.disabled}
            error={this.props.error}
          />
        </FormControl>
      )
    }
    else {
      return null;
    }
  }
}

export default TrialPersonFunctionSelector;
