import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from 'react-select';

class TrialBoardKlifoSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedKlifos: [], allKlifos: []}
    this.form = React.createRef();
  }

  componentDidMount(props) {
    let currentComponent = this;
    fetch('klifo_discussions/get_klifos', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      const klifos = data.klifos;
      currentComponent.setState({ allKlifos: klifos});
      if (currentComponent.props.klifoIds) {
        const newSelectedKlifos = klifos.filter(klifo => currentComponent.props.klifoIds.includes(klifo.id));
        currentComponent.setState({ selectedKlifos: newSelectedKlifos});
      }
    });
  }

  handleKlifosChange = event => {
    const selectedIds = (event) ? event.map(selection => selection.value) : [];
    const newSelectedKlifos = this.state.allKlifos.filter(klifo => selectedIds.includes(klifo.id));
    this.setState({selectedKlifos: newSelectedKlifos});
    if (this.props.handleChange) {
      this.props.handleChange(selectedIds);
    }
  }

  render() {
    const { classes, submissions, handleSubmissionsChange } = this.props;
    const klifoOptions = this.state.allKlifos.map((klifo) => {
      return (
        {value: klifo.id, label: klifo.topic}
      )
    });
    if (klifoOptions) {
      const selectedKlifoIds = this.state.selectedKlifos.map(klifo => klifo.id);
      const selectedKlifoOptions = klifoOptions.filter(sk => selectedKlifoIds.includes(sk.value));
      return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel filled animated shrink margin="dense" variant="outlined" >KliFoDis</InputLabel>
            <Select
              // defaultValue={}
              isMulti
              name="submissions"
              options={klifoOptions}
              onChange={this.handleKlifosChange}
              className="basic-multi-select"
              classNamePrefix="select"
              value={selectedKlifoOptions}
            />
          </FormControl>
        </div>
      )
    }
    else {
      return (<> Keine Klifos gefunden </>);
    }
  }
}

export default TrialBoardKlifoSelector;
