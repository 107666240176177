import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from './TextField.jsx';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import AddIcon from '@mui/icons-material/Edit';

import EnhancedTable from './EnhancedTable.jsx';
import TaskFields from './TaskFields.jsx';
import NewTaskDialog from './NewTaskDialog.jsx';
import RemindersList from './RemindersList.jsx';

const taskColumns = [
  {
    id: "date_due",
    label: "Fälligkeitsdatum",
    numeric: false,
    padding: 'none',
    date: true,
    type: "date",
  },
  {
    id: "title",
    label: "Titel",
    numeric: false,
    padding: 'none',
  },
  {
    id: "statusText",
    label: "Status",
    numeric: false,
    padding: 'none',
    type: "objectStatus",
    filterable: true,
  },
  // {
  //   id: "description",
  //   label: "Beschreibung",
  //   numeric: false,
  //   padding: 'none',
  //   type: "textNoBreak",
  // },

  {
    id: "nextReminderDate",
    label: "Erinnerungsdatum",
    numeric: false,
    padding: 'none',
    date: true,
    type: "date",
  },
];

// const setSelectedTasks = (rowData, allRowsSelected, allObjectsSelected) => {
//   return [allObjectsSelected, allRowsSelected];
// };

const handleSetTaskFilters = (filters, meta) => {
  if ((meta.action == "select-option") || (meta.action == "remove-value")) {
    let newTaskFilters = {...this.state.taskFilters};
    newTaskFilters[meta["name"]] = filters;
    return newTaskFilters;
    // this.setState({taskFilters: newTaskFilters});
  } else if (meta.action == "clear") {
    let newTaskFilters = {...this.state.taskFilters};
    delete newTaskFilters[meta["name"]];
    return newTaskFilters;
    // this.setState({taskFilters: newTaskFilters});
  }
}

export default function TaskListPaper(props) {
  const { tasks, selectedTasks, taskStatusList, selectableTaskStatusList, handleChange, handleChange2, handleEditStepClick, taskableType, taskableId, onTaskSelect } = props;
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [editTaskOpen, setEditTaskOpen] = useState(false);
  const [taskFilterOpen, setTaskFilterOpen] = useState(false);
  // const [selectedTasks, setSelectedTasks] = useState([]);
  const [taskFilters, setTaskFilters] = useState({});
  // const selectableStepStatusList = props.stepStatusList.filter(ss => (ss.value == "done" || ss.value == "active"));
  // console.log("taskPaper");
  // const taskListItems = tasks.map(task => {
  //   <ListItem>
  //     <TaskPaper
  //       task={task}
  //       // readOnly={true}
  //       taskStatusList={taskStatusList}
  //       statusEditable={true}
  //       selectableTaskStatusList={selectableTaskStatusList}
  //       enqueueSnackbar={props.enqueueSnackbar}
  //     />
  //   </ListItem>
  // })

    return (
    <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
      <NewTaskDialog
        // obj={obj}
        dueDateNotEditable={props.dueDateNotEditable}
        open={newTaskOpen}
        enqueueSnackbar={props.enqueueSnackbar}
        // handleAddOpen={this.handleEditProcessStepOpen}
        // handleSave={this.handleEditProcessStepSave}
        handleClose={() => setNewTaskOpen(false)}
        // selectedProcessStep={selectedProcessStep}
        // previousProcessStep={previousProcessStep}v
        // stepStatusList={stepStatusList}
        taskStatusList={taskStatusList}
        selectableTaskStatusList={selectableTaskStatusList}
        // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
        taskableType={taskableType}
        taskableId={taskableId}
      />
      <EnhancedTable
        title={"Merkzettel"}
        objectName={"Task"}
        data={tasks}
        allData={tasks}
        columns={taskColumns}
        rowsPerPage={5}
        order="asc"
        orderBy="date_due"
        controller="tasks"
        autoFill
        expanded
        selectable
        // singleSelect={true}
        selectedIds={selectedTasks.map(task => task.id)}
        selectedObjects={selectedTasks}
        onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => onTaskSelect(rowData, allRowsSelected, allObjectsSelected)}
        filters={taskFilters}
        // handleFilterClick={() => setTaskFilterOpen(!taskFilterOpen)}
        // onFilterChange={setTaskFilters((taskFilters, meta) => handleSetTaskFilters)}
        handleAddClick={() => setNewTaskOpen(true)}
        handleDuplicateClick={(selectedTasks.length == 1) ? props.handleDuplicateClick : null}
        handleDoneClick={(selectedTasks.length == 1 && (selectedTasks[0].status != "done")) ? props.handleDoneClick : null}
        // (selectedTask.taskable.status == "active" || selectedTask.status == "open")
        handleDeleteClick={(selectedTasks.length > 0) ? props.handleDeleteTaskConfirmationDialogOpen : null}
        onDoubleClick={props.onTaskDoubleClick}
        // handleLinkClick={this.handleTaskLinkClick}
        objectType="Task"
        multipleSelectable={true}
      />
    </Paper>
  )
}
