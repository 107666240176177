import React from 'react';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import { flexbox } from '@mui/system';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'
import EnhancedTable from './EnhancedTable.jsx';
import TextField from './TextField.jsx';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  content: {
      display: "flex",
      flexBasis: '100%',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-evenly',
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class InfoPersonSiteDialog extends React.Component {

  constructor(props) {
    super(props);
    // console.log("test");

    this.state = {open: this.props.open, selectedPerson: this.props.selectedPerson, addressSelection: {title: true, address: true, phone_number: true, mobile_number: true, institution: true, department: true, fax_number: true, name: true, email: true, salutation: false}, storyboardOpen: false};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPerson !== prevProps.selectedPerson) {
      // // this.getDepartments();
      // let departments = [];
      // if (this.props.selectedPerson.sites_people_associations) {
      //   const department_ids = this.props.selectedPerson.sites_people_associations.filter(sites_people_association => {
      //     return sites_people_association.site_id == this.props.selectedSite.id;
      //   }).map(sites_people_association => sites_people_association.department_id);
      //   departments = this.props.selectedPerson.site_departments.filter(department => {
      //     return department_ids.includes(department.id);
      //   }).filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i);
      // } else {
      //   departments = this.props.departments;
      // }
      // let salutation = "";
      // if (this.props.selectedPerson.gender == "m") {
      //   salutation = "Sehr geehrter Herr";
      // } else if (this.props.selectedPerson.gender == "w") {
      //   salutation = "Sehr geehrte Frau";
      // } else if (this.props.selectedPerson.gender == "?") {
      //   salutation = "Sehr geehrte(r) Frau/Herr";
      // }
      // let site_function = "";
      // if (this.props.selectedPerson.site_function != null) {
      //   site_function = this.props.selectedPerson.site_function;
      // }
      this.setState({ selectedPerson: this.props.selectedPerson});
    }
  }

  // getDepartments = () => {
  //   let currentComponent = this;
  //   // departments = this.this.props.peopleDepartmentsAssociations.filter(assoc => assoc.person_id == this.props.selectedPerson.id).map(assoc => assoc.department_id);
  //   currentComponent.setState({ departments: this.props.departments });
  //   // let currentComponent = this;
  //   // // const departments = this.state.departments;
  //   // fetch('/people/'+this.props.selectedPerson.id+'/get_departments', {
  //   //   credentials: 'include',
  //   // })
  //   // .then(function(response){return response.json();})
  //   // .then(function(data){
  //   //   // console.log(data);
  //   //   currentComponent.setState({ departments: data.departments, institutions: data.institutions });
  //   // });
  // }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let selectedPerson = {...this.state.selectedPerson};
    selectedPerson[name] = value;
    currentComponent.setState({ selectedPerson: selectedPerson });
  };

  handleChange2 = event => {
    let currentComponent = this;
    // const target = event.target;
    // const value = target.value;
    // const name = target.id;
    let newAddressSelection = Object.assign({}, this.state.addressSelection);
    // console.log(event.target.value);
    newAddressSelection[event.target.value] = newAddressSelection[event.target.value] ? false : true;
    currentComponent.setState({ addressSelection: newAddressSelection });
  };

  handleRemoveDepartment = (department_id) => {
    // console.log(department_id);
    let currentComponent = this;
    fetch('/people/'+this.props.selectedPerson.id+'/remove_from_department', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        department_id: department_id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        let newDepartments = [].concat(currentComponent.state.departments);
        const departmentIndex = newDepartments.findIndex(newDepartment => newDepartment.id == department_id);
        newDepartments.splice(departmentIndex,1);
        currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  };

  handleDoubleClickDepartment = event => {
    console.log(event);
    console.log("doubleClick")
  };

  handleStatusboxLockChange = event => {
    if (this.state.unlocked == false) {
      this.setState({unlocked: true})
    } else {
      this.setState({unlocked: false})
    }
  }

  handleGenderChange = event => {
    this.setState({ selectedGender: event, gender: event.value });
  }

  handleClose = event => {
    this.setState({selectedPerson: {}});
    this.props.handleClose();
  }

  handleStoryboardExpandChange = (val, title) => {
    this.setState({storyboardOpen: val});
  }

  render(props) {
    const { classes } = this.props;
    // const { departments } = this.state;
    const genderOptions = [
      {label: "Unbekannt", value: "?"},
      {label: "Männlich", value: "m"},
      {label: "Weiblich", value: "w"},
    ];

    let selectedGender = {};
    if (this.props.selectedPerson.gender == "m") {
      selectedGender = {label: "Männlich", value: "m"}
    } else if (this.props.selectedPerson.gender == "w") {
      selectedGender = {label: "Weiblich", value: "w"}
    } else if (this.props.selectedPerson.gender == "?") {
      selectedGender = {label: "Unbekannt", value: "?"}
    }
    let departments = [];
    if (this.props.selectedPerson.sites_people_associations) {
      const department_ids = this.props.selectedPerson.sites_people_associations.filter(sites_people_association => {
        return sites_people_association.site_id == this.props.selectedSite.id;
      }).map(sites_people_association => sites_people_association.department_id);
      departments = this.props.selectedPerson.site_departments.filter(department => {
        return department_ids.includes(department.id);
      }).filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i);
    } else {
      departments = this.props.departments;
    }
    let salutation = "";
    if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
      salutation = "Sehr geehrter Herr";
    } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
      salutation = "Sehr geehrte Frau";
    } else if (this.props.selectedPerson.gender == "?") {
      salutation = "Sehr geehrte(r) Frau/Herr";
    }

    const saveable = this.state.selectedPerson.lastName && this.state.selectedPerson.firstName  && (this.state.selectedPerson.gender != "" && this.state.selectedPerson.gender != null);
    var departmentChips = null;
    var handleRemoveDepartment = null;
    // if (this.state.unlocked) {
    //   handleRemoveDepartment = this.handleRemoveDepartment;
    // }
    let addresses = [];
    if (departments) {
      if (departments.length > 0) {
        departmentChips = departments.map((department) =>
          <Chip
            className={classes.chip}
            size="small"
            label={department.name}
            onDoubleClick={this.handleDoubleClickDepartment}
            onDelete={this.state.unlocked ? () => this.handleRemoveDepartment(department.id) : null}
            clickable
            color="primary"
          />
        );
        addresses = departments.map((department) => {
          let address = "\n";
          if (this.state.addressSelection.salutation && salutation) {
            address += salutation + " ";
          }
          if (this.state.addressSelection.title && this.state.selectedPerson.title) {
            address += this.state.selectedPerson.title + " ";
          }
          if (this.state.addressSelection.name) {
            address += this.state.selectedPerson.first_name + " " + this.state.selectedPerson.last_name;
          }
          if ((this.state.addressSelection.salutation && salutation) || (this.state.addressSelection.title && this.state.selectedPerson.title) || this.state.addressSelection.name) {
            address += "\n";
          }
          if (this.state.addressSelection.institution && this.state.selectedPerson.institution_name) {
            address += this.state.selectedPerson.institution_name + "\n";
          }
          if (this.state.addressSelection.department) {
            address += department.name + "\n";
          }
          if (this.state.addressSelection.address) {
            address += department.street + "\n" + department.zip + " " + department.city + "\n";
          }
          if (this.state.addressSelection.phone_number && this.state.selectedPerson.phone_number) {
            address += "Tel.: " + this.state.selectedPerson.phone_number + "\n";
          }
          if (this.state.addressSelection.mobile_number && this.state.selectedPerson.mobile_number) {
            address += "Mobil: " + this.state.selectedPerson.mobile_number + "\n";
          }
          if (this.state.addressSelection.fax_number && this.state.selectedPerson.fax_number) {
            address += "Fax: " + this.state.selectedPerson.fax_number + "\n";
          }
          if (this.state.addressSelection.email && this.state.selectedPerson.email) {
            address += "E-Mail: " + this.state.selectedPerson.email + "\n";
          }
          // console.log(address);
          return (
            <TextField
              margin="dense"
              id="addresses"
              label="Adressen"
              onChange={this.handleChange}
              fullWidth
              multiline
              variant="outlined"
              value={address}
              // error={!this.state.function}
              InputProps={{
                readOnly: true,
              }}
              type="text"
            />
          )
        })
      }
    }

    let forwardDisabled = false;
    let backwardDisabled = false;
    if ((!(this.props.handleForwardClick)) || ((this.props.selectedPersonIndex + 1) >= this.props.numPeople )) {
      forwardDisabled = true;
    }
    if ((!(this.props.handleBackwardClick)) || this.props.selectedPersonIndex == 0) {
      backwardDisabled = true;
    }

    // const name = (this.state.title ? this.state.title) +
    // storyboards = this.props.

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        fullWidth
        PaperProps={{style: {height: '900px'}}}
      >
        <DialogTitle id="form-dialog-title">
          {((this.state.selectedPerson.title != null) ? this.state.selectedPerson.title + " " : "") + this.state.selectedPerson.first_name + " " + this.state.selectedPerson.last_name}
          <Typography color="inherit" noWrap style={{fontSize: '8pt'}}>
            ID: {this.state.selectedPerson.id}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={6} style={{backgroundColor: blue[500]}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">

                <Typography className={classes.title} variant="h8" color="inherit" noWrap style={{marginLeft: 5}}>
                  Zentrumsspezifisch
                </Typography>
                <Tooltip title="Daten für Person im Zentrum editieren">
                  <IconButton aria-label="Edit" onClick={this.props.handleEditSpaClick}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <TextField
                  margin="dense"
                  id="site_function"
                  label="Funktion"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.site_function || ''}
                  // error={!this.state.function}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <FormControlLabel
                  style={{zIndex: 1}}
                  control={
                    <Checkbox
                      checked={this.state.selectedPerson.primary_contact}
                      onChange={this.handlePrimaryChange}
                    />
                  }
                  label="Primärer Kontakt"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="join_date"
                  label="Eintrittsdatum"
                  type="date"
                  value={this.state.selectedPerson.join_date}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <FormControlLabel
                  style={{zIndex: 1}}
                  control={
                    <Checkbox
                      checked={this.state.selectedPerson.left}
                      onChange={this.handleChange2}
                    />
                  }
                  label="Ausgeschieden"
                  name="left"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                { (this.state.selectedPerson.leave_date != null) &&
                  <TextField
                    id="leave_date"
                    label="Austrittsdatum"
                    type="date"
                    value={this.state.selectedPerson.leave_date}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                }
                <TextField
                  id="spa_comment"
                  label="Kommentar"
                  onChange={this.handleChange}
                  multiline
                  // className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={this.state.selectedPerson.spa_comment || ''}
                  fullWidth
                  style={{zIndex: 1}}
                  // disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="text"
                />
              </div>
              <EnhancedTable
                title={"Storyboard"}
                data={this.props.filteredNotes}
                singleSelect={false}
                selectedIds={this.props.selectedNoteIds}
                selectedObjects={this.props.selectedNotes}
                columns={this.props.noteColumns}
                handleAddClick={this.props.handleAddNoteOpen}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.props.handleNotesChange(rowData, allRowsSelected, allObjectsSelected)}
                handleEditClick={this.props.handleEditNoteClick}
                handleDeleteClick={this.props.handleDeleteNoteClick}
                rowsPerPage={5}
                onDoubleClick={this.props.handleNoteDoubleClick}
                selectable
                collapseEmpty
                orderBy="entry_date"
                order="desc"
                expanded={this.state.storyboardOpen}
                handleExpandChange={this.handleStoryboardExpandChange}
              />
            </Grid>
            <Grid item xs={6} style={{
              backgroundColor: green[500],
            }}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                <Typography className={classes.title} variant="h8" color="inherit" noWrap style={{marginLeft: 5}}>
                  Adressbuch
                </Typography>
                <Tooltip title="Person im Adressbuch editieren">
                  <IconButton aria-label="Edit" onClick={this.props.handleEditClick}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px',}}>
              <TextField
                margin="dense"
                id="function"
                label="Stelle"
                onChange={this.handleChange}
                fullWidth
                variant="outlined"
                value={this.state.selectedPerson.function || ''}
                InputProps={{
                  readOnly: true,
                }}
                type="string"
              />
              <FormControl component="fieldset">
                <FormLabel component="legend">Adressen</FormLabel>
                <FormGroup aria-label="position" onChange={this.handleChange2} name="position" row>
                  <FormControlLabel
                    value="salutation"
                    control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.gender)} checked={(salutation && this.state.addressSelection.salutation)} />}
                    label="Anrede"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="title"
                    control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.title)} checked={(this.state.addressSelection.title && this.state.selectedPerson.title)} />}
                    label="Titel"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="name"
                    control={<Checkbox color="primary" checked={this.state.addressSelection.name}/>}
                    label="Name"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="address"
                    control={<Checkbox color="primary" checked={this.state.addressSelection.address}/>}
                    label="Adresse"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="institution"
                    control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.institution_name)} checked={(this.state.addressSelection.institution && this.state.selectedPerson.institution_name)}/>}
                    label="Institution"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="department"
                    control={<Checkbox color="primary" disabled={(!departments)} checked={(this.state.addressSelection.department && departments)}/>}
                    label="Abteilung"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="phone_number"
                    control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.phone_number)} checked={(this.state.addressSelection.phone_number && this.state.selectedPerson.phone_number)}/>}
                    label="Telefonnummer"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="mobile_number"
                    control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.mobile_number)} checked={(this.state.addressSelection.mobile_number && this.state.selectedPerson.mobile_number)}/>}
                    label="Handynummer"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="fax_number"
                    control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.fax_number)} checked={(this.state.addressSelection.fax_number && this.state.selectedPerson.fax_number)}/>}
                    label="Fax-Nummer"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="email"
                    control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.email)} checked={(this.state.addressSelection.email && this.state.selectedPerson.email)}/>}
                    label="E-Mail"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
              {addresses}
              <TextField
                id="comment"
                label="Kommentar"
                onChange={this.handleChange}
                multiline
                // className={classes.textField}
                margin="dense"
                variant="outlined"
                value={this.state.selectedPerson.comment || ''}
                fullWidth
                style={{zIndex: 1}}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                type="text"
              />
              </div>
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center" width="100%">

            <div>
              {backwardDisabled ? (
                <IconButton aria-label="Zurück" disabled={backwardDisabled}>
                  <ArrowBackIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="Zurück" onClick={this.props.handleBackwardClick}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              {this.props.selectedPersonIndex + 1} von {this.props.numPeople}
              {forwardDisabled ? (
                  <IconButton aria-label="Forwärts" disabled={forwardDisabled}>
                    <ArrowForwardIcon />
                  </IconButton>
                ) : (
                  <IconButton aria-label="Forwärts" onClick={this.props.handleForwardClick}>
                    <ArrowForwardIcon />
                  </IconButton>
                )
              }
                </div>

            <Button onClick={this.handleClose} color="primary">
              Schließen
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}

InfoPersonSiteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoPersonSiteDialog);
