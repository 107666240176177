import React from 'react';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class DeleteTaskConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.form = React.createRef();
  }

  handleSave = event => {
    this.props.handleSave();
    this.props.handleClose();
  }

  render(props) {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={true}
      >
        <DialogTitle id="form-dialog-title">Tasks löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Dies löscht die Tasks vollständig. Sie können danach nur noch über den Audit Trail wiederhergestellt werden.
          </DialogContentText>
          <DialogContentText>
            Tasks: {this.props.selectedTasks.map(st => st.title).toString()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.props.handleSave()} color="primary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DeleteTaskConfirmationDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(DeleteTaskConfirmationDialog));
