import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import TrialPersonFunctionSelector from './TrialPersonFunctionSelector.jsx';

import TaskFields from './TaskFields.jsx';
import TaskPaper from './TaskPaper.jsx';

class EditTaskDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { obj: props.obj};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props.obj)
    if (this.props.obj !== prevProps.obj) {
      this.setState({ obj: this.props.obj });
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    currentComponent.setState({ obj: newObj });
  };

  handleChange2 = (event, meta) => {
    const value = event.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    this.setState({ obj: newObj });
  }

  handleFunctionChange = (newValue, actionMeta, test1, test2) => {
    console.log(newValue, actionMeta, test1, test2);
    let newObj = {...this.state.obj};
    newObj["trial_roles"] = newValue;
    console.log(newObj.trial_functions);
    this.setState({obj: newObj});
  }

  handleChangeCheckbox = (event, checked) => {
    // console.log(event.target.name);
    console.log(checked);
    let newObj = {...this.state.obj};
    newObj[event.target.name] = checked;
    this.setState({obj: newObj});
  }

  handleHasDeadlineChange = event => {
    let newObj = {... this.state.obj};
    newObj.date_deadline = null;
    newObj.deadline_days = null;
    this.setState({hasDeadline: (this.state.hasDeadline) ? false : true});
  }

  handleSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    if (obj.obj.taskable_type == "ProcessStep") {
      fetch('/trials/update_task', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          task: obj.obj,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        if (data.response == 1) {
          currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
          currentComponent.props.handleClose();
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/tasks/update', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          task: obj.obj,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        if (data.response == 1) {
          currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
          currentComponent.props.handleClose();
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
    // this.props.handleSave(Object.assign({}, this.state.obj, {deadline: deadline, date_status: date_status}));
  }

  render(props) {
    const { open, handleClose, taskStatusList, selectableTaskStatusList } = this.props;
    const { obj } = this.state;
    const saveable = obj.title && obj.status && obj.date_due && ((obj.recurring_cycle == null) || ((obj.recurring_cycle != null) && ((obj.recurring_number != null) && (obj.recurring_number > 0)) && (obj.recurring_type != null)));
    // define variables
    // console.log("editTaskDialog");
    const bgColor = ((obj.taskable_type == "Trial") || (obj.taskable_type == "ProcessStep")) ? blue[500] : 'white';
    // console.log(bgColor);
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '100%'}}}
      >
        <DialogTitle id="form-dialog-title">Task editieren</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start" style={{backgroundColor: bgColor}}>
            <Grid item xs={12} >
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <TaskFields
                  obj={obj}
                  dueDateNotEditable={this.props.dueDateNotEditable}
                  handleChange={this.handleChange}
                  handleChange2={this.handleChange2}
                  // handleChangeDate={this.handleChangeDate}
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  readOnly={false}
                  taskStatusList={taskStatusList}
                  statusEditable={true}
                  selectableTaskStatusList={selectableTaskStatusList}
                />
                { (!obj.taskable.activated) &&
                  <TrialPersonFunctionSelector
                    selectedFunction={obj.trial_roles}
                    handleChange={this.handleFunctionChange}
                    // handleInputValueChange={this.handleFunctionChange}
                    onCreateOption={this.handleCreateFunctionOption}
                    isMulti
                    error={((!obj.automatic_email && !obj.automatic_notification) || (obj.trial_roles.length > 0))}
                    title="Zu erinnernde Funktionen"
                  />
                }
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={!saveable}
            onClick={() => this.handleSave(this.state)}
            color="primary"
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditTaskDialog;
