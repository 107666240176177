import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can';
import Moment from 'moment'

import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import  EditDialog from './EditDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';
import CapasFields from './CapasFields.jsx';
const capasColumns = [
  {
    id: "id",
    label: "CAPAID",
    type: "integer"

  },
  {
    id: "description",
    label: "Beschreibung",
    type: "string"
  },
  {
    id: "severity",
    label: "Schweregrad",
    type: "integer"
  },
  {
    id: "resolution_date",
    label: "Auflösung_Datum",
    type: "date"
  }
 ];

class CapasIndex extends React.Component {

  constructor(props) {
    super(props);
    let user = this.props.user;
    this.state = { openDialogs: {}, capas: props.capas
    };
    this.form = React.createRef();
  }

  componentDidMount(props) {

  }

  handlePanelChange = panel => (event, expanded) => {
    let newExpanded = expanded;
    if (!this.state.user.name) {
      newExpanded = "user_details";
    } else if (!this.state.user.submitted_file_ids && expanded == "application") {
      newExpanded = "documents";
    }
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };
  handleCapaChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedCapas: allObjectsSelected,
      selectedCapaIds: allRowsSelected,
      // selectedCapaIndices: this.state.capas.findIndex(object => object.id === allRowsSelected[0]),
    });
  };
  handleOpen = (name) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }
  handleDoubleClick = (event, obj, name) => {
    let newSelectedIds = {... this.state.selectedIds};
    newSelectedIds[name] = [obj.id];
    this.setState({selectedIds: newSelectedIds});
    this.handleOpen("edit" + name)
  }
  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }
  handleCapaClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }
  handleCapasReceived = message => {
    console.log("received Capa");
    console.log(message);
    if (message.new_capa) {
      let capas = [].concat(this.state.capas);
      capas.push(message.new_capa);
      this.setState({ capas: capas });
      this.props.enqueueSnackbar('Capa wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_capa.short, {variant: 'success'});
    }
    if (message.capa) {
      let capas = [].concat(this.state.capas);
      const ind = capas.findIndex(capa => capa.id == message.capa.id);
      capas[ind] = message.capa;
      this.setState({ capas: capas });
      this.props.enqueueSnackbar('capa wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.capa.short, {variant: 'success'});
    }
    if (message.deleted_capa_ids) {
      let capas = [].concat(this.state.capas.filter(capa => !message.deleted_capa_ids.includes(capa.id)));
      let capaShorts = this.state.capas.filter(capa => message.deleted_capa_ids.includes(capa.id)).map(capa => capa.short);
      // message.deleted_capa_ids.forEach(function (capa_id, index) {
      //   console.log(capas);
      //   const ind = capas.findIndex(d => d.id == capa_id);
      //   capaShorts.push(capas[ind].short)
      //   delete capas[ind];
      // });
      // delete capaBoards[message.deleted_capa_board_ids];
      this.setState({ capas: capas });
      this.props.enqueueSnackbar('Capa wurde von Benutzer ' + message.username + ' gelöscht: ' + capaShorts, {variant: 'success'});
    }
  }
  handleAddCapaSave = (obj) => {
    const currentComponent = this;

    fetch('/capa/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        capa: obj,
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      if (handledData.response == 1) {
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        // currentComponent.props.handleFileUpload(handledData);
        // var patients = [... currentComponent.state.capas];
        // patients.push(handledData.new_capa);
        // currentComponent.setState({patients});
        let newOpenDialogs = { ... currentComponent.state.openDialogs };
        newOpenDialogs['addCapa'] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs });
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    })

  }
  handleUpdateCapaSave = (obj) => {
    const currentComponent = this;

    fetch('/capa/' + this.state.selectedCapaIds[0] + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        capa: obj,
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      if (handledData.response == 1) {
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        // currentComponent.props.handleFileUpload(handledData);
        // var patients = [... currentComponent.state.capas];
        // patients.push(handledData.new_capa);
        // currentComponent.setState({capas});
        let newOpenDialogs = { ... currentComponent.state.openDialogs };
        newOpenDialogs['editCapa'] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs });
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    })

  }
  handleSave = (obj, tableName) => {
    const currentComponent = this;
    // console.log(obj, tableName);

    fetch('/sarcbop_patient_data/save_' + tableName, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        obj: obj,
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      //onsole.log(handledData);
      if (handledData.response == 1) {
        //console.log(handledData);
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        // currentComponent.props.handleFileUpload(handledData);
        let newOpenDialogs = {... currentComponent.state.openDialogs};
        newOpenDialogs["fileUpload"] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs});
        currentComponent.handleClose("dataEntryEdit");
        currentComponent.handleClose("fileUpload");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    })
  };

  handleDeleteCapaClick = event => {
    let currentComponent = this;
    let newCapas = [];
    newCapas = newCapas.concat(currentComponent.state.capas);
    fetch('/capa/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        capa_ids: currentComponent.state.selectedCapaIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 0) {
        // const newCapas = currentComponent.state.capa.filter(capa => !currentComponent.state.selectedCapaIds.includes(capa.id));
        currentComponent.setState({ selectedCapas: [], selectedCapaIds: [] });
        currentComponent.props.enqueueSnackbar('capa(s) erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Konnte nicht gelöscht werden.', {variant: 'error'});
      }
    });
  }
  render() {
    const { openDialogs } = this.state;
    
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <ActionCableConsumer
          channel={{channel: "CapasChannel"}}
          onReceived={this.handleCapasReceived}
          // onConnected={this.handleConnected}
        />
        <Grid item xs={12}>
          <EnhancedTable
            title={"CAPAs"}
            data={this.state.capas}
            selectedIds={this.state.selectedCapaIds}
            selectedObjects={this.state.selectedCapas}
            columns={capasColumns}
            onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleCapaChange(rowData, allRowsSelected, allObjectsSelected)}
            rowsPerPage={25}
            onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "Capa")}
            handleDeleteClick={this.handleDeleteCapaClick}
            orderBy="pseudonym"
            order="asc"
            selectable
            autoFill
            expanded={true}
            handleExpandChange={this.handleExpandChange}
            // handleUploadClick={this.handleOpen("fileUpload")}
            handleAddClick={() => this.handleOpen("addCapa")}
            objectType="SarcPatient"
            multipleSelectable
          />
          {(this.state.openDialogs.addCapa) &&
          <EditDialog
            title="Capa hinzufügen"
            open={this.state.openDialogs.addCapa}
            handleSave={this.handleAddCapaSave}
            handleClose={() => this.handleClose("addCapa")}
            Fields={CapasFields}
            requiredFields={[]}
            selectOptions={{}}
          />
          }
           {(this.state.openDialogs.editCapa) &&
          <EditDialog
            title="Capa bearbeiten"
            open={this.state.openDialogs.editCapa}
            handleSave={this.handleUpdateCapaSave }
            handleClose={() => this.handleCapaClose("editCapa")}
            obj={this.state.selectedCapas[0]}
            Fields={CapasFields}
            requiredFields={[]}
            selectOptions={{}}
          />
          }
        </Grid>
      </Grid>
    

    )
  }
}

CapasIndex.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(CapasIndex));
