import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

// import moment from 'moment'
// import "moment/locale/de";

// moment.locale("de")

import ContractFields from './ContractFields.jsx';

class EditContractDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { obj: (props.selectedContract) ? {... props.selectedContract} : {}, hasExpiryDate: (props.selectedContract) ? (props.selectedContract.expiry_date != null) : false };
    this.form = React.createRef();
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    currentComponent.setState({ obj: newObj });
  };

  handleChange2 = (event, meta) => {
    const value = event.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    this.setState({ obj: newObj });
  }

  // handleChangeDate = (date, name) => {
  //   let newObj = {...this.state.obj};
  //   console.log(date, moment(date), moment(date).toISOString().substr(0,10));
  //   newObj[name] = moment(date).startOf("day").add(2, 'hour');
  //   this.setState({obj: newObj});
  // };

  handleHasExpiryDateChange = event => {
    let newObj = {... this.state.obj};
    newObj.expiry_date = null;
    this.setState({hasExpiryDate: (this.state.hasExpiryDate) ? false : true, obj: newObj});
  }

  handleSave = (state) => {
    // let expiry_date = moment(this.state.obj.expiry_date).toString();
    // let deadline = moment(this.state.obj.deadline).toString();
    // console.log(start_time, end_time);
    this.props.handleSave(this.state.obj);
    // this.props.handleSave(this.state);
    // this.setState({last_name: null, first_name: null, title: null, phone_number: null, mobile_number: null, fax_number: null, email: null, function: null});
  }

  render(props) {
    const saveable = this.state.obj.name && this.state.obj.status && this.state.obj.contract_type && (!this.state.hasExpiryDate || this.state.obj.expiry_date);
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Vertrag/Versicherung editieren</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: green[500]}}>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <ContractFields
                  contract={this.state.obj}
                  handleChange={this.handleChange}
                  handleChange2={this.handleChange2}
                  // handleChangeDate={this.handleChangeDate}
                  readOnly={false}
                  contractTypeList={this.props.contractTypeList}
                  statusList={this.props.statusList}
                  hasExpiryDate={this.state.hasExpiryDate}
                  handleHasExpiryDateChange={this.handleHasExpiryDateChange}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.handleSave(this.state)}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditContractDialog;
