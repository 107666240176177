import React from 'react';
import CreatableSelect from 'react-select/creatable';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

class DepartmentSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {departments: [], departmentsOptions: []}
    this.form = React.createRef();
  }

  componentDidMount(props) {
    if (this.props.selectedDepartmentId) {
      this.setState({selectedDepartment: {value: this.props.selectedDepartmentId}});
    }
    if (this.props.departments) {
      const departments = this.props.departments;
      const departmentsOptions = departments.map((department) => {
        return (
          {value: department.id, label: (department.name + "(" + department.cost_center + ")")}
        )
      })
      this.setState({ departmentsOptions: departmentsOptions});
    } else {
      if (this.props.siteId != null) {
        let currentComponent = this;
        fetch('/trials/'+this.props.trialId+'/site_selection/get_institution_departments?'+$.param({site_id: this.props.siteId}), {
          credentials: 'include',
        })
        .then(function(response){return response.json();})
        .then(function(data){
          const departments = data.institution_departments;
          // console.log(departments);
          const departmentsOptions = departments.map((department) => {
            return (
              {value: department.id, label: (department.name + "(" + department.institution.name + ")")}
            )
          })
          currentComponent.setState({ departmentsOptions: departmentsOptions});
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props.departments);
    if (this.props.departments !== prevProps.departments) {
      let departmentsOptions = [];
      for (var i = 0; i < this.props.departments.length; i++) {
        departmentsOptions.push({value: this.props.departments[i].id, label: this.props.departments[i].name + "(" + this.props.departments[i].id + ")"});
        if (this.props.selectedDepartmentId == this.props.departments[i].id) {
          this.setState({selectedDepartment: {value: this.props.departments[i].id, label: this.props.departments[i].name, backgroundColor: 'white' }});
        }
      }
      this.setState({ departments: this.props.departments, departmentsOptions: departmentsOptions });
    }
  }

  render(props) {
    if (this.state.departmentsOptions) {
      return (
        <CreatableSelect
          value={this.props.selectedDepartment}
          onChange={this.props.handleChange}
          options={this.state.departmentsOptions}
          onCreateOption={this.props.onCreateOption}
          formatCreateLabel={this.props.formatCreate}
          autoFocus={this.props.autoFocus}
          inputValue={this.props.inputValue}
          onInputChange={(newValue, actionMeta) => this.props.handleInputValueChange(newValue, actionMeta)}
          defaultMenuIsOpen={true}
        />
      )
    }
    else {
      return null;
    }
  }
}

export default DepartmentSelector;
