import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import SiteSelector from './SiteSelector.jsx';
import DepartmentSelector from './DepartmentSelector.jsx';

class NewPersonDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { last_name: this.props.lastName, gender: "", connect: true, primary_contact: false };
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastName !== prevProps.lastName) {
      this.setState({ inputValue: this.props.lastName, last_name: this.props.lastName });
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleGenderChange = event => {
    this.setState({ selectedGender: event, gender: event.value });
  }

  handleConnectChange = event => {
    this.setState({ connect: event.target.value });
  }

  handlePrimaryChange = event => {
    this.setState({primary_contact: event.target.checked});
  }

  handleSave = (state) => {
    this.props.handleSave(this.state);
    this.setState({last_name: null, first_name: null, title: null, phone_number: null, mobile_number: null, fax_number: null, email: null, function: null});
  }

  render(props) {
    const saveable = this.state.last_name && this.state.first_name && (this.state.gender != "");
    const genderOptions = [
      {label: "Unbekannt", value: "?"},
      {label: "Männlich", value: "m"},
      {label: "Weiblich", value: "w"},
    ];
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Neue Person im Adressbuch anlegen</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: green[500]}}>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="last_name"
                  label="Nachname"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.last_name}
                  error={!this.state.last_name}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="first_name"
                  label="Vorname"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.first_name}
                  error={!this.state.first_name}
                  type="string"
                />
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
                  <InputLabel
                    filled
                    animated
                    shrink
                    margin="dense"
                    variant="outlined"
                    error={this.state.gender == ""}
                  >
                    Geschlecht
                  </InputLabel>
                  <Select
                    value={this.state.selectedGender}
                    onChange={this.handleGenderChange}
                    options={genderOptions}
                    // isValidNewOption={this.showAdd}
                  />
                </FormControl>
                <TextField
                  margin="dense"
                  id="title"
                  label="Titel"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.title}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="phone_number"
                  label="Telefonnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.phone_number}
                  // error={!this.state.phone_number}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="mobile_number"
                  label="Telefonnummer (mobil)"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.mobile_number}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="fax_number"
                  label="Fax"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.fax_number}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="E-Mail"
                  type="email"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.email}
                />
                <TextField
                  margin="dense"
                  id="function"
                  label="Stelle"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.function}
                  // error={!this.state.function}
                  type="string"
                />
                <TextField
                  id="comment"
                  label="Kommentar"
                  onChange={this.handleChange}
                  multiline
                  // className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={this.state.comment}
                  fullWidth
                  style={{zIndex: 1}}
                  type="text"
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.handleSave(this.state)}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewPersonDialog;
