import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
// import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { AbilityContext } from './Can.js';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, grey } from '@mui/material/colors';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import logo from '../../assets/images/Nct_01.svg.png';

import moment from 'moment';

import MainMenuDrawer from './MainMenuDrawer.jsx';
import NotificationBell from './NotificationBell.jsx';
import TrialSelect from './TrialSelect.jsx';
// import theme from './theme.js';
// import ability from './ability';


// const theme = createTheme({
//   palette: {
//     primary: {main: blue[200]},
//     secondary: {main: blue[800]},
//   },
// });

// const styles = theme => ({
//   root: {
//     width: '100%',
//   },
//   grow: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginLeft: -12,
//     marginRight: 20,
//   },
//   title: {
//     display: 'none',
//   },
//   search: {
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: grey[0],
//     '&:hover': {
//       backgroundColor: grey[0],
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: '100%',
//   },
//   searchIcon: {
//     width: theme.spacing(9),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   inputRoot: {
//     color: 'inherit',
//     width: '100%',
//   },
//   inputInput: {
//     paddingTop: theme.spacing,
//     paddingRight: theme.spacing,
//     paddingBottom: theme.spacing,
//     paddingLeft: theme.spacing(10),
//     transition: theme.transitions.create('width'),
//     width: '100%',
//   },
//   sectionDesktop: {
//     display: 'none',
//   },
//   sectionMobile: {
//     display: 'flex',
//   },
//   ApplicationBar: {
//       paddingLeft: '5px',
//       backgroundColor: '#ffff',
//   },
// });

class ApplicationBar extends React.Component {

  constructor(props) {
    super(props);
    console.log(props.user);
    if (!props.user && (window.location.pathname != "/" || window.location.pathname != "/login" || window.location.pathname != "/signup")) {
      console.log(window.location.pathname);
      // setTimeout(window.location.assign('/login'), 500);
    }
    this.state = {trials: [], anchorEl: null, mobileMoreAnchorEl: null, obj_id: this.props.obj_id, user: props.user, notifications: this.props.notifications, notificationInfos: [], notifications: []};
    this.form = React.createRef();
  }

  componentDidMount = (props) => {
    this.updateNotifications();
    // console.log("state: " + this.props.notifications);
    // console.log(data)
  };

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.obj)
    // if (this.state.notifications !== prevState.notifications) {
    //   this.setState({ obj: this.props.obj });
    // }
  }

  markNotificationRead = (props) => {
    // console.log(props);
    let currentComponent = this;
    fetch('/notifications/' + props + '/mark_read', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 0){
        currentComponent.props.enqueueSnackbar('Notification konnte nicht als gelesen markiert werden. Bitte Administrator kontaktieren.', {variant: 'error'});
      }
    });
  };

  action = (key, id, link) => {
    console.log(key, id, link);
    // markNotificationUnread(id);
    return (
      <>
        <Button onClick={() => { 
          // this.props.closeSnackbar(key);
          // this.markNotificationRead(id);
          window.location.assign("/");
            }}>
            'Dashboard'
        </Button>
        <Button onClick={() => { 
          // this.props.closeSnackbar(key);
          // this.markNotificationRead(id);
          window.location.assign(link);
            }}>
            'Anzeigen'
        </Button>
        <Button onClick={() => { 
          this.markNotificationRead(id);
            }}>
            'Gelesen'
        </Button>
      </>
    )
  };

  onTrialSelectSubmit = () => {
    // console.log(event);
    if ((this.props.action != 'show') && (this.props.action != undefined) && (this.props.action != 'index')) {
      window.location.assign('/' + this.props.controller + '/' + event.value + '/' + this.props.action);
    } 
    else {
      window.location.assign('/' + this.props.controller + '/' + event.value);
    }
    // <Redirect to='/target' />
    // this.props.history.push(`/target`)
    // const data = new FormData(this.state.value);
    // this.form.current.submit(data);
    // event.preventDefault();
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleLogOut = () => {
    this.handleMenuClose();
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
    fetch('/logout', {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(){
      setTimeout(window.location.assign('/'), 500);
    });
  };

  handleProfileClick = () => {
    this.handleMenuClose();
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  // componentDidMount = (props) => {
  //   let currentComponent = this;
  //   currentComponent.updateTrials();
  // }

  updateTrials = (props) => {
    let currentComponent = this;
    // console.log(currentComponent);
    fetch('/trials/get_trials', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      // let trialsList = [];
      // for (var i = 0; i < data.trials.length; i++) {
      //   trialsList.push({value: data.trials[i].id.toString(), label: data.trials[i].short + "(" + data.trials[i].id + ")"});
      // }
      // console.log(trialsList);
      // console.log(colourOptions);
      currentComponent.setState({ trials: data.trials });
      // currentComponent.setState({ trials: data.trials });
    });
  }

  updateNotifications = (props) => {
    // console.log("updateNotifications");
    let currentComponent = this;
    fetch('/notifications', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.notifications) {
        const currentNotificationIds = currentComponent.state.notifications.map(notification => notification.id);
        let newNotifications = [].concat(data.notifications);
        data.notifications.filter(notification => (notification.important == true && notification.read == false && !currentNotificationIds.includes(notification.id))).forEach(notification => {
          currentComponent.props.enqueueSnackbar('Erinnerung: ' + notification.metadata.title + " fällig am " + moment(notification.metadata.deadline).format('dd DD.MM.YYYY'), {variant: 'warning', persist: true, action: (key) => {
            newNotifications.find(nn => notification.id == nn.id)["key"] = key;
            return currentComponent.action(key, notification.id, notification.metadata.link)
          }});
        })
        currentComponent.setState({ notifications: newNotifications, unread_num: data.unread_num });
      }
    });
  };

  handleTestReceived = message => {
    console.log("received Test Applicationbar");
    console.log(message);
    if (message.message) {
      this.props.enqueueSnackbar('Test message: ' + message.message, {variant: 'success', action: this.action});
    }
    if (message.important) {
      this.props.enqueueSnackbar('Test message: ' + message.important_message, {variant: 'success', persist: true, action: this.action});
    }
  }

  handleNotificationReceived = message => {
    console.log("received Notification");
    console.log(message, this.state.notifications.map(notification => notification.id));
    var newNotifications = [].concat(this.state.notifications);
    // console.log(this.state.notifications.map(notification => notification.id), newNotifications.map(notification => notification.id));
    if (message.notification && !this.state.notifications.map(notification => notification.id).includes(message.notification.id)) {
      if (!message.notification.important) {
        this.props.enqueueSnackbar('Test message: ' + message.notification.message, {variant: 'success', action: this.action});
      } else {
        // console.log("Running loop");
        console.log("Test");
        this.props.enqueueSnackbar('Erinnerung: ' + message.notification.metadata.title + " fällig am " + moment(message.notification.metadata.deadline).format('dd DD.MM.YYYY'), {variant: 'warning', persist: true, action: (key) => {
          // console.log(key);
          const nn = message.notification;
          nn["key"] = key;
          // console.log(newNotifications.map(notification => notification.id));
          if (!newNotifications.map(notification => notification.id).includes(nn.id)){
            newNotifications.push(nn);
          }
          // console.log(newNotifications.map(notification => notification.id));
          // newNotifications.find(nn => notification.id == nn.id)["key"] = key;
          return this.action(key, message.notification.id, message.notification.metadata.link)
        }});
      }
      
    } else if (message.read_notification) {
      const notification_ind = newNotifications.findIndex(notification => message.read_notification.id == notification.id);
      newNotifications[notification_ind] = message.read_notification;
      this.props.closeSnackbar(this.state.notifications[notification_ind].key);
    }
    const new_unread_num = newNotifications.filter(notification => notification.read == false).length;
    this.setState({notifications: newNotifications, unread_num: new_unread_num});
  }

  handleConnected = event => {
    console.log("connected", event);
  }

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    // const { classes } = this.props;
    // console.log(this.props.abilities);
    // ability.update(this.props.abilities);
    // console.log(ability);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleProfileClick}>Profil</MenuItem>
        <MenuItem onClick={this.handleLogOut}>Logout</MenuItem>
      </Menu>
    );

    // const trialsList = [];
    // for (var i = 0; i < this.state.trials.length; i++) {
    //   // console.log({value: this.state.trials[i].id.toString(), label: this.state.trials[i].short + "(" + this.state.trials[i].id + ")"});
    //   trialsList.push({value: this.state.trials[i].id, label: this.state.trials[i].short + "(" + this.state.trials[i].id + ")"});
    // }

    // const selectedTrial = { value: this.props.selectedTrial.id };

    let objSelect;
    // console.log("trialsList: ");
    // console.log(trialsList)
    if (this.props.controller == "trials" || (this.props.controller == "time_registration" && this.props.action != "show_stats")) {
      objSelect = <TrialSelect trials={this.props.trials} selectedTrialId={parseInt(this.state.obj_id)} controller={this.props.controller} action={this.props.action} style={{zIndex: 999}}/>
    } else {
      // console.log(this.props.controller);
      objSelect = null;
    }

    let titleBackgroundColor = 'white';
    if (this.props.controller == "trials") {
      titleBackgroundColor = blue[500];
    }
    const mainRoleAssoc = (this.state.user && this.state.user.user_roles) ? this.state.user.user_roles.find(assoc => assoc.is_main_role) : {};
    const mainRole = (this.state.user && this.state.user.roles && this.state.user.id && mainRoleAssoc) ? this.state.user.roles.find(role => role.id == (mainRoleAssoc.role_id)) : {};
    // console.log(theme);
    return (
        <div>
          <ActionCableConsumer
            channel={{channel: "TestChannel"}}
            onReceived={this.handleTestReceived}
            onConnected={this.handleConnected}
          />
          { (this.state.user) &&
          <ActionCableConsumer
            channel={{channel: "NotificationsChannel", user_id: this.state.user.id}}
            onReceived={this.handleNotificationReceived}
            onConnected={this.handleConnected}
          />}
          <AppBar color="neutral" position="fixed" style={{zIndex: 990}}>
            <Toolbar>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" style={{width: '100%'}}>
                <Box display="flex" flexDirection="row" justifyContent="left" flexShrink={true} flexWrap="nowrap" style={{width: '100%'}}>
                  {((this.props.action != "home" || (this.props.action == "home" && this.props.title == "Dashboard")) && this.props.controller != "sessions") &&
                    <MainMenuDrawer controller={this.props.controller} obj_id={this.props.obj_id}/>
                  }
                  <img src={logo} alt="NCT Studienzentrale" style={{width:90, height:35}}/>
                  <Typography variant="h5" color="inherit" noWrap style={{marginLeft: 5}}>
                    TOPAS
                  </Typography>
                  { (this.props.root_url == "http://localhost:3000/") && 
                    <Typography variant="h6" noWrap style={{marginLeft: 5, backgroundColor: "red"}}>
                      Dev
                    </Typography>
                  }
                  { (this.props.root_url == "http://nct-vm05/" || this.props.root_url == "https://topas.nct-heidelberg.de/") && 
                    <Typography variant="h6" noWrap style={{marginLeft: 5, backgroundColor: "red"}}>
                      Test
                    </Typography>
                  }
                  <Typography variant="h6" color="inherit" style={{marginLeft: 20, backgroundColor: titleBackgroundColor}}>
                    {this.props.title}
                  </Typography>
                </Box>
                
                {((this.props.action != "home" || (this.props.action == "home" && this.props.title == "Dashboard")) && this.props.controller != "sessions") &&
                  <Box display="flex" flexDirection="row" justifyContent="right" flexShrink={true} flexWrap="nowrap" style={{width: '100%'}}>
                    {objSelect}
                    <NotificationBell notifications={this.state.notifications} unread_num={this.state.unread_num} controller={this.props.controller} obj_id={this.props.obj_id} handleSeen={this.markNotificationRead} />

                    <IconButton
                      aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleProfileMenuOpen}
                      color="inherit"
                      data-cy="profil"
                    >
                      <AccountCircle />
                      { (this.state.user && mainRole) ? this.state.user.name + "(" + mainRole.name + ")" : null}
                    </IconButton>
                  </Box>
                }
                
              </Box>
            </Toolbar>
          </AppBar>
          {renderMenu}
        </div>
    );
  }
}

ApplicationBar.propTypes = {
  // classes: PropTypes.object.isRequired,
};

ApplicationBar.contextType = AbilityContext;

export default withSnackbar(ApplicationBar);
