import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import TrialSelect from './TrialSelect.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function EcrfApplicationFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly, selectOptions, hiddenFields } = props;
  console.log(selectOptions);
  // console.log(obj, readOnly);
  // console.log(props.hiddenFields);
  const functionsOptions = (props.selectOptions["function"] && props.selectOptions["function"][obj.ecrf_trial_configuration_id]) ? props.selectOptions["function"][obj.ecrf_trial_configuration_id] : [];
  const rolesOptions = (props.selectOptions["role"] && props.selectOptions["role"][obj.ecrf_trial_configuration_id] && props.selectOptions["role"][obj.ecrf_trial_configuration_id][obj.ecrf_trial_function_id]) ? props.selectOptions["role"][obj.ecrf_trial_configuration_id][obj.ecrf_trial_function_id] : [];
  const siteOptions = (props.selectOptions["site"] && props.selectOptions["site"][obj.ecrf_trial_configuration_id]) ? props.selectOptions["site"][obj.ecrf_trial_configuration_id] : [];
  // // console.log(props.selectOptions["function_id"]);
  // console.log(props.selectOptions["ecrf_trial_role_id"], obj.ecrf_trial_configuration_id, obj.function_id, obj.role_id);
  // if (props.selectOptions["ecrf_trial_role_id"]) {
  //   console.log(props.selectOptions["ecrf_trial_role_id"])
  //   if (props.selectOptions["ecrf_trial_role_id"][obj.ecrf_trial_configuration_id]) {
  //     console.log(props.selectOptions["ecrf_trial_role_id"][obj.ecrf_trial_configuration_id], obj.ecrf_trial_function_id)
  //     if (props.selectOptions["ecrf_trial_role_id"][obj.ecrf_trial_configuration_id][obj.ecrf_trial_function_id]) {
  //       console.log(props.selectOptions["ecrf_trial_role_id"][obj.ecrf_trial_configuration_id][obj.function_id])
  //     }
  //   }
  // }
  // console.log(props.selectOptions["ecrf_trial_role_id"]);
  // console.log(rolesOptions);
  // console.log(functionsOptions);
  const selectedFunctionName = (obj.ecrf_trial_function_id && functionsOptions.length > 0) ? functionsOptions.find(opt => opt.value == obj.ecrf_trial_function_id).label : "";
  console.log(selectedFunctionName);
  const handleChangeFunction = (event) => {
    if (selectedFunctionName == "Prüfstelle") {
      const fakeEvent = {target: {type: "react-select", id: "site_id", value: null}}
      // console.log(fakeEvent, selectedFunctionName);
      props.handleChange(fakeEvent )
    }
    props.handleChange(event);
  }
  const handleChangeTrial = (event) => {
    const fakeEvent1 = {target: {type: "react-select", id: "site_id", value: null}};
    props.handleChange(fakeEvent1 );
    const fakeEvent2 = {target: {type: "react-select", id: "ecrf_trial_function_id", value: null}};
    props.handleChange(fakeEvent2 );
    const fakeEvent3 = {target: {type: "react-select", id: "ecrf_trial_role_id", value: null}};
    props.handleChange(fakeEvent3 );
    props.handleChange(event);
  }
  // (functionsOptions.length > 0) ? functionsOptions.find(opt => opt.value == obj.function_id).label : "";
  // console.log(selectedFunctionName);
  // console.log((functionsOptions, rolesOptions))
  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            {/* <TrialSelect 
              selectedTrialId={obj.trial_id}
              style={{zIndex: 990}}
              // disabled={true}
              noSubmit
              handleChange={handleChange}
            /> */}
            <TextField
              margin="dense"
              id="ecrf_trial_configuration_id"
              label="Studie"
              value={obj.ecrf_trial_configuration_id}
              fullWidth
              handleChange={handleChangeTrial}
              // required={props.requiredFields.includes("short")}
              disabled={readOnly || props.admin}
              type="select"
              options={selectOptions["trial_id"]}
              size="small"
            />
            <TextField
              margin="dense"
              id="ecrf_trial_function_id"
              label="Funktion in Studie"
              value={obj.ecrf_trial_function_id}
              fullWidth
              handleChange={handleChangeFunction}
              // required={props.requiredFields.includes("short")}
              disabled={readOnly}
              type="select"
              options={functionsOptions}
              size="small"
            />
            <TextField
              margin="dense"
              id="site_id"
              label="Prüfstelle"
              value={obj.site_id}
              fullWidth
              // style={{zIndex: 988}}
              handleChange={handleChange}
              required={selectedFunctionName == "Prüfstelle"}
              disabled={readOnly || selectedFunctionName != "Prüfstelle"}
              type="select"
              options={siteOptions}
              size="small"
              // disabled={selectedFunctionName != "Prüfstelle"}
            />
            <TextField
              margin="dense"
              id="ecrf_trial_role_id"
              label="Rolle in Studie"
              value={obj.ecrf_trial_role_id}
              fullWidth
              // style={{zIndex: 988}}
              handleChange={handleChange}
              // required={props.requiredFields.includes("short")}
              disabled={readOnly}
              type="select"
              options={rolesOptions}
              size="small"
            />
            <TextField
              margin="dense"
              id="date_submitted"
              label="Antragsdatum"
              value={obj.date_submitted}
              fullWidth
              // style={{zIndex: 988}}
              handleChange={handleChange}
              // required={props.requiredFields.includes("short")}
              disabled={readOnly || props.admin || (props.disabledFields && props.disabledFields.includes("date_submitted"))}
              type="date"
              size="small"
              hidden
            />
            <TextField
              margin="dense"
              id="status"
              label="Status"
              value={obj.status}
              fullWidth
              // style={{zIndex: 988}}
              handleChange={handleChange}
              // required={props.requiredFields.includes("short")}
              disabled={true}
              // (readOnly || (props.disabledFields && props.disabledFields.includes("status"))) && (props.admin == null || props.admin == false)
              type="select"
              options={selectOptions["status"]}
              size="small"
            />
            <TextField
              margin="dense"
              id="username"
              label="Benutzername"
              value={obj.username}
              fullWidth
              // style={{zIndex: 1}}
              handleChange={handleChange}
              required={props.requiredFields && props.requiredFields.includes("username")}
              // error={!obj.name}
              disabled={((readOnly || (props.disabledFields && props.disabledFields.includes("username"))) && !props.admin)}
              type="string"
              size="small"
              // hidden={props.hiddenFields && props.hiddenFields.includes("username")}
              hidden
            />
          </Grid>
        </Grid>
  );
}
