import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { save } from 'save-file';

import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";

import { withSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import TrialPeople from './TrialPeople.jsx';
import AddPersonToTrialDialog from './AddPersonToTrialDialog.jsx';
import InfoPersonTrialDialog from './InfoPersonTrialDialog.jsx';
import EditPersonDialog from './EditPersonDialog.jsx';
import EditPtaDialog from './EditPtaDialog.jsx';

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    console.log("key length not equal")
    return false;
  }

  for (let key of keys1) {
    if (object1[key] != object2[key]) {
      console.log("unequal", key);
      return false;
    }
    else console.log("equal", key);
  }

  return true;
}

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 != val2
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

const peopleColumns = [
  {
    id: "primary_contact",
    label: "*",
    numeric: false,
    disablePadding: false,
    type: "starMark",
    filterable: true,
  },
  {
    id: "trial_role",
    label: "Funktion",
    numeric: false,
    disablePadding: false,
    type: "string",
    filterable: true,
  },
  {
    id: "last_name",
    secondary: "last_name",
    label: "Name",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "phone_number",
    secondary: "phone_number",
    label: "Tel.",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "email",
    label: "E-Mail",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "user",
    label: "User",
    numeric: false,
    disablePadding: true,
    type: "usericon"
  },
  {
    id: "pta_comment",
    label: "Kommentar",
    numeric: false,
    disablePadding: false,
    type: "comment",
  },
  {
    id: "overview_show",
    label: "Übersichtsanzeigestatus",
    type: "hidden",
    filterable: true,
  },
  {
    id: "left",
    label: "Ausgeschieden",
    type: "hidden",
    filterable: true,
  },
];

class TrialPeopleWrapper extends React.Component {
  constructor(props) {
    super(props);
    let pta = {};
    if (this.props.people_trials_associations) {
      pta = this.props.people_trials_associations.map(selected_pta => {
        // console.log(selected_pta);
        let ret = Object.assign({}, selected_pta.person, selected_pta);
        delete ret["person"];
        // ret["pta_comment"] = selected_pta.comment;
        // ret["comment"] = selected_pta.person.comment;
        return (
          ret
        )
      })
    }

    this.state = {trial: this.props.trial, peopleDepartmentsAssociations: this.props.peopleDepartmentsAssociations, people_departments: props.people_departments, people_institutions: props.people_institutions, people_trials_associations: pta, selectedPeopleIds: [], selectedPeople: [], selectedPeopleIndices: [], peopleFilters: {left: {label: "Nicht Ausgeschieden", value: false}, primary_contact: null}, openDialogs: {data: false, addPersonToTrial: false, infoPersonDialog: false}, user: props.user};
    // this.setState({trial: this.props.trial});
    this.form = React.createRef();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.people_trials_associations != this.state.people_trials_associations) {
  //     let pta = {};
  //     if (this.props.people_trials_associations) {
  //       pta = this.props.people_trials_associations.map(selected_pta => {
  //         // console.log(selected_pta);
  //         let ret = Object.assign({}, selected_pta.person, selected_pta);
  //         // ret["pta_comment"] = selected_pta.comment;
  //         // ret["comment"] = selected_pta.person.comment;
  //         return (
  //           ret
  //         )
  //       })
  //     }
  //     this.setState({people_trials_associations: pta});
  //   }
  // }

  handleEditTrialDataOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editData"] = true;
    this.setState({openDialogs: openDialogs});
  }

  handleEditTrialAdditionalInformationOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editAdditionalInformation"] = true;
    this.setState({openDialogs: openDialogs});
  }

  handleEditTrialAdditionalInformationClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editAdditionalInformation"] = false;
    this.setState({openDialogs: openDialogs});
  }

  handleEditTrialDataClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editData"] = false;
    this.setState({openDialogs: openDialogs});
  }

  handlePeopleChange = (rowData, allRowsSelected, allObjectsSelected) => {
    if (allObjectsSelected.findIndex(obj => obj.missingRow) == -1) {
      const indices = allRowsSelected.map(personId => this.state.people_trials_associations.findIndex(person => person.person_id == personId));
      const selectedPeople = allObjectsSelected;
      // .map(selectedPerson => selectedPerson.person)
      this.setState({
        // allPeopleRowsSelected: allRowsSelected,
        selectedPeople: selectedPeople,
        selectedPeopleIds: allRowsSelected,
        selectedPeopleIndices: indices,
      });
    }
  };

  handleMailToPeople = event => {

  }

  // handleInfoPersonClick = event => {

  // }

  handleAddPersonToTrialOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["addPersonToTrial"] = true;
    this.setState({openDialogs: openDialogs});
  }

  handleAddPersonToTrialClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["addPersonToTrial"] = false;
    this.setState({openDialogs: openDialogs});
  }

  handleFilterPeopleClick = event => {

  }

  handleInfoPersonClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["infoPersonDialog"] = false;
    this.setState({openDialogs: openDialogs});
  }

  handlePersonDoubleClick = (event, obj) => {
    if (!obj.missingRow) {
      var openDialogs = {...this.openDialogs};
      openDialogs["infoPersonDialog"] = true;
      var allObjectsSelected = [obj];
      var selectedPeopleIds = [obj.id];
      var selectedPeopleIndices = [this.state.people_trials_associations.findIndex(object => object.id === obj.id)];
      const selectedPeople = allObjectsSelected;
      // .map(selectedPerson => selectedPerson.person);
      this.setState({
        selectedPeople: allObjectsSelected,
        selectedPeopleIds: selectedPeopleIds,
        selectedPeopleIndices: selectedPeopleIndices,
        openDialogs: openDialogs,
      });
    }
  }

  handleAddPersonToTrialSave = obj => {
    let currentComponent = this;
    this.setState({ newSiteOpen: false});
    fetch('/trials/'+this.state.trial.id+'/execute_add_person', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({...obj}),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // const newPeopleTrialsAssociations = data.people_trials_associations.map(selected_pta => {
        //   let ret = Object.assign({}, selected_pta.person, selected_pta);
        //   return (
        //     ret
        //   )
        // });
        // currentComponent.setState({ people_trials_associations: newPeopleTrialsAssociations });
        // // currentComponent.handleFilterUpdate(newSites)
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
        currentComponent.handleAddPersonToTrialClose();
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Person existiert bereits mit dieser Funktion.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.forceUpdate();
  };

  handleEditPtaOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editPtaDialog"] = true;
    this.setState({ openDialogs: openDialogs });
  };

  handleEditPtaClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editPtaDialog"] = false;
    this.setState({ openDialogs: openDialogs});
  };

  handleEditPtaSave = (person) => {
    let currentComponent = this;
    fetch('/trials/update_people_trials_association', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify(person),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // let newPta = Object.assign({}, data.pta.person, data.pta);
        // delete newPta["person"];
        // var newPeopleTrialsAssociations = [].concat(currentComponent.state.people_trials_associations);
        // const index = newPeopleTrialsAssociations.findIndex(pta => pta.id === newPta.id);
        // newPeopleTrialsAssociations[index] = newPta;
        // let selectedPeople = [newPeopleTrialsAssociations[index]];
        var openDialogs = {...currentComponent.openDialogs};
        openDialogs["editPtaDialog"] = false;
        currentComponent.setState({ openDialogs: openDialogs});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditPersonOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editPersonDialog"] = true;
    this.setState({ openDialogs: openDialogs });
  };

  handleEditPersonClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editPersonDialog"] = false;
    this.setState({ openDialogs: openDialogs});
  };

  handleEditPersonSave = (person) => {
    let currentComponent = this;
    fetch('/people/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify(person),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // // console.log(data);
        // // let p = Object.assign({}, data.person);
        // // console.log(p);
        // // delete p["id"];
        // var newPeopleTrialsAssociations = [].concat(currentComponent.state.people_trials_associations);
        // const index = newPeopleTrialsAssociations.findIndex(pta => pta.person_id === data.person.id);
        // // let newPta = Object.assign({}, newPeopleTrialsAssociations[index], p);
        // // console.log(newPta);
        // // // newPta["person"] = data.person;
        // // newPeopleTrialsAssociations[index] = newPta;
        // let selectedPeople = [newPeopleTrialsAssociations[index]];
        var openDialogs = {...currentComponent.openDialogs};
        openDialogs["editPersonDialog"] = false;
        currentComponent.setState({ openDialogs: openDialogs});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleRemovePersonClick = event => {
    let currentComponent = this;
    this.setState({ newSiteOpen: false});
    fetch('/trials/'+this.state.trial.id+'/execute_remove_people', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({pta_ids: currentComponent.state.selectedPeopleIds}),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.setState({ selectedPeople: [], selectedPeopleIds: [], selectedPeopleIndices: []})
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.forceUpdate();
  }

  handlePeopleFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({peopleFilters: {left: {label: "Nicht Ausgeschieden", value: false}, primary_contact: null}});
    }
  }

  handlePeopleFilterChange = (filters, meta) => {
    let newPeopleFilters = {...this.state.peopleFilters};
    newPeopleFilters[meta["name"]] = filters;
    this.setState({peopleFilters: newPeopleFilters});
  }

  handleDownloadClick  = event => {
    let currentComponent = this;
    let data = "ZID\tInstitution\tGeschlecht\tHerr_Frau\tAnrede\tVorname\tName\tTitel\tTelefon\tFax\teMail\tAdresse\tFunktion\tprim. Ansprechpartner\tTags\tAusgeschieden\tDatum_Eintritt\tDatum_Austritt";
    this.state.selectedPeople.forEach(person => {
      let herr_frau = "";
      let anrede = "";
      if (person.gender == "m" || person.gender == "M") {
        herr_frau = "Herr";
        anrede = "geehrter";
      } else if (person.gender == "w" || person.gender == "W") {
        herr_frau = "Frau";
        anrede = "geehrte";
      } else {
        herr_frau = "Frau/Herr";
        anrede = "geehrte(r)";
      }
      // const funktion = this.
      const address = "Klinik\r\nAbteilung\r\n"
      data += "\r\n" + person.site_id + "\t" + person.institution_name + "\t" + person.gender + "\t" + herr_frau + "\t" + anrede + "\t" + person.first_name + "\t" + person.last_name + "\t" + person.title + "\t" + person.phone_number + "\t" + person.fax_number+ "\t" + person.email+ "\t" + person.email + "\t";
    })
    // const data = to_json(this.state.selectedPeople);
    save(data, 'example.tsv');
  }

  handleFilters = () => {
    let ret = [].concat(this.state.people_trials_associations);
    if (this.state.peopleFilters["overview_show"] && ret.length > 0 && this.state.peopleFilters["overview_show"].value != null) {
      ret = ret.filter(person => ((person.overview_show == null && (this.state.peopleFilters["overview_show"]["value"] == true || this.state.peopleFilters["overview_show"]["value"] == null)) || person.overview_show == this.state.peopleFilters["overview_show"]["value"]));
    }
    if (this.state.peopleFilters["trial_role"] && ret.length > 0) {
      const trial_roles = this.state.peopleFilters.trial_role.map(role => role.value);
      ret = ret.filter(person => trial_roles.includes(person.trial_role));
    }
    if (this.state.peopleFilters["left"] != null && ret.length > 0) {
      ret = ret.filter(person => ((person.left == null && this.state.peopleFilters["left"].value == false) || (person.left == this.state.peopleFilters["left"].value) || this.state.peopleFilters["left"].value == null));
    }
    if (this.state.peopleFilters["primary_contact"] != null && ret.length > 0) {
      ret = ret.filter(person => (person.primary_contact == this.state.peopleFilters["primary_contact"].value));
    }
    return ret;
  }

  handleTrialsReceived = message => {
    console.log("received Trial");
    console.log(message);
    if (message.trial && message.trial.id == this.state.trial.id) {
      const trial = message.trial;
      this.setState({ trial: trial });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
    }
    if (message.deleted_trial_ids && message.deleted_trial_ids.includes(this.state.trial.id)) {
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' gelöscht: ' + this.state.trial.short, {variant: 'success'});
      window.location.assign('/trials');
    }
    if (message.people_trials_association && message.people_trials_association.trial_id == this.state.trial.id) {
      let newPeopleTrialsAssociations = [].concat(this.state.people_trials_associations);
      const newPtaIndex = newPeopleTrialsAssociations.findIndex(pta => pta.id == message.people_trials_association.id);
      newPeopleTrialsAssociations[newPtaIndex] = Object.assign({}, this.state.people_trials_associations[newPtaIndex], message.people_trials_association);
      delete newPeopleTrialsAssociations[newPtaIndex]["person"];
      let selectedPeople = [].concat(this.state.selectedPeople);
      let changedSelectedPersonIndex = (selectedPeople.length > 0) ? selectedPeople.findIndex(selected_person => message.people_trials_association.id == selected_person.id) : null;
      if ((changedSelectedPersonIndex != null) && (changedSelectedPersonIndex != -1)) {
        selectedPeople[changedSelectedPersonIndex] = message.people_trials_association;
      }
      this.setState({ people_trials_associations: newPeopleTrialsAssociations, selectedPeople: selectedPeople });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person: ' + message.people_trials_association.person.first_name + ' ' + message.people_trials_association.person.last_name, {variant: 'success'});
    }
    if (message.new_people_trials_association && message.trial_id == this.state.trial.id) {
      let newPta = Object.assign({}, message.new_people_trials_association.person, message.new_people_trials_association);
      delete newPta["person"];
      const newPeopleTrialsAssociations = [].concat(this.state.people_trials_associations, newPta);

      this.setState({ people_trials_associations: newPeopleTrialsAssociations });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person hinzugefügt: ' + message.new_people_trials_association.person.first_name + ' ' + message.new_people_trials_association.person.last_name, {variant: 'success'});
    }
    if (message.removed_pta_ids) {
      const newPeopleTrialsAssociations = this.state.people_trials_associations.filter(pta => !message.removed_pta_ids.includes(pta.id));
      const removedPtas = this.state.people_trials_associations.filter(pta => message.removed_pta_ids.includes(pta.id));
      const namesArray = removedPtas.map(pta => pta.first_name + ' ' + pta.last_name);
      this.setState({ people_trials_associations: newPeopleTrialsAssociations });
      const added_person = this.state.people_trials_associations.find(person => person.person_id == message.person_id);
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person(en) entfernt: ' + namesArray, {variant: 'success'});
    }
  }
  // handleTrialsReceived = message => {
  //   console.log("received Person");
  //   console.log(message);
  //   if (message.deleted_trial_ids && message.deleted_trial_ids.includes(this.state.trial.id)) {
  //     this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' gelöscht: ' + this.state.trial.short, {variant: 'success'});
  //     window.location.assign('/trials');
  //   }
  //   if (message.people_trials_associations && message.trial_id == this.state.trial.id) {
  //     const newPeopleTrialsAssociations = message.people_trials_associations.map(selected_pta => {
  //       let ret = Object.assign({}, selected_pta.person, selected_pta);
  //       return (
  //         ret
  //       )
  //     });
  //     let pta_filtered = {};
  //     if (newPeopleTrialsAssociations) {
  //       pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
  //     }
  //     this.setState({ people_trials_associations: newPeopleTrialsAssociations, people_trials_associations_filtered: pta_filtered });
  //     const added_person = this.state.people_trials_associations.find(person => person.person_id == message.person_id);
  //     this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person: ' + added_person.first_name + ' ' + added_person.last_name, {variant: 'success'});
  //   }
  //   if (message.people_trials_association && message.people_trials_association.trial_id == this.state.trial.id) {
  //     const newPeopleTrialsAssociations = [].concat(this.state.people_trials_associations);
  //     const ind = newPeopleTrialsAssociations.findIndex(pta => pta.id == message.people_trials_association.id);
  //     const newPta = Object.assign({}, newPeopleTrialsAssociations[ind], message.people_trials_association);
  //     newPeopleTrialsAssociations[ind] = newPta;
  //     let pta_filtered = [];
  //     if (newPeopleTrialsAssociations) {
  //       pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
  //     }
  //     this.setState({ people_trials_associations: newPeopleTrialsAssociations, people_trials_associations_filtered: pta_filtered });
  //     this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Studienspezifische Information von Person: ' + message.people_trials_association.first_name + ' ' + message.people_trials_association.last_name, {variant: 'success'});
  //   }
  //   if (message.removed_pta_ids) {
  //     const newPeopleTrialsAssociations = this.state.people_trials_associations.filter(pta => !message.removed_pta_ids.includes(pta.id));
  //     const removedPtas = this.state.people_trials_associations.filter(pta => message.removed_pta_ids.includes(pta.id));
  //     const namesArray = removedPtas.map(pta => pta.first_name + ' ' + pta.last_name);
  //     let pta_filtered = [];
  //     if (newPeopleTrialsAssociations) {
  //       pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
  //     }
  //     this.setState({ people_trials_associations: newPeopleTrialsAssociations, people_trials_associations_filtered: pta_filtered });
  //     const added_person = this.state.people_trials_associations.find(person => person.person_id == message.person_id);
  //     this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person(en) entfernt: ' + namesArray, {variant: 'success'});
  //   }
  // }

  // handlePeopleReceived = message => {
  //   console.log("received Person");
  //   console.log(message);
  //   if (message.person) {
  //     let people = [].concat(this.state.people);
  //     const ind = people.findIndex(dep => dep.id == message.person.id);
  //     people[ind] = message.person;
  //     this.setState({ people: people });
  //     this.props.enqueueSnackbar('Person wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.full_name, {variant: 'success'});
  //   }
  //   if (message.deleted_person_ids) {
  //     let people = [].concat(this.state.people);
  //     message.deleted_person_ids.forEach(function (person_id, index) {
  //       const ind = people.findIndex(d => d.id == person_id);
  //       delete people[ind];
  //     });
  //     // delete trialBoards[message.deleted_trial_board_ids];
  //     this.setState({ people: people });
  //     this.props.enqueueSnackbar('Person(en) wurde(n) von Benutzer ' + message.username + ' gelöscht: ' + message.full_names, {variant: 'success'});
  //   }
  // }

  handlePeopleReceived = message => {
    console.log("received Person");
    console.log(message);
    if (message.person) {
      const ptas = this.state.people_trials_associations.filter(pta => pta.person_id == message.person.id);
      let newPtas = [].concat(this.state.people_trials_associations);
      if (ptas.length > 0){
        const ptaIds = ptas.map(pta => pta.id);
        newPtas = this.state.people_trials_associations.map(pta => {
          let ret = pta;
          if (ptaIds.includes(pta.id)) {
            const changedPtaIndex = this.state.people_trials_associations.findIndex(pta_int => pta_int.id == pta.id);
            ret = Object.assign({}, pta, message.person);
            ret["id"] = pta.id;
          }
          return ret
        });
      }
      let selectedPeople = [].concat(this.state.selectedPeople);
      const changedSelectedPersonIndex = (selectedPeople.length > 0) ? selectedPeople.findIndex(selected_person => message.person.id == selected_person.person_id) : null;
      if ((changedSelectedPersonIndex != null) && (changedSelectedPersonIndex != -1)) {
        let newSelectedPerson = selectedPeople[changedSelectedPersonIndex];
        newSelectedPerson = Object.assign({}, newSelectedPerson, message.person);
        newSelectedPerson["id"] = selectedPeople[changedSelectedPersonIndex].id;
        selectedPeople[changedSelectedPersonIndex] = newSelectedPerson;
      }
      this.setState({ people_trials_associations: newPtas, selectedPeople: selectedPeople });
      this.props.enqueueSnackbar('Person wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.full_name, {variant: 'success'});
    }

  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    const { classes, trial } = this.props;
    // const people_trials_associations_filtered = this.handleFilters();
    const isStandardPeopleFilters = deepEqual(this.state.peopleFilters, {left: {label: "Nicht Ausgeschieden", value: false}, primary_contact: null});
    let people_trials_associations_with_essential = [].concat(this.state.people_trials_associations);
    if ((people_trials_associations_with_essential.findIndex(pta => pta.trial_role == "LKP" || pta.trial_role == "LKP/PI" || pta.trial_role == "PI") == -1) && isStandardPeopleFilters) {
      people_trials_associations_with_essential.push({missingRow: true, last_name: "", phone_number: "", trial_role: "LKP/PI"});
    }
    if ((people_trials_associations_with_essential.findIndex(pta => pta.trial_role == "SP") == -1) && isStandardPeopleFilters) {
      people_trials_associations_with_essential.push({missingRow: true, last_name: "", phone_number: "", trial_role: "SP"});
    }
    if ((people_trials_associations_with_essential.findIndex(pta => pta.trial_role == "PM") == -1) && isStandardPeopleFilters) {
      people_trials_associations_with_essential.push({missingRow: true, last_name: "", phone_number: "", trial_role: "PM"});
    }
    if ((people_trials_associations_with_essential.findIndex(pta => pta.trial_role == "MO") == -1) && isStandardPeopleFilters) {
      people_trials_associations_with_essential.push({missingRow: true, last_name: "", phone_number: "", trial_role: "MO"});
    }

    let selectedPersonDepartments = [];
    if (this.state.selectedPeople.length == 1) {
      const selectedPersonDepartmentIds = this.state.peopleDepartmentsAssociations.filter(pda => pda.person_id == this.state.selectedPeople[0].person_id).map(pda => pda.department_id);
      selectedPersonDepartments = this.state.people_departments.filter(department => selectedPersonDepartmentIds.includes(department.id));
    }

    return (
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "TrialsChannel"}}
            onReceived={this.handleTrialsReceived}
            onConnected={this.handleConnected}
          />
          <ActionCableConsumer
            channel={{channel: "PeopleChannel"}}
            onReceived={this.handlePeopleReceived}
            onConnected={this.handleConnected}
          />
          <Grid item xs={12} >
            <Grid container justify="center">
                { (this.state.openDialogs.addPersonToTrial == true) &&
                  <AddPersonToTrialDialog
                    trialId={this.state.trial.id}
                    open={this.state.openDialogs.addPersonToTrial}
                    handleClose={this.handleAddPersonToTrialClose}
                    handleSave={this.handleAddPersonToTrialSave}
                  />
                }
                { (this.state.selectedPeople.length == 1) &&
                  <>
                    <InfoPersonTrialDialog
                      open={this.state.openDialogs.infoPersonDialog}
                      handleClose={this.handleInfoPersonClose}
                      selectedPerson={this.state.selectedPeople[0]}
                      numPeople={this.state.people_trials_associations.length}
                      selectedPersonIndex={this.state.selectedPeopleIndices[0]}
                      handleEditPersonClick={this.handleEditPersonOpen}
                      handleEditPtaClick={this.handleEditPtaOpen}
                      handleForwardClick={this.handlePersonForwardClick}
                      handleBackwardClick={this.handlePersonBackwardClick}
                      departments={selectedPersonDepartments}
                      institutions={this.state.people_institutions}
                    />
                    <EditPersonDialog
                      open={this.state.openDialogs.editPersonDialog}
                      handleClose={this.handleEditPersonClose}
                      handleSave={this.handleEditPersonSave}
                      selectedPerson={this.state.selectedPeople[0]}
                      // people_departments={this.props.people_departments}
                    />
                    <EditPtaDialog
                      open={this.state.openDialogs.editPtaDialog}
                      handleClose={this.handleEditPtaClose}
                      handleSave={this.handleEditPtaSave}
                      selectedPerson={this.state.selectedPeople[0]}
                    />
                  </>
                }
                <TrialPeople
                  people_trials_associations={people_trials_associations_with_essential}
                  singleSelect={false}
                  selectedIds={this.state.selectedPeopleIds}
                  selectedObjects={this.state.selectedPeople}
                  departments={selectedPersonDepartments}
                  institutions={this.state.people_institutions}
                  columns={peopleColumns}
                  handleChange={this.handlePeopleChange}
                  handleMailTo={this.handleMailToPeople}
                  // handleInfoPersonClick={this.handleInfoPersonClick}
                  handleAddOpen={this.handleAddPersonToTrialOpen}
                  handleAddClose={this.handleAddPersonToTrialClose}
                  handleFilterClick={this.handlePeopleFilterClick}
                  handleRemoveClick={this.handleRemovePersonClick}
                  handleDownloadClick={this.handleDownloadClick}
                  onFilterChange={this.handlePeopleFilterChange}
                  onDoubleClick={this.handlePersonDoubleClick}
                  allData={this.state.people_trials_associations}
                  // missingRows={this.state.missingRoles}
                  filters={this.state.peopleFilters}
                  autoFill
                  rowsPerPage={15}
                  expanded={true}
                />
                <Typography style={{color: 'red', fontSize: '20px'}}>
                  Hinweis: –	Für Kontakte der Prüfstelle siehe Tab "Zentren"
                </Typography>
            </Grid>
          </Grid>
        </Grid>

    )
  }
}

TrialPeopleWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(TrialPeopleWrapper));
