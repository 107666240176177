import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'
import PersonSelector from './PersonSelector.jsx'
import SitePersonFunctionSelector from './SitePersonFunctionSelector.jsx'

class AddPersonDialog extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.state = {selectedPerson: this.props.selectedPerson, selectedFunction: [], inputValue: this.props.inputValue, primary_contact: false, join_date: yyyy+"-"+mm+"-"+dd, spa_comment: "", leave_date: null, left: false}
    this.form = React.createRef();
  }

  // handleSiteChange = event => {
  //   let currentComponent = this;
  //   let site_id = event.target.value.id
  //   // console.log(site_id)
  //   fetch('/trials/'+this.props.trialId+'/site_selection/get_institution_departments?'+$.param({site_id: site_id}))
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       console.log(data);
  //       currentComponent.setState({ departments: data.institution_departments});
  //     });
  //   currentComponent.setState({ selectedSite: event.target.value});
  // };
  //
  // handleDepartmentChange = event => {
  //   let currentComponent = this;
  //   let department_id = event.target.value.id
  //   fetch('/trials/'+this.props.trialId+'/site_selection/get_department_people?'+$.param({department_id: department_id}))
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       console.log(data);
  //       currentComponent.setState({ people: data.department_people});
  //     });
  //   currentComponent.setState({ selectedDepartment: event.target.value});
  // };

  handleClose = event => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.setState({selectedPerson: this.props.selectedPerson, selectedFunction: [], inputValue: this.props.inputValue, primary_contact: false, join_date: yyyy+"-"+mm+"-"+dd, spa_comment: "", leave_date: null, left: false, newPersonOpen: false, open: this.props.open, people: {}, newPersonName: "", newPersonOpen: false});
    this.props.handleClose();
  }

  handleSave = event => {
    this.props.handleSave({person_id: this.state.selectedPerson.value, function: this.state.selectedFunction.value, primary_contact: this.state.primary_contact, join_date: this.state.join_date, leave_date: this.state.leave_date, left: this.state.left, comment: this.state.spa_comment});
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.setState({selectedPerson: this.props.selectedPerson, selectedFunction: [], inputValue: this.props.inputValue, primary_contact: false, join_date: yyyy+"-"+mm+"-"+dd, spa_comment: "", leave_date: null, left: false, newPersonOpen: false, open: this.props.open, people: {}, newPersonName: ""});
  };

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleChange2 = (event, checked) => {
    // console.log(event.target.name);
    // console.log(checked);
    this.setState({[event.target.name]: checked});
  }

  handlePersonChange = event => {
    let currentComponent = this;
    // console.log(event);
    currentComponent.setState({ selectedPerson: event, inputValue: event});
  };

  handleFunctionChange = (newValue, actionMeta) => {
    // console.log(newValue);
    this.setState({selectedFunction: newValue});
  }

  handlePrimaryChange = event => {
    this.setState({primary_contact: event.target.checked});
  }

  handleConnectChange = event => {
    this.setState({connect: event.target.checked});
  }
  // handleSave = event => {
  //   let currentComponent = this;
  //   this.setState({ open: false});
  //   fetch('/trials/'+this.props.trialId+'/site_selection/add_person', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       // TODO
  //       site_id: this.state.selectedSite.id,
  //       department_id: this.state.selectedDepartment.id,
  //       person_id: this.state.selectedPerson.id,
  //     })
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     console.log(data);
  //     // currentComponent.setState({ people: data.people, notes: data.notes });
  //     var people = currentComponent.state.people;
  //     people.push(data.person);
  //     currentComponent.setState({ people: people })
  //   });
  //   currentComponent.handleClose;
  // };

  render(props) {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={true}
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Person zu Abteilung hinzufügen</DialogTitle>
        <DialogContent>
          Bitte wählen Sie die Person aus dem Adressbuch aus. Ist die Person noch nicht im Adressbuch vorhanden, dann kann sie hier ins Adressbuch eingetragen werden.
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
            <InputLabel
              filled
              animated
              shrink
              margin="dense"
              variant="outlined"
              error={!this.state.selectedPerson}
            >
              Person
            </InputLabel>
            <PersonSelector
              // people={this.state.people}
              selectedPerson={this.state.selectedPerson}
              trialId={this.props.trialId}
              departmentId={this.props.selectedDepartment.id}
              onCreateOption={this.props.onCreateOption}
              handleChange={this.handlePersonChange}
              inputValue={this.props.inputValue}
              handleInputValueChange={this.props.handleInputValueChange}
              autoFocus={true}
            />
          </FormControl>
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 998}}>
            <InputLabel
              filled
              animated
              shrink
              margin="dense"
              variant="outlined"
              error={!this.state.selectedFunction}
            >
              Funktion
            </InputLabel>
            <SitePersonFunctionSelector
              selectedFunction={this.state.selectedFunction}
              handleChange={this.handleFunctionChange}
              // handleInputValueChange={this.handleFunctionChange}
              onCreateOption={this.handleCreateFunctionOption}
            />
          </FormControl>
          <FormControlLabel
            style={{zIndex: 997}}
            control={
              <Checkbox
                checked={this.state.primary_contact}
                onChange={this.handleChange2}
              />
            }
            name="primary_contact"
            label="Primärer Kontakt"
          />
          <TextField
            id="join_date"
            label="Eintrittsdatum"
            defaultValue={this.state.join_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            type="date"
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={this.state.left}
                onChange={this.handleChange2}
              />
            }
            label="Ausgeschieden"
            name="left"
          />
          <TextField
            id="leave_date"
            label="Austrittsdatum"
            type="date"
            // defaultValue={this.state.leave_date}
            value={this.state.leave_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={(!(this.state.leave_date) && this.state.left)}
            disabled={!(this.state.left)}
            style={{zIndex: 1}}
          />
          <TextField
            id="spa_comment"
            label="Kommentar"
            onChange={this.handleChange}
            multiline
            // className={classes.textField}
            margin="dense"
            variant="outlined"
            value={this.state.spa_comment}
            fullWidth
            style={{zIndex: 1}}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.handleSave({
            person_id: this.state.selectedPerson.value,
            function: this.state.selectedFunction.value,
            comment: this.state.spa_comment,
            primary_contact: this.state.primary_contact,
            join_date: this.state.join_date,
            leave_date: this.state.leave_date,
            left: this.state.left,
            comment: this.state.spa_comment,
          })} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default AddPersonDialog;
