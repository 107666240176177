import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
// import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import TextField from './TextField.jsx';

const styles = theme => ({
  status: {
    margin: 0,
  },
  statusCheckbox: {
    padding: 0,
  }
});

const marks = [
  {
    value: 0,
    label: 'Offen',
  },
  {
    value: 1,
    label: 'OK',
  },
  {
    value: -1,
    label: 'N.A.',
  },
];

class Milestones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedSites: this.props.selectedSites}
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSites != prevProps.selectedSites) {
      this.setState({selectedSites: this.props.selectedSites});
    }
  }

  render(props) {
    const { classes } = this.props;
    let milestonesComponents = null;
    const options = [{value: 0, label: "nicht erledigt", backgroundColor: "#F5B556"}, {value: 1, label: "Erledigt", backgroundColor: "#5cba3f"}, {value: -1, label: "N.Z.", backgroundColor: "#adadad"}, {value: 2, label: "Niemals", backgroundColor: "#adadad"}];
    if (this.props.standardMilestones) {
      milestonesComponents = this.props.standardMilestones.map(milestone => {
        let bg = "#F5B556";
        let val = 0;
        if (this.state.selectedSites.length == 1) {
          if (this.state.selectedSites[0].milestones[milestone.name] == "1") {
            bg = "#5cba3f";
            val = 1;
          } else if (this.state.selectedSites[0].milestones[milestone.name] == "-1") {
            bg = "#adadad";
            val = -1;
          } else if (this.state.selectedSites[0].milestones[milestone.name] == "2") {
            bg = "#adadad";
            val = 2;
          }
        } else {
          bg = "#adadad";
          val = -1;
        }
        // const val = {value: this.props.selectedSite.milestones[milestone], label: label};
        // const val = this.props.selectedSite.milestones[milestone.name];
        const selectedOption = options.find(opt => opt.value == val);
        console.log(selectedOption);
        return (
          <FormControlLabel
            control={
              <TextField
                value={val}
                onChange={this.props.handleChange({name: milestone.name, site_id: (this.props.selectedSites.length == 1) ? this.props.selectedSites[0].id : null})}
                // backGroundColor={bg}
                disabled={((!this.props.unlocked) || (!(this.state.selectedSites.length == 1)))}
                type="select"
                options={options}
                size="small"
                style={{backgroundColor: bg, width: '100px'}}
              />
            }
            label={milestone.name}
            // className={classes.status}
          />
        )
      });
    }
    let customMilestonesComponents = null;
    if ((this.state.selectedSites.length == 1) && this.props.customMilestones) {
      customMilestonesComponents = this.props.customMilestones.map(customMilestoneName => {
        const milestone = this.state.selectedSites[0].milestones[customMilestoneName];
        let bg = "#F5B556";
        let val = 0;
        if (milestone == "1") {
          bg = "#5cba3f";
          val = 1;
        } else if (milestone == "-1") {
          bg = "#adadad";
          val = -1;
        } else if (milestone == "2") {
          bg = "#adadad";
          val = 2;
        }
        // const val = {value: this.props.selectedSite.milestones[milestone], label: label};
        // const val = this.props.selectedSite.milestones[milestone];
        return (
          <FormControlLabel
            control={
              <TextField
                value={val}
                onChange={this.props.handleChange({name: customMilestoneName, site_id: (this.props.selectedSites.length == 1) ? this.props.selectedSites[0].id : null})}
                // backGroundColor={bg}
                disabled={((!this.props.unlocked) || (!(this.state.selectedSites.length == 1)))}
                type="select"
                options={options}
                size="small"
                style={{backgroundColor: bg, width: '100px'}}
              />
            }
            label={customMilestoneName.name}
            // className={classes.status}
          />
        )
      });
    } else if (this.props.customMilestones) {
      // console.log("custom", this.state.selectedSites);
      customMilestonesComponents = this.props.customMilestones.map(milestone => {
        let bg = "#adadad";
        let val = -1;
        // const val = {value: this.props.selectedSite.milestones[milestone], label: label};
        // const val = this.props.selectedSite.milestones[milestone.name];
        return (
          <FormControlLabel
            control={
              <TextField
                value={val}
                onChange={this.props.handleChange({name: milestone.name, site_id: (this.props.selectedSites.length == 1) ? this.props.selectedSites[0].id : null})}
                // backGroundColor={bg}
                disabled={((!this.props.unlocked) || (!(this.state.selectedSites.length == 1)))}
                type="select"
                options={options}
                size="small"
                style={{backgroundColor: bg, width: '100px'}}
              />
            }
            label={milestone.name}
            // className={classes.status}
            fullWidth
          />
        )
      });
    }
    return (
      // <div >
        <FormGroup>
          {milestonesComponents}
          {customMilestonesComponents}
        </FormGroup>
      // </div>
    )
  }
}

Milestones.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedSites: PropTypes.isRequired,
};

Milestones = withStyles(styles)(Milestones);

export default Milestones ;
