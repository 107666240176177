import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import TextField from '@mui/material/TextField';
import TextField from './TextField.jsx';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import { withSnackbar } from 'notistack';

// import InstitutionSelector from './InstitutionSelector.jsx'

class NewInstitutionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, name: this.props.selectedInstitution.name, id: this.props.selectedInstitution.id, email: this.props.selectedInstitution.email, street: this.props.selectedInstitution.street, city: this.props.selectedInstitution.city, website: this.props.selectedInstitution.website, zip: this.props.selectedInstitution.zip, country: this.props.selectedInstitution.country, comment: this.props.selectedInstitution.comment, phone_number: this.props.selectedInstitution.phone_number, fax_number: this.props.selectedInstitution.fax_number};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedInstitution !== prevProps.selectedInstitution) {
      this.setState({open: this.props.open, name: this.props.selectedInstitution.name, email: this.props.selectedInstitution.email, street: this.props.selectedInstitution.street, city: this.props.selectedInstitution.city, website: this.props.selectedInstitution.website, zip: this.props.selectedInstitution.zip, country: this.props.selectedInstitution.country, comment: this.props.selectedInstitution.comment, phone_number: this.props.selectedInstitution.phone_number, fax_number: this.props.selectedInstitution.fax_number});
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleSave = event => {
    this.props.handleSave({
      name: this.state.name,
      street: this.state.street,
      city: this.state.city,
      zip: this.state.zip,
      country: this.state.country,
      phone_number: this.state.phone_number,
      fax_number: this.state.fax_number,
      email: this.state.email,
      website: this.state.website}
    )
    this.setState({name: null, email: null, street: null, city: null, zip: null, country: null})
  }

  render(props) {
    const saveable = this.state.name;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.state.name}
          <Typography color="inherit" noWrap style={{fontSize: '8pt'}}>
            ID: {this.state.id}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: green[500]}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                <Typography variant="h8" color="inherit" noWrap style={{marginLeft: 5}}>
                  Adressbuch
                </Typography>
                <Tooltip title="Daten für Institution editieren">
                  <IconButton aria-label="Edit" onClick={this.props.handleEditClick}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.name}
                  error={!this.state.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="street"
                  label="Straße und Hausnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.street}
                  // error={!this.state.street}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="zip"
                  label="Postleitzahl"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.zip}
                  // error={!this.state.zip}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="city"
                  label="Stadt"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.city}
                  // error={!this.state.city}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="country"
                  label="Land"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.country}
                  // error={!this.state.country}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="E-Mail"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.email}
                  // error={!this.state.country}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="phone_number"
                  label="Telefonnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.phone_number}
                  // error={!this.state.country}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="fax_number"
                  label="Faxnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.fax_number}
                  // error={!this.state.country}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="website"
                  label="Webseite"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.website}
                  // error={!this.state.country}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <TextField
                  id="comment"
                  label="Kommentar"
                  onChange={this.handleChange}
                  multiline
                  // className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={this.state.comment}
                  fullWidth
                  style={{zIndex: 1}}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="text"
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withSnackbar(NewInstitutionDialog);
