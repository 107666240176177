import React from 'react';
// import { withStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CreatableSelect from 'react-select/creatable';

import DepartmentSelectorGlobal from './DepartmentSelectorGlobal.jsx';
import NewDepartmentDialog from './NewDepartmentDialog.jsx';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class AddDepartmentGlobalToSiteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedDepartment: this.props.selectedDepartment, newDepartmentOpen: false, newDepartmentName: "", inputValue: this.props.inputValue, open: this.props.open}
    this.form = React.createRef();
  }


  // componentDidMount(props) {
  //   let currentComponent = this;
  //   // const departments = this.state.departments;
  // }

  // handleSiteChange = event => {
  //   let currentComponent = this;
  //   let site_id = event.target.value.id
  //   // console.log(site_id)
  //   fetch('/trials/'+this.props.trialId+'/site_selection/get_institution_departments?'+$.param({site_id: site_id}))
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       // console.log(data);
  //       currentComponent.setState({ departments: data.institution_departments});
  //     });
  //   currentComponent.setState({ selectedSite: event.target.value});
  // };

  componentDidUpdate(prevProps) {
    if (this.props.inputValue != prevProps.inputValue || this.props.open != prevProps.open) {
      this.setState({inputValue: this.props.inputValue, open: this.props.open});
    }
  }

  handleDepartmentChange = event => {
    let currentComponent = this;
    currentComponent.setState({ selectedDepartment: event});
  };

  handleNewDepartmentOpen = event => {
    // console.log(event);
    this.setState({ newDepartmentOpen: true, open: false, newDepartmentName: event});
  };

  handleNewDepartmentClose = event => {
    // console.log(event);
    this.setState({ newDepartmentOpen: false});
  };

  handleInputValueChange = (newValue, actionMeta) => {
    if ((actionMeta.action != "input-blur") && (actionMeta.action != "menu-close")) {
      this.setState({inputValue: newValue});
    }
  }

  handleSave = ids => {
    let currentComponent = this;
    this.setState({ addDepartmentOpen: false});
    fetch('/trials/'+this.state.trialId+'/site_selection/add_department', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        department_id: ids.department_id,
        site_id: ids.site_id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.handleAddDepartment(data.department, data.assoc);
        currentComponent.props.handleClose();
        // currentComponent.props.handleSave;
      } else if (data.response == 0) {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler beim hinzufügen aufgetreten.', {variant: 'error'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Die Abteilung existiert bereits in diesem Zentrum.', {variant: 'error'});
      }
    });
  }

  formatCreate = (inputValue) => {
    return (<p> {inputValue} neu im Adressbuch anlegen</p>);
  };

  render(props) {
    return (
      <>
      <NewDepartmentDialog
        open={this.state.newDepartmentOpen}
        handleClose={this.handleNewDepartmentClose}
        trialId={this.props.trialId}
        // sites={this.state.sites}
        // handleSave={this.handleNewDepartmentSave}
        selectedSite={this.props.selectedSite}
        inputValue={this.state.newDepartmentName}
        onCreateOption={this.handleNewDepartmentOpen}
        formatCreate={this.formatCreate}
        handleAddDepartment={this.props.handleAddDepartment}
        // handleNewDepartmentOpen={this.handleNewDepartmentOpen}
        // handleNewDepartmentClose={this.handleNewDepartmentClose}
      />
      <Dialog
        open={this.state.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={true}
        fullWidth
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">Abteilung hinzufügen</DialogTitle>
        <DialogContent>
          <DepartmentSelectorGlobal
            siteId={this.props.selectedSite.id}
            trialId={this.props.trialId}
            // handleNewDepartmentOpen={this.props.handleNewDepartmentOpen}
            // handleNewDepartmentClose={this.props.handleNewDepartmentClose}
            handleSave={this.props.handleSave}
            handleChange={this.handleDepartmentChange}
            onCreateOption={this.handleNewDepartmentOpen}
            inputValue={this.state.inputValue}
            formatCreate={this.formatCreate}
            autoFocus
            handleInputValueChange={this.handleInputValueChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCancel} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.handleSave({site_id: this.props.selectedSite.id, department_id: this.state.selectedDepartment.value})} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
  }
}

AddDepartmentGlobalToSiteDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(AddDepartmentGlobalToSiteDialog));
