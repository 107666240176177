import React from 'react';
// import styled from 'styled-components';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import Avatar from '@mui/material/Avatar';
import blue from '@mui/material/colors/blue';
import deepOrange from '@mui/material/colors/deepOrange';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';

// import Link from '@mui/material/Link';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const blueAvatar = {
  margin: 0,
  color: '#fff',
  backgroundColor: blue[200],
};

const redAvatar = {
  margin: 0,
  color: '#fff',
  backgroundColor: deepOrange[200],
};

const checkIconStyle = {
  // fontSize: "small",
  // transform: "translate(-50%, 50%)",
}

class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  handleClicked = (e) => {
    this.props.handleSeen(this);
    // console.log(e)
  }

  state = {
    anchorEl: null,
  };

  render(props) {
    // const { anchorEl } = this.state;
    // const classes = this.props;
    // console.log(this.props.notifications)
    // const newItems = this.props.new_notifications.map((notification) =>
    //   // <div key={"div_" + notification.id.toString()}>
    //     <ListItemLink key={"list_item_" + notification.id.toString()} href={notification.link}>
    //       <Avatar key={"avatar_" + notification.id.toString()} style={blueAvatar}>
    //         <CheckBoxOutlineBlank />
    //       </Avatar>
    //       <ListItemText primary={notification.title} secondary={notification.content} />
    //     </ListItemLink>
    //   // </div>
    // );
    // const readItems = this.props.read_notifications.map((notification) =>
    //   <ListItemLink key={notification.id.toString()} href={notification.link}>
    //
    //     <ListItemText primary={notification.title} secondary={notification.content} />
    //   </ListItemLink>
    // );

    // console.log(this.props.read_notifications)
    return (
      // <>
      <>
       <Grid item xs={11} key={"inner_grid_" + this.props.id.toString()} >
         <ListItemLink key={"list_item_" + this.props.id.toString()} href={this.state.link} href={this.props.link} style={{width: '400px'}}>
           {this.props.read ? (
             <ListItemIcon><CheckBox /></ListItemIcon>
           ) : (
             <ListItemIcon><CheckBoxOutlineBlank /></ListItemIcon>
           )}
           <ListItemText primary={this.props.title} secondary={this.props.content} />
         </ListItemLink>
       </Grid>
       <Grid item xs={1}>
         {this.props.read ? (
           null
         ) : (
           <IconButton
             key={"seen_button_" + this.props.id.toString()}
             // className={classes.button}
             aria-label="Read"
             style={checkIconStyle}
             onClick={this.handleClicked}
             // onClick={this.handleSeen.bind(this, notification)}
           >
             <CheckIcon/>
           </IconButton>
         )}
       </Grid>
     </>


        // <Grid item xs={1}>
        //   {this.state.read ? (
        //     null
        //   ) : (
        //     <IconButton
        //       key={"seen_button_" + this.state.id.toString()}
        //       // className={classes.button}
        //       aria-label="Read"
        //       style={checkIconStyle}
        //       onClick={this.handleSeen}
        //     >
        //       <CheckIcon/>
        //     </IconButton>
        //   )}
        // </Grid>
      // </>
    );
  }
}

export default Notification;
