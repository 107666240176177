import React from 'react';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'
import SitePersonFunctionSelector from './SitePersonFunctionSelector.jsx'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  content: {
      display: "flex",
      flexBasis: '100%',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-evenly',
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class EditSitesPeopleAssociationsDialog extends React.Component {

  constructor(props) {
    super(props);
    const selectedFunction = {value: this.props.selectedPerson.site_function, label: this.props.selectedPerson.site_function};
    this.state = {open: this.props.open, departments: {}, institutions: {}, lastName: this.props.selectedPerson.last_name, firstName: this.props.selectedPerson.first_name, title: this.props.selectedPerson.title, phoneNumber: this.props.selectedPerson.phone_number, email: this.props.selectedPerson.email, function: this.props.selectedPerson.function, mobileNumber: this.props.selectedPerson.mobile_number, faxNumber: this.props.selectedPerson.fax_number, unlocked: false, gender: this.props.selectedPerson.gender, comment: this.props.selectedPerson.comment, spa_comment: this.props.selectedPerson.spa_comment, primary_contact: this.props.selectedPerson.primary_contact, site_function: this.props.selectedPerson.site_function, join_date: this.props.selectedPerson.join_date, leave_date: this.props.selectedPerson.leave_date, left: this.props.selectedPerson.left, selectedFunction: selectedFunction};
    this.form = React.createRef();
  }

  componentDidMount() {
    this.getDepartments();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedPerson.id !== prevProps.selectedPerson.id) {
      this.getDepartments();
      const selectedFunction = {value: this.props.selectedPerson.site_function, label: this.props.selectedPerson.site_function};
      this.setState({ lastName: this.props.selectedPerson.last_name, firstName: this.props.selectedPerson.first_name, title: this.props.selectedPerson.title, phoneNumber: this.props.selectedPerson.phone_number, email: this.props.selectedPerson.email, function: this.props.selectedPerson.function, mobileNumber: this.props.selectedPerson.mobile_number, faxNumber: this.props.selectedPerson.fax_number, unlocked: false, comment: this.props.selectedPerson.comment, spa_comment: this.props.selectedPerson.spa_comment, primary_contact: this.props.selectedPerson.primary_contact, site_function: this.props.selectedPerson.site_function, join_date: this.props.selectedPerson.join_date, left: this.props.selectedPerson.left, selectedFunction: selectedFunction});
    }
    if ((this.state.left != prevState.left) && (this.state.left)) {
      if (this.props.selectedPerson.leave_date) {
        this.setState({leave_date: this.props.selectedPerson.leave_date});
      } else {
        var today = new Date();
        this.setState({leave_date: today});
      }

    } else if (this.state.left != prevState.left) {
      this.setState({leave_date: null});
    }
  }

  getDepartments = () => {
    let currentComponent = this;
    // departments = this.this.props.peopleDepartmentsAssociations.filter(assoc => assoc.person_id == this.props.selectedPerson.id).map(assoc => assoc.department_id);
    currentComponent.setState({ departments: this.props.departments });
    // let currentComponent = this;
    // // const departments = this.state.departments;
    // fetch('/people/'+this.props.selectedPerson.id+'/get_departments', {
    //   credentials: 'include',
    // })
    // .then(function(response){return response.json();})
    // .then(function(data){
    //   // console.log(data);
    //   currentComponent.setState({ departments: data.departments, institutions: data.institutions });
    // });
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleChange2 = (event, checked) => {
    // console.log(event.target.name);
    // console.log(checked);
    this.setState({[event.target.name]: checked});
  }

  handleFunctionChange = (newValue, actionMeta) => {
    // console.log(newValue);
    this.setState({selectedFunction: newValue});
  }

  handleRemoveDepartment = (department_id) => {
    // console.log(department_id);
    let currentComponent = this;
    fetch('/people/'+this.props.selectedPerson.id+'/remove_from_department', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        department_id: department_id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        let newDepartments = [].concat(currentComponent.state.departments);
        const departmentIndex = newDepartments.findIndex(newDepartment => newDepartment.id == department_id);
        newDepartments.splice(departmentIndex,1);
        currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  };

  handleDoubleClickDepartment = event => {
    console.log(event);
    console.log("doubleClick")
  };

  handleStatusboxLockChange = event => {
    if (this.state.unlocked == false) {
      this.setState({unlocked: true})
    } else {
      this.setState({unlocked: false})
    }
  }

  handleClose = event => {
    this.setState({lastName: this.props.selectedPerson.last_name, firstName: this.props.selectedPerson.first_name, title: this.props.selectedPerson.title, phoneNumber: this.props.selectedPerson.phone_number, email: this.props.selectedPerson.email, function: this.props.selectedPerson.function, mobileNumber: this.props.selectedPerson.mobile_number, faxNumber: this.props.selectedPerson.fax_number, unlocked: false, });
    this.props.handleClose();
  }

  render(props) {
    // const { classes } = this.props;
    const { departments } = this.state;
    let leave_date = null;
    if (this.state.leave_date && this.state.left) {
      leave_date = this.state.leave_date;
    }
    const saveable = (this.state.leave_date && this.state.left) || !(this.state.left);
    var departmentChips = null;
    var handleRemoveDepartment = null;
    // if (this.state.unlocked) {
    //   handleRemoveDepartment = this.handleRemoveDepartment;
    // }
    if (departments) {
      if (departments.length > 0) {
        departmentChips = departments.map((department) =>
          <Chip
            // className={classes.chip}
            size="small"
            label={department.name}
            onDoubleClick={this.handleDoubleClickDepartment}
            onDelete={this.state.unlocked ? () => this.handleRemoveDepartment(department.id) : null}
            clickable
            color="primary"
          />
        );
      }
    }
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">Zentrumsspezifische Informationen zu Person editieren</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
            <InputLabel
              filled
              animated
              shrink
              margin="dense"
              variant="outlined"
              error={!this.state.selectedFunction}
            >
              Funktion
            </InputLabel>
            <SitePersonFunctionSelector
              selectedFunction={this.state.selectedFunction}
              handleChange={this.handleFunctionChange}
              // handleInputValueChange={this.handleFunctionChange}
              onCreateOption={this.handleCreateFunctionOption}
              // styles={colourStyles}
            />
          </FormControl>
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={this.state.primary_contact}
                onChange={this.handleChange2}
              />
            }
            name="primary_contact"
            label="Primärer Kontakt"
          />
          <TextField
            id="join_date"
            label="Eintrittsdatum"
            type="date"
            defaultValue={this.state.join_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={this.state.left}
                onChange={this.handleChange2}
              />
            }
            label="Ausgeschieden"
            name="left"
          />
          <TextField
            id="leave_date"
            label="Austrittsdatum"
            type="date"
            // defaultValue={this.state.leave_date}
            value={this.state.leave_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={(!(this.state.leave_date) && this.state.left)}
            disabled={!(this.state.left)}
            style={{zIndex: 1}}
          />
          <TextField
            id="spa_comment"
            label="Kommentar"
            onChange={this.handleChange}
            multiline
            // className={classes.textField}
            margin="dense"
            variant="outlined"
            value={this.state.spa_comment}
            fullWidth
            style={{zIndex: 1}}
            type="text"
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.props.handleSave({
            spa_id: this.props.selectedPerson.id,
            function: this.state.selectedFunction.value,
            comment: this.state.spa_comment,
            primary_contact: this.state.primary_contact,
            join_date: this.state.join_date,
            leave_date: this.state.leave_date,
            left: this.state.left,
            comment: this.state.spa_comment,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EditSitesPeopleAssociationsDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditSitesPeopleAssociationsDialog);
