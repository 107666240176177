import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcbopProOksFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
    <>
      <Typography variant="h6">QLQ C30</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
          <TextField
          margin="dense"
          id="oks_01"
          label="oks_01"
          value={obj.oks_01}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_02"
          label="oks_02"
          value={obj.oks_02}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_03"
          label="oks_03"
          value={obj.oks_03}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_04"
          label="oks_04"
          value={obj.oks_04}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_05"
          label="oks_05"
          value={obj.oks_05}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_06"
          label="oks_06"
          value={obj.oks_06}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_07"
          label="oks_07"
          value={obj.oks_07}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_08"
          label="oks_08"
          value={obj.oks_08}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_09"
          label="oks_09"
          value={obj.oks_09}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_10"
          label="oks_10"
          value={obj.oks_10}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="oks_11"
          label="oks_11"
          value={obj.oks_11}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        
        <TextField
          margin="dense"
          id="oks_12"
          label="oks_12"
          value={obj.oks_12}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </>
  );
}
