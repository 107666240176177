import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import TrialSelect from './TrialSelect.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function EcrfFunctionFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleInputValueChange, onCreateOption, handleSelectChange, readOnly, selectOptions, hiddenFields } = props;
  // console.log(obj, readOnly);
  console.log(props.obj);
  // console.log(obj.ecrf_trial_function_associations.map(assoc => assoc.ecrf_trial_configuration_id));
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <TextField
          autoFocus
          margin="dense"
          id="ecrf_trial_configuration_id"
          label="Studie"
          value={(obj != null && obj.ecrf_trial_configuration_id) ? obj.ecrf_trial_configuration_id : null}
          fullWidth
          variant="outlined"
          style={{zIndex: 989}}
          handleChange={handleChange}
          required={props.requiredFields.includes("ecrf_trial_configuration_id")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("ecrf_trial_configuration_id"))),
          }}
          type="select"
          options={selectOptions["ecrf_trial_configuration_id"]}
          size="small"
          data-cy="trialSelect"
          disabled
          // multiple
        />
        <TextField
          margin="dense"
          id="name"
          label="Name"
          value={obj.name}
          fullWidth
          variant="outlined"
          style={{zIndex: 988}}
          handleChange={handleChange}
          inputValue={props.inputValue}
          handleInputValueChange={handleInputValueChange}
          onCreateOption={onCreateOption}
          required={props.requiredFields.includes("name")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("name"))),
          }}
          type="string"
          options={selectOptions["name"]}
          // size="small"
          data-cy="select_name"
        />
        {/* <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
          <InputLabel
            filled
            animated
            shrink
            margin="dense"
            variant="outlined"
            // error={!this.state.selectedFunction}
          >
            Person
          </InputLabel>
          <CreatableSelect
            value={this.props.selectedPerson}
            onChange={this.props.handleChange}
            options={this.state.peopleOptions}
            onCreateOption={this.onCreateOption}
            formatCreateLabel={this.formatCreate}
            inputValue={this.props.inputValue}
            onInputChange={(newValue, actionMeta) => this.props.handleInputValueChange(newValue, actionMeta)}
            autoFocus={this.props.autoFocus}
            defaultMenuIsOpen={true}
            onBlur={null}
            // isValidNewOption={this.showAdd}
          />
        </FormControl> */}
      </Grid>
    </Grid>
  );
}
