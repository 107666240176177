import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import MUIDataTable from "mui-datatables";

import { withSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import EnhancedTable from './EnhancedTable.jsx';
import Select from 'react-select';
import TrialData from './TrialData.jsx';
import TrialCustomData from './TrialCustomData.jsx';
import TrialAdditionalInformation from './TrialAdditionalInformation.jsx';
import TrialPeople from './TrialPeople.jsx';
import EditTrialDataDialog from './EditTrialDataDialog.jsx';
import EditTrialAdditionalInformationDialog from './EditTrialAdditionalInformationDialog.jsx';
import AddPersonToTrialDialog from './AddPersonToTrialDialog.jsx';
import InfoPersonTrialDialog from './InfoPersonTrialDialog.jsx';
import EditPersonDialog from './EditPersonDialog.jsx';
import EditPtaDialog from './EditPtaDialog.jsx';
import EditTrialCustomDataDialog from './EditTrialCustomDataDialog.jsx';
import AddTrialCustomDataDialog from './AddTrialCustomDataDialog.jsx';
import NewNoteDialog from './NewNoteDialog.jsx';
import EditNoteDialog from './EditNoteDialog.jsx';
import EditTaskDialog from './EditTaskDialog.jsx';
import InfoTaskDialog from './InfoTaskDialog.jsx';
import NewTaskDialog from './NewTaskDialog.jsx';


import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

const peopleColumns = [
  {
    id: "primary_contact",
    label: "*",
    numeric: false,
    disablePadding: false,
    type: "starMark",
    filterable: true,
  },
  {
    id: "trial_role",
    label: "Funktion",
    numeric: false,
    disablePadding: false,
    type: "string",
    filterable: true,
  },
  {
    id: "last_name",
    secondary: "last_name",
    label: "Name",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "user",
    label: "User",
    numeric: false,
    disablePadding: true,
    type: "usericon"
  },
  {
    id: "phone_number",
    secondary: "phone_number",
    label: "Tel.",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "left",
    label: "Ausgeschieden",
    type: "hidden",
    filterable: true,
  },
];

const noteColumns = [
  {
   id: "entry_date",
   label: "Datum",
   numeric: false,
   disablePadding: false,
   date: true,
   type: "date",
  },
  {
   id: "note",
   label: "Notiz",
   numeric: false,
   disablePadding: false,
  },
 ];
//  const taskColumns = [
//   {
//     id: "title",
//     label: "Titel",
//     numeric: false,
//     padding: 'none',
//   },
//   {
//     id: "nextReminderDate",
//     label: "Erinnerungsdatum",
//     numeric: false,
//     padding: 'none',
//     date: true,
//     type: "date",
//   },
//   {
//     id: "date_due",
//     label: "Fälligkeitsdatum",
//     numeric: false,
//     padding: 'none',
//     date: true,
//     type: "date",
//   },
//   {
//     id: "description",
//     label: "Beschreibung",
//     numeric: false,
//     padding: 'none',
//     type: "textNoBreak",
//   },
//   {
//     id: "statusText",
//     label: "Status",
//     numeric: false,
//     padding: 'none',
//     type: "objectStatus",
//     filterable: true,
//   },
//   {
//     id: "created_at",
//     label: "Erstellungsdatum",
//     numeric: false,
//     padding: 'none',
//     date: true,
//     type: "date",
//   },
//   {
//     id: "updated_at",
//     label: "Letzte Änderung",
//     numeric: false,
//     padding: 'none',
//     date: true,
//     type: "date",
//   },
// ];

class ShowTrial extends React.Component {
  constructor(props) {
    super(props);
    let pta = {};
    let pta_filtered = {};
    let user = props.user;
    try {
      user = JSON.parse(props.user);
    } catch {
      console.log(props.user);
    }
    if (this.props.people_trials_associations) {
      pta = this.props.people_trials_associations.map(selected_pta => {
        // console.log(selected_pta);
        let ret = Object.assign({}, selected_pta.person, selected_pta);
        delete ret["person"];
        // ret["pta_comment"] = selected_pta.comment;
        // ret["comment"] = selected_pta.person.comment;
        return (
          ret
        )
      })
      pta_filtered = pta.filter(assoc => assoc.overview_show);
    }

    this.state = {trial: this.props.trial, people_trials_associations: pta, people_trials_associations_filtered: pta_filtered, people_departments: props.people_departments, people_institutions: props.people_institutions, peopleDepartmentsAssociations: props.people_departments_associations, selectedPeopleIds: [], selectedPeople: [], selectedPeopleIndices: [], peopleFilters: {left: {value: false}, overview_show: {value: true}}, selectedNotes: [], selectedNoteIds: [], selectedNoteIndices: [], editingCustom: null, openDialogs: {data: false, addPersonToTrial: false, infoPersonDialog: false, addCustomData: false, editCustomData: false, editTrialCustomData: false, newNote: false, editNote: false}, expandedTable: {Personen: true, Notizen: true}, user: user};
    // this.setState({trial: this.props.trial});
    this.form = React.createRef();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.people_trials_associations != this.state.people_trials_associations) {
  //     let pta = {};
  //     if (this.props.people_trials_associations) {
  //       pta = this.props.people_trials_associations.map(selected_pta => {
  //         // console.log(selected_pta);
  //         let ret = Object.assign({}, selected_pta.person, selected_pta);
  //         // ret["pta_comment"] = selected_pta.comment;
  //         // ret["comment"] = selected_pta.person.comment;
  //         return (
  //           ret
  //         )
  //       })
  //     }
  //     this.setState({people_trials_associations: pta});
  //   }
  // }

  handleEditTrialDataOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editData"] = true;
    this.setState({openDialogs: openDialogs});
  }

  handleEditTrialCustomDataOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editTrialCustomData"] = true;
    this.setState({openDialogs: openDialogs});
  }

  handleAddTrialCustomDataOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["addCustomData"] = true;
    this.setState({openDialogs: openDialogs});
  }

  handleEditCustomDataOpen = csf => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editCustomData"] = true;
    this.setState({openDialogs: openDialogs, editingCustom: csf});
  }

  // handleEditTrialCustomDataClose = event => {
  //   var openDialogs = {...this.openDialogs};
  //   openDialogs["editTrialCustomData"] = false;
  //   this.setState({openDialogs: openDialogs});
  // }

  handleEditCustomDataClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editCustomData"] = false;
    openDialogs["editTrialCustomData"] = true;
    this.setState({openDialogs: openDialogs, editingCustom: null});
  }

  handleEditTrialAdditionalInformationOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editAdditionalInformation"] = true;
    this.setState({openDialogs: openDialogs});
  }

  handleEditTrialAdditionalInformationClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editAdditionalInformation"] = false;
    this.setState({openDialogs: openDialogs});
  }

  handleEditTrialDataClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editData"] = false;
    this.setState({openDialogs: openDialogs, editingCustom: null});
  }

  handleEditTrialCustomDataClose = csf => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editCustomData"] = false;
    openDialogs["addCustomData"] = false;
    openDialogs["editTrialCustomData"] = false;
    this.setState({openDialogs: openDialogs, editingCustom: null});
  }

  handleAddTrialCustomDataClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editTrialCustomData"] = true;
    openDialogs["addCustomData"] = false;
    this.setState({openDialogs: openDialogs});
  }

  handlePeopleChange = (checked, allRowsSelected, allObjectsSelected) => {
    if (allObjectsSelected.findIndex(obj => obj.missingRow) == -1) {
      const indices = allRowsSelected.map(personId => this.state.people_trials_associations.findIndex(person => person.person_id == personId));
      const selectedPeople = allObjectsSelected;
      // .map(selectedPerson => selectedPerson.person)
      this.setState({
        // allPeopleRowsSelected: allRowsSelected,
        selectedPeople: selectedPeople,
        selectedPeopleIds: allRowsSelected,
        selectedPeopleIndices: indices,
      });
    }
  };

  handleMailToPeople = event => {

  }

  // handleInfoPersonClick = event => {

  // }

  handleRemovePersonClick = event => {
    // console.log("remove person");
    // console.log(event);
    let currentComponent = this;
    this.setState({ newSiteOpen: false});
    fetch('/trials/'+this.state.trial.id+'/execute_remove_people', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({pta_ids: currentComponent.state.selectedPeopleIds}),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.setState({ selectedPeople: [], selectedPeopleIds: [], selectedPeopleIndices: []})
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.forceUpdate();
  }

  handleAddPersonToTrialOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["addPersonToTrial"] = true;
    this.setState({openDialogs: openDialogs});
  }

  handleAddPersonToTrialClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["addPersonToTrial"] = false;
    this.setState({openDialogs: openDialogs});
  }

  handleFilterPeopleClick = event => {

  }

  handleInfoPersonClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["infoPersonDialog"] = false;
    this.setState({openDialogs: openDialogs});
  }

  handlePersonDoubleClick = (event, obj) => {
    if (!obj.missingRow) {
      var openDialogs = {...this.openDialogs};
      openDialogs["infoPersonDialog"] = true;
      var allObjectsSelected = [obj];
      var selectedPeopleIds = [obj.id];
      var selectedPeopleIndices = [this.state.people_trials_associations.findIndex(object => object.id === obj.id)];
      const selectedPeople = allObjectsSelected;
      // .map(selectedPerson => selectedPerson.person);
      this.setState({
        selectedPeople: allObjectsSelected,
        selectedPeopleIds: selectedPeopleIds,
        selectedPeopleIndices: selectedPeopleIndices,
        openDialogs: openDialogs,
      });
    }
  }

  handleAddPersonToTrialSave = obj => {
    let currentComponent = this;
    this.setState({ newSiteOpen: false});
    fetch('/trials/'+this.state.trial.id+'/execute_add_person', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({...obj}),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // const newPeopleTrialsAssociations = data.people_trials_associations.map(selected_pta => {
        //   let ret = Object.assign({}, selected_pta.person, selected_pta);
        //   return (
        //     ret
        //   )
        // });
        // let pta_filtered = {};
        // if (newPeopleTrialsAssociations) {
        //   pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
        // }
        // currentComponent.setState({ people_trials_associations: newPeopleTrialsAssociations, people_trials_associations_filtered: pta_filtered });
        // // currentComponent.handleFilterUpdate(newSites)
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
        currentComponent.handleAddPersonToTrialClose();
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Person existiert in dieser Studie bereits mit dieser Funktion.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.forceUpdate();
  };

  handleEditPtaOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editPtaDialog"] = true;
    this.setState({ openDialogs: openDialogs });
  };

  handleEditPtaClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editPtaDialog"] = false;
    this.setState({ openDialogs: openDialogs});
  };

  handleEditPtaSave = (person) => {
    let currentComponent = this;
    fetch('/trials/update_people_trials_association', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify(person),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // let newPta = Object.assign({}, data.pta.person, data.pta);
        // delete newPta["person"];
        // var newPeopleTrialsAssociations = [].concat(currentComponent.state.people_trials_associations);
        // const index = newPeopleTrialsAssociations.findIndex(pta => pta.id === newPta.id);
        // newPeopleTrialsAssociations[index] = newPta;
        // let pta_filtered = {};
        // if (newPeopleTrialsAssociations) {
        //   pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
        // }
        // let selectedPeople = [newPeopleTrialsAssociations[index]];
        var openDialogs = {...currentComponent.openDialogs};
        openDialogs["editPtaDialog"] = false;
        currentComponent.setState({ openDialogs: openDialogs });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Diese Person existiert in dieser Studie bereits mit dieser Funktion.', {variant: 'error'});
      }else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditPersonOpen = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editPersonDialog"] = true;
    this.setState({ openDialogs: openDialogs });
  };

  handleEditPersonClose = event => {
    var openDialogs = {...this.openDialogs};
    openDialogs["editPersonDialog"] = false;
    this.setState({ openDialogs: openDialogs});
  };

  handleEditPersonSave = (person) => {
    let currentComponent = this;
    fetch('/people/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify(person),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // let p = Object.assign({}, data.person);
        // delete p["id"];
        // var newPeopleTrialsAssociations = [].concat(currentComponent.state.people_trials_associations);
        // const index = newPeopleTrialsAssociations.findIndex(pta => pta.person_id === data.person.id);
        // let newPta = Object.assign({}, newPeopleTrialsAssociations[index], p);
        // // newPta["person"] = data.person;
        // newPeopleTrialsAssociations[index] = newPta;
        // let selectedPeople = [newPeopleTrialsAssociations[index]];
        var openDialogs = {...currentComponent.openDialogs};
        openDialogs["editPersonDialog"] = false;
        currentComponent.setState({ people_trials_associations: newPeopleTrialsAssociations, selectedPeople: selectedPeople, openDialogs: openDialogs});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handlePeopleFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({peopleFilters: {left: {value: false}, overview_show: {value: true}}});
    }
  }

  handlePeopleFilterChange = (filters, meta) => {
    let newPeopleFilters = {...this.state.peopleFilters};
    newPeopleFilters[meta["name"]] = filters;
    this.setState({peopleFilters: newPeopleFilters});
  }

  handleEditCustomTrialSave = (obj) => {
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/update_custom', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        trial: this.state.trial,
        ...obj.custom_string_field,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        var openDialogs = {...currentComponent.openDialogs};
        openDialogs["editCustomData"] = false;
        currentComponent.setState({openDialogs: openDialogs});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditCustomDataSave = (obj) => {
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/update_custom_field', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        csf: obj.custom_string_field,
        // ...obj.custom_string_field,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var openDialogs = {...currentComponent.openDialogs};
        currentComponent.handleEditCustomDataClose();
        // currentComponent.setState({openDialogs: openDialogs});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleAddCustomTrialSave = (obj) => {
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/add_custom', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...obj.custom_string_field,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleAddTrialCustomDataClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleRemoveCustomTrialSave = (csf) => {
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/remove_custom', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        csf_id: csf.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleAddTrialCustomDataClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditTrialSave = (trial) => {
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...trial,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        var openDialogs = {...currentComponent.openDialogs};
        openDialogs["editData"] = false;
        currentComponent.setState({openDialogs: openDialogs, trial: data.trial})
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleNewNoteOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newNote"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditNoteOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editNote"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewNoteClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newNote"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditNoteClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editNote"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleDeleteNote = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/delete_notes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        // contract_id: this.state.selectedContracts[0].id,
        note_ids: this.state.selectedNoteIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // this.handleDeleteProcessConfirmationDialogClose();
        currentComponent.setState({selectedNotes: [], selectedNoteIds: [], selectedNoteIndices: []});
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleNoteChange = (rowData, allRowsSelected, allObjectsSelected) => {
    // const ProcessIds = allObjectsSelected.map(site => site.institution_id);
    // const selectedProcesses = this.state.processes.filter(institution => institutionIds.includes(institution.id));
    // let newSelectedProcessStep = allObjectsSelected[0];
    // newSelectedProcessStep["date_deadline"] = ;
    this.setState({
      selectedNote: allObjectsSelected,
      selectedNoteIds: allRowsSelected,
    });
  };

  handleNoteDoubleClick = (event, obj) => {
    var allObjectsSelected = [obj];
    var selectedNoteIds = [obj.id];
    // var selectedNoteIndices = this.state.selectedContract[0].notes.findIndex(object => object.id === obj.id);
    this.setState({
      selectedNotes: allObjectsSelected,
      selectedNoteIds: selectedNoteIds,
    });
    this.handleEditNoteOpen();
  }

  handleNewNoteSave = (note) => {
    let currentComponent = this;
    // console.log(note);
    this.setState({ editNoteOpen: false});
    fetch('/trials/'+this.state.trial.id+'/new_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        // contract_id: this.state.selectedContracts[0].id,
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newNotes = [].concat(currentComponent.state.notes);
        // const noteIndex = newNotes.findIndex(note => note.id === data.note.id );
        // newNotes[noteIndex] = data.note;
        // currentComponent.setState({ notes: newNotes });
        // // currentComponent.updateNotes(currentComponent.state.selectedDepartmentIds);
        currentComponent.handleNewNoteClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateNotes(this.state.selectedDepartmentIds);
  }

  handleEditNoteSave = (note) => {
    let currentComponent = this;
    // console.log(note);
    this.setState({ editNoteOpen: false});
    fetch('/trials/'+this.state.trial.id+'/update_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
        // contract_id: this.state.selectedContracts[0].id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newNotes = [].concat(currentComponent.state.notes);
        // const noteIndex = newNotes.findIndex(note => note.id === data.note.id );
        // newNotes[noteIndex] = data.note;
        // currentComponent.setState({ notes: newNotes });
        // // currentComponent.updateNotes(currentComponent.state.selectedDepartmentIds);
        currentComponent.handleEditNoteClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateNotes(this.state.selectedDepartmentIds);
  }

  handleTrialsReceived = message => {
    console.log("received Trial");
    console.log(message);
    if (message.trial && message.trial.id == this.state.trial.id) {
      const trial = message.trial;
      const notes = [].concat(this.state.trial.polymorphic_notes).concat(this.state.trial.archive.polymorphic_notes);
      const allNoteIds = notes.map(note => note.id);
      const newSelectedNotes = this.state.selectedNotes.filter(note => allNoteIds.includes(note.id));
      const newSelectedNoteIds = newSelectedNotes.map(note => note.id);
      // const newSelectedNoteIndices =
      this.setState({ trial: trial, selectedNotes: newSelectedNotes, selectedNoteIds: newSelectedNoteIds });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
    }
    if (message.deleted_trial_ids && message.deleted_trial_ids.includes(this.state.trial.id)) {
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' gelöscht: ' + this.state.trial.short, {variant: 'success'});
      window.location.assign('/trials');
    }
    if (message.people_trials_association && message.people_trials_association.trial_id == this.state.trial.id) {
      let newPeopleTrialsAssociations = [].concat(this.state.people_trials_associations);
      const newPtaIndex = newPeopleTrialsAssociations.findIndex(pta => pta.id == message.people_trials_association.id);
      newPeopleTrialsAssociations[newPtaIndex] = Object.assign({}, this.state.people_trials_associations[newPtaIndex], message.people_trials_association);
      delete newPeopleTrialsAssociations[newPtaIndex]["person"];
      let pta_filtered = {};
      if (newPeopleTrialsAssociations) {
        pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
      }
      let selectedPeople = [].concat(this.state.selectedPeople);
      let changedSelectedPersonIndex = (selectedPeople.length > 0) ? selectedPeople.findIndex(selected_person => message.people_trials_association.id == selected_person.id) : null;
      if ((changedSelectedPersonIndex != null) && (changedSelectedPersonIndex != -1)) {
        selectedPeople[changedSelectedPersonIndex] = message.people_trials_association;
      }
      this.setState({ people_trials_associations: newPeopleTrialsAssociations, people_trials_associations_filtered: pta_filtered, selectedPeople: selectedPeople });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person: ' + message.people_trials_association.person.first_name + ' ' + message.people_trials_association.person.last_name, {variant: 'success'});
    }
    if (message.new_people_trials_association && message.trial_id == this.state.trial.id) {
      let newPta = Object.assign({}, message.new_people_trials_association.person, message.new_people_trials_association);
      delete newPta["person"];
      const newPeopleTrialsAssociations = [].concat(this.state.people_trials_associations, newPta);

      let pta_filtered = {};
      if (newPeopleTrialsAssociations) {
        pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
      }
      this.setState({ people_trials_associations: newPeopleTrialsAssociations, people_trials_associations_filtered: pta_filtered });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person hinzugefügt: ' + message.new_people_trials_association.person.first_name + ' ' + message.new_people_trials_association.person.last_name, {variant: 'success'});
    }
    if (message.removed_pta_ids) {
      const newPeopleTrialsAssociations = this.state.people_trials_associations.filter(pta => !message.removed_pta_ids.includes(pta.id));
      const removedPtas = this.state.people_trials_associations.filter(pta => message.removed_pta_ids.includes(pta.id));
      const namesArray = removedPtas.map(pta => pta.first_name + ' ' + pta.last_name);
      let pta_filtered = [];
      if (newPeopleTrialsAssociations) {
        pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
      }
      this.setState({ people_trials_associations: newPeopleTrialsAssociations, people_trials_associations_filtered: pta_filtered });
      const added_person = this.state.people_trials_associations.find(person => person.person_id == message.person_id);
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person(en) entfernt: ' + namesArray, {variant: 'success'});
    }
  }

  handlePeopleReceived = message => {
    console.log("received Person");
    console.log(message);
    if (message.person) {
      const ptas = this.state.people_trials_associations.filter(pta => pta.person_id == message.person.id);
      let newPtas = [].concat(this.state.people_trials_associations);
      if (ptas.length > 0){
        const ptaIds = ptas.map(pta => pta.id);
        newPtas = this.state.people_trials_associations.map(pta => {
          let ret = pta;
          if (ptaIds.includes(pta.id)) {
            const changedPtaIndex = this.state.people_trials_associations.findIndex(pta_int => pta_int.id == pta.id);
            ret = Object.assign({}, pta, message.person);
            ret["id"] = pta.id;
          }
          return ret
        });
      }
      let pta_filtered = [];
      if (newPtas) {
        pta_filtered = newPtas.filter(newPta => newPta.overview_show);
      }
      let selectedPeople = [].concat(this.state.selectedPeople);
      const changedSelectedPersonIndex = (selectedPeople.length > 0) ? selectedPeople.findIndex(selected_person => message.person.id == selected_person.person_id) : null;
      if ((changedSelectedPersonIndex != null) && (changedSelectedPersonIndex != -1)) {
        let newSelectedPerson = selectedPeople[changedSelectedPersonIndex];
        newSelectedPerson = Object.assign({}, newSelectedPerson, message.person);
        newSelectedPerson["id"] = selectedPeople[changedSelectedPersonIndex].id;
        selectedPeople[changedSelectedPersonIndex] = newSelectedPerson;
      }
      this.setState({ people_trials_associations: newPtas, people_trials_associations_filtered: pta_filtered, selectedPeople: selectedPeople });
      this.props.enqueueSnackbar('Person wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.full_name, {variant: 'success'});
    }

  }

  handleConnected = event => {
    console.log("connected");
  }

  handleFilters = () => {
    let ret = [].concat(this.state.people_trials_associations);
    if (this.state.peopleFilters["overview_show"] && ret.length > 0) {
      ret = ret.filter(person => ((person.overview_show == null && (this.state.peopleFilters["overview_show"]["value"] == true || this.state.peopleFilters["overview_show"]["value"] == null)) || person.overview_show == this.state.peopleFilters["overview_show"]["value"]));
    }
    if (this.state.peopleFilters["trial_role"] && ret.length > 0) {
      const trial_roles = this.state.peopleFilters.trial_role.map(role => role.value);
      ret = ret.filter(person => trial_roles.includes(person.trial_role));
    }
    if (this.state.peopleFilters["left"] != null && ret.length > 0) {
      ret = ret.filter(person => ((person.left == null && this.state.peopleFilters["left"].value == false) || (person.left == this.state.peopleFilters["left"].value) || this.state.peopleFilters["left"].value == null));
    }
    if (this.state.peopleFilters["primary_contact"] != null && ret.length > 0) {
      ret = ret.filter(person => (person.primary_contact == this.state.peopleFilters["primary_contact"].value));
    }
    return ret;
  }

  handleCopy = event => {
    console.log("copied");
    // const [isCopied, setCopied] = useClipboard("Text to copy");
  }

  renderCopyText = event => {
    let ret = "Titel\t" + this.state.trial.title + "\n";
    ret += "Akronym\t" + this.state.trial.short + "\n";
    if (this.state.trial.eudract_num) {
      ret += "EudraCT\t" + this.state.trial.eudract_num + "\n";
    }
    if (this.state.trial.bob_code) {
      ret += "BOB Vorlagenummer\t" + this.state.trial.bob_code + "\n";
    }
    if (this.state.trial.ek_code) {
      ret += "EK Aktenzeichen\t" + this.state.trial.ek_code + "\n";
    }
    if (this.state.trial.inspection_plan_code) {
      ret += "Prüfplan Code\t" + this.state.trial.inspection_plan_code + "\n";
    }
    if (this.state.trial.ctgov_code) {
      ret += "CT.gov Nr.\t" + this.state.trial.ctgov_code + "\n";
    }
    if (this.state.trial.comment) {
      ret += "Kommentar\t" + this.state.trial.comment;
    }
    return ret;
  }

  renderAdditionalInformationCopyText = event => {
    let ret = "Status\t" + this.state.trial.status.text + "\n";
    if (this.state.trial.trial_type) {
      ret += "Studientyp\t" + this.state.trial.trial_type + "\n";
    }
    if (this.state.trial.iit) {
      ret += "IIT/IIS\t" + ((this.state.trial.iit) ? "IIT" : "IIS") + "\n";
    }
    if (this.state.trial.phase) {
      ret += "Phase\t" + this.state.trial.phase + "\n";
    }
    if (this.state.trial.num_pat_goal) {
      ret += "Anzahl Patienten Ziel\t" + this.state.trial.num_pat_goal + "\n";
    }
    if (this.state.trial.num_patients_included) {
      ret += "Anzahl Patienten eingeschlossen\t" + this.state.trial.num_patients_included + "\n";
    }
    return ret;
  }

  renderCustomCopyText = event => {
    let ret = "";
    if (this.state.trial.custom_string_fields.length > 0) {
      this.state.trial.custom_string_fields.forEach(csf => ret += csf.label + "\t" + csf.text + "\n")
    }
    return ret;
  }

  handleExpandChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({expandedTable: newExpandState});
  }

  handleExpandPaperChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({expandedTable: newExpandState});
  }

  render() {
    const { classes, trial } = this.props;
    const {openDialogs, selectedTask} = this.state;
    const taskStatusList = [{value: "done", label: "Erledigt"}, {value: "open", label: "Offen"}];
    var departments = null;
    var institutions = null;
    // const people_trials_associations_filtered = this.handleFilters();
    let people_trials_associations_with_essential = [].concat(this.state.people_trials_associations);
    if (people_trials_associations_with_essential.findIndex(pta => pta.trial_role == "LKP" || pta.trial_role == "LKP/PI" || pta.trial_role == "PI") == -1) {
      people_trials_associations_with_essential.push({missingRow: true, tooltipText: "Bitte über den Button '+' hinzufügen", last_name: "", phone_number: "", trial_role: "LKP/PI"});
    }
    if (people_trials_associations_with_essential.findIndex(pta => pta.trial_role == "SP") == -1) {
      people_trials_associations_with_essential.push({missingRow: true, tooltipText: "Bitte über den Button '+' hinzufügen", last_name: "", phone_number: "", trial_role: "SP"});
    }
    if (people_trials_associations_with_essential.findIndex(pta => pta.trial_role == "PM") == -1) {
      people_trials_associations_with_essential.push({missingRow: true, tooltipText: "Bitte über den Button '+' hinzufügen", last_name: "", phone_number: "", trial_role: "PM"});
    }
    if (people_trials_associations_with_essential.findIndex(pta => pta.trial_role == "MO") == -1) {
      people_trials_associations_with_essential.push({missingRow: true, tooltipText: "Bitte über den Button '+' hinzufügen", last_name: "", phone_number: "", trial_role: "MO"});
    }
    // console.log(people_trials_associations_with_essential);
    if (this.state.selectedPeopleIds) {
      if (this.state.selectedPeopleIds.length == 1) {
        const assocs = this.state.peopleDepartmentsAssociations.filter(assoc => assoc.person_id == this.state.selectedPeople[0].person_id);
        departments = this.state.people_departments.filter(department => assocs.map(assoc => assoc.department_id).includes(department.id)).map(department => {
          let ret = {...department};
          ret["institutionName"] = this.state.people_institutions.find(institution => institution.id == department.institution_id).name;
          return ret;
        });
        institutions = this.state.people_institutions.filter(institution => departments.map(department => department.institution_id).includes(institution.id));
      }
    }
    let selectedPersonDepartments = [];
    if (this.state.selectedPeople.length == 1) {
      const selectedPersonDepartmentIds = this.state.peopleDepartmentsAssociations.filter(pda => pda.person_id == this.state.selectedPeople[0].person_id).map(pda => pda.department_id);
      selectedPersonDepartments = this.state.people_departments.filter(department => selectedPersonDepartmentIds.includes(department.id));
    }

    const notes = [].concat(this.state.trial.polymorphic_notes).concat(this.state.trial.archive.polymorphic_notes);

    return (
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "TrialsChannel"}}
            onReceived={this.handleTrialsReceived}
            onConnected={this.handleConnected}
          />
          <ActionCableConsumer
            channel={{channel: "PeopleChannel"}}
            onReceived={this.handlePeopleReceived}
            onConnected={this.handleConnected}
          />
          <ActionCableConsumer
            channel={{channel: "TasksChannel"}}
            onReceived={this.handleTasksReceived}
            onConnected={this.handleConnected}
          />
          <Grid item xs={4} >
            <Grid container justify="center">
              { (this.state.openDialogs.editData) &&
              <Can I="update" a="Trial">
                <EditTrialDataDialog
                  trial={this.state.trial}
                  open={this.state.openDialogs.editData}
                  handleSave={this.handleEditTrialSave}
                  handleClose={this.handleEditTrialDataClose}
                />
              </Can>}
              <Paper style={{borderStyle: "solid", width: '100%', borderWidth: "1px"}}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                  <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                    Studie: {this.state.trial.short}
                  </Typography>
                  <Toolbar
                    variant='dense'
                  >
                    <Can I="update" a="Trial">
                      <Tooltip title="Studie editieren">
                        <IconButton aria-label="Edit" onClick={this.handleEditTrialDataOpen}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Can>
                    
                    <CopyToClipboard onCopy={this.handleCopy} text={this.renderCopyText()}>
                      <Tooltip title="In die Zwischenablage kopieren">
                        <IconButton aria-label="Edit" >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                    
                  </Toolbar>
                </Box>
                <TrialData
                  trial={this.state.trial}
                  handleEditClick={this.handleEditTrialDataClick}
                  readOnly={true}
                />
              </Paper>
              <Can I="update" a="Trial">
                { (this.state.openDialogs.editTrialCustomData) &&
                <EditTrialCustomDataDialog
                  trial={this.state.trial}
                  open={this.state.openDialogs.editTrialCustomData}
                  handleAddOpen={this.handleAddTrialCustomDataOpen}
                  handleEditOpen={this.handleEditCustomDataOpen}
                  handleSave={this.handleEditCustomTrialSave}
                  handleRemove={this.handleRemoveCustomTrialSave}
                  handleClose={this.handleEditTrialCustomDataClose}
                />}
                { (this.state.openDialogs.addCustomData) &&
                <AddTrialCustomDataDialog
                  title="Neues Benutzerdefiniertes Feld anlegen"
                  trial={this.state.trial}
                  open={this.state.openDialogs.addCustomData}
                  handleSave={this.handleAddCustomTrialSave}
                  handleClose={this.handleAddTrialCustomDataClose}
                />}
                { (this.state.openDialogs.editCustomData) &&
                <AddTrialCustomDataDialog
                  title="Benutzerdefiniertes Feld editieren"
                  trial={this.state.trial}
                  csf={this.state.editingCustom}
                  open={this.state.openDialogs.editCustomData}
                  handleSave={this.handleEditCustomDataSave}
                  handleClose={this.handleEditCustomDataClose}
                />}
              </Can>
              <Paper style={{marginTop: '10px', width: '100%', borderStyle: "solid", borderWidth: "1px"}} >
                <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center" >
                  <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                    Benutzerdefinierte Felder
                  </Typography>
                  <Can I="update" a="Trial">
                    <Toolbar
                      variant='dense'
                    >
                      {/* <Tooltip title="Feld hinzufügen">
                        <IconButton aria-label="Edit" onClick={this.handleAddTrialCustomDataOpen}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip> */}
                      <CopyToClipboard onCopy={this.handleCopy} text={this.renderCustomCopyText()}>
                        <Tooltip title="In die Zwischenablage kopieren">
                          <IconButton aria-label="Edit" >
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                      <Tooltip title="Felder editieren">
                        <IconButton aria-label="Edit" onClick={this.handleEditTrialCustomDataOpen}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Toolbar>
                  </Can>
                </Box>
                <TrialCustomData
                  trial={this.state.trial}
                  handleEditClick={this.handleEditTrialDataClick}
                  readOnly={true}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={4} >
            <Grid container justify="center">
                <AddPersonToTrialDialog
                  trialId={this.state.trial.id}
                  open={this.state.openDialogs.addPersonToTrial}
                  handleClose={this.handleAddPersonToTrialClose}
                  handleSave={this.handleAddPersonToTrialSave}
                />
                { (this.state.selectedPeople.length == 1) &&
                  <Can I="update" a="Trial">
                    <InfoPersonTrialDialog
                      open={this.state.openDialogs.infoPersonDialog}
                      handleClose={this.handleInfoPersonClose}
                      selectedPerson={this.state.selectedPeople[0]}
                      numPeople={this.state.people_trials_associations.length}
                      selectedPersonIndex={this.state.selectedPeopleIndices[0]}
                      handleEditPersonClick={this.handleEditPersonOpen}
                      handleEditPtaClick={this.handleEditPtaOpen}
                      handleForwardClick={this.handlePersonForwardClick}
                      handleBackwardClick={this.handlePersonBackwardClick}
                      departments={selectedPersonDepartments}
                      institutions={this.state.people_institutions}
                    />
                    <EditPersonDialog
                      open={this.state.openDialogs.editPersonDialog}
                      handleClose={this.handleEditPersonClose}
                      handleSave={this.handleEditPersonSave}
                      selectedPerson={this.state.selectedPeople[0]}
                      // departments={departments}
                    />
                    <EditPtaDialog
                      open={this.state.openDialogs.editPtaDialog}
                      handleClose={this.handleEditPtaClose}
                      handleSave={this.handleEditPtaSave}
                      selectedPerson={this.state.selectedPeople[0]}
                    />
                  </Can>
                }
                <TrialPeople
                  people_trials_associations={people_trials_associations_with_essential}
                  singleSelect={false}
                  selectedIds={this.state.selectedPeopleIds}
                  selectedObjects={this.state.selectedPeople}
                  departments={selectedPersonDepartments}
                  institutions={this.state.people_institutions}
                  columns={peopleColumns}
                  handleChange={this.handlePeopleChange}
                  handleMailTo={this.handleMailToPeople}
                  // handleInfoPersonClick={this.handleInfoPersonClick}
                  handleAddOpen={this.handleAddPersonToTrialOpen}
                  handleAddClose={this.handleAddPersonToTrialClose}
                  handleFilterClick={this.handlePeopleFilterClick}
                  handleRemoveClick={this.handleRemovePersonClick}
                  onFilterChange={this.handlePeopleFilterChange}
                  onDoubleClick={this.handlePersonDoubleClick}
                  allData={this.state.people_trials_associations}
                  numSelected={this.state.selectedPeople.length}
                  filters={this.state.peopleFilters}
                  expanded={this.state.expandedTable["Personen"]}
                  handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                />
            </Grid>
          </Grid>
          <Grid item xs={4} >
            <Grid container justify="center">
              <EditTrialAdditionalInformationDialog
                trial={this.state.trial}
                open={this.state.openDialogs.editAdditionalInformation}
                handleSave={this.handleEditTrialSave}
                handleClose={this.handleEditTrialAdditionalInformationClose}
              />
              <Paper style={{borderStyle: "solid", width: '100%', borderWidth: "1px"}}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                  <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                    Weitere Informationen
                  </Typography>
                  <Toolbar
                    variant='dense'
                  >
                    <CopyToClipboard onCopy={this.handleCopy} text={this.renderAdditionalInformationCopyText()}>
                      <Tooltip title="In die Zwischenablage kopieren">
                        <IconButton aria-label="Edit" >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                    <Can I="update" a="Trial">
                      <Tooltip title="Studie editieren">
                        <IconButton aria-label="Edit" onClick={this.handleEditTrialAdditionalInformationOpen}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Can>
                  </Toolbar>
                </Box>
                <TrialAdditionalInformation
                  trial={this.state.trial}
                  handleEditClick={this.handleEditTrialAdditionalInformationClick}
                  readOnly={true}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} >
            { (openDialogs.editNote && this.state.selectedNotes.length == 1) &&
              <EditNoteDialog
                open={openDialogs.editNote}
                selectedNote={this.state.selectedNotes[0]}
                handleSave={this.handleEditNoteSave}
                handleClose={this.handleEditNoteClose}
              />
            }
            { (openDialogs.newNote) &&
              <NewNoteDialog
                open={openDialogs.newNote}
                handleSave={this.handleNewNoteSave}
                handleClose={this.handleNewNoteClose}
              />
            }
            <Grid container justify="center">
              {/* <Paper> */}
                <EnhancedTable
                  title={"Notizen"}
                  data={(notes) ? notes : []}
                  columns={noteColumns}
                  // singleSelect={!this.state.multipleSelectMode}
                  multipleSelectable
                  selectedIds={this.state.selectedNoteIds}
                  selectedObjects={this.state.selectedNotes}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleNoteChange(rowData, allRowsSelected, allObjectsSelected)}
                  handleAddClick={this.handleNewNoteOpen}
                  // handleInfoClick={this.handleInfoSiteClick}
                  // handleEditClick={this.handleEditSiteClick}
                  // handleRemoveClick={this.handleRemoveSiteClick}
                  handleDeleteClick={this.handleDeleteNote}
                  // handleFilterClick={this.handleSitesFilterClick}
                  // filters={this.state.siteFilters}
                  rowsPerPage={50}
                  orderBy="entry_date"
                  order="desc"
                  onDoubleClick={this.handleNoteDoubleClick}
                  // markedIds={this.state.selectedDepartmentsSitesIds}
                  // onFilterChange={this.handleSitesFilterChange}
                  allData={notes}
                  selectable
                  expanded={this.state.expandedTable["Notizen"]}
                  handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                />
              {/* </Paper> */}
            </Grid>
          </Grid>
        </Grid>

    )
  }
}

ShowTrial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(ShowTrial));
