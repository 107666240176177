import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'

class NewPersonDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {selectedSite: this.props.selectedSite, open: this.props.open, selectedDepartment: this.props.selectedDepartment, departments: null};
    this.form = React.createRef();
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleSiteChange = event => {
    let currentComponent = this;
    let site_id = event.target.value.id
    fetch('/trials/'+this.props.trialId+'/site_selection/get_institution_departments?'+$.param({site_id: site_id}), {
      credentials: 'include',
    })
      .then(function(response){return response.json();})
      .then(function(data){
        // console.log(data);
        currentComponent.setState({ departments: data.institution_departments});
      });
    currentComponent.setState({ selectedSite: event.target.value});
  };

  handleDepartmentChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    // const department_id = value.id;
    // // console.log(site_id)
    // fetch('/trials/'+this.state.trial.id+'/site_selection/get_department_people?'+$.param({department_id: department_id}))
    //   .then(function(response){return response.json();})
    //   .then(function(data){
    //     console.log(data);
    //     currentComponent.setState({ department_people: data.department_people});
    //   });
    currentComponent.setState({ selectedDepartment: value});
  };



  render(props) {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Studienboard editieren</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="lastName"
            label="Nachname"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            type="string"
          />
          <TextField
            margin="dense"
            id="firstName"
            label="Vorname"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            type="string"
          />
          <TextField
            margin="dense"
            id="title"
            label="Titel"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            type="string"
          />
          <TextField
            margin="dense"
            id="phoneNumber"
            label="Telefonnummer"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            type="string"
          />
          <TextField
            margin="dense"
            id="mobileNumber"
            label="Telefonnummer (mobil)"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            type="string"
          />
          <TextField
            margin="dense"
            id="faxNumber"
            label="Fax"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            type="string"
          />
          <TextField
            margin="dense"
            id="email"
            label="E-Mail"
            type="email"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="function"
            label="Stelle"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            type="string"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          onClick={() => this.props.handleSave({
            lastName: this.state.lastName,
            firstName: this.state.firstName,
            title: this.state.title,
            phoneNumber: this.state.phoneNumber,
            faxNumber: this.state.faxNumber,
            mobileNumber: this.state.mobileNumber,
            email: this.state.email,
            func: this.state.function,
            departmentId: this.state.selectedDepartment.id,
            siteId: this.state.selectedSite.id}
          )}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewPersonDialog;
