import React from 'react';

import { SnackbarProvider } from 'notistack';
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// // import { createTheme } from '@mui/styles';
import blue from '@mui/material/colors/blue';
import ApplicationBar from './ApplicationBar.jsx';
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability';
import { AbilityContext } from './Can.js';
import ability from './ability.js';
import theme from './theme.js';

function updateAbility(ability, abilities, user) {
  // console.log(abilities, user);
  const { can, rules } = new AbilityBuilder(Ability);
  if (user && user.admin) {
    can('manage', 'all');
  } else {
    if (abilities) {
      for (let i = 0; i < abilities.length; i++) {
        can(abilities[i].actions[0], abilities[i].subject[0]);
      }
    }
  }

  ability.update(rules);
}

// const theme = createTheme({
//   palette: {
//     primary: {main: "#1B4E9C"},
//     secondary: {main: "#2C7AC8"},
//     neutral: {main: "#FFFFFF"},
//   },
// });



// export const AbilityContext = createContext();
// export const Can = createContextualCan(AbilityContext.Consumer);

// add action to all snackbars
// const notistackRef = React.createRef();
// const onClickDismiss = key => () => {
//     notistackRef.current.closeSnackbar(key);
// }

export default function SnackbarWrappedApplicationBar(props) {
  let user = props.user;
  try {
    user = JSON.parse(props.user);
  } catch {
    console.log(props.user);
  }
  
  updateAbility(ability, props.abilities, user);
  // console.log(props.abilities, ability);
  console.log(window.location.hostname, window.location.port);
  let cableUrl = null;
  if (window.location.hostname == "topas.nct-heidelberg.de") {
    cableUrl = "wss://" + window.location.hostname + "/cable";
  } else {
    cableUrl = "ws://" + window.location.hostname + ":" + window.location.port + "/cable";
  }
  // console.log(JSON.parse(props.user));
  // console.log(props);
  // const user = (props.user) ? JSON.parse(props.user) : null;
  // let user = (props.user) ? props.user : null;
  // console.log(user, (props.user && props.user.activated))
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <AbilityContext.Provider value={ability}>
          <ActionCableProvider url={cableUrl}>
            <ApplicationBar controller={props.controller} obj_id={props.obj_id} action={props.action} title={props.title} logged_in={props.logged_in} user={user} abilities={props.abilities} root_url={props.root_url}/>
          </ActionCableProvider>
        </AbilityContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
