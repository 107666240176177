import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from 'react-select';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'


class EditDocumentVersionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", site_functions: [], version_name: ""};
    this.form = React.createRef();
  }

  componentDidMount(prevProps) {
    // console.log(this.props);
    this.setState({date_start: this.props.selectedDocument["versions"][parseInt(this.props.selectedDocumentVersion)]["date_start"], version_name: this.props.selectedDocument["versions"][parseInt(this.props.selectedDocumentVersion)]["version_name"]});

  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDocument != prevProps.selectedDocument) {
        this.setState({date_start: this.props.selectedDocument["versions"][parseInt(this.props.selectedDocumentVersion)]["date_start"], version_name: this.props.selectedDocument["versions"][parseInt(this.props.selectedDocumentVersion)]["version_name"]});
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  render(props) {
    const options = this.props.allPeople.map(row =>{
      return ({value: row.site_function, label: row.site_function})
    });
    const selectionOptions = options.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.value == item.value
      ))
    );

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // maxWidth="lg"
        fullWidth
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">Version editieren</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="version_name"
            label="Name der Version"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.version_name}
            type="string"
          />
          <TextField
            id="date_start"
            label="Gültig ab"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
            autoFocus
            value={this.state.date_start}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={this.props.handleSave({ date_start: this.state.date_start, version_name: this.state.version_name, document_version: this.props.selectedDocumentVersion, document_name: this.props.selectedDocument.name })} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditDocumentVersionDialog;
