import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import DropZone from './DropZone.jsx';
// import DataEntryEditDialog from './DataEntryEditDialog.jsx';

class FileUploadDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [], name: null, type: null, openDialogs: {}
    };
    this.form = React.createRef();
  }

  handleOpen = name => (event) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
    console.log(this.pro_data_registries);
  }

  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleDrop = (acceptedFiles, rejectedFiles, event) => {
    this.setState({
      files: acceptedFiles
    });
  }

  handleSave = (event) => {
    if (this.state.files.length == 0) {
      // catch empty save
      return;
    }
    const currentComponent = this;
    const payload = new FormData();
    payload.append('file', currentComponent.state.files[0]);

    fetch(this.props.saveUrl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: payload,
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.handleFileUpload(data);
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  render() {

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Datei hochladen</DialogTitle>
        <DialogContent>
          <DropZone
            onDrop={this.handleDrop}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={(object) => this.handleSave({
              // date: this.state.date
            }
            )}
            color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}


export default FileUploadDialog;
