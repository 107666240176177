import React, { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import Moment from 'moment';
import "moment/locale/de";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import NewReminderDialog from './NewReminderDialog.jsx';
import EditReminderDialog from './EditReminderDialog.jsx';

const toolbarStyles = makeStyles((theme) => createStyles({
  root: {
    paddingRight: theme.spacing,
    display: "flex",
    // flex: '0 1 auto',
    // flex: 1,
    flexBasis: '100%',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 1,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    // alignItems: 'flex-start',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
        }
      : {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.primary.main,
    align: "center",
  },
  title: {
    flex: '0 0 auto',
  },
}));



export default function RemindersList(props) {

  const handleEditClick = reminder => {
    setEditReminderOpen(true);
    setreminderToEdit(reminder);
  }

  const handleDeleteClick = reminder => {
    const currentComponent = this;
    fetch('/reminders/destroy', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        reminder: reminder,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.props.enqueueSnackbar('Erfolgreich geändert.', {variant: 'success'});
        // currentComponent.handleEditProcessStepClose();
        currentComponent.props.handleClose();
      } else {
        // currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  const { reminders, reminderable, reminderableType, reminderableId} = props;
  const [addReminderOpen, setAddReminderOpen] = useState(false);
  const [editReminderOpen, setEditReminderOpen] = useState(false);
  const [reminderToEdit, setreminderToEdit] = useState(null);

  const classes = toolbarStyles(props.theme);

  const listItems = reminders.map(reminder => {
    return (
      <ListItem>
        <ListItemText
          primary={Moment(reminder.remind_when).format('dd DD.MM.YYYY') + " " + reminder.title}
        />
        <ListItemSecondaryAction>
          <Tooltip title="Aktive Erinnerung editieren">
            <IconButton aria-label="Aktive Erinnerung editieren" onClick={() => handleEditClick(reminder)} disabled={reminder.notified}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Aktive Erinnerung löschen">
            <IconButton aria-label="Aktive Erinnerung löschen" onClick={() => handleDeleteClick(reminder)} disabled={reminder.notified}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    )
  })
  // console.log(addReminderOpen, editReminderOpen, reminderToEdit);
  return(
    <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
      { (addReminderOpen) &&
      <NewReminderDialog
        open={addReminderOpen}
        // handleSave
        reminderableType={reminderableType}
        reminderableId={reminderableId}
        reminderable={reminderable}
        handleClose={() => setAddReminderOpen(false)}
      />}
      { (editReminderOpen && reminderToEdit) &&
      <EditReminderDialog
        open={editReminderOpen}
        obj={reminderToEdit}
        // handleSave
        reminderableType={reminderableType}
        reminderableId={reminderableId}
        handleClose={() => setEditReminderOpen(false)}
      />}
      <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
        <Typography variant="h6" id="reminders" component="div" >
          Erinnerungen
        </Typography>
        <Toolbar
          variant='dense'
        >
          <Tooltip title="Aktive Erinnerung hinzufügen">
            <IconButton aria-label="Aktive Erinnerung hinzufügen" onClick={() => setAddReminderOpen(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Box>
      <List >
        {listItems}
      </List>
    </Paper>
      
  )
}