import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withSnackbar } from 'notistack';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import ShowSodaTrial from './ShowSodaTrial.jsx';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  MuiPaper: {
    elevation24: {
      height: 800,
      width: "100%",
    },
  },
});

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const trialNameA = a.name.toUpperCase();
  const trialNameB = b.name.toUpperCase();

  let comparison = 0;
  if (trialNameA > trialNameB) {
    comparison = 1;
  } else if (trialNameA < trialNameB) {
    comparison = -1;
  }
  return comparison;
}

class TrialPresentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
    this.form = React.createRef();
  }

  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes, trials, statuses } = this.props;
    const { expanded } = this.state;
    const sortedTrials = trials.sort(compare);
    const panels = sortedTrials.map( trial => {
      if (trial.status_id != 0) {
        return (

                <Accordion expanded={expanded === trial.name} onChange={this.handlePanelChange(trial.name)} >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>{trial.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ShowSodaTrial trial={trial} statuses={statuses}/>
                  </AccordionDetails>
                </Accordion>

        )
      } else {
        return null
      }
    })
    return (
      <Grid container >
        <Grid item xs={12} >
          <Grid container justify="center">
            {panels}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

TrialPresentation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme(withSnackbar(TrialPresentation)));
