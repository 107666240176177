import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { createTheme } from '@mui/styles';
import blue from '@mui/material/colors/blue';
import EcrfManagementConfiguration from './EcrfManagementConfiguration.jsx';
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability';
import { AbilityContext } from './Can.js';
import ability from './ability.js';
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import theme from './theme.js';

function updateAbility(ability, abilities, user) {
  console.log(abilities, user);
  const { can, rules } = new AbilityBuilder(Ability);
  if (user && user.admin) {
    can('manage', 'all');
  } else {
    for (let i = 0; i < abilities.length; i++) {
      can(abilities[i].actions[0], abilities[i].subject[0]);
    }
  }

  ability.update(rules);
}

// add action to all snackbars
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

export default function SnackbarWrappedEcrfManagementConfiguration(props) {
  let user = props.user;
  try {
    user = JSON.parse(props.user);
  } catch {
    console.log(props.user);
  }
  updateAbility(ability, props.abilities, user);
  let cableUrl = null;
  if (window.location.hostname == "topas.nct-heidelberg.de") {
    cableUrl = "wss://" + window.location.hostname + "/cable";
  } else {
    cableUrl = "ws://" + window.location.hostname + ":" + window.location.port + "/cable";
  }
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'right',
          horizontal: 'bottom',
        }}
      >
        <AbilityContext.Provider value={ability}>
          <ActionCableProvider url={cableUrl}>
            <EcrfManagementConfiguration ecrf_trial_configurations={props.ecrf_trial_configurations} ecrf_trial_roles={props.ecrf_trial_roles} ecrf_trial_functions={props.ecrf_trial_functions} controller={props.controller} title={props.title} root_url={props.root_url} user={user} />
          </ActionCableProvider>
        </AbilityContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
