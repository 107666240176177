import React from 'react';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import { flexbox } from '@mui/system';
import PropTypes from 'prop-types';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'
// import EnhancedTable from './EnhancedTable.jsx';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  content: {
      display: "flex",
      flexBasis: '100%',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-evenly',
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class InfoPerson extends React.Component {

  constructor(props) {
    super(props);
    // console.log("test");
    let selectedGender = {};
    if (this.props.selectedPerson.gender == "m") {
      selectedGender = {label: "Männlich", value: "m"}
    } else if (this.props.selectedPerson.gender == "w") {
      selectedGender = {label: "Weiblich", value: "w"}
    } else if (this.props.selectedPerson.gender == "?") {
      selectedGender = {label: "Unbekannt", value: "?"}
    }
    let departments = [];
    if (this.props.selectedPerson.sites_people_associations) {
      const department_ids = this.props.selectedPerson.sites_people_associations.filter(sites_people_association => {
        return sites_people_association.site_id == this.props.selectedSite.id;
      }).map(sites_people_association => sites_people_association.department_id);
      departments = this.props.selectedPerson.site_departments.filter(department => {
        return department_ids.includes(department.id);
      }).filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i);
    } else {
      departments = this.props.departments;
    }
    let salutation = "";
    if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
      salutation = "Sehr geehrter Herr";
    } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
      salutation = "Sehr geehrte Frau";
    } else if (this.props.selectedPerson.gender == "?") {
      salutation = "Sehr geehrte(r) Frau/Herr";
    }
    this.state = { selectedGender: selectedGender, selectedPerson: this.props.selectedPerson, institution_name: this.props.selectedPerson.institution_name, departments: departments, addressSelection: {title: true, address: true, phone_number: true, mobile_number: true, institution: true, department: true, fax_number: true, name: true, email: true, salutation: true}, salutation: salutation};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPerson !== prevProps.selectedPerson) {
      // this.getDepartments();
      let departments = [];
      if (this.props.selectedPerson.sites_people_associations) {
        const department_ids = this.props.selectedPerson.sites_people_associations.filter(sites_people_association => {
          return sites_people_association.site_id == this.props.selectedSite.id;
        }).map(sites_people_association => sites_people_association.department_id);
        departments = this.props.selectedPerson.site_departments.filter(department => {
          return department_ids.includes(department.id);
        }).filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i);
      } else {
        departments = this.props.departments;
      }
      let salutation = "";
      if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
        salutation = "Sehr geehrter Herr";
      } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
        salutation = "Sehr geehrte Frau";
      } else if (this.props.selectedPerson.gender == "?") {
        salutation = "Sehr geehrte(r) Frau/Herr";
      }
      this.setState({ selectedPerson: this.props.selectedPerson, selectedGender: {value: this.props.selectedPerson.gender}, departments: departments, addressSelection: {title: true, address: true, phone_number: true, mobile_number: true, institution: true, department: true, fax_number: true, name: true, email: true, salutation: true }, salutation: salutation});
    }
  }

  // getDepartments = () => {
  //   let currentComponent = this;
  //   // departments = this.this.props.peopleDepartmentsAssociations.filter(assoc => assoc.person_id == this.props.selectedPerson.id).map(assoc => assoc.department_id);
  //   currentComponent.setState({ departments: this.props.departments });
  //   // let currentComponent = this;
  //   // // const departments = this.state.departments;
  //   // fetch('/people/'+this.props.selectedPerson.id+'/get_departments', {
  //   //   credentials: 'include',
  //   // })
  //   // .then(function(response){return response.json();})
  //   // .then(function(data){
  //   //   // console.log(data);
  //   //   currentComponent.setState({ departments: data.departments, institutions: data.institutions });
  //   // });
  // }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleChange2 = event => {
    let currentComponent = this;
    // const target = event.target;
    // const value = target.value;
    // const name = target.id;
    let newAddressSelection = Object.assign({}, this.state.addressSelection);
    // console.log(event.target.value);
    newAddressSelection[event.target.value] = newAddressSelection[event.target.value] ? false : true;
    currentComponent.setState({ addressSelection: newAddressSelection });
  };

  handleRemoveDepartment = (department_id) => {
    // console.log(department_id);
    let currentComponent = this;
    fetch('/people/'+this.props.selectedPerson.id+'/remove_from_department', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        department_id: department_id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        let newDepartments = [].concat(currentComponent.state.departments);
        const departmentIndex = newDepartments.findIndex(newDepartment => newDepartment.id == department_id);
        newDepartments.splice(departmentIndex,1);
        currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  };

  handleDoubleClickDepartment = event => {
    console.log(event);
    console.log("doubleClick")
  };

  handleGenderChange = event => {
    this.setState({ selectedGender: event, gender: event.value });
  }

  handleClose = event => {
    this.setState({lastName: this.props.selectedPerson.last_name, firstName: this.props.selectedPerson.first_name, title: this.props.selectedPerson.title, phoneNumber: this.props.selectedPerson.phone_number, email: this.props.selectedPerson.email, function: this.props.selectedPerson.function, mobileNumber: this.props.selectedPerson.mobile_number, faxNumber: this.props.selectedPerson.fax_number, unlocked: false, selectedGender: {value: this.props.selectedPerson.gender}});
    this.props.handleClose();
  }

  handleStoryboardExpandChange = (val, title) => {
    this.setState({storyboardOpen: val});
  }

  render(props) {
    const { classes, departments } = this.props;
    const genderOptions = [
      {label: "Unbekannt", value: "?"},
      {label: "Männlich", value: "m"},
      {label: "Weiblich", value: "w"},
    ];
    var departmentChips = null;
    var handleRemoveDepartment = null;
    let addresses = [];
    if (departments) {
      if (departments.length > 0) {
        departmentChips = departments.map((department) =>
          <Chip
            className={classes.chip}
            size="small"
            label={department.name}
            onDoubleClick={this.handleDoubleClickDepartment}
            onDelete={this.state.unlocked ? () => this.handleRemoveDepartment(department.id) : null}
            clickable
            color="primary"
          />
        );
        addresses = this.state.departments.map((department) => {
          let address = "\n";
          if (this.state.addressSelection.salutation && this.state.salutation) {
            address += this.state.salutation + " ";
          }
          if (this.state.addressSelection.title && this.state.selectedPerson.title) {
            address += this.state.selectedPerson.title + " ";
          }
          if (this.state.addressSelection.name) {
            address += this.state.selectedPerson.first_name + " " + this.state.selectedPerson.last_name;
          }
          if ((this.state.addressSelection.salutation && this.state.salutation) || (this.state.addressSelection.title && this.state.selectedPerson.title) || this.state.addressSelection.name) {
            address += "\n";
          }
          const institution = this.props.institutions.find(institution => institution.id == department.institution_id);
          if (this.state.addressSelection.institution && institution) {
            address += institution.name + "\n";
          }
          if (this.state.addressSelection.department) {
            address += department.name + "\n";
          }
          if (this.state.addressSelection.address) {
            address += department.street + "\n" + department.zip + " " + department.city + "\n";
          }
          if (this.state.addressSelection.phone_number && this.state.phone_number) {
            address += "Tel.: " + this.state.selectedPerson.phone_number + "\n";
          }
          if (this.state.addressSelection.mobile_number && this.state.mobile_number) {
            address += "Mobil: " + this.state.selectedPerson.mobile_number + "\n";
          }
          if (this.state.addressSelection.fax_number && this.state.fax_number) {
            address += "Fax: " + this.state.selectedPerson.fax_number + "\n";
          }
          if (this.state.addressSelection.email && this.props.selectedPerson.email) {
            address += "E-Mail: " + this.state.selectedPerson.email + "\n";
          }
          return (
            <TextField
              margin="dense"
              id="addresses"
              label="Adressen"
              onChange={this.handleChange}
              fullWidth
              multiline
              variant="outlined"
              value={address}
              // error={!this.state.function}
              InputProps={{
                readOnly: true,
              }}
              type="text"
            />
          )
        })
      }
    }
    if ((departments == null) || departments.length == 0) {
      let address = "\n";
      if (this.state.addressSelection.salutation && this.state.salutation) {
        address += this.state.salutation + " ";
      }
      if (this.state.addressSelection.title && this.state.title) {
        address += this.state.title + " ";
      }
      if (this.state.addressSelection.name) {
        address += this.state.selectedPerson.first_name + " " + this.state.selectedPerson.last_name + "\n";
      }
      if (this.state.addressSelection.phone_number && this.state.selectedPerson.phone_number) {
        address += "Tel.: " + this.state.selectedPerson.phone_number + "\n";
      }
      if (this.state.addressSelection.mobile_number && this.state.selectedPerson.mobile_number) {
        address += "Mobil: " + this.state.selectedPerson.mobile_number + "\n";
      }
      if (this.state.addressSelection.fax_number && this.state.selectedPerson.fax_number) {
        address += "Fax: " + this.state.selectedPerson.fax_number + "\n";
      }
      if (this.state.addressSelection.email && this.props.selectedPerson.email) {
        address += "E-Mail: " + this.state.selectedPerson.email + "\n";
      }
      addresses = [
        <>
          <Typography style={{color: 'red', fontSize: '20px'}}>
            Hinweis: Diese Person ist keiner Abteilung zugeordnet und hat daher keine Adresse!
          </Typography>
          <TextField
            margin="dense"
            id="addresses"
            label="Adressen"
            onChange={this.handleChange}
            fullWidth
            multiline
            variant="outlined"
            value={address}
            // error={!this.state.function}
            InputProps={{
              readOnly: true,
            }}
            type="text"
          />
        </>
      ]
    }
    return (
            <>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                <Typography className={classes.title} variant="h8" color="inherit" noWrap style={{marginLeft: 5}}>
                  Adressbuch
                </Typography>
                <Tooltip title="Person im Adressbuch editieren">
                  <IconButton aria-label="Edit" onClick={this.props.handleEditClick}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
                <div style={{margin: "10px", backgroundColor: 'white', padding: '10px',}}>
                <TextField
                  margin="dense"
                  id="function"
                  label="Stelle"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.function || ""}
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="string"
                />
                <FormControl component="fieldset">
                  <FormLabel component="legend">Adressen</FormLabel>
                  <FormGroup aria-label="position" onChange={this.handleChange2} name="position" row>
                    <FormControlLabel
                      value="salutation"
                      control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.gender)} checked={(this.state.addressSelection.salutation && this.state.selectedPerson.gender)} />}
                      label="Anrede"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="title"
                      control={<Checkbox color="primary" disabled={(!this.state.selectedPerson.title)} checked={(this.state.addressSelection.title && this.state.selectedPerson.title)} />}
                      label="Titel"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="name"
                      control={<Checkbox color="primary" checked={this.state.addressSelection.name}/>}
                      label="Name"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="address"
                      control={<Checkbox color="primary" checked={this.state.addressSelection.address} disabled={((departments == null) || (departments.length == 0))}/>}
                      label="Adresse"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="institution"
                      control={<Checkbox color="primary" disabled={((!this.props.institutions) || (departments == null) || (departments.length == 0))} checked={(this.state.addressSelection.institution && (typeof this.props.institutions !== 'undefined'))}/>}
                      label="Institution"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="department"
                      control={<Checkbox color="primary" disabled={(!this.state.departments || (departments == null) || (departments.length == 0))} checked={(this.state.addressSelection.department && this.state.departments)}/>}
                      label="Abteilung"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="phone_number"
                      control={
                        <Checkbox
                          color="primary"  disabled={(!this.state.phone_number)} checked={(this.state.addressSelection.phone_number && (typeof this.state.phone_number !== 'undefined'))}
                        />
                      }
                      label="Telefonnummer"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="mobile_number"
                      control={<Checkbox color="primary" disabled={(!this.state.mobile_number)} checked={(this.state.addressSelection.mobile_number && this.state.mobile_number)}/>}
                      label="Handynummer"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="fax_number"
                      control={<Checkbox color="primary" disabled={(!this.state.fax_number)} checked={(this.state.addressSelection.fax_number && this.state.fax_number)}/>}
                      label="Fax-Nummer"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="email"
                      control={<Checkbox color="primary" disabled={(!this.state.email)} checked={(this.state.addressSelection.email && this.state.email)}/>}
                      label="E-Mail"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
                {addresses}
                <TextField
                  id="comment"
                  label="Kommentar"
                  onChange={this.handleChange}
                  multiline
                  // className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={this.state.selectedPerson.comment || ""}
                  fullWidth
                  style={{zIndex: 1}}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="text"
                />
              </div>
            </>
    )
  }
}

InfoPerson.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoPerson);
