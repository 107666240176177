import React from 'react';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class DeleteDepartmentConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.form = React.createRef();
  }

  render(props) {
    const selectedDepartmentIds = this.props.selectedDepartments.map(department => department.id);
    const pdas = this.props.peopleDepartmentsAssociations.filter(pda => selectedDepartmentIds.includes(pda.department_id));
    const connectedPeople = this.props.people.filter(person => pdas.map(pda => pda.person_id).includes(person.id));
    const departmentsListItems = this.props.selectedDepartments.map(department => {
        return <ListItem>
                  <ListItemText
                    primary={department.name}
                  />
                </ListItem>
    })
    const peopleListItems = connectedPeople.map(person => {
        return <ListItem>
                  <ListItemText
                    primary={person.first_name + " " + person.last_name}
                  />
                </ListItem>
    })
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={true}
      >
        <DialogTitle id="form-dialog-title">Abteilung(en) löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Die folgenden Abteilungen werden gelöscht:
          </DialogContentText>
          <div >
            <List >
              {departmentsListItems}
            </List>
          </div>
          <DialogContentText>
            Die folgenden Personen verlieren Abteilungen, bleiben aber im Adressbuch:
          </DialogContentText>
          <div >
            <List >
              {peopleListItems}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.props.handleSave()} color="primary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DeleteDepartmentConfirmationDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(DeleteDepartmentConfirmationDialog));
