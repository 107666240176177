import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import TextField from './TextField.jsx';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: "#f5f5f5",
  },
}));


export default function SubmissionResolution(props) {
  const classes = useStyles();
  // const files = [{name: "bla", type: "xls"}, {name: "bla2", type: "docx"}, {name: "PdfTest", type: "pdf"}];
  const { assoc, handleEditClick, locked } = props;
  return (
    <Grid container justify="center" direction="row" alignItems="start">
      <Grid item xs={12} >
        <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
          <TextField
            margin="dense"
            id="resolution"
            label={"Beschluss vom " + Moment(assoc.trial_board.board_date).format('dd DD.MM.YYYY')}
            value={assoc.board_resolution}
            fullWidth
            variant="outlined"
            // InputProps={{
            //   readOnly: locked,
            // }}
            disabled={locked}
            InputLabelProps={{ shrink: true }}
            multiline
            type="text"
          />
          { (!locked) &&
            <Tooltip title="Person im Adressbuch editieren">
              <IconButton aria-label="Edit" onClick={() => handleEditClick(assoc.id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          }
        </Box>
      </Grid>
    </Grid>
  );
}


