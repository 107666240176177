import React from 'react';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

import MomentUtils from "@date-io/moment";
import moment from 'moment'
import "moment/locale/de";

import TimeRegistrationFields from './TimeRegistrationFields.jsx';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

class TimeRegistrationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, trial: props.trial, decimalSelected: true, obj: {...props.obj, entry_date: props.obj.entry_date, time_str: props.obj.hours.replace(".","").padEnd(3,'0'), decimalSelected: true}};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {

  }

  componentDidMount(props) {
    // let currentComponent = this;
    // this.setState({});
  }

  renderHours = (input, decimalSelected) => {
    let ret = null;
    const value = input;
    const dot = /\d*\.\d{0,2}/;
    const comma = /\d*\,{1}\d{0,2}/;
    const colon = /\d*\:\d{0,2}/;
    const num = /\d+/;
    let newHour = null;
    const hh = value.substring(0,value.length-2);
    const mm = value.substring(value.length-2, value.length);
    if (value.match(num) && value.match(num)[0] == value) {
      if (value.match(num)) {
        let hh = "";
        let mm = "";
        if (value.length > 2) {
          hh = value.substring(0,value.length-2);
          mm = value.substring(value.length-2, value.length);
        } else {
          hh = "0";
          mm = value.padStart(2, "0");
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + (parseFloat(mm)/60.0);
        }
      }
    }
    if (value.match(dot) && value.match(dot)[0] == value) {
      if (value.match(dot)) {
        const dotIndex = value.indexOf(".");
        let hh = value.substring(0,dotIndex);
        if (!hh) {
          hh = "0";
        }
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!mm) {
          mm = "00";
        } else {
          mm = mm.padEnd("0",2);
        }
        // console.log(hh,mm);
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          // console.log(parseFloat(mm)/0.6);
          newHour = parseFloat(hh) +  ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
        // console.log(newHour);
      }
    }
    if (value.match(comma) && value.match(comma)[0] == value) {
      if (value.match(comma)) {
        const dotIndex = value.indexOf(",");
        let hh = value.substring(0,dotIndex);
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!hh) {
          hh = "0";
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
      }
    }
    if (value.match(colon) && value.match(colon)[0] == value) {
      if (value.match(colon)) {
        const dotIndex = value.indexOf(":");
        let hh = value.substring(0,dotIndex);
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!hh) {
          hh = "0";
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
      }
    }
    ret = newHour;
    return ret;
  }

  handleChange2 = (value, meta) => {
    const newVal = value.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = newVal;
    if (name == "role_id") {
      newObj["service_id"] = null;
    }
    this.setState({obj: newObj});
  }

  handleChangeTime = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    // const any = /\d+\:\d{2}|\d*\,{1}\d+|\d+\.\d+|\d+/;
    // const dot = /\d+\.\d+/;
    // const comma = /\d*\,{1}\d+/;
    const colon = /\d+\:\d{2}/;
    const num = /\d+/;
    // console.log(value.match(dot), value.match(comma), value.match(colon), value.match(num), value.match(any));
    // console.log(value.match(re), value, value.match(re)[0] == value);
    let newHour = null;
    const hh = value.substring(0,value.length-2);
    const mm = value.substring(value.length-2, value.length);
    if (name == "time_str") {
      newObj["time_str"] = value;
      // if (value.match(num) && value.match(num)[0] == value) {
      //   if (value.match(num)) {
      //     const hh = value.substring(0,value.length-2);
      //     const mm = value.substring(value.length-2, value.length);
      //     if (this.state.obj.decimalSelected) {
      //       newHour = parseFloat(hh + "." + mm);
      //     } else {
      //       newHour = parseFloat(hh) + (parseFloat(mm)/60.0);
      //     }
      //   }
      // }
    }
    newObj["hours"] = this.renderHours(value, this.state.obj.decimalSelected);;
    this.setState({ obj: newObj, time_str: value });
  }

  handleDecimalSelectedChange = (event) => {
    let newObj = {...this.state.obj};
    if (newObj["decimalSelected"]) {
      newObj["decimalSelected"] = false;
    } else {
      newObj["decimalSelected"] = true;
    }
    if (this.state.obj.time_str) {
      // let newHour = null;
      // const hh = this.state.obj.time_str.substring(0,this.state.obj.time_str.length-2);
      // const mm = this.state.obj.time_str.substring(this.state.obj.time_str.length-2, this.state.obj.time_str.length);
      // if (!this.state.obj.decimalSelected) {
      //   newHour = parseFloat(hh + "." + mm);
      // } else {
      //   newHour = parseFloat(hh) + (parseFloat(mm)/60.0);
      // }
      // newObj["hours"] = newHour;
      newObj["hours"] = this.renderHours(this.state.obj.time_str, newObj["decimalSelected"]);
    }
    this.setState({ obj: newObj });

    // if (this.state.obj.decimalSelected) {
    //   this.setState({decimalSelected: false});
    // } else {
    //   this.setState({decimalSelected: true});
    // }
  }

  handleChangeDate = (date, name) => {
    // console.log(date, start_time);
    let newObj = {...this.state.obj};
    newObj["entry_date"] = moment(date).toISOString().substr(0,10);
    this.setState({obj: newObj});
  };

  handlePrevDay = event => {
    // console.log(date, start_time);
    let newObj = {...this.state.obj};
    newObj["entry_date"] = newObj.entry_date.startOf("day").subtract(1, 'day').add(2, 'hour');
    this.setState({obj: newObj});
  };

  handleNextDay = event => {
    // console.log(date, start_time);
    let newObj = {...this.state.obj};
    newObj["entry_date"] = newObj.entry_date.startOf("day").add(1, 'day').add(2, 'hour');
    this.setState({obj: newObj});
  };


  handleClose = event => {
    this.props.handleClose();
  }

  render(props) {
    const {classes, roleOptions, serviceOptions} = this.props;
    const {decimalSelected, obj} = this.state;
    const saveable = (obj.hours && obj.role_id && obj.service_id && !obj.entry_date);

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        PaperProps={{style: {height: '700px'}}}
      >
        { (!this.props.obj) &&
        <DialogTitle id="form-dialog-title">Zeit anlegen</DialogTitle>}
        { (this.props.obj) &&
        <DialogTitle id="form-dialog-title">Zeit editieren</DialogTitle>}
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: blue[500]}}>
              <TimeRegistrationFields
                obj={obj}
                handleChange={this.handleChange}
                handleChange2={this.handleChange2}
                handleChangeTime={this.handleChangeTime}
                handleChangeDate={this.handleChangeDate}
                handlePrevDay={this.handlePrevDay}
                handleNextDay={this.handleNextDay}
                // handleSave={(this.props.obj) ? this.handleEditSave : this.handleSave}
                roleOptions={roleOptions}
                serviceOptions={serviceOptions}
                handleDecimalSelectedChange={this.handleDecimalSelectedChange}
                // decimalSelected={decimalSelected}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={saveable}
          onClick={(this.props.obj) ? () => this.props.handleEditSave(obj) : () => this.props.handleSave(obj)}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default TimeRegistrationDialog;
