import React, { Component } from 'react';
import { hydrate } from 'react-dom';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import LoadingButton from '@mui/lab/LoadingButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Dialog from '@mui/material/Dialog';


export default class GeneratePDFButton extends Component {
  state = {
    loading: false,
    dialogOpen: false,
  };

  download = (filename, url) => {
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  createAndDownloadPDF = (pdfContent, filename, divId, callback) => {
    setTimeout(() => {
      const link = (
        <div id={divId}>
          <PDFDownloadLink document={pdfContent} fileName={filename}>
            {({ blob, url, loading, error }) => {
              console.log(filename, blob, loading, url, error);
              if (!loading && blob != null) {
                // console.log(filename, blob);
                this.download(filename, URL.createObjectURL(blob));
                callback();
              }
            }}
          </PDFDownloadLink>
        </div>
      );
      const elem = document.createElement('div');
      document.getElementById('pdfButton').appendChild(elem);
      hydrate(link, elem);
    }, 500);
  };

  buildPDF = (pdfContent, filename, download, dialog) => {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true }, () => {
        if (download) {
          this.createAndDownloadPDF(pdfContent, filename, `file`, () =>
            this.setState({ loading: false })
          );
        }
        if (dialog) {
          this.setState({ dialogOpen: true })
          this.setState({ loading: false })
        }
      });
    }
  };


  render() {
    const { loading } = this.state;
    const { pdfContent, pdfTitle, buttonText, download, dialog, obj, user } = this.props;

    return (
      <div>
        <LoadingButton
          id="pdfButton"
          loading={loading}
          variant="contained"
          size="small"
          onClick={() => this.buildPDF(pdfContent, pdfTitle, download, dialog, obj, user)}
        >
          <PictureAsPdfIcon />
          {buttonText}
        </LoadingButton>
        {this.state.dialogOpen ?
          <Dialog
            open={this.state.dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
            maxWidth="lg"
          >
            <PDFViewer width={900} height={900} children={pdfContent} />
          </Dialog> : null}

      </div>
    )
  }
}