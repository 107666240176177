import React from 'react';
import Grid from '@mui/material/Grid';

class SiteRow extends React.Component {

  constructor(props) {
    super(props);
    // console.log(this.props.site);
    this.form = React.createRef();
  }

  render(props) {
    return (
      <Grid container >
        <Grid item xs={3}>
          {this.props.inst_description}
        </Grid>
      </Grid>
    )
  }
}

export default SiteRow;
