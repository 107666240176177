import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';

// import { AbilityBuilder, Ability } from '@casl/ability';
// import { AbilityContext } from './Can.js';
// import ability from './ability.js';
import theme from './theme.js';

import logo from '../../assets/images/Nct_01.svg.png';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function handleSignUpClick() {
  window.location.assign('/signup');
}

function handleLoginClick() {
  window.location.assign('/login');
}

// function updateAbility(ability, abilities, user) {
//   console.log(abilities, user);
//   const { can, rules } = new AbilityBuilder(Ability);
//   if (user && user.admin) {
//     can('manage', 'all');
//   } else {
//     for (let i = 0; i < abilities.length; i++) {
//       can(abilities[i].actions[0], abilities[i].subject[0]);
//     }
//   }

//   ability.update(rules);
// }

export default function Home(props) {
  // let user = props.user;
  // try {
  //   user = JSON.parse(props.user);
  // } catch {
  //   console.log(props.user);
  // }
  // updateAbility(ability, props.abilities, user);

  return (

    <ThemeProvider theme={theme}>
      {/* <AbilityContext.Provider value={ability}> */}
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} style={{padding: 10}} justify="center" >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={6} style={{padding: 10}} justify="center" >
                <Box display="flex" flexDirection="row" justifyContent="center" flexShrink={true} flexWrap="nowrap">
                  <Typography variant="h3" color="inherit" style={{marginLeft: 5}} align="center">
                    Willkommen im Trial Operations Planning and Administration System (TOPAS) der NCT Studienzentrale
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{padding: 10}} justify="center" >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={2} style={{padding: 10}} justify="center" >
                <Box display="flex" flexDirection="row" justifyContent="space-around" flexShrink={true} flexWrap="wrap">
                  {/* <ButtonGroup variant="contained" aria-label="outlined button group" style={{color: "primary", textAlign: "center"}}> */}
                    <Button
                      // type="submit"
                      // disabled={!saveable}
                      onClick={() => handleLoginClick()}
                      variant="contained"
                      color="primary">
                        Login
                    </Button>
                    <Button
                      // type="submit"
                      // disabled={!saveable}
                      onClick={() => handleSignUpClick()}
                      variant="contained"
                      color="primary">
                        Anmeldung
                    </Button>
                  {/* </ButtonGroup> */}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      {/* </AbilityContext.Provider> */}
    </ThemeProvider>
  );
}
