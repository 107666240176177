import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';

// import SiteSelector from './SiteSelector.jsx'
// import DepartmentSelector from './DepartmentSelector.jsx'

class NewKlifoDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { klifo: {status: "Offen"} };
    this.form = React.createRef();
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newKlifo = {...this.state.klifo};
    newKlifo[name] = value;
    currentComponent.setState({ klifo: newKlifo });
  };

  handleStatusChange = (event) => {
    // console.log(event);
    let newKlifo = {...this.state.klifo};
    newKlifo["status"] = event.value;
    this.setState({ klifo: newKlifo });
  }

  render(props) {
    const statusList = [{value: "Offen", label: "Offen"}, {value: "Geschlossen", label: "Geschlossen"}];
    const selectedStatus = statusList.find(status => status.value == this.state.klifo.status)
    const saveable = (this.state.klifo.topic) && (this.state.klifo.status)
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Neue Sitzung des Studienboards anlegen</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="topic"
            label="Thema"
            onChange={this.handleChange}
            value={this.state.klifo.topic}
            fullWidth
            error={!this.state.klifo.topic}
            variant="outlined"
            // disabled={this.state.locked}
            InputLabelProps={{ shrink: (this.state.klifo.topic != null) }}
            style={{zIndex: 1}}
            type="string"
          />
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}} >
            <InputLabel filled animated shrink margin="dense" variant="outlined" error={!this.state.klifo.status}>Status</InputLabel>
            <Select
              id="status"
              value={ selectedStatus }
              onChange={this.handleStatusChange}
              options={statusList}
              style={{width: "100%"}}
            />
          </FormControl>
          <TextField
            margin="dense"
            id="comment"
            label="Kommentar"
            onChange={this.handleChange}
            value={this.state.klifo.comment}
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: this.state.locked,
            }}
            InputLabelProps={{ shrink: (this.state.klifo.comment != null) }}
            multiline
            style={{zIndex: 1}}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={(object) => this.props.handleSave({
              ...this.state.klifo
              }
            )}
            color="primary"
            disabled={!saveable}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewKlifoDialog;
