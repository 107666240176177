import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
// import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

const styles = theme => ({
  status: {
    margin: 0,
  },
  statusCheckbox: {
    padding: 0,
  }
});

const marks = [
  {
    value: 0,
    label: 'Offen',
  },
  {
    value: 1,
    label: 'OK',
  },
  {
    value: -1,
    label: 'N.A.',
  },
];

class StatusCheckboxes extends React.Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
  }

  render(props) {
    const { classes } = this.props;
    return (
      <div >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.sample_contract_out}
                onChange={this.props.handleChange('sample_contract_out', this.props.selectedSite.sample_contract_out)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Beispielvertrag versandt"
            className={classes.status}
            />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.sample_contract_back}
                onChange={this.props.handleChange('sample_contract_back', this.props.selectedSite.sample_contract_back)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Vertrag überarbeitet zurück"
            className={classes.status}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.contract_approved}
                onChange={this.props.handleChange('contract_approved', this.props.selectedSite.contract_approved)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Vertrag freigegeben"
            className={classes.status}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.contract_signed}
                onChange={this.props.handleChange('contract_signed', this.props.selectedSite.contract_signed)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Vertrag von allen unterschrieben"
            className={classes.status}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.sample_ethics}
                onChange={this.props.handleChange('sample_ethics', this.props.selectedSite.sample_ethics)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Muster Ethik"
            className={classes.status}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.ethics_vote_in}
                onChange={this.props.handleChange('ethics_vote_in', this.props.selectedSite.ethics_vote_in)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Ethik Votum hier"
            className={classes.status}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.ethics_not_needed}
                onChange={this.props.handleChange('ethics_not_needed', this.props.selectedSite.ethics_not_needed)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Ethik nicht nötig"
            className={classes.status}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.received_delegation_log}
                onChange={this.props.handleChange('received_delegation_log', this.props.selectedSite.received_delegation_log)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Delegation Log erhalten"
            className={classes.status}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.sample_invoice_out}
                onChange={this.props.handleChange('sample_invoice_out', this.props.selectedSite.sample_invoice_out)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Rechnungsvorlage hingeschickt"
            className={classes.status}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.selectedSite.raising_data}
                onChange={this.props.handleChange('raising_data', this.props.selectedSite.raising_data)}
                color="primary"
                // disabled={this.props.disabled}
                className={classes.statusCheckbox}
              />
            }
            label="Erheben Daten"
            className={classes.status}
          />
        </FormGroup>
      </div>
    )
  }
}

StatusCheckboxes.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedSite: PropTypes.isRequired,
};

StatusCheckboxes = withStyles(styles)(StatusCheckboxes);

export default StatusCheckboxes ;
