import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import { IconButton, Menu, MenuItem } from "@mui/material";

// handleChange2 = (event, checked) => {
//   // console.log(event.target.name);
//   // console.log(checked);
//   this.setState({[event.target.name]: checked});
// }

export default function UserFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleChange2, handleChangeMultiple, handleChangeCheckbox, readOnly } = props;
  const roleOptions = props.roles.map(role => {
    return {value: role.id, label: role.name};
  })
  // const roleIds = (obj.roles) ? obj.roles.map(role => role.id) : [];
  const selectedRoles = roleOptions.filter(ro => obj.roleIds.includes(ro.value));
  const mainRole = roleOptions.filter(ro => obj.mainRoleId == ro.value)
  console.log(selectedRoles);
  return (

        <div style={{backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px'}}>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            value={obj.name}
            fullWidth
            autoFocus
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!obj.name}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (obj.name != null) }}
            type="text"
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            value={obj.email}
            fullWidth
            autoFocus
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!obj.email}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (obj.email != null) }}
            type="text"
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj.activated}
                onChange={handleChangeCheckbox}
              />
            }
            label="Aktiviert"
            name="activated"
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj.admin}
                onChange={handleChangeCheckbox}
              />
            }
            label="Admin"
            name="admin"
          />
          <div style={{width: 300, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              Hauptgruppe
            </Typography>
            <Select
              value={ mainRole }
              // defaultValue={"Bitte wählen"}
              name="mainRoleId"
              onChange={handleChange2}
              options={roleOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
            />
          </div>
          <div style={{width: 300, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              Gruppen
            </Typography>
            <Select
              value={ selectedRoles }
              // defaultValue={"Bitte wählen"}
              name="roleIds"
              onChange={handleChangeMultiple}
              options={roleOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              isMulti
            />
          </div>
        </div>
  );
}
