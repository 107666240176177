import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from 'react-select';

import { ThemeProvider, createTheme } from '@mui/material/styles';

export default function TrialBoardData(props) {
  // const classes = useStyles();
  const { classes, people, trialBoard, handleParticipantsChange, locked } = props;
  const allPeopleOptions = people.map(person => {
    return (
      {value: person.id, label: person.first_name + " " + person.last_name}
    )
  });
  const participantIds = trialBoard.participants.map(participant => participant.id);
  const selectedPeople = allPeopleOptions.filter(po => participantIds.includes(po.value));
  return (

        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Teilnehmer</InputLabel>
            <Select
              // defaultValue={}
              isMulti
              name="participants"
              options={allPeopleOptions}
              onChange={handleParticipantsChange}
              className="basic-multi-select"
              classNamePrefix="select"
              isDisabled={locked}
              value={props.selectedParticipants}
              isDisabled={locked}
            />
          </FormControl>
        </div>
  );
}
