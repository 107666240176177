import React from 'react';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class DeletePersonConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.form = React.createRef();
  }

  render(props) {
    const peopleListItems = this.props.selectedPeople.map(person => {
        return <ListItem>
                  <ListItemText
                    primary={person.first_name + " " + person.last_name }
                  />
                </ListItem>
    })
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={true}
      >
        <DialogTitle id="form-dialog-title">Person(en) entfernen</DialogTitle>
        <DialogContent>
          <DialogContentText style={{color: 'red'}}>
            Achtung: die folgenden Personen werden komplett aus allen Abteilungen und aus allen Studien gelöscht:
          </DialogContentText>
          <DialogContentText>
            Personen:
          </DialogContentText>
          <div >
            <List >
              {peopleListItems}
            </List>
          </div>
          <DialogContentText style={{color: 'red'}}>
            Hinweis: Die Verknüpfung mit bestimmten Abteilungen muss im Detailfenster der jeweiligen Person entfernt werden. Die Zugehörigkeit zu Studien muss in der studienspezifischen Zentrumsliste gelöscht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.props.handleSave()} color="primary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DeletePersonConfirmationDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(DeletePersonConfirmationDialog));
