import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import { IconButton, Menu, MenuItem } from "@mui/material";

// handleChange2 = (event, checked) => {
//   // console.log(event.target.name);
//   // console.log(checked);
//   this.setState({[event.target.name]: checked});
// }

export default function TaskFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleChange2, handleChangeCheckbox, readOnly, taskStatusList, statusEditable, selectableTaskStatusList, handleAddClick, handleEditClick, handleDeleteClick } = props;
  const [addReminderOpen, setAddReminderOpen] = useState(false);
  const selectedTaskStatus = taskStatusList.find(stat => obj.status == stat.value);
  const recurringCycleOptions = [{value: null, label: " "}, {value: "weekly", label: "Wöchentlich"}, {value: "monthly", label: "Monatlich"}, {value: "yearly", label: "Jährlich"}];
  const selectedRecurringCycle = recurringCycleOptions.find(opt => opt.value == obj.recurring_cycle);
  const recurringTypeOptions = [{value: "static", label: "Statisch (Fälligkeitsdatum + Wiederholungsturnus)"}, {value: "dynamic", label: "Dynamisch (Datum erledigt + Wiederholungsturnus)"}];
  const selectedRecurringType = recurringTypeOptions.find(opt => opt.value == obj.recurring_type);
  // console.log("taskFields");
  return (

        <div style={{backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px'}}>
          <TextField
            id="date_due"
            label="Fälligkeitsdatum"
            margin="dense"
            variant="outlined"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            value={obj.date_due}
            error={!obj.date_due}
            disabled={readOnly || props.dueDateNotEditable}
          />
          <TextField
            key="title"
            margin="dense"
            id="title"
            label="Titel"
            value={obj.title || ""}
            fullWidth
            autoFocus
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!obj.title}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (obj.title != null) }}
            type="string"
          />
          { (readOnly || !statusEditable) ?
            <TextField
              margin="dense"
              id="status"
              label="Status"
              value={selectedTaskStatus.label}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              error={!obj.status}
              InputProps={{
                readOnly: (readOnly || obj.status == "planned"),
              }}
              InputLabelProps={{ shrink: (obj.status != null) }}
              type="string"
            />
          :
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Status</InputLabel>
              <Select
                id="status"
                name="status"
                value={selectedTaskStatus}
                options={taskStatusList}
                style={{width: "100%"}}
                onChange={handleChange2}
                error={!obj.status}
                // disabled={task.status == "planned"}
              />
            </FormControl>
          }
          <TextField
            margin="dense"
            id="description"
            label="Beschreibung"
            value={obj.description}
            fullWidth
            multiline
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (obj.description != null) }}
            type="text"
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj.important}
                onChange={handleChangeCheckbox}
              />
            }
            label="Hohe Priorität"
            name="important"
          />
          { (readOnly) ?
            <TextField
              margin="dense"
              id="recurring_cycle"
              label="Wiederholungszyklus"
              value={selectedRecurringCycle.label}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.status}
              InputProps={{
                readOnly: (readOnly),
              }}
              InputLabelProps={{ shrink: (obj.recurring_cycle != null) }}
              type="string"
            />
          :
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Wiederholungszyklus</InputLabel>
              <Select
                id="recurring_cycle"
                name="recurring_cycle"
                value={selectedRecurringCycle}
                options={recurringCycleOptions}
                style={{width: "100%"}}
                onChange={handleChange2}
                // error={!obj.status}
                // disabled={task.status == "planned"}
              />
            </FormControl>
          }
          { ( obj.recurring_cycle != null) &&
          <>
          <TextField
            margin="dense"
            id="recurring_number"
            label="Wiederholung nach wie viel Zeiteinheiten"
            type="number"
            onChange={handleChange}
            fullWidth
            variant="outlined"
            value={obj.recurring_number}
            error={!obj.recurring_number || obj.recurring_number <= 0}
            InputProps={{
              readOnly: readOnly,
            }}
          />
          { (readOnly) ?
            <TextField
              margin="dense"
              id="recurring_type"
              label="Wiederholung Typ"
              value={(selectedRecurringType) ? selectedRecurringType.label : null}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.status}
              InputProps={{
                readOnly: (readOnly),
              }}
              InputLabelProps={{ shrink: (obj.recurring_type != null) }}
              type="string"
            />
          :
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 996}} error={!obj.recurring_type}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Wiederholung Typ</InputLabel>
              <Select
                id="recurring_type"
                name="recurring_type"
                value={selectedRecurringType}
                options={recurringTypeOptions}
                style={{width: "100%", zIndex: 996}}
                onChange={handleChange2}
                error={!obj.recurring_type}
                // disabled={task.status == "planned"}
              />
            </FormControl>
          }
          </>
          }
        </div>
  );
}
