import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import moment from 'moment'
import "moment/locale/de";

moment.locale("de")

import ProcessFields from './ProcessFields.jsx';

class EditProcessDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { obj: (props.selectedProcess) ? {... props.selectedProcess} : {status_date: moment().toISOString().substr(0,10)} };
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastName !== prevProps.lastName) {
      this.setState({ inputValue: this.props.lastName, last_name: this.props.lastName });
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    currentComponent.setState({ obj: newObj });
  };

  handleChange2 = (event, meta) => {
    const value = event.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    if (name == "process_category" && this.props.processCategoryTypeList[value].length == 1) {
      newObj["process_type"] = this.props.processCategoryTypeList[value][0].value;
    }
    this.setState({ obj: newObj });
  }

  handleChangeCheckbox = (event, meta) => {
    const value = meta;
    const name = event.target.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    if (name == "process_category" && this.props.processCategoryTypeList[value].length == 1) {
      newObj["process_type"] = this.props.processCategoryTypeList[value][0].value;
    }
    this.setState({ obj: newObj });
  }

  // handleChangeDate = (date, name) => {
  //   let newObj = {...this.state.obj};
  //   newObj[name] = moment(date).startOf("day").add(2, 'hour');
  //   this.setState({obj: newObj});
  // };

  handleSave = (state) => {
    // let status_date = moment(this.state.obj.status_date).toString();
    // let deadline = moment(this.state.obj.deadline).toString();
    // console.log(start_time, end_time);
    this.props.handleSave(this.state.obj);
    // this.props.handleSave(this.state);
    // this.setState({last_name: null, first_name: null, title: null, phone_number: null, mobile_number: null, fax_number: null, email: null, function: null});
  }

  render(props) {
    const saveable = this.state.obj.name && this.state.obj.process_type && this.state.obj.status_date && this.state.obj.process_category;
    const selectedType = this.props.processCategoryTypeList[this.state.obj.process_category].find(type => type.label == this.state.obj.process_type);
    const selectedCategory = this.props.processCategoryList.find(category => category.value == this.state.obj.process_category);
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Prozess editieren</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: green[500]}}>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <ProcessFields
                  process={this.state.obj}
                  handleChange={this.handleChange}
                  handleChange2={this.handleChange2}
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  // handleChangeDate={this.handleChangeDate}
                  readOnly={false}
                  processCategoryTypeList={this.props.processCategoryTypeList}
                  processCategoryList={this.props.processCategoryList}
                  statusList={this.props.statusList}
                  statusDateNotEditable
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.handleSave(this.state)}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditProcessDialog;
