import React from 'react';
import { Page, Text, View, Document, Image, Font, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/nct-heidelberg.jpg';
import { width } from '@mui/system';

const styles = StyleSheet.create({
  header: { fontSize: 16, fontFamily: 'Helvetica-Bold', textAlign: 'center' },
  subtitle: { fontSize: 14, fontFamily: 'Helvetica-Bold' },
  body: { fontSize: 11, fontFamily: 'Helvetica-Bold' },
  label: { fontSize: 8, fontStyle: 'italic' },
  table: {
    width: '100%',
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 12,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeader: {
    borderBottomWidth: 2,
    marginTop: 2,
  },
  tableSubtitle: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomStyle: 'dashed',
    margin: 2
  },
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    margin: 2
  },
  leftcell: {
    flex: 4,
  },
  rightcell: {
    flex: 8,
    objectFit: 'fill',
    paddingRight: 5,
    borderBottom: 1,
  }
})

const folders = (props) => {
  let s = '';
  props.forEach(e => {
    s += '; ' + e.name + ': ' + (e.value == ""? "- ": e.value);
  });
  return s
}
export const HandoverStudyPDF = props => {
  return (
    <Document title={"Übergabe von Studienunterlagen"}>
      <Page size="A4" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingRight: '10px', paddingTop: '10px' }}>
          <Image src={logo} alt="NCT Studienzentrale" style={{ width: 197, height: 50 }} />
        </View>
        <View style={styles.table}>
          <Text style={[styles.header, styles.tableHeader]}>Übergabe von Studienunterlagen</Text>
          <Text style={[styles.subtitle, styles.tableSubtitle, { borderTopWidth: 0 }]}>Studie:</Text>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Kurztitel der Studie:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.trial.short}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>EudraCT:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.trial.eudract_num}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Sponsor:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.trial.sponsor}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Übernahme:</Text>
            {props.archive_for_sponsor ?
              <Text style={[styles.body, styles.cell, styles.rightcell]}>Archivierung wird für Sponsor übernommen</Text>
              :
              <Text style={[styles.body, styles.cell, styles.rightcell]}>Archivierung wird nicht für Sponsor übernommen</Text>
            }
          </View>
          <Text style={[styles.subtitle, styles.tableSubtitle]}>Unterlagen:</Text>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Archivar:in:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.archivist}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Übergebene Unterlagen:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>iTMF: {props.itmf_folder_qty == null? "- ": props.itmf_folder_qty}; CRF: {props.crf_folder_qty == null? "- ": props.crf_folder_qty};  sTMF: {props.stmf_folder_qty == null ? "- ": props.stmf_folder_qty}{folders(props.custom_folders)}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Anzahl übergebener Kartons:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.packaging_qty ? props.packaging_qty : 'Nicht zutreffend'}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Archivierungsfirst (Jahre):</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.archiving_duration ? props.archiving_duration : 'Unbekannt'}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Vernichtungsdatum:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.destruction_date ? props.destruction_date : 'Unbekannt'}</Text>
          </View>
          <Text style={[styles.subtitle, styles.tableSubtitle]}>Empfänger:in der Studienunterlagen:</Text>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Name, Vorname:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.recipient_name}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Telefonnummer:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.recipient_phone}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>E-Mail Adresse:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.recipient_mail}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Abteilung:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.recipient_department}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell, { marginTop: 7 }]}>Abholung erfolgt druch:</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Name, Vorname</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.pickup_name}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Abholdatum:</Text>
            <Text style={[styles.body, styles.cell, styles.rightcell]}>{props.pickup_date}</Text>
          </View>
          <Text style={[styles.subtitle, styles.tableSubtitle]}>Empfangsbestätigung:</Text>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Unterlagen (s.o.) erhalten am:</Text>
            <Text style={[styles.label, styles.cell, styles.rightcell]} />
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]} />
            <Text style={[styles.label, styles.cell, styles.leftcell, { flex: 8, objectFit: 'fill', paddingRight: 5, }]}>(TT/MM/JJJJ)</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]}>Empfänger:in</Text>
            <Text style={[styles.label, styles.cell, styles.rightcell]} />
            <Text style={[styles.label, styles.cell, styles.rightcell]} />
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell]} />
            <Text style={[styles.label, styles.cell, styles.leftcell, { flex: 8, objectFit: 'fill', paddingRight: 5, }]}>Name in DRUCKBUCHSTABEN</Text>
            <Text style={[styles.label, styles.cell, styles.leftcell, { flex: 8, objectFit: 'fill', paddingRight: 5, }]}>Unterschrift</Text>
          </View>
          <View style={[styles.tableRow, { marginTop: 20 }]}>
            <Text style={[styles.body, styles.cell, styles.leftcell, { textAlign: 'center' }]}>Bitte unterschriebenes Übergabeformular umgehend zurückschicken an:</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.body, styles.cell, styles.leftcell, { textAlign: 'center', marginBottom: 20 }]}>NCT Studienzentrale</Text>
          </View>
        </View>

      </Page>
    </Document>
  );
}