import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import MUIDataTable from "mui-datatables";

import { withSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import EnhancedTable from './EnhancedTable.jsx';
import Select from 'react-select';

import EditTaskDialog from './EditTaskDialog.jsx';
import InfoTaskDialog from './InfoTaskDialog.jsx';
import NewTaskDialog from './NewTaskDialog.jsx';
import DeleteTaskConfirmationDialog from './DeleteTaskConfirmationDialog.jsx';

import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import Moment from 'moment';

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

 const taskColumns = [
  {
    id: "date_due",
    label: "Fälligkeitsdatum",
    numeric: false,
    padding: 'none',
    date: true,
    type: "date",
  },
  { id: "attributes", 
    label: "Attr.",
    padding: 'none',
    type: "attributes",
  },
  {
    id: "title",
    label: "Titel",
    numeric: false,
    padding: 'none',
  },
  {
    id: "statusText",
    label: "Status",
    numeric: false,
    padding: 'none',
    type: "objectStatus",
    filterable: true,
  },
  {
    id: "description",
    label: "Beschreibung",
    numeric: false,
    padding: 'none',
    type: "textNoBreak",
  },
  {
    id: "nextReminderDate",
    label: "Erinnerungsdatum",
    numeric: false,
    padding: 'none',
    date: true,
    type: "date",
  },
  {
    id: "link",
    label: "Link",
    numeric: false,
    padding: 'none',
    type: "linkButton",
  },
];

class TrialTasks extends React.Component {
  constructor(props) {
    super(props);
    let pta = {};
    let pta_filtered = {};
    if (this.props.people_trials_associations) {
      pta = this.props.people_trials_associations.map(selected_pta => {
        // console.log(selected_pta);
        let ret = Object.assign({}, selected_pta.person, selected_pta);
        delete ret["person"];
        // ret["pta_comment"] = selected_pta.comment;
        // ret["comment"] = selected_pta.person.comment;
        return (
          ret
        )
      })
      pta_filtered = pta.filter(assoc => assoc.overview_show);
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const task_id = urlParams.get('task_id');
    this.state = {trial: this.props.trial, selectedTasks: (task_id) ? this.props.trial.tasks.find(task => task.id == task_id) : [], selectedTaskIds: (task_id) ? [parseInt(task_id)] : [], openDialogs: {editTask: false, newTask: false, infoTask: (task_id != null), deleteTaskConfirmation: false }, user: props.user, taskFilters: {is_pm: {label: "nur meine", value: true}, status: [{label: "Offen", value: "open"}]}};
    // this.setState({trial: this.props.trial});
    this.form = React.createRef();
  }

  // handleTrialsReceived = message => {
  //   console.log("received Trial");
  //   console.log(message);
  //   if (message.trial && message.trial.id == this.state.trial.id) {
  //     const trial = message.trial;
  //     this.setState({ trial: trial });
  //     this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
  //   }
  //   if (message.deleted_trial_ids && message.deleted_trial_ids.includes(this.state.trial.id)) {
  //     this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' gelöscht: ' + this.state.trial.short, {variant: 'success'});
  //     window.location.assign('/trials');
  //   }
  // }

  handleConnected = event => {
    console.log("connected");
  }

  handleFilters = () => {
    let ret = [].concat(this.state.people_trials_associations);
    if (this.state.peopleFilters["overview_show"] && ret.length > 0) {
      ret = ret.filter(person => ((person.overview_show == null && (this.state.peopleFilters["overview_show"]["value"] == true || this.state.peopleFilters["overview_show"]["value"] == null)) || person.overview_show == this.state.peopleFilters["overview_show"]["value"]));
    }
    if (this.state.peopleFilters["trial_role"] && ret.length > 0) {
      const trial_roles = this.state.peopleFilters.trial_role.map(role => role.value);
      ret = ret.filter(person => trial_roles.includes(person.trial_role));
    }
    if (this.state.peopleFilters["left"] != null && ret.length > 0) {
      ret = ret.filter(person => ((person.left == null && this.state.peopleFilters["left"].value == false) || (person.left == this.state.peopleFilters["left"].value) || this.state.peopleFilters["left"].value == null));
    }
    if (this.state.peopleFilters["primary_contact"] != null && ret.length > 0) {
      ret = ret.filter(person => (person.primary_contact == this.state.peopleFilters["primary_contact"].value));
    }
    return ret;
  }

  handleCopy = event => {
    console.log("copied");
    // const [isCopied, setCopied] = useClipboard("Text to copy");
  }

  renderCopyText = event => {
    let ret = "Titel\t" + this.state.trial.title + "\n";
    ret += "Akronym\t" + this.state.trial.short + "\n";
    if (this.state.trial.eudract_num) {
      ret += "EudraCT\t" + this.state.trial.eudract_num + "\n";
    }
    if (this.state.trial.bob_code) {
      ret += "BOB Vorlagenummer\t" + this.state.trial.bob_code + "\n";
    }
    if (this.state.trial.ek_code) {
      ret += "EK Aktenzeichen\t" + this.state.trial.ek_code + "\n";
    }
    if (this.state.trial.inspection_plan_code) {
      ret += "Prüfplan Code\t" + this.state.trial.inspection_plan_code + "\n";
    }
    if (this.state.trial.ctgov_code) {
      ret += "CT.gov Nr.\t" + this.state.trial.ctgov_code + "\n";
    }
    if (this.state.trial.comment) {
      ret += "Kommentar\t" + this.state.trial.comment;
    }
    return ret;
  }

  handleNewTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewTaskClose = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewTaskCloseOpenInfo = (obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = false;
    newOpenDialogs["infoTask"] = true;
    this.setState({selectedTasks: [obj], selectedTaskIds: [obj.id]})
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoTaskClose = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditTaskClose = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleTaskDoubleClick = (event, obj) => {
    this.setState({selectedTasks: [obj], selectedTaskIds: [obj.id]});
    this.handleInfoTaskOpen();
  }

  // handleTaskLinkClick = (event, obj) => {
  //   console.log(event, obj);
  //   if (obj.taskable_type == "ProcessStep") {
  //     let taskable = obj.taskable;
  //     while (!taskable.is_main_step) {
  //       taskable = taskable.taskable;
  //     }
  //     const process_instance = taskable.process_instance;
  //     const processable = process_instance.processable;
  //     const process_dummiable = processable.process_dummiable;
  //     if (processable.process_dummiable_type == "Trial") {
  //       window.location.assign('/trials/' + process_dummiable.id + '/ethics_processes');
  //     }
  //     // let process_dummy = process_instance.process_dummy;
  //     // console.log(taskable, process_instance, processable, process_dummiable);
  //   } else if (obj.taskable_type == "User") {
  //     const taskable = obj.taskable;
  //     console.log(taskable);
  //   } else {
  //     console.log(taskable);
  //   }
  // }

  handleDuplicateTaskClick = (event) => {
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/duplicate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task: currentComponent.state.selectedTasks[0],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich dupliziert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleMarkDoneClick = (event) => {
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/' + currentComponent.state.selectedTasks[0].id + '/mark_done', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task: currentComponent.state.selectedTasks[0],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich als erledigt markiert.', {variant: 'success'});
        currentComponent.setState({selectedTasks: [], selectedTaskIds: []});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteTask = (event, obj) => {
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task_ids: currentComponent.state.selectedTasks.map(st => st.id),
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
        currentComponent.handleDeleteTaskConfirmationDialogClose();
        // currentComponent.props.handleClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleTaskChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedTasks: allObjectsSelected,
      selectedTaskIds: allRowsSelected,
    });
  };

  handleTasksFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({taskFilters: {is_pm: {label: "nur meine", value: true}, status: [{label: "Offen", value: "open"}]}});
    }
  }

  handleTasksFilterChange = (filters, meta) => {
    if ((meta.action == "select-option") || (meta.action == "remove-value")) {
      let newTaskFilters = {...this.state.taskFilters};
      newTaskFilters[meta["name"]] = filters;
      this.setState({taskFilters: newTaskFilters});
    } else if (meta.action == "clear") {

      let newTaskFilters = {...this.state.taskFilters};
      delete newTaskFilters[meta["name"]];
      this.setState({taskFilters: newTaskFilters});
    }
  }

  handleDeleteTaskConfirmationDialogOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["deleteTaskConfirmation"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleDeleteTaskConfirmationDialogClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["deleteTaskConfirmation"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleTasksReceived = message => {
    console.log("Task received", message);
    let newTrial = {...this.state.trial};
    let newProcessTasks = [].concat(this.state.trial.process_tasks);
    let newTrialTasks = [].concat(this.state.trial.tasks);
    let newContractTasks = [].concat(this.state.trial.contract_tasks);
    if (message.new_task) {
      if (message.new_task.taskable_type == "ProcessStep"){
        newProcessTasks.push(message.new_task);
        newTrial.process_tasks = newProcessTasks;
      } else if (message.new_task.taskable_type == "Trial") {
        newTrialTasks.push(message.new_task);
        newTrial.tasks = newTrialTasks;
      } else if (message.new_task.taskable_type == "Contract") {
        newContractTasks.push(message.new_task);
        newTrial.contract_tasks = newContractTasks;
      }
      // this.setState({tasks: newTasks});
      this.props.enqueueSnackbar('Task wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_task.title, {variant: 'success'});
    } else if (message.task) {
      if (message.task.taskable_type == "ProcessStep"){
        let changedProcessTaskIndex = newProcessTasks.findIndex(task => task.id == message.task.id);
        if ((changedProcessTaskIndex > -1)) {
          newProcessTasks[changedProcessTaskIndex] = message.task;
          newTrial.process_tasks = newProcessTasks;
          this.setState({trial: newTrial});
          this.props.enqueueSnackbar('Prozess Task aktualisiert: ' + message.task.title, {variant: 'success'});   
        }
      } else if (message.task.taskable_type == "Trial") {
        let changedTrialTaskIndex = newTrialTasks.findIndex(task => task.id == message.task.id);
        console.log(changedTrialTaskIndex);
        if ((changedTrialTaskIndex > -1)) {
          newTrialTasks[changedTrialTaskIndex] = message.task;
          newTrial.tasks = newTrialTasks;
          this.setState({trial: newTrial});
          this.props.enqueueSnackbar('Studientask aktualisiert: ' + message.task.title, {variant: 'success'});   
        }
      } else if (message.task.taskable_type == "Contract") {
        let changedContractTaskIndex = newContractTasks.findIndex(task => task.id == message.task.id);
        if ((changedContractTaskIndex > -1)) {
          newContractTasks[changedContractTaskIndex] = message.task;
          newTrial.contract_tasks = newContractTasks;
          this.setState({trial: newTrial});
          this.props.enqueueSnackbar('Vertragstask aktualisiert: ' + message.task.title, {variant: 'success'});   
        }
      }
      if (this.state.selectedTasks && this.state.selectedTasks.length > 0 && this.state.selectedTasks.map(st => st.id).includes(message.task.id)){
        const taskIndex = this.state.selectedTasks.findIndex(st => st.id == message.task.id);
        let newSelectedTasks = [].concat(this.state.selectedTasks);
        newSelectedTasks[taskIndex] = message.task;
        this.setState({selectedTasks: newSelectedTasks});
      }
    } else if (message.deleted_task_ids) {
      const deletedTaskNames = [].concat(newProcessTasks.filter(task => message.deleted_task_ids.includes(task.id)).map(task => task.title), newTrialTasks.filter(task => message.deleted_task_ids.includes(task.id)).map(task => task.title));
      newProcessTasks = newProcessTasks.filter(task => !message.deleted_task_ids.includes(task.id));
      newTrial.process_tasks = newProcessTasks;
      newTrialTasks = newTrialTasks.filter(task => !message.deleted_task_ids.includes(task.id));
      newTrial.tasks = newTrialTasks;
      newContractTasks = newContractTasks.filter(task => !message.deleted_task_ids.includes(task.id));
      newTrial.contract_tasks = newContractTasks;
      if (this.state.selectedTasks) {
        let newSelectedTasks = [].concat(this.state.selectedTasks);
        newSelectedTasks = newSelectedTasks.filter(st => !(message.deleted_task_ids.includes(st.id))) || [];
        this.setState({selectedTasks: newSelectedTasks});
      }
      // this.setState({tasks: newTasks});
      this.props.enqueueSnackbar('Tasks von Benutzer ' + message.username + ' gelöscht: ' + deletedTaskNames, {variant: 'success'});
    }
    this.setState({trial: newTrial});
  }

  render() {
    const { classes, trial } = this.props;
    const {openDialogs, selectedTaskIds} = this.state;
    const taskStatusList = [{value: "done", label: "Erledigt", color: "#ADADAD"}, {value: "open", label: "Offen", color: "#FFFFFF"}];
    // console.log(this.state.trial.tasks);
    let concatinatedTasks = [].concat(this.state.trial.process_tasks, this.state.trial.tasks, this.state.trial.contract_tasks);
    concatinatedTasks = concatinatedTasks.filter(function( element ) {
      return element !== undefined;
    });
    const supplementedTasks = concatinatedTasks.map(task => {
      // console.log(task);
      let ret = {... task};
      ret["statusText"] = taskStatusList.find(ts => task.status == ts.value).label;
      ret["attributes"] = [];
      if (task.important) {
        ret["attributes"].push("important");
      }
      if (task.recurring_cycle != null) {
        ret["attributes"].push("recurring");
      }
      if (task.reminders.length > 0) {
        ret["attributes"].push("reminder");
      }
      const notifiedReminders = task.reminders.filter(reminder => reminder.notified == true);
      if (task.status == "done") {
        ret["statusColor"] = "#ADADAD";
      } else if ((task.status == "open") && (Moment(task.date_due).isBefore(Moment()))){
        ret["statusColor"] = "#E29393";
      } else if ((Moment().add(1, "year").isBefore(Moment(task.date_due))) && (task.status != "done")) {
        ret["statusColor"] = "#76d658";
      } else if ((notifiedReminders.length > 0) && task.status != "done") {
        ret["statusColor"] = "#F5B556";
      } else {
        ret["statusColor"] = taskStatusList.find(ts => task.status == ts.value).color;
      }
      // console.log(ret);
      if (task.taskable_type == "ProcessStep"){
        const trialShort = trial.short;
        ret["trialShort"] = trialShort;
        const process_instance = task.taskable.process_instance;
        const processable = process_instance.processable;
        // const process_dummiable = processable.process_dummiable;
        ret["link"] = '/trials/' + trial.id + '/ethics_processes';
        // ret["is_pm"] = (this.state.pmTrialIds.includes(task.taskable.process_instance.processable.trial.id)) ? true : false;
      } else if (task.taskable_type == "Trial") {
        const trialShort = trial.short;
        ret["trialShort"] = trialShort;
        // ret["link"] = '/trials/' + trial.id + '/trial_tasks';
      } else if (task.taskable_type == "Contract") {
        // const trialShort = task.taskable.process_instance.processable.process_dummiable.short;
        ret["link"] = '/trials/' + trial.id + '/contracts/?task_id=' + task.id + '&contract_id=' + task.taskable.id;
        ret["trialShort"] = "Contract";
        // ret["is_pm"] = null;
      }
      let latestReminderDate = Moment('2100-01-01');
      task.reminders.forEach(reminder => {
        if (Moment(latestReminderDate).isAfter(Moment(reminder.remind_when))) {
          latestReminderDate = reminder.remind_when;
        }
      })
      if (!Moment(latestReminderDate).isSame('2100-01-01')) {
        ret["nextReminderDate"] = Moment(latestReminderDate);
      } else {
        ret["nextReminderDate"] = null;
      }
      return ret;
    })
    console.log(supplementedTasks);
    // if (selectedTask) {
    //   console.log(selectedTask.taskable_type, selectedTask.status, selectedTask.taskable.status)
    // }
    const selectedTasks = supplementedTasks.filter(task => selectedTaskIds.includes(task.id));
    const filteredSupplementedTasks = supplementedTasks.filter(task => {
      var ret = true;
      if ((this.state.taskFilters.is_pm.value != null) && !this.state.taskFilters.is_pm.value == task.is_pm) {
        ret = false;
      }
      if ((this.state.taskFilters.trialShort != null) && !this.state.taskFilters.trialShort.map(ts => ts.value).includes(task.trialShort)) {
        ret = false;
      }
      if ((this.state.taskFilters.status != null) && !this.state.taskFilters.status.map(stat => stat.value).includes(task.status)) {
        ret = false;
      }
      if (ret) {
        return true;
      } else {
        return false;
      }
    })

    // const notes = [].concat(this.state.trial.polymorphic_notes)

    return (
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "TrialsChannel"}}
            onReceived={this.handleTrialsReceived}
            onConnected={this.handleConnected}
          />
          <ActionCableConsumer
            channel={{channel: "TasksChannel"}}
            onReceived={this.handleTasksReceived}
            onConnected={this.handleConnected}
          />
          <Grid item xs={12}>
            <Grid container justify="center">
              { (openDialogs.newTask) &&
              <NewTaskDialog
                open={openDialogs.newTask}
                enqueueSnackbar={this.props.enqueueSnackbar}
                // handleAddOpen={this.handleEditProcessStepOpen}
                // handleSave={this.handleEditProcessStepSave}
                handleClose={this.handleNewTaskClose}
                handleCloseOpenInfo={this.handleNewTaskCloseOpenInfo}
                // selectedProcessStep={selectedProcessStep}
                // previousProcessStep={previousProcessStep}
                // stepStatusList={stepStatusList}
                taskStatusList={taskStatusList}
                taskableType="Trial"
                taskableId={this.state.trial.id}
                // selectableTaskStatusList={selectableTaskStatusList}
                // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              />}
              { (openDialogs.infoTask && selectedTasks.length == 1) &&
              <InfoTaskDialog
                obj={selectedTasks[0]}
                open={openDialogs.infoTask}
                enqueueSnackbar={this.props.enqueueSnackbar}
                dueDateNotEditable={(selectedTasks[0].taskable_type == "ProcessStep")}
                // handleAddOpen={this.handleEditProcessStepOpen}
                // handleSave={this.handleEditProcessStepSave}
                handleClose={this.handleInfoTaskClose}
                // selectedProcessStep={selectedProcessStep}
                // previousProcessStep={previousProcessStep}
                // stepStatusList={stepStatusList}
                taskStatusList={taskStatusList}
                // selectableTaskStatusList={selectableTaskStatusList}
                // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              />}
              { (openDialogs.editTask && selectedTasks.length == 1) &&
              <EditTaskDialog
                obj={selectedTask[0]}
                open={openDialogs.editTask}
                enqueueSnackbar={this.props.enqueueSnackbar}
                dueDateNotEditable={!(selectedTasks[0].taskable_type == "ProcessStep")}
                // handleAddOpen={this.handleEditProcessStepOpen}
                // handleSave={this.handleEditProcessStepSave}
                handleClose={this.handleEditTaskClose}
                // selectedProcessStep={selectedProcessStep}
                // previousProcessStep={previousProcessStep}
                // stepStatusList={stepStatusList}
                taskStatusList={taskStatusList}
                // selectableTaskStatusList={selectableTaskStatusList}
                // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              />}
              
              { (openDialogs.deleteTaskConfirmation && selectedTasks.length > 0) &&
              <DeleteTaskConfirmationDialog
                open={openDialogs.deleteTaskConfirmation}
                handleSave={this.handleDeleteTask}
                handleClose={this.handleDeleteTaskConfirmationDialogClose}
                selectedTasks={selectedTasks}
              />}
              <EnhancedTable
                title={"Merkzettel"}
                objectName={"Task"}
                data={filteredSupplementedTasks}
                allData={supplementedTasks}
                columns={taskColumns}
                rowsPerPage={20}
                order="asc"
                orderBy="date_due"
                controller="tasks"
                autoFill
                expanded
                selectable
                // singleSelect={true}
                selectedIds={selectedTasks.map(st => st.id)}
                selectedObjects={selectedTasks}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleTaskChange(rowData, allRowsSelected, allObjectsSelected)}
                filters={this.state.taskFilters}
                handleFilterClick={this.handleTasksFilterClick}
                onFilterChange={this.handleTasksFilterChange}
                handleAddClick={this.handleNewTaskOpen}
                handleDuplicateClick={(selectedTasks.length == 1 && selectedTasks[0].taskable_type != "ProcessStep") ? this.handleDuplicateTaskClick : null}
                handleDoneClick={(selectedTasks.length == 1 && ((selectedTasks[0].taskable_type != "ProcessStep") && (selectedTasks[0].status != "done") || (selectedTasks[0].taskable_type == "ProcessStep" && (selectedTasks[0].taskable.status == "active" )))) ? this.handleMarkDoneClick : null}
                // (selectedTask.taskable.status == "active" || selectedTask.status == "open")
                handleDeleteClick={(selectedTasks.length > 0 && !selectedTasks.map(st => st.taskable_type).includes("ProcessStep")) ? this.handleDeleteTaskConfirmationDialogOpen : null}
                onDoubleClick={this.handleTaskDoubleClick}
                // handleLinkClick={this.handleTaskLinkClick}
                objectType="Task"
                multipleSelectable
              />
            </Grid>
          </Grid>
        </Grid>

    )
  }
}

TrialTasks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(TrialTasks));
