import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
// import MemberStateProcess from './MemberStateProcess.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function EuCtrProcessFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleInputValueChange, onCreateOption, handleSelectChange, readOnly, selectOptions, hiddenFields } = props;

  // console.log(obj, readOnly);
  // console.log(props.obj);
  // console.log(obj.ecrf_trial_function_associations.map(assoc => assoc.ecrf_trial_configuration_id));
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={6}>
        <TextField
          margin="dense"
          id="process_type"
          label="Prozesstyp"
          value={obj.process_type}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          onCreateOption={onCreateOption}
          required={props.requiredFields.includes("process_type")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("process_type"))),
          }}
          options={selectOptions["process_type"]}
          type="select"
          // size="small"
          data-cy="select_process_type"
        />
        <TextField
          margin="dense"
          id="part"
          label="Teil"
          value={obj.part}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          onCreateOption={onCreateOption}
          required={props.requiredFields.includes("part")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("part"))),
          }}
          options={selectOptions["part"]}
          type="select"
          // size="small"
          data-cy="select_part"
        />
        <TextField
          margin="dense"
          id="agency"
          label="Behörde"
          value={obj.agency}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          onCreateOption={onCreateOption}
          required={props.requiredFields.includes("agency")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("agency"))),
          }}
          type="string"
          // size="small"
          data-cy="select_agency"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="dense"
          id="member_country_ids"
          label="Länder"
          value={obj.member_country_ids}
          fullWidth
          options={props.selectOptions["memberCountries"]}
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          // onCreateOption={onCreateOption}
          required={props.requiredFields.includes("member_country_ids")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("member_country_ids"))),
          }}
          type="mselect"
          multiple={true}
          // size="small"
          data-cy="select_member_country_ids"
        />

      </Grid>
    </Grid>
  );
}
