import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
// import TaskFields from './TaskFields.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import { IconButton, Menu, MenuItem } from "@mui/material";

// handleChange2 = (event, checked) => {
//   // console.log(event.target.name);
//   // console.log(checked);
//   this.setState({[event.target.name]: checked});
// }

export default function ProcessStepFields(props) {
  // const classes = useStyles();
  const { processStep, handleChange, handleChange2, readOnly, stepStatusList, taskStatusList, hasDeadline, handleHasDeadlineChange, statusEditable, selectableStepStatusList, selectableTaskStatusList } = props;
  const selectedStepStatus = stepStatusList.find(stat => processStep.status == stat.value);
  // console.log(processStep.status, stepStatusList, selectedStepStatus);
  console.log(processStep.date_deadline);
  return (

        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <TextField
            margin="dense"
            id="name"
            label="Arbeitsschritt"
            value={processStep.name}
            fullWidth
            autoFocus
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!processStep.name}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (processStep.name != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="description"
            label="Beschreibung"
            value={processStep.description}
            fullWidth
            multiline
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (processStep.description != null) }}
            type="text"
          />
          <FormGroup>
            <FormControlLabel
              style={{zIndex: 997}}
              control={
                <Checkbox
                  checked={hasDeadline}
                  onChange={handleHasDeadlineChange}
                  disabled={!(handleHasDeadlineChange)}
                />
              }
              name="hasDeadline"
              label="Deadline existiert"
            />
          </FormGroup>
          { (hasDeadline) &&
          <>
          <TextField
            margin="dense"
            id="deadline_days"
            label="Frist (Tage)"
            value={processStep.deadline_days}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!processStep.deadline_days}
            InputProps={{
              readOnly: readOnly,
            }}
            type="number"
            InputLabelProps={{ shrink: (processStep.deadline_days != null) }}
            error={!processStep.deadline_days}
          />
          <TextField
            id="date_deadline"
            label="Frist Datum"
            margin="dense"
            variant="outlined"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            value={processStep.date_deadline}
            error={!processStep.date_deadline}
            disabled={readOnly}
          />
          </>}
          { (!hasDeadline) &&
          <>
          <TextField
            margin="dense"
            id="planned_duration"
            label="Geplante Dauer"
            value={processStep.planned_duration}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!processStep.deadline_days}
            InputProps={{
              readOnly: readOnly,
            }}
            type="number"
            InputLabelProps={{ shrink: (processStep.planned_duration != null) }}
            error={!processStep.planned_duration}
          />
          <TextField
            id="date_deadline"
            label="Geplantes Datum"
            margin="dense"
            variant="outlined"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            value={processStep.date_deadline}
            error={!processStep.date_deadline}
            // disabled={readOnly || deadlineNotEditable}
            disabled={readOnly}
          />
          </>}
          { (readOnly) ?
            <TextField
              margin="dense"
              id="status"
              label="Status"
              value={selectedStepStatus.label}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              error={!processStep.status}
              InputProps={{
                readOnly: (readOnly || processStep.status == "planned"),
              }}
              InputLabelProps={{ shrink: (processStep.status != null) }}
              type="string"
            />
          :
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Status</InputLabel>
              <Select
                id="status"
                name="status"
                value={selectedStepStatus}
                options={stepStatusList}
                style={{width: "100%"}}
                onChange={handleChange2}
                error={!processStep.status}
                isDisabled={(!statusEditable)}
              />
            </FormControl>
          }
          <TextField
            id="date_status"
            label="Datum Status"
            margin="dense"
            variant="outlined"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            value={processStep.date_status}
            error={!processStep.date_status}
            // disabled={readOnly || deadlineNotEditable}
            disabled={readOnly || (!props.dateStatusEditable)}
          />
          <TextField
            margin="dense"
            id="comment"
            label="Kommentar"
            value={processStep.comment}
            fullWidth
            multiline
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (processStep.comment != null) }}
            type="text"
          />
          
        </div>
  );
}
