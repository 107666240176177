import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';

import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
// import MUIDataTable from "mui-datatables";
import { withSnackbar } from 'notistack';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import Moment from 'moment';

import NewTrialBoardTrialDialog from './NewTrialBoardTrialDialog.jsx';
import EditTrialBoardTrialDialog from './EditTrialBoardTrialDialog.jsx';
import InfoTrialBoardTrialDialog from './InfoTrialBoardTrialDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';
import SearchBar from './SearchBar.jsx';

const styles = {
  overrides: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableCell: {
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiPaper: {
      elevation24: {
        height: 800,
        width: 800,
      },
    },
  },
  palette: {
    primary: {main: blue[500]},
    secondary: {main: blue[800]}
  },

};

class TrialBoardTrialsIndex extends React.Component {
  constructor(props) {
    super(props);
    const trialColumns = [
      {
       id: "id",
       label: "ID",
       numeric: false,
       padding: 'none',
      },
      {
        id: "short",
        label: "Kurztitel",
        numeric: false,
        padding: 'none',
      },
      {
        id: "legalDeptFirst",
        label: "Zeit 1. Antwort",
        numeric: false,
        padding: 'none',
      },
      {
        id: "legalDeptTimeCompleted",
        label: "Zeit gesamt",
        numeric: false,
        padding: 'none',
      },
      {
        id: "timeSignature",
        label: "Zeit Unterschrift",
        numeric: false,
        padding: 'none',
      },
      {
        id: "potentialPis",
        label: "PIs",
        numeric: false,
        padding: 'none',
        type: "stringList",
      },
    ];
    this.state = {trials: props.trials, selectedTrials: [], selectedTrialIds: [], selectedTrialIndices: [], newTrialOpen: false, editTrialOpen: false, infoTrialOpen: false, trialColumns: trialColumns, user: props.user};
    this.form = React.createRef();
  }

  updateTrials = () => {
    let currentComponent = this;
    fetch('/trial_boards/trial_board_submissions', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({ trials: data.trial_board_submissions });
    });
  }

  handleTrialChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedTrials: allObjectsSelected,
      selectedTrialIds: allRowsSelected,
      selectedTrialIndices: this.state.trials.findIndex(object => object.id === allRowsSelected[0]),
    });
  };
  // TODO
  handleNewTrialSave = object => {
    let currentComponent = this;
    this.setState({ newTrialOpen: false});
    // TODO
    fetch('/trial_board_submissions/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...object,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1){
        currentComponent.props.enqueueSnackbar('Studie erfolgreich erstellt.', {variant: 'success'});
        // var trials = [].concat(currentComponent.state.trials);
        // trials.push(data.trial_board_submission);
        // currentComponent.setState({ trials: trials });
      } else {
        currentComponent.props.enqueueSnackbar('Studie konnte nicht erstellt werden.', {variant: 'success'});
      }
    });
  };

  handleEditTrialSave = (trial) => {
    let currentComponent = this;
    // console.log(site);
    this.setState({ editTrialOpen: false});
    fetch('/trial_boards/update_submission', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        // TODO
        ...trial,
        trial_id: trial.id,
        show: 1,
        site_status_id: 1,

        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1){
        currentComponent.props.enqueueSnackbar('Studie erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Studie konnte nicht editiert werden.', {variant: 'success'});
      }
    });
    // this.updateNotes
  }

  handleNewTrialClose = event => {
    this.setState({ newTrialOpen: false });
  };

  handleNewTrialOpen = event => {
    this.setState({ newTrialOpen: true });
  };

  handleEditTrialOpen = event => {
    // console.log("clicked edit");
    // this.setState({ editTrialBoardOpen: true});
    window.location.assign('/trial_boards/' + this.state.selectedTrialIds[0] + '/');
  };

  handleEditTrialClose = event => {
    this.setState({ editTrialOpen: false});
  };

  handleInfoTrialOpen = event => {
    // console.log("clicked edit");
    this.setState({ infoTrialOpen: true});
  };

  handleDoubleClick = (event, obj) => {
    window.location.assign('/trial_boards/' + this.state.selectedTrialIds[0] + '/');
  }

  handleInfoTrialClose = event => {
    this.setState({ infoTrialBoardOpen: false});
  };

  handleDeleteTrialClick = event => {
    let currentComponent = this;
    let newTrials = [];
    newTrials = newTrials.concat(currentComponent.state.trials);
    fetch('/trial_board_submissions/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        trial_board_submission_ids: this.state.selectedTrialIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      const trials = currentComponent.state.trials;

      for (let i = 0, l = currentComponent.state.selectedTrialIds.length; i < l; i += 1) {
        // console.log(currentComponent.state.selectedSiteIds[i]);
        // console.log(sites);
        const currentId = currentComponent.state.selectedTrialIds[i];
        const selectedIndex = newTrials.findIndex(obj => obj.id === currentId);
        if (selectedIndex === -1) { //not clicked before
          console.log("index does not exist");
        } else {
          // newSites = newSites.concat(
          //   sites.slice(0, selectedIndex),
          //   sites.slice(selectedIndex + 1),
          // );
          newTrials.splice(selectedIndex,1);
        }
      }
      // console.log(newSites);
      currentComponent.setState({ trials: newTrials, selectedTrials: [], selectedTrialIds: [] });
    });
    // this.updateSites
  };

  onSearchChange = event => {
    this.setState({searchTerm: event.target.value});
    // this.handleFilterUpdate();
  }

  handleClear = event => {
    // console.log("clear");
    let currentComponent = this;
    currentComponent.setState({selectedTrials: [], selectedTrialIds: [], selectedTrialIndices: [], searchTerm: ""});
  }

  handleSearchClear = event => {
    // console.log("clear");
    let currentComponent = this;
    currentComponent.setState({searchTerm: ""});
  }

  handleRefresh = event => {
    let currentComponent = this;
    fetch('/trial_boards/refresh_trials', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        console.log(data.trials);
        currentComponent.setState ({trials: data.trials});
      }
    });
  }

  handleTrialBoardSubmissionReceived = message => {
    console.log("received Trial Board Submission");
    console.log(message);
    if (message.new_trial_board_submission) {
      console.log("new trial Board");
      let trials = [].concat(this.state.trials);
      trials.push(message.new_trial_board_submission);
      this.setState({ trials: trials });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_trial_board_submission.short, {variant: 'success'});
    }
    if (message.trial) {
      var trialBoards = [];
      let trials = [].concat(this.state.trials);
      const ind = trials.findIndex(tb => tb.id == message.trial.id);
      trials[ind] = message.trial;
      this.setState({ trials: trials });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
    }
    if (message.deleted_trials) {
      let trials = [].concat(this.state.trials);
      let trialShorts = [];
      message.deleted_trials.forEach(function (trial_id, index) {
        const ind = trials.findIndex(t => t.id == trial_id);
        trialShorts.push(trials[ind].short);
        delete trials[ind];
      });
      // delete trialBoards[message.deleted_trial_board_ids];
      this.setState({ trials: trials });
      this.props.enqueueSnackbar('Studie(n) wurde(n) von Benutzer ' + message.username + ' gelöscht: ' + trialShorts, {variant: 'success'});
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    let supplementedTrials = (this.state.searchTerm && this.state.searchTerm.length > 0) ? this.state.trials.filter(trial => trial.short.toLowerCase().includes(this.state.searchTerm.toLowerCase())) : this.state.trials;
    supplementedTrials = supplementedTrials.map(trial => {
      let legalDeptFirst = (trial.cda_legal_dept_ret) ? Moment(trial.cda_legal_dept_ret).diff(trial.cda_legal_dept_out, 'days') : null;
      let legalDeptTimeCompleted = (trial.date_second_legal_dept_in) ? Moment(trial.date_second_legal_dept_in).diff(trial.cda_in, 'days') : Moment(trial.cda_legal_dept_ret).diff(trial.cda_legal_dept_out, 'days');
      let timeSignature = (trial.cda_signed) ? Moment(trial.cda_signed).diff(trial.cda_in, 'days') : null;
      if (!legalDeptTimeCompleted) {
        legalDeptTimeCompleted = Moment().diff(trial.cda_in, 'days') + "+"
      }
      if (!legalDeptFirst) {
        legalDeptFirst = Moment().diff(trial.cda_in, 'days') + "+"
      }
      if (!timeSignature) {
        timeSignature = Moment().diff(trial.cda_in, 'days') + "+"
      }
      const potentialPis = trial.potential_pis.map(person => person.last_name);
      return {...trial, legalDeptTimeCompleted: legalDeptTimeCompleted, legalDeptFirst: legalDeptFirst, timeSignature: timeSignature, potentialPis: potentialPis}
    })
    let copyText = "Akronym\tZeit 1. Antwort\tZeit gesamt\tZeit Unterschrift\n";
    supplementedTrials.forEach(trial => {
      const potentialPis = trial.potential_pis.map(person => person.last_name);
      copyText += trial.short + "\t" + trial.legalDeptFirst + "\t" + trial.legalDeptTimeCompleted + "\t" + trial.timeSignature + "\t" + potentialPis.toString() + "\n"
    })
    return (
      // <ThemeProvider theme={theme}>
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "TrialBoardSubmissionsChannel"}}
            onReceived={this.handleTrialBoardSubmissionReceived}
            onConnected={this.handleConnected}
          />
          <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" name="position" row>
              {/* <SearchBar
                onChange={this.onSearchChange}
              /> */}
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
                <SearchBar
                  onChange={this.onSearchChange}
                  handleClear={this.handleSearchClear}
                  searchTerm={this.state.searchTerm}
                />
                {/* <FormControlLabel
                  value={this.state.multipleSelectMode}
                  control={<Switch color="primary" />}
                  label="Mehrfachauswahl-Modus"
                  labelPlacement="end"
                  onChange={this.handleMultipleSelectChange}
                /> */}
                <Tooltip title="Auswahl löschen">
                  <IconButton aria-label="Auswahl löschen" onClick={this.handleClear}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Refresh">
                  <IconButton aria-label="Refresh" onClick={this.handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <NewTrialBoardTrialDialog
                open={this.state.newTrialOpen}
                handleClose={this.handleNewTrialClose}
                handleSave={this.handleNewTrialSave}
              />
              <EnhancedTable
                title={"Studien"}
                data={supplementedTrials}
                // singleSelect={true}
                selectedIds={this.state.selectedTrialIds}
                selectedObjects={this.state.selectedTrials}
                columns={this.state.trialColumns}
                handleAddClick={this.handleNewTrialOpen}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleTrialChange(rowData, allRowsSelected, allObjectsSelected)}
                handleEditClick={this.handleEditTrialOpen}
                handleDeleteClick={this.handleDeleteTrialClick}
                textToCopy={copyText}
                // onDoubleClick={this.handleDoubleClick}
                rowsPerPage={30}
                order="desc"
                orderBy="short"
                expanded
                selectable
                autoFill
                controller="trial_board_submissions"
                objectType="TrialBoardSubmission"
              />
            </Grid>
          </Grid>
        </Grid>
      // </ThemeProvider>
    )
  }
}

export default withStyles(styles)(withTheme(withSnackbar(TrialBoardTrialsIndex)));
