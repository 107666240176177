import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientStudyIdentifierFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
            >
              {/* <TextField
                margin="dense"
                id="pseudonym"
                label="Pseudonym"
                value={obj.pseudonym}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              /> */}
              <TextField
                margin="dense"
                id="patnr"
                label="PATNR"
                value={obj.patnr}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="extnum"
                label="EXTNUM"
                value={obj.extnum}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="studiencockpitId"
                label="STUDIE"
                value={obj.studiencockpitId}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="title"
                label="TITEL"
                value={obj.title}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
          </Grid>
        </Grid>
  );
}
