import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function TrialData(props) {
  // const classes = useStyles();
  const phaseList = [{value: "I", label: "I"}, {value: "I/II", label: "I/II"}, {value: "II", label: "II"}, {value: "IV", label: "IV"}, {value: "V", label: "V"}];
  const trialTypeList = [{value: "IIT", label: "IIT"}, {value: "IIS", label: "IIS"}];
  return (

        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <TextField
            margin="dense"
            id="title"
            label="Titel"
            value={props.trial.title}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            error={!props.trial.title}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.title != null) }}
            type="string"
            size="small"
          />
          <TextField
            margin="dense"
            id="short"
            label="Akronym"
            value={props.trial.short}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            error={!props.trial.short}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.short != null) }}
            type="string"
            size="small"
          />
          { ((props.trial.legal_basis == "EU-CTR") || (props.trial.eu_ct_number)) &&
          <TextField
            margin="dense"
            id="eu_ct_number"
            label="EU CT Nr."
            value={props.trial.eu_ct_number}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.eu_ct_number != null) }}
            type="string"
            size="small"
          />}
          { ((props.trial.legal_basis != "EU-CTR") || (props.trial.eudract_num)) &&
          <TextField
            margin="dense"
            id="eudract_num"
            label="EudraCT"
            value={props.trial.eudract_num}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.eudract_num != null) }}
            type="string"
            size="small"
          />}
          { ((props.trial.legal_basis != "EU-CTR") || (props.trial.bob_code)) &&
          <TextField
            margin="dense"
            id="bob_code"
            label="BOB Vorlagenummer"
            value={props.trial.bob_code}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.bob_code != null) }}
            type="string"
            size="small"
          />}
          { ((props.trial.legal_basis != "EU-CTR") || (props.trial.ek_code)) &&
          <TextField
            margin="dense"
            id="ek_code"
            label="EK Aktenzeichen"
            value={props.trial.ek_code}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.ek_code != null) }}
            type="string"
            size="small"
          />}
          <TextField
            margin="dense"
            id="inspection_plan_code"
            label="Prüfplan Code"
            value={props.trial.inspection_plan_code}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.inspection_plan_code != null) }}
            type="string"
            size="small"
          />
          <TextField
            margin="dense"
            id="ctgov_code"
            label="CT.gov Nr."
            value={props.trial.ctgov_code}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.ctgov_code != null) }}
            type="string"
            size="small"
          />
          { (props.trial.legal_basis == "EU-CTR") &&
          <TextField
            margin="dense"
            id="trial_register_number"
            label="Studien Register Nr."
            value={props.trial.trial_register_number}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.trial_register_number != null) }}
            type="string"
            size="small"
          />}
          <TextField
            margin="dense"
            id="comment"
            label="Kommentar"
            value={props.trial.comment}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.comment != null) }}
            multiline
            type="text"
            size="small"
          />
        </div>
  );
}
