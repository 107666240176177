import React from 'react';
import { withSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import RoleFields from './RoleFields.jsx';

class EditRoleDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { obj: props.obj};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props.obj)
    if (this.props.obj !== prevProps.obj) {
      this.setState({ obj: this.props.obj });
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    currentComponent.setState({ obj: newObj });
  };

  handleChange2 = (event, meta) => {
    console.log(event, meta);
    const value = event.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    this.setState({ obj: newObj });
  }

  handleChangeMultiple = (event, meta) => {
    const newValues = event.map(option => option.value);
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = newValues;
    this.setState({obj: newObj});
  }

  handleChangeCheckbox = (event, checked) => {
    // console.log(event.target.name);
    // console.log(checked);
    let newObj = {...this.state.obj};
    newObj[event.target.name] = checked;
    this.setState({obj: newObj});
  }

  handleSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/roles/' + obj.obj.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        role: obj.obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
        currentComponent.props.handleClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.props.handleSave(Object.assign({}, this.state.obj, {deadline: deadline, date_status: date_status}));
  }

  render(props) {
    const { open, permArr, handleClose } = this.props;
    const { obj } = this.state;
    const saveable = obj.name;
    // define variables
    // console.log("editTaskDialog");
    // const bgColor = ((obj.taskable_type == "Trial") || (obj.taskable_type == "ProcessStep")) ? blue[500] : 'white';
    // console.log(bgColor);
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '100%'}}}
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle id="form-dialog-title">Task editieren</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} >
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <RoleFields
                  obj={obj}
                  handleChange={this.handleChange}
                  handleChange2={this.handleChange2}
                  handleChangeMultiple={this.handleChangeMultiple}
                  handleChangeDate={this.handleChangeDate}
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  readOnly={false}
                  permArr={permArr}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={!saveable}
            onClick={() => this.handleSave(this.state)}
            color="primary"
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withSnackbar(EditRoleDialog);
