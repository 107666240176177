import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import PersonGlobalSelector from './PersonGlobalSelector.jsx'
import TrialPersonFunctionSelector from './TrialPersonFunctionSelector.jsx'

const alwaysVisibleFunctions = ["LKP", "PI", "SP", "PM", "MO"];

class AddPersonToTrialDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {obj: {trial_id: this.props.trialId, overview_show: false, primary_contact: false}}
    this.form = React.createRef();
  }

  handleClose = event => {
    this.setState({obj: {}});
    this.props.handleClose();
  }

  handleSave = obj => {
    // this.setState({obj: {}});
    this.props.handleSave({...obj});
  };

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let obj = this.state.obj;
    obj[name] = value;
    currentComponent.setState({obj: obj});
  };

  handleChange2 = (event, checked) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let obj = this.state.obj;
    obj[name] = checked;
    this.setState({obj: obj});
  }

  handlePersonChange = event => {
    let currentComponent = this;
    let obj = this.state.obj;
    obj["person_id"] = event.value;
    currentComponent.setState({obj: obj, inputValue: event, selectedPerson: event});
  };

  handleFunctionChange = (newValue, actionMeta) => {
    
    let obj = this.state.obj;
    obj["trial_role"] = newValue;
    if (alwaysVisibleFunctions.includes( obj["trial_role"] )) {
      obj["overview_show"] = true;
    }
    this.setState({obj: obj});
  }

  handleOverviewShowChange = event => {
    let obj = this.state.obj;
    obj["overview_show"] = event.target.checked;
    this.setState({obj: obj});
  }

  handleInputValueChange = event => {

  }

  render(props) {
    const saveable = this.state.selectedPerson && (!this.state.obj.left || this.state.obj.left && this.state.obj.leave_date)
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={true}
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Person zu Studie hinzufügen</DialogTitle>
        <DialogContent>
          Bitte wählen Sie die Person aus dem Adressbuch aus. Ist die Person noch nicht im Adressbuch vorhanden, dann kann sie hier ins Adressbuch eingetragen werden.
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
            <InputLabel
              filled
              animated
              shrink
              margin="dense"
              variant="outlined"
              error={!this.state.obj.person_id}
            >
              Person
            </InputLabel>
            <PersonGlobalSelector
              selectedPerson={this.state.selectedPerson}
              handleChange={this.handlePersonChange}
              handleInputValueChange={this.handleInputValueChange}
              autoFocus
            />
          </FormControl>
          <TrialPersonFunctionSelector
            selectedFunction={this.state.obj.trial_role}
            handleChange={this.handleFunctionChange}
            // handleInputValueChange={this.handleFunctionChange}
            onCreateOption={this.handleCreateFunctionOption}
            title="Funktion"
          />
          <FormControlLabel
            style={{zIndex: 997}}
            control={
              <Checkbox
                checked={this.state.obj.overview_show}
                onChange={this.handleChange2}
                disabled={alwaysVisibleFunctions.includes(this.state.obj.trial_role)}
              />
            }
            name="overview_show"
            label="Auf Studienübersicht angezeigt"
          />
          <FormControlLabel
            style={{zIndex: 996}}
            control={
              <Checkbox
                checked={this.state.obj.primary_contact}
                onChange={this.handleChange2}
              />
            }
            name="primary_contact"
            label="Primärer Ansprechpartner"
          />
          <TextField
            id="join_date"
            label="Eintrittsdatum"
            type="date"
            defaultValue={this.state.obj.join_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={this.state.obj.left}
                onChange={this.handleChange2}
              />
            }
            label="Ausgeschieden"
            name="left"
          />
          <TextField
            id="leave_date"
            label="Austrittsdatum"
            type="date"
            // defaultValue={this.state.leave_date}
            value={this.state.obj.leave_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={(!(this.state.obj.leave_date) && this.state.obj.left)}
            disabled={!(this.state.obj.left)}
            style={{zIndex: 1}}
          />
          <TextField
            id="pta_comment"
            label="Kommentar"
            onChange={this.handleChange}
            multiline
            // className={classes.textField}
            margin="dense"
            variant="outlined"
            value={this.state.obj.pta_comment}
            fullWidth
            style={{zIndex: 1}}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button disabled={!saveable} onClick={() => this.handleSave(this.state.obj)} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default AddPersonToTrialDialog;
