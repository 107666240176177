import React from 'react';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
// import {
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import RightIcon from '@mui/icons-material/ArrowRight';
import LeftIcon from '@mui/icons-material/ArrowLeft';

import TrialSelectField from './TrialSelectField.jsx';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
  }),
}

export default function TimeRegistrationFilterFields(props) {
  const {classes, obj, disabled} = props;
  const selectedRoleOption = (obj.role_ids) ? props.roleOptions.filter(ro => obj.role_ids.includes(ro.value)) : [];
  const selectedServiceOption = (obj.service_ids) ? props.serviceOptions.filter(so => obj.service_ids.includes(so.value)) : [];
  const selectedRangeTypeOption = props.rangeTypeOptions.find(rto => obj.rangeType == rto.value);
  const selectedStatsTypeOption = props.statsTypeOptions.find(sto => obj.statsType == sto.value);
  console.log(obj.rangeType, props.rangeTypeOptions, selectedRangeTypeOption);
  return (
    // <Paper className={classes.root} style={{borderStyle: "solid", borderWidth: "1px"}}>
      <FormControl variant="outlined" margin="normal" fullWidth style={{backgroundColor: "white"}} >
        <FormGroup aria-label="position" name="position" row>
          <FormControl variant="outlined" margin="dense" style={{zIndex: 999, width: '300px', margin: '10px', fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]}} >
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Studien</InputLabel>
            <TrialSelectField
              trials={props.trials} 
              selectedTrialIds={props.selectedTrialIds}
              onChange={props.handleChangeMulti}
            />
          </FormControl>
          {/* TODO */}
          {/* <DatePicker onChange={(date) => props.handleChangeDate(date, "filter_start_date")} /> */}
          <TextField
            margin="dense"
            id="filter_start_date"
            label="Startdatum"
            value={obj.filter_start_date}
            // fullWidth
            autoFocus
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            error={!obj.filter_start_date}
            InputProps={{
              readOnly: props.readOnly,
            }}
            type="date"
            size="small"
          />
          <TextField
            margin="dense"
            id="filter_end_date"
            label="Enddatum"
            value={obj.filter_end_date}
            // fullWidth
            autoFocus
            variant="outlined"
            style={{zIndex: 1}}
            onChange={props.handleChange}
            error={!obj.filter_start_date}
            InputProps={{
              readOnly: props.readOnly,
            }}
            type="date"
            size="small"
          />
          <FormControl variant="outlined" margin="dense" style={{zIndex: 999, width: '250px', margin: '10px', fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]}} >
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Rolle</InputLabel>
            <Select
              value={ selectedRoleOption }
              // defaultValue={"Bitte wählen"}
              name="role_ids"
              onChange={(newValue, actionMeta) => props.handleChangeMulti(newValue, actionMeta)}
              options={props.roleOptions}
              isMulti
              size="small"
            />
          </FormControl>
          <FormControl variant="outlined" margin="dense" style={{zIndex: 998, width: '250px', margin: '10px', fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]}} >
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Leistungsart</InputLabel>
            <Select
              value={ selectedServiceOption }
              // defaultValue={"Bitte wählen"}
              name="service_ids"
              onChange={(newValue, actionMeta) => props.handleChangeMulti(newValue, actionMeta)}
              options={props.serviceOptions}
              isMulti
              size="small"
            />
          </FormControl>
          <FormControl variant="outlined" margin="dense" style={{zIndex: 998, width: '150px', margin: '10px', fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]}} >
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Summiert nach</InputLabel>
            <Select
              value={ selectedStatsTypeOption }
              // defaultValue={{value: "monthly", label: "Monat"}}
              name="statsType"
              onChange={(newValue, actionMeta) => props.handleChange2(newValue, actionMeta)}
              options={props.statsTypeOptions}
              size="small"
            />
          </FormControl>
          { (selectedStatsTypeOption.value == "timespan") &&
          <FormControl variant="outlined" margin="dense" style={{zIndex: 998, width: '150px', margin: '10px', fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]}} >
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Zeit gruppiert nach</InputLabel>
            <Select
              value={ selectedRangeTypeOption }
              // defaultValue={{value: "monthly", label: "Monat"}}
              name="rangeType"
              onChange={(newValue, actionMeta) => props.handleChange2(newValue, actionMeta)}
              options={props.rangeTypeOptions}
              size="small"
            />
          </FormControl>}
        </FormGroup>
      </FormControl>
    // </Paper>
  )
}