import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from './TextField.jsx';
import Button from '@mui/material/Button';


import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'


class EditNoteDialog extends React.Component {
  constructor(props) {
    super(props);
    // var today = new Date();
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();
    // this.state = {selectedNote: this.props.selectedNote, note: this.props.selectedNote.note, entryDate: this.props.selectedNote.entry_date};
    this.state = { ...props.selectedNote};
    this.form = React.createRef();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.selectedNote !== prevProps.selectedNote) {
  //     this.setState({ selectedNote: this.props.selectedNote, note: this.props.selectedNote.note, entryDate: this.props.selectedNote.entry_date});
  //   }
  // }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleSave = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.props.handleSave({...this.state});
    this.setState({ note: "", entry_date: yyyy+"-"+mm+"-"+dd});
  }

  // handleSiteChange = event => {
  //   let currentComponent = this;
  //   const target = event.target;
  //   const value = target.value;
  //   const site_id = value.id;
  //   // console.log(value);
  //   fetch('/trials/'+this.props.trialId+'/site_selection/get_site_departments?'+$.param({site_id: site_id}))
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       console.log(data);
  //       currentComponent.setState({ departments: data.departments});
  //     });
  //   currentComponent.setState({ selectedSite: value });
  // };
  //
  // handleDepartmentChange = event => {
  //   let currentComponent = this;
  //   const target = event.target;
  //   const value = target.value;
  //   // const department_id = value.id;
  //   // // console.log(site_id)
  //   // fetch('/trials/'+this.state.trial.id+'/site_selection/get_department_people?'+$.param({department_id: department_id}))
  //   //   .then(function(response){return response.json();})
  //   //   .then(function(data){
  //   //     console.log(data);
  //   //     currentComponent.setState({ department_people: data.department_people});
  //   //   });
  //   currentComponent.setState({ selectedDepartment: value});
  // };

  render(props) {
    // const { classes } = this.props;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Notiz editieren</DialogTitle>
        <DialogContent>
          <TextField
            id="entry_date"
            label="Datum"
            type="date"
            // defaultValue={this.props.selectedNote.entry_date}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
            value={this.state.entry_date}
          />
          <TextField
            autoFocus
            multiline
            rows="4"
            margin="dense"
            id="note"
            label="Notiz"
            onChange={this.handleChange}
            variant="outlined"
            fullWidth
            value={this.state.note}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.handleSave()} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditNoteDialog;
