import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import { withStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const defaultToolbarStyles = {
  iconButton: {
  },
};

class ToolbarIcon extends React.Component {

  render(props) {
    // const { classes } = this.props;
    if (this.props.rend){
      return (
        <ThemeProvider theme={this.getMuiTheme()}>
          <Tooltip title={this.props.title}>
            <IconButton onClick={this.props.handleAddClick}>
              {this.props.content}
            </IconButton>
          </Tooltip>
        </ThemeProvider>
      );
    } else {
      return null
    }
  }
}

class CustomToolbar extends React.Component {
  handleClick = () => {
    console.log("clicked on icon!");
  }

  render(props) {
    // const { classes } = this.props;
    const addIcon = <AddIcon />;
    if (this.props.renderAdd) {
      return (
        <>
          <Tooltip title={this.props.title + " hinzufügen"}>
            <IconButton onClick={this.props.handleAddClick}>
              <AddIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title={"Neue " + this.props.title + " anlegen"}>
            <IconButton onClick={this.props.handleNewClick}>
              Neu
            </IconButton>
          </Tooltip>
        </>
      );
    } else {
      return (
        <>
          <Tooltip title={"Neue " + this.props.title + " anlegen"}>
            <IconButton onClick={this.props.handleNewClick}>
              Neu
            </IconButton>
          </Tooltip>
        </>
      )
    }
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
