import React from 'react';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SiteSelector from './SiteSelector.jsx';
import DepartmentSelector from './DepartmentSelector.jsx';
import TrialPersonFunctionSelector from './TrialPersonFunctionSelector.jsx';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  content: {
      display: "flex",
      flexBasis: '100%',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-evenly',
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

const alwaysVisibleFunctions = ["LKP", "PI", "SP", "PM", "MO"];

class EditPtaDialog extends React.Component {

  constructor(props) {
    super(props);
    let selectedGender = {};
    if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
      selectedGender = {label: "Männlich", value: "m"}
    } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
      selectedGender = {label: "Weiblich", value: "w"}
    } else if (this.props.selectedPerson.gender == "?") {
      selectedGender = {label: "Unbekannt", value: "?"}
    }
    const selectedFunction = {label: this.props.selectedPerson.trial_role, value: this.props.selectedPerson.trial_role}
    this.state = {open: this.props.open, obj: this.props.selectedPerson, unlocked: false, selectedGender: selectedGender, selectedFunction: selectedFunction};
    this.form = React.createRef();
  }

  // componentDidMount() {
  //   this.getDepartments();
  // }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPerson !== prevProps.selectedPerson) {
      const selectedFunction = {label: this.props.selectedPerson.site_function, value: this.props.selectedPerson.site_function}
      let selectedGender = {};
      if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
        selectedGender = {label: "Männlich", value: "m"}
      } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
        selectedGender = {label: "Weiblich", value: "w"}
      } else if (this.props.selectedPerson.gender == "?") {
        selectedGender = {label: "Unbekannt", value: "?"}
      }
      this.setState({ obj: this.props.selectedPerson, selectedFunction: selectedFunction, selectedGender: selectedGender});
    }
  }

  // getDepartments = () => {
  //   let currentComponent = this;
  //   // departments = this.this.props.peopleDepartmentsAssociations.filter(assoc => assoc.person_id == this.props.selectedPerson.id).map(assoc => assoc.department_id);
  //   currentComponent.setState({ departments: this.props.departments });
  //   // let currentComponent = this;
  //   // // const departments = this.state.departments;
  //   // fetch('/people/'+this.props.selectedPerson.id+'/get_departments', {
  //   //   credentials: 'include',
  //   // })
  //   // .then(function(response){return response.json();})
  //   // .then(function(data){
  //   //   currentComponent.setState({ departments: data.departments, institutions: data.institutions });
  //   // });
  // }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let obj = {...this.state.obj};
    obj[name] = value;
    // selectedPerson["person"][name] = value;
    currentComponent.setState({ obj: obj });
  };

  handleRemoveDepartment = (department_id) => {
    let currentComponent = this;
    fetch('/people/'+this.props.selectedPerson.id+'/remove_from_department', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        department_id: department_id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        let newDepartments = [].concat(currentComponent.state.departments);
        const departmentIndex = newDepartments.findIndex(newDepartment => newDepartment.id == department_id);
        newDepartments.splice(departmentIndex,1);
        currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  };

  handleDoubleClickDepartment = event => {
    console.log(event);
    console.log("doubleClick");
  };

  handleStatusboxLockChange = event => {
    if (this.state.unlocked == false) {
      this.setState({unlocked: true});
    } else {
      this.setState({unlocked: false});
    }
  }

  handleFunctionChange = (newValue, actionMeta) => {
    let obj = this.state.obj;
    obj["trial_role"] = newValue;
    if (alwaysVisibleFunctions.includes( obj["trial_role"] )) {
      obj["overview_show"] = true;
    }
    this.setState({obj: obj});
  }

  handleChange2 = (event, checked) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let obj = this.state.obj;
    obj[name] = checked;
    this.setState({obj: obj});
  }

  handleGenderChange = event => {
    let selectedPerson = {...this.state.selectedPerson};
    selectedPerson.gender = event.value;
    // selectedPerson.person.gender = event.value;
    this.setState({ selectedGender: event, obj: selectedPerson });
  }

  handleClose = event => {
    this.setState({unlocked: false});
    this.props.handleClose();
  }

  render(props) {
    // const { classes } = this.props;
    const { departments } = this.state;
    const genderOptions = [
      {label: "Unbekannt", value: "?"},
      {label: "Männlich", value: "m"},
      {label: "Weiblich", value: "w"},
    ];
    const saveable = this.state.obj.trial_role && (!this.state.obj.left || (this.state.obj.left && this.state.obj.leave_date));
    var departmentChips = null;
    var handleRemoveDepartment = null;
    // if (this.state.unlocked) {
    //   handleRemoveDepartment = this.handleRemoveDepartment;
    // }
    if (departments) {
      if (departments.length > 0) {
        departmentChips = departments.map((department) =>
          <Chip
            // className={classes.chip}
            size="small"
            label={department.name}
            onDoubleClick={this.handleDoubleClickDepartment}
            onDelete={this.state.unlocked ? () => this.handleRemoveDepartment(department.id) : null}
            clickable
            color="primary"
          />
        );
      }
    }
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Person editieren</DialogTitle>
        <DialogContent>
          <TrialPersonFunctionSelector
            selectedFunction={this.state.obj.trial_role}
            handleChange={this.handleFunctionChange}
            // handleInputValueChange={this.handleFunctionChange}
            onCreateOption={this.handleCreateFunctionOption}
            title="Funktion"
          />
          <FormControlLabel
            style={{zIndex: 997}}
            control={
              <Checkbox
                checked={this.state.obj.overview_show}
                // onChange={this.handleChange2}
                onChange={this.handleChange2}
                disabled={alwaysVisibleFunctions.includes(this.state.obj.trial_role)}
              />
            }
            name="overview_show"
            label="Auf Studienübersicht angezeigt"
          />
          <FormControlLabel
            style={{zIndex: 996}}
            control={
              <Checkbox
                checked={this.state.obj.primary_contact}
                onChange={this.handleChange2}
              />
            }
            name="primary_contact"
            label="Primärer Ansprechpartner"
          />
          <TextField
            id="join_date"
            label="Eintrittsdatum"
            type="date"
            defaultValue={this.state.obj.join_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={this.state.obj.left}
                onChange={this.handleChange2}
              />
            }
            label="Ausgeschieden"
            name="left"
          />
          <TextField
            id="leave_date"
            label="Austrittsdatum"
            type="date"
            // defaultValue={this.state.leave_date}
            value={this.state.obj.leave_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={(!(this.state.obj.leave_date) && this.state.obj.left)}
            disabled={!(this.state.obj.left)}
            style={{zIndex: 1}}
          />
          <TextField
            id="pta_comment"
            label="Kommentar"
            onChange={this.handleChange}
            multiline
            // className={classes.textField}
            margin="dense"
            variant="outlined"
            value={this.state.obj.pta_comment}
            fullWidth
            style={{zIndex: 1}}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.props.handleSave({
            ...this.state.obj,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EditPtaDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditPtaDialog);
