import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import TextField from './TextField.jsx';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const camelize = function camelize(str) {
  return str.replace(/\W+(.)/g, function(match, chr)
   {
        return chr.toUpperCase();
    });
}

export default function SitesStatusReport(props) {
  // const classes = useStyles();
  const { classes, sites } = props;
  const siteStatuses = sites.map(site => site.statusText);
  let siteStatusCounts = {};
  for(var i = 0; i < siteStatuses.length; ++i){
    siteStatusCounts[siteStatuses[i]] = (siteStatusCounts[siteStatuses[i]]) ? siteStatusCounts[siteStatuses[i]] + 1 : 1;
  }
  const siteStatusEntries = Object.entries(siteStatusCounts);
  const siteStatusComponents = siteStatusEntries.map(ssa => {
    return (
      <TextField
        id={camelize(ssa[0])}
        label={ssa[0]}
        margin="dense"
        variant="outlined"
        value={ssa[1]}
        fullWidth
        style={{zIndex: 1}}
        InputLabelProps={{ shrink: (ssa[0] != null) }}
        type="string"
      />
    )
  })
  return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          {siteStatusComponents}
        </div>
  );
}
