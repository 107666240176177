import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import TrialArchive from './TrialArchive.jsx';
import { AbilityBuilder, Ability } from '@casl/ability';
import { AbilityContext } from './Can.js';
import ability from './ability.js';
import theme from './theme.js';
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can.js';

function updateAbility(ability, abilities, user) {
  console.log(abilities, user);
  const { can, rules } = new AbilityBuilder(Ability);
  if (user && user.admin) {
    can('manage', 'all');
  } else {
    for (let i = 0; i < abilities.length; i++) {
      can(abilities[i].actions[0], abilities[i].subject[0]);
    }
  }
  ability.update(rules);
}

export default function SnackbarWrappedArchive(props) {
  let user = props.user;
  try {
    user = JSON.parse(props.user);
  } catch {
    console.log(props.user);
  }
  updateAbility(ability, props.abilities, user);
  const cableUrl = "ws://" + window.location.hostname + ":" + window.location.port + "/cable";
  console.log('archive:')
  console.log(props.archive)
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <AbilityContext.Provider value={ability}>
          <ActionCableProvider url={cableUrl}>
            <Can I="read" a="Archive">
              <TrialArchive archive={props.archive} persons={props.persons} />
            </Can>
          </ActionCableProvider>
        </AbilityContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
