import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcbopProOhsFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
    <>    
      <Typography variant="h6">QLQ C30</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
          <TextField
          margin="dense"
          id="ohs_01"
          label="ohs_01"
          value={obj.ohs_01}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_02"
          label="ohs_02"
          value={obj.ohs_02}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_03"
          label="ohs_03"
          value={obj.ohs_03}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_04"
          label="ohs_04"
          value={obj.ohs_04}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_05"
          label="ohs_05"
          value={obj.ohs_05}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_06"
          label="ohs_06"
          value={obj.ohs_06}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_07"
          label="ohs_07"
          value={obj.ohs_07}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_08"
          label="ohs_08"
          value={obj.ohs_08}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_09"
          label="ohs_09"
          value={obj.ohs_09}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_10"
          label="ohs_10"
          value={obj.ohs_10}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_11"
          label="ohs_11"
          value={obj.ohs_11}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="ohs_12"
          label="ohs_12"
          value={obj.ohs_12}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </>
  );
}
