import React from 'react';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import { makeStyles, createStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import SitesStatusReport from './SitesStatusReport.jsx';
import SitesMilestonesReport from './SitesMilestonesReport.jsx';
import SitesMilestonesTableReport from './SitesMilestonesTableReport.jsx';
import SitesListReport from './SitesListReport.jsx';

const styles = theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
    MuiIconButton: {
      root: {
        padding: '2',
      },
    },
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.primary.main,
    align: "center",
    backGroundColor: "#007bff",
  },
  title: {
    display: "flex",
    // flex: '0 1 auto',
    // flex: 1,
    flexBasis: '100%',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 1,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    backGroundColor: "#007bff",
    // alignItems: 'flex-start',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class ReportsPaper extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {unlocked: false}
    this.state = {action: "numbers"}
    this.form = React.createRef();
  }

  handleEditOpen = event => {
    this.setState({editOpen: true});
  }

  handleEditClose = event => {
    this.setState({editOpen: false});
  }

  handleTabChange = (props, event, newValue) => {
    this.setState({action: newValue})
  }

  render(props) {
    const { classes, unlocked, sites, departments, people, sitesDepartmentsAssociations, peopleDepartmentsAssociations, handleExpandChange, expanded, handleLockChange, handleMilestoneChange } = this.props;
    const { action } = this.state;
    // const classes = styles(this.props.theme);
    // console.log(classes);
    // const locklabel = {
    //   if (unlocked) {
    //     return (
    //       <LockOpen />
    //     )
    //   } else {
    //     return (
    //       <Lock />
    //     )
    //   }
    // }
    return (
      <>
      <Paper 
        // className={classes.root}
      >
        <Toolbar
          // className={classNames(classes.root)}
          variant='dense'
        >
          <div >
            <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
              Reports
            </Typography>
            {/* <div className={classes.actions}>
              {(this.props.unlocked) &&
                <Tooltip title="Editieren">
                  <IconButton aria-label="Edit" onClick={this.handleEditOpen}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
            </div> */}
          </div>
        </Toolbar>
        <AppBar position="static" color='primary'>
          <Tabs value={action} onChange={(event, newValue) => this.handleTabChange(props, event, newValue)} variant="scrollable" textColor="neutral">
            <Tab label="#Zentren" value="numbers" />
            <Tab label="Milestones" value="milestones" />
            <Tab label="Prüfstellenliste" value="sites_list" />
          </Tabs>
        </AppBar>
        <TabPanel value={action} index={"numbers"}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6}>
              <SitesStatusReport sites={sites} />
            </Grid>
            <Grid item xs={6}>
              <SitesMilestonesReport sites={sites} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={action} index={"milestones"}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <SitesMilestonesTableReport sites={sites} unlocked={unlocked} expanded={expanded} handleExpandChange={handleExpandChange} handleLockChange={handleLockChange} handleMilestoneChange={handleMilestoneChange}
                customMilestones={this.props.customMilestones}
                // milestones={(this.state.selectedSites.length == 1) ? this.state.selectedSites[0].milestones : null}
                standardMilestones={this.props.standardMilestones}
               />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={action} index={"sites_list"}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <SitesListReport sites={sites} departments={departments} people={people} sitesDepartmentsAssociations={sitesDepartmentsAssociations} peopleDepartmentsAssociations={peopleDepartmentsAssociations} expanded={expanded} handleExpandChange={handleExpandChange} />
            </Grid>
          </Grid>
        </TabPanel>
      </Paper>
      </>
    )
  }
}

ReportsPaper.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedSite: PropTypes.isRequired,
};

export default withStyles(styles)(withTheme(ReportsPaper)) ;
