import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import Save from '@mui/icons-material/Save';
import LockOpen from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";

import { withSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControl: {
      root: {
        marginLeft: 5,
        marginBottom: 2,
        marginTop: 2,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

class TrialConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {trial: this.props.trial, locked: true, user: props.user};
    this.form = React.createRef();
  }

  handleLockChange = event => {
    if (this.state.locked == false) {
      this.setState({locked: true})
    } else {
      this.setState({locked: false})
    }
  }

  handleSave = event => {
    const currentComponent = this;
    fetch('/trials/' + this.state.trial.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...this.state.trial,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ locked: true });
        // // currentComponent.updatePeople(currentComponent.state.selectedDepartmentIds);
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleChange2 = (event, checked) => {
    let newTrial = {...this.state.trial};
    newTrial[event.target.name] = checked;
    this.setState({trial: newTrial});
  }

  handleTrialsReceived = message => {
    console.log("received Trial");
    console.log(message);
    if (message.trial && message.trial.id == this.state.trial.id) {
      const trial = message.trial;
      this.setState({ trial: trial });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
    }
    if (message.deleted_trial_ids && message.deleted_trial_ids.includes(this.state.trial.id)) {
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' gelöscht: ' + this.state.trial.short, {variant: 'success'});
      window.location.assign('/trials');
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    const { classes } = this.props;
    const { trial, locked } = this.state;
    return (
      <Grid container >
        <ActionCableConsumer
          channel={{channel: "TrialsChannel"}}
          onReceived={this.handleTrialsReceived}
          onConnected={this.handleConnected}
        />
        <Grid item xs={12} style={{padding: '0px'}}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" flexShrink={true} flexWrap="nowrap">
            { (!this.state.locked) &&
              <Tooltip title="Speichern">
                <IconButton aria-label="Save" onClick={this.handleSave}>
                  <Save />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title="Studie editieren">
              <FormControlLabel
                control={
                  <Switch
                    checked={!this.state.locked}
                    onChange={this.handleLockChange}
                    color="primary"
                  />
                }
                label= {this.state.locked ? <Lock /> : <LockOpen /> }
                style={{marginRight: 0, alignSelf: 'flex-end'}}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} style={{padding: '0px'}}>
          <TextField
            margin="dense"
            id="status"
            label="Status"
            value={trial.status.text}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            InputProps={{
              readOnly: locked,
            }}
            InputLabelProps={{ shrink: (trial.status != null) }}
            type="string"
          />
        </Grid>
        <Grid item xs={4} >
          <Grid container justify="center">
            <Paper className={classes.root} style={{width: '100%'}}>
              <div className={classes.title}>
                <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5, justify: "center"}}>
                  Finanzen und Verträge
                </Typography>
              </div>
              <div>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.contracts_coordination_sponsor || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Verträge, Abstimmung mit Sponsor"
                    name="contracts_coordination_sponsor"
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.finance_administration || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Finanzverwaltung"
                    name="finance_administration"
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.financial_planning || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                        style={{margin: '0px'}}
                      />
                    }
                    label="Kalkulation/Finanzplan"
                    name="financial_planning"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid container justify="center">
          <Paper className={classes.root} style={{width: '100%'}}>
              <div className={classes.title}>
                <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                  Projektmanagement
                </Typography>
              </div>
              <div>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.ethics_management || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Ethikvorbereitung und Einreichung"
                    name="ethics_management"
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.patient_insurance || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Patientenversicherung"
                    name="patient_insurance"
                  />
                </FormControl><FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.bob_application || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="BOB Antrag"
                    name="bob_application"
                  />
                </FormControl><FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.administration_processing || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Behördenverfahren"
                    name="administration_processing"
                  />
                </FormControl><FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.application_support || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Unterstützung Antragsteller (BMBF, DFG, etc.)"
                    name="application_support"
                  />
                </FormControl><FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.trial_documentation_compilation || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Studiendokumente (Erstellung, Review)"
                    name="trial_documentation_compilation"
                  />
                </FormControl><FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.external_center_coordination || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Organisation externe Zentren"
                    name="external_center_coordination"
                  />
                </FormControl><FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.public_register || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="öffentliches Register"
                    name="public_register"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid container justify="center">
          <Paper className={classes.root} style={{width: '100%'}}>
              <div className={classes.title}>
                <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                  Studienassistenz
                </Typography>
              </div>
              <div>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.trial_documentation || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Studiendokumentation"
                    name="trial_documentation"
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.local_trial_management || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="lok. Studienorganisation (Pat.-Visiten, Doku)"
                    name="local_trial_management"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid container justify="center">
          <Paper className={classes.root} style={{width: '100%'}}>
              <div className={classes.title}>
                <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                 Monitoring
                </Typography>
              </div>
              <div>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.monitoring || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Monitoring"
                    name="monitoring"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid container justify="center">
          <Paper className={classes.root} style={{width: '100%'}}>
              <div className={classes.title}>
                <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                  Datenmanagement
                </Typography>
              </div>
              <div>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}} >
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.data_management_planning || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Datenmanagement - Planung (CRF, DMP)"
                    name="data_management_planning"
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}} >
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.data_management_execution || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Datenmanagement - Durchführung (DB, Queries, DE, Bericht)"
                    name="data_management_execution"
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}} >
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.data_management_analysis || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Datenmanagement - Auswertung (Prog.)"
                    name="data_management_analysis"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid container justify="center">
          <Paper className={classes.root} style={{width: '100%'}}>
              <div className={classes.title}>
                <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
                  Biometrie
                </Typography>
              </div>
              <div>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.biometrics_planning || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Biometrie - Planung (SAP Fallzahlprüfung)"
                    name="biometrics_planning"
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.biometrics_execution || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Biometrie - Durchführung (Randomisierung, Zw.-Auswertung)"
                    name="biometrics_execution"
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999, marginBottom: '2px', marginTop: '2px', marginLeft: '4px'}}>
                  <FormControlLabel
                    style={{ marginBottom: '0px', marginTop: '0px', marginLeft: '0px'}}
                    control={
                      <Checkbox
                        color="primary"
                        checked={trial.biometrics_analysis || false}
                        onChange={this.handleChange2}
                        disabled={locked}
                      />
                    }
                    label="Biometrie Auswertung (stat. Analyse, Bericht, Publik.)"
                    name="biometrics_analysis"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

    )
  }
}

TrialConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(TrialConfiguration));
