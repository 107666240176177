import React from 'react';
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
// import MUIDataTable from "mui-datatables";
// import { createTheme } from '@mui/styles';

import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import NewTrialDialog from './NewTrialDialog.jsx';
import EditTrialDialog from './EditTrialDialog.jsx';
import InfoTrialDialog from './InfoTrialDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';
import SearchBar from './SearchBar.jsx';


// const styles = {
//   TableCheckbox: {
//     padding: 0,
//   },
//   root: {
//     MuiIconButton: {
//       root: {
//         padding: 3,
//       },
//     },
//     MuiTableCell: {
//       root: {
//         paddingTop: 3,
//         paddingBottom: 3,
//         paddingRight: 10,
//         paddingLeft: 10,
//         height: 10,
//       },
//       head: {
//         paddingTop: 6,
//         paddingBottom: 6,
//         paddingRight: 10,
//         paddingLeft: 10,
//         height: 10,
//       },
//       paddingCheckbox: {
//         width: 37,
//         backgroundColor: 'FFFFF',
//       },
//     },
//     MuiTableRow: {
//       root: {
//         height: "7px",
//       },
//       head: {
//         height: "26px",
//       },
//     },
//     MuiTableHead: {
//       root: {
//         height: "30px",
//       },
//     },
//     MuiIconButton: {
//       root: {
//         padding: 0,
//       },
//     },
//     MuiTablePagination: {
//       toolbar: {
//         minHeight: 0,
//         height: 30,
//         paddingRight: 2,
//       }
//     },
//     MuiDialog: {
//       paper: {
//         height: 800,
//         width: 800,
//       },
//     },
//     MuiPaper: {
//       root: {
//         width: "100%",
//       },
//     },
//     MuiFormControlLabel: {
//       root: {
//         marginLeft: 10,
//         marginBottom: 0,
//       },
//     },
//     menu: {
//       zIndex: 100
//     },
//   },
// };
// const theme = createTheme({
//   overrides: {
//     MuiIconButton: {
//       root: {
//         padding: 3,
//       },
//     },
//     MuiTableCell: {
//       root: {
//         paddingTop: 3,
//         paddingBottom: 3,
//         paddingRight: 10,
//         paddingLeft: 10,
//         height: 10,
//       },
//       paddingCheckbox: {
//         width: 37,
//         backgroundColor: 'FFFFF',
//       },
//     },
//     MuiTableCell: {
//       head: {
//         paddingTop: 6,
//         paddingBottom: 6,
//         paddingRight: 10,
//         paddingLeft: 10,
//         height: 10,
//       },
//     },
//     MuiTableRow: {
//       root: {
//         height: "7px",
//       },
//       head: {
//         height: "26px",
//       },
//     },
//     MuiTableHead: {
//       root: {
//         height: "30px",
//       },
//     },
//     MuiIconButton: {
//       root: {
//         padding: 0,
//       },
//     },
//     MuiTablePagination: {
//       toolbar: {
//         minHeight: 0,
//         height: 30,
//         paddingRight: 2,
//       }
//     },
//     MuiPaper: {
//       elevation24: {
//         height: 800,
//         width: 1600,
//       },
//     },
//   },
//   palette: {
//     primary: {main: blue[500]},
//     secondary: {main: blue[800]}
//   },
//
// });

class TrialsIndex extends React.Component {
  constructor(props) {
    super(props);
    const trialColumns = [
      {
        id: "id",
        label: "ID",
        numeric: false,
        padding: 'none',
      },
      {
        id: "short",
        label: "Kurztitel",
        numeric: false,
        padding: 'none',
      },
      {
        id: "statusText",
        label: "Status",
        numeric: false,
        padding: 'none',
        type: "objectStatus",
      },
      {
        id: "created_at",
        label: "Erstellungsdatum",
        numeric: false,
        padding: 'none',
        date: true,
        type: "date",
      },
      {
        id: "updated_at",
        label: "Letzte Änderung",
        numeric: false,
        padding: 'none',
        date: true,
        type: "date",
      },
    ];
    this.state = {trials: props.trials, selectedTrials: [], selectedTrialIds: [], selectedTrialIndices: [], newTrialOpen: false, editTrialOpen: false, infoTrialOpen: false, trialColumns: trialColumns, searchTerm: "", user: props.user};
    this.form = React.createRef();
  }

  onSearchChange = event => {
    this.setState({searchTerm: event.target.value});
    // this.handleFilterUpdate();
  }

  handleSearchClear = event => {
    this.setState({searchTerm: ""});
    // this.handleFilterUpdate();
  }

  updateTrials = () => {
    let currentComponent = this;
    fetch('/trials/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({ trials: data.trials });
    });
  }

  handleTrialChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedTrials: allObjectsSelected,
      selectedTrialIds: allRowsSelected,
      selectedTrialIndices: this.state.trials.findIndex(object => object.id === allRowsSelected[0]),
    });
  };
  handleNewTrialSave = object => {
    let currentComponent = this;
    this.setState({ newTrialOpen: false});
    fetch('/trials/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...object,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // var trials = [];
      // trials = trials.concat(currentComponent.state.trials);
      //
      // trials.push(data.trial);
      // currentComponent.setState({ trials: trials });
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich erstellt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Studie konnte nicht erstellt werden. Bitte kontaktieren Sie ihren Administrator.', {variant: 'success'});
      }

    });
  };

  // handleEditTrialSave = (trial) => {
  //   let currentComponent = this;
  //   // console.log(site);
  //   this.setState({ editTrialOpen: false});
  //   fetch('/trials/'+this.state.trial.id+'/update', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     // TODO
  //     body: JSON.stringify({
  //       // TODO
  //       site_id: site.id,
  //       marvin_id: site.marvin_id,
  //       institution_id: site.institution_id,
  //       inst_description: site.inst_description,
  //       name: site.name,
  //       show: 1,
  //       site_status_id: 1,
  //
  //       // trial_id: this.state.trial.id,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     // console.log(data);
  //     // currentComponent.setState({ notes: notes });
  //   });
  //   this.updateTrials
  // }

  handleNewTrialClose = event => {
    this.setState({ newTrialOpen: false });
  };

  handleNewTrialOpen = event => {
    this.setState({ newTrialOpen: true });
  };

  handleEditTrialOpen = event => {
    // console.log("clicked edit");
    // this.setState({ editTrialBoardOpen: true});
    window.location.assign('/trials/' + this.state.selectedTrialIds[0] + '/');
  };

  handleEditTrialClose = event => {
    this.setState({ editTrialOpen: false});
  };

  handleInfoTrialOpen = event => {
    // console.log("clicked edit");
    this.setState({ infoTrialOpen: true});
  };

  handleInfoTrialClose = event => {
    this.setState({ infoTrialOpen: false});
  };

  handleDeleteTrialClick = event => {
    let currentComponent = this;
    let newTrials = [];
    newTrials = newTrials.concat(currentComponent.state.trials);
    fetch('/trials/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        trial_ids: currentComponent.state.selectedTrialIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 0) {
        // const newTrials = currentComponent.state.trials.filter(trial => !currentComponent.state.selectedTrialIds.includes(trial.id));
        currentComponent.setState({ selectedTrials: [], selectedTrialIds: [] });
        currentComponent.props.enqueueSnackbar('Studie(n) erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Konnte nicht gelöscht werden.', {variant: 'error'});
      }
    });
    // this.updateSites
  };

  handleTrialsReceived = message => {
    console.log("received Trial");
    console.log(message);
    if (message.new_trial) {
      let trials = [].concat(this.state.trials);
      trials.push(message.new_trial);
      this.setState({ trials: trials });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_trial.short, {variant: 'success'});
    }
    if (message.trial) {
      let trials = [].concat(this.state.trials);
      const ind = trials.findIndex(trial => trial.id == message.trial.id);
      trials[ind] = message.trial;
      this.setState({ trials: trials });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
    }
    if (message.deleted_trial_ids) {
      let trials = [].concat(this.state.trials.filter(trial => !message.deleted_trial_ids.includes(trial.id)));
      let trialShorts = this.state.trials.filter(trial => message.deleted_trial_ids.includes(trial.id)).map(trial => trial.short);
      // message.deleted_trial_ids.forEach(function (trial_id, index) {
      //   console.log(trials);
      //   const ind = trials.findIndex(d => d.id == trial_id);
      //   trialShorts.push(trials[ind].short)
      //   delete trials[ind];
      // });
      // delete trialBoards[message.deleted_trial_board_ids];
      this.setState({ trials: trials });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' gelöscht: ' + trialShorts, {variant: 'success'});
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    // console.log(this.props);
    const st = this.state.searchTerm.toLowerCase()
    // for (const [key, value] of Object.entries(this.state.trials[0])) {

    //   console.log(value, key);
    // };
    // const filteredTrials = this.state.trials.filter(trial => trial.short.toLowerCase().includes(st) || trial.short.toLowerCase().includes(st));
    const filteredTrials = this.state.trials.filter(trial => {
      for (const [key, value] of Object.entries(trial)) {
        console.log(key, value);
        if (value && String(value).includes(st)) {
          return true
        }
      };
    });
    // console.log(filteredTrials);
    const supplementedTrials = filteredTrials.map(trial => {
      let ret = {...trial};
      ret["statusText"] = ret.status.text;
      ret["statusColor"] = ret.status.color;
      return ret;
    })
    return (
      // <ThemeProvider theme={theme}>
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "TrialsChannel"}}
            onReceived={this.handleTrialsReceived}
            onConnected={this.handleConnected}
          />
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" name="position" row>
                <SearchBar
                  onChange={this.onSearchChange}
                  handleClear={this.handleSearchClear}
                  searchTerm={this.state.searchTerm}
                />
                <Tooltip title="Refresh">
                  <IconButton aria-label="Info" onClick={this.updateTrials}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <NewTrialDialog
                open={this.state.newTrialOpen}
                handleClose={this.handleNewTrialClose}
                handleSave={this.handleNewTrialSave}
              />
                <EnhancedTable
                  title={"Studien"}
                  data={supplementedTrials}
                  // singleSelect={false}
                  multipleSelectable
                  selectedIds={this.state.selectedTrialIds}
                  selectedObjects={this.state.selectedTrials}
                  columns={this.state.trialColumns}
                  handleAddClick={this.handleNewTrialOpen}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleTrialChange(rowData, allRowsSelected, allObjectsSelected)}
                  handleEditClick={this.handleEditTrialOpen}
                  handleDeleteClick={this.handleDeleteTrialClick}
                  rowsPerPage={30}
                  order="asc"
                  orderBy="id"
                  controller="trials"
                  autoFill
                  selectable
                  expanded
                  objectType="Trial"
                />
            </Grid>
          </Grid>
        </Grid>
      // </ThemeProvider>
    )
  }
}

export default withTheme(withSnackbar(TrialsIndex));
