import React from 'react';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  content: {
      display: "flex",
      flexBasis: '100%',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-evenly',
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class EditPersonDialog extends React.Component {

  constructor(props) {
    super(props);
    let selectedGender = {};
    if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
      selectedGender = {label: "Männlich", value: "m"}
    } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
      selectedGender = {label: "Weiblich", value: "w"}
    } else if (this.props.selectedPerson.gender == "?") {
      selectedGender = {label: "Unbekannt", value: "?"}
    }
    const selectedFunction = {label: this.props.selectedPerson.site_function, value: this.props.selectedPerson.site_function}
    this.state = {open: this.props.open, selectedPerson: this.props.selectedPerson, unlocked: false, selectedGender: selectedGender, selectedFunction: selectedFunction};
    this.form = React.createRef();
  }

  // componentDidMount() {
  //   this.getDepartments();
  // }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPerson !== prevProps.selectedPerson) {
      const selectedFunction = {label: this.props.selectedPerson.site_function, value: this.props.selectedPerson.site_function}
      let selectedGender = {};
      if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
        selectedGender = {label: "Männlich", value: "m"}
      } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
        selectedGender = {label: "Weiblich", value: "w"}
      } else if (this.props.selectedPerson.gender == "?") {
        selectedGender = {label: "Unbekannt", value: "?"}
      }
      this.setState({ selectedPerson: this.props.selectedPerson, selectedFunction: selectedFunction, selectedGender: selectedGender});
    }
  }

  // getDepartments = () => {
  //   let currentComponent = this;
  //   // departments = this.this.props.peopleDepartmentsAssociations.filter(assoc => assoc.person_id == this.props.selectedPerson.id).map(assoc => assoc.department_id);
  //   currentComponent.setState({ departments: this.props.departments });
  //   // let currentComponent = this;
  //   // // const departments = this.state.departments;
  //   // fetch('/people/'+this.props.selectedPerson.id+'/get_departments', {
  //   //   credentials: 'include',
  //   // })
  //   // .then(function(response){return response.json();})
  //   // .then(function(data){
  //   //   currentComponent.setState({ departments: data.departments, institutions: data.institutions });
  //   // });
  // }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let selectedPerson = {...this.state.selectedPerson};
    selectedPerson[name] = value;
    // console.log(name, value);
    // selectedPerson["person"][name] = value;
    currentComponent.setState({ selectedPerson: selectedPerson });
  };

  handleRemoveDepartment = (department_id) => {
    let currentComponent = this;
    fetch('/people/'+this.props.selectedPerson.id+'/remove_from_department', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        department_id: department_id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // let newDepartments = [].concat(currentComponent.state.departments);
        // const departmentIndex = newDepartments.findIndex(newDepartment => newDepartment.id == department_id);
        // newDepartments.splice(departmentIndex,1);
        // currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  };

  handleDoubleClickDepartment = event => {
    console.log(event);
    console.log("doubleClick");
  };

  handleStatusboxLockChange = event => {
    if (this.state.unlocked == false) {
      this.setState({unlocked: true});
    } else {
      this.setState({unlocked: false});
    }
  }

  handleGenderChange = event => {
    let selectedPerson = {...this.state.selectedPerson};
    selectedPerson.gender = event.value;
    // selectedPerson.person.gender = event.value;
    this.setState({ selectedGender: event, selectedPerson: selectedPerson });
  }

  handleClose = event => {
    this.setState({unlocked: false});
    this.props.handleClose();
  }

  render(props) {
    const { departments } = this.props;
    const genderOptions = [
      {label: "Unbekannt", value: "?"},
      {label: "Männlich", value: "m"},
      {label: "Weiblich", value: "w"},
    ];
    const saveable = this.state.selectedPerson.last_name && this.state.selectedPerson.first_name && this.state.selectedGender;
    var departmentChips = null;
    var handleRemoveDepartment = null;
    // if (this.state.unlocked) {
    //   handleRemoveDepartment = this.handleRemoveDepartment;
    // }
    if (departments) {
      if (departments.length > 0) {
        departmentChips = departments.map((department) =>
          <Chip
            className={classes.chip}
            size="small"
            label={department.name + " - " + department.institutionName}
            onDoubleClick={this.handleDoubleClickDepartment}
            onDelete={() => this.handleRemoveDepartment(department.id)}
            clickable
            color="primary"
          />
        );
      }
    }
    // console.log(departments);
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Person editieren</DialogTitle>
        <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="last_name"
                  label="Nachname"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.last_name || ''}
                  error={!this.state.selectedPerson.last_name}
                  style={{backgroundColor: "white"}}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="first_name"
                  label="Vorname"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.first_name || ''}
                  error={!this.state.selectedPerson.first_name}
                  style={{backgroundColor: "white"}}
                  type="string"
                />
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
                  <InputLabel
                    filled
                    animated
                    shrink
                    margin="dense"
                    variant="outlined"
                    error={(this.state.selectedPerson.gender == "" || this.state.selectedPerson.gender == null)}
                    style={{backgroundColor: "white"}}
                  >
                    Geschlecht
                  </InputLabel>
                  <Select
                    value={this.state.selectedGender}
                    onChange={this.handleGenderChange}
                    options={genderOptions}
                    // isValidNewOption={this.showAdd}
                    style={{backgroundColor: "white"}}
                  />
                </FormControl>
                <TextField
                  margin="dense"
                  id="title"
                  label="Titel"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.title || ''}
                  style={{backgroundColor: "white"}}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="phone_number"
                  label="Telefonnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.phone_number || ''}
                  // error={!this.state.phoneNumber}
                  style={{backgroundColor: "white"}}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="mobile_number"
                  label="Telefonnummer (mobil)"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.mobile_number || ''}
                  style={{backgroundColor: "white"}}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="fax_number"
                  label="Fax"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.fax_number || ''}
                  style={{backgroundColor: "white"}}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="E-Mail"
                  type="email"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.email || ''}
                  // error={!this.state.selectedPerson.email}
                  style={{backgroundColor: "white"}}
                />
                <TextField
                  margin="dense"
                  id="function"
                  label="Stelle"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.selectedPerson.function || ''}
                  // error={!this.state.function}
                  style={{backgroundColor: "white"}}
                  type="string"
                />

                <TextField
                  id="comment"
                  label="Kommentar"
                  onChange={this.handleChange}
                  multiline
                  // className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={this.state.selectedPerson.comment || ''}
                  fullWidth
                  style={{zIndex: 1}}
                  type="text"
                />
                { (departments) && 
                <InputLabel
                  filled
                  animated
                  shrink
                  margin="dense"
                  variant="outlined"
                  error={(this.state.selectedPerson.gender == "" || this.state.selectedPerson.gender == null)}
                  style={{backgroundColor: "white"}}
                >
                  Abteilungen
                </InputLabel>
                }
                {departmentChips}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.props.handleSave({
            ...this.state.selectedPerson,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EditPersonDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditPersonDialog);
