import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import InstitutionSelector from './InstitutionSelector.jsx';
import StatusCheckboxes from './StatusCheckboxes.jsx';

class EditSubmissionResolutionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: props.open, resolution: props.assoc.board_resolution};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.assoc !== prevProps.assoc) {
      this.setState({resolution: this.props.assoc.board_resolution});

      // for (var i = 0; i < this.state.site_statuses.length; i++) {
      //   if (currentComponent.props.selectedSite.site_status_id == data.site_statuses[i].id) {
      //     currentComponent.setState({selectedSiteStatus: {value: data.site_statuses[i].id, label: data.site_statuses[i].text, color: data.site_statuses[i].color }});
      //   }
      // }
    }
  };

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  render(props) {
    const saveable = true;
    const {handleClose, assoc} = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Beschluss editieren</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} >
                <TextField
                  autoFocus
                  margin="dense"
                  id="resolution"
                  label="Beschluss"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.resolution}
                  style={{zIndex: 1}}
                  multiline
                  type="text"
                />
                
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleClose(assoc.id)} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.props.handleSave({
            assoc_id: assoc.id,
            board_resolution: this.state.resolution,
            trial_board_id: assoc.trial_board_id,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditSubmissionResolutionDialog;
