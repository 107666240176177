import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import EnhancedTable from './EnhancedTable.jsx';

import moment from 'moment';

import DropZone from './DropZone.jsx';
// import { ThirteenMp, ThreeSixty } from '@mui/icons-material';
// import { ThirteenMp, ThreeSixty } from '@mui/icons-material';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import LoadingButton from '@mui/lab/LoadingButton';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemText from '@mui/material/ListItemText';
import { object } from 'prop-types';

const selectionColumns = [
  // {
  //   id: "name_in_export",
  //   secondary: "name_in_export",
  //   label: "Parameter",
  //   numeric: false,
  //   disablePadding: false,
  //   searchable: true,
  // },
  // {
  //   id: "old",
  //   secondary: "old",
  //   label: "Alter Eintrag",
  // }, 
  // {
  //   id: "selection",
  //   type: "choseWhetherOverwrite",
  // },
  // {
  //   id: "new",
  //   secondary: "new",
  //   label: "Neuer Eintrag",
  // }, 
  {
    id: "pseudonym",
    label: "Pseudonym"
  },
  {
    id: "column_name",
    label: "Eintrag"
  },
  {
    id: "oldValue",
    label: "Alt"
  },
  {
    id: "writeNew",
    label: "Auswählen",
    type: "choseWhetherOverwrite"
  },
  {
    id: "newValue",
    label: "Neu"
  }
]

class DataEntryEditDialog extends React.Component{
  constructor(props){
    super(props);
    let user = this.props.user;
    this.state = {
      // fileContent: this.props.fileContent,
      updatedDataArr: [],
      user: user,
      oldData: [],
      contentVisible: false,
      data: props.fileContent
    };
    this.form = React.createRef();

    // To get data from db.
    // let pairs;
    // let oldData;

  }

  componentDidMount(props) {
    
      // Write information about file kind into variable.
      const currentComponent = this;

      // fetch('/sarcbob_file_kind/' + this.props.sarcbobInputFileKindId + '/show', {
      //   method: 'GET',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //     'X-Transaction': 'POST Example',
      //     'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      //   },
      //   credentials: 'include',
      // })
      // .then(function(response){return response.json();})
      // .then(function(data){
      //   currentComponent.setState({fileKindInformation: data.result, sarcPatientIdString: (data.result.table_name == 'sarc_patients') ? 'id' : 'sarc_patient_id'});
      //   currentComponent.getOldData(data.result, currentComponent.props.fileContent);
      // });

      // Get data about headlines.
      fetch('/bijections/'+ this.props.sarcbobInputFileKindId + '/get_pairs_and_filekind', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        // console.log(data);
        currentComponent.setState({pairs: data.pairs, fileKindInformation: data.filekind, sarcPatientIdString: (data.filekind.table_name == 'sarc_patients') ? 'id' : 'sarc_patient_id'});
        currentComponent.getOldData(data.filekind, currentComponent.props.fileContent, data.pairs);

      });
      

      // var newEntries = [];

      // Iterate over uploaded data.
      // for (var i in this.props.fileContent){

      //   var newData = {};
      //   // TODO
      //   // for (var item in this.state.fileContent[i]) {
      //   //   newData[i] = [[this.state.pairs.find(pair => pair.name_in_export === item).name_in_db]]: this.state.fileContent[i][item];
      //   // }
      //   this.state.fileContent[i].new = newData;

      //   try {
      //     this.state.fileContent[i][this.state.sarcPatientIdString] = this.props.patients.find(patient =>
      //       patient.pseudonym === this.state.fileContent[i].new.pseudonym
      //     ).id;
      //   } catch {
      //     if(this.state.sarcPatientIdString == "id") {
      //       newEntries.push({
      //         new: this.state.fileContent[i].new,
      //         old: [],
      //         writeNew: true,
      //         Pseudonym: this.state.fileContent[i].Pseudonym,
      //         type: "new"
      //       });
      //     }
      //     continue;
      //   }

      //   if(this.state.fileKindInformation.table_name == "sarc_socios"){
      //     if(typeof this.state.fileContent[i]["kinder"] !== 'undefined') {
      //       this.state.fileContent[i].new.children_under_7 = this.state.fileContent[i]["kinder"].includes("1");
      //       this.state.fileContent[i].new.children_between_7_and_13 = this.state.fileContent[i]["kinder"].includes("2");
      //       this.state.fileContent[i].new.children_between_14_and_21 = this.state.fileContent[i]["kinder"].includes("3");
      //       this.state.fileContent[i].new.children_over_21 = this.state.fileContent[i]["kinder"].includes("4");
      //     }
      //     // Find Better way
      //     // delete this.state.data[i].new.pseudonym;
      //   }



      //   // if (!Object.keys(oldData).length && this.state.fileContent[i][this.state.sarcPatientIdString] != null){
      //   //   newEntries.push({
      //   //     ...this.state.fileContent[i],
      //   //     [this.state.sarcPatientIdString]: this.state.fileContent[i][this.state.sarcPatientIdString],
      //   //     writeNew: true,
      //   //   });
      //   // } else if (Object.keys(oldData).length) {
      //   //   var affectedColumns = [];

      //   //   for (const key of Object.keys(this.state.fileContent[i].new)){
      //   //     if(key == "pseudonym" && this.props.sarcbobInputFileKindId != 1) continue;

      //   //     if(
      //   //         (oldData[key] === undefined || oldData[key] === null)
      //   //         && this.state.fileContent[i].new[key] != null
      //   //         && this.state.fileContent[i].new[key] != ""
      //   //       ) {
      //   //       affectedColumns.push(key);
      //   //     } else if(key.includes("date")){
      //   //       if(moment(this.state.fileContent[i].new[key]).format() != moment(oldData[key]).format()){

      //   //         newEntries.push({
      //   //           new: this.state.fileContent[i].new[key],
      //   //           old: oldData[key],
      //   //           key: key,
      //   //           writeNew: true,
      //   //           [this.state.sarcPatientIdString]: this.state.fileContent[i][this.state.sarcPatientIdString],
      //   //           Pseudonym: this.state.fileContent[i].Pseudonym,
      //   //           type: "replace"
      //   //         })
      //   //       }
      //   //     } else if (key != "pseudonym") {
      //   //       if(this.state.fileContent[i].new[key] != oldData[key] && !(this.state.fileContent[i].new[key] == "" && oldData[key] == null)){
      //   //         newEntries.push({
      //   //           new: this.state.fileContent[i].new[key],
      //   //           old: oldData[key],
      //   //           key: key,
      //   //           writeNew: true,
      //   //           [this.state.sarcPatientIdString]: this.state.fileContent[i][this.state.sarcPatientIdString],
      //   //           Pseudonym: this.state.fileContent[i].Pseudonym,
      //   //           type: "replace"
      //   //         })
      //   //       };
      //   //     }
      //   //   }
      //   //   if(affectedColumns.length){
      //   //     newEntries.push({
      //   //       new: this.state.fileContent[i].new,
      //   //       affectedColumns: affectedColumns,
      //   //       writeNew: true,
      //   //       [this.state.sarcPatientIdString]: this.state.fileContent[i][this.state.sarcPatientIdString],
      //   //       Pseudonym: this.state.fileContent[i].Pseudonym,
      //   //       type: "extend"
      //   //     })
      //   //   }
      //   // }
      // }

      // this.setState({newEntries: newEntries});
      // this.setState({contentVisible: true});
    }

  getOldData = (fileKindInformation, fileContent, pairs) => {
    // console.log("getOldData");
    console.log(fileKindInformation.table_name, fileContent, pairs)
    const currentComponent = this;
    const table_name = fileKindInformation.table_name;
    const pseudonyms = Object.keys(fileContent);
    this.setState({loading: true })
    // console.log(fileKindInformation, fileContent, pairs);

    fetch('/sarcbop_patient_data/get_objects_by_pseudonyms', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        pseudonyms: pseudonyms,
        tableName: table_name,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      const comparedData = currentComponent.compareData(fileContent, data.result, fileKindInformation, pairs);
      console.log(comparedData.newDataArr);
      currentComponent.setState({oldData: data.result, loading: false, dataTupleArr: comparedData.dataTupleArr, updatedDataArr: comparedData.updatedDataArr, newDataArr: comparedData.newDataArr});
    });
  }

  compareData = (fileContent, oldData, fileKindInformation, pairs) => {
    console.log("compareData");
    var dataTupleArr = [];
    var updatedDataArr = [];
    var newDataArr = [];
    console.log(Object.keys(fileContent[Object.keys(fileContent)[0]][0]));
    const namesInExport = Object.keys(fileContent[Object.keys(fileContent)[0]][0]);
    var pairsTable = {};
    // var obj = {};
    for (var i in namesInExport) {
      const pair = pairs.find(pair => pair.name_in_export == namesInExport[i]);
      if (pair) {
        pairsTable[namesInExport[i]] = pair;
      }
    }
    var primaryArray = [];
    for (var i in pairsTable) {
      if (pairsTable[i].primary) {
        primaryArray.push(pairsTable[i].name_in_export)
      }
    }
    // console.log(pairsTable, primaryArray);
    const primaries = pairs.filter(pair => pair.primary);
    Object.entries(fileContent).forEach(entry => {
      const [pseudonym, objArr] = entry;
      // console.log(objArr);
      var patient = {}
      if (oldData) {
        patient = oldData.find(pat => pat.pseudonym == pseudonym);
      }
      const tableName = fileKindInformation.table_name;
      // if (patient) console.log(patient[tableName]);
      // console.log(objArr);
      if (patient ) {
        objArr.forEach(obj => {
          var [translatedObj, fieldMask] = this.translateObj(obj, pairsTable);
          translatedObj["pseudonym"] = pseudonym;
          console.log( patient, tableName, translatedObj);
          if (patient[tableName]){
            const oldObjs = patient[tableName].filter(arrObj => {
              // console.log(arrObj);
              var ret = true;
              primaries.forEach(primary => {
                // console.log(obj, primary.name_in_export, obj[primary.name_in_export], arrObj, primary.name_in_db, arrObj[primary.name_in_db])
                // console.log(obj, arrObj)
                // if(obj[primary.name_in_export] != null && arrObj[primary.name_in_db]!= null){
                if (obj[primary.name_in_export] != arrObj[primary.name_in_db]) {
                  ret = false;  
                    // break;
                  // }
                }
                
              })
              return ret;
            });
            if (oldObjs.length > 1) {
              console.log("Object is too long", oldObjs);
            } else if (oldObjs.length == 1){
              // console.log(oldObjs);
              var changed = false;
              var changedFields = [];
              Object.entries(obj).forEach(entry2 => {
                const [nameInExport, fieldValue] = entry2;
                const pt = pairsTable[nameInExport];
                if ((pt) && (nameInExport != "Pseudonym")) {
                  console.log(oldObjs[0][pt.name_in_db], fieldValue);
                  if ((oldObjs[0][pt.name_in_db] != fieldValue) && fieldValue != "") {
                    changed = true;
                    changedFields.push(pt.name_in_db);
                    console.log("changed");
                  }
                  else { 
                    // console.log("not changed");
                    // changed = false;
                    // changedFields.push(pt.name_in_db);
                  }
                }
                // if ((pt) && (nameInExport != "Pseudonym")) {
                //   const nameInDb = pt.name_in_db;
                //   // find(arrObj => arrObj[nameInDb] == fieldValue);
                //   const oldValue = ((patient[tableName]) && (patient[tableName][0])) ? patient[tableName][0][nameInDb] : null;
                //   // console.log(tableName, nameInExport, nameInDb, fieldValue, patient, oldValue);
                //   // patient[tableName].find( )
                //   // patient[tableName][nameInDb]
                // }
              })
              oldObjs[0]["pseudonym"] = pseudonym;
              if (changed) {
                var newObj = {...oldObjs[0]}; 
                // console.log(newObj);
                changedFields.forEach(fieldName => {
                  newObj[fieldName] = translatedObj[fieldName] });
                const tuple = [translatedObj, oldObjs[0], fieldMask, changedFields];
                updatedDataArr.push(newObj);
                dataTupleArr.push(tuple);
              }
            } else if (oldObjs.length == 0){
                const tuple = [translatedObj, {}, fieldMask];
                // console.log(tuple);
                // var newObj = {};
                newDataArr.push(translatedObj);
                dataTupleArr.push(tuple);
            }       
        // const patient[tableName][]
          } else {
            const tuple = [translatedObj, {}, fieldMask];
            dataTupleArr.push(tuple);
            // console.log("test", translatedObj);
            newDataArr.push({pseudonym: objArr[0]["Pseudonym"], ...translatedObj});
          }
        })
      } else {
        // console.log(objArr);
        const tuple = [translatedObj, {}, fieldMask];
        // console.log(obj);
        if (objArr[0]["PID"] != "") {
          var [translatedObj, fieldMask] = this.translateObj(objArr[0], pairsTable);
          // var obj = {};
          // var newObj = {};
          newDataArr.push({pseudonym: objArr[0]["Pseudonym"], ...translatedObj});
          // console.log(objArr[0], {pseudonym: objArr[0]["Pseudonym"], ...translatedObj});
          dataTupleArr.push(tuple);
        }
      }
    })
    // console.log(dataTupleArr);
    return {dataTupleArr: dataTupleArr, updatedDataArr: updatedDataArr, newDataArr: newDataArr, loading: false};
  }

  translateObj = (obj, pairsTable) => {
    var ret = [{}, {}];
    Object.entries(obj).forEach(entry2 => {
      const [nameInExport, fieldValue] = entry2;
      const pt = pairsTable[nameInExport];
      if (pt) {
        ret[0][pt.name_in_db] = fieldValue;
        ret[1][pt.name_in_db] = true;
      }
    })
    return ret;
  }

  handleData = async () => {
    this.setState({contentVisible: true});
  }

  handleGoToPage = (newNumber) => {
    this.setState({i: newNumber});
  }

  handleSave = (arrays, tableName) => {
    this.setState({loading: true});
    this.props.handleSave(arrays, tableName);
  }

  handleChangeRowEntry = (event, ind, column_name) => {
    // console.log("changerow");
    // var newNewEntries = this.state.newEntries;
    // var element = newNewEntries.find(x => x.sarc_patient_id == event.target.name);
    //console.log(element);
    var updatedDataArr2 = [...this.state.updatedDataArr];
    // var newElem = {...this.state.updatedDataArr[ind]};
    // console.log(event.target.value, this.state.dataTupleArr[ind]);
    if (event.target.value == "true") {
      // console.log(ind, column_name, this.state.dataTupleArr[ind][0][column_name]);
      updatedDataArr2[ind][column_name] = (' ' + this.state.dataTupleArr[ind][0][column_name]).slice(1);
    } else {
      // console.log(ind, column_name, this.state.dataTupleArr[ind][1][column_name]);
      updatedDataArr2[ind][column_name] = this.state.dataTupleArr[ind][1][column_name];
    }
    // element.writeNew = Boolean(event.target.value);
    //console.log(element);
    this.setState({updatedDataArr: updatedDataArr2});
  }

  render(){
    // const newData = (this.state.contentVisible) ? 
    //   this.state.newEntries.filter(x => x.type === "new").map(option => {
    //     return (
    //       <ListItem>
    //         <ListItemIcon>•</ListItemIcon>
    //         <ListItemText primary={option.Pseudonym} />
    //       </ListItem>
    //     )
    //   }) : null

    // const extendedData = (this.state.contentVisible) ? 
    //   this.state.newEntries.filter(x => x.type === "extend").map(option => {
    //     return (
    //       <ListItem>
    //         <ListItemIcon>•</ListItemIcon>
    //         <ListItemText primary={option.Pseudonym} />
    //       </ListItem>
    //     )
    //   }) : null
    
    // console.log((this.state.oldData && this.state.oldData.length > 0), );
    // if (this.state.oldData && this.state.oldData.length > 0 && this.state.fileKindInformation && this.state.pairs && this.state.pairs.length > 0) {
      
    //   this.compareData(this.props.fileContent, this.state.oldData, this.state.fileKindInformation, this.state.pairs);
    // }

    var newEntries = [];
    var ind = 0;
    (this.state.dataTupleArr != null) ? this.state.dataTupleArr.forEach(tuple => {
      
      if (tuple[3] && tuple[3].length > 0) {
        tuple[3].forEach(changedObj => newEntries.push({pseudonym: tuple[0].pseudonym, column_name: changedObj, oldValue: tuple[1][changedObj], newValue: tuple[0][changedObj], writeNew: tuple[2][changedObj], ind: ind}));
      }
      // {pseudonym: tuple[0].pseudonym, changedObj};
      // console.log(tuple[2])
      ind++;
    }) : [];
    // console.log(newEntries[0]);
    const saveable = ((this.state.fileKindInformation) && (this.state.updatedDataArr) && (this.state.newDataArr))
    

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Hochgeladene Daten</DialogTitle>
        <DialogContent>
            <p> 
              {/* Folgende neuen Datensätze werden gespeichert:<br/>
              <List>
                {newData}
              </List>
              Bei folgenden Datensätzen werden neue Einträge ergänzt:<br/>
              <List>
                {extendedData}
              </List> */}
              Folgende Datensätze können aktualisiert werden:<br/>
              <EnhancedTable
                title={"Daten"}
                data={newEntries}
                handleExpandChange={()=>{}}
                handleChangeRowEntry={this.handleChangeRowEntry}
                columns={selectionColumns}
                rowsPerPage={25}
                expanded={true}
                orderBy={"Pseudonym"}
              />
            </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <LoadingButton onClick={() => {this.handleSave(this.state.updatedDataArr.concat(this.state.newDataArr), this.state.fileKindInformation.table_name)}}
            color="primary"
            data-cy="data_entry_save"
            loading={this.state.loading}
            disabled={!saveable}
          >
            Speichern
          </LoadingButton>
        </DialogActions>
      </Dialog>

    )
  }
}


export default DataEntryEditDialog;
