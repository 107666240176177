import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from 'react-select';

class TrialBoardSubmissionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedSubmissions: [], allSubmissions: []}
    this.form = React.createRef();
  }

  componentDidMount(props) {
    let currentComponent = this;
    fetch('trial_board_submissions/get_trial_board_submissions', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      const submissions = data.submissions;
      currentComponent.setState({ allSubmissions: submissions});
      if (currentComponent.props.submissionIds) {
        const newSelectedSubmissions = submissions.filter(submission => currentComponent.props.submissionIds.includes(submission.id));
        currentComponent.setState({ selectedSubmissions: newSelectedSubmissions});
      }
    });
  }

  handleSubmissionsChange = event => {
    const selectedIds = event.map(selection => selection.value);
    const newSelectedSubmissions = this.state.allSubmissions.filter(submission => selectedIds.includes(submission.id));
    this.setState({selectedSubmissions: newSelectedSubmissions});
    if (this.props.handleChange) {
      this.props.handleChange(selectedIds);
    }
  }

  render() {
    const { classes, submissions, handleSubmissionsChange } = this.props;
    const submissionOptions = this.state.allSubmissions.map((submission) => {
      return (
        {value: submission.id, label: submission.short}
      )
    });
    if (submissionOptions) {
      const selectedSubmissionIds = this.state.selectedSubmissions.map(submission => submission.id);
      const selectedSubmissionOptions = submissionOptions.filter(so => selectedSubmissionIds.includes(so.value));
      return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Studien</InputLabel>
            <Select
              // defaultValue={}
              isMulti
              name="submissions"
              options={submissionOptions}
              onChange={this.handleSubmissionsChange}
              className="basic-multi-select"
              classNamePrefix="select"
              value={selectedSubmissionOptions}
            />
          </FormControl>
        </div>
      )
    }
    else {
      return (<> Keine Studien gefunden </>);
    }
  }
}

export default TrialBoardSubmissionSelector;
