import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import NewMilestoneDialog from './NewMilestoneDialog.jsx';
import EditMilestoneDialog from './EditMilestoneDialog.jsx';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class MilestonesListDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedSite: this.props.selectedSite, newOpen: false, editOpen: false}
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSite != prevProps.selectedSite) {
      this.setState({selectedSite: this.props.selectedSite});
    }
  }

  handleNewClose = event => {
    this.setState({newOpen: false});
  }

  handleNewOpen = event => {
    this.setState({newOpen: true});
  }

  handleEditOpen = name => event => {
    this.setState({editOpen: true, selectedMilestone: name});
  }

  handleEditClose = event => {
    this.setState({editOpen: false});
  }

  handleNewSave = vals => event => {
    this.props.handleNewSave(vals);
    this.setState({newOpen: false, open: true});
  }

  handleEditSave = vals => event => {
    this.props.handleEditSave(vals);
    this.setState({editOpen: false, open: true});
  }

  render(props) {
    const { classes } = this.props;
    let customMilestonesComponents = null;
    if (this.props.milestones) {
      customMilestonesComponents = this.props.milestones.map(milestone => {
        // const val = {value: this.props.selectedSite.milestones[milestone], label: label};
        // const val = this.props.selectedSite.milestones[milestone];
        return (
            <ListItem button style={{paddingLeft: '30px'}}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={milestone} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={this.handleEditOpen(milestone)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => this.props.handleDelete(milestone)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
        )
      });
    }
    return (
      <>
      { (this.state.newOpen) &&
      <NewMilestoneDialog
        open={this.state.newOpen}
        handleSave={this.handleNewSave}
        handleClose={this.handleNewClose}
      />}
      { (this.state.editOpen) &&
      <EditMilestoneDialog
        open={this.state.editOpen}
        handleSave={this.handleEditSave}
        handleClose={this.handleEditClose}
        name={this.state.selectedMilestone}
      />}
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
          <DialogTitle id="form-dialog-title">Milestones editieren</DialogTitle>
          <Tooltip title="Neues Dokument für die Studie anlegen">
            <IconButton aria-label="Add" onClick={this.handleNewOpen}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <DialogContent >
          <List component="div" disablePadding>
            {customMilestonesComponents}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
  }
}

MilestonesListDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedSite: PropTypes.isRequired,
};

export default withStyles(styles)(withSnackbar(MilestonesListDialog)) ;
