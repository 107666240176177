import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
// import { deepmerge } from '@mui/utils';

export default createTheme({
  palette: {
    mode: 'light',
    // primary: {main: "#1B4E9C"},
    primary: {main: "#1565c0", dark: "#1565c0", light: "#1565c0"},
    secondary: {main: "#82ba29"},
    tertiary: {main: "#004896"},

    // tertiary: {main: "#0277bd"},
    neutral: {main: "#FFFFFF"},
  },
  components: {
    MuiGrid: {
      defaultProps: {
        padding: '2px',
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '2px',
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '5px',
        }
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          paddingTop: '2px',
          paddingBottom: '2px',
          paddingLeft: '5px',
          paddingRight: '5px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          backgroundColor: blue[800],
        }
      }
    }
  },
});
