import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import { IconButton, Menu, MenuItem } from "@mui/material";


export default function ReminderFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleCheckboxChange, readOnly, deadlineNotEditable, deadlineDate } = props;
  return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <TextField
            id="deadline"
            label="Fälligkeitsdatum"
            margin="dense"
            variant="outlined"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            value={obj.deadline.substr(0,10) || deadlineDate}
            error={!obj.deadline}
            disabled={readOnly || deadlineNotEditable}
          />
          <TextField
            id="remind_when"
            label="Erinnerungsdatum"
            margin="dense"
            variant="outlined"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            value={obj.remind_when.substr(0,10)}
            error={!obj.remind_when}
            // disabled={readOnly || deadlineNotEditable}
          />
          <TextField
            margin="dense"
            id="title"
            label="Titel"

            value={obj.title}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!obj.title}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (obj.title != null) }}
            type="string"
          />
          <FormGroup>
            <FormControlLabel
              style={{zIndex: 1}}
              control={
                <Checkbox
                  checked={obj.notify}
                  onChange={handleCheckboxChange}
                  disabled={readOnly}
                />
              }
              name="notify"
              label="Erinnerung per Notification"
            />
            <FormControlLabel
              style={{zIndex: 1}}
              control={
                <Checkbox
                  checked={obj.notify_by_mail}
                  onChange={handleCheckboxChange}
                  disabled={readOnly}
                />
              }
              name="notify_by_mail"
              label="Erinnerung per Mail"
            />
            <FormControlLabel
              style={{zIndex: 1}}
              control={
                <Checkbox
                  checked={obj.notified}
                  onChange={handleCheckboxChange}
                  disabled={true}
                />
              }
              name="notify_by_mail"
              label="Erinnerung wurde versandt"
            />
          </FormGroup>
        </div>
  );
}
