import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from './TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { PropaneSharp } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import AddIcon from "@mui/icons-material/Add";

export default function ViewDialog({Fields, ...props}) {
  console.log(props);
  const [errors, setErrors] = useState([]);

  function objChanged() {
    Object.keys(obj).forEach(key => {
      if (obj[key] != props.obj[key]) {
        return false;
      }
      return true;
    })
  }

  // const [obj, setObj] = useState({...props.obj});
  // (props.options) ? obj = props.obj : obj = [props.obj];
  const obj = (props.options) ? props.obj : [props.obj];
  const selectionVariableName = (props.selectionVariableName) ? props.selectionVariableName : [props.selectionVariableName];
  // const initialSelected = props.options[0].value
  const [selectedIndex, setSelectedIndex] = useState(0);
  console.log(selectedIndex);
  const i = 0;
  
  function handleChange(event, meta) {
    console.log(event, meta);
    // let newObj = obj;
    // const target = event.target;
    // let value = target.value;
    // if (target.type == "checkbox") {
    //   const name = target.name;
    //   newObj[name] = meta;
    // } else {
    //   const name = target.id || target.name;
    //   newObj[name] = value;
    // }
    // // setObj({...newObj});
    setSelectedIndex(props.options.findIndex(opt => opt.value == event.target.value));
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const currentComponent = this;
    // console.log(event.target.value);
    console.log(obj);
    setSelectedIndex(obj.findIndex(x => x[selectionVariableName] == value));
  };

  const handleChangeSelect = (selected) => {
    console.log(selected);
  }
  // const handleOpenNewObject = props.handleOpenNewObject;
  // console.log(handleOpenNewObject);

  function isSuperset(set, subset) {
    for (const elem of subset) {
      if (!set.has(elem)) {
        console.log(elem);
        return false;
      }
    }
    return true;
  }

  function onCreateOption(test) {
    let newObj = {...obj};
    newObj["ecrf_trial_configuration_id"] = value;
    // this.setState({inputValue: newValue});
    setObj({...newObj});
  }

  function handleInputValueChange(newValue, actionMeta) {
    if ((actionMeta.action != "input-blur") && (actionMeta.action != "menu-close")) {
      console.log(newValue, actionMeta);
      this.setState({inputValue: newValue});
    }
  }

  function handleInput(event) {
    // detects errors in inputfields
    const target = event.target;
    const name = target.id || target.name;
    if (!target.validity.valid) {
      if (!errors.includes(name)) {
        setErrors([...errors, name]);
      }
    } else {
      // resets errors
      if (errors.includes(name)) {
        let temp = errors
        temp.splice(temp.indexOf(name), 1)
        setErrors([...temp]);
      }
    }
  }

  function addObjValue(field, value) {
    let newObj = obj;
    newObj[field] = value;
    setObj({ ...newObj });
  }

  function handleOpenNewObject() {
    console.log("test");
    // () => props.handleOpenNewObject;
  }

  // const filledFields = [];
  // for(var key in obj){
  //   if (obj[key]) {
  //     filledFields.push(key);
  //   }
  // }
  // const filledFields = Object.keys(obj).map(key => (obj[key] != null) && (obj[key] != ""));

  //obj.map(x => x.sh)

  // const val = (props.options) ? props.options.find(opt => opt.value == selectedIndex) : null;
  console.log(props, props.options);
  const val = (props.options && props.options.length > 0) ? props.options[selectedIndex].value : null;
  const label = (props.options && props.options.length > 0) ? props.options[selectedIndex].label : "";
  const proType = label.substr(0,label.search(" - "));
  // console.log(label, proType, obj, obj[selectedIndex]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen={props.fullScreen}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      PaperProps={props.paperProps}
    >
      <Box
        display="flex"
        flexDirection="row"
      >
        <DialogTitle id="form-dialog-title">
          {props.title}
        </DialogTitle>
        {/* <IconButton style= {{float: "right"}} onClick={handleOpenNewObject}>
          <><AddIcon/></>
        </IconButton> */}
        {(props.options && props.options.length > 1) ?
        <TextField
          margin="dense"
          id="selected_pro"
          // label="Bogennummer"
          label={props.selectionBoxTitle}
          value={val}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          // required={props.requiredFields.includes("short")}
          // InputProps={{
          //   readOnly: false,
          // }}
          type="select"
          options={props.options}
          size="small"
          defaultValue={val ? val.value : null}
        ></TextField> : null
        }
      </Box>
      <DialogContent>
        <Fields
          obj={(obj) ? obj[selectedIndex] : {}} handleChange={handleChange} handleOpenNewObject={props.handleOpenNewObject} handleSelectChange={handleSelectChange} handleInputValueChange={handleInputValueChange} onCreateOption={onCreateOption} handleInput={handleInput} addObjValue={addObjValue} requiredFields={props.requiredFields} errors={errors} additionalData={props.additionalData} selectOptions={props.selectOptions} readOnly={props.disabled} originalObj={props.obj} objChanged={objChanged} admin={props.admin} proType={proType} 
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// export default ViewDialog;
