import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import { IconButton, Menu, MenuItem } from "@mui/material";

// handleChange2 = (event, checked) => {
//   // console.log(event.target.name);
//   // console.log(checked);
//   this.setState({[event.target.name]: checked});
// }

export default function RoleFields(props) {
  const { obj, handleChange, handleChange2, handleChangeMultiple, handleChangeCheckbox, permArr, readOnly } = props;
  const permComponents = permArr.map(permName => {
    const readString = permName + "_read";
    const updateString = permName + "_update";
    const createString = permName + "_create";
    const deleteString = permName + "_delete";
    if(obj["sarcbop_admin"] == null){
      obj["sarcbop_admin"] = false;
    }
    if(obj["heroes_aya_admin"] == null){
      obj["heroes_aya_admin"] = false;
    }
    console.log(obj["sarcbop_admin"]);
    return (
      <Box>
        <Typography variant="h6" noWrap>{permName}</Typography>
        <div>
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj[readString]}
                onChange={handleChangeCheckbox}
              />
            }
            label="Read"
            name={readString}
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj[createString]}
                onChange={handleChangeCheckbox}
              />
            }
            label="Create"
            name={createString}
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj[updateString]}
                onChange={handleChangeCheckbox}
              />
            }
            label="Update"
            name={updateString}
          />
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj[deleteString]}
                onChange={handleChangeCheckbox}
              />
            }
            label="Delete"
            name={deleteString}
          />
        </div>
        
      </Box>
    )
  })
  return (

        <div style={{backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px'}}>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            value={obj.name}
            fullWidth
            autoFocus
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!obj.name}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (obj.name != null) }}
            type="string"
          />
          {permComponents}
          <Typography variant="h6" noWrap>sarcbop_admin</Typography>
        <div>
          
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj["sarcbop_admin"]}
                onChange={handleChangeCheckbox}
              />
            }
            // label="Delete"
            name={"sarcbop_admin"}
          />
        </div>
        <Typography variant="h6" noWrap>heroes_aya_admin</Typography>
        <div>
          
          <FormControlLabel
            style={{zIndex: 1}}
            control={
              <Checkbox
                checked={obj["heroes_aya_admin"]}
                onChange={handleChangeCheckbox}
              />
            }
            // label="Delete"
            name={"heroes_aya_admin"}
          />
        </div>
        </div>
        
        
  );
}
