import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from './TextField.jsx';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import InstitutionSelector from './InstitutionSelector.jsx';
import StatusCheckboxes from './StatusCheckboxes.jsx';

class EditSiteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, id: this.props.selectedSite.id, marvin_id: this.props.selectedSite.marvin_id, name: this.props.selectedSite.name, inst_description: this.props.selectedSite.inst_description, selectedInstitutionId: this.props.selectedSite.institution_id, siteStatuses: {}, selectedSiteStatus: {}, selectedSiteStatusObject: null, selectedSite: {}, comment: this.props.selectedSite.comment, show: this.props.selectedSite.show};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSite.id !== prevProps.selectedSite.id) {
      const currentSelectedStatuses = this.state.siteStatuses.filter(status => status.id == this.props.selectedSite.site_status_id);
      const currentSelectedStatus = {value: currentSelectedStatuses[0].id, label: currentSelectedStatuses[0].text, color: currentSelectedStatuses[0].color};
      this.setState({id: this.props.selectedSite.id, marvin_id: this.props.selectedSite.marvin_id, name: this.props.selectedSite.name, inst_description: this.props.selectedSite.inst_description, selectedInstitutionId: this.props.selectedSite.institution_id, selectedSiteStatus: currentSelectedStatus, comment: this.props.selectedSite.comment, show: this.props.selectedSite.show});

      // for (var i = 0; i < this.state.site_statuses.length; i++) {
      //   if (currentComponent.props.selectedSite.site_status_id == data.site_statuses[i].id) {
      //     currentComponent.setState({selectedSiteStatus: {value: data.site_statuses[i].id, label: data.site_statuses[i].text, color: data.site_statuses[i].color }});
      //   }
      // }
    }
  }

  componentDidMount(props) {
    let currentComponent = this;
    // const departments = this.state.departments;
    fetch('/site_selection/get_site_statuses')
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      var statusList = [];
      for (var i = 0; i < data.site_statuses.length; i++) {
        statusList.push({value: data.site_statuses[i].id, label: data.site_statuses[i].text + " (" + data.site_statuses[i].explanation + ")", color: data.site_statuses[i].color});
        if (currentComponent.props.selectedSite.site_status_id == data.site_statuses[i].id) {
          currentComponent.setState({selectedSiteStatus: {value: data.site_statuses[i].id, label: data.site_statuses[i].text, color: data.site_statuses[i].color }});
        }
      }
      currentComponent.setState({ siteStatuses: data.site_statuses, siteStatusList: statusList});
    });
    this.setState({marvin_id: this.props.selectedSite.marvin_id, name: this.props.selectedSite.name, inst_description: this.props.selectedSite.inst_description, selectedInstitutionId: this.props.selectedSite.institution_id});
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleChange2 = (event, checked) => {
    // console.log(event.target.name);
    // console.log(checked);

    this.setState({[event.target.name]: checked});
  }

  handleInstitutionChange = event => {
    let currentComponent = this;
    // console.log("event:");
    // console.log(event);
    // const target = event.target;
    // const value = target.value;
    currentComponent.setState({ selectedInstitutionId: event.value });
  };

  onStatusChange = (event) => {
    let selectedSiteStatusObject = this.state.siteStatuses[this.state.siteStatuses.findIndex(status => status.id == event.value)];
    this.setState({selectedSiteStatus: event, selectedSiteStatusObject: selectedSiteStatusObject});
    // this.props.handleChange(event, selectedSiteStatusObject);
    // this.onSubmit(event);
    // this.onSubmit(event)
    // this.form.submit()
    // this.refs.parentForm.submit();
    // { this.form.submit()}
    // handleSubmit(event);
  }

  handleClose = event => {
    this.setState({marvin_id: this.props.selectedSite.marvin_id, name: this.props.selectedSite.name, inst_description: this.props.selectedSite.inst_description, selectedInstitutionId: this.props.selectedSite.institution_id});
    this.props.handleClose();
  }

  render(props) {
    const saveable = this.state.selectedInstitutionId && this.state.inst_description && !isNaN(this.state.marvin_id);
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Zentrum editieren</DialogTitle>
        <DialogContent>
        <Grid container direction="row" justify="start" alignItems="start">
          <Grid item xs={12} style={{backgroundColor: blue[500]}}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">

              <Typography variant="h8" color="inherit" noWrap style={{marginLeft: 5}}>
                Studienspezifisch
              </Typography>
            </Box>
            <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
              <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
                <InputLabel
                  filled
                  animated
                  shrink
                  margin="dense"
                  variant="outlined"
                  error={!this.state.selectedInstitutionId}
                >
                  Institution
                </InputLabel>
                <InstitutionSelector
                  autoFocus
                  trial={this.props.trialId}
                  selectedInstitutionId={this.state.selectedInstitutionId}
                  handleChange={this.handleInstitutionChange}
                  id="institution_id"
                />
              </FormControl>
              <TextField
                autoFocus
                margin="dense"
                id="inst_description"
                label="Institution Beschreibung"
                onChange={this.handleChange}
                fullWidth
                variant="outlined"
                value={this.state.inst_description}
                error={!this.state.inst_description}
                style={{zIndex: 1}}
                InputLabelProps={{ shrink: (this.state.inst_description != null) }}
                type="string"
              />
              <TextField
                margin="dense"
                id="marvin_id"
                label="Zentrum ID"
                type="number"
                onChange={this.handleChange}
                fullWidth
                variant="outlined"
                value={this.state.marvin_id}
                error={(isNaN(this.state.marvin_id) && this.state.marvin_id != "")}
                style={{zIndex: 1}}
                InputLabelProps={{ shrink: (this.state.marvin_id != null) }}
              />
              <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 998}}>
                <InputLabel
                  filled
                  animated
                  shrink
                  margin="dense"
                  variant="outlined"
                  error={!this.state.selectedInstitutionId}
                >
                  Status
                </InputLabel>
                <Select
                  value={ this.state.selectedSiteStatus }
                  // defaultValue={"Bitte wählen"}
                  onChange={this.onStatusChange}
                  options={this.state.siteStatusList}
                  // styles={colourStyles}
                  // menuIsOpen={true}
                  // className={"bg-warning"}
                />
              </FormControl>
              <TextField
                id="comment"
                label="Kommentar"
                onChange={this.handleChange}
                multiline
                // className={classes.textField}
                margin="dense"
                variant="outlined"
                value={this.state.comment}
                fullWidth
                style={{zIndex: 1}}
                InputLabelProps={{ shrink: (this.state.comment != null) }}
                type="text"
              />
              <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 997}}>
                <FormControlLabel
                  style={{zIndex: 1}}
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.state.show}
                      onChange={this.handleChange2}
                    />
                  }
                  label="Anzeigen"
                  name="show"
                />
              </FormControl>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.props.handleSave({
            id: this.state.id,
            marvin_id: this.state.marvin_id,
            name: this.state.name,
            inst_description: this.state.inst_description,
            show: this.state.show,
            institution_id: this.state.selectedInstitutionId,
            site_status_id: this.state.selectedSiteStatus.value,
            comment: this.state.comment,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditSiteDialog;
