import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import moment from 'moment'
import "moment/locale/de";

moment.locale("de")

import ProcessStepFields from './ProcessStepFields.jsx';

class EditProcessStepDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { obj: props.selectedProcessStep, hasDeadline: (props.selectedProcessStep.deadline_days) ? true : false };
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastName !== prevProps.lastName) {
      this.setState({ inputValue: this.props.lastName, last_name: this.props.lastName });
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    if ((name == "deadline_days" || name == "planned_duration") && this.props.previousProcessStep && (((this.props.previousProcessStep.status == "done") && this.props.previousProcessStep.date_status) || this.props.previousProcessStep.date_deadline)) {
      if ((this.props.previousProcessStep.status == "done") && this.props.previousProcessStep.date_status) {
        newObj["date_deadline"] = moment(this.props.previousProcessStep.date_status).add(value, "days").toISOString().substr(0,10);
      } else if (this.props.previousProcessStep.date_deadline) {
        newObj["date_deadline"] = moment(this.props.previousProcessStep.date_deadline).add(value, "days").toISOString().substr(0,10);
      }
    } else if ((name == "deadline_days" || name == "planned_duration") && (this.props.previousProcessStep == null)) {
      newObj["date_deadline"] = moment(this.state.obj.date_status).add(value, "days").toISOString().substr(0,10);
    }
    currentComponent.setState({ obj: newObj });
  };

  handleChange2 = (event, meta) => {
    const value = event.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    this.setState({ obj: newObj });
  }

  handleChangeDate = (date, name) => {
    let newObj = {...this.state.obj};
    newObj[name] = moment(date).toISOString().substr(0,10);
    if (this.props.previousProcessStep && ((this.props.previousProcessStep.date_status && (this.props.previousProcessStep.status == "done")) || this.props.previousProcessStep.date_deadline)) {
      let date1 = null;
      if (this.props.previousProcessStep.date_status) {
        date1 = new moment(this.props.previousProcessStep.date_status);
      } else if (this.props.previousProcessStep.date_deadline) {
        date1 = new moment(this.props.previousProcessStep.date_deadline);
      }
      const date2 = new moment(date);
      let momentDiff = moment.duration(date2.diff(date1)).days();
      let difference = Math.abs(parseInt(momentDiff));
      if (newObj.deadline_days) {
        newObj["deadline_days"] = difference;
      } else if (newObj.planned_duration) {
        newObj["planned_duration"] = difference;
      }
    } else if (this.props.previousProcessStep == null) {
      const date1 = new moment(this.state.obj.date_status);
      const date2 = new moment(date);
      let momentDiff = moment.duration(date2.diff(date1)).days();
      let difference = Math.abs(parseInt(momentDiff));
      if (newObj.deadline_days) {
        newObj["deadline_days"] = difference;
      } else if (newObj.planned_duration) {
        newObj["planned_duration"] = difference;
      }
    }
    // console.log(newObj, moment.duration(moment(this.props.previousProcessStep.date_change).diff(moment(date))).days());
    this.setState({obj: newObj});
  };

  handleHasDeadlineChange = event => {
    let newObj = {... this.state.obj};
    newObj.date_deadline = null;
    newObj.deadline_days = null;
    this.setState({hasDeadline: (this.state.hasDeadline) ? false : true});
  }

  handleSave = (state) => {
    // let date_status = moment(this.state.obj.date_status).toString();
    // let deadline = moment(this.state.obj.deadline).toString();
    // console.log(start_time, end_time);
    this.props.handleSave(this.state.obj);
    // this.props.handleSave(this.state);
    // this.setState({last_name: null, first_name: null, title: null, phone_number: null, mobile_number: null, fax_number: null, email: null, function: null});
  }

  render(props) {
    const { taskStatusList } = this.props;
    const saveable = this.state.obj.name && this.state.obj.status && this.state.obj.date_status && (this.state.obj.deadline_days || this.state.obj.planned_duration);
    const selectableStepStatusList = this.props.stepStatusList.filter(ss => (ss.value == "done" || ss.value == "active"));
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '100%'}}}
      >
        <DialogTitle id="form-dialog-title">Prozessschritt editieren</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} >
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <ProcessStepFields
                  processStep={this.state.obj}
                  handleChange={this.handleChange}
                  handleChange2={this.handleChange2}
                  handleChangeDate={this.handleChangeDate}
                  readOnly={false}
                  dateStatusEditable={(this.props.previousProcessStep != null) && (this.props.previousProcessStep.status == "done") || (this.state.obj.status != this.props.selectedProcessStep.status)}
                  stepStatusList={this.props.stepStatusList}
                  taskStatusList={this.props.taskStatusList}
                  hasDeadline={this.state.hasDeadline}
                  handleHasDeadlineChange={(this.props.previousProcessStep) ? this.handleHasDeadlineChange : null}
                  statusEditable={this.props.statusEditable}
                  selectableStepStatusList={selectableStepStatusList}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={!saveable}
            onClick={() => this.handleSave(this.state)}
            color="primary"
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditProcessStepDialog;
