import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import TextField from './TextField.jsx';
import EnhancedTable from './EnhancedTable.jsx';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const camelize = function camelize(str) {
  return str.replace(/\W+(.)/g, function(match, chr)
   {
        return chr.toUpperCase();
    });
}

export default function SitesMilestonesTableReport(props) {
  // const classes = useStyles();
  const { classes, sites, unlocked, expanded, handleExpandChange, handleLockChange, handleMilestoneChange } = props;
  const milestoneNames = Array.from((new Set(sites.map(site => {
    return Object.entries(site.milestones).map(milestone => milestone[0]);
  }).flat(1))));
  const columns = [
    {
      id: "site_id",
      label: "Site ID",
      numeric: false,
      padding: 'none',
      type: "hidden",
    },
    {
      id: "marvin_id",
      label: "ZID",
      numeric: false,
      padding: 'none',
    },
    {
     id: "site_name",
     label: "Zentrum",
     numeric: false,
     padding: 'none',
    },
  ];
  // console.log(props.standardMilestones, props.customMilestones)
  const milestoneColumns = props.standardMilestones.map(ms => {
    // console.log("ms",ms);
    // console.log(ms.name)
    // console.log(props.customMilestones);
    return {
      id: camelize(ms.name),
      label: ms.name,
      numeric: false,
      disablePadding: false,
      type: "milestoneSelect",
    }
  }).concat(props.customMilestones.map(msName => {
    // console.log("msName",msName);
    return {
      id: camelize(msName),
      label: msName,
      numeric: false,
      disablePadding: false,
      type: "milestoneSelect",
    }
  }))
  // const milestoneColumns = milestoneNames.map(milestoneName => {
  //   return {
  //     id: camelize(milestoneName),
  //     label: milestoneName,
  //     numeric: false,
  //     disablePadding: false,
  //     type: "milestoneSelect",
  //   }
  // });
  // const columnsWithMilestones = Object.assign([...columns], milestoneColumns);
  const columnsWithMilestones = [...columns, ...milestoneColumns];
  const data = sites.map(site => {
    let ret = {};
    for (let i in Object.entries(site.milestones)) {
      ret[camelize(Object.entries(site.milestones)[i][0])] = Object.entries(site.milestones)[i][1];
    };
    ret["site_id"] = site.id;
    ret["marvin_id"] = site.marvin_id;
    ret["site_name"] = site.inst_description;
    return ret;
  });
  return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <EnhancedTable
            title={"Zentren - Milestones"}
            data={data}
            selectedIds={[]}
            // selectedObjects={this.state.selectedNotes}
            columns={columnsWithMilestones}
            // handleAddClick={this.handleNewDocumentClick}
            // onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => handleChange(rowData, allRowsSelected, allObjectsSelected)}
            handleLockChange={handleLockChange}
            unlocked={unlocked}
            rowsPerPage={50}
            collapseEmpty
            expanded={true}
            handleExpandChange={handleExpandChange}
            handleUpdateMilestones={handleMilestoneChange}
          />
        </div>
  );
}
