import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
// import { withTheme } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
// import { createTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import blue from '@mui/material/colors/blue';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import theme from './theme.js';
import ability from './ability.js';


// const theme = createTheme({
//   palette: {
//     primary: {main: blue[200]},
//     secondary: {main: blue[800]},
//   },
// });

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

function handleChange(props, event, newValue) {
  console.log(newValue, event, props);
  // console.log(props.controller, newValue, newValue);
  if (newValue == "index") {
    window.location.assign('/' + props.controller + '/');
  }
  if (newValue == "index_trials" || (newValue == "index_klifos") || newValue == "show_calendar" || newValue == "show_timeline" || newValue == "configuration" || newValue == "accounts") {
    window.location.assign('/' + props.controller + '/' + newValue);
  }
  if (newValue == "audit_trail" && !props.controllerId) {
    window.location.assign('/' + props.controller + '/audit_trail/');
  }
  if (newValue == "show") {
    window.location.assign('/' + props.controller + '/');
  }
  if (newValue == "show" && props.controllerId) {
    window.location.assign('/' + props.controller + '/' + props.controllerId + '/');
  }
  if (!newValue.includes("show") && !newValue.includes("index") && props.controllerId) {
    window.location.assign('/' + props.controller + '/' + props.controllerId + '/' + newValue);
  }
  if (newValue == "show_stats") {
    window.location.assign('/' + props.controller + '/' + newValue);
  }
  if (newValue.includes("sarcbop")){
    window.location.assign('/' + newValue);
  }
}

export default function Navbar(props) {
  // const classes = useStyles();

  const { controller, action } = props;
  // console.log(props);
  const indicatorColor = "secondary";
  const tColor = "neutral";
  console.log(theme.palette)
  return (
    // <div style={{justifyContent:'center', opacity: 0.99}}>
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="primary">
        <Tabs value={action} onChange={(event, newValue) => handleChange(props, event, newValue)} variant="scrollable" indicatorColor={indicatorColor} textColor={tColor}>
          {(controller != "trial_boards" && controller != "calendars" && controller != "time_registration" && controller != "ecrf_management" && controller != "sarc_patients") &&
            <Tab label="Index" value="index" />
          }
          {(controller == "calendars") &&
            <Tab label="Kalenderansicht" value="show_calendar" />
          }
          {(controller == "calendars") &&
            <Tab label="Abwesenheitsübersicht" value="show_timeline" />
          }
          {controller == "trial_boards" &&
            <Tab label="Index Studienboards" value="index" />
          }
          {controller == "trial_boards" &&
            <Tab label="Index Studien" value="index_trials" />
          }
          {controller == "trial_boards" &&
            <Tab label="Index KliFoDis" value="index_klifos" />
          }
          {controller == "sarc_patients" &&
            <Tab label="Index Patienten" value="sarcbop"/>
          }
          {(controller != "address_book" && controller != "trial_boards" && controller != "trial_presentation" && controller != "time_registration" && controller != "ecrf_management") &&
            <Tab label="Übersicht" value="show" disabled={!props.controllerId} />
          }
          {controller == "sarc_patients" &&
            <Tab label="Statistiken" value="sarcbop/statistics/"/>
          }
          {controller == "sarc_patients" &&
            <Tab label="Patienten Filter" value="sarcbop/filter"/>
          }
          {controller == "sarc_patients" ?
            ability.can('read', 'sarbop_admin') ||  ability.can('read', 'heroes_aya_admin')?
            <Tab label="Zugriffsanforderung" value="sarcbop/request_access"/>
              : null
            : null
          }
          {controller == "sarc_patients" ?
            ability.can('read', 'sarbop_admin') ||  ability.can('read', 'heroes_aya_admin')?
            <Tab label="Audit Trail" value="sarcbop/audit_trail"/>
              : null
            : null
          }
          {(controller == "time_registration") &&
            <Tab label="Zeiterfassung" value="show" />
          }
          {(controller == "time_registration") &&
            <Tab label="Abfrage" value="show_stats" />
          }
          {controller == "trials" &&
            <Tab label="Personen" value="people" disabled={!props.controllerId} />
          }
          {controller == "trials" &&
            <Tab label="Prozesse" value="ethics_processes" disabled={!props.controllerId} />
          }
          {controller == "trials" &&
            <Tab label="Merkzettel" value="trial_tasks" disabled={!props.controllerId} />
          }
          {controller == "trials" &&
            <Tab label="Verträge etc." value="contracts" disabled={!props.controllerId} />
          }
          {/* {controller == "trials" &&
                <Tab label="Institutionen" value="institutions"  disabled={!props.controllerId}/>
              } */}
          {controller == "trials" &&
            <Tab label="Zentren" value="centers" disabled={!props.controllerId} />
          }

          {controller == "trials" ?
            ability.can('read', 'Archive') ?
              <Tab label="Archiv" value="archives" disabled={!props.controllerId} />
              : null
            : null
          }
          {(controller == "trials" && false) &&
            <Tab label="Konfiguration" value="configuration" disabled={!props.controllerId} />
          }
          {(controller == "ecrf_management" && action != "user_overview") && 
            <Tab label="Personen" value="index" disabled={false} />
          }
          {(controller == "ecrf_management" && action != "user_overview") && 
            <Tab label="Accounts" value="accounts" disabled={false} />
          }
          {(controller == "ecrf_management" && action != "user_overview") && 
            <Tab label="Studien und Konfiguration" value="configuration" disabled={false} />
          }

          {/* <Tab label="Audit Trail" value="audit_trail"/> */}
        </Tabs>
      </AppBar>
      <TabContainer style={{ padding: '0px' }}></TabContainer>
    </ThemeProvider >
    // </div>
  );
}
