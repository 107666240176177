import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcTissueSampleFields(props) {
  // const classes = useStyles();
  console.log();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            {/* <TextField
              margin="dense"
              id="Pseudonym"
              label="BMBHPID"
              value={obj.pseudonym}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            /> */}
            <TextField
              margin="dense"
              id="sampleid"
              label="Proben-ID"
              value={obj.sampleid}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            {/* <TextField
              margin="dense"
              id="histology_date"
              label="Datum Histologie"
              value={moment(obj.histology_date).format("MM-DD-YYYY")}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="date"
              size="small"
            /> */}
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="sample_status"
                label="Probenstatus"
                value={obj.sample_status}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="quantity"
                label="Menge"
                value={obj.quantity}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="integer"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
            <TextField
              margin="dense"
              id="unit"
              label="Einheit"
              value={obj.unit}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="sample_size"
              label="Probengröße"
              value={obj.sample_size}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="sample_orientation"
              label="Probenorientierung"
              value={obj.sample_orientation}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            </Box>
            <TextField
              margin="dense"
              id="heroes_aya_study"
              label="Studie HEROESAYA"
              value={obj.heroes_aya_study}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="sarcbop_study"
              label="Studie SarcBop "
              value={obj.sarcbop_study}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <Box
              display="flex"
              flexDirection="row"
            >
            <TextField
              margin="dense"
              id="biopsy"
              label="Biopsie"
              value={obj.biopsy}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="operation_resectate"
              label="OP-Rescktate"
              value={obj.operation_resectate}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="chemo_therapy"
              label="Chemo"
              value={obj.chemo_therapy}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="radiotherapy"
              label="Radiatio"
              value={obj.radiotherapy}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
            <TextField
              margin="dense"
              id="localisation"
              label="Lokalisation"
              value={obj.localisation}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            </Box><Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="icd_03"
                label="ICD-03"
                value={obj.icd_03}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="resection_time_op"
                label="Resektionszeit-OP"
                value={moment(obj.resection_time_op).format("DD.MM.YYYY, h:mm:ss")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="op_pick_up_time"
                label="Abholzeit im OP"
                value={moment(obj.op_pick_up_time).format("DD.MM.YYYY, HH:mm:ss")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="freezing_time"
                label="Einfrierzeit"
                value={moment(obj.freezing_time).format("DD.MM.YYYY, h:mm:ss")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="arrival_time"
                label="Ankunftszeitpunkt"
                value={moment(obj.arrival_time).format("DD.MM.YYYY", "HH:mm:ss")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="physician"
                label="Arzt"
                value={obj.physician}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="fa"
                label="FA"
                value={obj.fa}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="ta"
                label="TA"
                value={obj.ta}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="dignity"
                label="Dignität"
                value={obj.dignity}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              </Box>
              <Box
              display="flex"
              flexDirection="row"
              >
                  <TextField
                margin="dense"
                id="information_nct_gwb"
                label="Aufklärung NCT GWB"
                value={obj.information_nct_gwb}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="checkbox"
                size="small"
              />
                <TextField
                margin="dense"
                id="information_sarcbop"
                label="Aufklärung SarcBOP"
                value={obj.information_sarcbop}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="checkbox"
                size="small"
              />
                <TextField
                margin="dense"
                id="information_master"
                label="Aufklärung MASTER"
                value={obj.information_master}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="checkbox"
                size="small"
              />
                <TextField
                margin="dense"
                id="vital_tissue_patho"
                label="Vitales Gewebe Patho"
                value={obj.vital_tissue_patho}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="checkbox"
                size="small"
              />
                <TextField
                margin="dense"
                id="vital_tissue_ortho"
                label="Vitales Gewebe Ortho"
                value={obj.vital_tissue_ortho}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="checkbox"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="diagnosis"
                label="Diagnose"
                value={obj.diagnosis}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="entry_nr"
                label="EingangsNr"
                value={obj.entry_nr}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <TextField
              margin="dense"
              id="comment"
              label="Kommentar"
              value={obj.comment}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
          </Grid>
        </Grid>
  );
}
