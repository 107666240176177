import React from 'react';
// import styled from 'styled-components';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import Avatar from '@mui/material/Avatar';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Notification from './Notification.jsx'
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider } from 'notistack';
import { withSnackbar } from 'notistack';

// import Link from '@mui/material/Link';

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

function NotificationItem(props) {
  // console.log(props)
  if (props.showread == props.read) {
    return (
      <Notification id={props.id} title={props.title} content={props.content} link={props.link} read={props.read} showread={props.showread} handleSeen={props.handleSeen} ind={props.ind} />
    )
  }
  return null;
};

const menuStyle = {
  width: '1000',
};

const blueAvatar = {
  margin: 0,
  color: '#fff',
  backgroundColor: blue[200],
};

const checkIconStyle = {
  // fontSize: "small",
  // transform: "translate(-50%, 50%)",
}

class NotificationBell extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    // this.handleSeen = this.handleSeen.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleSeen = this.handleSeen.bind(this);
    this.form = React.createRef();
  };

  // componentDidMount = (props) => {
  //   let currentComponent = this;
  //   currentComponent.update();
  //   // console.log("state: " + this.props.notifications);
  //   // console.log(data)
  // };

  state = {
    anchorEl: null,
  };

  // updateNotifications

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = (e) => {
    this.setState({ anchorEl: null });
  };

  handleSeen = (notification) => {
    // let currentComponent = this;
    const notifications = this.props.notifications;
    // const { id } = e.props.ind
    // // const { id } = e.target;
    // console.log(notifications[id])
    // e.preventDefault();
    // console.log(this);
    // this.setState({read: true})
    // this.setState({});
    // this.setState({notifications: tnis.notifications});
    // notification.read = true;
    fetch('/notifications/'+notification.id+'/mark_read', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        firstParam: 'yourValue',
        secondParam: 'yourOtherValue',
      }),
      credentials: 'include',
    })
    notifications[notification.props.ind].read = true;
    this.setState({notifications, unread_num: this.state.unread_num-1})
  }



  render(props) {
    const { anchorEl } = this.state;
    const classes = this.props;
    const notifications = this.props.notifications;
    // // console.log(this.state)
    // if (this.props.notifications) {
    //   const newNotifications = this.props.notifications.map((notification) =>
    //     <NotificationItem key={"inner_grid_" + notification.id.toString()} id={notification.id} title={notification.title} content={notification.content} link={notification.link} read={notification.read} showread={false} />
    //   );
    // } else {
    //   <CircularProgress className={classes.progress} />
    // }
    //
    // if (this.props.notifications) {
    //   const readNotifications = this.props.notifications.map((notification) =>
    //     <NotificationItem key={"inner_grid_" + notification.id.toString()} id={notification.id} title={notification.title} content={notification.content} link={notification.link} read={notification.read} showread={true} />
    //   );
    // } else {
    //   <CircularProgress className={classes.progress} />
    // }
    // console.log(newNotifications)
    const notificationsComponents = (notifications && notifications.length > 0) ? notifications.map((notification, index) =>
      <NotificationItem key={"inner_grid_" + notification.id.toString()} id={notification.id} title={notification.metadata.title} content={notification.metadata.content} link={notification.metadata.link} read={notification.read} showread={false} handleSeen={() => this.props.handleSeen(notification.id)} ind={index} />
    ) : null;

    return (
        <>
          {(this.props.unread_num > 0) ? (
            <IconButton
              color="inherit"
              onClick={this.handleClick}
            >
              <Badge color="secondary" badgeContent={this.props.unread_num} >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              onClick={this.handleClick}
            >
              <NotificationsIcon />
            </IconButton>
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            style={menuStyle}
            menuListProps={{width: '500px'}}
            >
            <Grid container style={{width: '700px'}}>
              {notificationsComponents}
              
              <Divider />
              {this.props.notifications ? (
                this.props.notifications.map((notification, index) =>
                  <Grid item xs={11} key={"inner_grid_" + notification.id.toString()}>
                    <NotificationItem key={"inner_grid_" + notification.id.toString()} id={notification.id} title={notification.metadata.title} content={notification.metadata.content} link={notification.metadata.link} read={notification.read} showread={true}  handleSeen={() => this.props.handleSeen(notification.id)} ind={index} />
                  </Grid>
                )
              ) : (
                null
              )}
            </Grid>
          </Menu>
        </>
      // <>
      // </>
    );
  }
}

// export default withSnackbar(NotificationBell);
export default NotificationBell;
