import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';

import SubmissionResolution from './SubmissionResolution.jsx';
import EditSubmissionResolutionDialog from './EditSubmissionResolutionDialog.jsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: "#f5f5f5",
  },
}));


export default function SubmissionResolutionList(props) {
  const classes = useStyles();
  const { assocs, editOpen, handleEditClick, handleClose, handleSave, locked } = props;
  const resolutionItems = assocs.sort(function(a,b) {
    if (a.trial_board.board_date < b.trial_board.board_date) {
      return 1;
    }
    if (a.trial_board.board_date >= b.trial_board.board_date) {
      return -1;
    }
  }).map(assoc => {
    return (
      <>
        <EditSubmissionResolutionDialog assoc={assoc} open={editOpen[assoc.id]} handleClose={handleClose} handleSave={handleSave} />
        <SubmissionResolution assoc={assoc} handleEditClick={handleEditClick} locked={locked} />
      </>
    )
  });
  return (
    <>
      {resolutionItems}
    </>
  );
}
