import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

class AddTrialCustomDataDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, obj: (props.csf) ? props.csf : {}};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {

  }

  componentDidMount(props) {
    // let currentComponent = this;
    // this.setState({});
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let obj = {...this.state.obj};
    obj[name] = value;
    // console.log(obj);
    currentComponent.setState({ obj: obj });
  };

  handleClose = event => {
    this.props.handleClose();
  }

  render(props) {
    const {obj} = this.state;
    const saveable = obj.label != null && obj.label != "";
    // console.log(obj);
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} >
              <TextField
                margin="dense"
                id="label"
                label="Label"
                value={obj.label}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={this.handleChange}
                InputProps={{
                  readOnly: false,
                }}
                InputLabelProps={{ shrink: (obj.label != null) }}
                error={!saveable}
                type="string"
              />
              <TextField
                margin="dense"
                id="text"
                label="Text"
                value={obj.text}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={this.handleChange}
                InputProps={{
                  readOnly: false,
                }}
                InputLabelProps={{ shrink: (obj.text != null) }}
                type="string"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.props.handleSave({
            custom_string_field: obj,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddTrialCustomDataDialog;
