

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import ReactOnRails from 'react-on-rails';
// import Search from 'components/Search';
// import TrialSelect from 'components/TrialSelect.jsx';
// import Clock from 'components/Clock';
// import Notification from 'components/Notification';
import NotificationBell from 'components/NotificationBell.jsx';
// import Calendars from 'components/Calendars';
// import Calendar from 'components/Calendar';
import SnackbarWrappedBigCalendar from 'components/SnackbarWrappedBigCalendar.jsx';
// import Centers from 'components/Centers.jsx';
import MainMenuDrawer from 'components/MainMenuDrawer.jsx';
import SnackbarWrappedApplicationBar from 'components/SnackbarWrappedApplicationBar.jsx';
import SnackbarWrappedLogin from 'components/SnackbarWrappedLogin.jsx';
import SnackbarWrappedSignUp from 'components/SnackbarWrappedSignUp.jsx';
import Footer from 'components/Footer.jsx';
import SnackbarWrappedTrialBoardsIndex from 'components/SnackbarWrappedTrialBoardsIndex.jsx';
import SnackbarWrappedShowTrialBoard from 'components/SnackbarWrappedShowTrialBoard.jsx';
import SnackbarWrappedShowTrialBoardSubmission from 'components/SnackbarWrappedShowTrialBoardSubmission.jsx';
import SnackbarWrappedShowKlifo from 'components/SnackbarWrappedShowKlifo.jsx';
import SnackbarWrappedTrialsIndex from 'components/SnackbarWrappedTrialsIndex.jsx';
import AddressBookIndex from 'components/AddressBookIndex.jsx';
import SnackbarWrappedAddressBook from 'components/SnackbarWrappedAddressBook.jsx';
import SnackbarWrappedCenters from 'components/SnackbarWrappedCenters.jsx';
import SnackbarWrappedShowTrial from 'components/SnackbarWrappedShowTrial.jsx';
import SnackbarWrappedTrialBoardTrialsIndex from 'components/SnackbarWrappedTrialBoardTrialsIndex.jsx';
import SnackbarWrappedKlifosIndex from 'components/SnackbarWrappedKlifosIndex.jsx';
import SnackbarWrappedTrialPeople from 'components/SnackbarWrappedTrialPeople.jsx';
import SnackbarWrappedTrialTasks from 'components/SnackbarWrappedTrialTasks.jsx';
import SnackbarWrappedDashboard from 'components/SnackbarWrappedDashboard.jsx';
import SnackbarWrappedTrialPresentation from 'components/SnackbarWrappedTrialPresentation.jsx';
import SnackbarWrappedTrialConfiguration from 'components/SnackbarWrappedTrialConfiguration.jsx'
import Navbar from 'components/Navbar.jsx';
import SnackbarWrappedTimeline from 'components/SnackbarWrappedTimeline.jsx';
import SnackbarWrappedEthicsProcesses from 'components/SnackbarWrappedEthicsProcesses.jsx';
import SnackbarWrappedContracts from 'components/SnackbarWrappedContracts.jsx';
import SnackbarWrappedTimeRegistrationIndex from 'components/SnackbarWrappedTimeRegistrationIndex.jsx';
import SnackbarWrappedTimeRegistrationStats from 'components/SnackbarWrappedTimeRegistrationStats.jsx';
import SnackbarWrappedUsers from 'components/SnackbarWrappedUsers.jsx';
import SnackbarWrappedPasswordReset from 'components/SnackbarWrappedPasswordReset.jsx';
import SnackbarWrappedMailLogin from 'components/SnackbarWrappedMailLogin.jsx';
import SnackbarWrappedEcrfUserOverview from 'components/SnackbarWrappedEcrfUserOverview.jsx';
import SnackbarWrappedEcrfUsersIndex from 'components/SnackbarWrappedEcrfUsersIndex.jsx';
import SnackbarWrappedEcrfManagement from 'components/SnackbarWrappedEcrfManagement.jsx';
import SnackbarWrappedSarcBobIndex from 'components/SnackbarWrappedSarcBobIndex.jsx';
import SnackbarWrappedSarcPatientOverview from 'components/SnackbarWrappedSarcPatientOverview'
import SnackbarWrappedSarcBobStatistics from 'components/SnackbarWrappedSarcBobStatistics'
//import SnackbarWrappedLabValuesIndex from 'components/SnackbarWrappedLabValuesIndex.jsx';
import SnackbarWrappedEcrfManagementAccounts from 'components/SnackbarWrappedEcrfManagementAccounts.jsx';
import SnackbarWrappedEcrfManagementConfiguration from 'components/SnackbarWrappedEcrfManagementConfiguration.jsx';
import Home from 'components/Home.jsx';
import Archive from 'components/TrialArchive.jsx';
import SnackbarWrappedArchive from 'components/SnackbarWrappedArchive.jsx';
import SnackbarWrappedPatientFilter from 'components/SnackbarWrappedPatientFilter.jsx';
import SnackbarWrappedCapasIndex from 'components/SnackbarWrappedCapasIndex.jsx';
import SnackbarWrappedSarcbopRequestAccess from 'components/SnackbarWrappedSarcbopRequestAccess.jsx';
import SnackBarWrappedAuditTrail from 'components/SnackBarWrappedAuditTrail.jsx';

// import 'stylesheets/Timeline.css';

// import 'stylesheets/react-big-calendar';

ReactOnRails.register({
  // Search,
  // TrialSelect,
  // Clock,
  NotificationBell,
  // Notification,
  // Calendars,
  // Calendar,
  SnackbarWrappedBigCalendar,
  // Centers,
  MainMenuDrawer,
  SnackbarWrappedApplicationBar,
  SnackbarWrappedLogin,
  SnackbarWrappedSignUp,
  SnackbarWrappedPasswordReset,
  Footer,
  SnackbarWrappedTrialBoardsIndex,
  SnackbarWrappedShowTrialBoard,
  SnackbarWrappedShowTrialBoardSubmission,
  SnackbarWrappedShowKlifo,
  SnackbarWrappedTrialsIndex,
  AddressBookIndex,
  Navbar,
  SnackbarWrappedAddressBook,
  SnackbarWrappedCenters,
  SnackbarWrappedShowTrial,
  SnackbarWrappedTrialBoardTrialsIndex,
  SnackbarWrappedKlifosIndex,
  SnackbarWrappedTrialPeople,
  SnackbarWrappedTrialTasks,
  SnackbarWrappedDashboard,
  SnackbarWrappedTrialPresentation,
  SnackbarWrappedTrialConfiguration,
  SnackbarWrappedTimeline,
  SnackbarWrappedEthicsProcesses,
  SnackbarWrappedContracts,
  SnackbarWrappedTimeRegistrationIndex,
  SnackbarWrappedTimeRegistrationStats,
  SnackbarWrappedUsers,
  SnackbarWrappedMailLogin,
  SnackbarWrappedEcrfUserOverview,
  SnackbarWrappedEcrfUsersIndex,
  SnackbarWrappedEcrfManagement,
  SnackbarWrappedSarcBobIndex,
  SnackbarWrappedSarcPatientOverview,
  SnackbarWrappedSarcBobStatistics,
  //SnackbarWrappedLabValuesImport,
  SnackbarWrappedEcrfManagementAccounts,
  SnackbarWrappedEcrfManagementConfiguration,
  Home,
  Archive,
  SnackbarWrappedArchive,
  SnackbarWrappedPatientFilter,
  SnackbarWrappedCapasIndex,
  SnackbarWrappedSarcbopRequestAccess,
  SnackBarWrappedAuditTrail
});
// Support component names relative to this directory:
// var componentRequireContext = require.context("components", true)
// var ReactRailsUJS = require("react_ujs")
// ReactRailsUJS.useContext(componentRequireContext)
