import React from 'react';
import classNames from 'classnames';
// import { withStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
// import PropTypes from 'prop-types';
// import TextField from './TextField.jsx';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const camelize = function camelize(str) {
  return str.replace(/\W+(.)/g, function(match, chr)
   {
        return chr.toUpperCase();
    });
}

function listComponent(item, state) {
  // const classes = useStyles();
  const departmentComponents = item.departments.map(department => {
    return (
      departmentComponent(department, item, state)
    )
  })
  return (
    <TableContainer component={Paper} style={{margin: '10px'}} fullWidth={false}>
      { (state.selectedHeader == "1") &&
      <h4>{item.marvin_id} - {item.inst_description}</h4>}
      { (state.selectedHeader == "2") &&
      <h5>{item.marvin_id} - {item.inst_description}</h5>}
      <Table  size="small" aria-label="a dense table" padding="none">
        <TableBody>
          { (state.site_name) &&
          <TableRow key="name">
            <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
              {/* <Typography className={classes.title} variant="h6" color="inherit" noWrap style={{marginLeft: 5}}> */}
              <b>Prüfstelle</b>
              {/* </Typography> */}
            </TableCell>
            <TableCell align="left"><b>{item.inst_description}</b></TableCell>
          </TableRow>}
          { (state.marvin_id) &&
          <TableRow key="marvin_id">
            <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
              <b>Nr Prüfstelle</b>
            </TableCell>
            <TableCell align="left"><b>{item.marvin_id}</b></TableCell>
          </TableRow>}
          { (state.status) &&
          <TableRow key="status">
            <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
              Status
            </TableCell>
            <TableCell align="left">{item.statusText}</TableCell>
          </TableRow>}
          {/* { (state.zek) &&
          <TableRow key="zek">
            <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
              Zuständige EK
            </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>}
          { (state.zek2) &&
          <TableRow key="zek2">
            <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
              Zeichen EK
            </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>} */}
          { (state.site_comment && item.comment) &&
          <TableRow key="site_comment">
            <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
              Kommentar
            </TableCell>
            <TableCell align="left"><i>{item.comment}</i></TableCell>
          </TableRow>}
          {departmentComponents}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function departmentComponent(department, site, state) {
  // const classes = useStyles();
  const functionArray = ["LKP", "Prüfer", "Stellvertreter", "äMP", "SN"];
  const functionsNotInArray = Array.from(new Set(department.people.filter(person => !(functionArray.includes(person.site_function))).map(person => person.site_function)));
  let ppl = [];
  functionArray.forEach(func => {
    department.people.filter(person => person.site_function == func).forEach(person => ppl.push(person));
  })
  functionsNotInArray.forEach(func => {
    department.people.filter(person => person.site_function == func).forEach(person => ppl.push(person));
  })
  
  const personComponents = ppl.map(person => {
    return (
      personComponent(person, department, state)
    )
  })
  return (
    <>
      { (state.address) &&
      <TableRow key={"address" + department.name}>
        <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
          Adresse
        </TableCell>
        <TableCell align="left">{site.institution.name} <br></br>
          {department.name} <br></br>
          {department.street} <br></br>
          {department.zip} {department.city} {(state.country) && <br></br>}
          {(state.country) ? department.country : null}
        </TableCell>
      </TableRow>}
      {personComponents}
    </>
  )
}

function personComponent(person, department, state) {
  // const classes = useStyles();
  let salutation = "";
  if (person.gender == "m" || person.gender == "M") {
    salutation = "Herr";
  } else if (person.gender == "w" || person.gender == "W") {
    salutation = "Frau";
  } else if (person.gender == "?") {
    salutation = "Frau/Herr";
  }
  let primaryContact = "";
  if (person.primary_contact) {
    primaryContact = "Primärer Ansprechpartner"
  }
  return (
    <TableRow key={"person" + person.person_id}>
      <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
        {person.site_function}
      </TableCell>
      <TableCell align="left" >
        {(state.salutation) ? salutation : null} {(state.title) ? person.title : null} {(state.first_name) ? person.first_name : null} {(state.last_name) ? person.last_name : null} { (state.salutation || state.title || state.first_name || state.last_name) && <br></br>}
        {(state.phone_number && person.phone_number) ? 'Tel: ' + person.phone_number : null} { (state.phone_number && person.phone_number) && <br></br>}
        {(state.fax_number && person.fax_number) ? 'Fax: ' + person.fax_number : null} { (state.fax_number && person.fax_number) && <br></br>}
        {(state.mobile_number && person.mobile_number) ? 'Mobil: ' + person.mobile_number : null} {(state.mobile_number && person.mobile_number) && <br></br>}
        {(state.email && person.email) ? 'E-Mail: ' + person.email : null} {(state.email && person.email) && <br></br>}
        {(state.primary_contact && (primaryContact != "")) && <i>{primaryContact}</i>} {(state.primary_contact && (primaryContact != "")) && <br></br>}
        {(state.comment && person.site_comment) && <i>{'Kommentar: ' + person.site_comment}</i>} {(state.comment && person.site_comment) && <br></br>}
      </TableCell>
    </TableRow>
  )
}

export default function SitesListReport(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    marvin_id: true,
    site_name: true,
    status: false,
    zek: false,
    zek2: false,
    site_comment: false,
    address: true,
    country: false,
    salutation: true,
    title: true,
    first_name: true,
    last_name: true,
    phone_number: true,
    fax_number: true,
    mobile_number: false,
    email: true,
    primary_contact: false,
    comment: false,
    selectedSorting: "id",
    selectedHeader: "none"
  });

  const { marvin_id, site_name, status, zek, zek2, site_comment, address, country, salutation, title, first_name, last_name, phone_number, fax_number, mobile_number, email, primary_contact, comment, selectedSorting, selectedHeader } = state;

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleRadioChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  }

  const { sites, departments, people, sitesDepartmentsAssociations, peopleDepartmentsAssociations } = props;
  const filteredPeople = people;
  let sortedSites = [];
  switch (selectedSorting) {
    case "id":
      sortedSites = sites.sort(function (a,b) {
        return a.marvin_id - b.marvin_id
      });
      break;
    case "name":
      sortedSites = sites.sort(function (a,b) {
        return a.inst_description.toLowerCase().localeCompare(b.inst_description.toLowerCase())
      });
    case "status":
      sortedSites = sites.sort(function (a,b) {
        return a.site_status_id - b.site_status_id
      });
    case "ek":
      sortedSites = sites.sort(function (a,b) {
        return a.ek < b.ek
      });
  }
  const listInfos = sortedSites.map(site => {
    let ret = site;
    const sdas = sitesDepartmentsAssociations.filter(sda => sda.site_id == site.id);
    const departmentIds = sdas.map(sda => sda.department_id);
    let deps = departments.filter(department => departmentIds.includes(department.id));
    // let departmentInd = -1;
    for (let departmentInd in deps) {
      const department = deps[departmentInd];
      const pdas = peopleDepartmentsAssociations.filter(pda => (pda.department_id == department.id) && (pda.site_id == site.id));
      const peopleIds = pdas.map(pda => pda.person_id);
      let ppl = [].concat(people.filter(person => (peopleIds.includes(person.person_id)) && (person.site_id == site.id)));
      for (let pplInd in ppl) {
        const pda = pdas.find(pda => pda.person_id == ppl[pplInd].person_id)
        // ppl[pplInd]["site_function"] = pda.function;
        ppl[pplInd]["site_comment"] = pda.comment;
      }
      deps[departmentInd]["people"] = ppl;
    }
    ret["departments"] = deps;
    return ret;
  });
  // let listInfos = [];
  // let listComponents = [];
  
  // let siteInd = -1;
  // for (siteInd in sites) {
  //   let site = sites[siteInd];
  //   // console.log(site);
  //   let sdas = sitesDepartmentsAssociations.filter(sda => sda.site_id == site.id);
  //   let sdaInd = -1;
  //   for (sdaInd in sdas) {
  //     const sda = sdas[sdaInd];
  //     let department = departments.find(dep => dep.id == sda.department_id);
  //     const pdas = peopleDepartmentsAssociations.filter(pda => (pda.department_id == department.id) && (pda.site_id == site.id));
  //     const dp = 
  //     sdas[sdaInd]
  //   }
  //   // const sdaDepartmentIds = sdas.map(sda => sda.department_id);
  //   // const deps = departments.filter(department => sdaDepartmentIds.includes(department.id));
  //   // let departmentInd = -1;
  //   // for (departmentInd in deps) {
  //   //   const department = departments[departmentInd];
  //   //   // console.log(department);
  //   //   let ret = site;
  //   //   ret["street"] = department.street;
  //   //   ret["city"] = department.city;
  //   //   ret["zip"] = department.zip;
  //   //   ret["country"] = department.country;
  //   //   ret["department_name"] = department.name;
  //   //   ret["institution_name"] = site.institution.name;
  //   //   const pdas = peopleDepartmentsAssociations.filter(pda => ((pda.site_id == site.id) && (department.id == pda.department_id)));
  //   //   // console.log(pdas);
  //   //   const pdaPersonIds = (pdas) ? pdas.map(pda => pda.person_id) : [];
  //   //   // console.log(filteredPeople);
  //   //   const peop = (pdaPersonIds) ? filteredPeople.filter(person => pdaPersonIds.includes(person.id)) : [];
  //   //   ret["poeple"] = peop;
  //   //   listInfos.push(ret);
  //   //   listComponents.push(listComponent(ret));
  //   // }
  // };
  const listComponents = listInfos.map(site => listComponent(site, state));
  
  return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <Typography style={{color: 'red', fontSize: '20px'}}>
            Hinweis: Gezeigt werden nur Datensätze, die oben in der Auswahl zu sehen sind, also nach Filter!
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Zentrum</FormLabel>
            <FormGroup row>
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={site_name}
                    onChange={handleChange}
                  />
                }
                label="Prüfstelle"
                name="site_name"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={marvin_id}
                    onChange={handleChange}
                  />
                }
                label="Nr Prüfstelle"
                name="marvin_id"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={status}
                    onChange={handleChange}
                  />
                }
                label="Status"
                name="status"
                InputProps={{
                  readOnly: true,
                }}
              />
              {/* <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={zek}
                    onChange={handleChange}
                  />
                }
                label="Zuständige EK"
                name="zek"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={zek2}
                    onChange={handleChange}
                  />
                }
                label="Zeichen EK"
                name="zek2"
                InputProps={{
                  readOnly: true,
                }}
              /> */}
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={site_comment}
                    onChange={handleChange}
                  />
                }
                label="Kommentar"
                name="site_comment"
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Sortierung</FormLabel>
            <FormGroup row>
            <FormControlLabel
              value="right"
              control={<Radio
                checked={selectedSorting === 'id'}
                onChange={handleRadioChange}
                value="id"
                name="selectedSorting"
                inputProps={{ 'aria-label': 'ZID' }}
              />}
              label="ZID"
              labelPlacement="end"
            />
            <FormControlLabel
              value="right"
              control={<Radio
                checked={selectedSorting === 'name'}
                onChange={handleRadioChange}
                value="name"
                name="selectedSorting"
                inputProps={{ 'aria-label': 'Name' }}
              />}
              label="Name"
              labelPlacement="end"
            />
            <FormControlLabel
              value="right"
              control={<Radio
                checked={selectedSorting === 'status'}
                onChange={handleRadioChange}
                value="status"
                name="selectedSorting"
                inputProps={{ 'aria-label': 'Status' }}
              />}
              label="Status"
              labelPlacement="end"
            />
            <FormControlLabel
              value="right"
              control={<Radio
                checked={selectedSorting === 'ek'}
                onChange={handleRadioChange}
                value="ek"
                name="selectedSorting"
                inputProps={{ 'aria-label': 'EK' }}
                disabled
              />}
              label="EK"
              labelPlacement="end"
            />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Überschrift</FormLabel>
              <FormGroup row>
              <FormControlLabel
                value="right"
                control={<Radio
                  checked={selectedHeader === 'none'}
                  onChange={handleRadioChange}
                  value="none"
                  name="selectedHeader"
                  inputProps={{ 'aria-label': 'Keine' }}
                />}
                label="Keine"
                labelPlacement="end"
              />
              <FormControlLabel
                value="right"
                control={<Radio
                  checked={selectedHeader === '1'}
                  onChange={handleRadioChange}
                  value="1"
                  name="selectedHeader"
                  inputProps={{ 'aria-label': '1' }}
                />}
                label="Groß"
                labelPlacement="end"
              />
              <FormControlLabel
                value="right"
                control={<Radio
                  checked={selectedHeader === '2'}
                  onChange={handleRadioChange}
                  value="2"
                  name="selectedHeader"
                  inputProps={{ 'aria-label': '2' }}
                />}
                label="Klein"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Abteilung</FormLabel>
            <FormGroup row>
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={address}
                    onChange={handleChange}
                  />
                }
                label="Adresse"
                name="address"
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={country}
                    onChange={handleChange}
                  />
                }
                label="Land"
                name="country"
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Personen</FormLabel>
            <FormGroup row>
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={salutation}
                    onChange={handleChange}
                  />
                }
                label="Anrede"
                name="salutation"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={title}
                    onChange={handleChange}
                  />
                }
                label="Titel"
                name="title"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={first_name}
                    onChange={handleChange}
                  />
                }
                label="Vorname"
                name="first_name"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={last_name}
                    onChange={handleChange}
                  />
                }
                label="Nachname"
                name="last_name"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={phone_number}
                    onChange={handleChange}
                  />
                }
                label="Tel"
                name="phone_number"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={fax_number}
                    onChange={handleChange}
                  />
                }
                label="Fax"
                name="fax_number"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={mobile_number}
                    onChange={handleChange}
                  />
                }
                label="Mobil"
                name="mobile_number"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={email}
                    onChange={handleChange}
                  />
                }
                label="E-Mail"
                name="email"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={primary_contact}
                    onChange={handleChange}
                  />
                }
                label="Prim. Ansprechpartner"
                name="primary_contact"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                style={{zIndex: 1}}
                control={
                  <Checkbox
                    checked={comment}
                    onChange={handleChange}
                  />
                }
                label="Kommentar"
                name="comment"
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormGroup>
          </FormControl>
          {listComponents}
        </div>
  );
}
