import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientPTnmFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            {/* <TextField
              margin="dense"
              id="pseudonym"
              label="Pseudonym"
              value={obj.pseudonym}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            /> */}
            <TextField
              margin="dense"
              id="histology_date"
              label="Datum Histologie"
              value={moment(obj.histology_date).format("DD-MM-YYYY")}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="y"
                label="y"
                value={obj.y}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="r"
                label="r"
                value={obj.r}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
            <TextField
              margin="dense"
              id="p_t"
              label="pT"
              value={obj.p_t}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="p_n"
              label="pN"
              value={obj.p_n}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="p_m"
              label="pM"
              value={obj.p_m}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="c_m"
              label="cM"
              value={obj.c_m}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            </Box>
            <TextField
              margin="dense"
              id="grading"
              label="Grading"
              value={obj.grading}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <Box
              display="flex"
              flexDirection="row"
            >
            <TextField
              margin="dense"
              id="perineural_invasion"
              label="Perineurale Invasion"
              value={obj.perineural_invasion}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="venous_invasion"
              label="Veneninvasion"
              value={obj.venous_invasion}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="lymphatic_invasion"
              label="Lymphgefäßinvasion"
              value={obj.lymphatic_invasion}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="a"
                label="a"
                value={obj.a}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="m"
                label="m"
                value={obj.m}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box><Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="r_local"
                label="R_Lokal"
                value={obj.r_local}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="affected_lymphatic_nodes"
                label="Befallene Lymphknoten"
                value={obj.affected_lymphatic_nodes}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="examinated_lymphatic_nodes"
                label="Untersuchte Lymphknoten"
                value={obj.examinated_lymphatic_nodes}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            {/* <TextField
              margin="dense"
              id="introduction_date"
              label="Erstvorstellung"
              value={(obj.introduction_date) ? moment(obj.introduction_date).format("YYYY-MM-DD") : null}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="effective_date"
              label="Stand Dokumentation"
              value={(obj.effective_date) ? moment(obj.effective_date).format("YYYY-MM-DD") : null}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="icd_10_code"
                label="ICD-10-Code"
                value={obj.icd_code}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="icd_10_text"
                label="ICD-10-Text"
                value={obj.icd_10_text}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="icd_o3_code"
                label="ICD-O3-Code"
                value={obj.icd_o3_code}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="icd_o3_text"
                label="ICD-O3-Text"
                value={obj.icd_o3_text}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="icd_o3_histology_code"
                label="ICD-O3-Histologie-Code"
                value={obj.icd_o3_histology_code}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="icd_o3_histology_text"
                label="ICD-O3-Histologie-Text"
                value={obj.icd_o3_histology_text}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="diagnostic_confirmation"
                label="Diagnosesicherung"
                value={obj.diagnostic_confirmation}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="lateral_localization"
                label="Seitenlokalisation"
                value={obj.lateral_localization}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <TextField
              margin="dense"
              id="metastasis_1"
              label="Metastase 1"
              value={(obj.metastasis_1)}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="metastasis_2"
              label="Metastase 2"
              value={(obj.metastasis_2)}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="metastasis_3"
              label="Metastase 3"
              value={(obj.metastasis_3)}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="metastasis_4"
              label="Metastase 4"
              value={(obj.metastasis_4)}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="metastasis_5"
              label="Metastase 5"
              value={(obj.metastasis_5)}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="metastasis_6"
              label="Metastase 6"
              value={(obj.metastasis_6)}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            /> */}
          </Grid>
        </Grid>
  );
}
