import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientBloodSampleFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;
  // console.log(props);

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <TextField
              id="advice_offered"
              label="Beratung angeboten"
              value={obj.advice_offered}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />

            <TextField
              id="remark"
              label="Hinweis"
              value={obj.remark}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />

            <TextField
              id="rhabdomyosarkom"
              label="Rhabdomyosarkom"
              value={obj.rhabdomyosarkom}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="osteosarkom"
              label="Osteosarkom"
              value={obj.osteosarkom}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="sarkom_cervix_uteri"
              label="Sarkom Cervix Uteri"
              value={obj.sarkom_cervix_uteri}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="pleuropulmonales_blastom"
              label="Pleuropulmonales Blastom"
              value={obj.pleuropulmonales_blastom}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="mpnst"
              label="MPNST"
              value={obj.mpnst}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="abdominelle_fibromatose"
              label="Abdominelle Fibromatose"
              value={obj.abdominelle_fibromatose}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="gastrointestinaler_stromatumor"
              label="Gastrointestinaler Stromatumor"
              value={obj.gastrointestinaler_stromatumor}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="intrakranielles_sarkom"
              label="Intrakranielles Sarkom"
              value={obj.intrakranielles_sarkom}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="diagnose_nicht_zufreffend"
              label="Diagnose nicht Zufreffend"
              value={obj.diagnose_nicht_zufreffend}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="knochen_weichgewebetumoren"
              label="Knochen Weichgewebetumoren"
              value={obj.knochen_weichgewebetumoren}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="multifokale_knochen_weichgewebetumoren"
              label="Multifokale Knochen Weichgewebetumoren"
              value={obj.multifokale_knochen_weichgewebetumoren}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="eigenanamnese_nicht_zutreffend"
              label="Eigenanamnese nicht Zutreffend"
              value={obj.eigenanamnese_nicht_zutreffend}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="hautveraenderungen"
              label="Hautveraenderungen"
              value={obj.hautveraenderungen}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="wachstumsauffaelligkeiten"
              label="Wachstumsauffaelligkeiten"
              value={obj.wachstumsauffaelligkeiten}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="geistige_entwicklungsstoerung"
              label="Geistige Entwicklungsstoerung"
              value={obj.geistige_entwicklungsstoerung}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="angeborene_fehlbildung"
              label="Angeborene Fehlbildung"
              value={obj.angeborene_fehlbildung}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="dysmorphe_gesichtszuege"
              label="Dysmorphe Gesichtszuege"
              value={obj.dysmorphe_gesichtszuege}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="diagnose_sarkom_nicht_zutreffend"
              label="Diagnose Sarkom nicht Zutreffend"
              value={obj.diagnose_sarkom_nicht_zutreffend}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
            <TextField
              id="pathogene_genvariante"
              label="Pathogene Genvariante"
              value={obj.pathogene_genvariante}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              id="krebsdiagnose_kind_jugendalter"
              label="Krebsdiagnose Kind Jugendalter"
              value={obj.krebsdiagnose_kind_jugendalter}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              id="tumor_krebsdiagnose_ue45_ersten_grades"
              label="Tumor Krebsdiagnose Ue45 ersten Grades"
              value={obj.tumor_krebsdiagnose_ue45_ersten_grades}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              id="tumor_krebsdiagnose_ue45_zweiten_grades"
              label="Tumor Krebsdiagnose Ue45 zweiten Grades"
              value={obj.tumor_krebsdiagnose_ue45_zweiten_grades}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              id="hirntumor_seltene_krebskrankheit"
              label="Hirntumor seltene Krebskrankheit"
              value={obj.hirntumor_seltene_krebskrankheit}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              id="familienanam_nicht_zutreffend"
              label="Familienanam nicht Zutreffend"
              value={obj.familienanam_nicht_zutreffend}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            
          </Grid>
        </Grid>
  );
}
