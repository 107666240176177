import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can';
import Moment from 'moment'

import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';

import EcrfUserFields from './EcrfUserFields.jsx';
import EcrfApplicationFields from './EcrfApplicationFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import ViewDialog from './ViewDialog.jsx';
import SarcBobFileUploadDialog from './SarcBobFileUploadDialog.jsx';
import ListOfFiles from './ListOfFiles.jsx';
import ListOfEcrfApplications from './ListOfEcrfApplications.jsx';
import { ImportContactsOutlined, ThreeSixty, ViewInArOutlined } from '@mui/icons-material';
import EnhancedTable from './EnhancedTable.jsx';
import SarcPatientFields from './SarcPatientFields.jsx';
import SarcPatientDiagnosisFields from './SarcPatientDiagnosisFields.jsx';
import SarcPatientPTnmFields from './SarcPatientPTnmFields.jsx';
import SarcPatientStadiumFields from './SarcPatientStadiumFields.jsx';
import SarcPatientOperationFields from './SarcPatientOperationFields.jsx';
import SarcPatientSystemicTherapyFields from './SarcPatientSystemicTherapyFields.jsx';
import SarcPatientMiscellaneousTherapyFields from './SarcPatientMiscellaneousTherapyFields.jsx';
import SarcPatientRadiotherapyFields from './SarcPatientRadiotherapyFields.jsx';
import SarcPatientNuclearTherapyFields from './SarcPatientNuclearTherapyFields.jsx';
import SarcPatientProgressionFields from './SarcPatientProgressionFields.jsx';
import SarcPatientVitalStatusFields from './SarcPatientVitalStatusFields.jsx';
import SarcPatientSocioFields from './SarcPatientSocioFields.jsx';
import SarcPatientBloodSampleFields from './SarcPatientBloodSampleFields.jsx';

//import Chart from 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import { Chart as ReactChart } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js'; 
Chart.register(CategoryScale);
Chart.register(...registerables);
//import faker from 'faker';
import Papa from "../../../node_modules/papaparse/";
import DataEntryEditDialog from './DataEntryEditDialog.jsx';

//const inclusion_labels = [1,1,1,1,1,1,1,1,1,1,1,2,2,3,3,3,4,4,4,4,4,5,6,6,6,6,6,7,9,9,9,9,9,9,9,9,9,9,9,10];
//const inclusion_labels = ["2019-08-31","2019-09-30","2019-10-31","2019-11-30","2019-12-31","2020-01-31","2020-02-29","2020-03-31","2020-04-30","2020-05-31","2020-06-30","2020-07-31","2020-08-31","2020-09-30","2020-10-31","2020-11-30","2020-12-31","2021-01-31","2021-02-28","2021-03-31","2021-04-30","2021-05-31","2021-06-30","2021-07-31","2021-08-31","2021-09-30","2021-10-31","2021-11-30","2021-12-31","2022-01-31","2022-02-28","2022-03-31","2022-04-30","2022-05-31","2022-06-30","2022-07-31","2022-08-31","2022-09-30","2022-10-31","2022-11-30"];

// console.log(this.props);

class SarcBobStatistics extends React.Component {

  constructor(props) {
    super(props);
    let user = this.props.user;
    this.state = {
      patients: props.patients,
      patients2: props.patients2,
      user: user,
      pro_data_registries: props.pro_data_registries,
      expanded: null,
      openDialogs: {}
    };
    this.form = React.createRef();
  }

  handleOpen = name => (event) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleApplicationRemove = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/delete_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      //console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handlePatientChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedPatients: allObjectsSelected,
      selectedPatientIds: allRowsSelected,
    });
    // this.updatePeople(allRowsSelected);
  };

  render() {
    const inclusion_options =   {
      responsive: true,
      plugins: {
        // legend: {
        //   position: 'top' as const,
        // },
        // title: {
        //   display: true,
        //   text: 'Eingeschlossene Patient*innen',
        // },
      },
    };
    const inclusion_data = {
      labels: Object.keys(this.props.inclusion_statistics).map((key) => key.substring(0,7)),
      datasets: [{
        label: 'Eingeschlossene Patient*innen',
        //data: [65, 59, 80, 81, 56, 55, 85, -10],
        data: Object.values(this.props.inclusion_statistics),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };

    {console.log(Object.keys(this.props.inclusion_statistics).map((key) => key.substring(0,7)), Object.values(this.props.inclusion_statistics));}
    return <Line options={{responsive: true}} data={inclusion_data} />;
  }
}

export default withTheme(withSnackbar(SarcBobStatistics));
