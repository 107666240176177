import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';

import PropTypes from 'prop-types';
import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
// import MUIDataTable from "mui-datatables";
import { withSnackbar } from 'notistack';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

import Moment from 'moment';

import AddDepartmentToSiteDialog from './AddDepartmentToSiteDialog.jsx';
import RemoveDepartmentConfirmationDialog from './RemoveDepartmentConfirmationDialog.jsx';
import RemovePersonConfirmationDialog from './RemovePersonConfirmationDialog.jsx';
import AddPersonDialog from './AddPersonDialog.jsx';
import AddPersonGlobalDialog from './AddPersonGlobalDialog.jsx';
// import NewPersonDialog from './NewPersonDialog.jsx'
import AddSiteDialog from './AddSiteDialog.jsx';

import EditSiteDialog from './EditSiteDialog.jsx';
import InfoSiteDialog from './InfoSiteDialog.jsx';
import EditDepartmentDialog from './EditDepartmentDialog.jsx';
import InfoDepartmentDialog from './InfoDepartmentDialog.jsx';
// import EditPersonDialog from './EditPersonDialog.jsx';
import EditSitesPeopleAssociationsDialog from './EditSitesPeopleAssociationsDialog.jsx';
import InfoPersonSiteDialog from './InfoPersonSiteDialog.jsx';
import EditNoteDialog from './EditNoteDialog.jsx';
// import NoteTable from './NoteTable.jsx';
import SiteRow from './SiteRow.jsx';
import CustomDataTablesToolbar from './CustomDataTablesToolbar.jsx';
import NewDepartmentDialog from './NewDepartmentDialog.jsx';
// import NewPersonDialog from './NewPersonDialog';
import NewNoteDialog from './NewNoteDialog.jsx'
import NewDocumentDialog from './NewDocumentDialog.jsx'
import NewDocumentVersionDialog from './NewDocumentVersionDialog.jsx'
import EditDocumentDialog from './EditDocumentDialog.jsx'
import EditDocumentVersionDialog from './EditDocumentVersionDialog.jsx'
import EnhancedTable from './EnhancedTable.jsx';
import MilestonesPaper from './MilestonesPaper.jsx';
import Milestones from './Milestones.jsx';
import DocumentsListDialog from './DocumentsListDialog.jsx';
import SearchBar from './SearchBar.jsx';
import EditTaskDialog from './EditTaskDialog.jsx';
import InfoTaskDialog from './InfoTaskDialog.jsx';
import NewPrivateTaskDialog from './NewPrivateTaskDialog.jsx';
import DeleteTaskConfirmationDialog from './DeleteTaskConfirmationDialog.jsx';
import { Can } from './Can.js';
import { AbilityContext } from './Can.js';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    const taskColumns = [
      {
        id: "date_due",
        label: "Fälligkeitsdatum",
        numeric: false,
        padding: 'none',
        date: true,
        type: "date",
      },
      {
        id: "title",
        label: "Titel",
        numeric: false,
        padding: 'none',
      },
      {
        id: "trialShort",
        label: "Studie",
        numeric: false,
        padding: 'none',
        type: "string",
        filterable: true,
      },
      {
        id: "affiliation",
        label: "Zugehörigkeit",
        numeric: false,
        padding: 'none',
        type: "colorText",
        // filterable: true,
      },
      {
        id: "statusText",
        label: "Status",
        numeric: false,
        padding: 'none',
        type: "objectStatus",
        filterable: true,
      },
      // {
      //   id: "description",
      //   label: "Beschreibung",
      //   numeric: false,
      //   padding: 'none',
      //   type: "textNoBreak",
      // },

      {
        id: "nextReminderDate",
        label: "Erinnerungsdatum",
        numeric: false,
        padding: 'none',
        date: true,
        type: "date",
      },
      {
        id: "link",
        label: "Link",
        numeric: false,
        padding: 'none',
        type: "linkButton",
      },
      {
        id: "is_pm",
        label: "PM",
        type: "hidden",
        filterable: true,
       },
    ];
    const trialColumns = [
      {
        id: "id",
        label: "ID",
        numeric: false,
        padding: 'none',
      },
      {
        id: "short",
        label: "Kurztitel",
        numeric: false,
        padding: 'none',
      },
      {
        id: "statusText",
        label: "Status",
        numeric: false,
        padding: 'none',
        type: "objectStatus",
        filterable: true,
      },
      {
        id: "created_at",
        label: "Erstellungsdatum",
        numeric: false,
        padding: 'none',
        date: true,
        type: "date",
      },
      {
        id: "updated_at",
        label: "Letzte Änderung",
        numeric: false,
        padding: 'none',
        date: true,
        type: "date",
      },
    ];
    const trialBoardColumns = [
      {
       id: "id",
       label: "ID",
       numeric: false,
       padding: 'none',
      },
     {
      id: "board_date",
      label: "Datum",
      numeric: false,
      padding: 'none',
      type: "date",
     },
    ];
    this.state = { trials: props.trials, trialBoards: props.trial_boards, taskColumns: taskColumns, trialColumns: trialColumns, trialBoardColumns: trialBoardColumns, tasks: props.tasks, selectedTasks: [], selectedTaskIds: [], pmTrialIds: props.pm_trial_ids, associatedTrialIds: props.associated_trial_ids, openDialogs: {editTask: false, newTask: false, infoTask: false, deleteTaskConfirmation: false}, user: props.user, taskFilters: {is_pm: {label: "nur meine", value: true}, status: [{label: "Offen", value: "open"}]}, expandedTable: {Merkzettel: true, Studien: true, "Studienboard Sitzungen": true}, user: props.user };
    // this.handleFilterUpdate();
    // this.onChange = this.onChange.bind(this);
    this.form = React.createRef();
    // this.state = this.props.selectedTrial;
    // console.log(this.state);
  }

  handleNewTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewTaskClose = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewTaskCloseOpenInfo = (obj) => {
    // console.log(event, obj);
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = false;
    newOpenDialogs["infoTask"] = true;
    this.setState({selectedTasks: [obj], selectedTaskIds: [obj.id]})
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoTaskClose = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditTaskClose = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleTaskDoubleClick = (event, obj) => {
    // console.log(obj);
    this.setState({selectedTasks: [obj], selectedTaskIds: [obj.id]});
    this.handleInfoTaskOpen();
  }

  // handleTaskLinkClick = (event, obj) => {
  //   console.log(event, obj);
  //   if (obj.taskable_type == "ProcessStep") {
  //     let taskable = obj.taskable;
  //     while (!taskable.is_main_step) {
  //       taskable = taskable.taskable;
  //     }
  //     const process_instance = taskable.process_instance;
  //     const processable = process_instance.processable;
  //     const process_dummiable = processable.process_dummiable;
  //     if (processable.process_dummiable_type == "Trial") {
  //       window.location.assign('/trials/' + process_dummiable.id + '/ethics_processes');
  //     }
  //     // let process_dummy = process_instance.process_dummy;
  //     // console.log(taskable, process_instance, processable, process_dummiable);
  //   } else if (obj.taskable_type == "User") {
  //     const taskable = obj.taskable;
  //     console.log(taskable);
  //   } else {
  //     console.log(taskable);
  //   }
  // }

  handleDuplicateTaskClick = (event) => {
    // console.log(event);
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/duplicate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task: currentComponent.state.selectedTasks[0],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich dupliziert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleMarkDoneClick = (event) => {
    // console.log(event);
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/' + currentComponent.state.selectedTasks[0].id + '/mark_done', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task: currentComponent.state.selectedTasks[0],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich als erledigt markiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteTask = (event, obj) => {
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task_ids: currentComponent.state.selectedTasks.map(st => st.id),
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
        currentComponent.handleDeleteTaskConfirmationDialogClose();
        // currentComponent.props.handleClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleTaskChange = (rowData, allRowsSelected, allObjectsSelected) => {
    // console.log(rowData, allRowsSelected, allObjectsSelected);
    this.setState({
      selectedTasks: allObjectsSelected,
      selectedTaskIds: allRowsSelected,
    });
  };

  handleTasksFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({taskFilters: {is_pm: {label: "nur meine", value: true}, status: [{label: "Offen", value: "open"}]}});
    }
  }

  handleTasksFilterChange = (filters, meta) => {
    if ((meta.action == "select-option") || (meta.action == "remove-value")) {
      let newTaskFilters = {...this.state.taskFilters};
      newTaskFilters[meta["name"]] = filters;
      this.setState({taskFilters: newTaskFilters});
    } else if (meta.action == "clear") {

      let newTaskFilters = {...this.state.taskFilters};
      delete newTaskFilters[meta["name"]];
      this.setState({taskFilters: newTaskFilters});
    }
  }

  handleDeleteTaskConfirmationDialogOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["deleteTaskConfirmation"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleDeleteTaskConfirmationDialogClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["deleteTaskConfirmation"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleCableTest = test => {
    let currentComponent = this;
    fetch('/dashboard/actioncable_test', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        testtext: "Testtext",
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 0) {
        currentComponent.props.enqueueSnackbar('Controller funktioniert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Controller funktioniert nicht.', {variant: 'error'});
      }
    });
  }

  handleExpandChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({expandedTable: newExpandState});
  }

  handleExpandPaperChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({expandedTable: newExpandState});
  }

  handleReceived = message => {
    console.log("received");
    console.log(message);
    this.props.enqueueSnackbar('Nachricht erhalten: ' + message, {variant: 'success'});
  }

  handleConnected = event => {
    console.log("connected");
  }

  handleTrialsReceived = trial => {
    console.log(trial.trial.id);
    let newTrials = [].concat(this.state.trials);
    let changedTrialIndex = newTrials.findIndex(tr => tr.id == trial.trial.id);
    if (changedTrialIndex > -1) {
      newTrials[changedTrialIndex] = trial.trial;
      this.setState({trials: newTrials});
      this.props.enqueueSnackbar('Studie aktualisiert: ' + trial.trial.title, {variant: 'success'});
    }
  }

  handleTasksReceived = message => {
    console.log("Task received", message);
    let newTasks = [].concat(this.state.tasks);
    if (message.new_task) {
      newTasks.push(message.new_task);
      this.setState({tasks: newTasks});
      this.props.enqueueSnackbar('Task wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_task.title, {variant: 'success'});
    } else if (message.task) {
      let changedTaskIndex = newTasks.findIndex(task => task.id == message.task.id);
      if (changedTaskIndex > -1) {
        newTasks[changedTaskIndex] = message.task;
        if (this.state.selectedTasks.length > 0 && this.state.selectedTasks.map(st => st.id).includes(message.task.id)){
          const taskIndex = this.state.selectedTasks.findIndex(st => st.id == message.task.id);
          let newSelectedTasks = [].concat(this.state.selectedTasks);
          newSelectedTasks[taskIndex] = message.task;
          this.setState({selectedTasks: newSelectedTasks});
        }
        this.setState({tasks: newTasks});
        this.props.enqueueSnackbar('Task aktualisiert: ' + message.task.title, {variant: 'success'});
      }
    } else if (message.deleted_task_ids) {
      const deletedTaskNames = newTasks.filter(task => message.deleted_task_ids.includes(task.id)).map(task => task.title);
      newTasks = newTasks.filter(task => !message.deleted_task_ids.includes(task.id));
      if (this.state.selectedTasks) {
        let newSelectedTasks = [].concat(this.state.selectedTasks);
        newSelectedTasks = newSelectedTasks.filter(st => !(message.deleted_task_ids.includes(st.id))) || [];
        this.setState({selectedTasks: newSelectedTasks});
      }
      this.setState({tasks: newTasks});
      this.props.enqueueSnackbar('Tasks von Benutzer ' + message.username + ' gelöscht: ' + deletedTaskNames, {variant: 'success'});
    }
  }

  render() {
    // const {classes} = this.props;
    const {openDialogs, selectedTasks} = this.state;
    const taskStatusList = [{value: "done", label: "Erledigt", color: "#ADADAD"}, {value: "open", label: "Offen", color: "#FFFFFF"}];
    // console.log(this.state.tasks);
    const supplementedTrials = this.state.trials.map(trial => {
      let ret = {...trial};
      ret["statusText"] = ret.status.text;
      ret["statusColor"] = ret.status.color;
      return ret;
    })
    const supplementedTasks = this.state.tasks.map(task => {
      // console.log(task);
      let ret = {... task};
      ret["statusText"] = taskStatusList.find(ts => task.status == ts.value).label;
      // ret["affiliation"] = "Benutzer";
      const notifiedReminders = task.reminders.filter(reminder => reminder.notified == true);
      if (task.status == "done") {
        ret["statusColor"] = "#ADADAD";
      } else if ((task.status == "open") && (Moment(task.date_due).isBefore(Moment()))){
        ret["statusColor"] = "#E29393";
        // console.log("Datum überschritten", Moment(task.date_due), Moment(), Moment(task.date_due).isBefore(Moment()) );
      } else if ((Moment().add(1, "year").isBefore(Moment(task.date_due))) && (task.status != "done")) {
        ret["statusColor"] = "#76d658";
        // console.log("Datum ein Jahr in Zukunft", Moment(task.date_due), Moment().add(1, "year"), (Moment(task.date_due).isBefore(Moment().add(1, "year"))));
      } else if ((notifiedReminders.length > 0) && task.status != "done") {
        ret["statusColor"] = "#F5B556";
        // console.log("Erstes Erinnerungsdatum erreicht");
      } else {
        ret["statusColor"] = taskStatusList.find(ts => task.status == ts.value).color;
        // console.log("Default");
      }
      // console.log(ret);
      if (task.taskable_type == "ProcessStep") {
        // console.log(task);
        const trialShort = task.taskable.process_instance.processable.process_dummiable.short;
        ret["trialShort"] = trialShort;
        // ret["affiliation"] = task.taskable.process_instance.name;
        const process_instance = task.taskable.process_instance;
        const processable = process_instance.processable;
        const process_dummiable = processable.process_dummiable;
        ret["link"] = '/trials/' + process_dummiable.id + '/ethics_processes/?process_instance_id=' + task.taskable.process_instance.id + '&process_step_id=' + task.taskable.id;
        ret["is_pm"] = (this.state.pmTrialIds.includes(task.taskable.process_instance.processable.process_dummiable.id)) ? true : false;
        ret["is_associated"] = (this.state.associatedTrialIds.includes(task.taskable.process_instance.processable.process_dummiable.id)) ? true : false;
      } else if (task.taskable_type == "Trial") {
        const trialShort = task.taskable.short;
        ret["trialShort"] = trialShort;
        // ret["affiliation"] = "Studientask";
        ret["link"] = '/trials/' + task.taskable.id + '/trial_tasks/?task_id=' + task.id;
        ret["is_pm"] = (this.state.pmTrialIds.includes(task.taskable.id)) ? true : false;
        ret["is_associated"] = (this.state.associatedTrialIds.includes(task.taskable.id)) ? true : false;
      } else if (task.taskable_type == "Contract") {
        
        const trial = task.taskable.contractable;
        ret["trialShort"] = trial.short;
        ret["link"] = '/trials/' + trial.id + '/contracts/?task_id=' + task.id + '&contract_id=' + task.taskable.id;
        ret["is_pm"] = (this.state.pmTrialIds.includes(trial.id)) ? true : false;
        ret["is_associated"] = (this.state.associatedTrialIds.includes(trial.id)) ? true : false;
      } else if (task.taskable_type == "User") {
        // const trialShort = task.taskable.process_instance.processable.process_dummiable.short;
        ret["trialShort"] = "Privat";
        ret["is_pm"] = null;
        ret["is_associated"] = null;
        ret["association"] = "Privat";
      }
      if (ret["is_pm"]) {
        // ret["affiliation"] = "Vertrag/Versicherung";
        ret["affiliation"] = ["Zuständig", "#E29393"];
      } else if (ret["is_associated"]) {
        ret["affiliation"] = ["Beteilligt", "#F29C5E"];
      } else if (task.taskable_type == "User") {
        ret["affiliation"] = ["Privat", "#2C7ACB"];
      } else {
        ret["affiliation"] = ["Info", "#FFFFFF"];
      }
      let latestReminderDate = Moment('2100-01-01');
      task.reminders.forEach(reminder => {
        if (Moment(latestReminderDate).isAfter(Moment(reminder.remind_when))) {
          latestReminderDate = reminder.remind_when;
        }
      })
      if (!Moment(latestReminderDate).isSame('2100-01-01')) {
        ret["nextReminderDate"] = Moment(latestReminderDate);
      } else {
        ret["nextReminderDate"] = null;
      }
      return ret;
    })
    // if (selectedTask) {
    //   console.log(selectedTask.taskable_type, selectedTask.status, selectedTask.taskable.status)
    // }
    // console.log(this.state.taskFilters);
    const filteredSupplementedTasks = supplementedTasks.filter(task => {
      var ret = true;
      if ((this.state.taskFilters.is_pm.value != null) && !this.state.taskFilters.is_pm.value == task.is_pm) {
        ret = false;
      }
      if ((this.state.taskFilters.trialShort != null) && !this.state.taskFilters.trialShort.map(ts => ts.value).includes(task.trialShort)) {
        ret = false;
      }
      if ((this.state.taskFilters.status != null) && !this.state.taskFilters.status.map(stat => stat.value).includes(task.status)) {
        ret = false;
      }
      if (ret) {
        return true;
      } else {
        return false;
      }
    })
    // if (selectedTask) {
    //   console.log(selectedTask.taskable_type == "ProcessStep");
    // }
    // console.log(selectedTasks);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // console.log(queryString, urlParams.get('process_instance_id'));
    return (
      <>
        <ActionCableConsumer
          channel={{channel: "TestChannel"}}
          onReceived={this.handleReceived}
          onConnected={this.handleConnected}
        />
        <ActionCableConsumer
          channel={{channel: "TrialsChannel"}}
          onReceived={this.handleTrialsReceived}
          onConnected={this.handleConnected}
        />
        <ActionCableConsumer
          channel={{channel: "TasksChannel"}}
          onReceived={this.handleTasksReceived}
          onConnected={this.handleConnected}
        />
        <Grid container >
          {/* <Grid item xs={12}>
            <Grid container justify="center">
              <Typography className={classes.title} variant="h3" color="inherit" noWrap >
                TOPAS Dashboard
              </Typography>
            </Grid>
          </Grid> */}
          <Grid item xs={6}>
              { (openDialogs.newTask) &&
              <NewPrivateTaskDialog
                open={openDialogs.newTask}
                enqueueSnackbar={this.props.enqueueSnackbar}
                // handleAddOpen={this.handleEditProcessStepOpen}
                // handleSave={this.handleEditProcessStepSave}
                handleClose={this.handleNewTaskClose}
                handleCloseOpenInfo={this.handleNewTaskCloseOpenInfo}
                // selectedProcessStep={selectedProcessStep}
                // previousProcessStep={previousProcessStep}
                // stepStatusList={stepStatusList}
                taskStatusList={taskStatusList}
                taskableType="User"
                taskableId={this.state.user.id}
                // selectableTaskStatusList={selectableTaskStatusList}
                // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              />}
              { (openDialogs.infoTask && selectedTasks.length == 1) &&
              <InfoTaskDialog
                obj={selectedTasks[0]}
                dueDateNotEditable={(selectedTasks[0].taskable_type == "ProcessStep")}
                open={openDialogs.infoTask}
                enqueueSnackbar={this.props.enqueueSnackbar}
                // handleAddOpen={this.handleEditProcessStepOpen}
                // handleSave={this.handleEditProcessStepSave}
                handleClose={this.handleInfoTaskClose}
                // selectedProcessStep={selectedProcessStep}
                // previousProcessStep={previousProcessStep}
                // stepStatusList={stepStatusList}
                taskStatusList={taskStatusList}
                // selectableTaskStatusList={selectableTaskStatusList}
                // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              />}
              { (openDialogs.deleteTaskConfirmation && selectedTasks.length > 0) &&
              <DeleteTaskConfirmationDialog
                open={openDialogs.deleteTaskConfirmation}
                handleSave={this.handleDeleteTask}
                handleClose={this.handleDeleteTaskConfirmationDialogClose}
                selectedTasks={selectedTasks}
              />}
              <EnhancedTable
                title={"Merkzettel"}
                objectName={"Task"}
                data={filteredSupplementedTasks}
                allData={supplementedTasks}
                columns={this.state.taskColumns}
                rowsPerPage={10}
                order="asc"
                orderBy="date_due"
                controller="tasks"
                autoFill
                expanded={this.state.expandedTable["Merkzettel"]}
                handleExpandChange={this.handleExpandChange}
                selectable
                // singleSelect={true}
                selectedIds={this.state.selectedTaskIds}
                selectedObjects={selectedTasks}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleTaskChange(rowData, allRowsSelected, allObjectsSelected)}
                filters={this.state.taskFilters}
                handleFilterClick={this.handleTasksFilterClick}
                onFilterChange={this.handleTasksFilterChange}
                handleAddClick={this.handleNewTaskOpen}
                handleDuplicateClick={(selectedTasks.length == 1 && selectedTasks[0].taskable_type != "ProcessStep") ? this.handleDuplicateTaskClick : null}
                handleDoneClick={(selectedTasks.length == 1 && ((selectedTasks[0].taskable_type != "ProcessStep") && (selectedTasks[0].status != "done") || (selectedTasks[0].taskable_type == "ProcessStep" && (selectedTasks[0].taskable.status == "active" )))) ? this.handleMarkDoneClick : null}
                // (selectedTask.taskable.status == "active" || selectedTask.status == "open")
                handleDeleteClick={(selectedTasks.length > 0 && !selectedTasks.map(st => st.taskable_type).includes("ProcessStep")) ? this.handleDeleteTaskConfirmationDialogOpen : null}
                onDoubleClick={this.handleTaskDoubleClick}
                // handleLinkClick={this.handleTaskLinkClick}
                objectType="Task"
                multipleSelectable={true}
              />
          </Grid>
          <Can I="read" a="Trial">
            <Grid item xs={6}>
                <EnhancedTable
                  title={"Studien"}
                  data={supplementedTrials}
                  columns={this.state.trialColumns}
                  rowsPerPage={10}
                  order="asc"
                  orderBy="id"
                  controller="trials"
                  autoFill
                  expanded={this.state.expandedTable["Studien"]}
                  handleExpandChange={this.handleExpandChange}
                />
            </Grid>
          </Can>
          <Can I="read" a="TrialBoard">
            <Grid item xs={6}>
              {/* <Grid container justify="center"> */}
                <EnhancedTable
                  title={"Studienboard Sitzungen"}
                  data={this.state.trialBoards}
                  columns={this.state.trialBoardColumns}
                  // onDoubleClick={this.handleDoubleClick}
                  rowsPerPage={10}
                  order="desc"
                  orderBy="board_date"
                  // expanded
                  autoFill
                  controller="trial_boards"
                  expanded={this.state.expandedTable["Studienboard Sitzungen"]}
                  handleExpandChange={this.handleExpandChange}
                />
              {/* </Grid> */}
            </Grid>
          </Can>
        </Grid>
      </>
    )
  }
}

Dashboard.propTypes = {
  // classes: PropTypes.object.isRequired,
};

Dashboard.contextType = AbilityContext;

export default withStyles(styles)(withTheme(withSnackbar(Dashboard)));
