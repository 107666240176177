import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import moment from 'moment'
import "moment/locale/de";

moment.locale("de")

import ProcessStepFields from './ProcessStepFields.jsx';

class NewProcessStepDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { obj: (props.selectedProcessStep) ? {... props.selectedProcess} : {date_status: moment().toISOString().substr(0,10), status: (props.lastProcessStep.status == "planned" || this.props.lastProcessStep.status == "active") ? "planned" : "active"}, hasDeadline: true, automatic_reminders_by_notification: props.selectedProcess.automatic_reminders_by_notification, automatic_reminders_by_mail: props.selectedProcess.automatic_reminders_by_mail };
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastName !== prevProps.lastName) {
      this.setState({ inputValue: this.props.lastName, last_name: this.props.lastName });
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    if ((name == "deadline_days" || name == "planned_duration") && this.props.lastProcessStep && (((this.props.lastProcessStep.status == "done") && this.props.lastProcessStep.date_status) || this.props.lastProcessStep.date_deadline)) {
      if ((this.props.lastProcessStep.status == "done") && this.props.lastProcessStep.date_status) {
        newObj["date_deadline"] = moment(this.props.lastProcessStep.date_status).add(value, "days").toISOString().substr(0,10);
      } else if (this.props.lastProcessStep.date_deadline) {
        newObj["date_deadline"] = moment(this.props.lastProcessStep.date_deadline).add(value, "days").toISOString().substr(0,10);
      }
    }
    currentComponent.setState({ obj: newObj });
  };

  handleChange2 = (event, meta) => {
    const value = event.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    this.setState({ obj: newObj });
  }

  handleChangeDate = (date, name) => {
    let newObj = {...this.state.obj};
    newObj[name] = moment(date).toISOString().substr(0,10);
    if (this.props.lastProcessStep && ((this.props.lastProcessStep.date_status && (this.props.lastProcessStep.status == "done")) || this.props.lastProcessStep.date_deadline)) {
      let date1 = null;
      if (this.props.lastProcessStep.date_status && (this.props.lastProcessStep.status == "done")) {
        date1 = new moment(this.props.lastProcessStep.date_status);
      } else if (this.props.lastProcessStep.date_deadline) {
        date1 = new moment(this.props.lastProcessStep.date_deadline);
      }
      const date2 = new moment(date);
      let momentDiff = moment.duration(date2.diff(date1)).days();
      let difference = Math.abs(parseInt(momentDiff));
      newObj["deadline_days"] = difference;
    }
    // console.log(newObj, moment.duration(moment(this.props.previousProcessStep.date_change).diff(moment(date))).days());
    this.setState({obj: newObj});
  };

  handleChangeDeadline = (date, name) => {
    let newObj = {...this.state.obj};
    let newDeadline = this.state.date_deadline;
    newDeadline = moment(date).toISOString().substr(0,10);
    // console.log(this.props.lastProcessStep, this.props.lastProcessStep.date_status, this.props.lastProcessStep.status, this.state.hasDeadline)
    if ((this.props.lastProcessStep || this.props.lastProcessStep.status == "received") && this.state.hasDeadline) {
      if (this.props.lastProcessStep.date_status && (this.props.lastProcessStep.status == "done")) {
        const date1 = new moment(this.props.lastProcessStep.date_status);
      } else if (this.props.lastProcessStep.date_deadline) {
        const date1 = new moment(this.props.lastProcessStep.date_deadline);
      }
      const date2 = new moment(date);
      let momentDiff = moment.duration(date2.diff(date1)).days();
      let difference = Math.abs(parseInt(momentDiff));
      newObj["deadline_days"] = difference;
    }
    // console.log(newObj, moment.duration(moment(this.props.previousProcessStep.date_change).diff(moment(date))).days());
    this.setState({obj: newObj, date_deadline: newDeadline});
  };

  handleHasDeadlineChange = event => {
    let newObj = {... this.state.obj};
    newObj.date_deadline = null;
    newObj.deadline_days = null;
    this.setState({hasDeadline: (this.state.hasDeadline) ? false : true});
  }

  handleChangeCheckboxInState = (event, meta) => {
    const value = meta;
    const name = event.target.name;
    let newObj = {... this.state.obj};
    newObj[name] = value;
    this.setState({ name: value });
  }

  handleSave = (state) => {
    this.props.handleSave(this.state.obj);
    // this.props.handleSave(this.state);
    // this.setState({last_name: null, first_name: null, title: null, phone_number: null, mobile_number: null, fax_number: null, email: null, function: null});
  }

  render(props) {
    const saveable = this.state.obj.name && this.state.obj.status && this.state.obj.date_status && ((this.state.hasDeadline && this.state.obj.date_deadline) || (!this.state.hasDeadline));
    const { taskStatusList } = this.props;
    const stepStatusList = this.props.stepStatusList.filter(ss => (ss.value == "planned" || ss.value == "active"));
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Neuen Prozessschritt anlegen</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} >
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <ProcessStepFields
                  processStep={this.state.obj}
                  handleChange={this.handleChange}
                  handleChange2={this.handleChange2}
                  handleChangeDate={this.handleChangeDate}
                  readOnly={false}
                  stepStatusList={stepStatusList}
                  statusEditable={false}
                  taskStatusList={taskStatusList}
                  hasDeadline={this.state.hasDeadline}
                  handleHasDeadlineChange={this.handleHasDeadlineChange}
                />
                <FormControlLabel
                  style={{zIndex: 1}}
                  control={
                    <Checkbox
                      checked={this.state.automatic_reminders_by_notification}
                      onChange={this.handleChangeCheckboxInState}
                      disabled={true}
                    />
                  }
                  name="automatic_reminders_by_notification"
                  label="Automatische Erinnerungen per Notification"

                />
                <FormControlLabel
                  style={{zIndex: 1}}
                  control={
                    <Checkbox
                      checked={this.state.automatic_reminders_by_mail}
                      onChange={this.handleChangeCheckboxInState}
                      disabled={true}
                    />
                  }
                  name="automatic_reminders_by_mail"
                  label="Automatische Erinnerungen per Mail"
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={!saveable}
            onClick={() => this.handleSave(this.state)}
            color="primary"
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewProcessStepDialog;
