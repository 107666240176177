import React from 'react';
import { withStyles } from '@mui/styles';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import SearchBar from './SearchBar.jsx';

export default function FilterPopper(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const id = open ? 'simple-popper' : undefined;

  function onChange(event, meta) {
    props.onChange(event, meta);
  }

  function handleTextfieldChange(event) {
    props.onChange({value: event.target.value, label: event.target.id}, {action: "select-option", name: "search"});
  }

  function handleSearchClear(event) {
    props.onChange({value: null, label: event.target.id}, {action: "clear", name: "search"});
  }
  const ret = props.columns.map((cellInfo) => {
    if (cellInfo.filterable) {
      if (cellInfo.type == "objectStatus") {
        let name = "";
        const options = props.allData.map(row =>{
          if (row.site_status_id) {
            name = "site_status_id"
            return ({value: row.site_status_id, color: row.statusColor, label: row.statusText})
          } else {
            name = "status"
            return ({value: row.status, color: row.statusColor, label: row.statusText})
          }
        });
        const selectionOptions = options.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.value == item.value
          ))
        );
        return (
          <div style={{width: 500, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              Status
            </Typography>
            <Select
              // value={ this.state.selectedSiteStatus }
              // defaultValue={"Bitte wählen"}
              name={name}
              onChange={onChange}
              options={selectionOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              isMulti
            />
          </div>
        )
      }
      if (cellInfo.type == "objectStatus2") {
        const options = props.allData.map(row =>{
          return ({value: row.status, color: row.statusColor, label: row.statusText})
        });
        const selectionOptions = options.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.value == item.value
          ))
        );
        return (
          <div style={{width: 500, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              Status
            </Typography>
            <Select
              // value={ this.state.selectedSiteStatus }
              // defaultValue={"Bitte wählen"}
              name={"status"}
              onChange={onChange}
              options={selectionOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              isMulti
            />
          </div>
        )
      }
      if (cellInfo.type == "starMark") {
        const options = [{value: true, label: "Primärer Kontakt"}, {value: false, label: "Nicht Primär"}, {value: null, label: "Alle"}];
        const selectionOptions = options.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.value == item.value
          ))
        );
        return (
          <div style={{width: 300, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              Primärer Kontakt
            </Typography>
            <Select
              // value={ this.state.selectedSiteStatus }
              // defaultValue={"Bitte wählen"}
              name={"primary_contact"}
              onChange={onChange}
              options={selectionOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              // isMulti
            />
          </div>
        )
      }
      if (cellInfo.id == "left") {
        const options = [{value: true, label: "Ausgeschieden"}, {value: false, label: "Nicht Ausgeschieden"}, {value: null, label: "Alle"}];
        const selectionOptions = options.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.value == item.value
          ))
        );
        return (
          <div style={{width: 300, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              Personenstatus
            </Typography>
            <Select
              // value={ this.state.selectedSiteStatus }
              // defaultValue={"Bitte wählen"}
              name={"left"}
              onChange={onChange}
              options={selectionOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              // isMulti
            />
          </div>
        )
      }
      if (cellInfo.id == "show" || cellInfo.id == "overview_show") {
        const options = [{value: true, label: "nicht auf Übersicht anzeigen"}, {value: false, label: "auf Übersicht anzeigen"}, {value: null, label: "Alle"}];
        const selectionOptions = options.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.value == item.value
          ))
        );

        return (
          <div style={{width: 300, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              Anzeigeoption
            </Typography>
            <Select
              value={ props.filters[cellInfo.id] }
              // defaultValue={"Bitte wählen"}
              name={cellInfo.id}
              onChange={onChange}
              options={selectionOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              // isMulti
            />
          </div>
        )
      }
      if (cellInfo.id == "is_pm") {
        const options = [{value: true, label: "nur meine"}, {value: false, label: "nur andere"}, {value: null, label: "Alle"}];
        const selectionOptions = options.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.value == item.value
          ))
        );

        return (
          <div style={{width: 300, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              Anzeigeoption
            </Typography>
            <Select
              value={ props.filters[cellInfo.id] }
              // defaultValue={"Bitte wählen"}
              name={cellInfo.id}
              onChange={onChange}
              options={selectionOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              // isMulti
            />
          </div>
        )
      }
      if (cellInfo.type == "string") {
        const options = props.allData.map(row =>{
          return ({value: row[cellInfo.id], label: row[cellInfo.id]})
        });
        const selectionOptions = options.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.value == item.value
          ))
        );
        let val = null;
        if (props.filters) {
          val = props.filters[cellInfo.id];
        }
        return (
          <div style={{width: 300, zIndex: 1, margin: '10px'}}>
            <Typography variant="h8" color="inherit" >
              {cellInfo.label}
            </Typography>
            <Select
              value={ val }
              // defaultValue={"Bitte wählen"}
              name={cellInfo.id}
              onChange={onChange}
              options={selectionOptions}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              isMulti
            />
          </div>
        )
      }
    }
    if (cellInfo.type == "stringList") {
      let stringArray = [];
      props.allData.forEach((row, index, arr) =>{
        const strings = row[cellInfo.id].split(",").forEach((roleName, index2, arr2) => {
          if (stringArray.length > 0 && (!(stringArray.includes(roleName)))) {
            stringArray.push(roleName);
          } else if (stringArray.length == 0) {
            stringArray = [roleName];
          }
        });
      });
      const options = stringArray.map(str => {return {value: str, label: str}})
      let val = null;
      if (props.filters) {
        val = props.filters[cellInfo.id];
      }
      return (
        <div style={{width: 300, zIndex: 1, margin: '10px'}}>
          <Typography variant="h8" color="inherit" >
            {cellInfo.label}
          </Typography>
          <Select
            value={ val }
            // defaultValue={"Bitte wählen"}
            name={cellInfo.id}
            onChange={onChange}
            options={options}
            // styles={colourStyles}
            // menuIsOpen={true}
            // className={"bg-warning"}
            isMulti
          />
        </div>
      )
    }
    if (cellInfo.searchable) {
      return (
        <div style={{width: '400px', zIndex: 1, margin: '10px'}}>
          <Typography variant="h8" color="inherit" >
            Suche
          </Typography>
          <SearchBar
            onChange={handleTextfieldChange}
            handleClear={handleSearchClear}
            searchTerm={props.filters.search ? props.filters.search.value : ""}
          />
        </div>
      )
    }
  });

  return (
    <div>
      <Popper id={id} open={props.open} anchorEl={props.anchorEl} transition placement="top" style={{zIndex: 998}}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              {/* <Typography style={{
                display: "flex",
                flexBasis: '100%',
                flexDirection: 'row',
                flexGrow: 0,
                flexShrink: 1,
                flexWrap: 'nowrap',
                justifyContent: 'space-evenly',
              }}
              > */}
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
                {ret}
              </Box>
              {/* </Typography> */}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
