import React, { useState } from "react";
import { Toolbar, Box, Grid, Paper, Typography, styled, Checkbox, IconButton, Tooltip, InputLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import UploadIcon from '@mui/icons-material/Upload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import TextField from "./TextField.jsx";
import EditDialog from './EditDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';
import NewNoteDialog from './NewNoteDialog.jsx';
import EditNoteDialog from './EditNoteDialog.jsx';
import PersonGlobalSelector from "./PersonGlobalSelector.jsx";
import FileUploadDialog from "./FileUploadDialog.jsx";
import ListOfFiles from "./ListOfFiles.jsx";
import GeneratePDFButton from "./CustomComponents/GeneratePDFButton.jsx";
import { withSnackbar } from 'notistack';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { HandoverStudyPDF } from './PDF_Print/Archive/HandoverStudyPDF';
import { Can } from "./Can.js";


// small custom components for this Module 
const Text = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

function TextBox(props) {
  var { value, multiline, type, readOnly, errors, ...other } = props;
  return (
    <TextField
      size='small'
      margin='dense'
      fullWidth={true}
      multiline={multiline}
      variant={type == "email" && readOnly ? "standard" : "outlined"}
      type={type === undefined ? "string" : type}
      error={errors ? errors.includes(props.id) : false}
      value={value !== null ? value : ''}
      InputProps={{ readOnly: readOnly, }}
      InputLabelProps={{ shrink: true, }}
      {...other}
    />
  );
}

function Border(props) {
  return (
    <Paper style={{ marginTop: '10px', borderStyle: "solid", width: '100%', borderWidth: "1px" }}>
      {props.children}
    </Paper>
  );
}

function CheckboxWithLabel(props) {
  return (
    <InputLabel>
      <Checkbox name={props.name} checked={props.checked} disabled={props.disabled} onChange={props.onChange} data-cy={"checkbox " + props.label}></Checkbox>
      {props.label}
    </InputLabel>
  );
}

function otherFilesDialog(props) {

  const deleteCustomFolder = (event) => {
    // remove folder in dialog 
    let temp = [...props.obj.files];
    temp.splice(parseInt(event.currentTarget.id), 1);
    props.addObjValue('files', temp);
  };

  const addCustomFile = () => {
    // adds empty entry 
    let temp = [...props.obj.files];
    temp.push({ name: '', value: '' })
    props.addObjValue('files', temp);
  };

  const handleChange = (event) => {
    let temp = [...props.obj.files];
    if (!event.target.id.includes('name')) {
      // value change
      temp[event.target.id]['value'] = event.target.value;
    } else {
      // name change
      // _TODO: if (event.target.value !== '' && !Object.keys(props.obj).includes(event.target.value)) 
      let id = parseInt((event.target.id).match(/\d+/)[0]);
      temp[id]['name'] = event.target.value;
    }
    props.addObjValue('files', temp);
  }

  let customFilesList =
    props.obj.files.map((el, i) =>
      <Grid container justifyContent="center" alignItems="center" key={'' + i}>
        <Grid item xs={7}>
          <TextBox label='Name' id={'name' + i} onChange={handleChange} onInput={props.handleInput} value={el['name']} errors={props.errors} data-cy={'name' + i}/>
        </Grid>
        <Grid item xs={2.5}>
          <TextBox label='Anzahl' id={'' + i} onChange={handleChange} onInput={props.handleInput} value={el['value']} type={'number'} errors={props.errors} />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Ordner löschen">
            <IconButton onClick={deleteCustomFolder} id={'' + i} disabled={props.errors.includes('' + i)} data-cy={'delete' + i}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        {customFilesList}
      </Grid>
      <Grid item xs={2}>
        <Tooltip title="Ordner hinzufügen">
          <div>
            <IconButton onClick={addCustomFile} id={'add'} data-cy="add">
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

function importAdressBookDialog(props) {

  let handlePersonChange = (event) => {
    props.addObjValue('PersonID', event.value);
  };

  return (
    <Box minHeight={375} minWidth={250}>
      <PersonGlobalSelector
        handleChange={handlePersonChange}
        handleInputValueChange={() => { }}
      />
    </Box>
  );
}

// main Component, either as editDialog or view with or without readOnly 
function ArchiveModule(props) {
  // tipp: use VS-Code fold-all (ctrl+k followed by ctrl+0) and unfold-all (ctrl+k followed by ctrl+j) command  
  const { handleChange, handleInput } = props;
  const [openDialogs, setOpenDialogs] = useState({ files: false, adressBook: false })

  // Dialogs open and close
  const handleOpen = name => (event) => {
    let newOpenDialogs = { ...openDialogs };
    newOpenDialogs[name] = true;
    setOpenDialogs(newOpenDialogs);
  }
  const handleClose = name => (event) => {
    let newOpenDialogs = { ...openDialogs };
    newOpenDialogs[name] = false;
    setOpenDialogs(newOpenDialogs);
  }

  // Dialogs Save
  const handleSaveFilesDialog = (editedCustomFiles) => {
    let newOpenDialogs = { ...openDialogs };
    newOpenDialogs["files"] = false;
    setOpenDialogs(newOpenDialogs);
    props.addObjValue('custom_folders', editedCustomFiles['files']);
  };
  const handleSaveAdressDialog = (personID) => {
    let newOpenDialogs = { ...openDialogs };
    newOpenDialogs["adressBook"] = false;
    setOpenDialogs(newOpenDialogs);

    const person = props.additionalData['persons'].find(el => el['id'] === personID['PersonID']);
    let dict = { recipient_name: ' ', recipient_phone: ' ', recipient_mail: ' ', recipient_department: ' ' };

    dict['recipient_name'] = (person['last_name'] + ', ' + person['first_name']);
    dict['recipient_phone'] = person['phone_number'];
    dict['recipient_mail'] = person['email'];
    dict['recipient_department'] = person['departments'][0]['name'];
    Object.keys(dict).forEach((key) => dict[key] ? props.addObjValue(key, dict[key]) : props.addObjValue(key, ''))
  }


  const handleFileDelete = (file) => {
    fetch('/trials/' + props.obj.trial_id + '/archives/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
      .then(function (response) { return response.json(); })
      .then(function (data) {
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          props.snackBarMessage('Erfolgreich gelöscht.', 'success');
        } else {
          props.snackBarMessage('Es ist ein Fehler aufgetreten.', 'error');
        }
      });
  }

  const handleCheckboxChecked = (event, checked) => {
    if (checked) {
      props.addObjValue(event.target.name, '');
    } else {
      // _TODO: props.obj[(''+event.target.name)] alten wert Cashen
      props.addObjValue(event.target.name, 0);
    }
  }

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {/* Trial Informations: */}
      <Grid item xs={11}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextBox label='EudraCT' id='eudract_num' readOnly={true} value={props.obj.trial.eudract_num} />
          </Grid>
          <Grid item xs={3}>
            <TextBox label='Akronym' id='short' readOnly={true} value={props.obj.trial.short} />
          </Grid>
          <Grid item xs={6}>
            <TextBox label='Sponsor' id='sponsor' readOnly={true} value={props.obj.trial.sponsor} />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <CheckboxWithLabel name={'archive_for_sponsor'} label={'Abwicklung der Archivierung für Sponsor übernehmen'} checked={props.obj.archive_for_sponsor} disabled={props.readOnly} onChange={handleChange}></CheckboxWithLabel>
            </InputLabel>
          </Grid>
        </Grid>
      </Grid>
      {/* Archive Data: */}
      <Grid item xs={11}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBox label='Archivar:in (Name, Vorname)' id='archivist' readOnly={props.readOnly} value={props.obj.archivist} onChange={handleChange} errors={props.errors} data-cy={props.readOnly ? "" : "archivist"} />
          </Grid>
          <Grid item xs={11}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={8.5}>
                <Text> Übergebene Unterlagen als Ordneranzahl:</Text>
              </Grid>
              <Grid item xs={3.5} />
              <Grid item xs={2.2}>
                <TextBox label='iTMF' id='itmf_folder_qty' readOnly={props.readOnly} value={props.obj.itmf_folder_qty} onChange={handleChange} onInput={handleInput} type={props.readOnly ? 'string' : "number"} errors={props.errors} />
              </Grid>
              <Grid item xs={2.2}>
                <TextBox label='CRF' id='crf_folder_qty' value={props.obj.crf_folder_qty} readOnly={props.readOnly} onChange={handleChange} onInput={handleInput} type={props.readOnly ? 'string' : "number"} errors={props.errors} />
              </Grid>
              <Grid item xs={2.2}>
                <TextBox label='sTMF' id='stmf_folder_qty' readOnly={props.readOnly} value={props.obj.stmf_folder_qty} onChange={handleChange} onInput={handleInput} type={props.readOnly ? 'string' : "number"} errors={props.errors} />
              </Grid>
              <Grid item xs={1}>
                {!props.readOnly &&
                  <Tooltip title="Weitere Ordner">
                    <IconButton onClick={handleOpen('files')} component="label" data-cy="files">
                      <FolderIcon color="primary" />
                    </IconButton>
                  </Tooltip>}
              </Grid>
              <Grid item xs={4.4}>
                <CheckboxWithLabel name='otherFolders' checked={props.obj.custom_folders ? props.obj.custom_folders.length !== 0 : false} label="Weitere Ordner" disabled={true} ></CheckboxWithLabel>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <TextBox label='Anzahl Kartons' id='packaging_qty' readOnly={props.readOnly} value={props.obj.packaging_qty} onChange={handleChange} onInput={handleInput} type={props.readOnly ? 'string' : "number"} errors={props.errors} />
          </Grid>
          <Grid item xs={4}>
            <TextBox label='Archivierungsfrist (Jahre)' id='archiving_duration' readOnly={props.readOnly} value={props.obj.archiving_duration} onInput={handleInput} onChange={handleChange} type={props.readOnly ? 'string' : "number"} errors={props.errors} />
          </Grid>
          <Grid item xs={4}>
            <TextBox label='Vernichtungsdatum' type='date' id='destruction_date' readOnly={props.readOnly} value={props.obj.destruction_date} onChange={handleChange} errors={props.errors} />
          </Grid>
          <Grid item xs={4}>
            <CheckboxWithLabel name='packaging_qty' checked={props.obj.packaging_qty === '' || props.obj.packaging_qty === null} onChange={handleCheckboxChecked} label="Nicht zutreffend" disabled={props.readOnly} ></CheckboxWithLabel>
          </Grid>
          <Grid item xs={4}>
            <CheckboxWithLabel name='archiving_duration' checked={props.obj.archiving_duration === '' || props.obj.archiving_duration === null} onChange={handleCheckboxChecked} label="Unbekannt" disabled={props.readOnly}></CheckboxWithLabel>
          </Grid>
          <Grid item xs={4}>
            <CheckboxWithLabel name='destruction_date' checked={props.obj.destruction_date === '' || props.obj.destruction_date === null} onChange={handleCheckboxChecked} label="Unbekannt" disabled={props.readOnly}></CheckboxWithLabel>
          </Grid>
        </Grid>
      </Grid>
      {/* Recipient Informations: */}
      <Grid item xs={11}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Text>Empfänger:in der Studienunterlagen:</Text>
          </Grid>
          <Grid item xs={2}>
            {!props.readOnly &&
              <Tooltip title="Empfänger:in importieren">
                <IconButton size='large' onClick={handleOpen('adressBook')} data-cy="adressbook" >
                  <ImportContactsIcon color="primary" />
                </IconButton>
              </Tooltip>}
          </Grid>
          <Grid item xs={12}>
            <TextBox label='Name, Vorname' id='recipient_name' readOnly={props.readOnly} value={props.obj.recipient_name} onChange={handleChange} errors={props.errors} />
          </Grid>
          <Grid item xs={4.5}>
            <TextBox label='Telefon-Nr' id='recipient_phone' readOnly={props.readOnly} value={props.obj.recipient_phone} onChange={handleChange} errors={props.errors} />
          </Grid>
          <Grid item xs={7.5}>
            <TextBox label='E-Mail-Adresse' id='recipient_mail' readOnly={props.readOnly} value={props.obj.recipient_mail} onChange={handleChange} type={"email"} errors={props.errors} />
          </Grid>
          <Grid item xs={12}>
            <TextBox label='Abteilung' id='recipient_department' readOnly={props.readOnly} value={props.obj.recipient_department} onChange={handleChange} errors={props.errors} />
          </Grid>
          <Grid item xs={12}>
            <Text>Abholung erfolgt durch:</Text>
          </Grid>
          <Grid item xs={8}>
            <TextBox label='Name, Vorname' id='pickup_name' readOnly={props.readOnly} value={props.obj.pickup_name} onChange={handleChange} errors={props.errors} />
          </Grid>
          <Grid item xs={4}>
            <TextBox label='Abholdatum' type='date' id='pickup_date' readOnly={props.readOnly} value={props.obj.pickup_date} onChange={handleChange} errors={props.errors} />
          </Grid>
        </Grid>
      </Grid>
      {/* Additions: */}
      <Grid item xs={11}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <CheckboxWithLabel name={'recipient_confirmation'} label="Eingangsbestätigung eingetroffen" disabled={props.readOnly} checked={props.obj.recipient_confirmation} onChange={handleChange}></CheckboxWithLabel>
          </Grid>
          {props.readOnly ?
            <Grid item xs={12}>
              <ListOfFiles
                files={props.obj.files}
                handleDelete={handleFileDelete}
                confirmationDialog={true}
                // _TODO: make disabled true if no can do :) 
                disabled={false} />
            </Grid> : null}
        </Grid>
      </Grid>
      {/* Dialogs: */}
      <div>
        {openDialogs.files ?
          <EditDialog
            title="Weitere Ordner"
            obj={{ files: props.obj.custom_folders }}
            open={openDialogs.files}
            handleSave={handleSaveFilesDialog}
            handleClose={handleClose('files')}
            Fields={otherFilesDialog}
          /> : null}
        {openDialogs.adressBook ?
          <EditDialog
            title="Adressbuch"
            obj={props.additionalData['persons']}
            open={openDialogs.adressBook}
            handleSave={handleSaveAdressDialog}
            handleClose={handleClose('adressBook')}
            Fields={importAdressBookDialog}
          /> : null}
      </div>
    </Grid>
  );
}

class TrialArchive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      archive: { ...props.archive },
      dialogOpen: false,
      openDialogs: { archive: false, upload: false, newNote: false, editNote: false },
      selectedNoteIds: [],
      selectedNotes: [],
      expandedTable: { Notizen: true, Archive: true },
    }
  }

  snackBarMessage = (message, variant) => {
    this.props.enqueueSnackbar(message, { variant: variant });
  }

  // Dialogs Open and Close
  handleOpen = (name) => (event) => {
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs[name] = true;
    this.setState({ openDialogs: newOpenDialogs });
  }
  handleClose = (name) => (event) => {
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs[name] = false;
    this.setState({ openDialogs: newOpenDialogs });
  }
  handleCopy = () => {
    console.log('copied');
  }
  handleCopyText = () => {
    let ret = { ... this.state.archive };
    // only relevant information
    delete ret['trial'];
    delete ret['polymorphic_notes'];
    delete ret['files'];
    return JSON.stringify(ret);
  }

  // methods for ArchiveModuleEditDialog
  handleArchiveSave = (archive) => {
    let currentComponent = this;
    fetch('/trials/' + this.state.archive.trial_id + '/archives/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        archive: archive
      }),
      credentials: 'include',
    })
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.response == 1) {
          // closes dialog without button pressed
          let newOpenDialogs = { ...currentComponent.state.openDialogs };
          newOpenDialogs['archive'] = false;
          currentComponent.setState({ openDialogs: newOpenDialogs });
          currentComponent.snackBarMessage('Erfolgreich editiert.', 'success')
        } else {
          currentComponent.snackBarMessage('Es ist ein Fehler aufgetreten.', 'error')
        }
      });
  }
  handleArchivesReceived = (message) => {
    console.log("received Archive Board");
    console.log(message);
    if (message.archive && message.archive.trial_id == this.state.archive.trial_id) {
      this.setState({ archive: message.archive });
      this.snackBarMessage(('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.archive.trial.short), 'success')
    }
  }

  handleFileUpload = () => {
    console.log('uploaded')
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs['upload'] = false;
    this.setState({ openDialogs: newOpenDialogs });
  }

  handleExpandChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({ expandedTable: newExpandState });
  }

  // methods for Note 
  handleNewNoteOpen = event => {
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs["newNote"] = true;
    this.setState({ openDialogs: newOpenDialogs });
  }
  handleEditNoteOpen = event => {
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs["editNote"] = true;
    this.setState({ openDialogs: newOpenDialogs });
  }
  handleNewNoteClose = event => {
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs["newNote"] = false;
    this.setState({ openDialogs: newOpenDialogs });
  }
  handleEditNoteClose = event => {
    let newOpenDialogs = { ... this.state.openDialogs };
    newOpenDialogs["editNote"] = false;
    this.setState({ openDialogs: newOpenDialogs });
  }
  handleDeleteNote = obj => {
    let currentComponent = this;
    fetch('/trials/' + this.state.archive.trial_id + '/archives/delete_notes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        note_ids: this.state.selectedNoteIds,
      }),
      credentials: 'include',
    })
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.response == 1) {
          currentComponent.setState({ selectedNotes: [], selectedNoteIds: [], selectedNoteIndices: [] });
          currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', { variant: 'success' });
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', { variant: 'error' });
        }
      });
  }
  handleNoteChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedNote: allObjectsSelected,
      selectedNoteIds: allRowsSelected,
    });
  };
  handleNoteDoubleClick = (event, obj) => {
    var allObjectsSelected = [obj];
    var selectedNoteIds = [obj.id];
    this.setState({
      selectedNotes: allObjectsSelected,
      selectedNoteIds: selectedNoteIds,
    });
    this.handleEditNoteOpen();
  }
  handleNewNoteSave = (note) => {
    let currentComponent = this;
    this.setState({ editNoteOpen: false });
    fetch('/trials/' + this.state.archive.trial_id + '/archives/new_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.response == 1) {
          currentComponent.handleNewNoteClose();
          currentComponent.props.enqueueSnackbar('Erfolgreich erstellt', { variant: 'success' });
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', { variant: 'error' });
        }
      });
  }
  handleEditNoteSave = (note) => {
    let currentComponent = this;
    this.setState({ editNoteOpen: false });
    fetch('/trials/' + this.state.archive.trial_id + '/archives/update_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.response == 1) {
          currentComponent.handleEditNoteClose();
          currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', { variant: 'success' });
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', { variant: 'error' });
        }
      });
  }

  render() {
    const notes = [].concat(this.state.archive.polymorphic_notes);
    const noteColumns = [
      {
        id: "entry_date",
        label: "Datum",
        numeric: false,
        disablePadding: false,
        date: true,
        type: "date",
      },
      {
        id: "note",
        label: "Notiz",
        numeric: false,
        disablePadding: false,
      },
    ];

    return (
      <Grid container>
        {/* main component */}
        <Grid container spacing={1}>
          <ActionCableConsumer
            channel={{ channel: "ArchivesChannel" }}
            onReceived={this.handleArchivesReceived}
          />
          <Grid item xs={8}>
            <Border>
              <Grid container spacing={1} justifyContent='center'>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                    <Typography variant="h6" id="tableTitle" onClick={() => this.handleExpandChange(!this.state.expandedTable.Archive, 'Archive')}>
                      {this.state.expandedTable.Archive ? <ExpandMore /> : <ExpandLess />} {'Archivierung'}
                    </Typography>
                    <Toolbar variant='dense'>
                      <Can I="update" a="Archive">
                        <Tooltip title="Archivierung bearbeiten">
                          <IconButton aria-label="Edit" data-cy="edit" onClick={this.handleOpen('archive')} >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eingangsbestätigung und andere Anhänge hochladen">
                          <IconButton size='large' onClick={this.handleOpen('upload')} component="label">
                            <UploadIcon />
                          </IconButton>
                        </Tooltip>
                      </Can>
                      <CopyToClipboard onCopy={this.handleCopy} text={this.handleCopyText()}>
                        <Tooltip title="In die Zwischenablage kopieren">
                          <IconButton aria-label="copie" >
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    </Toolbar>
                  </Box>
                </Grid>
                <Collapse in={this.state.expandedTable.Archive} timeout="auto" unmountOnExit>
                  <Grid item xs={12}>
                    <ArchiveModule obj={this.state.archive} snackBarMessage={this.snackBarMessage} readOnly={true}></ArchiveModule>
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container justifyContent="center">
                      <Grid item xs={4}>
                        <GeneratePDFButton
                          pdfContent={<HandoverStudyPDF {...this.state.archive} />}
                          pdfTitle={'test'}
                          buttonText={'generieren'}
                          dialog={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Border>
          </Grid>
          {
            this.state.openDialogs.archive ?
              <EditDialog
                title="Archivierung bearbeiten"
                obj={this.state.archive}
                open={this.state.openDialogs.archive}
                additionalData={{ persons: this.props.persons, snackBarMessage: this.snackBarMessage }}
                handleSave={this.handleArchiveSave}
                handleClose={this.handleClose('archive')}
                Fields={ArchiveModule}
              /> : null
          }
          {
            this.state.openDialogs.upload ?
              <FileUploadDialog
                open={this.state.openDialogs.upload}
                saveUrl={'/trials/' + this.props.archive.trial_id + '/archives/upload_file'}
                handleFileUpload={this.handleFileUpload}
                handleClose={this.handleClose('upload')}
              /> : null
          }
        </Grid >
        {/* Notes component */}
        < Grid item xs={12} >
          {(this.state.openDialogs.editNote && this.state.selectedNotes.length == 1) &&
            <EditNoteDialog
              open={this.state.openDialogs.editNote}
              selectedNote={this.state.selectedNotes[0]}
              handleSave={this.handleEditNoteSave}
              handleClose={this.handleEditNoteClose}
            />
          }
          {
            (this.state.openDialogs.newNote) &&
            <NewNoteDialog
              open={this.state.openDialogs.newNote}
              handleSave={this.handleNewNoteSave}
              handleClose={this.handleNewNoteClose}
            />
          }
          <Grid container justify="center">
            <EnhancedTable
              title={"Notizen"}
              data={(notes) ? notes : []}
              columns={noteColumns}
              multipleSelectable
              selectedIds={this.state.selectedNoteIds}
              selectedObjects={this.state.selectedNotes}
              onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleNoteChange(rowData, allRowsSelected, allObjectsSelected)}
              handleAddClick={this.handleNewNoteOpen}
              handleDeleteClick={this.handleDeleteNote}
              rowsPerPage={50}
              orderBy="entry_date"
              order="desc"
              onDoubleClick={this.handleNoteDoubleClick}
              allData={notes}
              selectable
              expanded={this.state.expandedTable["Notizen"]}
              handleExpandChange={this.handleExpandChange}
              superType="Trial"
            />
          </Grid>
        </Grid >
      </Grid >
    );
  }
}

export default withSnackbar(TrialArchive);