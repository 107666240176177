import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly, selectOptions, selectDiagnosisOptions } = props;
  console.log(props);
  const required = [];
  if(obj.heroes_aya == 0 || obj.heroes_aya == 1 || obj.heroes_aya == 2){
    Object.assign(props.obj, {heroes_aya: obj.heroes_aya});
  }
  else if(obj.heroes_aya == 3){
    Object.assign(props.obj, {heroes_aya: 1});
  }
  return (
    <Box
      component="form"
      // sx={{
      //   '& .MuiTextField-root': { m: 1, width: '25ch' },
      // }}
      noValidate
      autoComplete="off"
    >
      <div>
            <TextField
              autoFocus
              margin="dense"
              id="pseudonym"
              label="Pseudonym"
              value={obj.pseudonym}
              // fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              handleChange={handleChange}
              required={props.requiredFields.includes("pseudonym")}
              // error={!obj.name}
              InputProps={{
                readOnly: readOnly,
              }}
              type="string"
              size="small"
            />
            {/* <TextField
              autoFocus
              margin="dense"
              id="master_pseudonym"
              label="Master Pseudonym"
              value={obj.master_pseudonym}
              // fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              handleChange={handleChange}
              required={props.requiredFields.includes("master_pseudonym")}
              // error={!obj.name}
              InputProps={{
                readOnly: readOnly,
              }}
              type="string"
              size="small"
            /> */}
            <TextField
              margin="dense"
              id="master_pseudonym"
              label="Master Pseudonym"
              value={obj.master_pseudonym}
              // fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              required={props.requiredFields.includes("master_pseudonym")}
              // error={!obj.name}
              InputProps={{
                readOnly: readOnly,
              }}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="gender"
              label="Geschlecht"
              value={obj.gender}
              type="select"
              options={[{value: "1", label: "Männlich"},{value: "2", label: "Weiblich"}]}
              // fullWidth
              variant="outlined"
              style={{zIndex: 990}}
              handleChange={handleChange}
              required={props.requiredFields.includes("gender")}
              // InputProps={{
              //   readOnly: readOnly,
              // }}
              // options={[{label: "weiblich", value: "w"}, {label: "männlich", value: "m"}, {label: "andere", value: "?"}, {label: "unbekannt", value: null}]}
              size="small"
            />
            <TextField
              margin="dense"
              id="date_of_birth"
              label="Geburtsdatum"
              value={obj.date_of_birth}
              // fullWidth
              variant="outlined"
              style={{zIndex: 988}}
              handleChange={handleChange}
              required={props.requiredFields.includes("date_of_birth")}
              InputProps={{
                readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("date_of_birth"))),
              }}
              type="date"
              size="small"
            />
            <TextField
              margin="dense"
              id="heroes_aya"
              label="HeroesAya"
              value={obj.heroes_aya}
              type="select"
              options={[{value: 0, label: "Offen"},{value: 1, label: "Eingewilligt"}, {value: 2, label: "Abgelehnt"}, {value: " ", label: "Kein Heroes Aya"}]}
              // fullWidth
              variant="outlined"
              style={{zIndex: 990}}
              handleChange={handleChange}
              // required={props.requiredFields.includes("gender")}
              // InputProps={{
              //   readOnly: readOnly,
              // }}
              // options={[{label: "weiblich", value: "w"}, {label: "männlich", value: "m"}, {label: "andere", value: "?"}, {label: "unbekannt", value: null}]}
              size="small"
            />
            <TextField
              margin="dense"
              id="inclusion_date"
              label="Einschlussdatum"
              value={obj.inclusion_date}
              // fullWidth
              variant="outlined"
              style={{zIndex: 988}}
              handleChange={handleChange}
              required={props.requiredFields.includes("inclusion_date")}
              InputProps={{
                readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("inclusion_date"))),
              }}
              type="date"
              size="small"
            />
            <TextField
              margin="dense"
              id="storage_number_sarcbob"
              label="Sarcbop-Schranknr."
              value={obj.storage_number_sarcbob}
              // fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              handleChange={handleChange}
              required={props.requiredFields.includes("storage_number_sarcbob")}
              InputProps={{
                readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("storage_number_sarcbob"))),
              }}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="institution_id"
              label="Einrichtung"
              value={obj.institution_id}
              // fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              handleChange={handleChange}
              required={props.requiredFields.includes("institution_id")}
              InputProps={{
                readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("institution_id"))),
              }}
              options={selectOptions["institutionOptions"]}
              type="select"
              size="small"
            />
            <TextField
              margin="dense"
              id="primary_sarcdiagnosisid"
              label="Primäre Diagnose"
              value={obj.primary_sarcdiagnosisid}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              handleChange={handleChange}
              required={props.requiredFields.includes("primary_sarcdiagnosisid")}
              InputProps={{
                readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("primary_sarcdiagnosisid"))),
              }}
              options={selectOptions["diagnosisOptions"]}
              type="select"
              size="small"
            />
        </div>
      </Box>
  );
}
