import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

import SiteCustomFields from './SiteCustomFields.jsx';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';


class EditSiteCustomFieldsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, site: this.props.site};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.site != this.props.site) {
      this.setState({site: this.props.site});
    }
  }

  componentDidMount(props) {
    // let currentComponent = this;
    // this.setState({});
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let site = {...this.state.site};
    const ind = site.custom_string_fields.findIndex(csf => csf.label == name);
    if (ind != -1) {
      site.custom_string_fields[ind] = {...site.custom_string_fields[ind], label: name, text: value};
    } else {
      site.custom_string_fields.push({label: name, text: value});
    }
    currentComponent.setState({ site: site });
  };

  handleClose = event => {
    this.props.handleClose();
  }

  render(props) {
    const saveable = true;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '700px'}}}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
          <DialogTitle id="form-dialog-title">Benutzerdefinierte Felder editieren</DialogTitle>
          <Tooltip title="Felder hinzufügen">
            <IconButton aria-label="Edit" onClick={this.props.handleAddOpen}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: blue[500]}}>
              <SiteCustomFields
                site={this.state.site}
                readOnly={false}
                handleChange={this.handleChange}
                handleEditOpen={this.props.handleEditOpen}
                handleRemove={this.props.handleRemove}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.props.handleSave({
            site: this.state.site,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditSiteCustomFieldsDialog;
