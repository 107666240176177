import React from 'react';
import CreatableSelect from 'react-select/creatable';
import chroma from 'chroma-js';

const customControlStyles = {
    width: "200px",
};

const testselected = { value: '9', label: 'test1 (ID: 9)' }

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    let color = chroma('black');
    if (data.color) {
      color = chroma(data.color);
    }
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

export default class InstitutionSelector extends React.Component {

  constructor(props) {
    super(props);
    let currentComponent = this;
    // this.trials= this.props.trials;
    // this.state: {value: this.props.selectedTrial};
    // this.selectedTrial= this.props.selectedTrial;
    // fetch('/trials/get_trials')
    // .then(function(response){return response.json();})
    // .then(function(data){
    //   currentComponent.setState({ trials: data.trials, selectedTrial: currentComponent.props.selectedTrial });
    // });
    this.state = {selectedInstitution: {}, institutions: {}, selectedInstitutionObject: null};
    // this.onChange = this.onChange.bind(this);
    this.form = React.createRef();
    // this.state = this.props.selectedTrial;
  }

  onChange = (event) => {
    let selectedInstitutionObject = this.state.institutions[this.state.institutions.findIndex(institution => institution.id == event.value)];
    this.setState({selectedInstitution: event, selectedInstitutionObject: selectedInstitutionObject});
    this.props.handleChange(event, selectedInstitutionObject);
    // this.onSubmit(event);
    // this.onSubmit(event)
    // this.form.submit()
    // this.refs.parentForm.submit();
    // { this.form.submit()}
    // handleSubmit(event);
  }
  // componentDidUpdate() {
  //   this.onSubmit()
  // }

  componentDidMount(props) {
    if (this.props.selectedInstitutionId) {
      this.setState({selectedInstitution: {value: this.props.selectedInstitutionId}});
    }
    if (this.props.institutions) {
      let institutionsList = [];
      for (var i = 0; i < this.props.institutions.length; i++) {
        institutionsList.push({value: this.props.institutions[i].id, label: this.props.institutions[i].name });
        if (this.props.selectedInstitutionId == this.props.institutions[i].id) {
          this.setState({selectedInstitution: {value: this.props.institutions[i].id, label: this.props.institutions[i].name, backgroundColor: 'white' }});
        }
      }
      this.setState({ institutions: this.props.institutions, institutionsList: institutionsList });
    } else {
      this.updateInstitutions();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.institutions !== prevProps.institutions) {
      let institutionsList = [];
      for (var i = 0; i < this.props.institutions.length; i++) {
        institutionsList.push({value: this.props.institutions[i].id, label: this.props.institutions[i].name });
        if (this.props.selectedInstitutionId == this.props.institutions[i].id) {
          this.setState({selectedInstitution: {value: this.props.institutions[i].id, label: this.props.institutions[i].name, backgroundColor: 'white' }});
        }
      }
      this.setState({ institutions: this.props.institutions, institutionsList: institutionsList });
    }
  }

  updateInstitutions = (props) => {
    let currentComponent = this;
    let institutionsList = [];
    fetch('/institutions/index_all', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data.user);
      // let trialsList = [];
      for (var i = 0; i < data.institutions.length; i++) {
        institutionsList.push({value: data.institutions[i].id, label: data.institutions[i].name + "(" + data.institutions[i].id + ")"});
        if (currentComponent.props.selectedInstitutionId == data.institutions[i].id) {
          currentComponent.setState({selectedInstitution: {value: data.institutions[i].id, label: data.institutions[i].name, backgroundColor: 'white' }});
        }
      }
      currentComponent.setState({ institutions: data.institutions, institutionsList: institutionsList });
      // currentComponent.setState({ trials: data.trials });
    });
  }

  // onSubmit = (event) => {
  //   if ((this.props.action != 'show') && (this.props.action != undefined)) {
  //     window.location.assign('/' + this.props.controller + '/' + event.value + '/' + this.props.action);
  //   }
  //   else {
  //     window.location.assign('/' + this.props.controller + '/' + this.state.selectedTrial.value);
  //   }
  //   // <Redirect to='/target' />
  //   // this.props.history.push(`/target`)
  //   // const data = new FormData(this.state.value);
  //   // this.form.current.submit(data);
  //   // event.preventDefault();
  // }

  formatCreate = (inputValue) => {
    return (<p> {inputValue} neu anlegen</p>);
  };

  render() {
    // const trialsList = [];
    // for (var i = 0; i < this.state.trials.length; i++) {
    //   trialsList.push({value: this.state.trials[i].id.toString(), label: this.state.trials[i].short + "(" + this.state.trials[i].id + ")"});
    // }
    return (
      <div style={{width: "100%"}}>
        <form ref={this.form} >
        <input id="id" ref="inputField" type="hidden"  />
          <CreatableSelect
            value={ this.state.selectedInstitution }
            // defaultValue={"Bitte wählen"}
            onChange={this.onChange}
            options={this.state.institutionsList}
            styles={colourStyles}
            onCreateOption={this.props.onCreateOption}
            formatCreateLabel={this.formatCreate}
            // menuIsOpen={true}
            // className={"bg-warning"}
            isDisabled={this.props.disabled}
          />
        </form>
      </div>
      // <div> trials </div>
    );
  }
}
