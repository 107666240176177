import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function ProcessFields(props) {
  // const classes = useStyles();
  const { process, handleChange, handleChange2, handleChangeCheckbox, readOnly, processCategoryTypeList, processCategoryList, statusList } = props;
  const selectedStatus = statusList.find(stat => process.status == stat.value);
  const selectedProcessCategory = (process.process_category) ? processCategoryList.find(proc => process.process_category == proc.value) : null;
  // processCategoryList.find(proc => process.process_category == proc.value);
  const processTypeList = (selectedProcessCategory) ? processCategoryTypeList[selectedProcessCategory.value] : [];
  let selectedProcessType = (selectedProcessCategory && process.process_type) ? processCategoryTypeList[selectedProcessCategory.value].find(type => type.value == process.process_type) :null;
  if (processTypeList.length == 1) {
    selectedProcessType = processTypeList[0];
  } else {
    selectedProcessType = (processTypeList) ? processTypeList.find(proc => process.process_type == proc.value) : null;
  }
  return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          { readOnly ?
          <TextField
            autoFocus
            margin="dense"
            id="process_category"
            label="Kategorie"
            value={process.process_category}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!process.process_category}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (process.process_category != null) }}
            type="string"
          />
          :
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" error={!process.process_category} >Kategorie</InputLabel>
              <Select
                autoFocus
                id="process_category"
                name="process_category"
                value={selectedProcessCategory}
                options={processCategoryList}
                style={{width: "100%"}}
                onChange={handleChange2}
                error={!process.process_category}
              />
            </FormControl>
          }
          { readOnly ?
          <TextField
            margin="dense"
            id="process_type"
            label="Typ"
            value={process.process_type}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!process.process_type}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (process.process_type != null) }}
            type="string"
          />
          :
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 998}}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" error={!process.process_type} >Typ</InputLabel>
              <Select
                id="process_type"
                name="process_type"
                value={selectedProcessType}
                options={processTypeList}
                style={{width: "100%"}}
                onChange={handleChange2}
                error={!process.process_type}
              />
            </FormControl>
          }
          <TextField
            margin="dense"
            id="name"
            label="Titel"

            value={process.name}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!process.name}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (process.name != null) }}
            type="string"
          />
          <TextField
            id="status_date"
            label="Status Datum"
            type="date"
            // value={process.status_date}
            defaultValue={process.status_date}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            disabled={props.statusDateNotEditable}
          />
          <FormGroup aria-label="position" name="position" row>
            <FormControlLabel
              style={{zIndex: 1}}
              control={
                <Checkbox
                  checked={process.automatic_reminders_by_notification}
                  onChange={handleChangeCheckbox}
                />
              }
              name="automatic_reminders_by_notification"
              label="Automatische Erinnerungen per Notification"
            />
            <FormControlLabel
              style={{zIndex: 1}}
              control={
                <Checkbox
                  checked={process.automatic_reminders_by_mail}
                  onChange={handleChangeCheckbox}
                />
              }
              name="automatic_reminders_by_mail"
              label="Automatische Erinnerungen per Mail"
            />
          </FormGroup>
        </div>
  );
}
