import React from 'react';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
// import {
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';

import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import RightIcon from '@mui/icons-material/ArrowRight';
import LeftIcon from '@mui/icons-material/ArrowLeft';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
  }),
}

export default function TimeRegistrationFields(props) {
  const {classes, obj, disabled} = props;
  const selectedRoleOption = props.roleOptions.find(ro => ro.value == obj.role_id);
  const selectedServiceOption = props.serviceOptions.find(so => so.value == obj.service_id);
  let shownTime = "";
  if (obj.hours) {
    if (obj.decimalSelected) {
      shownTime = obj.hours + "h";
    } else {
      const hoursString = "" + obj.hours;
      let separatorIndex = hoursString.indexOf('.');
      const hh = (separatorIndex != -1) ? hoursString.substring(0, separatorIndex) : hoursString;
      let mm = (separatorIndex && separatorIndex != -1 && separatorIndex != hoursString.length) ? hoursString.substring(separatorIndex + 1, hoursString.length) : "00";
      if (props.obj.decimalSelected) {
        shownTime = hh + "," + mm + "h";
      } else {
        mm = Math.round((("0." + mm.padEnd(2,"0")) * 60)).toString().padStart(2, "0");
        shownTime = hh + "h " + mm + "m";
      }
    }
  }
  return (
    // <Paper className={classes.root} style={{borderStyle: "solid", borderWidth: "1px"}}>
      <FormControl variant="outlined" margin="normal" fullWidth style={{backgroundColor: "white"}} >
        <FormGroup aria-label="position" name="position" row>
          <FormControl variant="outlined" margin="dense" style={{zIndex: 999, width: '150px', margin: '10px', fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]}} >
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Rolle</InputLabel>
            <Select
              // onChange={props.handleChange2}
              options={props.roleOptions}
              value={selectedRoleOption}
              onChange={(newValue, actionMeta) => props.handleChange2(newValue, actionMeta)}
              name="role_id"
              styles={customStyles}
            />
          </FormControl>
          <TextField
            id="entry_date"
            label="Datum"
            margin="dense"
            variant="outlined"
            type="date"
            onChange={props.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            value={obj.entry_date}
            // error={!obj.entry_date}
            // disabled={readOnly || props.dueDateNotEditable}
            size="small"
          />
          <Tooltip title="Vorheriger Tag">
            <IconButton aria-label="up" onClick={props.handlePrevDay} style={{padding: '5px'}}>
              <LeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Nächster Tag">
            <IconButton aria-label="up" onClick={props.handleNextDay} style={{padding: '5px'}}>
              <RightIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={
          <>
          <div>Zeiteingabe</div>
            <table>
              <tr> <th>Eingabe</th> <th>Interpretation hh:mm</th> <th>Interpretation dezimal"</th> </tr>
              <tr> <td>3</td> <td>0:03</td> <td>0,03</td></tr>
              <tr> <td>32</td> <td>0:32</td> <td>0,32</td></tr>
              <tr> <td>321</td> <td>3:21</td> <td>3,21</td></tr>
              <tr> <td>3.</td> <td>3:00</td> <td>3,00</td></tr>
              <tr> <td>.3</td> <td>0:30</td> <td>0,3</td></tr>
            </table>
          </>
          }>
            <TextField
              autoFocus
              id="time_str"
              margin="dense"
              label="Zeit"
              type="string"
              value={obj.time_str || ""}
              onChange={props.handleChangeTime}
              // InputLabelProps={{
              //   shrink: (obj.time_str),
              // }}
              style={{zIndex: 1, width: '100px'}}
              disabled={disabled}
              size="small"
            />
          </Tooltip>
          <TextField
            id="calcTime"
            margin="dense"
            label="Erkannte Zeit"
            type="string"
            value={shownTime || "Ungültiger Wert"}
            onChange={props.handleChangeTime}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1, width: '130px'}}
            disabled
            error={!shownTime}
            size="small"
          />
           
          <FormControl variant="outlined" margin="dense" style={{zIndex: 999, width: '200px', margin: '10px', fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]}} >
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Leistungsart</InputLabel>
            <Select
              // onChange={props.handleChange2}
              options={props.serviceOptions}
              value={selectedServiceOption}
              onChange={(newValue, actionMeta) => props.handleChange2(newValue, actionMeta)}
              name="service_id"
              styles={customStyles}
            />
          </FormControl>
          {(props.saveButton) &&
          <Tooltip title="Speichern">
            <IconButton type="submit" aria-label="Save" size="small" disabled={(!obj.hours || !obj.role_id || !obj.service_id || !obj.entry_date)}>
              <SaveIcon />
            </IconButton>
          </Tooltip>}
          {/* </div> */}
          <FormControlLabel
            label="hh:mm"
            control={<></>}
            labelPlacement="end"
            style={{marginLeft: '5px'}}
          />
          <FormControlLabel
            value={obj.decimalSelected}
            control={<Switch checked={obj.decimalSelected} color="primary" />}
            label="Dezimalsystem"
            labelPlacement="end"
            onChange={props.handleDecimalSelectedChange}
          />
        </FormGroup>
      </FormControl>
    // </Paper>
  )
}