import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import moment from 'moment'
import "moment/locale/de";

moment.locale("de")

import TaskFields from './TaskFields.jsx';
import TaskPaper from './TaskPaper.jsx';

class InfoTaskDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { obj: props.obj};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props.obj)
    if (this.props.obj !== prevProps.obj) {
      this.setState({ obj: this.props.obj });
    }
  }

  handleChange = event => {
    // console.log("handleChange");
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    currentComponent.setState({ obj: newObj });
  };

  handleChange2 = (event, meta) => {
    const value = event.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    this.setState({ obj: newObj });
  }

  handleChangeDate = (date, name) => {
    let newObj = {...this.state.obj};
    newObj[name] = moment(date).startOf("day").add(2, 'hour').toISOString().substr(0,10);
    if (this.props.previousProcessStep && ((this.props.previousProcessStep.date_status && (this.props.previousProcessStep.status == "done")) || this.props.previousProcessStep.date_deadline)) {
      let date1 = null;
      if (this.props.previousProcessStep.date_status) {
        date1 = new moment(this.props.previousProcessStep.date_status);
      } else if (this.props.previousProcessStep.date_deadline) {
        date1 = new moment(this.props.previousProcessStep.date_deadline);
      }
      const date2 = new moment(date);
      let momentDiff = moment.duration(date2.diff(date1)).days();
      let difference = Math.abs(parseInt(momentDiff));
      newObj["deadline_days"] = difference;
    }
    // console.log(newObj, moment.duration(moment(this.props.previousProcessStep.date_change).diff(moment(date))).days());
    this.setState({obj: newObj});
  };

  handleHasDeadlineChange = event => {
    let newObj = {... this.state.obj};
    newObj.date_deadline = null;
    newObj.deadline_days = null;
    this.setState({hasDeadline: (this.state.hasDeadline) ? false : true});
  }

  handleSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    if (obj.obj.taskable_type == "ProcessStep") {
      fetch('/trials/update_task', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          task: obj.obj,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        if (data.response == 1) {
          currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
          currentComponent.props.handleClose();
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/tasks/update', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          task: obj.obj,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        if (data.response == 1) {
          currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
          currentComponent.props.handleClose();
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
    // this.props.handleSave(Object.assign({}, this.state.obj, {deadline: deadline, date_status: date_status}));
  }

  render(props) {
    const { open, handleClose, taskStatusList, selectableTaskStatusList } = this.props;
    const { obj } = this.state;
    const saveable = obj.title && obj.status && obj.due_date;
    // define variables
    // console.log("editTaskDialog");
    const bgColor = ((obj.taskable_type == "Trial") || (obj.taskable_type == "ProcessStep")) ? blue[500] : 'white';
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '100%'}}}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Task Info</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start" style={{backgroundColor: bgColor}}>
            <Grid item xs={12} >
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <TaskPaper
                  obj={obj}
                  dueDateNotEditable={this.props.dueDateNotEditable}
                  handleChange={this.handleChange}
                  handleChange2={this.handleChange2}
                  readOnly={false}
                  taskStatusList={taskStatusList}
                  statusEditable={true}
                  selectableTaskStatusList={selectableTaskStatusList}
                  enqueueSnackbar={this.props.enqueueSnackbar}
                  handleDeleteTaskConfirmationDialogOpen={this.props.handleDeleteTaskConfirmationDialogOpen}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default InfoTaskDialog;
