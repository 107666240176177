import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from './TextField.jsx';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import Save from '@mui/icons-material/Save';
import LockOpen from '@mui/icons-material/LockOpen';
import Tooltip from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withSnackbar } from 'notistack';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import ShowTrialBoardSubmission from './ShowTrialBoardSubmission.jsx';
import ShowKlifoDiscussion from './ShowKlifoDiscussion.jsx';
import TrialBoardData from './TrialBoardData.jsx';
import AddTrialBoardSubmissionDialog from './AddTrialBoardSubmissionDialog.jsx';
import AddTrialBoardKlifoDialog from './AddTrialBoardKlifoDialog.jsx';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  MuiPaper: {
    elevation24: {
      height: 800,
      width: "100%",
    },
  },
});

class ShowTrialBoard extends React.Component {
  constructor(props) {
    super(props);
    const allPeopleOptions = props.allPeople.map(person => {
      return (
        {value: person.id, label: person.first_name + " " + person.last_name}
      )
    });
    let participantIds = [];
    if (props.trialBoard.participants) {
      participantIds = props.trialBoard.participants.map(participant => participant.id);
    }
    const selectedParticipants = allPeopleOptions.filter(po => participantIds.includes(po.value));
    this.state = { trialBoard: this.props.trialBoard, selectedParticipants: selectedParticipants, selectedParticipantsIds: participantIds, locked: true, openDialogs: {addSubmission: false}, user: props.user };
    this.form = React.createRef();
  }

  handleLockChange = event => {
    if (this.state.locked == false) {
      this.setState({locked: true})
    } else {
      this.setState({locked: false})
    }
  }

  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleParticipantsChange = (event) => {
    const newParticipantsIds = event.map(person => {
      return (
        person.value
      )
    });
    const allPeopleOptions = this.props.allPeople.map(person => {
      return (
        {value: person.id, label: person.first_name + " " + person.last_name}
      )
    });
    const selectedParticipants = allPeopleOptions.filter(po => newParticipantsIds.includes(po.value));
    this.setState({selectedParticipantsIds: newParticipantsIds, selectedParticipants: selectedParticipants});
  }

  handleSave = event => {
    const currentComponent = this;
    fetch('/trial_boards/'+ this.state.trialBoard.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...this.state.trialBoard,
        participant_ids: this.state.selectedParticipantsIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.setState({ locked: true });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleAddKlifoOpen = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["addKlifo"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleAddKlifoClose = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["addKlifo"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleAddSubmissionOpen = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["addSubmission"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleAddSubmissionClose = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["addSubmission"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleAddSubmissionSave = data => {
    const currentComponent = this;
    fetch('/trial_boards/'+ this.state.trialBoard.id + '/add_submission', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        submission_ids: data.submissionIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.setState({ locked: true });
        currentComponent.handleAddSubmissionClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleAddKlifoSave = data => {
    const currentComponent = this;
    fetch('/trial_boards/'+ this.state.trialBoard.id + '/add_klifo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        klifo_ids: data.klifoIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.setState({ locked: true });
        currentComponent.handleAddKlifoClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  // handleTrialBoardSubmissionReceived = message => {
  //   console.log("received Trial Board");
  //   console.log(message);
  //   if (message.new_trial_board_submission) {
  //     let submissions = [].concat(this.state.submissions);
  //     submissions.push(message.new_trial_board_submission);
  //     this.setState({ submissions: submissions });
  //     this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_trial_board_submission.short, {variant: 'success'});
  //   }
  //   // if (message.trial) {
  //   //   var trialBoards = [];
  //   //   let submissions = [].concat(this.state.submissions);
  //   //   const ind = submissions.findIndex(tb => tb.id == message.trial.id);
  //   //   submissions[ind] = message.trial;
  //   //   this.setState({ submissions: submissions });
  //   //   this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
  //   // }
  //   // if (message.deleted_trials) {
  //   //   let submissions = [].concat(this.state.submissions);
  //   //   let trialShorts = [];
  //   //   message.deleted_trials.forEach(function (trial_id, index) {
  //   //     const ind = submissions.findIndex(t => t.id == trial_id);
  //   //     trialShorts.push(submissions[ind].short);
  //   //     delete submissions[ind];
  //   //   });
  //   //   // delete trialBoards[message.deleted_trial_board_ids];
  //   //   console.log(submissions);
  //   //   this.setState({ submissions: submissions });
  //   //   this.props.enqueueSnackbar('Studie(n) wurde(n) von Benutzer ' + message.username + ' gelöscht: ' + trialShorts, {variant: 'success'});
  //   // }
  // }

  handleTrialBoardReceived = message => {
    console.log("received Trial Board");
    console.log(message);
    if (message.trial_board) {
      this.setState({ trialBoard: message.trial_board });
      this.props.enqueueSnackbar('Studienboard wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial_board.board_date, {variant: 'success'});
    }
    if (message.deleted_board_ids) {
      if (message.deleted_board_ids.contains(this.trialBoard.id)) {
        this.props.enqueueSnackbar('Studienboard wurde von Benutzer ' + message.username + ' gelöscht.', {variant: 'success'});
        window.location.assign('/trial_boards/');
      }
    }
  }
  
  handleConnected = event => {
    console.log("connected");
  }

  render() {
    const { allPeople, tbs_statuses } = this.props;
    const { expanded, trialBoard } = this.state;
    // const peopleOptions = allPeople.map(person => {
    //   return (
    //     {value: person.id, label: person.first_name + " " + person.last_name}
    //   )
    // })
    const submissions = trialBoard.submissions;
    const klifos = trialBoard.klifo_discussions;
    const klifoPanels = klifos.map( klifo => {
      return (
        <>
          <Accordion expanded={expanded === klifo.topic} onChange={this.handlePanelChange(klifo.topic)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography >{klifo.topic}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ShowKlifoDiscussion klifo={klifo} people={allPeople}/>
            </AccordionDetails>
          </Accordion>
        </>
      )
    })
    const panels = submissions.map( submission => {
      return (
        <>
          <Accordion expanded={expanded === submission.short} onChange={this.handlePanelChange(submission.short)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography >{submission.short}</Typography>
              <Typography >{submission.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ShowTrialBoardSubmission trial={submission} people={allPeople} tbs_statuses={tbs_statuses}/>
            </AccordionDetails>
          </Accordion>
        </>
      )
    })
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <ActionCableConsumer
          channel={{channel: "TrialBoardsChannel"}}
          onReceived={this.handleTrialBoardReceived}
          onConnected={this.handleConnected}
        />
        { (this.state.openDialogs.addKlifo) &&
          <AddTrialBoardKlifoDialog open={this.state.openDialogs.addKlifo} klifoIds={klifos.map(klifo => klifo.id)} handleSave={this.handleAddKlifoSave} handleClose={this.handleAddKlifoClose} />
        }
        { (this.state.openDialogs.addSubmission) &&
          <AddTrialBoardSubmissionDialog open={this.state.openDialogs.addSubmission} submissionIds={submissions.map(submission => submission.id)} handleSave={this.handleAddSubmissionSave} handleClose={this.handleAddSubmissionClose} />
        }
        <Grid item xs={9}>
          <TrialBoardData trialBoard={trialBoard} people={allPeople} selectedParticipants={this.state.selectedParticipants} handleParticipantsChange={this.handleParticipantsChange} locked={this.state.locked} />
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" flexShrink={true} flexWrap="nowrap">
            { (!this.state.locked) &&
              <>
                <Tooltip title="Speichern">
                  <IconButton aria-label="Save" onClick={this.handleSave}>
                    <Save />
                  </IconButton>
                </Tooltip>
              </>
            }
            <Tooltip title="KliFoDi editieren">
              <FormControlLabel
                control={
                  <Switch
                    checked={!this.state.locked}
                    onChange={this.handleLockChange}
                    color="primary"
                  />
                }
                label= {this.state.locked ? <Lock /> : <LockOpen /> }
                style={{marginRight: 0, alignSelf: 'flex-end'}}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
              <Typography variant="h6" id="tableTitle" onClick={() => this.handleExpandPaperChange(!this.state.expandedTable["Studien"], "Studien")}>
                KliFo Diskussionen
              </Typography>
              { (!this.state.locked) &&
              <Tooltip title="KliFoDi hinzufügen">
                <IconButton aria-label="Add" onClick={this.handleAddKlifoOpen}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              }
            </Box>
            {klifoPanels}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
              <Typography variant="h6" id="tableTitle" onClick={() => this.handleExpandPaperChange(!this.state.expandedTable["Studien"], "Studien")}>
                Studien
              </Typography>
              { (!this.state.locked) &&
              <Tooltip title="Studie hinzufügen">
                <IconButton aria-label="Add" onClick={this.handleAddSubmissionOpen}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              }
            </Box>
            {panels}
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

ShowTrialBoard.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme(withSnackbar(ShowTrialBoard)));
