import React from 'react';
// import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
// import { createTheme } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Clear from '@mui/icons-material/Clear';


const theme = createTheme({
  overrides: {
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    MuiInputBase: {
      input: {
        marginLeft: 8,
        flex: 1,
      },
    },
    MuiPaper: {
      root:  {
        width: 400,
      },
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    },
  },
});

export default function SearchBar(props) {
  // const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Paper style={{width: '100%'}}>
        <TextField
          placeholder="Suche/Filter..."
          margin="dense"
          id="search"
          label="Suche/Filter"
          value={props.searchTerm}
          fullWidth
          variant="outlined"
          style={{zIndex: 1, marginTop: '0px', marginBottom: '0px'}}
          onChange={props.onChange}
          InputProps={{
            readOnly: false,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={props.handleClear}
                  // onMouseDown={handleMouseDownPassword}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
          type="string"
        />
       </Paper>
    </ThemeProvider>
  );
}
