import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";

import { withSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import moment from 'moment';

import EnhancedTable from './EnhancedTable.jsx';
import NewProcessDialog from './NewProcessDialog.jsx';
import EditProcessDialog from './EditProcessDialog.jsx';
import InfoProcessStepDialog from './InfoProcessStepDialog.jsx';
import NewProcessStepDialog from './NewProcessStepDialog.jsx';
import EditProcessStepDialog from './EditProcessStepDialog.jsx';
import DeleteProcessConfirmationDialog from './DeleteProcessConfirmationDialog.jsx';
import EuCtrProcessFields from './EuCtrProcessFields.jsx';
import EuCtrCountryFields from './EuCtrCountryFields.jsx';
import EditDialog from './EditDialog.jsx';
import NewDialog from './NewDialog.jsx';

moment.locale('de');

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

const ctrColumns = [
  {
    id: "process_type",
    label: "Typ",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "part",
    label: "Teil",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "agency",
    label: "Behörde",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "submission_date",
    label: "Einreichungsdatum",
    numeric: false,
    disablePadding: false,
    type: "date",
  },
  {
    id: "members_status",
    label: "Status",
    numeric: false,
    disablePadding: false,
    type: "text",
  },
];

const participatingCountriesColumns = [
  {
    id: "name",
    label: "Land",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "short",
    label: "Kürzel",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "statusarr",
    label: "Status",
    numeric: false,
    disablePadding: false,
    type: "statusSelection",
  },
  {
    id: "join_date",
    label: "Datum Beitritt",
    numeric: false,
    disablePadding: false,
    type: "date",
  },{
    id: "leave_date",
    label: "Datum Austritt",
    numeric: false,
    disablePadding: false,
    type: "date",
  },
];

const processColumns = [
  {
    id: "category",
    label: "Kategorie",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "gtitle",
    label: "Typ",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "name",
    label: "Titel",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "gstatus",
    label: "Status",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "status_date",
    // secondary: "Datum (s)",
    label: "Datum Status",
    numeric: false,
    disablePadding: false,
    type: "date",
  },
  {
    id: "step_name",
    label: "Arbeitsschritt",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "step_deadline",
    label: "Nächste Frist",
    numeric: false,
    disablePadding: false,
    type: "date",
  },
];

const processStepColumns = [
  {
    id: "num",
    label: "Nr",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "name",
    label: "Arbeitsschritt",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "days",
    label: "Frist/Geplant(T)",
    numeric: false,
    disablePadding: false,
    type: "duration",
  },
  {
    id: "date_deadline",
    label: "Frist",
    numeric: false,
    disablePadding: false,
    type: "duration_date",
  },
  {
    id: "gstatus",
    label: "Status",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "date_status",
    secondary: "Datum Status",
    label: "Datum Status",
    numeric: false,
    disablePadding: false,
    type: "date",
  },
];

function current_step(steps) {
  let currentStep = {}
  // console.log(steps);
  if (steps.length > 0) {
    const rootStep = steps.find(step => !step.process_step_id);
    currentStep = rootStep;
    let nextStep = steps.find(step => step.process_step_id == currentStep.id);
    while (nextStep) {
      currentStep = {... nextStep};
      // console.log(currentStep);
      nextStep = steps.find(step => step.process_step_id == currentStep.id);
      // console.log("Status", currentStep.status);
      if (currentStep.status == "active") {
        break;
      }
      if (!nextStep) {
        return null;
      }
    }
  }
  // console.log(currentStep);
  return currentStep;
}

function order_steps(steps) {
  // console.log(steps);
  const str = "abcdefghijklmnopqrstuvwxyz";
  let rootStep = {... steps.find(step => !step.process_step_id && step.is_main_step)};
  var ret1 = [];
  // console.log("order steps");
  // console.log("rootStep", rootStep);
  if (steps.length > 0) {
    let rootSubSteps = steps.filter(step => !step.process_step_id && !step.is_main_step);
    // console.log("rootSubSteps", rootSubSteps);
    rootStep["num"] = (rootSubSteps.length > 0) ? "1a" : "1";
    rootStep["date_deadline"] = (rootStep.planned_duration > 0) ? moment(rootStep.date_status).add(rootStep.planned_duration, "days").toISOString().substr(0,10) : moment(rootStep.date_status).toISOString().substr(0,10);
    rootStep["days"] = rootStep.planned_duration;
    ret1.push(rootStep);
    let lettercount = 1;
    if (rootSubSteps) {
      rootSubSteps.forEach(rss => {
        // console.log("rss", rss);
        let tmp = {... rss};
        tmp["num"] = "1" + str.charAt(lettercount);
        ret1.push(tmp);
        lettercount++;
      })
    }
    let currentStep = rootStep;
    let nextSteps = steps.filter(step => step.process_step_id == currentStep.id);
    let counter = 2;
    while (nextSteps.length > 0) {
      // currentStep = nextSteps.find(step => step.is_main_step);
      let mainStep = nextSteps.find(step => step.is_main_step);
      let subSteps = nextSteps.filter(step => !step.is_main_step);
      let prevStep = ret1.find(step => step.id == mainStep.process_step_id);
      // console.log("mainStep", mainStep);
      // console.log("subSteps", subSteps);
      mainStep["num"] = (subSteps.length > 0) ? counter + "a" : counter;
      mainStep["days"] = (mainStep.deadline_days) ? mainStep.deadline_days : mainStep.planned_duration;
      mainStep["date_deadline"] = null;
      console.log(prevStep.date_status, prevStep.status, mainStep.deadline_days, mainStep.planned_duration, mainStep.days);
      if (prevStep.date_status && (prevStep.status == "done") && mainStep.days) {
        mainStep["date_deadline"] = moment(prevStep.date_status).add(mainStep.days, "days").toISOString().substr(0,10);
        // console.log("done and deadline");
      } else if (prevStep.date_deadline && mainStep.days) {
        mainStep["date_deadline"] = moment(prevStep.date_deadline).add(mainStep.days, "days").toISOString().substr(0,10);
        // console.log("deadline");
      } else if (!prevStep) {
        // console.log(mainStep.days, mainStep.date_status, moment(mainStep.date_status).add(mainStep.days, "days"))
        mainStep["date_deadline"] = moment(mainStep.date_status).add(mainStep.days, "days").toISOString().substr(0,10)
      }
      // console.log(mainStep);
      ret1.push(mainStep);
      let lettercount = 1;
      subSteps.forEach(step => {
        let retStep = {... step};
        let letter = "";
        if (nextSteps.length > 1) {
          letter = str.charAt(lettercount);
          lettercount++;
        }
        retStep["num"] = counter + letter;
        ret1.push(retStep);
      })
      counter++;
      nextSteps = steps.filter(step => (step.process_step_id == mainStep.id));
      // console.log("nextSteps:", nextSteps);
    }
    // console.log(steps);
  }
  return ret1;
}

class EthicsProcesses extends React.Component {
  constructor(props) {
    super(props);
    let supplementedTrial = {... props.trial};
    let supplementedProcessInstances = [];
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const process_instance_id = urlParams.get('process_instance_id');
    const process_step_id = urlParams.get('process_step_id');
    let selectedProcess = null;
    let selectedProcessStep = null;
    if (props.trial.process_dummies && props.trial.process_dummies.length > 0) {
      props.trial.process_dummies.find(pd => (pd.name == "ethics") || (pd.name == "BoB") || (pd.name == "other")).process_instances.forEach((pi, ind) => {
        let processSteps = [... pi.process_steps];
        let newPi = {... pi};
        if (processSteps.length > 0) {
          const newProcessSteps = order_steps(processSteps);
          newPi["process_steps"] = newProcessSteps;
        }
        if (newPi.id == process_instance_id) {
          selectedProcess = newPi;
          selectedProcessStep = selectedProcess.process_steps.find(ps => ps.id == process_step_id);
        }
        supplementedProcessInstances.push(newPi);
      })
    }
    if (supplementedTrial.process_dummies && supplementedTrial.process_dummies.length > 0) {
      supplementedTrial.process_dummies.find(pd => (pd.name == "ethics") || (pd.name == "BoB") || (pd.name == "other"))["process_instances"] = supplementedProcessInstances;
    }
    
    this.state = {trial: supplementedTrial, selectedProcess: (selectedProcess) ? selectedProcess : null, selectedProcessId: (selectedProcess) ? selectedProcess.id : null, selectedProcessStep: (selectedProcessStep) ? selectedProcessStep : null, selectedProcessStepId: (selectedProcessStep) ? selectedProcessStep.id : null, openDialogs: {newProcess: false, editProcess: false, infoProcessStep: false, newProcessStep: false, editProcessStep: (process_instance_id && process_step_id), deleteProcessConfirmation: false}, expandedTable: {Prozesse: true, Prozessschritte: true}, user: props.user, selectedIds: {}};
    // this.setState({trial: this.props.trial});
    this.form = React.createRef();
  }

  handleNewProcessOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newProcess"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditProcessOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editProcess"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoProcessStepOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoProcessStep"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewProcessStepOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newProcessStep"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditProcessStepOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editProcessStep"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditTaskOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewProcessClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newProcess"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditProcessClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editProcess"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewProcessStepClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newProcessStep"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditTaskClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoProcessStepClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoProcessStep"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditProcessStepClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editProcessStep"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleDeleteProcessConfirmationDialogOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["deleteProcessConfirmation"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleDeleteProcessConfirmationDialogClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["deleteProcessConfirmation"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleDoubleClick = (event, obj, objName, openName) => {
    //TODO
    console.log(event, obj, objName, openName);
    let newSelectedIds = {...this.state.selectedIds};
    newSelectedIds[objName] = [obj.id];
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs[openName] = true;
    this.setState({selectedIds: newSelectedIds, openDialogs: newOpenDialogs});
    // this.handleEditProcessOpen();
  }

  handleProcessDoubleClick = (event, obj) => {
    this.setState({selectedProcess: obj});
    this.handleEditProcessOpen();
  }

  handleProcessStepDoubleClick = (event, obj) => {
    this.setState({selectedProcessStep: obj});
    this.handleInfoProcessStepOpen();
  }

  handleNewProcessSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/new_process_instance', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
        currentComponent.handleNewProcessClose()
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditProcessSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/update_process_instance', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
        currentComponent.handleEditProcessClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteProcessInstance = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/delete_process_instance', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance_id: this.state.selectedProcess.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
        currentComponent.handleDeleteProcessConfirmationDialogClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleNewProcessStepSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/new_process_step', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance_id: this.state.selectedProcess.id,
        process_step: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
        currentComponent.handleNewProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditProcessStepSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/update_process_step', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance_id: this.state.selectedProcess.id,
        process_step: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
        currentComponent.handleEditProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteProcessStep = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/delete_process_step', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_step_id: this.state.selectedProcessStep.id,
        process_instance_id: this.state.selectedProcess.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleDeleteProcessConfirmationDialogClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
        currentComponent.handleEditProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleProcessChange = (rowData, allRowsSelected, allObjectsSelected) => {
    // const ProcessIds = allObjectsSelected.map(site => site.institution_id);
    // const selectedProcesses = this.state.processes.filter(institution => institutionIds.includes(institution.id));
    this.setState({
      selectedProcess: allObjectsSelected[0],
      selectedProcessIds: allRowsSelected[0],
      selectedProcessStep: null,
      selectedProcessStepIds: [],
    });
    // this.updateDepartments(allRowsSelected);
  };

  handleProcessStepChange = (rowData, allRowsSelected, allObjectsSelected) => {
    // const ProcessIds = allObjectsSelected.map(site => site.institution_id);
    // const selectedProcesses = this.state.processes.filter(institution => institutionIds.includes(institution.id));
    // let newSelectedProcessStep = allObjectsSelected[0];
    // newSelectedProcessStep["date_deadline"] = ;
    this.setState({
      selectedProcessStep: allObjectsSelected[0],
      selectedProcessStepIds: allRowsSelected[0],
    });
  };

  handleNewCountrySave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/create_country', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        trial_member_state: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
        currentComponent.handleClose("newCountry");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleNewEuCtrProcessSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/ethics_processes/create_eu_ctr_process', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        eu_ctr_process: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
        currentComponent.handleClose("newEuCtrProcess");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleCountrySave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/update_country', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        trial_member_state: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
        currentComponent.handleClose("editCountry");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleCountriesDelete = ids => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/delete_countries', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        trial_member_state_ids: this.state.selectedIds["Teilnehmerlaender"],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
        // currentComponent.handleNewProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleMoveStepUpSave = obj => {
    // console.log();
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/move_step_up', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance_id: this.state.selectedProcess.id,
        process_step_id: this.state.selectedProcessStep.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich geändert.', {variant: 'success'});
        currentComponent.handleEditProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleMoveStepDownSave = obj => {
    console.log("move Step down");
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/move_step_down', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance_id: this.state.selectedProcess.id,
        process_step_id: this.state.selectedProcessStep.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich geändert.', {variant: 'success'});
        currentComponent.handleEditProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleMoveStepRightSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/move_step_right', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance_id: this.state.selectedProcess.id,
        process_step_id: this.state.selectedProcessStep.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich geändert.', {variant: 'success'});
        currentComponent.handleEditProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleMoveStepLeftSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/move_step_left', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        process_instance_id: this.state.selectedProcess.id,
        process_step_id: this.state.selectedProcessStep.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich geändert.', {variant: 'success'});
        currentComponent.handleEditProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleMarkDoneClick = (event) => {
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/' + currentComponent.state.selectedProcessStep.task.id + '/mark_done', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task: currentComponent.state.selectedProcessStep.task,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich als erledigt markiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleExpandChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({expandedTable: newExpandState});
  }

  handleExpandPaperChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({expandedTable: newExpandState});
  }

  handleRowsSelect = (rowData, allRowsSelected, allObjectsSelected, objName) => {
    let newSelectedIds = {...this.state.selectedIds};
    newSelectedIds[objName] = allRowsSelected;
    this.setState({
      selectedIds: newSelectedIds,
    });
    // this.updateDepartments(allRowsSelected);
  };

  handleOpen = (name) => {
    console.log("handleOpen " + name);
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleClose = name => {
    console.log("handleClose " + name);
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleProcessInstanceReceived = message => {
    console.log("received Process Instance");
    console.log(message);
    if (message.new_trial_member_state && message.trial_id == this.state.trial.id) {
      let newTrial = {...this.state.trial};
      newTrial.trial_member_states.push(message.new_trial_member_state);
      this.setState({trial: newTrial});
      this.props.enqueueSnackbar('Ein neues Land wurde von Benutzer ' + message.username + ' angelegt: ' + message.new_trial_member_state.name, {variant: 'success'});
    }
    if (message.trial_member_state && message.trial_id == this.state.trial.id) {
      let newTrial = {...this.state.trial};
      let trialMemberStateIndex = newTrial.trial_member_states.findIndex(tms => tms.id == message.trial_member_state.id);
      newTrial.trial_member_states[trialMemberStateIndex] = message.trial_member_state;
      this.setState({trial: newTrial});
      this.props.enqueueSnackbar('Ein Land wurde von Benutzer ' + message.username + ' editiert: ' + message.trial_member_state.name, {variant: 'success'});
    }
    if (message.deleted_trial_member_state_ids) {
      let newTrial = {...this.state.trial};
      newTrial.trial_member_states = newTrial.trial_member_states.filter(tms => !message.deleted_trial_member_state_ids.includes(tms.id));
      let newSelectedIds = {...this.state.selectedIds};
      newSelectedIds["Teilnehmerlaender"] = [];
      this.setState({trial: newTrial, selectedIds: newSelectedIds})
    }
    if (message.new_process_instance) {
      let newTrial = {... this.state.trial};
      let newProcessInstance = {... message.new_process_instance};
      newProcessInstance["process_steps"] = order_steps(message.new_process_instance.process_steps);
      if (newTrial.process_dummies && newTrial.process_dummies.find(pd => pd.name == message.new_process_instance.process_category)) {
        if (newTrial.process_dummies.find(pd => pd.name == message.new_process_instance.process_category).process_instances) {
          newTrial.process_dummies.find(pd => pd.name == message.new_process_instance.process_category).process_instances.push(newProcessInstance);
        } else {
          newTrial.process_dummies.find(pd => pd.name == message.new_process_instance.process_category).process_instances = [newProcessInstance];
        }
      } else {
        newTrial.process_dummies = [{name: message.new_process_instance.process_category, process_instances: [newProcessInstance]}];
      }
      // newTrial.process_dummies.find(pd => pd.name == message.new_process_instance).process_instances.find(pi => pi.id == message.)["process_steps"]
      this.setState({ trial: newTrial });
      this.props.enqueueSnackbar('Ein neuer Prozess wurde von Benutzer ' + message.username + ' angelegt: ' + message.new_process_instance.name, {variant: 'success'});
    }
    if (message.process_instance) {
      let newTrial = {... this.state.trial};
      // console.log(newTrial);
      let procIndex = newTrial.process_dummies.find(pd => pd.name == message.process_instance.process_category).process_instances.findIndex(pi => pi.id == message.process_instance.id);
      let supplementedProcessInstance = {... message.process_instance};
      let processSteps = [... message.process_instance.process_steps];
      if (processSteps.length > 0){
        const newProcessSteps = [... order_steps(processSteps)];
        supplementedProcessInstance["process_steps"] = newProcessSteps;
      }
      newTrial.process_dummies.find(pd => pd.name == message.process_instance.process_category).process_instances[procIndex] = supplementedProcessInstance;
      if (this.state.selectedProcess && message.process_instance.id == this.state.selectedProcess.id) {
        const newSelectedProcessStep = (this.state.selectedProcessStep) ? supplementedProcessInstance.process_steps.find(ps => ps.id == this.state.selectedProcessStep.id) : null;
        this.setState({selectedProcess: message.process_instance, selectedProcessStep: newSelectedProcessStep});
      }
      this.setState({ trial: newTrial });
      this.props.enqueueSnackbar('Ein Prozess wurde von Benutzer ' + message.username + ' editiert: ' + message.process_instance.name, {variant: 'success'});
    }
    if (message.deleted_process_instance_ids) {
      let newTrial = {... this.state.trial};
      let deletedProcessNames = [];
      this.state.trial.process_dummies.forEach((process_dummy, ind) => {
        const newProcesses = process_dummy.process_instances.filter(pi => (!message.deleted_process_instance_ids.includes(pi.id)));
        deletedProcessNames = deletedProcessNames.concat(process_dummy.process_instances.filter(pi => (message.deleted_process_instance_ids.includes(pi.id))).map(pi => pi.name));
        newTrial.process_dummies[ind]["process_instances"] = newProcesses;
      })
      // newTrial.process_dummies.find(pd => pd.name == message.process_instance.process_category).process_instances = newTrial.process_dummies.find(pd => pd.name == message.process_instance.process_category).process_instances.filter(pi => (!message.deleted_process_instance_ids.includes(pi.id)));
      if (message.deleted_process_instance_ids.includes(this.state.selectedProcess.id)) {
        this.setState({selectedProcess: null});
      }
      this.setState({ trial: newTrial });
      this.props.enqueueSnackbar('Ein Prozess wurde von Benutzer ' + message.username + ' gelöscht: ' + deletedProcessNames[0], {variant: 'success'});
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    const { classes } = this.props;
    const { trial, selectedProcess, selectedProcessStep, selectedIds, openDialogs } = this.state;

    const statusList = [{value: "planned", label: "Geplant"}, {value: "active", label: "Aktiv"}, {value: "completed", label: "Beendet"}];
    const stepStatusList = [{value: "done", label: "Erledigt"}, {value: "active", label: "Aktiv"}, {value: "planned", label: "Geplant"}];
    const taskStatusList = [{value: "done", label: "Erledigt"}, {value: "open", label: "Offen"}];
    const processCategoryList = [{value: "ethics", label: "EK"}, {value: "BoB", label: "BoB"}, {value: "other", label: "Sonstige"}];
    const processCategoryTypeList = {"ethics": [{value: "user_defined", label: "Benutzerdefiniert"}, {value: "informal_advice", label: "Informeller Advice"}, {value: "initial_submission", label: "Initiale Einreichung"}, {value: "DSUR", label: "DSUR"}, {value: "amendment", label: "Amendment"}, {value: "trial_end", label: "Beendigung"}, {value: "interruption", label: "Unterbrechung"}], "BoB": [{value: "user_defined", label: "Benutzerdefiniert"}], "other": [{value: "user_defined", label: "Benutzerdefiniert"}]}
    // // const processTypeList = [{value: "user_defined", label: "Benutzerdefiniert"}, {value: "informal_advice", label: "Informeller Advice"}, {value: "initial_submission", label: "Initiale Einreichung"}, {value: "DSUR", label: "DSUR"}, {value: "amendment", label: "Amendment"}, {value: "trial_end", label: "Beendigung"}, {value: "interruption", label: "Unterbrechung"}];
    // console.log(trial.process_dummies);
    // let processes = (trial.process_dummies.length > 0) ? trial.process_dummies.filter(pd => processCategoryList.map(pc => pc.value).includes(pd.name)).map(pd => pd.process_instances).reduce((flat, val) => flat.concat(val), []) : [];
    // console.log(processes);
    let supplemented_processes = [];
    trial.process_dummies.forEach(pd => {
        const processes = pd.process_instances;
        // console.log(processes);
        if (processes) {
          processes.forEach(process => {
            // console.log(process);
            var ret = {... process};
            const cur_step = current_step(process.process_steps);
            if (cur_step) {
              ret["step_name"] = cur_step.name;
              ret["step_deadline"] = cur_step.date_deadline;
              // ret["step_deadline"] = cur_step.date_deadline;
              // ret["gstepstatus"] = stepStatusList.find(ss => ss.value == cur_step.status).label
            } else {
              ret["step_name"] = "";
              ret["step_deadline"] = null;
            }
            ret["gtitle"] = processCategoryTypeList[pd.name].find(typ => typ.value == process.process_type).label
            ret["category"] = processCategoryList.find(category => category.value == pd.name).label
            ret["process_category"] = processCategoryList.find(category => category.value == pd.name).value
            ret["gstatus"] = statusList.find(stat => stat.value == process.status).label
            supplemented_processes.push(ret);
          });
        }
    })
    const selectedCtr = supplemented_processes.find(sp => (selectedIds["CTIS Einreichungen"]) ? (sp.id == selectedIds["CTIS Einreichungen"][0]) : false);

    let supplemented_ctr = [];
    let supplemented_ctr_columns = [...ctrColumns];
    if (trial.eu_ctr_processes) {
      trial.eu_ctr_processes.forEach(process => {
        var ret = {... process};
        var members_status = [];
        process.eu_ctr_process_member_states.forEach(ms => {
          members_status.push(ms.role + " " + ms.short + " " + ms.decision + " " + ms.decision_date);
          // ret[ms.short + "_role"] = ms.role;
          // ret[ms.short + "_decision"] = ms.decision;
          // ret[ms.short + "_decision_date"] = ms.decision_date;
          // supplemented_ctr_columns.push({
          //   id: ms.short + "_role",
          //   label: ms.short + " Rolle",
          //   numeric: false,
          //   disablePadding: false,
          //   type: "string",
          // });
          // supplemented_ctr_columns.push({
          //   id: ms.short + "_decision",
          //   label: ms.short + " Entscheidung",
          //   numeric: false,
          //   disablePadding: false,
          //   type: "string",
          // });
          // supplemented_ctr_columns.push({
          //   id: ms.short + "_decision_date",
          //   label: ms.short + " Entscheidung Datum",
          //   numeric: false,
          //   disablePadding: false,
          //   type: "date",
          // });

        })
        ret["members_status"] = members_status;
        supplemented_ctr.push(ret);
      })
    }
    
    let supplementalProcessSteps = [];
    if (selectedProcess && selectedProcess.process_steps.length > 0) {
      const processSteps = [].concat(selectedProcess.process_steps);
      const orderedProcessSteps = order_steps(processSteps);
      let stepCounter = 0;
      supplementalProcessSteps = [].concat(orderedProcessSteps.map(ps => {
        // console.log(ps);
        stepCounter++;
        var ret = {... ps};
        // ret["num"] = stepCounter;
        // if (ps.deadline_days) {
        //   ret["deadline"]
        // }
        ret["gstatus"] = stepStatusList.find(ss => ss.value == ps.status).label
        if (ps.process_step_id) {
          const prevStep = orderedProcessSteps.find(procStep => procStep.id == ps.process_step_id); 
        }
        return ret;
      }))
    }


    let previousProcessStep = (selectedProcess && selectedProcessStep) ? supplementalProcessSteps.find(step => step.id == selectedProcessStep.process_step_id) : null;
    const connectedProcessStepIds = (selectedProcess) ? selectedProcess.process_steps.map(ps => ps.process_step_id).filter((i, index, self) => ((i != null) && (i != undefined) && (self.indexOf(i) === index))) : null;
    let nextMainProcessStep = (selectedProcess && selectedProcessStep) ? selectedProcess.process_steps.find(ps => (ps.process_step_id == selectedProcessStep.id && ps.is_main_step)) : null;
    let lastProcessStep = (selectedProcess) ? supplementalProcessSteps.find(ps => ((!(connectedProcessStepIds.includes(ps.id))) && ps.is_main_step)) : null;
    let lastDoneProcessStep = (selectedProcess) ? supplementalProcessSteps.find(ps => ((!(connectedProcessStepIds.includes(ps.id))) && ps.is_main_step && ps.status == "done")) : null;
    const requiredEuCtrFields = ["process_type", "part"];
    const requiredCountryFields = ["name", "short", "status", "role"];
    const euCtrProcessTypeOptions = [{label: "Initialer Antrag", value: "initial application"}, {label: "Substantial Modification", value: "substantial modification"}, {label: "Non-substantial Modification"}];
    const euCtrProcessPartOptions = [{label: "Teil 1", value: "part1"}, {label: "Teil 2", value: "part2"}];
    const euCtrProcessAgencyOptions = [{label: "Planung", value: "planned"}, {label: "genehmigt", value: "approved"}, {label: "nicht genehmigt", value: "not approved"}, {label: "endgültig ausgeschieden", value: "finally left"}];
    const countryStatusOptions = [{label: "Planung", value: "planned"}, {label: "genehmigt", value: "approved"}, {label: "nicht genehmigt", value: "not approved"}, {label: "endgültig ausgeschieden", value: "finally left"}];
    const countryRoleOptions = [{label: "rMS", value: "rMS"}, {label: "MSc", value: "MSc"}];
    const euCtrProcessStatusOptions = [{label: "genehmigt", value: "approved"}, {label: "abgelehnt", value: "denied"}, {label: "noch nicht entschieden", value: "undecided"}];
    let euCtrMemberCountryOptions = [];
    this.state.trial.trial_member_states.forEach(country => {
      // if (country.status != "finally left") {
        euCtrMemberCountryOptions.push({label: country.name, value: country.id});
      // }
    })
    const euCtrProcessOptions = {process_type: euCtrProcessTypeOptions, part: euCtrProcessPartOptions, agency: euCtrProcessAgencyOptions, memberCountries: euCtrMemberCountryOptions, euCtrProcessStatus: euCtrProcessStatusOptions};
    
    const countryOptions = {status: countryStatusOptions, role: countryRoleOptions};
    let supplemented_trial_member_states = [];
    this.state.trial.trial_member_states.forEach(tms => {
      const ret = {...tms, statusarr: countryStatusOptions.find(cso => cso.value == tms.status)};
      supplemented_trial_member_states.push(ret);
    })
    const selectedCountries = supplemented_trial_member_states.filter(sc => (selectedIds["Teilnehmerlaender"]) ? (sc.id == selectedIds["Teilnehmerlaender"][0]) : false);
    // console.log(selectedCountries, selectedIds["Teilnehmerlaender"]);
    // console.log(supplemented_trial_member_states);
    return (
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "ProcessInstancesChannel"}}
            onReceived={this.handleProcessInstanceReceived}
            onConnected={this.handleConnected}
          />
          <Can I="update" a="Trial">
          { (openDialogs.editEuCtrProcess) &&
            <EditDialog
              title="CTIS Einreichung editieren"
              obj={selectedCtr}
              open={openDialogs.editEuCtrProcess}
              handleSave={this.handleEditEcrfUserSave}
              handleClose={this.handleClose("editEuCtrProcess")}
              Fields={EuCtrProcessFields}
              requiredFields={requiredEuCtrFields}
              selectOptions={euCtrProcessOptions}
            />}
          { (openDialogs.newEuCtrProcess) &&
            <NewDialog
              title="Neue CTIS Einreichung anlegen"
              obj={{member_country_ids: this.state.trial.trial_member_states.filter(tms => tms.status == "approved").map(tms => tms.id)}}
              open={openDialogs.newEuCtrProcess}
              handleSave={this.handleNewEuCtrProcessSave}
              handleClose={() => this.handleClose("newEuCtrProcess")}
              Fields={EuCtrProcessFields}
              requiredFields={requiredEuCtrFields}
              selectOptions={euCtrProcessOptions}
            />}
          { (openDialogs.editCountry && selectedIds["Teilnehmerlaender"] && selectedIds["Teilnehmerlaender"].length == 1) &&
            <EditDialog
              title="Land editieren"
              obj={selectedCountries[0]}
              open={openDialogs.editCountry}
              handleSave={this.handleCountrySave}
              handleClose={() => this.handleClose("editCountry")}
              Fields={EuCtrCountryFields}
              requiredFields={requiredCountryFields}
              selectOptions={countryOptions}
            />}
          { (openDialogs.newCountry) &&
            <NewDialog
              title="Land anlegen"
              obj={{role: "MSc", status: "planned", join_date: moment().toISOString().substr(0,10)}}
              open={openDialogs.newCountry}
              handleSave={this.handleNewCountrySave}
              handleClose={() => this.handleClose("newCountry")}
              Fields={EuCtrCountryFields}
              requiredFields={requiredCountryFields}
              selectOptions={countryOptions}
            />}
          { (openDialogs.editProcess && selectedProcess) &&
            <EditProcessDialog
              trial={trial}
              open={openDialogs.editProcess}
              handleAddOpen={this.handleEditProcessOpen}
              handleSave={this.handleEditProcessSave}
              handleClose={this.handleEditProcessClose}
              selectedProcess={selectedProcess}
              processCategoryTypeList={processCategoryTypeList}
              processCategoryList={processCategoryList}
              statusList={statusList}
            />}
          { (openDialogs.newProcess) &&
            <NewProcessDialog
              open={openDialogs.newProcess}
              handleSave={this.handleNewProcessSave}
              handleClose={this.handleNewProcessClose}
              processCategoryTypeList={processCategoryTypeList}
              processCategoryList={processCategoryList}
              statusList={statusList}
            />}
          { (openDialogs.infoProcessStep && selectedProcess && selectedProcessStep) &&
            <InfoProcessStepDialog
              trial={trial}
              open={openDialogs.infoProcessStep}
              // handleAddOpen={this.handleEditProcessStepOpen}
              // handleSave={this.handleEditProcessStepSave}
              handleEditStepClick={this.handleEditProcessStepOpen}
              handleEditTaskClick={this.handleEditTaskClick}
              handleClose={this.handleInfoProcessStepClose}
              obj={selectedProcessStep}
              previousProcessStep={previousProcessStep}
              stepStatusList={stepStatusList}
              taskStatusList={taskStatusList}
              statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              enqueueSnackbar={this.props.enqueueSnackbar}
            />}
          { (openDialogs.editProcessStep && selectedProcess && selectedProcessStep) &&
            <EditProcessStepDialog
              trial={trial}
              open={openDialogs.editProcessStep}
              handleAddOpen={this.handleEditProcessStepOpen}
              handleSave={this.handleEditProcessStepSave}
              handleClose={this.handleEditProcessStepClose}
              selectedProcessStep={selectedProcessStep}
              previousProcessStep={previousProcessStep}
              stepStatusList={stepStatusList}
              taskStatusList={taskStatusList}
              statusEditable={(((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active") && supplementalProcessSteps.length > 1)}
            />}
          { (openDialogs.newProcessStep) &&
            <NewProcessStepDialog
              trial={this.state.trial}
              open={openDialogs.newProcessStep}
              handleSave={this.handleNewProcessStepSave}
              handleClose={this.handleNewProcessStepClose}
              lastProcessStep={lastProcessStep}
              // selectedProcess={selectedProcess}
              stepStatusList={stepStatusList}
              taskStatusList={taskStatusList}
              selectedProcess={selectedProcess}
            />}
          { (openDialogs.deleteProcessConfirmation && selectedProcess) &&
            <DeleteProcessConfirmationDialog
              open={openDialogs.deleteProcessConfirmation}
              handleSave={this.handleDeleteProcessInstance}
              handleClose={this.handleDeleteProcessConfirmationDialogClose}
              selectedProcess={selectedProcess}
            />}
          </Can>
          { (this.state.trial.legal_basis == "EU-CTR") &&
          <Grid item xs={12} >
            <Grid container justify="center">
              {/* <Paper> */}
                <EnhancedTable
                  title={"CTIS Einreichungen"}
                  data={supplemented_ctr}
                  columns={supplemented_ctr_columns}
                  // singleSelect={true}
                  selectedIds={(this.state.selectedIds) ? this.state.selectedIds["CTIS Einreichungen"] : []}
                  selectedObjects={[selectedCtr]}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "CTIS Einreichungen")}
                  handleAddClick={() => this.handleOpen("newEuCtrProcess")}
                  // handleInfoClick={this.handleInfoSiteClick}
                  // handleEditClick={this.handleEditSiteClick}
                  // handleRemoveClick={this.handleRemoveSiteClick}
                  handleDeleteClick={this.handleDeleteCTRConfirmationDialogOpen}
                  // handleFilterClick={this.handleSitesFilterClick}
                  // filters={this.state.siteFilters}
                  rowsPerPage={10}
                  orderBy="submission_date"
                  order="asc"
                  onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "CTIS Einreichungen", "editEuCtrProcess")}
                  // markedIds={this.state.selectedDepartmentsSitesIds}
                  // onFilterChange={this.handleSitesFilterChange}
                  allData={supplemented_ctr}
                  selectable
                  expanded={true}
                  // handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                />
              {/* </Paper> */}
            </Grid>
          </Grid>}
          { (this.state.trial.legal_basis == "EU-CTR") &&
          <Grid item xs={12} >
            <Grid container justify="center">
              {/* <Paper> */}
                <EnhancedTable
                  title={"Teilnehmerländer"}
                  data={supplemented_trial_member_states}
                  columns={participatingCountriesColumns}
                  // singleSelect={true}
                  selectedIds={(this.state.selectedIds) ? this.state.selectedIds["Teilnehmerlaender"] : []}
                  selectedObjects={[selectedCountries]}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "Teilnehmerlaender")}
                  handleAddClick={() => this.handleOpen("newCountry")}
                  // handleInfoClick={this.handleInfoSiteClick}
                  // handleEditClick={this.handleEditSiteClick}
                  // handleRemoveClick={this.handleRemoveSiteClick}
                  handleDeleteClick={this.handleCountriesDelete}
                  // handleFilterClick={this.handleSitesFilterClick}
                  // filters={this.state.siteFilters}
                  rowsPerPage={10}
                  orderBy="id"
                  order="asc"
                  onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "Teilnehmerlaender", "editCountry")}
                  // markedIds={this.state.selectedDepartmentsSitesIds}
                  // onFilterChange={this.handleSitesFilterChange}
                  allData={supplemented_trial_member_states}
                  selectable
                  expanded={true}
                  // handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                />
              {/* </Paper> */}
            </Grid>
          </Grid>}
          <Grid item xs={5} >
            <Grid container justify="center">
              {/* <Paper> */}
                <EnhancedTable
                  title={"Prozesse"}
                  data={supplemented_processes}
                  columns={processColumns}
                  // singleSelect={true}
                  selectedIds={(selectedProcess) ? [selectedProcess.id] : []}
                  selectedObjects={[selectedProcess]}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleProcessChange(rowData, allRowsSelected, allObjectsSelected)}
                  handleAddClick={() => this.handleNewProcessOpen}
                  // handleInfoClick={this.handleInfoSiteClick}
                  // handleEditClick={this.handleEditSiteClick}
                  // handleRemoveClick={this.handleRemoveSiteClick}
                  handleDeleteClick={this.handleDeleteProcessConfirmationDialogOpen}
                  // handleFilterClick={this.handleSitesFilterClick}
                  // filters={this.state.siteFilters}
                  rowsPerPage={50}
                  orderBy="id"
                  order="asc"
                  onDoubleClick={this.handleProcessDoubleClick}
                  // markedIds={this.state.selectedDepartmentsSitesIds}
                  // onFilterChange={this.handleSitesFilterChange}
                  allData={supplemented_processes}
                  selectable
                  expanded={this.state.expandedTable["Prozesse"]}
                  handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                />
              {/* </Paper> */}
            </Grid>
          </Grid>
          <Grid item xs={7} >
            <Grid container justify="center">
              {/* <Paper> */}
                { (selectedProcess) &&
                <EnhancedTable
                  title={"Prozessschritte"}
                  data={supplementalProcessSteps}
                  columns={processStepColumns}
                  // singleSelect={true}
                  selectedIds={(selectedProcessStep) ? [selectedProcessStep.id] : []}
                  selectedObjects={[selectedProcessStep]}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleProcessStepChange(rowData, allRowsSelected, allObjectsSelected)}
                  handleAddClick={this.handleNewProcessStepOpen}
                  // handleInfoClick={this.handleInfoSiteClick}
                  // handleEditClick={this.handleEditSiteClick}
                  // handleRemoveClick={this.handleRemoveSiteClick}
                  handleDeleteClick={(selectedProcessStep && (selectedProcessStep.status != "done" && previousProcessStep)) ? this.handleDeleteProcessStep : null}
                  handleUpClick={(selectedProcessStep && selectedProcessStep.is_main_step && selectedProcessStep.process_step_id && previousProcessStep.status != "done" && previousProcessStep.status != "active") ? this.handleMoveStepUpSave : null }
                  handleDownClick={(selectedProcessStep && selectedProcessStep.is_main_step && nextMainProcessStep && selectedProcessStep.status != "active" && selectedProcessStep.status != "done") ? this.handleMoveStepDownSave : null}
                  handleRightClick={(selectedProcessStep && selectedProcessStep.is_main_step && previousProcessStep && selectedProcessStep.status != "active" && selectedProcessStep.status != "done") ? this.handleMoveStepRightSave : null}
                  handleLeftClick={(selectedProcessStep && !selectedProcessStep.is_main_step && selectedProcessStep.status != "active" && selectedProcessStep.status != "done") ? this.handleMoveStepLeftSave : null}
                  handleDoneClick={(selectedProcess && selectedProcessStep && (selectedProcessStep.task.status != "done") && (selectedProcessStep.status == "active" ) && (supplementalProcessSteps.length > 1)) ? this.handleMarkDoneClick : null}
                  // handleFilterClick={this.handleSitesFilterClick}
                  // filters={this.state.siteFilters}
                  rowsPerPage={50}
                  // orderBy="id"
                  order="asc"
                  onDoubleClick={this.handleProcessStepDoubleClick}
                  // markedIds={this.state.selectedDepartmentsSitesIds}
                  // onFilterChange={this.handleSitesFilterChange}
                  allData={supplementalProcessSteps}
                  selectable
                  expanded={this.state.expandedTable["Prozessschritte"]}
                  handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                />}
              {/* </Paper> */}
            </Grid>
          </Grid>
          {/* { (this.state.selectedProcessStep) &&
          <>
            <Grid item xs={5} >
              <Grid container justify="center">
              </Grid>
            </Grid>
            <Grid item xs={7} >
              <Grid container justify="center" fullWidth>
                <Paper fullWidth style={{width: '100%'}}>
                    <TextField
                        margin="dense"
                        id="description"
                        label="Beschreibung"
                        value={this.state.selectedProcessStep.description || ""}
                        fullWidth
                        multiline
                        variant="outlined"
                        style={{zIndex: 1}}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{ shrink: (this.state.selectedProcessStep.description != null) }}
                      />
                      <TextField
                        margin="dense"
                        id="comment"
                        label="Kommentar"
                        value={this.state.selectedProcessStep.comment || ""}
                        fullWidth
                        multiline
                        variant="outlined"
                        style={{zIndex: 1}}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{ shrink: (this.state.selectedProcessStep.comment != null) }}
                      />
                </Paper>
              </Grid>
            </Grid>
          </>} */}
          <Typography style={{color: 'red', fontSize: '18px'}}>
            Hinweis: Statusänderungen sind immer nur beim aktiven oder letzten erledigten Prozessschritt möglich.  Fristen sind immer von der Frist des vorherigen Prozessschrittes abhängig. Unterschritte werden nicht gezählt. Zur Berechnung muss entweder Frist oder geplante Dauer angegeben sein. 
          </Typography>
        </Grid>
    )
  }
}

EthicsProcesses.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(EthicsProcesses));
