import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function EuCtrCountryFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleInputValueChange, onCreateOption, handleSelectChange, readOnly, selectOptions, hiddenFields } = props;
  // console.log(obj, readOnly);
  console.log(props);
  // console.log(obj.ecrf_trial_function_associations.map(assoc => assoc.ecrf_trial_configuration_id));
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={6}>
        <TextField
          margin="dense"
          id="name"
          label="Land"
          value={obj.name}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          onCreateOption={onCreateOption}
          required={props.requiredFields.includes("name")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("name"))),
          }}
          type="string"
          // size="small"
          // data-cy="select_name"
        />
        <TextField
          margin="dense"
          id="short"
          label="Kurz"
          value={obj.short}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          onCreateOption={onCreateOption}
          required={props.requiredFields.includes("short")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("short"))),
          }}
          type="string"
          // size="small"
          // data-cy="select_name"
        />
        <TextField
          margin="dense"
          id="role"
          label="Rolle"
          value={obj.role}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          required={props.requiredFields.includes("role")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("role"))),
          }}
          options={props.selectOptions["role"]}
          type="select"
          // size="small"
          // data-cy="select_name"
        />
        <TextField
          margin="dense"
          id="status"
          label="Status"
          value={obj.status}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          required={props.requiredFields.includes("status")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("status"))),
          }}
          options={props.selectOptions["status"]}
          type="select"
          // size="small"
          // data-cy="select_name"
        />
        <TextField
          margin="dense"
          id="join_date"
          label="Beitrittsdatum"
          value={obj.join_date}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          onCreateOption={onCreateOption}
          required={props.requiredFields.includes("join_date")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("join_date"))),
          }}
          type="date"
          // size="small"
          // data-cy="select_name"
        />
        <TextField
          margin="dense"
          id="leave_date"
          label="Austrittsdatum"
          value={obj.leave_date}
          fullWidth
          variant="outlined"
          handleChange={handleChange}
          handleInputValueChange={handleInputValueChange}
          onCreateOption={onCreateOption}
          required={props.requiredFields.includes("leave_date")}
          InputProps={{
            readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("leave_date"))),
          }}
          type="date"
          // size="small"
          // data-cy="select_name"
        />
      </Grid>
    </Grid>
  );
}
