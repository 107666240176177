import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import EditIcon from '@mui/icons-material/Edit';

import Moment from 'moment';
import moment from 'moment'
import "moment/locale/de";

moment.locale("de")

import ContractFields from './ContractFields.jsx';
import TaskListPaper from './TaskListPaper.jsx';

export default function InfoContractDialog(props) {

  // constructor(props) {
  //   super(props);
  //   this.state = { obj: (props.selectedContract) ? {... props.selectedContract} : {}, hasExpiryDate: (props.selectedContract) ? (props.selectedContract.expiry_date != null) : false };
  //   this.form = React.createRef();
  // }

  // handleChange = event => {
  //   let currentComponent = this;
  //   const target = event.target;
  //   const value = target.value;
  //   const name = target.id;
  //   let newObj = {...this.state.obj};
  //   newObj[name] = value;
  //   currentComponent.setState({ obj: newObj });
  // };

  // handleChange2 = (event, meta) => {
  //   const value = event.value;
  //   const name = meta.name;
  //   let newObj = {...this.state.obj};
  //   newObj[name] = value;
  //   this.setState({ obj: newObj });
  // }

  // handleChangeDate = (date, name) => {
  //   let newObj = {...this.state.obj};
  //   newObj[name] = moment(date).startOf("day").add(2, 'hour');
  //   this.setState({obj: newObj});
  // };

  // handleHasExpiryDateChange = event => {
  //   let newObj = {... this.state.obj};
  //   newObj.expiry_date = null;
  //   this.setState({hasExpiryDate: (this.state.hasExpiryDate) ? false : true, obj: newObj});
  // }

  // handleSave = (state) => {
  //   let expiry_date = moment(this.state.obj.expiry_date).toString();
  //   // let deadline = moment(this.state.obj.deadline).toString();
  //   // console.log(start_time, end_time);
  //   this.props.handleSave(Object.assign({}, this.state.obj, {expiry_date: expiry_date}));
  //   // this.props.handleSave(this.state);
  //   // this.setState({last_name: null, first_name: null, title: null, phone_number: null, mobile_number: null, fax_number: null, email: null, function: null});
  // }

  // render(props) {
  const { obj, open, taskStatusList, handleChange, handleChange2, handleEditContractClick } = props;
  const [editTaskOpen, setEditTaskOpen] = useState(false);
  const selectableTaskStatusList = taskStatusList.filter(ss => (ss.value == "done" || ss.value == "active"));
  // const saveable = this.state.obj.name && this.state.obj.status && this.state.obj.contract_type && (!this.state.hasExpiryDate || this.state.obj.expiry_date);
  const supplementedTasks = obj.tasks.map(task => {
    // console.log(task);
    if (task) {
      let ret = {... task};
      ret["statusText"] = taskStatusList.find(tsl => task.status == tsl.value).label;
      let latestReminderDate = Moment('2100-01-01');
      task.reminders.forEach(reminder => {
        if (Moment(latestReminderDate).isAfter(Moment(reminder.remind_when))) {
          latestReminderDate = reminder.remind_when;
        }
      })
      if (!Moment(latestReminderDate).isSame('2100-01-01')) {
        ret["nextReminderDate"] = Moment(latestReminderDate);
      } else {
        ret["nextReminderDate"] = null;
      }
      return ret;
    }
  })
  return (
    <Dialog
      open={open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
      PaperProps={{style: {height: '700px'}}}
    >
      <DialogContent>
        <Grid container direction="row" justify="start" alignItems="start">
          <Grid item xs={6} style={{backgroundColor: green[500]}}>
            <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                <Typography variant="h6" id="Task" component="div" >
                  Vertrag/Versicherung {obj.name}
                </Typography>
                <Tooltip title="Editieren">
                  <IconButton aria-label="Edit" onClick={handleEditContractClick}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <ContractFields
                contract={obj}
                readOnly={true}
                contractTypeList={props.contractTypeList}
                statusList={props.statusList}
                hasExpiryDate={(obj.expiry_date != null)}
                // handleHasExpiryDateChange={this.handleHasExpiryDateChange}
              />
            </Paper>
          </Grid>
          <Grid item xs={6} >
            <TaskListPaper
              tasks={supplementedTasks}
              // readOnly={true}
              taskStatusList={taskStatusList}
              statusEditable={true}
              selectableTaskStatusList={selectableTaskStatusList}
              enqueueSnackbar={props.enqueueSnackbar}
              selectedTasks={props.selectedTasks}
              taskableType={"Contract"}
              taskableId={obj.id}
              onTaskSelect={props.onTaskSelect}
              onTaskDoubleClick={props.onTaskDoubleClick}
              handleDeleteTaskConfirmationDialogOpen={props.handleDeleteTaskConfirmationDialogOpen}
              handleDuplicateClick={props.handleDuplicateClick}
              handleDoneClick={props.handleDoneClick}
            />
            {/* <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center"> 
                <Typography variant="h6" id="Task" component="div" >
                  Task
                </Typography>
                
                <Tooltip title="Editieren">
                  <IconButton aria-label="Edit" onClick={() => setEditTaskOpen(true)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              
            </Paper> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  )
  // }
}
