import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';

import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
// import MUIDataTable from "mui-datatables";
import { withSnackbar } from 'notistack';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import NewTrialBoardDialog from './NewTrialBoardDialog.jsx';
import EditTrialBoardDialog from './EditTrialBoardDialog.jsx';
import InfoTrialBoardDialog from './InfoTrialBoardDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';
import { isEqual } from 'lodash';

const styles = {
  overrides: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableCell: {
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiPaper: {
      elevation24: {
        height: 800,
        width: 800,
      },
    },
  },
  palette: {
    primary: {main: blue[500]},
    secondary: {main: blue[800]}
  },

};

class TrialBoardsIndex extends React.Component {
  constructor(props) {
    super(props);
    const trialBoardColumns = [
      {
       id: "id",
       label: "ID",
       numeric: false,
       padding: 'none',
      },
      {
        id: "submission_names_array",
        label: "Studien",
        padding: 'none',
        numeric: false,
        type: "shards",
      },
      {
        id: "klifo_topics_array",
        label: "KliFoDis",
        padding: 'none',
        numeric: false,
        type: "shards",
      },
      {
        id: "board_date",
        label: "Datum",
        numeric: false,
        padding: 'none',
        type: "date",
      },
    ];
    this.state = {trialBoards: props.trial_boards, selectedTrialBoards: [], selectedTrialBoardIds: [], selectedTrialBoardIndices: [], newTrialBoardOpen: false, editTrialBoardOpen: false, infoTrialBoardOpen: false, trialBoardColumns: trialBoardColumns, user: props.user};
    this.form = React.createRef();
  }

  updateTrialBoards = () => {
    let currentComponent = this;
    fetch('/trial_boards/', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({ trialBoards: data.trial_boards });
    });
  }

  handleTrialBoardChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedTrialBoards: allObjectsSelected,
      selectedTrialBoardIds: allRowsSelected,
      selectedTrialBoardIndices: this.state.trialBoards.findIndex(object => object.id === allRowsSelected[0]),
    });
  };
  // TODO
  handleNewTrialBoardSave = object => {
    let currentComponent = this;
    this.setState({ newTrialBoardOpen: false});
    // TODO
    fetch('/trial_boards/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        date: object.date,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data.trial_board);
      // var trialBoards = [];
      // trialBoards = trialBoards.concat(currentComponent.state.trialBoards);
      //
      // trialBoards.push(data.trial_board);
      // currentComponent.setState({ trialBoards: trialBoards });
      currentComponent.props.enqueueSnackbar('Studienboard wurde erstellt.', {variant: 'success'});
    });
  };

  handleEditTrialBoardSave = (trial_board) => {
    let currentComponent = this;
    // console.log(site);
    this.setState({ editTrialBoardOpen: false});
    fetch('/trial_boards/'+this.state.trialBoard.id+'/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        // TODO
        site_id: site.id,
        marvin_id: site.marvin_id,
        institution_id: site.institution_id,
        inst_description: site.inst_description,
        name: site.name,
        show: 1,
        site_status_id: 1,

        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      // currentComponent.setState({ notes: notes });
    });
    // this.updateNotes
  }

  handleNewTrialBoardClose = event => {
    this.setState({ newTrialBoardOpen: false });
  };

  handleNewTrialBoardOpen = event => {
    this.setState({ newTrialBoardOpen: true });
  };

  handleEditTrialBoardOpen = event => {
    // console.log("clicked edit");
    // this.setState({ editTrialBoardOpen: true});
    window.location.assign('/trial_boards/' + this.state.selectedTrialBoardIds[0] + '/');
  };

  handleEditTrialBoardClose = event => {
    this.setState({ editTrialBoardOpen: false});
  };

  handleInfoTrialBoardOpen = event => {
    // console.log("clicked edit");
    this.setState({ infoTrialBoardOpen: true});
  };

  handleDoubleClick = (event, obj) => {
    window.location.assign('/trial_boards/' + this.state.selectedTrialBoardIds[0] + '/');
  }

  handleInfoTrialBoardClose = event => {
    this.setState({ infoTrialBoardOpen: false});
  };

  handleDeleteTrialBoardClick = event => {
    let currentComponent = this;
    let newTrialBoards = [];
    newTrialBoards = newTrialBoards.concat(currentComponent.state.trialBoards);
    fetch('/trial_boards/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        trial_board_ids: this.state.selectedTrialBoardIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      const trialBoards = currentComponent.state.trialBoards;

      for (let i = 0, l = currentComponent.state.selectedTrialBoardIds.length; i < l; i += 1) {
        // console.log(currentComponent.state.selectedSiteIds[i]);
        // console.log(sites);
        const currentId = currentComponent.state.selectedTrialBoardIds[i];
        const selectedIndex = newTrialBoards.findIndex(obj => obj.id === currentId);
        if (selectedIndex === -1) { //not clicked before
          console.log("index does not exist");
        } else {
          // newSites = newSites.concat(
          //   sites.slice(0, selectedIndex),
          //   sites.slice(selectedIndex + 1),
          // );
          newTrialBoards.splice(selectedIndex,1);
        }
      }
      // console.log(newSites);
      currentComponent.setState({ trialBoards: newTrialBoards, selectedTrialBoards: [], selectedTrialBoardIds: [] });
    });
    // this.updateSites
  };

  handleTrialBoardReceived = message => {
    console.log("received Trial Board");
    console.log(message);
    // console.log(this.state.trialBoards.findIndex(trialBoard => isEqual(trialBoard, message.trial_board)));
    // if (this.state.trialBoards.findIndex(trialBoard => {
    //   let tb = {...trialBoard};
    //   let mtb = {...message.trial_board};
    //   delete tb.created_at;
    //   delete mtb.created_at;
    //   delete tb.updated_at;
    //   delete mtb.updated_at;
    //   delete tb.id;
    //   delete mtb.id;
    //   return (isEqual(trialBoard, message.trial_board)) > -1)
    // })
    if (message.new_trial_board) {
      var trialBoards = [];
      trialBoards = [].concat(this.state.trialBoards);
      trialBoards.push(message.new_trial_board);
      this.setState({ trialBoards: trialBoards });
      this.props.enqueueSnackbar('Trial Board wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_trial_board.board_date, {variant: 'success'});
    }
    if (message.trial_board) {
      var trialBoards = [];
      trialBoards = [].concat(this.state.trialBoards);
      ind = trialBoards.findIndex(tb => tb.id == message.trial_board.id);
      trialBoards[ind] = message.trial_board;
      this.setState({ trialBoards: trialBoards });
      this.props.enqueueSnackbar('Trial Board wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial_board.board_date, {variant: 'success'});
    }
    if (message.deleted_trial_board_ids) {
      const trialBoards = [].concat(this.state.trialBoards.filter(tb => !message.deleted_trial_board_ids.includes(tb.id)));
      const trialBoardDates = this.state.trialBoards.filter(tb => message.deleted_trial_board_ids.includes(tb.id)).map(tb => tb.board_date);
      // message.deleted_trial_board_ids.forEach(function (trial_board_id, index) {
      //   const ind = trialBoards.findIndex(tb => tb.id == trial_board_id);
      //   trialBoardDates.push(trialBoards[ind].board_date);
      //   delete trialBoards[ind];
      // });
      // delete trialBoards[message.deleted_trial_board_ids];
      this.setState({ trialBoards: trialBoards });
      this.props.enqueueSnackbar('Trial Board wurde von Benutzer ' + message.username + ' gelöscht: ' + trialBoardDates, {variant: 'success'});
    }
  }

  handleTrialBoardSubmissionReceived = message => {
    console.log("received Trial Board Submission");
    console.log(message);
    this.props.enqueueSnackbar('Trial Board Submission erhalten: ' + message, {variant: 'success'});
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    return (
      // <ThemeProvider theme={theme}>
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "TrialBoardsChannel"}}
            onReceived={this.handleTrialBoardReceived}
            onConnected={this.handleConnected}
          />

          <Grid item xs={12}>
            <Grid container justify="center">
              <NewTrialBoardDialog
                open={this.state.newTrialBoardOpen}
                handleClose={this.handleNewTrialBoardClose}
                handleSave={this.handleNewTrialBoardSave}
              />
              <EnhancedTable
                title={"Studienboard Sitzungen"}
                data={this.state.trialBoards}
                // singleSelect={true}
                selectedIds={this.state.selectedTrialBoardIds}
                selectedObjects={this.state.selectedTrialBoards}
                columns={this.state.trialBoardColumns}
                handleAddClick={this.handleNewTrialBoardOpen}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleTrialBoardChange(rowData, allRowsSelected, allObjectsSelected)}
                // handleEditClick={this.handleEditTrialBoardOpen}
                handleDeleteClick={this.handleDeleteTrialBoardClick}
                // onDoubleClick={this.handleDoubleClick}
                rowsPerPage={30}
                order="desc"
                orderBy="board_date"
                expanded
                selectable
                autoFill
                controller="trial_boards"
                objectType="TrialBoard"
              />
            </Grid>
          </Grid>
        </Grid>
      // </ThemeProvider>
    )
  }
}

export default withStyles(styles)(withTheme(withSnackbar(TrialBoardsIndex)));
