import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from 'react-select';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'


class EditDocumentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", site_functions: []};
    this.form = React.createRef();
  }

  componentDidMount(prevProps) {
    const newSiteFunctionsOptions = this.props.selectedDocument.roles.map(func => {
      return {value: func, label: func};
    });
    // console.log(newSiteFunctionsOptions);
    this.setState({name: this.props.selectedDocument.name, site_functions: this.props.selectedDocument.roles, site_functions_options: newSiteFunctionsOptions})

  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDocument != prevProps.selectedDocument) {
      const newSiteFunctionsOptions = this.props.selectedDocument.roles.map(func => {
        return {value: func, label: func};
      });
      this.setState({name: this.props.selectedDocument.name, site_functions: this.props.selectedDocument.roles, site_functions_options: newSiteFunctionsOptions})
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  // handleSiteChange = event => {
  //   let currentComponent = this;
  //   const target = event.target;
  //   const value = target.value;
  //   const site_id = value.id;
  //   // console.log(value);
  //   fetch('/trials/'+this.props.trialId+'/site_selection/get_site_departments?'+$.param({site_id: site_id}))
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       // console.log(data);
  //       currentComponent.setState({ departments: data.departments});
  //     });
  //   currentComponent.setState({ selectedSite: value });
  // };

  handleSiteFunctionsChange = (functions, meta) => {
    const newSiteFunctions = functions.map(func => func.value);
    // const newSiteFunctionsOptions = functions.map(func => {value: func.value, label: func.value);
    this.setState({site_functions: newSiteFunctions, site_functions_options: functions});
  }

  handleDepartmentChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    // const department_id = value.id;
    // // console.log(site_id)
    // fetch('/trials/'+this.state.trial.id+'/site_selection/get_department_people?'+$.param({department_id: department_id}))
    //   .then(function(response){return response.json();})
    //   .then(function(data){
    //     console.log(data);
    //     currentComponent.setState({ department_people: data.department_people});
    //   });
    currentComponent.setState({ selectedDepartment: value});
  };

  render(props) {
    const options = this.props.allPeople.map(row =>{
      return ({value: row.site_function, label: row.site_function})
    });
    const selectionOptions = options.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.value == item.value
      ))
    );
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // maxWidth="lg"
        fullWidth
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">Dokument editieren</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            onChange={this.handleChange}
            variant="outlined"
            fullWidth
            value={this.state.name}
            type="string"
          />
          <Select
            // value={ this.state.selectedSiteStatus }
            // defaultValue={"Bitte wählen"}
            name={"site_function"}
            onChange={this.handleSiteFunctionsChange}
            options={selectionOptions}
            // styles={colourStyles}
            // menuIsOpen={true}
            // className={"bg-warning"}
            isMulti
            value={this.state.site_functions_options}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.props.handleSave({old_name: this.props.selectedDocument.name, new_name: this.state.name, site_functions: this.state.site_functions})} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditDocumentDialog;
