import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import moment from 'moment'
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';
import SarcbopProEortcFields from './SarcbopProEortcFields.jsx';
import SarcbopProDashFields from './SarcbopProDashFields.jsx';
import SarcbopProOksFields from './SarcbopProOksFields.jsx';
import SarcbopProEfasFields from './SarcbopProEfasFields.jsx';
import SarcbopProMstsoFields from './SarcbopProMstsoFields.jsx';
import SarcbopProMstsuFields from './SarcbopProMstsuFields.jsx';
import SarcbopProOdiFields from './SarcbopProOdiFields.jsx';
import SarcbopProOhsFields from './SarcbopProOhsFields.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcbopProFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="bgnr"
                label="Bogen Nummer"
                value={obj.bgnr}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="number"
                size="small"
              />
              {/* <TextField
                margin="dense"
                id="pseudonym"
                label="Pseudonym"
                value={obj.pseudonym}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="string"
                size="small"
              /> */}
              {/* <TextField
                margin="dense"
                id="fb_date"
                label="Geb_jahr"
                value={moment(obj.fb_date).format("YYYY")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              /> */}
              <TextField
                margin="dense"
                id="sent_date"
                label="Aus-Datum"
                value={moment(obj.sent_date).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="fb_date"
                label="FB-Datum"
                value={moment(obj.fb_date).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              
            </Box>
            <TextField
              margin="dense"
              id="Kommentar"
              label="Kommentar"
              value={obj.Kommentar}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
            { (props.proType == "eortc") &&
              <SarcbopProEortcFields
                obj={props.obj}
              >

              </SarcbopProEortcFields>

            }
            { (props.proType == "dash") &&
              <SarcbopProDashFields
                obj={props.obj}
              >

              </SarcbopProDashFields>

            }
            { (props.proType == "oks") &&
              <SarcbopProOksFields
                obj={props.obj}
              >

              </SarcbopProOksFields>

            }
            { (props.proType == "odi") &&
              <SarcbopProOdiFields
                obj={props.obj}
              >

              </SarcbopProOdiFields>

            }
            { (props.proType == "ohs") &&
              <SarcbopProOhsFields
                obj={props.obj}
              >

              </SarcbopProOhsFields>

            }
            { (props.proType == "efas") &&
              <SarcbopProEfasFields
                obj={props.obj}
              >

              </SarcbopProEfasFields>

            }
            { (props.proType == "mstso") &&
              <SarcbopProMstsoFields
               obj={props.obj}
              >

              </SarcbopProMstsoFields>

            }
            { (props.proType == "mstsu") &&
              <SarcbopProMstsuFields
                obj={props.obj}
              >

              </SarcbopProMstsuFields>

            }

          </Grid>
        </Grid>
  );
}
