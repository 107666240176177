import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import moment from 'moment'
import "moment/locale/de";

moment.locale("de")

import TaskFields from './TaskFields.jsx';

class NewTaskDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { obj: {taskable_type: props.taskableType, taskable_id: props.taskableId, date_due: moment().toISOString().substr(0,10), status: "open", automatic_notification: true, automatic_email: true, important: false}};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastName !== prevProps.lastName) {
      this.setState({  });
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    currentComponent.setState({ obj: newObj });
  };

  handleChange2 = (event, meta) => {
    const value = event.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    this.setState({ obj: newObj });
  }

  handleChangeCheckbox = (event, checked) => {
    // console.log(event.target.name);
    // console.log(checked);
    let newObj = {...this.state.obj};
    newObj[event.target.name] = checked;
    this.setState({obj: newObj});
  }

  handleChangeDate = (date, name) => {
    let newObj = {...this.state.obj};
    newObj[name] = moment(date).toISOString().substr(0,10);
    if (this.props.previousProcessStep && ((this.props.previousProcessStep.date_status && (this.props.previousProcessStep.status == "done")) || this.props.previousProcessStep.date_deadline)) {
      let date1 = null;
      if (this.props.previousProcessStep.date_status) {
        date1 = new moment(this.props.previousProcessStep.date_status);
      } else if (this.props.previousProcessStep.date_deadline) {
        date1 = new moment(this.props.previousProcessStep.date_deadline);
      }
      const date2 = new moment(date);
      let momentDiff = moment.duration(date2.diff(date1)).days();
      let difference = Math.abs(parseInt(momentDiff));
      newObj["deadline_days"] = difference;
    }
    // console.log(newObj, moment.duration(moment(this.props.previousProcessStep.date_change).diff(moment(date))).days());
    this.setState({obj: newObj});
  };

  handleHasDeadlineChange = event => {
    let newObj = {... this.state.obj};
    newObj.date_deadline = null;
    newObj.deadline_days = null;
    this.setState({hasDeadline: (this.state.hasDeadline) ? false : true});
  }

  handleSave = obj => {
    let currentComponent = this;
    fetch('/tasks/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        
      },
      body: JSON.stringify({
        task: obj.obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
        // currentComponent.props.handleSave();
        currentComponent.props.handleCloseOpenInfo(data.task);
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    
  }

  render(props) {
    const { open, handleClose, taskStatusList, selectableTaskStatusList } = this.props;
    const { obj } = this.state;
    const saveable = obj.title && obj.status && obj.date_due && ((obj.recurring_cycle == null) || ((obj.recurring_cycle != null) && ((obj.recurring_number != null) && (obj.recurring_number > 0)) && (obj.recurring_type != null)));
    // define variables
    // console.log("editTaskDialog");
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '100%'}}}
      >
        <DialogTitle id="form-dialog-title">Neuen Task anlegen</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} >
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
                  {<TaskFields
                    obj={obj}
                    handleChange={this.handleChange}
                    handleChange2={this.handleChange2}
                    handleChangeDate={this.handleChangeDate}
                    handleChangeCheckbox={this.handleChangeCheckbox}
                    readOnly={false}
                    taskStatusList={taskStatusList}
                    statusEditable={true}
                    selectableTaskStatusList={selectableTaskStatusList}
                  />}
                  <FormControlLabel
                    style={{zIndex: 997}}
                    control={
                      <Checkbox
                        checked={obj.automatic_notification}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    name="automatic_notification"
                    label="Erinnerung per Notification"
                  />
                  <FormControlLabel
                    style={{zIndex: 997}}
                    control={
                      <Checkbox
                        checked={obj.automatic_email}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    name="automatic_email"
                    label="Erinnerung per Mail"
                  />
                </Paper>
              </div>
            </Grid>
            { ((obj.automatic_notification || obj.automatic_email) && obj.date_due) &&
            <Grid item xs={12} >
              <Typography style={{color: 'red', fontSize: '20px'}}>
                Hinweis: Es wird automatisch eine Erinnerung angelegt am {moment(obj.date_due).subtract(1, 'day').format('DD.MM.yyyy')}.
              </Typography>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={!saveable}
            onClick={() => this.handleSave(this.state)}
            color="primary"
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewTaskDialog;
