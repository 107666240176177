import React from 'react';
import classNames from 'classnames';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import TextField from './TextField.jsx';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import Save from '@mui/icons-material/Save';
import AddIcon from "@mui/icons-material/Add";
import LockOpen from '@mui/icons-material/LockOpen';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

// import TrialBoardSubmission from './TrialBoardSubmission.jsx';
import KlifoFileUploadDialog from './KlifoFileUploadDialog.jsx';
import ListOfFiles from './ListOfFiles.jsx';
// import SubmissionResolutionList from './SubmissionResolutionList.jsx';
import PersonGlobalSelector from './PersonGlobalSelector.jsx'

// const styles = theme => ({

// });

class ShowKlifoDiscussion extends React.Component {
  constructor(props) {
    super(props);
    // const selectedSpeakerId = props.trial.trial_boards_submissions_people_associations.map(tbspa => tbspa.person_id);
    // const tbs_status = props.tbs_statuses.find(tbs_status => tbs_status.id == props.trial.trial_board_submission_status_id);
    console.log(props.klifo);
    const status = {value: props.klifo.status, label: props.klifo.status};
    this.state = {klifo: props.klifo, selectedStatus: status, openDialogs: {fileUpload: false, editSubmissionResolution: {}}, locked: true, user: props.user};
    this.form = React.createRef();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.trial !== prevProps.trial) {
  //     const selectedPotentialPisIds = this.props.trial.trial_boards_submissions_people_associations.map(tbspa => tbspa.person_id);
  //     const tbs_status = this.props.tbs_statuses.find(tbs_status => tbs_status.id == this.props.trial.trial_board_submission_status_id);
  //     const status = {value: tbs_status.id, label: tbs_status.text};
  //     this.setState({trial: this.props.trial, selectedPotentialPis: selectedPotentialPisIds, selectedTrialType: {value: this.props.trial.trial_type, label: this.props.trial.trial_type}, selectedStatus: status, selectedPhase: {value: this.props.trial.phase, label: this.props.trial.phase}});
  //   }
  // }

  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = event => {
    let currentComponent = this;
    // console.log(event.target.checked);
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newKlifo = {...this.state.klifo}
    newKlifo[name] = value;
    currentComponent.setState({ klifo: newKlifo });
  };

  handleChange2 = (event, checked) => {
    let currentComponent = this;
    let newTrial = {...this.state.trial}
    newTrial[event.target.name] = checked;
    currentComponent.setState({ trial: newTrial });
  }

  handleTrialTypeChange = (event) => {
    this.setState({selectedTrialType: event});
  }

  handlePhaseChange = (event) => {
    this.setState({selectedPhase: event});
  }

  handleSpeakerChange = (event) => {
    console.log(event);
    let newObj = {...this.state.klifo}
    newObj["speaker_id"] = event.value;
    this.setState({klifo: newObj});
  }

  handleStatusChange = (event) => {
    let newObj = {...this.state.klifo}
    newObj["status"] = event.value;
    this.setState({klifo: newObj, selectedStatus: event});
  }

  componentDidMount = (props) => {
    let currentComponent = this;
  }

  handleLockChange = event => {
    if (this.state.locked == false) {
      this.setState({locked: true})
    } else {
      this.setState({locked: false})
    }
  }

  // handleNewSubmissionSave = event => {
  //   const currentComponent = this;
  //   fetch('/trial_board_submissions/create', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       ...this.state.trial,
  //       trial_type: this.state.selectedTrialType.value,
  //       phase: this.state.selectedPhase.value,
  //       trial_board_submission_status_id: this.state.selectedStatus.value,
  //       // trial_id: this.state.trial.id,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     if (data.response == 1) {
  //       console.log(data.trial);
  //       const newTrial = data.trial;
  //       currentComponent.setState({ trial: newTrial, locked: true });
  //       currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
  //     } else {
  //       // currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  // }

  handleSave = (event, test) => {
    const currentComponent = this;
    fetch('/klifo_discussions/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        klifo_discussion: this.state.klifo,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // console.log(data.trial);
        // const newTrial = data.trial;
        currentComponent.setState({ locked: true });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        // currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleFileUploadOpen = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["fileUpload"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleFileUploadClose = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["fileUpload"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleFileUpload = (trial, event) => {
    // const newTrial = trial;
    // this.setState({trial: newTrial});
    this.handleFileUploadClose();
  }

  handleKlifoFileUploadOpen = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["klifoFileUpload"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleKlifoFileUploadClose = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["klifoFileUpload"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleKlifoFileUpload = (trial, event) => {
    // const newTrial = trial;
    // this.setState({trial: newTrial});
    this.handleKlifoFileUploadClose();
  }

  handleFileDelete = file => {
    const currentComponent = this;
    fetch('/trial_board_submissions/' + this.state.trial.id + '/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ trial: newTrial });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleKlifoFileDelete = file => {
    const currentComponent = this;
    fetch('/klifo_discussions/' + this.state.klifo.id + '/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ trial: newTrial });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten: "' + data.errorMessage + '"', {variant: 'error'});
      }
    });
  }

  handleEditResolutionClick = assocId => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["editSubmissionResolution"][assocId] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditResolutionSave = assoc => {
    const currentComponent = this;
    fetch('/trial_board_submissions/' + assoc.assoc_id + '/update_board_resolution', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...assoc,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleEditResolutionClose(assoc.assoc_id);
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditResolutionClose = assocId => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["editSubmissionResolution"][assocId] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleKlifoDiscussionReceived = message => {
    console.log("received Klifo Discussion");
    console.log(message);
    if (message.klifo_discussion && message.klifo_discussion.id == this.state.klifo.id) {
      let klifo = message.klifo_discussion;
      this.setState({ klifo: klifo });
      this.props.enqueueSnackbar('KliFo Diskussion wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.klifo_discussion.topic, {variant: 'success'});
    }
    if (message.deleted_klifo_discussions && message.deleted_klifo_discussions.includes(this.state.klifo.id)) {
      this.props.enqueueSnackbar('KliFo Diskussion wurde von Benutzer ' + message.username + ' gelöscht: ' + this.state.klifo_discussion.topic, {variant: 'success'});
      window.location.assign('/trial_boards/index_trials');
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    const { people } = this.props;
    const klifo = this.state.klifo;
    const trialTypeList = [{value: "IIT", label: "IIT"}, {value: "IIS", label: "IIS"}];
    const phaseList = [{value: "I", label: "I"}, {value: "I/II", label: "I/II"}, {value: "II", label: "II"}, {value: "III", label: "III"}, {value: "IV", label: "IV"}, {value: "V", label: "V"}]
    const statusList = [{value: "Offen", label: "Offen"}, {value: "Geschlossen", label: "Geschlossen"}];
    // console.log(this.state);
    const files = klifo.files;
    const allPeopleOptions = people.map(person => {
      return (
        {value: person.id, label: person.first_name + " " + person.last_name}
      )
    });
    const speaker = allPeopleOptions.filter(person => this.state.klifo.speaker_id == person.value);
    const status = statusList.find(statusAssArr => statusAssArr.value == klifo.status);
    return (
      <>
      <Grid container >
        <ActionCableConsumer
          channel={{channel: "KlifoDiscussionsChannel"}}
          onReceived={this.handleKlifoDiscussionReceived}
          onConnected={this.handleConnected}
        />
        <Grid item xs={12} style={{paddingBottom: '0px'}}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" flexShrink={true} flexWrap="nowrap">
            { (!this.state.locked) &&
              <Tooltip title="Speichern">
                <IconButton aria-label="Save" onClick={this.handleSave}>
                  <Save />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title="Studie editieren">
              <FormControlLabel
                control={
                  <Switch
                    checked={!this.state.locked}
                    onChange={this.handleLockChange}
                    color="primary"
                  />
                }
                label= {this.state.locked ? <Lock /> : <LockOpen /> }
                style={{marginRight: 0, alignSelf: 'flex-end'}}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={4} style={{paddingTop: '0px'}}>
          <Grid container justify="center">
            <TextField
              margin="dense"
              id="topic"
              label="Thema"
              onChange={this.handleChange}
              value={this.state.klifo.topic}
              fullWidth
              error={!this.state.klifo.topic}
              variant="outlined"
              // disabled={this.state.locked}
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.klifo.topic != null) }}
              style={{zIndex: 1}}
              type="string"
              size="small"
            />
            { this.state.locked &&
              <>
                <TextField
                  margin="dense"
                  id="klifo_status"
                  label="Status"
                  onChange={this.handleChange}
                  value={status.label}
                  fullWidth
                  error={!status.label}
                  variant="outlined"
                  // disabled={this.state.locked}
                  InputProps={{
                    readOnly: this.state.locked,
                  }}
                  InputLabelProps={{ shrink: (status.label != null) }}
                  style={{zIndex: 1}}
                  type="string"
                  size="small"
                />
              </>
            }
            { !this.state.locked &&
              <>
                <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
                  <InputLabel filled animated shrink margin="dense" variant="outlined" >Status</InputLabel>
                  <Select
                    id="status"
                    value={ this.state.selectedStatus }
                    onChange={this.handleStatusChange}
                    options={statusList}
                    style={{width: "100%"}}
                    isDisabled={this.state.locked}
                  />
                </FormControl>
              </>
            }
            <TextField
              margin="dense"
              id="comment"
              label="Kommentar"
              onChange={this.handleChange}
              value={this.state.klifo.comment}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: this.state.locked,
              }}
              InputLabelProps={{ shrink: (this.state.klifo.comment != null) }}
              multiline
              style={{zIndex: 1}}
              type="text"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid item xs={4} style={{paddingTop: '0px'}}>
          <Grid container justify="center">
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 997}}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Präsentation</InputLabel>
              <Select
                // defaultValue={}
                // isMulti
                name="speaker"
                options={allPeopleOptions}
                onChange={this.handleSpeakerChange}
                className="basic-multi-select"
                classNamePrefix="select"
                isDisabled={this.state.locked}
                value={speaker}
              />
            </FormControl>
            <FormControl variant="outlined" margin="dense" fullWidth>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" flexShrink={true} flexWrap="nowrap">
                <InputLabel filled animated shrink margin="dense" variant="outlined" >Übertragene Dateien</InputLabel>
                { (!this.state.locked) &&
                  <Tooltip title="Neue Datei hochladen">
                    <IconButton aria-label="Upload" onClick={this.handleKlifoFileUploadOpen} size="small">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                }
              </Box>
            </FormControl>
            
            <KlifoFileUploadDialog
              open={this.state.openDialogs.klifoFileUpload}
              id={this.state.klifo.id}
              handleFileUpload={this.handleKlifoFileUpload}
              handleClose={this.handleKlifoFileUploadClose}
            />
            <ListOfFiles files={files} handleDelete={this.handleKlifoFileDelete} disabled={this.state.locked}/>
          </Grid>
        </Grid>
      </Grid>
      </>
    )
  }
}

ShowKlifoDiscussion.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(ShowKlifoDiscussion));
