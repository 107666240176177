import React from 'react';
import Select from 'react-select';

const selectStyles = { menu: { zIndex: 999 } };

export default class SarcPatientSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {selectedPatient: {}};
    this.form = React.createRef();
  }

  onChange = (event) => {
    this.setState({selectedPatient: event});
    this.onSubmit(event);
  }

  componentDidMount = (props) => {
    let currentComponent = this;
    currentComponent.updatePatient();
  }

  updatePatient = (props) => {
    let currentComponent = this;
    let patientsList = [];
    fetch('/sarc_patients/get_patients', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      for (var i = 0; i < data.patients.length; i++) {
        patientsList.push({value: data.patients[i].id, label: data.patients[i].pseudonym});
        if (currentComponent.props.selectedPatientId == data.patients[i].id) {
          currentComponent.setState({selectedPatient: {value: data.patients[i].id, label: data.patients[i].pseudonym, color: '#36B37E' }});
        }
      }
      currentComponent.setState({ patients: data.patients, patientsList: patientsList });
    });
  }

  onSubmit = (event) => {
    window.location.assign('/sarcbop/patient_overview/' + event.value);
    /* if ((this.props.action != 'show') && (this.props.action != undefined) && (this.props.action != 'index')) {
      window.location.assign('/' + this.props.controller + '/' + event.value + '/' + this.props.action);
    } else if (this.props.onSubmit) {
      this.props.onSubmit();
    }
    else {
      window.location.assign('/' + this.props.controller + '/' + event.value);
    } */
  }

  render() {
    return (
      <div style={{width: 200, zIndex: 999}}>
        <form ref={this.form} >
        <input id="id" ref="inputField" type="hidden"  />
          <Select
            value={ this.state.selectedPatient }
            onChange={this.onChange}
            options={this.state.patientsList}
            style={selectStyles}
          />
        </form>
      </div>
    );
  }
}
