import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class EditInstitutionDialog extends React.Component {

  constructor(props) {
    super(props);
    // console.log("test");
    this.state = {selectedInstitution: this.props.selectedInstitution, open: this.props.open};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedInstitution !== prevProps.selectedInstitution) {
      this.setState({open: this.props.open, selectedInstitution: this.props.selectedInstitution});
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    var object = this.state.selectedInstitution;
    object[name] = value;
    // console.log(object);
    currentComponent.setState({ selectedInstitution: object });
  };

  render(props) {

    const onlyNumber = /\d+/;
    const onlyNumberMatch = (this.state.selectedInstitution.zip && this.state.selectedInstitution.zip.toString().match(onlyNumber)) ? this.state.selectedInstitution.zip.toString().match(onlyNumber)[0] == this.state.selectedInstitution.zip : false;
    const zipIsOnlyNumber = (this.state.zip == null || this.state.zip == "" || onlyNumberMatch);
    const saveable = this.state.selectedInstitution.name && zipIsOnlyNumber;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Institution editieren</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.name}
            error={!this.state.selectedInstitution.name}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.name != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="street"
            label="Straße und Hausnummer"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.street}
            // error={!this.state.street}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.street != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="zip"
            label="Postleitzahl"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.zip}
            error={!zipIsOnlyNumber}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.zip != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="city"
            label="Stadt"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.city}
            // error={!this.state.city}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.city != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="country"
            label="Land"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.country}
            // error={!this.state.country}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.country != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="email"
            label="E-Mail"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.email}
            // error={!this.state.country}
            disabled
            InputLabelProps={{ shrink: (this.state.selectedInstitution.email != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="phone_number"
            label="Telefonnummer"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.phone_number}
            // error={!this.state.country}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.phone_number != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="fax_number"
            label="Faxnummer"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.fax_number}
            // error={!this.state.country}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.fax_number != null) }}
            type="string"
          />
          <TextField
            margin="dense"
            id="website"
            label="Webseite"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.selectedInstitution.website}
            // error={!this.state.country}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.website != null) }}
            type="string"
          />
          <TextField
            id="comment"
            label="Kommentar"
            onChange={this.handleChange}
            multiline
            // className={classes.textField}
            margin="dense"
            variant="outlined"
            value={this.state.selectedInstitution.comment}
            fullWidth
            style={{zIndex: 1}}
            InputLabelProps={{ shrink: (this.state.selectedInstitution.comment != null) }}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          onClick={() => this.props.handleSave(
            this.state.selectedInstitution,
          )}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditInstitutionDialog;
