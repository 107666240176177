import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can';
import Moment from 'moment'

import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';

import EnhancedTable from './EnhancedTable.jsx';
import EcrfTrialConfigurationFields from './EcrfTrialConfigurationFields.jsx';
import EcrfRoleFields from './EcrfRoleFields.jsx';
import EcrfFunctionFields from './EcrfFunctionFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import ListOfEcrfApplications from './ListOfEcrfApplications.jsx';
import EditNoteDialog from './EditNoteDialog.jsx';
import NewNoteDialog from './NewNoteDialog.jsx';

const ecrfTrialConfigurationColumns = [
  {
    id: "short",
    // secondary: "short",
    label: "Akronym",
    numeric: false,
    padding: 'none',
    searchable: true,
    // type: "subObject",
  },
  {
    id: "link",
    label: "Prüfzentren",
    numeric: false,
    padding: 'none',
    type: "linkButton",
  },
  {
    id: "confStatusArray",
    label: "Status",
    numeric: false,
    padding: 'none',
    searchable: true,
    type: "statusSelection"
  },
]

const ecrfTrialRoleColumns = [
  {
    id: "name",
    label: "Rolle",
    numeric: false,
    padding: 'none',
    searchable: true,
  },
]

const ecrfTrialFunctionColumns = [
  {
    id: "name",
    label: "Funktion",
    numeric: false,
    padding: 'none',
    searchable: true,
  },
]

const noteColumns = [
  {
   id: "entry_date",
   label: "Datum",
   numeric: false,
   disablePadding: false,
   date: true,
   type: "date",
  },
  {
   id: "note",
   label: "Notiz",
   numeric: false,
   disablePadding: false,
  },
 ];

class EcrfManagementConfiguration extends React.Component {

  constructor(props) {
    super(props);
    let user = props.user;
    this.state = {ecrf_trial_configurations: props.ecrf_trial_configurations, ecrf_trial_roles: props.ecrf_trial_roles, ecrf_trial_functions: props.ecrf_trial_functions, user: user, expanded: null, openDialogs: {editUser: false}, selectedIds: {"Studien": [], "Rollen": [], "Funktionen": []}};
    this.form = React.createRef();
  }

  componentDidMount(props) {
  }

  handleRowsSelect = (rowData, allRowsSelected, allObjectsSelected, objName) => {
    var newSelectedIds = {...this.state.selectedIds};
    newSelectedIds[objName] = allRowsSelected;
    this.setState({
      selectedIds: newSelectedIds,
    });
    // this.updateDepartments(allRowsSelected);
  };

  handleDoubleClick = (event, obj, name) => {
    let newSelectedIds = {...this.state.selectedIds};
    newSelectedIds[name] = [obj.id]
    this.setState({selectedIds: newSelectedIds});
    this.handleOpen("edit" + name)
  }

  handleOpen = (name) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewConfigurationSave = configuration => {
    const currentComponent = this;
    fetch('/ecrf_management/create_ecrf_configuration', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_configuration: configuration,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.handleClose("newStudien");
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleEditConfigurationSave = configuration => {
    const currentComponent = this;
    fetch('/ecrf_management/update_ecrf_configuration', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_configuration: configuration,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.handleClose("editStudien");
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleNewFunctionSave = func => {
    const currentComponent = this;
    fetch('/ecrf_management/create_ecrf_function', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_function: func,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.handleClose("newFunktionen");
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleEditFunctionSave = func => {
    const currentComponent = this;
    fetch('/ecrf_management/update_ecrf_function', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_function: func,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.handleClose("editFunktionen");
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleNewRoleSave = role => {
    const currentComponent = this;
    fetch('/ecrf_management/create_ecrf_role', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_role: role,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.handleClose("newRollen");
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleEditRoleSave = role => {
    const currentComponent = this;
    fetch('/ecrf_management/update_ecrf_role', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_role: role,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.handleClose("editRollen");
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleDeleteConfigurations = event => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_ecrf_configurations', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_configuration_ids: currentComponent.state.selectedIds["Studien"],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        // currentComponent.handleClose("newConfiguration");
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleDeleteFunctions = event => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_ecrf_functions', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_function_ids: currentComponent.state.selectedIds["Funktionen"],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        // currentComponent.handleClose("newConfiguration");
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleDeleteRoles = event => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_ecrf_roles', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_trial_role_ids: currentComponent.state.selectedIds["Rollen"],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.handleClose("newConfiguration");
        // currentComponent.setState({user: data.ecrf_user});
        // currentComponent.handleClose("newConfiguration");
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleNewNoteSave = (note) => {
    let currentComponent = this;
    // console.log(note);
    fetch('/ecrf_management/'+this.state.selectedIds["Studien"][0]+'/create_ecrf_configuration_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        // contract_id: this.state.selectedContracts[0].id,
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newNotes = [].concat(currentComponent.state.notes);
        // const noteIndex = newNotes.findIndex(note => note.id === data.note.id );
        // newNotes[noteIndex] = data.note;
        // currentComponent.setState({ notes: newNotes });
        // // currentComponent.updateNotes(currentComponent.state.selectedDepartmentIds);
        currentComponent.handleClose("newNotizen");
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateNotes(this.state.selectedDepartmentIds);
  }

  handleEditNoteSave = (note) => {
    let currentComponent = this;
    // console.log(note);
    fetch('/ecrf_management/'+this.state.selectedIds["Studien"][0]+'/update_ecrf_configuration_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
        // contract_id: this.state.selectedContracts[0].id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newNotes = [].concat(currentComponent.state.notes);
        // const noteIndex = newNotes.findIndex(note => note.id === data.note.id );
        // newNotes[noteIndex] = data.note;
        // currentComponent.setState({ notes: newNotes });
        // // currentComponent.updateNotes(currentComponent.state.selectedDepartmentIds);
        currentComponent.handleClose("editNotizen");
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateNotes(this.state.selectedDepartmentIds);
  }

  handleDeleteNotes = (note) => {
    let currentComponent = this;
    // console.log(note);
    fetch('/ecrf_management/'+this.state.selectedIds["Studien"][0]+'/update_ecrf_configuration_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note_ids: currentComponent.state.selectedIds["Notizen"],
        // contract_id: this.state.selectedContracts[0].id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newNotes = [].concat(currentComponent.state.notes);
        // const noteIndex = newNotes.findIndex(note => note.id === data.note.id );
        // newNotes[noteIndex] = data.note;
        // currentComponent.setState({ notes: newNotes });
        // // currentComponent.updateNotes(currentComponent.state.selectedDepartmentIds);
        // currentComponent.handleClose("editNotizen");
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateNotes(this.state.selectedDepartmentIds);
  }

  hasIntersectingElement(arrA, arrB) {
    var ret = false;
    const setA = new Set(arrA);
    const setB = new Set(arrB);
    for (const elem of setB) {
      if (setA.has(elem)) {
        ret = true;
        break;
      }
    }
    return ret;
  }

  handleEcrfManagementReceived = message => {
    console.log("received ECRF Management");
    console.log(message);
    if (message.new_ecrf_trial_configuration) {
      const ecrf_configuration = message.new_ecrf_trial_configuration;
      let newEcrfConfigurations = [...this.state.ecrf_trial_configurations]
      // const ind = this.state.ecrf_users.findIndex(user => user.id == ecrf_user.id);
      // newEcrfUsers[ind] = ecrf_user;
      newEcrfConfigurations.push(ecrf_configuration);
      this.setState({ ecrf_trial_configurations: newEcrfConfigurations});
      this.props.enqueueSnackbar('Eine neue Studie wurde von Benutzer ' + message.username + ' hinzugefügt: ' + ecrf_configuration.trial.short, {variant: 'success'});
    }
    if (message.new_ecrf_trial_function) {
      const ecrf_function = message.new_ecrf_trial_function;
      let newEcrfFunctions = [...this.state.ecrf_trial_functions]
      // const ind = this.state.ecrf_users.findIndex(user => user.id == ecrf_user.id);
      // newEcrfUsers[ind] = ecrf_user;
      if (newEcrfFunctions.filter(func => ecrf_function.id == func.id).length == 0) {
        newEcrfFunctions.push(ecrf_function);
      }
      this.setState({ ecrf_trial_functions: newEcrfFunctions});
      this.props.enqueueSnackbar('Eine neue Funktion wurde von Benutzer ' + message.username + ' hinzugefügt: ' + ecrf_function.name, {variant: 'success'});
    }
    if (message.ecrf_trial_configuration) {
      const conf = message.ecrf_trial_configuration;
      let newEcrfConfigurations = [...this.state.ecrf_trial_configurations]
      const ind = newEcrfConfigurations.findIndex(config => config.id == conf.id);
      newEcrfConfigurations[ind] = conf;
      this.setState({ ecrf_trial_configurations: newEcrfConfigurations});
      this.props.enqueueSnackbar('Eine Studie wurde von Benutzer ' + message.username + ' editiert: ' + conf.trial.short, {variant: 'success'});
    }
    if (message.ecrf_trial_function) {
      const ecrf_function = message.ecrf_trial_function;
      let newEcrfFunctions = [...this.state.ecrf_trial_functions]
      const ind = newEcrfFunctions.findIndex(func => func.id == ecrf_function.id);
      newEcrfFunctions[ind] = ecrf_function;
      this.setState({ ecrf_trial_functions: newEcrfFunctions});
      this.props.enqueueSnackbar('Eine Funktion wurde von Benutzer ' + message.username + ' editiert: ' + ecrf_function.name, {variant: 'success'});
    }
    if (message.new_ecrf_trial_functions) {
      let newEcrfFunctions = [...this.state.ecrf_trial_functions]
      console.log(newEcrfFunctions);
      message.new_ecrf_trial_functions.forEach((currentValue, index, arr) => {
        newEcrfFunctions.push(currentValue);
      })
      console.log(newEcrfFunctions);
      this.setState({ ecrf_trial_functions: newEcrfFunctions});
      // this.props.enqueueSnackbar('Eine Funktion wurde von Benutzer ' + message.username + ' editiert: ' + ecrf_function.name, {variant: 'success'});
    }
    if (message.new_ecrf_trial_roles) {
      let newEcrfRoles = [...this.state.ecrf_trial_roles]
      console.log(newEcrfRoles);
      message.new_ecrf_trial_roles.forEach((currentValue, index, arr) => {
        newEcrfRoles.push(currentValue);
      })
      console.log(newEcrfRoles);
      this.setState({ ecrf_trial_roles: newEcrfRoles});
      // this.props.enqueueSnackbar('Eine Funktion wurde von Benutzer ' + message.username + ' editiert: ' + ecrf_function.name, {variant: 'success'});
    }
    if (message.new_ecrf_trial_role) {
      const ecrf_role = message.new_ecrf_trial_role;
      let newEcrfRoles = [...this.state.ecrf_trial_roles]
      // const ind = this.state.ecrf_users.findIndex(user => user.id == ecrf_user.id);
      // newEcrfUsers[ind] = ecrf_user;
      if (newEcrfRoles.filter(role => ecrf_role.id == role.id).length == 0) {
        newEcrfRoles.push(ecrf_role);
      }
      this.setState({ ecrf_trial_roles: newEcrfRoles});
      this.props.enqueueSnackbar('Eine neue Rolle wurde von Benutzer ' + message.username + ' hinzugefügt: ' + ecrf_role.name, {variant: 'success'});
    }
    if (message.ecrf_trial_role) {
      const ecrf_role = message.ecrf_trial_role;
      let newEcrfRoles = [...this.state.ecrf_trial_roles]
      const ind = newEcrfRoles.findIndex(role => role.id == ecrf_role.id);
      newEcrfRoles[ind] = ecrf_role;
      this.setState({ ecrf_trial_roles: newEcrfRoles});
      this.props.enqueueSnackbar('Eine Rolle wurde von Benutzer ' + message.username + ' editiert: ' + ecrf_role.name, {variant: 'success'});
    }
    if (message.removed_ecrf_trial_configuration_ids) {
      const newEcrfConfigurations = this.state.ecrf_trial_configurations.filter(conf => !message.removed_ecrf_trial_configuration_ids.includes(conf.id));
      const removedEcrfConfigurations = this.state.ecrf_trial_configurations.filter(conf => message.removed_ecrf_trial_configuration_ids.includes(conf.id));
      const namesArray = removedEcrfConfigurations.map(conf => conf.trial.short);
      // let conf_filtered = [];
      let newSelectedIds = {...this.state.selectedIds};
      newSelectedIds["Studien"] = [];
      this.setState({ ecrf_trial_configurations: newEcrfConfigurations, selectedIds: newSelectedIds });
      this.props.enqueueSnackbar('Studie(n) wurde(n) von Benutzer ' + message.username + ' entfernt: ' + namesArray, {variant: 'success'});
    }
    if (message.removed_ecrf_trial_function_ids) {
      const newEcrfFunctions = this.state.ecrf_trial_functions.filter(func => !message.removed_ecrf_trial_function_ids.includes(func.id));
      const removedEcrfFunctions = this.state.ecrf_trial_functions.filter(func => message.removed_ecrf_trial_function_ids.includes(func.id));
      const namesArray = removedEcrfFunctions.map(func => func.name);
      // let func_filtered = [];
      let newSelectedIds = {...this.state.selectedIds};
      newSelectedIds["Funktionen"] = [];
      this.setState({ ecrf_trial_functions: newEcrfFunctions, selectedIds: newSelectedIds });
      this.props.enqueueSnackbar('Rolle(n) wurde(n) von Benutzer ' + message.username + ' entfernt: ' + namesArray, {variant: 'success'});
    }
    if (message.removed_ecrf_trial_role_ids) {
      const newEcrfRoles = this.state.ecrf_trial_roles.filter(role => !message.removed_ecrf_trial_role_ids.includes(role.id));
      const removedEcrfRoles = this.state.ecrf_trial_roles.filter(role => message.removed_ecrf_trial_role_ids.includes(role.id));
      const namesArray = removedEcrfRoles.map(role => role.name);
      // let conf_filtered = [];
      let newSelectedIds = {...this.state.selectedIds};
      newSelectedIds["Rollen"] = [];
      this.setState({ ecrf_trial_roles: newEcrfRoles, selectedIds: newSelectedIds });
      this.props.enqueueSnackbar('Studie(n) wurde(n) von Benutzer ' + message.username + ' entfernt: ' + namesArray, {variant: 'success'});
    }
  }

  render() {
    const { openDialogs } = this.state;
    
    const requiredConfigurationFields = ["trial_id", "status"];
    const requiredFunctionFields = ["ecrf_trial_configuration_id", "name"];
    const requiredRoleFields = ["ecrf_trial_configuration_id", "name"];
    const confStatusOptions = [{value: "open", label: "Offen", backgroundColor: "#76d658"}, {value: "closed", label: "Geschlossen", backgroundColor: "#FF4917"}]
    const confOptions = {status: confStatusOptions}
    const cnfOptions = this.state.ecrf_trial_configurations.map(conf => {
      const ret = {label: conf.trial.short, value: conf.id};
      return ret;
    })
    const fnOptions = this.state.ecrf_trial_functions.map(fn => {
      const ret = {label: fn.name, value: fn.id};
      return ret;
    })
    const roleNameOptions = this.state.ecrf_trial_roles.map(role => {
      const ret = {value: role.name, label: role.name}; 
      return ret;
    })
    const roleOptions = {ecrf_trial_configuration_id: cnfOptions, ecrf_trial_function_id: fnOptions, name: roleNameOptions}
    const functionNameOptions = this.state.ecrf_trial_functions.map(func => {
      const ret = {value: func.name, label: func.name}; 
      return ret;
    })
    const functionOptions = {ecrf_trial_configuration_id: cnfOptions, name: functionNameOptions}
    const annotatedEcrfConfigurations = this.state.ecrf_trial_configurations.map(conf => {
      const ret = {...conf, confStatusArray: confStatusOptions.find(opt => opt.value == conf.status), link: "/trials/" + conf.trial.id + "/centers/"}
      return ret;
    })
    // const annotatedEcrfFunctions = this.state.ecrf_trial_functions.map(func => {
    //   const ret = {...func, ecrf_trial_configuration_id: func.trial_role_associations.ecrf_trial_configuration_id}
    //   return ret;
    // })
    // const annotatedEcrfRoles = this.state.ecrf_trial_roles.map(role => {
    //   const ret = {...role, ecrf_trial_configuration_id: role.trial_role_associations.ecrf_trial_configuration_id, ecrf_trial_function_id: role.trial_role_associations.ecrf_trial_function_id, ecrf_trial_role_association_id: role.trial_role_associations.map(tra => tra.id}
    //   return ret;
    // })
    const selectedConfigurations = (annotatedEcrfConfigurations && this.state.selectedIds && this.state.selectedIds["Studien"]) ? annotatedEcrfConfigurations.filter(ecrftc => this.state.selectedIds["Studien"].includes(ecrftc.id )) : [];
    const selectedFunctions = (this.state.ecrf_trial_functions && this.state.selectedIds && this.state.selectedIds["Funktionen"] && this.state.selectedIds["Funktionen"].length == 1) ? this.state.ecrf_trial_functions.filter(func => this.state.selectedIds["Funktionen"].includes(func.id)).map(func => {
      return ({...func, ecrf_trial_configuration_id: func.ecrf_trial_function_associations[0].ecrf_trial_configuration_id})
      // func.ecrf_trial_function_associations.map(assoc => assoc.ecrf_trial_configuration_id)
    }) : [];
    const selectedRoles = (this.state.ecrf_trial_roles && this.state.selectedIds && this.state.selectedIds["Rollen"]) ? this.state.ecrf_trial_roles.filter(role => this.state.selectedIds["Rollen"].includes(role.id)).map(role => {
      return ({...role, ecrf_trial_configuration_id: role.ecrf_trial_role_associations[0].ecrf_trial_configuration_id, ecrf_trial_function_id: role.ecrf_trial_role_associations[0].ecrf_trial_function_id})
    }) : [];
    const filteredEcrfTrialFunctions = (this.state.selectedIds["Studien"] && this.state.selectedIds["Studien"].length > 0) ? this.state.ecrf_trial_functions.filter(func => this.hasIntersectingElement(this.state.selectedIds["Studien"], func.ecrf_trial_function_associations.map(assoc => assoc.ecrf_trial_configuration_id))) : this.state.ecrf_trial_functions;
    const trialFilteredEcrfTrialRoleIds = (this.state.selectedIds["Studien"] && this.state.selectedIds["Studien"].length > 0) ? this.state.ecrf_trial_roles.filter(role => this.hasIntersectingElement(this.state.selectedIds["Studien"], role.ecrf_trial_role_associations.map(assoc => assoc.ecrf_trial_configuration_id))).map(role => role.id) : [];
    const functionFilteredEcrfTrialRoleIds = (this.state.selectedIds["Funktionen"] && this.state.selectedIds["Funktionen"].length > 0) ? this.state.ecrf_trial_roles.filter(role => this.hasIntersectingElement(this.state.selectedIds["Funktionen"], role.ecrf_trial_role_associations.map(assoc => assoc.ecrf_trial_function_id))).map(role => role.id) : [];
    const filteredEcrfTrialRoles = this.state.ecrf_trial_roles.filter(role => trialFilteredEcrfTrialRoleIds.includes(role.id) && functionFilteredEcrfTrialRoleIds.includes(role.id));
    // (this.state.selectedIds["Studien"].length > 0 || this.state.selectedIds["Funktionen"].length > 0) ? this.state.ecrf_trial_roles.filter(role => this.hasIntersectingElement(this.state.selectedIds["Studien"], func.ecrf_trial_function_associations.map(assoc => assoc.ecrf_trial_configuration_id))) : this.state.ecrf_trial_roles;
    // console.log(this.state.selectedIds["Funktionen"], trialFilteredEcrfTrialRoleIds, functionFilteredEcrfTrialRoleIds, filteredEcrfTrialRoles  );
    console.log(selectedConfigurations);
    const notes = (selectedConfigurations && selectedConfigurations.length == 1 && selectedConfigurations[0].polymorphic_notes) ? [...selectedConfigurations[0].polymorphic_notes] : [];
    console.log(notes);
    const selectedNotes = (notes && this.state.selectedIds && this.state.selectedIds["Notizen"]) ? notes.filter(note => this.state.selectedIds["Notizen"].includes(note.id)) : null;
    console.log(selectedNotes);
    return (
      <Grid container direction="row" justify="center" alignItems="top">
        <ActionCableConsumer
          channel={{channel: "EcrfManagementChannel"}}
          onReceived={this.handleEcrfManagementReceived}
        />
        { (openDialogs.editStudien) &&
        <EditDialog  
          title="Studienkonfiguration editieren"
          obj={selectedConfigurations[0]}
          open={openDialogs.editStudien}
          handleSave={this.handleEditConfigurationSave}
          handleClose={() => this.handleClose("editStudien")}
          Fields={EcrfTrialConfigurationFields}
          selectOptions={confOptions}
          requiredFields={requiredConfigurationFields}
          fullWidth
          maxWidth="lg"
          paperProps={{style: {height: '700px'}}}
        />}
        { (openDialogs.newStudien) &&
        <NewDialog
          title="Neue Studienkonfiguration anlegen"
          obj={{status: "closed"}}
          open={openDialogs.newStudien}
          handleSave={this.handleNewConfigurationSave}
          handleClose={() => this.handleClose("newStudien")}
          Fields={EcrfTrialConfigurationFields}
          requiredFields={requiredConfigurationFields}
          selectOptions={confOptions}
          // disabledFields={disabledFields}
          // hiddenFields={hiddenFields}
          fullWidth
          maxWidth="lg"
          paperProps={{style: {height: '700px'}}}
        />}
        <Grid item xs={4}>
          <EnhancedTable
            title={"Studien"}
            data={annotatedEcrfConfigurations}
            columns={ecrfTrialConfigurationColumns}
            // singleSelect={!this.state.multipleSelectMode}
            multipleSelectable
            selectedIds={this.state.selectedIds["Studien"]}
            selectedObjects={selectedConfigurations}
            onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "Studien")}
            handleAddClick={() => this.handleOpen("newStudien")}
            // handleInfoClick={this.handleInfoInstitutionClick}
            handleDeleteClick={this.handleDeleteConfigurations}
            // handleFilterClick={this.handleInstitutionsFilterClick}
            filters={this.state.peopleFilters}
            onFilterChange={this.handlePeopleFilterChange}
            rowsPerPage={15}
            orderBy="short"
            order="asc"
            onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "Studien")}
            allData={annotatedEcrfConfigurations}
            autoFill
            selectable
            expanded={true}
            handleExpandChange={this.handleExpandChange}
            objectType="EcrfTrialConfiguration"
          />
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" justify="center" alignItems="top">
            { (openDialogs.editFunktionen) &&
            <EditDialog  
              title="eCRF Funktion editieren"
              obj={selectedFunctions[0]}
              open={openDialogs.editFunktionen}
              handleSave={this.handleEditFunctionSave}
              handleClose={() => this.handleClose("editFunktionen")}
              Fields={EcrfFunctionFields}
              selectOptions={functionOptions}
              requiredFields={requiredFunctionFields}
              fullWidth
              maxWidth="lg"
              paperProps={{style: {height: '700px'}}}
            />}
            { (openDialogs.newFunktionen) &&
            <NewDialog
              title="Neue eCRF Funktion anlegen"
              obj={{ecrf_trial_configuration_id: (this.state.selectedIds["Studien"] && this.state.selectedIds["Studien"].length == 1) ? this.state.selectedIds["Studien"][0] : null}}
              open={openDialogs.newFunktionen}
              handleSave={this.handleNewFunctionSave}
              handleClose={() => this.handleClose("newFunktionen")}
              Fields={EcrfFunctionFields}
              requiredFields={requiredFunctionFields}
              selectOptions={functionOptions}
              // disabledFields={disabledFields}
              // hiddenFields={hiddenFields}
              fullWidth
              maxWidth="lg"
              paperProps={{style: {height: '700px'}}}
            />}
            { (this.state.selectedIds["Studien"] && this.state.selectedIds["Studien"].length > 0) &&
              <Grid item xs={6}>
                <EnhancedTable
                  title={"Funktionen"}
                  data={filteredEcrfTrialFunctions}
                  columns={ecrfTrialFunctionColumns}
                  // singleSelect={!this.state.multipleSelectMode}
                  multipleSelectable
                  selectedIds={this.state.selectedIds["Funktionen"]}
                  selectedObjects={selectedFunctions}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "Funktionen")}
                  handleAddClick={() => this.handleOpen("newFunktionen")}
                  // handleInfoClick={this.handleInfoInstitutionClick}
                  handleDeleteClick={this.handleDeleteFunctions}
                  // handleFilterClick={this.handleInstitutionsFilterClick}
                  filters={this.state.peopleFilters}
                  onFilterChange={this.handlePeopleFilterChange}
                  rowsPerPage={5}
                  orderBy="name"
                  order="asc"
                  onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "Funktionen")}
                  allData={filteredEcrfTrialFunctions}
                  // autoFill
                  selectable
                  expanded={true}
                  handleExpandChange={this.handleExpandChange}
                  objectType="EcrfTrialConfiguration"
                />
              </Grid>
            }
            { (openDialogs.editRollen) &&
            <EditDialog  
              title="eCRF Rolle editieren"
              obj={selectedRoles[0]}
              open={openDialogs.editRollen}
              handleSave={this.handleEditRoleSave}
              handleClose={() => this.handleClose("editRollen")}
              Fields={EcrfRoleFields}
              selectOptions={roleOptions}
              requiredFields={requiredRoleFields}
              fullWidth
              maxWidth="lg"
              paperProps={{style: {height: '700px'}}}
            />}
            { (openDialogs.newRollen) &&
            <NewDialog
              title="Neue eCRF Rolle anlegen"
              obj={{ecrf_trial_configuration_id: (this.state.selectedIds["Studien"].length == 1) ? this.state.selectedIds["Studien"][0] : null, ecrf_trial_function_id: (this.state.selectedIds["Funktionen"].length == 1) ? this.state.selectedIds["Funktionen"][0] : null}}
              open={openDialogs.newRollen}
              handleSave={this.handleNewRoleSave}
              handleClose={() => this.handleClose("newRollen")}
              Fields={EcrfRoleFields}
              requiredFields={requiredRoleFields}
              selectOptions={roleOptions}
              // disabledFields={disabledFields}
              // hiddenFields={hiddenFields}
              fullWidth
              maxWidth="lg"
              paperProps={{style: {height: '700px'}}}
            />}
            { (this.state.selectedIds["Funktionen"] && this.state.selectedIds["Funktionen"].length > 0) &&
              <Grid item xs={6}>
                <EnhancedTable
                  title={"Rollen"}
                  data={filteredEcrfTrialRoles}
                  columns={ecrfTrialRoleColumns}
                  // singleSelect={!this.state.multipleSelectMode}
                  multipleSelectable
                  selectedIds={this.state.selectedIds["Rollen"]}
                  selectedObjects={selectedRoles}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "Rollen")}
                  handleAddClick={() => this.handleOpen("newRollen")}
                  // handleInfoClick={this.handleInfoInstitutionClick}
                  handleDeleteClick={this.handleDeleteRoles}
                  handleFilterClick={this.handleInstitutionsFilterClick}
                  filters={this.state.peopleFilters}
                  onFilterChange={this.handlePeopleFilterChange}
                  rowsPerPage={5}
                  orderBy="name"
                  order="asc"
                  onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "Rollen")}
                  allData={filteredEcrfTrialRoles}
                  // autoFill
                  selectable
                  expanded={true}
                  handleExpandChange={this.handleExpandChange}
                  objectType="EcrfTrialConfiguration"
                />
              </Grid>
            }
            { (this.state.selectedIds && this.state.selectedIds["Studien"] && this.state.selectedIds["Studien"].length > 0) &&
              <Grid item xs={12} >
                { (openDialogs.editNotizen && selectedNotes && selectedNotes.length == 1) &&
                  <EditNoteDialog
                    open={openDialogs.editNotizen}
                    selectedNote={selectedNotes[0]}
                    handleSave={this.handleEditNoteSave}
                    handleClose={() => this.handleClose("editNotizen")}
                  />
                }
                { (openDialogs.newNotizen) &&
                  <NewNoteDialog
                    open={openDialogs.newNotizen}
                    handleSave={this.handleNewNoteSave}
                    handleClose={() => this.handleClose("newNotizen")}
                  />
                }
                <Grid container justify="center">
                  {/* <Paper> */}
                    <EnhancedTable
                      title={"Notizen"}
                      data={notes}
                      columns={noteColumns}
                      // singleSelect={!this.state.multipleSelectMode}
                      multipleSelectable
                      selectedIds={this.state.selectedIds["Notizen"]}
                      selectedObjects={selectedNotes}
                      onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "Notizen")}
                      // selectedIds={this.state.selectedNoteIds}
                      // selectedObjects={this.state.selectedNotes}
                      // onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleNoteChange(rowData, allRowsSelected, allObjectsSelected)}
                      handleAddClick={() => this.handleOpen("newNotizen")}
                      // handleInfoClick={this.handleInfoSiteClick}
                      // handleEditClick={this.handleEditSiteClick}
                      // handleRemoveClick={this.handleRemoveSiteClick}
                      handleDeleteClick={this.handleDeleteNote}
                      // handleFilterClick={this.handleSitesFilterClick}
                      // filters={this.state.siteFilters}
                      rowsPerPage={5}
                      orderBy="entry_date"
                      order="desc"
                      onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "Notizen")}
                      // markedIds={this.state.selectedDepartmentsSitesIds}
                      // onFilterChange={this.handleSitesFilterChange}
                      allData={notes}
                      selectable
                      expanded={true}
                      // '{this.state.expandedTable["Notizen"]}
                      // handleExpandChange={this.handleExpandChange}
                      superType="EcrfTrialConfiguration"
                    />
                  {/* </Paper> */}
                </Grid>
              </Grid>}
            </Grid>
          </Grid>
          <Grid xs={12}>
          <Typography style={{color: 'red', fontSize: '20px'}}>
          Hinweise: <br/>
            1.	Studien können nur in TOPAS angelegt und editiert werden. Sie müssen erst in die User-Verwaltung importiert werden, um sie nutzen zu können. Die Liste der Studien ist hier: http://w010-topas.inet.dkfz-heidelberg.de/trials. <br/>
            2.	Damit ein User eine eCRF-Account beantragen kann, muss die Studie in die eCRF-Verwaltung importiert worden sein und „Registrierung“ auf „offen“ stehen. <br/>
            3.	Die Prüfstellen werden ebenfalls nur in TOPAS verwaltet. Die eCRF-Userverwaltung verwendet automatisch alle in der Zentrenliste eingetragenen Prüfstellen, die nicht als versteckt markiert wurden. <br/>
            4.	Studien, Funktionen und Rollen können nur aus der Userverwaltung entfernt werden, wenn ihnen keine eCRF-Accounts mehr zugeordnet sind. <br/>
            5.	Die Tabelle filtern von links nach rechts die angezeigten Inhalte. D.h. für eine ausgewählte Studie werden immer nur die verfügbaren Funktionen und Rollen angezeigt.
          </Typography>
          </Grid>
      </Grid>
    )
  }
}

EcrfManagementConfiguration.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(EcrfManagementConfiguration));
