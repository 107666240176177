import React from 'react';
import classNames from 'classnames';
// import { withStyles } from '@mui/styles';
// import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
// import MUIDataTable from "mui-datatables";
// import { createTheme } from '@mui/styles';

import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import EditUserDialog from './EditUserDialog.jsx';
import EditRoleDialog from './EditRoleDialog.jsx';
import NewRoleDialog from './NewRoleDialog.jsx';
import EnhancedTable from './EnhancedTable.jsx';
import SearchBar from './SearchBar.jsx';


const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};
// const theme = createTheme({
//   overrides: {
//     MuiIconButton: {
//       root: {
//         padding: 3,
//       },
//     },
//     MuiTableCell: {
//       root: {
//         paddingTop: 3,
//         paddingBottom: 3,
//         paddingRight: 10,
//         paddingLeft: 10,
//         height: 10,
//       },
//       paddingCheckbox: {
//         width: 37,
//         backgroundColor: 'FFFFF',
//       },
//     },
//     MuiTableCell: {
//       head: {
//         paddingTop: 6,
//         paddingBottom: 6,
//         paddingRight: 10,
//         paddingLeft: 10,
//         height: 10,
//       },
//     },
//     MuiTableRow: {
//       root: {
//         height: "7px",
//       },
//       head: {
//         height: "26px",
//       },
//     },
//     MuiTableHead: {
//       root: {
//         height: "30px",
//       },
//     },
//     MuiIconButton: {
//       root: {
//         padding: 0,
//       },
//     },
//     MuiTablePagination: {
//       toolbar: {
//         minHeight: 0,
//         height: 30,
//         paddingRight: 2,
//       }
//     },
//     MuiPaper: {
//       elevation24: {
//         height: 800,
//         width: 1600,
//       },
//     },
//   },
//   palette: {
//     primary: {main: blue[500]},
//     secondary: {main: blue[800]}
//   },
//
// });

class UsersGroupsIndex extends React.Component {
  constructor(props) {
    super(props);
    const userColumns = [
      {
        id: "id",
        label: "ID",
        numeric: false,
        padding: 'none',
      },
      {
        id: "admin",
        label: "Admin",
        numeric: false,
        padding: 'none',
        type: "boolean"
      },
      {
        id: "name",
        label: "Name",
        numeric: false,
        padding: 'none',
        type: "string"
      },
      {
        id: "email",
        label: "Status",
        numeric: false,
        padding: 'none',
        type: "string",
      },
      {
        id: "groupNames",
        label: "Gruppen",
        padding: 'none',
        numeric: false,
        type: "shards",
      },
      {
        id: "created_at",
        label: "Erstellungsdatum",
        numeric: false,
        padding: 'none',
        date: true,
        type: "date",
      },
      {
        id: "updated_at",
        label: "Letzte Änderung",
        numeric: false,
        padding: 'none',
        date: true,
        type: "date",
      },
    ];
    const roleColumns = [
      {
        id: "id",
        label: "ID",
        numeric: false,
        padding: 'none',
      },
      {
        id: "name",
        label: "Name",
        numeric: false,
        padding: 'none',
        type: "string"
      },
    ];

    this.state = {users: this.props.users, roles: this.props.roles, permission_fields: this.props.permission_fields, userColumns: userColumns, roleColumns: roleColumns, searchTerm: "", openDialogs: {editUser: false, newRole: false, editRole: false}, user: props.user};
    this.form = React.createRef();
  }

  onSearchChange = event => {
    this.setState({searchTerm: event.target.value});
    this.handleFilterUpdate();
  }

  handleSearchClear = event => {
    this.setState({searchTerm: ""});
    this.handleFilterUpdate();
  }

  updateUsers = () => {
    let currentComponent = this;
    fetch('/trials/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({ trials: data.trials });
    });
  }

  handleUserChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedUsers: allObjectsSelected,
      selectedUserIds: allRowsSelected,
      selectedUserIndices: this.state.users.findIndex(object => object.id === allRowsSelected[0]),
    });
  };

  handleRoleChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedRoles: allObjectsSelected,
      selectedRoleIds: allRowsSelected,
      selectedRoleIndices: this.state.roles.findIndex(object => object.id === allRowsSelected[0]),
    });
  };

  handleNewRoleSave = object => {
    let currentComponent = this;
    console.log(object);
    // this.setState({ newTrialOpen: false});
    // fetch('/roles/create', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'X-Transaction': 'POST Example',
    //     'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    //   },
    //   body: JSON.stringify({
    //     role: object,
    //   }),
    //   credentials: 'include',
    // })
    // .then(function(response){return response.json();})
    // .then(function(data){
    //   // var trials = [];
    //   // trials = trials.concat(currentComponent.state.trials);
    //   //
    //   // trials.push(data.trial);
    //   // currentComponent.setState({ trials: trials });
    //   if (data.response == 1) {
    //     currentComponent.props.enqueueSnackbar('Erfolgreich erstellt.', {variant: 'success'});
    //   } else {
    //     currentComponent.props.enqueueSnackbar('Studie konnte nicht erstellt werden. Bitte kontaktieren Sie ihren Administrator.', {variant: 'success'});
    //   }

    // });
  };

  handleEditUserSave = (user) => {
    let currentComponent = this;
    // console.log(site);
    
    fetch('/users/'+this.state.trial.id+'/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      // TODO
      body: JSON.stringify({
        // TODO
        site_id: site.id,
        marvin_id: site.marvin_id,
        institution_id: site.institution_id,
        inst_description: site.inst_description,
        name: site.name,
        show: 1,
        site_status_id: 1,
  
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        this.setState({ editUserOpen: false});
      } else {

      }

      // console.log(data);
      // currentComponent.setState({ notes: notes });
    });
    this.updateTrials
  }

  handleEditRoleSave = (user) => {
    let currentComponent = this;
    // console.log(site);
    
    fetch('/roles/' + user.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      // TODO
      body: JSON.stringify({
        // TODO
        user: user 
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        this.setState({ editUserOpen: false});
      } else {

      }

      // console.log(data);
      // currentComponent.setState({ notes: notes });
    });
    this.updateTrials
  }

  handleEditUserOpen = (event, obj) => {
    // console.log("clicked edit");
    console.log(event);
    let newOpenDialogs = {...this.state.openDialogs}
    newOpenDialogs["editUser"] = true;
    var allObjectsSelected = [obj];
    // console.log(allObjectsSelected);
    var selectedObjectIds = [obj.id];
    var selectedObjectIndices = this.state.users.findIndex(object => object.id === obj.id);
    this.setState({
      selectedUsers: allObjectsSelected,
      selectedUserIds: selectedObjectIds,
      selectedUserIndices: selectedObjectIndices,
      openDialogs: newOpenDialogs,
    });
  };

  handleNewRoleOpen = (event, obj) => {
    // console.log("clicked edit");
    console.log(event);
    let newOpenDialogs = {...this.state.openDialogs}
    newOpenDialogs["newRole"] = true;
    this.setState({
      openDialogs: newOpenDialogs,
    });
  };

  handleEditRoleOpen = (event, obj) => {
    // console.log("clicked edit");
    console.log(event);
    let newOpenDialogs = {...this.state.openDialogs}
    newOpenDialogs["editRole"] = true;
    var allObjectsSelected = [obj];
    // console.log(allObjectsSelected);
    var selectedObjectIds = [obj.id];
    var selectedObjectIndices = this.state.users.findIndex(object => object.id === obj.id);
    this.setState({
      selectedRoles: allObjectsSelected,
      selectedRoleIds: selectedObjectIds,
      selectedRoleIndices: selectedObjectIndices,
      openDialogs: newOpenDialogs,
    });
  };

  handleEditUserClose = event => {
    let openDialogs = {...this.state.openDialogs}
    openDialogs["editUser"] = false;
    this.setState({ openDialogs: openDialogs});
  };

  handleEditRoleClose = event => {
    let openDialogs = {...this.state.openDialogs}
    openDialogs["editRole"] = false;
    this.setState({ openDialogs: openDialogs});
  };

  handleNewRoleClose = event => {
    let openDialogs = {...this.state.openDialogs}
    openDialogs["newRole"] = false;
    this.setState({ openDialogs: openDialogs});
  };

  handleInfoTrialOpen = event => {
    // console.log("clicked edit");
    this.setState({ infoTrialOpen: true});
  };

  handleInfoTrialClose = event => {
    this.setState({ infoTrialOpen: false});
  };

  handleDeleteUserClick = event => {
    let currentComponent = this;
    // let newTrials = [];
    // newTrials = newTrials.concat(currentComponent.state.trials);
    fetch('/users/delete_users', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        user_ids: currentComponent.state.selectedUserIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 0) {
        // const newTrials = currentComponent.state.trials.filter(trial => !currentComponent.state.selectedTrialIds.includes(trial.id));
        currentComponent.setState({ selectedUsers: [], selectedUserIds: [] });
        currentComponent.props.enqueueSnackbar('User erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Konnte nicht gelöscht werden.', {variant: 'error'});
      }
    });
    // this.updateSites
  };

  handleDeleteRoleClick = event => {
    let currentComponent = this;
    // console.log(currentComponent.state.selectedRoleIds);
    // let newTrials = [];
    // newTrials = newTrials.concat(currentComponent.state.trials);
    fetch('/roles/delete_roles', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        role_ids: currentComponent.state.selectedRoleIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // const newTrials = currentComponent.state.trials.filter(trial => !currentComponent.state.selectedTrialIds.includes(trial.id));
        currentComponent.setState({ selectedRoles: [], selectedRoleIds: [] });
        currentComponent.props.enqueueSnackbar('User erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Konnte nicht gelöscht werden.', {variant: 'error'});
      }
    });
    // this.updateSites
  };

  handleUsersReceived = message => {
    console.log("received User");
    console.log(message);
    if (message.new_user) {
      let users = [].concat(this.state.users);
      users.push(message.new_trial);
      this.setState({ users: users });
      this.props.enqueueSnackbar('Benutzer wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_user.name, {variant: 'success'});
    }
    if (message.user) {
      let users = [].concat(this.state.users);
      const ind = users.findIndex(user => user.id == message.user.id);
      users[ind] = message.user;
      this.setState({ users: users });
      this.props.enqueueSnackbar('Benutzer wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.user.name, {variant: 'success'});
    }
    if (message.deleted_user_ids) {
      let users = [].concat(this.state.users.filter(user => !message.deleted_user_ids.includes(user.id)));
      let userNames = this.state.users.filter(user => message.deleted_user_ids.includes(user.id)).map(user => user.name);
      // message.deleted_trial_ids.forEach(function (trial_id, index) {
      //   console.log(trials);
      //   const ind = trials.findIndex(d => d.id == trial_id);
      //   trialShorts.push(trials[ind].short)
      //   delete trials[ind];
      // });
      // delete trialBoards[message.deleted_trial_board_ids];
      this.setState({ users: users });
      this.props.enqueueSnackbar('Benutzer wurde(n) von Benutzer ' + message.username + ' gelöscht: ' + userNames, {variant: 'success'});
    }
  }

  handleRolesReceived = message => {
    console.log("received Role");
    console.log(message);
    if (message.new_role) {
      let roles = [].concat(this.state.roles);
      roles.push(message.new_role);
      this.setState({ roles: roles });
      this.props.enqueueSnackbar('Rolle wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_role.name, {variant: 'success'});
    }
    if (message.role) {
      let roles = [].concat(this.state.roles);
      const ind = roles.findIndex(role => role.id == message.role.id);
      roles[ind] = message.role;
      this.setState({ roles: roles });
      this.props.enqueueSnackbar('Rolle wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.role.name, {variant: 'success'});
    }
    if (message.deleted_role_ids) {
      let roles = [].concat(this.state.roles.filter(role => !message.deleted_role_ids.includes(role.id)));
      let roleNames = this.state.roles.filter(role => message.deleted_role_ids.includes(role.id)).map(role => role.name);
      // message.deleted_trial_ids.forEach(function (trial_id, index) {
      //   console.log(trials);
      //   const ind = trials.findIndex(d => d.id == trial_id);
      //   trialShorts.push(trials[ind].short)
      //   delete trials[ind];
      // });
      // delete trialBoards[message.deleted_trial_board_ids];
      this.setState({ roles: roles });
      this.props.enqueueSnackbar('Rolle(n) wurde(n) von Benutzer ' + message.username + ' gelöscht: ' + roleNames, {variant: 'success'});
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    // console.log(this.props);
    const filteredUsers = this.state.users.filter(user => user.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()) || user.email.toLowerCase().includes(this.state.searchTerm));
    // const supplementedTrials = filteredTrials.map(trial => {
    //   let ret = {...trial};
    //   ret["statusText"] = ret.status.text;
    //   ret["statusColor"] = ret.status.color;
    //   return ret;
    // })
    const supplementedUsers = filteredUsers.map(user => {
      const mainUserRoleAssoc = user.user_roles.find(ur => ur.is_main_role);
      const mainRole = (mainUserRoleAssoc) ? this.state.roles.find(role => role.id == mainUserRoleAssoc.role_id) : null;
      return {...user, mainRole: mainRole, groupNames: (user.roles) ? user.roles.map(group => group.name) : null}
    })
    const perms = Object.keys(this.state.roles[0]).filter(key => key.includes("_read"));
    const permSet = new Set(perms.map(perm => perm.substr(0,perm.lastIndexOf("_"))));
    const permArr = Array.from(permSet);

    
    return (
      // <ThemeProvider theme={theme}>
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "UsersChannel"}}
            onReceived={this.handleUsersReceived}
            onConnected={this.handleConnected}
          />
          <ActionCableConsumer
            channel={{channel: "RolesChannel"}}
            onReceived={this.handleRolesReceived}
            onConnected={this.handleConnected}
          />
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" name="position" row>
                <SearchBar
                  onChange={this.onSearchChange}
                  handleClear={this.handleSearchClear}
                  searchTerm={this.state.searchTerm}
                />
                <Tooltip title="Refresh">
                  <IconButton aria-label="Info" onClick={this.updateTrials}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              { (this.state.openDialogs.editUser) &&
              <EditUserDialog
                open={this.state.openDialogs.editUser}
                obj={this.state.selectedUsers[0]}
                handleClose={this.handleEditUserClose}
                handleSave={this.handleEditUserSave}
                roles={this.state.roles}
              />}
              <EnhancedTable
                title={"Benutzer"}
                data={supplementedUsers}
                // singleSelect={false}
                multipleSelectable
                selectedIds={this.state.selectedUserIds}
                selectedObjects={this.state.selectedUsers}
                columns={this.state.userColumns}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleUserChange(rowData, allRowsSelected, allObjectsSelected)}
                onDoubleClick={this.handleEditUserOpen}
                handleDeleteClick={this.handleDeleteUserClick}
                rowsPerPage={30}
                order="asc"
                orderBy="id"
                // controller="users"
                autoFill
                selectable
                expanded
                objectType="User"
                noAddIcon
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              { (this.state.openDialogs.newRole) &&
              <NewRoleDialog
                open={this.state.openDialogs.newRole}
                handleClose={this.handleNewRoleClose}
                handleSave={this.handleNewRoleSave}
                permArr={permArr}
              />}
              { (this.state.openDialogs.editRole) &&
              <EditRoleDialog
                open={this.state.openDialogs.editRole}
                obj={this.state.selectedRoles[0]}
                handleClose={this.handleEditRoleClose}
                handleSave={this.handleEditRoleSave}
                permArr={permArr}
              />}
              <EnhancedTable
                title={"Rollen"}
                data={this.state.roles}
                // singleSelect={false}
                multipleSelectable
                selectedIds={this.state.selectedRoleIds}
                selectedObjects={this.state.selectedRoles}
                columns={this.state.roleColumns}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRoleChange(rowData, allRowsSelected, allObjectsSelected)}
                onDoubleClick={this.handleEditRoleOpen}
                handleAddClick={this.handleNewRoleOpen}
                handleDeleteClick={this.handleDeleteRoleClick}
                rowsPerPage={30}
                order="asc"
                orderBy="id"
                // controller="users"
                autoFill
                selectable
                expanded
                objectType="Role"
              />
            </Grid>
          </Grid>
        </Grid>
      // </ThemeProvider>
    )
  }
}

export default withSnackbar(UsersGroupsIndex);
