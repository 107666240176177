import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from 'react-select';

class EditMilestoneDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: this.props.name };
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.name != prevProps.name) {
      this.setState({name: this.props.name})
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  render(props) {

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // maxWidth="lg"
        fullWidth
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">Milestone editieren</DialogTitle>
        <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          onChange={this.handleChange}
          fullWidth
          variant="outlined"
          value={this.state.name}
          type="string"
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={this.props.handleSave({ ...this.state, old_name: this.props.name })} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditMilestoneDialog;
