import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from './TextField.jsx';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import EditIcon from '@mui/icons-material/Edit';

import TaskFields from './TaskFields.jsx';
import EditTaskDialog from './EditTaskDialog.jsx';
import RemindersList from './RemindersList.jsx';
import TrialPersonFunctionSelector from './TrialPersonFunctionSelector.jsx';

export default function TaskPaper(props) {
  const { obj, taskStatusList, selectableTaskStatusList, handleChange, handleChange2, handleEditStepClick } = props;
  const [editTaskOpen, setEditTaskOpen] = useState(false);
  // const selectableStepStatusList = props.stepStatusList.filter(ss => (ss.value == "done" || ss.value == "active"));
  // console.log("taskPaper");
  console.log(obj);
    return (
    <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
      <EditTaskDialog
        obj={obj}
        dueDateNotEditable={props.dueDateNotEditable}
        open={editTaskOpen}
        enqueueSnackbar={props.enqueueSnackbar}
        // handleAddOpen={this.handleEditProcessStepOpen}
        // handleSave={this.handleEditProcessStepSave}
        handleClose={() => setEditTaskOpen(false)}
        // selectedProcessStep={selectedProcessStep}
        // previousProcessStep={previousProcessStep}
        // stepStatusList={stepStatusList}
        taskStatusList={taskStatusList}
        selectableTaskStatusList={selectableTaskStatusList}
        // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center"> 
        <Typography variant="h6" id="Task" component="div" >
          Task
        </Typography>
        
        <Tooltip title="Editieren">
          <IconButton aria-label="Edit" onClick={() => setEditTaskOpen(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <TaskFields
        obj={obj}
        handleChange={handleChange}
        handleChange2={handleChange2}
        dueDateNotEditable={props.dueDateNotEditable}
        readOnly={true}
        taskStatusList={taskStatusList}
        statusEditable={true}
        selectableTaskStatusList={selectableTaskStatusList}
      />
      { (!obj.taskable.activated) &&
      <TrialPersonFunctionSelector
        selectedFunction={obj.trial_roles}
        // handleInputValueChange={this.handleFunctionChange}
        isMulti
        error={((!obj.automatic_email && !obj.automatic_notification) || (obj.trial_roles.length > 0))}
        disabled
        title="Zu erinnernde Funktionen"
      />}
      <RemindersList
        reminders={obj.reminders}
        reminderableType="Task"
        reminderableId={obj.id}
        reminderable={obj}
        // handleNewClick={() => setReminderOpen(true)}
        // handleEditClick={handleEditReminderClick}
        // handleDeleteClick={handleDeleteClick}
      />
    </Paper>
  )
}
