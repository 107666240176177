import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

// import moment from 'moment'
// import "moment/locale/de";

// moment.locale("de")

import ReminderFields from './ReminderFields.jsx';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

class EditReminderDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, obj: this.props.obj};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {

  }

  componentDidMount(props) {
    // let currentComponent = this;
    // this.setState({});
  }

  handleSave = par => {
    let currentComponent = this;
       
    fetch('/reminders/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        reminder: par.obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // currentComponent.props.enqueueSnackbar('Erfolgreich geändert.', {variant: 'success'});
        // currentComponent.handleEditProcessStepClose();
        currentComponent.props.handleClose();
      } else {
        // currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // if (currentComponent.props.handleSave) {
    //   currentComponent.props.handleSave();
    // }
  }


  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    currentComponent.setState({ obj: newObj });
  };

  handleCheckboxChange = (event, meta) => {
    const value = meta;
    const name = event.target.name;
    let newObj = {...this.state.obj};
    newObj[name] = value;
    this.setState({ obj: newObj });
  }

  // handleChangeDate = (date, name) => {
  //   let newObj = {...this.state.obj};
  //   newObj[name] = moment(date).startOf("day").add(2, 'hour');
  //   this.setState({obj: newObj});
  // };

  handleClose = event => {
    this.props.handleClose();
  }

  render(props) {
    const { obj } = this.state;
    const saveable = obj.deadline && obj.remind_when && obj.title;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '700px'}}}
      >
        <DialogTitle id="form-dialog-title">Erinnerung editieren</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: blue[500]}}>
              <ReminderFields
                obj={obj}
                handleChange={this.handleChange}
                handleCheckboxChange={this.handleCheckboxChange}
                // handleChangeDate={this.handleChangeDate}
                deadlineNotEditable={(obj.reminderable_type == "Task")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.handleSave({
            obj: obj,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditReminderDialog;