import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import EmailIcon from '@mui/icons-material/Email';


export default function EcrfMailToUserDialog({ Fields, ...props }) {

  const [obj, setObj] = useState({ ...props.obj });
  // all fields with errors false 
  //const [errors, setErrors] = useState(Object.keys(obj).reduce((obj, v) => ({ ...obj, [v]: false }), {}))
  const [errors, setErrors] = useState([]);

  function objChanged() {
    Object.keys(obj).forEach(key => {
      if (obj[key] != props.obj[key]) {
        return false;
      }
      return true;
    })
  }

  function handleChange(event, meta) {
    console.log(event, meta);
    let newObj = obj;
    const target = event.target;
    let value = target.value;
    if (target.type == "checkbox") {
      const name = target.name;
      newObj[name] = meta;
    } else {
      const name = target.id || target.name;
      newObj[name] = value;
    }
    setObj({ ...newObj });
  };

  function handleSelectChange(event) {
    console.log("handleSelectChange", event);
    let newObj = obj;
    const target = event.target;
    const value = target.value;
    const name = target.name;
    newObj[name] = value;
    setObj({ ...newObj });
  };

  function onCreateOption(test) {
    let newObj = {...obj};
    newObj["ecrf_trial_configuration_id"] = value;
    // this.setState({inputValue: newValue});
    setObj({...newObj});
  }

  function handleInputValueChange(newValue, actionMeta) {
    if ((actionMeta.action != "input-blur") && (actionMeta.action != "menu-close")) {
      console.log(newValue, actionMeta);
      this.setState({inputValue: newValue});
    }
  }

  function handleInput(event) {
    // detects errors in inputfields
    const target = event.target;
    const name = target.id || target.name;
    if (!target.validity.valid) {
      if (!errors.includes(name)) {
        setErrors([...errors, name]);
      }
    } else {
      // resets errors
      if (errors.includes(name)) {
        let temp = errors
        temp.splice(temp.indexOf(name), 1)
        setErrors([...temp]);
      }
    }
  }


  const handleChangeSelect = (selected) => {

    console.log(selected);
  }

  function isSuperset(set, subset) {
    for (const elem of subset) {
      if (!set.has(elem)) {
        console.log(elem);
        return false;
      }
    }
    return true;
  }

  function addObjValue(field, value) {
    let newObj = obj;
    newObj[field] = value;
    setObj({ ...newObj });
  }


  // const filledFields = [];
  // for(var key in obj){
  //   if (obj[key]) {
  //     filledFields.push(key);
  //   }
  // }
  // const filledFields = Object.keys(obj).map(key => (obj[key] != null) && (obj[key] != ""));
  let requiredFieldsFilled = true;
  for (let field in props.requiredFields) {
    if (obj[props.requiredFields[field]] == null || obj[props.requiredFields[field]] == "" || obj[props.requiredFields[field]] == false) {
      requiredFieldsFilled = false;
      break;
    }
  }

  const saveable = requiredFieldsFilled;
  // console.log(props.requiredFields, requiredFieldsFilled);
  // console.log(obj);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      PaperProps={props.paperProps}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <Tooltip title="Leere Mail">
          <Button 
            variant="contained"
            // onClick={this.handleOpen("fileUpload")}
            href={"mailto:" + props.email + "?subject=look at this website&body=Hi, I found this website and thought you might like it http://www.geocities.com/wowhtml/"}
            size="small"
          > 
            Leere Mail
          </Button>
        </Tooltip>
        <Tooltip title="Studienantrag ergänzen">
          <Button 
            variant="contained"
            // onClick={this.handleOpen("fileUpload")}
            href={"mailto:" + props.email + "?subject=eCRF Marvin – Bitte Personendaten / Nachweise bearbeiten " + props.last_name + ", " + props.first_name + "&body=Guten Tag " + ((props.person_title) ? props.person_title + " " : "") + props.first_name + " " + props.last_name + ",%0D%0A%0D%0ADie Prüfung Ihrer persönlichen Daten und / oder der allgemeinen Nachweise war leider nicht erfolgreich.%0D%0A%0D%0ABitte korrigieren Sie folgende Informationen%0D%0AXXX PLATZHALTER – BITTE ANPASSEN XXX%0D%0A%0D%0ABitte reichen Sie folgende Nachweise nach%0D%0AXXX PLATZHALTER – BITTE ANPASSEN XXX%0D%0A%0D%0A%0D%0ABei Rückfragen wenden Sie sich bitte an eCRF.account.support@nct-heidelberg.de.%0D%0A%0D%0AMit freundlichen Grüßen,"}
            size="small"
          > 
            Bearbeitung
          </Button>
        </Tooltip>
        <Tooltip title="Studienantrag bereit">
          <Button 
            variant="contained"
            // onClick={this.handleOpen("fileUpload")}
            href={"mailto:" + props.email + "?subject=eCRF Marvin –Personendaten / Nachweise sind korrekt: " + props.last_name + ", " + props.first_name + "&body=Guten Tag " + ((props.person_title) ? props.person_title + " " : "") + props.first_name + " " + props.last_name + ",%0D%0A%0D%0ADie Prüfung Ihrer persönlichen Daten und / oder der allgemeinen Nachweise war erfolgreich.%0D%0A%0D%0ASie können nun im nächsten Schritt einen Antrag für einen eCRF-Account einer Studie ebenso über unser Portal https://topas.nct-heidelberg.de/ecrf_management/login/ stellen.%0D%0A%0D%0ABei Rückfragen wenden Sie sich bitte an eCRF.account.support@nct-heidelberg.de.%0D%0A%0D%0AMit freundlichen Grüßen,"}
            size="small"
          > 
            Erfolgreich
          </Button>
        </Tooltip>
        <Tooltip title="Studienantrag freigeschaltet">
          <Button 
            variant="contained"
            // onClick={this.handleOpen("fileUpload")}
            href={"mailto:" + props.email + "?subject=eCRF Marvin –Antrag eCRF-Zugang erfolgreich geprüft < Akronym >, " + props.last_name + ", " + props.first_name + "&body=Guten Tag " + ((props.person_title) ? props.person_title + " " : "") + props.first_name + " " + props.last_name + ",%0D%0A%0D%0ADie Prüfung Ihres Antrags auf eCRF-Zugang und / oder der studienspezifischen Nachweise war erfolgreich.  Sie müssen nichts weiter unternehmen.%0D%0A%0D%0AWir haben Ihren Account jedoch noch nicht freigeschaltet, da entweder der eCRF insgesamt noch nicht freigeschaltet wurde oder Ihre Prüfstelle noch nicht freigegeben wurde. Wir werden Sie erneut informieren, sobald wir Ihren Account freischalten.%0D%0A%0D%0AAkronym der Studie: < Akronym der Studie >%0D%0AFunktion in Studie: <Funktion>%0D%0APrüfzentrum: <Prüfzentrum>%0D%0ARolle in der Studie:  <Rolle>%0D%0A%0D%0ABei Rückfragen wenden Sie sich bitte an eCRF.account.support@nct-heidelberg.de.%0D%0A%0D%0AMit freundlichen Grüßen,"}
            size="small"
          > 
            Freigeschaltet
          </Button>
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// export default EditDialog;
