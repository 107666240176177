import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { TextField } from '@mui/material';


export default function DropDown(props) {

  const { title, selectable, others, filter_words } = props;
  const others_keyword = "Sonstige";
  const others_keyword_entry = { value: others_keyword, label: others_keyword };
  const [options, setOptions] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [dropdown, setDropdown] = React.useState(true);

  const dropDownComp = () => {
    return (
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel filled animated shrink margin="dense" variant="outlined"> {'titel'} </InputLabel>
        <Select id={title} name={title} options={options} onChange={handleOnChange}
          isSearchable isClearable
          fullWidth
          getOptionValue={(option) => `${option['first_name']}`}
          styles={{
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}>
        </Select>
      </FormControl>
    );
  }

  const inputFieldComp = () => {
    return (
      <TextField
        label={others_keyword}
        id='other'
        size='small'
        margin='dense'
        onBlur={handleOnBlur}
        autoFocus={true}
        fullWidth
      >
      </TextField>
    );
  }

  const handleOnChange = (event, meta) => {
    // change => clear input
    if (event === null) { setSelected(null); return; }
    if (event.value === others_keyword) {
      // change => Others_Keyword is selected 
      setDropdown(false);
    } else {
      // change => new selected
      setSelected(event);
      props.handleSelected(event, meta);
    }
  }

  const handleOnBlur = (event) => {
    // switch back to dropdown 
    setDropdown(true);
    if (event.target.value !== '' && event.target.value !== others_keyword) {
      // remove Others_Keyword from array, add new Entry and add Others
      const tempOpt = options;
      const tempItem = { value: event.target.value, label: event.target.value }
      // insert tempItem right before'Others_KeyWord'
      tempOpt.splice(tempOpt.length - 1, 0, tempItem);
      setSelected(tempItem);
      setOptions(tempOpt);
    }
  }

  const convertData = () => {
    //
    others ? selectable.concat([others_keyword_entry]) : selectable === undefined ? [] : selectable

    return(null);
  }

  const returnSelectedData = (convertedData) => {
    return (null);
  }

  return (
    <div>
      {dropdown
        ? dropDownComp()
        : inputFieldComp()}
    </div>
  )

}

