import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import PieChartIcon from '@mui/icons-material/PieChart';
import BuildIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

import WebIcon from '@mui/icons-material/Web';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Can } from './Can.js'
// import { useAbility } from '@casl/react';
import { AbilityContext } from './Can';


const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

class MainMenuDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    // const ability = useAbility(AbilityContext);
    const { classes } = this.props;
    console.log(this.context);
    const sideList = (
      <>
        <List>
          <ListItemLink href="/">
            <ListItemIcon><DashboardIcon/></ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItemLink>
          <Can I="read" a="Trial">
            <ListItemLink href="/address_book" data-cy="addressbooklink">
              <ListItemIcon><PeopleIcon/></ListItemIcon>
              <ListItemText primary={"Adressbuch"} />
            </ListItemLink>
          </Can>
          <Can I="read" a="Trial">
            <ListItemLink href="/trials" data-cy="trialslink">
              <ListItemIcon><PieChartIcon/></ListItemIcon>
              <ListItemText primary={"Studien"} />
            </ListItemLink>
          </Can>
          {/* <Can I="read" a="Trial">
            <ListItemLink href="/trial_presentation">
              <ListItemIcon><PieChartIcon/></ListItemIcon>
              <ListItemText primary={"Studien Präsentation"} />
            </ListItemLink>
          </Can> */}
          {/* <ListItemLink href="/calendars/show_calendar" data-cy="calendarlink">
            <ListItemIcon><CalendarTodayIcon/></ListItemIcon>
            <ListItemText primary={"Kalender"} />
          </ListItemLink> */}
          {/* <ListItemLink href="/reminders">
            <ListItemIcon><AccessAlarmIcon/></ListItemIcon>
            <ListItemText primary={"Erinnerungen"} />
          </ListItemLink> */}
          <Can I="read" a="Trial">
            <ListItemLink href="/time_registration" data-cy="timeregistrationlink">
              <ListItemIcon><AccessTimeIcon/></ListItemIcon>
              <ListItemText primary={"Zeiterfassung"} />
            </ListItemLink>
          </Can>
          <Can I="read" a="TrialBoard">
            <ListItemLink href="/trial_boards/index_trial_boards" data-cy="trialboardslink">
              <ListItemIcon><GroupsIcon/></ListItemIcon>
              <ListItemText primary={"Studienboard"} />
            </ListItemLink>
          </Can>
          <Can I="read" a="EcrfTrialConfiguration">
            <ListItemLink href="/ecrf_management" data-cy="ecrfmanagementlink">
              <ListItemIcon><WebIcon/></ListItemIcon>
              <ListItemText primary={"eCRF Management"} />
            </ListItemLink>
          </Can>
          <Can I="read" a="User">
            <ListItemLink href="/users" data-cy="userslink">
              <ListItemIcon><ManageAccountsIcon/></ListItemIcon>
              <ListItemText primary={"Benutzer- und Gruppenverwaltung"} />
            </ListItemLink>
          </Can>
          {/* <Can I="read" a="SarcPatient"> */}
            <ListItemLink href="/sarcbop">
              <ListItemIcon><DeveloperBoardIcon/></ListItemIcon>
              <ListItemText 
                primary={"SarcBOP Studie"}
                data-cy="sarcpatient"
              />
            </ListItemLink>
          {/* </Can> */}
          {/* <Can not I="read" a="SarcPatient">
            <ListItemLink href="/sarcbop/request_access">
              <ListItemIcon><DeveloperBoardIcon/></ListItemIcon>
              <ListItemText 
                primary={"SarcBOP Studie"}
                data-cy="sarcpatient"
              />
            </ListItemLink>
          </Can> */}
        </List>
        <Divider />
        {/* <List>
          <Can I="manage" a="User">
            <ListItemLink href="/users">
              <ListItemIcon><PersonIcon/></ListItemIcon>
              <ListItemText primary={"Benutzer"} />
            </ListItemLink>
          </Can>
          <Can I="manage" a="Role">
            <ListItemLink href="/roles">
              <ListItemIcon><AssignmentIndIcon/></ListItemIcon>
              <ListItemText primary={"Rollen"} />
            </ListItemLink>
          </Can>
        </List> */}
      </>
    );

    const fullList = (
      <div className={classes.fullList}>
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <div>
        <IconButton
          onClick={this.toggleDrawer('left', true)}
          color="inherit"
          aria-label="Open drawer"
          // onClick={this.toggleDrawer('left', true)}
          data-cy="mainmenu"
          >
          <MenuIcon />
        </IconButton>

        <SwipeableDrawer
          open={this.state.left}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

MainMenuDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

MainMenuDrawer.contextType = AbilityContext;

export default withStyles(styles)(MainMenuDrawer);
