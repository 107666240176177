import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from 'react-select';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'

const standardFunctionOptions = ["LKP", "Prüfer", "Stellvertreter", "SP", "äMP", "näMP", "SN", "CTA", "Studienkoordinator", "PM", "BM", "DM"];
const createOption = (label) => ({
  label,
  value: label,
  backgroundColor: 'white',
});
const standardOptions = {label: "StandardFunktionen", options: standardFunctionOptions.map(option => createOption(option))};


class NewDocumentDialog extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.state = { name: [], date_start: yyyy+"-"+mm+"-"+dd, site_functions: []};
    this.form = React.createRef();
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  // handleSiteChange = event => {
  //   let currentComponent = this;
  //   const target = event.target;
  //   const value = target.value;
  //   const site_id = value.id;
  //   // console.log(value);
  //   fetch('/trials/'+this.props.trialId+'/site_selection/get_site_departments?'+$.param({site_id: site_id}))
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       // console.log(data);
  //       currentComponent.setState({ departments: data.departments});
  //     });
  //   currentComponent.setState({ selectedSite: value });
  // };

  handleSiteFunctionChange = (functions, meta) => {
    const newSiteFunctions = functions.map(func => func.value);
    this.setState({site_functions: newSiteFunctions});
  }

  handleDepartmentChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    // const department_id = value.id;
    // // console.log(site_id)
    // fetch('/trials/'+this.state.trial.id+'/site_selection/get_department_people?'+$.param({department_id: department_id}))
    //   .then(function(response){return response.json();})
    //   .then(function(data){
    //     console.log(data);
    //     currentComponent.setState({ department_people: data.department_people});
    //   });
    currentComponent.setState({ selectedDepartment: value});
  };

  render(props) {
    const options = this.props.allPeople.map(row =>{
      return ({value: row.site_function, label: row.site_function})
    });
    const selectionOptions = options.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.value == item.value
      ))
    );
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // maxWidth="lg"
        fullWidth
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">Neues Dokument anlegen</DialogTitle>
        <DialogContent>
          <TextField
            id="date_start"
            label="Gültig ab"
            type="date"
            defaultValue={this.state.date_start}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
            autoFocus
          />
          <TextField
            margin="dense"
            id="name"
            label="Name"
            onChange={this.handleChange}
            variant="outlined"
            fullWidth
            type="string"
          />
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
            <InputLabel
              filled
              animated
              shrink
              margin="dense"
              variant="outlined"
              error={!(this.state.site_functions)}
              style={{backgroundColor: "white"}}
            >
              Gilt für:
            </InputLabel>
            <Select
              // value={ this.state.selectedSiteStatus }
              // defaultValue={"Bitte wählen"}
              name={"site_function"}
              onChange={this.handleSiteFunctionChange}
              options={[standardOptions]}
              // styles={colourStyles}
              // menuIsOpen={true}
              // className={"bg-warning"}
              isMulti
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.props.handleSave({name: this.state.name, date_start: this.state.date_start, site_functions: this.state.site_functions})} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewDocumentDialog;
