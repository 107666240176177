import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { withSnackbar } from 'notistack';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'
import PersonGlobalSelector from './PersonGlobalSelector.jsx'
import SitePersonFunctionSelector from './SitePersonFunctionSelector.jsx'
import NewPersonDialog from './NewPersonDialog.jsx'

class AddPersonToDepartmentDialog extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.state = {selectedPerson: this.props.selectedPerson, selectedFunction: [], inputValue: this.props.inputValue, primary_contact: false, join_date: yyyy+"-"+mm+"-"+dd, spa_comment: "", leave_date: null, left: false}
    this.form = React.createRef();
  }

  // handleSiteChange = event => {
  //   let currentComponent = this;
  //   let site_id = event.target.value.id
  //   // console.log(site_id)
  //   fetch('/trials/'+this.props.trialId+'/site_selection/get_institution_departments?'+$.param({site_id: site_id}))
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       console.log(data);
  //       currentComponent.setState({ departments: data.institution_departments});
  //     });
  //   currentComponent.setState({ selectedSite: event.target.value});
  // };
  //
  // handleDepartmentChange = event => {
  //   let currentComponent = this;
  //   let department_id = event.target.value.id
  //   fetch('/trials/'+this.props.trialId+'/site_selection/get_department_people?'+$.param({department_id: department_id}))
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       console.log(data);
  //       currentComponent.setState({ people: data.department_people});
  //     });
  //   currentComponent.setState({ selectedDepartment: event.target.value});
  // };

  componentDidMount(props) {
    let currentComponent = this;
    let peopleList = [];
    fetch('/people/index', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({ people: data.people });
      // currentComponent.setState({ trials: data.trials });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.inputValue != prevProps.inputValue) {
      this.setState({inputValue: this.props.inputValue});
    }
  }

  handleNewPersonOpen = event => {
    this.setState({ newPersonOpen: true, open: false, newPersonName: event});
    // if (event) {
    //   this.setState({ inputValue: event });
    // }
  };

  handleNewPersonClose = event => {
    this.setState({newPersonOpen: false, open: true});
  }

  handleNewPersonSave = person => {
    let currentComponent = this;
    let site_id = -1;
    if (this.props.selectedDepartmentsInstitutionsIds.length == 1) {
      site_id = this.props.selectedDepartmentsInstitutionsIds[0];
    } else if (this.props.selectedSites.length == 1) {
      site_id = this.props.selectedSites[0].id;
    } else {
      currentComponent.props.enqueueSnackbar('Interner Fehler. Bitte Admin kontaktieren.', {variant: 'error'});
    }
    fetch('/people/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        first_name: person.first_name,
        last_name: person.last_name,
        title: person.title,
        phone_number: person.phone_number,
        fax_number: person.fax_number,
        mobile_number: person.mobile_number,
        email: person.email,
        function: person.function,
        gender: person.gender,
        comment: person.comment,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        let people = [].concat(...currentComponent.state.people, data.person);
        // let assocs = [].concat(currentComponent.state.peopleDepartmentsAssociations, data.assoc);
        // console.log(assocs);
        person.last_name + ", " + person.first_name + " (" + person.email + ")"
        const selectedPerson = {value: data.person.id, label: data.person.last_name + ", " + data.person.first_name + " (" + data.person.email + ")"};
        currentComponent.setState({ people: people, newPersonOpen: false, selectedPerson: selectedPerson, connect: true });
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
        currentComponent.props.handleNewPerson(data.person);
        currentComponent.handleNewPersonClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  };

  handlePersonChange = event => {
    let currentComponent = this;
    // console.log(event);
    currentComponent.setState({ selectedPerson: event});
  };

  handleFunctionChange = (newValue, actionMeta) => {
    // console.log(newValue);
    this.setState({selectedFunction: newValue});
  }

  handlePrimaryChange = event => {
    this.setState({primary_contact: event.target.checked});
  }

  handleConnectChange = event => {
    this.setState({connect: event.target.checked});
  }

  handleInputValueChange = (newValue, actionMeta) => {
    if ((actionMeta.action != "input-blur") && (actionMeta.action != "menu-close")) {
      this.setState({inputValue: newValue});
    }
  }

  handleClose = event => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.setState({selectedPerson: this.props.selectedPerson, selectedFunction: [], inputValue: this.props.inputValue, primary_contact: false, join_date: yyyy+"-"+mm+"-"+dd, spa_comment: "", leave_date: null, left: false, connect: false, newPersonOpen: false, open: this.props.open, newPersonName: "", newPersonOpen: false});
    this.props.handleClose();
  }

  handleSave = event => {
    this.props.handleSave({person_id: this.state.selectedPerson.value, function: this.state.selectedFunction.value, primary_contact: this.state.primary_contact, join_date: this.state.join_date, leave_date: this.state.leave_date, left: this.state.left, comment: this.state.spa_comment, connect: this.state.connect});
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.setState({selectedPerson: this.props.selectedPerson, selectedFunction: [], inputValue: this.props.inputValue, primary_contact: false, join_date: yyyy+"-"+mm+"-"+dd, spa_comment: "", leave_date: null, left: false, connect: false, newPersonOpen: false, open: this.props.open, newPersonName: ""});

  };

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleChange2 = (event, checked) => {
    // console.log(event.target.name);
    // console.log(checked);
    this.setState({[event.target.name]: checked});
  }

  render(props) {
    return (
      <>
        <NewPersonDialog
          open={this.state.newPersonOpen}
          lastName={this.state.newPersonName}
          // selectedSite={this.state.selectedSites[0]}
          // selectedDepartment={this.state.selectedDepartments[0]}
          // selectedDepartmentSiteId={this.state.selectedDepartmentsInstitutionsIds[0]}
          handleOpen={this.handleNewPersonOpen}
          handleClose={this.handleNewPersonClose}
          handleSave={this.handleNewPersonSave}
          inputValue={this.state.inputValue}
        />
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          // fullScreen={true}
          PaperProps={{style: {height: '700px'}}}
        >
          <DialogTitle id="form-dialog-title">Person zur ausgewählten Abteilung hinzufügen</DialogTitle>
          <DialogContent>
            Bitte wählen Sie die Person aus. Ist die Person noch nicht im Adressbuch vorhanden, dann kann sie hier eingetragen werden.
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
              <InputLabel
                filled
                animated
                shrink
                margin="dense"
                variant="outlined"
                error={!this.state.selectedPerson}
              >
                Person
              </InputLabel>
              <PersonGlobalSelector
                people={this.state.people}
                selectedPerson={this.state.selectedPerson}
                // trialId={this.props.trialId}
                // departmentId={this.props.selectedDepartment.id}
                onCreateOption={this.handleNewPersonOpen}
                handleNewPersonOpen={this.handleNewPersonOpen}
                handleNewPersonClose={this.handleNewPersonClose}
                handleNewPersonSave={this.handleNewPersonSave}
                handleChange={this.handlePersonChange}
                inputValue={this.state.inputValue}
                handleInputValueChange={(newValue, actionMeta) => this.handleInputValueChange(newValue, actionMeta)}
                autoFocus
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Abbrechen
            </Button>
            <Button
              onClick={() => this.handleSave({
                id: this.state.selectedPerson.value,
              })} color="primary"
            >
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
export default withSnackbar(AddPersonToDepartmentDialog);
