import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { createTheme } from '@mui/styles';
// import blue from '@mui/material/colors/blue';
import Button from '@mui/material/Button';
import EcrfUserOverview from './EcrfUserOverview.jsx';
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability';
import { AbilityContext } from './Can.js';
import ability from './ability.js';
import theme from './theme.js';

function updateAbility(ability, abilities, user) {
  console.log(abilities, user);
  const { can, rules } = new AbilityBuilder(Ability);
  if (user && user.admin) {
    can('manage', 'all');
  } else {
    if (abilities && abilities.length > 0) {
      for (let i = 0; i < abilities.length; i++) {
        can(abilities[i].actions[0], abilities[i].subject[0]);
      }
    }
  }

  ability.update(rules);
}

import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';

export default function SnackbarWrappedEcrfUserOverview(props) {
  // console.log(user);
  let user = props.user;
  try {
    user = JSON.parse(props.user);
  } catch {
    console.log(props.user);
  }
  // console.log(props.user, user);
  updateAbility(ability, props.abilities, user);
  let cableUrl = null;
  if (window.location.hostname == "topas.nct-heidelberg.de") {
    cableUrl = "wss://" + window.location.hostname + "/cable";
  } else {
    cableUrl = "ws://" + window.location.hostname + ":" + window.location.port + "/cable";
  }
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        
      >
        <AbilityContext.Provider value={ability}>
          <ActionCableProvider url={cableUrl}>
            <EcrfUserOverview user={user} trials={props.trials}/>
          </ActionCableProvider>
        </AbilityContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
