import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import { IconButton, Menu, MenuItem } from "@mui/material";

export default function ContractFields(props) {
  // const classes = useStyles();
  const { contract, handleChange, handleChange2, readOnly, contractTypeList, statusList, hasExpiryDate, handleHasExpiryDateChange } = props;
  const selectedStatus = statusList.find(stat => contract.status == stat.value);
  const selectedContractType = contractTypeList.find(proc => contract.contract_type == proc.value);
  // console.log(contract);
  return (

        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"

            value={contract.name}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            error={!contract.name}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (contract.name != null) }}
            type="string"
          />
          { readOnly ?
          <TextField
            margin="dense"
            id="contract_type"
            label="Typ"
            value={contract.contract_type}
            fullWidth
            variant="outlined"
            style={{zIndex: 999}}
            onChange={handleChange}
            error={!contract.contract_type}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (contract.contract_type != null) }}
            type="string"
          />
          :
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}} error={!contract.contract_type}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Typ</InputLabel>
              <Select
                id="contract_type"
                name="contract_type"
                value={selectedContractType}
                options={contractTypeList}
                style={{width: "100%"}}
                onChange={handleChange2}
                error={!contract.contract_type}
              />
            </FormControl>
          }
          <TextField
            margin="dense"
            id="number_code"
            label="Nr/Code"

            value={contract.number_code}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!contract.number_code}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (contract.number_code != null) }}
            type="string"
          />
          { readOnly ?
          <TextField
            margin="dense"
            id="status"
            label="Status"
            value={contract.status}
            fullWidth
            variant="outlined"
            style={{zIndex: 998}}
            onChange={handleChange}
            error={!contract.status}
            InputProps={{
              readOnly: readOnly,
            }}
            InputLabelProps={{ shrink: (contract.status != null) }}
            type="string"
          />
          :
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 998}} error={!contract.status}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Status</InputLabel>
              <Select
                id="status"
                name="status"
                value={selectedStatus}
                options={statusList}
                style={{width: "100%"}}
                onChange={handleChange2}
                error={!contract.status}
              />
            </FormControl>
          }
          <FormGroup>
            <FormControlLabel
              style={{zIndex: 997}}
              control={
                <Checkbox
                  checked={hasExpiryDate}
                  onChange={handleHasExpiryDateChange}
                  disabled={(!(handleHasExpiryDateChange)) || readOnly}
                />
              }
              name="hasExpiryDate"
              label="Ablaufdatum existiert"
            />
          </FormGroup>
          { (hasExpiryDate) &&
          <TextField
            id="expiry_date"
            label="Ablaufdatum"
            margin="dense"
            variant="outlined"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{zIndex: 1}}
            value={contract.expiry_date}
            error={!contract.expiry_date}
          />
          }
        </div>
  );
}
