import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import TextField from './TextField.jsx';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const camelize = function camelize(str) {
  return str.replace(/\W+(.)/g, function(match, chr)
   {
        return chr.toUpperCase();
    });
}

export default function SitesMilestonesReport(props) {
  // const classes = useStyles();
  const { classes, sites } = props;
  const milestoneNames = sites.map(site => {
    return Object.entries(site.milestones);
  }).flat(1);
  let milestoneCounts = {};
  for(var i = 0; i < milestoneNames.length; ++i){
    if (milestoneNames[i][1] == "1") {
      milestoneCounts[milestoneNames[i][0]] = (milestoneCounts[milestoneNames[i][0]]) ? milestoneCounts[milestoneNames[i][0]] + 1 : 1;
    }
  }
  const milestoneEntries = Object.entries(milestoneCounts);
  const milestoneComponents = milestoneEntries.map(me => {
    return (
      <TextField
        id={camelize(me[0])}
        label={me[0]}
        margin="dense"
        variant="outlined"
        value={me[1]}
        fullWidth
        style={{zIndex: 1}}
        InputLabelProps={{ shrink: (me[0] != null) }}
        type="string"
      />
    )
  })
  return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          {milestoneComponents}
        </div>
  );
}
