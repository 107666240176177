import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { createTheme } from '@mui/styles';
import blue from '@mui/material/colors/blue';
import Login from './Login.jsx';
// import { AbilityContext } from './ability-context.jsx';
// import ability from './ability';
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import theme from './theme.js';

// const theme = createTheme({
//   palette: {
//     primary: {main: "#1B4E9C"},
//     secondary: {main: "#2C7AC8"},
//   },
// });

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}

export default function SnackbarWrappedLogin(props) {
  // let user = props.user;
  // try {
  //   user = JSON.parse(props.user);
  // } catch {
  //   console.log(props.user);
  // }
  // updateAbility(ability, props.abilities, user);
  let cableUrl = null;
  if (window.location.hostname == "topas.nct-heidelberg.de") {
    cableUrl = "wss://" + window.location.hostname + "/cable";
  } else {
    cableUrl = "ws://" + window.location.hostname + ":" + window.location.port + "/cable";
  }
  // const user = JSON.parse(props.user);
  console.log(theme);
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/* <AbilityContext.Provider value={ability}> */}
          <ActionCableProvider url={cableUrl}>
            <Login controller={props.controller} title={props.title} root_url={props.root_url} />
          </ActionCableProvider>
        {/* </AbilityContext.Provider> */}
      </SnackbarProvider>
    </ThemeProvider>
  );
};
