import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { createTheme } from '@mui/styles';
import blue from '@mui/material/colors/blue';
import MailLogin from './MailLogin.jsx';
// import { AbilityContext } from './ability-context.jsx';
// import ability from './ability';
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import theme from './theme.js';

// const theme = createTheme({
//   palette: {
//     primary: {main: "#1B4E9C"},
//     secondary: {main: "#2C7AC8"},
//   },
// });

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

export default function SnackbarWrappedMailLogin(props) {
  let cableUrl = null;
  if (window.location.hostname == "topas.nct-heidelberg.de") {
    cableUrl = "wss://" + window.location.hostname + "/cable";
  } else {
    cableUrl = "ws://" + window.location.hostname + ":" + window.location.port + "/cable";
  }
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ActionCableProvider url={cableUrl}>
          <MailLogin controller={props.controller} title={props.title} root_url={props.root_url} />
        </ActionCableProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
