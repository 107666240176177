import React, { Component } from 'react';
import { AbilityContext } from './ability-context.jsx';
// import { withStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withSnackbar } from 'notistack';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import Timeline from 'react-calendar-timeline';
// import TimelineMarkers from 'react-calendar-timeline';
// import TodayMarker from 'react-calendar-timeline';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import '!style-loader!css-loader!react-calendar-timeline/lib/Timeline.css';
// make sure you include the timeline stylesheet or the timeline will not be styled
// import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import NewCalendarEntryDialog from './NewCalendarEntryDialog.jsx';
import EditCalendarEntryDialog from './EditCalendarEntryDialog.jsx';
// import { data } from 'jquery';
// import { render } from 'node-sass';


const styles = {

  TableCheckbox: {
    padding: 0,
  },
  // overrides: {
    div: {
      holiday: {
        backgroundColor: "#fffc30",
      },
    },
  // },
  root: {

    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

function addWeekdays(date, days) {
  date = moment(date); // use a clone
  while (days > 0) {
    date = date.add(1, 'days');
    // decrease "days" only if it's a weekday.
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return date.toISOString().substr(0,10);
};

function numWeekdays(date1, date2, holidays = []) {
  let datea = moment(date1); // use a clone
  let dateb = moment(date2); // use a clone
  let whileEndPoint = moment(date2).subtract(1, "day");
  let ret = 0;
  while (datea.isBetween(date1, date2, "day", "[]")) {
    const holidaysInd = holidays.findIndex(holiday => datea.isBetween(holiday.start_time, holiday.end_time, "day", "[]"));
    if (datea.isoWeekday() !== 6 && datea.isoWeekday() !== 7 && holidaysInd == -1) {
      ret++;
    }
    datea = datea.add(1, 'days');
  }
  return ret;
};

function listComponents(result) {
  // const classes = useStyles();
  const yearComponents = Object.entries(result).map(res => {
    return (
      yearComponent(res)
    )
  })
  return (
    <TableContainer component={Paper} style={{margin: '10px'}} fullWidth={false}>
      <Table  size="small" aria-label="a dense table" padding="none">
        <TableHead>
          <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
            <b>Jahr</b>
          </TableCell>
          <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
            <b>Urlaub</b>
          </TableCell>
          <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
            <b>Urlaub geplant</b>
          </TableCell>
        </TableHead>
        <TableBody>
          {yearComponents}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function yearComponent(res) {
  // const classes = useStyles();
  const typeArray = ["vacation", "planned_vacation"];
  return (
    <TableRow key="name">
      <TableCell align="left" component="th" scope="row" style={{width: '200px'}}>
        <b>{res[0]}</b>
      </TableCell>
      <TableCell align="left">{res[1]["vacation"]}</TableCell>
      <TableCell align="left">{res[1]["planned_vacation"]}</TableCell>
    </TableRow>

  )
}

class CalendarTimeline extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(props.user);
    const mainRoleAssoc = (user.user_roles) ? user.user_roles.find(assoc => assoc.is_main_role) : {};
    const mainRole = (user && mainRoleAssoc) ? user.roles.find(role => role.id == (mainRoleAssoc.role_id)) : {};
    let openGroups = (mainRole) ? {[mainRole.id]: true} : {};
    this.state = {calendarEntries: props.calendar_entries, specialCalendarEntries: props.special_calendar_entries, roles: props.roles, openGroups: openGroups, newCalendarEntryOpen: false, editCalendarEntryOpen: false, selectedEntryId: null, user: user};
    // this.setState({trial: this.props.trial});
    this.form = React.createRef();
  }

  // componentDidUpdate() {
  //   console.log(this.props);
  //   let newOpenGroups = {...this.state.openGroups};
  //   newOpenGroups[this.state.user.role.id] = true;
  //   this.setState({
  //     openGroups: newOpenGroups
  //   });
  // }

  itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : item.selectedBgColor) : item.bgColor;
    const borderColor = itemContext.resizing ? "red" : item.color;
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: item.color,
            borderColor,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1
          },
          onMouseDown: () => {
            console.log("on item click", item);
          }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  verticalLineClassNamesForTime = (timeStart, timeEnd, special_entries) => {
    const currentTimeStart = moment(timeStart)
    const currentTimeEnd = moment(timeEnd)

    let classes = [];
    const specialArray = special_entries.map(special => [moment(special.start_time), moment(special.end_time), special.entry_type]);

    // check for public holidays
    for (let special of specialArray) {
      if (currentTimeStart.isBetween(special[0], special[1], "day", '[]')) {
        classes.push(special[2]);
      }
    }
    return classes;
  };

  toggleGroup = id => {
    let newOpenGroups = {...this.state.openGroups};
    newOpenGroups[id] = !this.state.openGroups[id];
    this.setState({
      openGroups: newOpenGroups
    });
  };

  handleNewCalendarEntryOpen = event => {
    this.setState({newCalendarEntryOpen: true});
  }

  handleNewCalendarEntryClose = event => {
    this.setState({newCalendarEntryOpen: false});
  }

  handleEditCalendarEntryOpen = (itemId, e, time) => {
    const mainRoleAssoc = (this.state.user.user_roles) ? this.state.user.user_roles.find(assoc => assoc.is_main_role) : {};
    const mainRole = (this.state.user && mainRoleAssoc) ? this.state.user.roles.find(role => role.id == (mainRoleAssoc.role_id)) : {};
    const userEntryIds = this.state.roles.find(r => r.id == mainRole.id).main_users.find(user => user.id == this.state.user.id).calendar_entries.map(ce => ce.id);
    if (userEntryIds.includes(itemId)) {
      this.setState({editCalendarEntryOpen: true, selectedEntryId: itemId});
    }
  }

  handleEditCalendarEntryClose = event => {
    this.setState({editCalendarEntryOpen: false});
  }

  handleRefresh = event => {
    let currentComponent = this;
    fetch('/calendars/get_roles', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({ roles: data.roles });
    });
  }

  handleCalendarEntrySelect = (itemId, e, time) => {
    const mainRoleAssoc = (this.state.user.user_roles) ? this.state.user.user_roles.find(assoc => assoc.is_main_role) : {};
    const mainRole = (this.state.user && mainRoleAssoc) ? this.state.user.roles.find(role => role.id == (mainRoleAssoc.role_id)) : {};
    const userEntryIds = (mainRole) ? this.state.roles.find(r => r.id == mainRole.id).main_users.find(user => user.id == this.state.user.id).calendar_entries.map(ce => ce.id) : [];
    if (userEntryIds.includes(itemId)) {
      this.setState({selectedEntryId: itemId});
    } else {
      this.setState({selectedEntryId: null});
    }
  }

  handleCalendarEntryDeselect = (e) => {
    this.setState({selectedEntryId: null})
  }

  handleDeleteCalendarEntry = event => {
    let currentComponent = this;
    fetch('/calendars/' + currentComponent.state.selectedEntryId + '/delete_calendar_entry', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleNewCalendarEntrySave = obj => {
    let currentComponent = this;
    fetch('/calendars/create_calendar_entry', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        calendar_entry: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleNewCalendarEntryClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditCalendarEntrySave = obj => {
    let currentComponent = this;
    fetch('/calendars/edit_calendar_entry', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        calendar_entry: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleEditCalendarEntryClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleCalendarEntriesReceived = message => {
    console.log("received Calendar Entry");
    console.log(message);
    if (message.new_calendar_entry) {
      let newRoles = [].concat(this.state.roles);
      this.state.roles.forEach((role,ind) => {
        const userInd = role.main_users.findIndex(user => user.id == message.user.id);
        if (userInd != -1) {
          newRoles[ind].main_users[userInd].calendar_entries.push(message.new_calendar_entry);
        }
      })
      this.setState({ roles: newRoles });
      this.props.enqueueSnackbar('Neuer Kalendereintrag wurde von Benutzer ' + message.user.name + ' erstellt.', {variant: 'success'});
    }
    if (message.new_special_calendar_entry) {
      let newSpecialCalendarEntries = [].concat(this.state.specialCalendarEntries);
      newSpecialCalendarEntries.push(message.new_special_calendar_entry);
      this.setState({ specialCalendarEntries: newSpecialCalendarEntries });
      this.props.enqueueSnackbar('Neuer Kalendereintrag wurde von Benutzer ' + message.user.name + ' erstellt.', {variant: 'success'});
    }
    if (message.calendar_entry) {
      let newRoles = [].concat(this.state.roles);
      this.state.roles.forEach((role,ind) => {
        const userInd = role.main_users.findIndex(user => user.id == message.user.id);
        if (userInd != -1) {
          const entryInd = role.main_users[userInd].calendar_entries.findIndex(ce => ce.id == message.calendar_entry.id);
          // console.log(ind);
          // console.log(newRoles[ind]);
          newRoles[ind].main_users[userInd].calendar_entries[entryInd] = message.calendar_entry;
        }
      })
      const sceInd = this.state.specialCalendarEntries.findIndex(ce => ce.id == message.calendar_entry.id);
      // console.log(sceInd);
      if (sceInd != -1) {
        let newSpecialCalendarEntries = [].concat(this.state.specialCalendarEntries);
        newSpecialCalendarEntries[sceInd] = message.calendar_entry;
        this.setState({specialCalendarEntries: newSpecialCalendarEntries});
      }
      this.setState({ roles: newRoles });
      this.props.enqueueSnackbar('Kalendereintrag wurde von Benutzer ' + message.user.name + ' editiert.', {variant: 'success'});
    }
    if (message.deleted_calendar_entry_id) {
      let newRoles = [].concat(this.state.roles);
      this.state.roles.forEach((role,ind) => {
        const userInd = role.main_users.findIndex(user => user.id == message.user.id);
        if (userInd != -1) {
          newRoles[ind].main_users[userInd].calendar_entries = newRoles[ind].main_users[userInd].calendar_entries.filter(ce => ce.id != message.deleted_calendar_entry_id);
        }
      })
      if (this.state.selectedEntryId == message.deleted_calendar_entry_id) {
        this.setState({selectedEntryId: null});
      }
      this.setState({ roles: newRoles });
      this.props.enqueueSnackbar('Kalendereintrag wurde von Benutzer ' + message.user.name + ' gelöscht.', {variant: 'success'});
    }
    if (message.user && message.user.id == this.state.user.id) {
      this.setState({user: message.user, calendarEntries: message.user.calendar_entries, specialCalendarEntries: message.user.special_calendar_entries});
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render () {
    // const { classes } = this.props;
    const { openGroups, roles, calendarEntries, specialCalendarEntries } = this.state;
    let groups = [];
    let items = [];
    let holidaysItems = [];
    let calendarEntriesFromRoles = [];
    let typeOptions = [];

    if (this.context.can('create', 'special_calendar_entry')) {
      typeOptions = [{value: "vacation", label: "Urlaub/Gleittag", color: "#e33434"}, {value: "absent", label: "Dienstlich/Anderweitig Abwesend", color: "#e33434"}, {value: "keep_free", label: "Termin frei halten", color: "#e33434"}, {value: "planned_vacation", label: "Planung Urlaub", color: "#e33434"}, {value: "holiday", label: "Feiertag", color: "#e33434"}, {value: "school_holiday", label: "Schulferien", color: "#fafca9"}];
    } else {
      typeOptions = [{value: "vacation", label: "Urlaub/Gleittag", color: "#e33434"}, {value: "absent", label: "Dienstlich/Anderweitig Abwesend", color: "#e33434"}, {value: "planned_vacation", label: "Planung Urlaub", color: "#e33434"}];
    }

    let typeList = typeOptions.map(to => to.value);
    // if canI
    typeList = typeList.filter(type => type != "holiday" && type != "keep free" && type != "school_holiday");

    let entryId = 0;
    const maxId = roles.reduce(
      (max, role) => (role.id > max ? role.id : max),
      roles[0].id
    );
    let i = maxId + 1;
    roles.forEach(group => {
      ++i;
      const currentRootId = i;
      const users = group.main_users;
      groups.push({id: group.id, title: group.name, root: true, parent: null})
      users.forEach(user => {
        const calendar_entries = user.calendar_entries;
        ++i;
        const currentUserId = i;
        const currentUser = user;
        groups.push({ id: currentUserId, title: currentUser.name, root: false, parent: group.id });
        calendar_entries.forEach(calendarEntry => {
          const id = calendarEntry.id;
          const ce = calendarEntry;
          const type = ce.entry_type;
          if (typeList.includes(type)){
            let color = "#ffffff";
            switch (type){
              case "vacation":
                color = "#e33434";
                break;
              case "absent":
                color = "#2b39fc";
                break;
              // case "keep_free":
              //   color = "#52de28";
              //   break;
              // case "holiday":
              //   color = "#fffc30";
              //   break;
              case "planned_vacation":
                color = "#ffc830";
                break;
            }
            items.push({id: id, group: currentUserId, title: ce.name, start: moment(ce.start_time), end: moment(ce.end_time), bgColor: color});
          } else if (type == "holiday") {
            holidaysItems.push({id: id, group: currentUserId, title: ce.name, start: moment(ce.start_time), end: moment(ce.end_time)})
          }
          calendarEntriesFromRoles.push(ce);
        })
      })
    })
    // const groups = [{id: 1, title: 'Leitung', rightTitle: 'LeitungRight', root: true, parent: null}, { id: 2, title: 'Andreas', rightTitle: 'AndreasRight', root: false, parent: 1 }, {id: 3, title: 'IT', rightTitle: 'ITRight', root: true, parent: null}, { id: 4, title: 'Sebastian', rightTitle: 'SebastianRight', root: false, parent: 3 }, {id: 5, title: 'PM', rightTitle: 'PMRight', root: true, parent: null}, { id: 6, title: 'Lucian', rightTitle: 'LucianRight', root: false, parent: 5 } ]
    const newGroups = groups
      .filter(g => g.root || openGroups[g.parent])
      .map(group => {
        return Object.assign({}, group, {
          title: group.root ? (
            <div onClick={() => this.toggleGroup(parseInt(group.id))} style={{ cursor: "pointer" }}>
              {openGroups[parseInt(group.id)] ? "[-]" : "[+]"} {group.title}
            </div>
          ) : (
            <div style={{ paddingLeft: 20 }}>{group.title}</div>
          )
        });
      });
    const selectedEntry = calendarEntriesFromRoles.find(ce => ce.id == this.state.selectedEntryId);

    var keys = {
      groupIdKey: "id",
      groupTitleKey: "title",
      groupRightTitleKey: "rightTitle",
      itemIdKey: "id",
      itemTitleKey: "title",
      itemDivTitleKey: "title",
      itemGroupKey: "group",
      itemTimeStartKey: "start",
      itemTimeEndKey: "end",
      groupLabelKey: "title"
    };

    const reportSums = {};
    const special_entries = (this.state.specialCalendarEntries) ?  this.state.specialCalendarEntries.filter(ce => (ce.entry_type == "holiday") || (ce.entry_type == "school_holiday") || (ce.entry_type == "keep_free")) : [];
    const holidays = (this.state.specialCalendarEntries) ? this.state.specialCalendarEntries.filter((ce => ce.entry_type == "holiday")) : [];
    this.state.calendarEntries.forEach(ce => {
      const st = moment(ce.start_time);
      const et = moment(ce.end_time);
      if (st.year() == et.year()){
        const year = st.year();
        const days = numWeekdays(st,et, (ce.entry_type != "holiday") ? holidays : []);
        const type = ce.entry_type;
        if (reportSums[year]) {
          if (reportSums[year][type]) {
            reportSums[year][type] = reportSums[year][type] + days;
          } else {
            reportSums[year][type] = days;
          }
        } else {
          reportSums[year] = {};
          reportSums[year][type] = days;
        }
      } else if (st.year() == (et.year() - 1)){
        const stYear = st.year();
        const etYear = et.year();
        const stEndOfYear = moment(st).endOf("year");
        const etStartOfYear = moment(et).startOf("year");
        const stDays = numWeekdays(st, stEndOfYear, (ce.entry_type != "holiday") ? holidays : []);
        const etDays = numWeekdays(etStartOfYear, et, (ce.entry_type != "holiday") ? holidays : []);
        const type = ce.entry_type;
        if (reportSums[stYear]) {
          if (reportSums[stYear][type]) {
            reportSums[stYear][type] = reportSums[stYear][type] + stDays;
          } else {
            reportSums[stYear][type] = stDays;
          }
        } else {
          reportSums[stYear] = {};
          reportSums[stYear][type] = stDays;
        }
        if (reportSums[etYear]) {
          if (reportSums[etYear][type]) {
            reportSums[etYear][type] = reportSums[etYear][type] + etDays;
          } else {
            reportSums[etYear][type] = etDays;
          }
        } else {
          reportSums[etYear] = {};
          reportSums[etYear][type] = etDays;
        }
      }
    });

    const sumReports = listComponents(reportSums);

    const today = moment();

    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <ActionCableConsumer
          channel={{channel: "CalendarEntriesChannel"}}
          onReceived={this.handleCalendarEntriesReceived}
          onConnected={this.handleConnected}
        />
        { (this.state.newCalendarEntryOpen) &&
        <NewCalendarEntryDialog
          open={this.state.newCalendarEntryOpen}
          handleClose={this.handleNewCalendarEntryClose}
          handleSave={this.handleNewCalendarEntrySave}
          typeOptions={typeOptions}
        />}
        { (this.state.editCalendarEntryOpen) &&
        <EditCalendarEntryDialog
          open={this.state.editCalendarEntryOpen}
          handleClose={this.handleEditCalendarEntryClose}
          handleSave={this.handleEditCalendarEntrySave}
          selectedEntry={selectedEntry}
          typeOptions={typeOptions}
        />}
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
            <Typography color="inherit" style={{fontWeight: '500', fontSize: '20px', marginLeft: 5}}>
              Abwesenheitsübersicht
            </Typography>
            <Typography style={{color: 'red', fontSize: '20px'}}>
              Hinweis: Verschieben des Zeitraums durch ziehen mit der Maus. Zoom durch halten von Strg und scrollen des Mausrads.
            </Typography>
            <div>
              <Tooltip title="Hinzufügen" >
                <IconButton aria-label="Add" onClick={this.handleNewCalendarEntryOpen}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              { (this.state.selectedEntryId) &&
              <Tooltip title="Löschen" >
                <IconButton aria-label="Add" onClick={this.handleDeleteCalendarEntry}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
              <Tooltip title="Refresh">
                <IconButton aria-label="Refresh" onClick={this.handleRefresh}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {/* <Timeline
            groups={newGroups}
            items={items}
            itemRenderer={this.itemRenderer}
            keys={keys}
            sidebarWidth={150}
            canMove={false}
            canResize={false}
            canSelect
            selected={[this.state.selectedEntryId]}
            onItemSelect={this.handleCalendarEntrySelect}
            onItemDeselect={this.handleCalendarEntryDeselect}
            onItemDoubleClick={this.handleEditCalendarEntryOpen}
            itemsSorted
            itemTouchSendsClick={false}
            stackItems
            itemHeightRatio={0.9}
            showCursorLine
            defaultTimeStart={moment().add(-1, 'month')}
            defaultTimeEnd={moment().add(+1, 'month')}
            verticalLineClassNamesForTime={(timeStart, timeEnd) => this.verticalLineClassNamesForTime(timeStart, timeEnd, special_entries)}
          >
            <TimelineMarkers>
              <TodayMarker />
            </TimelineMarkers>
          </Timeline> */}
        </Grid>
        <Grid item xs={12}>
          {sumReports}
        </Grid>
      </Grid>
    )
  }

}

CalendarTimeline.contextType = AbilityContext;
export default withSnackbar(CalendarTimeline)
