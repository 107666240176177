import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes, { string } from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';

// import MUIDataTable from "mui-datatables";

import { withSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import EnhancedTable from './EnhancedTable.jsx';
import TimeRegistrationDialog from './TimeRegistrationDialog.jsx';
import TimeRegistrationFields from './TimeRegistrationFields.jsx';
import Select from 'react-select';

import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import MomentUtils from "@date-io/moment";
import moment from 'moment'
import "moment/locale/de";
moment.locale('de');

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

const servicesColumns = [
  {
   id: "roleNames",
   label: "Rolle(n)",
   numeric: false,
   disablePadding: false,
   type: "stringList",
   filterable: true,
  },
  {
   id: "name",
   label: "Leistungsart",
   numeric: false,
   disablePadding: false,
   type: "string",
  },
  {
    id: "annotation",
    label: "Beschreibung",
    numeric: false,
    disablePadding: false,
    type: "string",
   },
 ];

class TimeRegistration extends React.Component {
  constructor(props) {
    super(props);
    const mainRoleAssoc = props.user.user_roles.find(assoc => assoc.is_main_role);
    const mainRole = props.user.roles.find(role => role.id == (mainRoleAssoc.role_id));
    // console.log(props.roles, mainRole);
    // console.log(props.time_registrations);
    const roleStandardServices = {"PM": "Koordination/Kommunikation", "BM": "Besprechung / Meeting", "DM": "CRF", "Testrolle": "Testservice", "IT": "Besprechung / Meeting", "Administrator": "Besprechung / Meeting", "CTA": "Besprechung / Meeting", "default": "Besprechung / Meeting"};
    const standardServiceForRole = props.services.find(service => service.name == roleStandardServices[mainRole.name]);
    this.state = { obj: {role_id: mainRole.id, service_id: standardServiceForRole.id, time_str: null, hours: null, decimalSelected: true, entry_date: moment().toISOString().substr(0,10)}, user: props.user, trial: (props.trial) ? props.trial : null, openDialogs: {}, time_str: null, trial: this.props.trial, timeRegistrationsFilters: [], servicesFilters: [], roleStandardServices: roleStandardServices, loading: true};
    this.form = React.createRef();
  }

  componentDidMount(props) {
    let currentComponent = this;
    if (this.state.trial) {
      fetch('/time_registration/' + this.state.trial.id + '/get_time_registrations', {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        currentComponent.setState({ time_registrations: data.time_registrations, loading: false });
        // currentComponent.setState({ trials: data.trials });
      });
    }
  }

  handleTimeRegistrationChange = (rowData, allRowsSelected, allObjectsSelected) => {
    // const trIds = allObjectsSelected.map(tr => tr.id);
    // const selectedInstitutions = this.state.institutions.filter(institution => institutionIds.includes(institution.id));
    this.setState({
      selectedTimeRegistrations: allObjectsSelected,
      selectedTimeRegistrationIds: allRowsSelected,
      selectedTimeRegistrationIndices: this.state.time_registrations.findIndex(object => object.id === allRowsSelected[0]),
    });
    // this.updateDepartments(allRowsSelected);
  };

  handleChange2 = (value, meta) => {
    const newVal = value.value;
    const name = meta.name;
    let newObj = {...this.state.obj};
    newObj[name] = newVal;
    if (name == "role_id") {
      const roleStandardServices = {"PM": "Koordination/Kommunikation", "BM": "Besprechungen/Meetings", "DM": "CRF", "Testrolle": "Testservice2"};
      const standardServiceForRole = this.props.services.find(service => service.name == roleStandardServices[this.state.user.roles.find(role => role.id == newVal).name]);
      // console.log(newVal, this.state.user.roles, standardServiceForRole);
      newObj["service_id"] = (standardServiceForRole) ? standardServiceForRole.id : null;
    }
    this.setState({obj: newObj});
  }

  renderHours = (input, decimalSelected) => {
    let ret = null;
    const value = input;
    const dot = /\d*\.\d{0,2}/;
    const comma = /\d*\,{1}\d{0,2}/;
    const colon = /\d*\:\d{0,2}/;
    const num = /\d+/;
    let newHour = null;
    const hh = value.substring(0,value.length-2);
    const mm = value.substring(value.length-2, value.length);
    if (value.match(num) && value.match(num)[0] == value) {
      if (value.match(num)) {
        let hh = "";
        let mm = "";
        if (value.length > 2) {
          hh = value.substring(0,value.length-2);
          mm = value.substring(value.length-2, value.length);
        } else {
          hh = "0";
          mm = value.padStart(2, "0");
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + (parseFloat(mm)/60.0);
        }
      }
    }
    if (value.match(dot) && value.match(dot)[0] == value) {
      if (value.match(dot)) {
        const dotIndex = value.indexOf(".");
        let hh = value.substring(0,dotIndex);
        if (!hh) {
          hh = "0";
        }
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!mm) {
          mm = "00";
        } else {
          mm = mm.padEnd("0",2);
        }
        // console.log(hh,mm);
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          // console.log(parseFloat(mm)/0.6);
          newHour = parseFloat(hh) +  ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
        // console.log(newHour);
      }
    }
    if (value.match(comma) && value.match(comma)[0] == value) {
      if (value.match(comma)) {
        const dotIndex = value.indexOf(",");
        let hh = value.substring(0,dotIndex);
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!hh) {
          hh = "0";
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
      }
    }
    if (value.match(colon) && value.match(colon)[0] == value) {
      if (value.match(colon)) {
        const dotIndex = value.indexOf(":");
        let hh = value.substring(0,dotIndex);
        let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
        if (!hh) {
          hh = "0";
        }
        if (decimalSelected) {
          newHour = parseFloat(hh + "." + mm);
        } else {
          newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
        }
      }
    }
    ret = newHour;
    return ret;
  }

  handleChangeTime = (event) => {
    // console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newObj = {...this.state.obj};
    // const any = /\d+\:\d{2}|\d*\,{1}\d+|\d+\.\d+|\d+/;
    // const dot = /\d*\.\d{0,2}/;
    // const comma = /\d*\,{1}\d{0,2}/;
    // const colon = /\d*\:\d{0,2}/;
    // const num = /\d+/;
    // // console.log(value.match(dot), value.match(comma), value.match(colon), value.match(num), value.match(any));
    // // console.log(value.match(re), value, value.match(re)[0] == value);
    // let newHour = null;
    // const hh = value.substring(0,value.length-2);
    // const mm = value.substring(value.length-2, value.length);
    // // console.log(value.match(dot));
    // console.log(value )
    if (name == "time_str") {
      newObj["time_str"] = value;
    //   if (value.match(num) && value.match(num)[0] == value) {
    //     if (value.match(num)) {
    //       let hh = "";
    //       let mm = "";
    //       if (value.length > 2) {
    //         hh = value.substring(0,value.length-2);
    //         mm = value.substring(value.length-2, value.length);
    //       } else {
    //         hh = "0";
    //         mm = value.padStart(2, "0");
    //       }
    //       if (this.state.obj.decimalSelected) {
    //         newHour = parseFloat(hh + "." + mm);
    //       } else {
    //         newHour = parseFloat(hh) + (parseFloat(mm)/60.0);
    //       }
    //     }
    //   }
    //   if (value.match(dot) && value.match(dot)[0] == value) {
    //     if (value.match(dot)) {
    //       const dotIndex = value.indexOf(".");
    //       let hh = value.substring(0,dotIndex);
    //       if (!hh) {
    //         hh = "0";
    //       }
    //       let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
    //       if (!mm) {
    //         mm = "00";
    //       } else {
    //         mm = mm.padEnd("0",2);
    //         console.log(mm);
    //       }
    //       console.log(hh,mm);
    //       if (this.state.obj.decimalSelected) {
    //         newHour = parseFloat(hh + "." + mm);
    //       } else {
    //         console.log(parseFloat(mm)/0.6);
    //         newHour = parseFloat(hh) +  ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
    //       }
    //       console.log(newHour);
    //     }
    //   }
    //   if (value.match(comma) && value.match(comma)[0] == value) {
    //     if (value.match(comma)) {
    //       const dotIndex = value.indexOf(",");
    //       let hh = value.substring(0,dotIndex);
    //       let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
    //       if (!hh) {
    //         hh = "0";
    //       }
    //       if (this.state.obj.decimalSelected) {
    //         newHour = parseFloat(hh + "." + mm);
    //       } else {
    //         newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
    //       }
    //     }
    //   }
    //   if (value.match(colon) && value.match(colon)[0] == value) {
    //     if (value.match(colon)) {
    //       const dotIndex = value.indexOf(":");
    //       let hh = value.substring(0,dotIndex);
    //       let mm = ((dotIndex < value.length) ? value.substring(dotIndex+1, value.length) : "00").padEnd(2,'0');
    //       if (!hh) {
    //         hh = "0";
    //       }
    //       if (this.state.obj.decimalSelected) {
    //         newHour = parseFloat(hh + "." + mm);
    //       } else {
    //         newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
    //       }
    //     }
    //   }
    }
    newObj["hours"] = this.renderHours(value, this.state.obj.decimalSelected);
    this.setState({ obj: newObj, time_str: value });
  }

  handleChangeDate = (date, name) => {
    // console.log(date, start_time);
    let newObj = {...this.state.obj};
    newObj["entry_date"] = moment(date).toISOString().substr(0,10);
    this.setState({obj: newObj});
  };

  handlePrevDay = event => {
    // console.log(date, start_time);
    let newObj = {...this.state.obj};
    newObj["entry_date"] = newObj.entry_date.startOf("day").subtract(1, 'day').add(2, 'hour');
    this.setState({obj: newObj});
  };

  handleNextDay = event => {
    // console.log(date, start_time);
    let newObj = {...this.state.obj};
    newObj["entry_date"] = newObj.entry_date.startOf("day").add(1, 'day').add(2, 'hour');
    this.setState({obj: newObj});
  };

  handleDecimalSelectedChange = (event) => {
    let newObj = {...this.state.obj};
    if (newObj["decimalSelected"]) {
      newObj["decimalSelected"] = false;
    } else {
      newObj["decimalSelected"] = true;
    }
    if (this.state.obj.time_str) {
      // const dot = /\d*\.\d{0,2}/;
      // const comma = /\d*\,{1}\d{0,2}/;
      // const colon = /\d*\:\d{0,2}/;
      // const num = /\d+/;
      // let newHour = null;
      // const hh = value.substring(0,value.length-2);
      // const mm = value.substring(value.length-2, value.length);
      // if (this.state.obj.time_str.length > 2) {
      //   hh = this.state.obj.time_str.substring(0,this.state.obj.time_str.length-2);
      //   mm = this.state.obj.time_str.substring(this.state.obj.time_str.length-2, this.state.obj.time_str.length);
      // } else {
      //   hh = "0";
      //   mm = this.state.obj.time_str.padStart(2, "0");
      // }
      // // const hh = this.state.obj.time_str.substring(0,this.state.obj.time_str.length-2);
      // // const mm = this.state.obj.time_str.substring(this.state.obj.time_str.length-2, this.state.obj.time_str.length);
      // if (!this.state.obj.decimalSelected) {
      //   newHour = parseFloat(hh + "." + mm);
      // } else {
      //   newHour = parseFloat(hh) + ((mm != "00") ? parseFloat(mm)/60.0 : parseFloat("0"));
      // }
      newObj["hours"] = this.renderHours(this.state.obj.time_str, newObj["decimalSelected"]);
    }
    this.setState({ obj: newObj });

    // if (this.state.obj.decimalSelected) {
    //   this.setState({decimalSelected: false});
    // } else {
    //   this.setState({decimalSelected: true});
    // }
  }

  handletimeRegistrationDoubleClick = (event, obj) => {
    let newOpenDialogs = [].concat(this.state.openDialogs);
    newOpenDialogs["editTimeRegistration"] = true;
    var allObjectsSelected = [obj];
    // console.log(allObjectsSelected);
    var selectedObjectIds = [obj.id];
    var selectedObjectIndices = this.state.time_registrations.findIndex(object => object.id === obj.id);
    this.setState({
      selectedTimeRegistrations: allObjectsSelected,
      selectedTimeRegistrationIds: selectedObjectIds,
      selectedTimeRegistrationIndices: selectedObjectIndices,
      openDialogs: newOpenDialogs,
    });
  }

  handleEditTimeRegistrationClose = (event, obj) => {
    let newOpenDialogs = [].concat(this.state.openDialogs);
    newOpenDialogs["editTimeRegistration"] = false;
    this.setState({
      openDialogs: newOpenDialogs,
    });
  }

  // handleEditTimeRegistrationSave = event => {
  //   let currentComponent = this;
  //   fetch('/time_registration/'+this.state.trial.id+'/update', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       time_registration_ids: this.state.selectedTimeRegistrationIds,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     if (data.response == 1) {
  //       currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
  //       this.handleEditTimeRegistrationClose();
  //     } else {
  //       currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  //   // this.updateSites
  // };

  handleSave = (event) => {
    // console.log(event);
    event.preventDefault();
    let currentComponent = this;
    // console.log(note);
    this.setState({ editNoteOpen: false});
    fetch('/time_registration/'+this.state.trial.id+'/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        service_id: this.state.obj.service_id,
        role_id: this.state.obj.role_id,
        hours: this.state.obj.hours,
        entry_date: moment(this.state.obj.entry_date).toISOString().substr(0,10),
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
        let newObj = {...currentComponent.state.obj};
        newObj["time_str"] = null;
        newObj["hours"] = null;
        currentComponent.setState({obj: newObj})
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditSave = (obj) => {
    let currentComponent = this;
    // console.log(obj);
    this.setState({ editNoteOpen: false});
    fetch('/time_registration/'+this.state.trial.id+'/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        service_id: obj.service_id,
        role_id: obj.role_id,
        hours: obj.hours,
        entry_date: moment(obj.entry_date).toISOString().substr(0,10),
        tr_id: obj.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich geändert.', {variant: 'success'});
        currentComponent.handleEditTimeRegistrationClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteTimeRegistrationClick = event => {
    let currentComponent = this;
    fetch('/time_registration/'+this.state.trial.id+'/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        time_registration_ids: this.state.selectedTimeRegistrationIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateSites
  };

  handleTimeRegistrationReceived = message => {
    console.log("received Time Registration");
    // console.log(message);
    if (message.trial_id == this.state.trial.id && message.new_time_registration) {
      let newTimeRegistrations = [].concat(this.state.time_registrations);
      newTimeRegistrations.push(message.new_time_registration);  
      this.setState({ time_registrations: newTimeRegistrations });
      this.props.enqueueSnackbar('Eine neue Zeit wurde erfasst.', {variant: 'success'});
    }
    if (message.trial_id == this.state.trial.id && message.time_registration) {
      let newTimeRegistrations = [].concat(this.state.time_registrations);
      const ind = newTimeRegistrations.findIndex(tr => tr.id == message.time_registration.id);  
      newTimeRegistrations[ind] = message.time_registration;
      this.setState({ time_registrations: newTimeRegistrations });
      this.props.enqueueSnackbar('Eine Zeit wurde geändert.', {variant: 'success'});
    }
    if (message.deleted_time_registration_ids) {
      let newTimeRegistrations = [].concat(this.state.time_registrations);
      newTimeRegistrations = newTimeRegistrations.filter(tr => !message.deleted_time_registration_ids.includes(tr.id))
      // newTimeRegistrtions = newTimeRegistrations.filter(tr => !message.deleted_triaL_ids.includes(tr.id));
      let newSelectedTimeRegistrations = [].concat(this.state.selectedTimeRegistrations);
      newSelectedTimeRegistrations = newSelectedTimeRegistrations.filter(str => !message.deleted_time_registration_ids.includes(str.id))
      const newSelectedTimeRegistrationIds = newSelectedTimeRegistrations.map(str => str.id);
      this.setState({time_registrations: newTimeRegistrations, selectedTimeRegistrations: newSelectedTimeRegistrations, selectedTimeRegistrationIds: newSelectedTimeRegistrationIds});
      this.props.enqueueSnackbar('Eine Zeit wurde gelöscht.', {variant: 'success'})
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  filterTimeRegistrations = trs => {
    let result = trs;
    if (this.state.timeRegistrationsFilters["roleName"]) {
      const roleNames = this.state.timeRegistrationsFilters["roleName"].map(roleName => roleName.value);
      result = result.filter(tr => roleNames.includes(tr.roleName));
    }
    if (this.state.timeRegistrationsFilters["serviceName"]) {
      const serviceNames = this.state.timeRegistrationsFilters["serviceName"].map(serviceName => serviceName.value);
      result = result.filter(tr => serviceNames.includes(tr.serviceName));
    }
    return result;
  }

  handleTimeRegistrationsFilterChange = (filters, meta) => {
    if ((meta.action == "select-option") || (meta.action == "remove-value")) {
      let newTimeRegistrationsFilters = {...this.state.timeRegistrationsFilters};
      newTimeRegistrationsFilters[meta["name"]] = filters;
      this.setState({timeRegistrationsFilters: newTimeRegistrationsFilters});
    } else if (meta.action == "clear") {

      let newTimeRegistrationsFilters = {...this.state.timeRegistrationsFilters};
      delete newTimeRegistrationsFilters[meta["name"]];
      this.setState({timeRegistrationsFilters: newTimeRegistrationsFilters});
    }
  }

  handleTimeRegistrationsFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({timeRegistrationsFilters: {}});
    }
  }

  filterServices = services => {
    let result = services;
    if (this.state.servicesFilters["roleNames"]) {
      const roleNames = this.state.servicesFilters["roleNames"].map(roleName => roleName.value);
      result = result.filter(service => roleNames.some(roleName => service.roleNames.split(",").includes(roleName)));
    }
    return result;
  }

  handleServicesFilterChange = (filters, meta) => {
    if ((meta.action == "select-option") || (meta.action == "remove-value")) {
      let newServicesFilters = {...this.state.servicesFilters};
      newServicesFilters[meta["name"]] = filters;
      this.setState({servicesFilters: newServicesFilters});
    } else if (meta.action == "clear") {
      let newServicesFilters = {...this.state.servicesFilters};
      delete newServicesFilters[meta["name"]];
      this.setState({servicesFilters: newServicesFilters});
    }
  }

  handleServicesFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({servicesFilters: {}});
    }
  }

  render() {
    const { classes, roles, services, trial_services, sras } = this.props;
    const { openDialogs, trial, obj, time_registrations, user, selectedTimeRegistrations, selectedTimeRegistrationIds } = this.state;

    const timeRegistrationColumns = [
      {
        id: "entry_date",
        // secondary: "Datum (s)",
        label: "Datum",
        numeric: false,
        disablePadding: false,
        type: "date",
      },
      {
        id: "roleName",
        label: "Rolle",
        numeric: false,
        disablePadding: false,
        type: "string",
        filterable: true,
      },
      {
        id: "serviceName",
        label: "Leistungsart",
        numeric: false,
        disablePadding: false,
        type: "string",
        filterable: true,
      },
      {
        id: "hours",
        label: "Zeit",
        numeric: false,
        disablePadding: false,
        type: (obj.decimalSelected) ? "decimaltime" : "time",
        precision: 2,
      },
      {
        id: "created_at",
        // secondary: "Datum (s)",
        label: "Erstellungsdatum",
        numeric: false,
        disablePadding: false,
        type: "hidden",
      },
    ];

    // console.log(this.props);
    const roleOptions = user.roles.map(role => {
      return {value: role.id, label: role.name}
    });
    // const sra_role_ids = sras.map(sra => sra.role_id);
    // const roleIds = roles.map(role => role.id);
    const srasByRole = (sras) ? sras.filter(sra => sra.role_id == obj.role_id) : null;
    // const srasByRoleRoleIds = srasByRole.map(sra => sra.role_id);
    // const srasByRoleServiceIds = 
    const availableServices = services.filter(ts => srasByRole ? srasByRole.find(sra => sra.service_id == ts.id) : null);
    // const allAvailableServices = trial_services.filter(ts => srasByRole.find(sra => sra.service_id == ts.id) );
    // console.log(trial_services, obj.role_id, sras, srasByRole, availableServices);
    const selectedRoleAvailableServices = trial_services.filter(ts => srasByRole.find(sra => sra.role_id == obj.role_id));
    // console.log(sras, srasByRole, trial_services, availableServices, selectedRoleAvailableServices);
    const serviceOptions = [{value: null, label: ""}].concat(availableServices.map(service => {
      return {value: service.id, label: service.name}
    }));
    const selectedServiceOption = serviceOptions.find(so => so.value == obj.service_id);
    const selectedRoleOption = roleOptions.find(ro => ro.value == obj.role_id);
    // console.log(selectedRoleOption, this.props.roles, time_registrations)

    const supplementedTimeRegistrations = (time_registrations && time_registrations.length) ? time_registrations.map(tr => {
      const serviceName = this.props.services.find(service => service.id == tr.service_id).name;
      const roleName = this.props.roles.find(role => role.id == tr.role_id).name;
      // const hoursString = "" + tr.hours;
      // const decIndex = hoursString.indexOf(".");
      // const hh = hoursString.substring(0,decIndex);
      // const mm = hoursString.substring(decIndex+1,hoursString.length);
      // const timeString = (hh + ":" + ((parseFloat(mm) / 100.0) * 60));
      // const time = timeString.substring(0, timeString.indexOf(":") + 3).replace(".",":");
      return {...tr, serviceName: serviceName, roleName: roleName }
    }) : [];
    const filteredTimeRegistrations = this.filterTimeRegistrations(supplementedTimeRegistrations);
    // console.log(filteredTimeRegistrations);
    // console.log(services);
    let supplementedServices = [];
    services.forEach((service, ind, arr) => {
      // console.log(ts.id, sras);
      // console.log(sras.find(sra => sra.service_id == ts.id).role_id, roles);
      // console.log(roles.find(role => role.id == sras.find(sra => sra.service_id == ts.id).role_id));
      // console.log(sra, ind, arr);
      // const service = services.find(service => service.id == sra.service_id);
      const roleNames = service.roles.map(role => role.name);
      supplementedServices.push({...service, roleNames: roleNames.sort().toString()});
    });
    const filteredServices = this.filterServices(supplementedServices);
    // console.log(filteredServices);

    // console.log(supplementedTimeRegistrations);
    // console.log(obj);

    return (
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "TimeRegistrationsChannel"}}
            onReceived={this.handleTimeRegistrationReceived}
            onConnected={this.handleConnected}
          />
          { (!trial) &&
          <Grid item xs={12} >
            <Typography style={{color: 'red', fontSize: '20px'}}>
              Hinweis: Es wurde noch keine Studie ausgewählt. Daher können noch keine Zeiten eingetragen werden.
            </Typography>
          </Grid>}
          <Grid item xs={12} >
            <Grid container justify="center">
              <form className={classes.container} onSubmit={this.handleSave} style={{width: '100%'}} >
                <Paper className={classes.root} fullWidth style={{borderStyle: "solid", borderWidth: "1px", width: '100%'}}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
                    <TimeRegistrationFields
                      obj={obj}
                      handleChange={this.handleChange}
                      handleChange2={this.handleChange2}
                      handleChangeTime={this.handleChangeTime}
                      handleChangeDate={this.handleChangeDate}
                      handlePrevDay={this.handlePrevDay}
                      handleNextDay={this.handleNextDay}
                      handleSave={this.handleSave}
                      handleDecimalSelectedChange={this.handleDecimalSelectedChange}
                      roleOptions={roleOptions}
                      serviceOptions={serviceOptions}
                      saveButton
                      // decimalSelected={decimalSelected}
                    />
                    
                  </Box>
                </Paper>
              </form>

            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop: '40px'}}>
            { (openDialogs.editTimeRegistration && (selectedTimeRegistrations.length == 1)) &&
            <TimeRegistrationDialog
              open={openDialogs.editTimeRegistration}
              obj={selectedTimeRegistrations[0]}
              roleOptions={roleOptions}
              serviceOptions={serviceOptions}
              trial={this.state.trial}
              handleEditSave={this.handleEditSave}
              // handleChangeDate={this.handleChangeDate}
              handleClose={this.handleEditTimeRegistrationClose}
            />}
            <EnhancedTable
              title={"Zeiterfassung"}
              data={filteredTimeRegistrations}
              columns={timeRegistrationColumns}
              handleFilterClick={this.handleTimeRegistrationsFilterClick}
              filters={this.state.timeRegistrationsFilters}
              onFilterChange={this.handleTimeRegistrationsFilterChange}
              singleSelect={true}
              selectedIds={(selectedTimeRegistrationIds) ? selectedTimeRegistrationIds : []}
              selectedObjects={[selectedTimeRegistrations]}
              onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleTimeRegistrationChange(rowData, allRowsSelected, allObjectsSelected)}
              // handleAddClick={this.handleNewProcessOpen}
              handleDeleteClick={this.handleDeleteTimeRegistrationClick}
              rowsPerPage={20}
              orderBy="created_at"
              order="desc"
              onDoubleClick={this.handletimeRegistrationDoubleClick}
              allData={supplementedTimeRegistrations}
              selectable
              expanded={true}
              objectType="TimeRegistration"
              noAddIcon={true}
              multipleSelectable
              loading={this.state.loading}
              autoFill
            />
          </Grid>
          <Grid item xs={12} >
            <EnhancedTable
              title={"Leistungsarten"}
              data={filteredServices}
              columns={servicesColumns}
              singleSelect={true}
              handleFilterClick={this.handleServicesFilterClick}
              filters={this.state.servicesFilters}
              onFilterChange={this.handleServicesFilterChange}
              // selectedIds={(selectedProcess) ? [selectedProcess.id] : []}
              // selectedObjects={[selectedProcess]}
              // onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleProcessChange(rowData, allRowsSelected, allObjectsSelected)}
              // handleAddClick={this.handleNewProcessOpen}
              // handleDeleteClick={this.handleDeleteProcessConfirmationDialogOpen}
              rowsPerPage={50}
              orderBy="roleName"
              order="asc"
              onDoubleClick={this.handleProcessDoubleClick}
              allData={supplementedServices}
              // selectable
              expanded={true}
              objectType="Service"
            />
          </Grid>
        </Grid>

    )
  }
}

TimeRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(TimeRegistration));
