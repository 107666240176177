import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientStadiumFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
            >
              {/* <TextField
                margin="dense"
                id="pseudonym"
                label="Pseudonym"
                value={obj.pseudonym}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              /> */}
              <TextField
                margin="dense"
                id="tumorid"
                label="Tumor-ID"
                value={obj.tumorid}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="date_stadium"
                label="Datum Status"
                value={moment(obj.date_stadium).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="c_t"
                label="cT"
                value={obj.c_t}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="c_n"
                label="cN"
                value={obj.c_n}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="c_m"
                label="cM"
                value={obj.c_m}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="uicc_stadium"
                label="UICC-Stadium"
                value={obj.uicc_stadium}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="r_complete"
                label="R gesamt"
                value={obj.r_complete}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="y"
                label="y"
                value={obj.y}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="r"
                label="r"
                value={obj.r}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
          </Grid>
        </Grid>
  );
}
