import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from './TextField.jsx';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

import EditIcon from '@mui/icons-material/Edit';

// import moment from 'moment';
// import "moment/locale/de";

// moment.locale("de")

import ProcessStepFields from './ProcessStepFields.jsx';
import TaskPaper from './TaskPaper.jsx';
// import EditTaskDialog from './EditTaskDialog.jsx';

export default function InfoProcessStepDialog(props) {
  const { obj, taskStatusList, selectableTaskStatusList, handleChange, handleChange2, handleEditStepClick } = props;
  const [editTaskOpen, setEditTaskOpen] = useState(false);
  const selectableStepStatusList = props.stepStatusList.filter(ss => (ss.value == "done" || ss.value == "active"));
  // const processStep = Object.assign({}, props.obj, {hasDeadline: props.obj.deadline_days != null})
  // console.log("infoProcessStepDialog")
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
      PaperProps={{style: {height: '90%'}}}
    >
      <DialogContent>
        <Grid container direction="row" justify="start" alignItems="start">
          <Grid item xs={6} >
            <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                <Typography variant="h6" id="Task" component="div" >
                  Prozessschritt {props.obj.title}
                </Typography>
                <Tooltip title="Editieren">
                  <IconButton aria-label="Edit" onClick={handleEditStepClick}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <ProcessStepFields
                processStep={obj}
                readOnly={true}
                stepStatusList={props.stepStatusList}
                taskStatusList={props.taskStatusList}
                hasDeadline={obj.deadline_days != null}
                statusEditable={props.statusEditable}
                selectableStepStatusList={selectableStepStatusList}
              />
            </Paper>
          </Grid>
          <Grid item xs={6} >
            <TaskPaper
              obj={obj.task}
              dueDateNotEditable
              handleChange={handleChange}
              handleChange2={handleChange2}
              readOnly={true}
              taskStatusList={taskStatusList}
              statusEditable={true}
              selectableTaskStatusList={selectableTaskStatusList}
              enqueueSnackbar={props.enqueueSnackbar}
            />
            {/* <Paper style={{borderStyle: "solid", borderWidth: "1px"}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center"> 
                <Typography variant="h6" id="Task" component="div" >
                  Task
                </Typography>
                
                <Tooltip title="Editieren">
                  <IconButton aria-label="Edit" onClick={() => setEditTaskOpen(true)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              
            </Paper> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Schließen
        </Button>
        {/* <Button
          disabled={!saveable}
          onClick={() => handleSave(props.obj)}
          color="primary"
        >
          Speichern
        </Button> */}
      </DialogActions>
    </Dialog>
  )
}

// export default InfoProcessStepDialog;
