import React from 'react';
// import { useState } from "react"; 
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can.js';
import moment from 'moment';

import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
//import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from './TextField.jsx';
import Card from "@mui/material/Card"; 
import Collapse from "@mui/material/Collapse"; 
import CardHeader from "@mui/material/CardHeader"; 
import Container from "@mui/material/Container"; 
import CardContent from "@mui/material/CardContent"; 
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"; 
import KeyboardArrowUpIcon from  "@mui/icons-material/KeyboardArrowUp"; 
import IconButton from "@mui/material/IconButton"; 
import CircularProgress from '@mui/material/CircularProgress';

// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';

import EcrfUserFields from './EcrfUserFields.jsx';
import EcrfApplicationFields from './EcrfApplicationFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import ViewDialog from './ViewDialog.jsx';
import SarcBobFileUploadDialog from './SarcBobFileUploadDialog.jsx';
import SearchBar from './SearchBar.jsx';
import ListOfFiles from './ListOfFiles.jsx';
import ListOfEcrfApplications from './ListOfEcrfApplications.jsx';
import { CheckBox, ImportContactsOutlined, Label, ThreeSixty, ViewInArOutlined } from '@mui/icons-material';
import EnhancedTable from './EnhancedTable.jsx';
import SarcPatientFields from './SarcPatientFields.jsx';
import SarcPatientDiagnosisFields from './SarcPatientDiagnosisFields.jsx';
import SarcPatientPTnmFields from './SarcPatientPTnmFields.jsx';
import SarcPatientStadiumFields from './SarcPatientStadiumFields.jsx';
import SarcPatientOperationFields from './SarcPatientOperationFields.jsx';
import SarcPatientSystemicTherapyFields from './SarcPatientSystemicTherapyFields.jsx';
import SarcPatientMiscellaneousTherapyFields from './SarcPatientMiscellaneousTherapyFields.jsx';
import SarcPatientRadiotherapyFields from './SarcPatientRadiotherapyFields.jsx';
import SarcPatientNuclearTherapyFields from './SarcPatientNuclearTherapyFields.jsx';
import SarcPatientProgressionFields from './SarcPatientProgressionFields.jsx';
import SarcPatientVitalStatusFields from './SarcPatientVitalStatusFields.jsx';
import SarcPatientSocioFields from './SarcPatientSocioFields.jsx';
// import SarcPatientPROFields from './SarcPatientPROFields.jsx';
import SarcPatientBloodSampleFields from './SarcPatientBloodSampleFields.jsx';
// import SarcPatientTissueSampleFields from './SarcPatientTissueSampleFields.jsx';
import Stack from '@mui/material/Stack';

import AuditTrailObjectFields from './AuditTrailObjectFields.jsx'
//import Chart from 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import { Chart as ReactChart } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js'; 
Chart.register(CategoryScale);
Chart.register(...registerables);

import Papa from "papaparse";
import DataEntryEditDialog from './DataEntryEditDialog.jsx';
import { border } from '@mui/system';
import Link from '@mui/material/Link';
// import moment from 'moment';

const auditTrailColumns = [
  {
   id: "item_type",
   label: "Item type",
   type: "link"
  },
  {
    id: "event",
    label: "Event",
    type: "string",
  },
  {
    id: "object",
    label: "Objekt",
    type: "object",
  },
  {
    id: "changeset",
    label: "Änderungen",
    type: "changeset",
  },
  {
    id: "created_at",
    label: "Änderungsdatum",
    type: "date",
  },
 ];
 

class AuditTrail extends React.Component {

  constructor(props) {
    super(props);
    let user = this.props.user;
    const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const user_id = urlParams.get('user_id');
    // const project = urlParams.get('project');
    // console.log(project);
    this.state = {
      user: user,
      audit_trails: this.props.audit_trails,
      openDialogs: false,
      current_value: ''
    };
    this.form = React.createRef();
  }
  
  componentDidMount(props) {
    
  }

  // handleClickAccept = (user_id, project, request) => {
  //   let currentComponent = this;
  //   // if(this.state.isChecked == true){
  //     fetch('/sarcbop/granting_access', {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'X-Transaction': 'POST Example',
  //         'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //       },
  //       body: JSON.stringify({
  //         project: project,
  //         user_id: user_id,
  //         request: request
  //       }),
  //       credentials: 'include',
  //     })
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       console.log(data);
  //       if (data.response == 1) {
  //         if(request == "accepted"){
  //           currentComponent.props.enqueueSnackbar('Der Antrag auf Zugang wird genehmigt.', {variant: 'success'});
  //         }
  //         else {
  //           currentComponent.props.enqueueSnackbar('Der Antrag auf Zugang wird abgelehnt.', {variant: 'success'});
  //         }
          
  //       } else {
  //         currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
  //       }
        
  //     })
  //   // }
  //   // else {
  //   //   currentComponent.props.enqueueSnackbar('Wenn Sie eine Zugangsberechtigung wünschen, Kreuzen Sie bitte das Kästchen an.', {variant: 'error'});
  //   // }
   
  // } 

  // handleClickRequest = (project_name) => {
  //   let currentComponent = this;
  
  //     fetch('/sarcbop/request_access_progress', {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'X-Transaction': 'POST Example',
  //         'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //       },
  //       body: JSON.stringify({
  //         project: project_name
  //       }),
  //       credentials: 'include',
  //     })
  //     .then(function(response){return response.json();})
  //     .then(function(data){
  //       if (data.response == 1) {
          
  //         currentComponent.props.enqueueSnackbar('Wir haben eine E-Mail an den Projektleiter geschickt. Ihre Zugangsberechtigung wird in Kürze erteilt werden.', {variant: 'success'});
  //       } else {
  //         // console.log(data.response);
  //         currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
  //       }
        
  //     })
    
  // }
  // handleRequestReceived = message => {
  //   console.log("Authorization requests received");
  //   console.log(message);
  //   if (message.requested_sarcbop_users) {
  //     this.setState({ requested_sarcbop_users: message.requested_sarcbop_users });
  //   }
  //   if (message.requested_heroes_aya_users) {
  //     this.setState({ requested_heroes_aya_users: message.requested_heroes_aya_users });
  //   }
  //   if (message.sarcbop_users) {
  //     this.setState({sarcbop_users: message.sarcbop_users });
  //   }
  //   if (message.heroes_aya_users) {
  //     this.setState({ heroes_aya_users: message.heroes_aya_users });
  //   }
  // }
  // handleConnected = event => {
  //   console.log("connected");
  // }
  handleDoubleClick = (event, obj) => {
    let newSelectedIds = {...this.state.selectedIds};
    newSelectedIds[name] = [obj.id]
    this.setState({selectedIds: newSelectedIds});
    this.handleOpen("view")
  }

  handleOpen = (name) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleClose = name => {
    
    this.setState({openDialogs: false});
  }
  handleAuditChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      // selectedPatients: allObjectsSelected,
      selectedObjectId: allRowsSelected[0],
    });
    // this.updatePeople(allRowsSelected);
  };
  handleObject = (event, obj) => {
    console.log(obj);
    let currentComponent = this;

    fetch('/sarcbop/sarc_object', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        version_id: obj.id
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      // const currentObject = props.data;
      if(data.current_object){
        currentComponent.setState({selectedObject: {current_object: data.current_object, object_before: data.object_before}, selectedObjectId: obj.id});
        // currentComponent.setState({object: data.object_before});
      }
      else {
        currentComponent.setState({currentObject: " "});
      }
      // currentComponent.setState({openDialogs: true});
      currentComponent.handleOpen("view")
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      // currentComponent.setState({diagnosisData: data.result.sarc_diagnoses});
  
      // let newOpenDialogs = {... currentComponent.state.openDialogs};
      // newOpenDialogs["viewDiagnosis"] = true;
      // currentComponent.setState({openDialogs: newOpenDialogs});
    });

  }
  handleGetData = (i) => {
    document.getElementById("current_object"+i).innerHTML = document.getElementById("current_object"+i).innerHTML
  }
  render(props) {

    // const selectedObject = (this.state.selectedObjectId) ? [this.state.audit_trails.filter(audit => this.state.selectedObjectId.includes(audit.id)), this.state.currentObject] : [];
    const selectedObject = (this.state.selectedObjectId) ? this.state.selectedObject : {};
   
    // const selectedObj = [selectedAuditTrail_before, this.state.currentObject];
    // console.log(selectedAuditTrail);
    return (
      <Grid item xs={12}>
        {/* {console.log(this.state.patients.filter(x => (x.pseudonym != null) ? x.pseudonym.toLowerCase().includes(this.state.searchTerm.toLowerCase()) : ""))} */}
        <EnhancedTable
          title={"Audit Trail"}
          data={this.state.audit_trails}
          selectedIds={[this.state.selectedObjectId]}
          selectedObjects={[selectedObject]}
          columns={auditTrailColumns}
          onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleAuditChange(rowData, allRowsSelected, allObjectsSelected)}
          rowsPerPage={50}
          onDoubleClick={(event, obj) => this.handleObject(event, obj)}
          orderBy="item_id"
          order="asc"
          // selectable
          autoFill
          expanded={true}
          // onDoubleClick={this.handleDoubleClick}
          // loading={this.state.loading}
          // objectType="SarcPatient"
          loading={this.state.loading}

        />
        { (this.state.openDialogs["view"] && this.state.selectedObject) &&
          <ViewDialog
            title="Objects"
            obj={selectedObject || []}
            currentObject={this.state.currentObject} 
            open={this.state.openDialogs}
            handleClose={() => this.handleClose("view")}
            Fields={AuditTrailObjectFields}
            // current_value={this.state.current_value}
            // options={objectOptions}
            // selectionBoxTitle="Tumor-ID"
            // selectionVariableName="tumorid"
            maxWidth="lg"
          />
        }
      </Grid> 
    )
  }
  
    
  
}

AuditTrail.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(AuditTrail));
