import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CreatableSelect from 'react-select/creatable';
import TrialBoardKlifoSelector from './TrialBoardKlifoSelector.jsx';

class AddTrialBoardKlifoDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {klifoIds: props.klifoIds};
    this.form = React.createRef();
  }

  handleTrialBoardKlifoChange = event => {
    let currentComponent = this;
    currentComponent.setState({ klifoIds: event });
  };

  render(props) {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={true}
        fullWidth
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">KliFoDis in diesem Trialboard</DialogTitle>
        <DialogContent>
          <TrialBoardKlifoSelector
            klifoIds={this.props.klifoIds}
            // handleNewDepartmentOpen={this.props.handleNewDepartmentOpen}
            handleChange={this.handleTrialBoardKlifoChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => this.props.handleSave({klifoIds: this.state.klifoIds})} color="primary">
              Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddTrialBoardKlifoDialog;
