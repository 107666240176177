import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';

// import SiteSelector from './SiteSelector.jsx'
// import DepartmentSelector from './DepartmentSelector.jsx'

class NewTrialBoardTrialDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { trial: {}, selectedTrialType: {}, selectedPhase: {} };
    this.form = React.createRef();
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newTrial = {...this.state.trial};
    newTrial[name] = value;
    currentComponent.setState({ trial: newTrial });
  };

  handleTrialTypeChange = (event) => {
    this.setState({selectedTrialType: event});
  }

  handlePhaseChange = (event) => {
    this.setState({selectedPhase: event});
  }

  render(props) {
    const trialTypeList = [{value: "IIT", label: "IIT"}, {value: "IIS", label: "IIS"}];
    const phaseList = [{value: "I", label: "I"}, {value: "I/II", label: "I/II"}, {value: "II", label: "II"}, {value: "IV", label: "IV"}, {value: "V", label: "V"}]
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Neue Sitzung des Studienboards anlegen</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="short"
            label="Akronym"
            onChange={this.handleChange}
            value={this.state.trial.short}
            fullWidth
            variant="outlined"
            disabled={this.state.locked}
            type="string"
          />
          <TextField
            margin="dense"
            id="title"
            label="Titel"
            onChange={this.handleChange}
            value={this.state.trial.title}
            fullWidth
            variant="outlined"
            disabled={this.state.locked}
            type="string"
          />
          <TextField
            margin="dense"
            id="sponsor_cro"
            label="Sponsor/CRO"
            onChange={this.handleChange}
            value={this.state.trial.sponsor_cro}
            fullWidth
            variant="outlined"
            disabled={this.state.locked}
            type="string"
          />
          <TextField
            margin="dense"
            id="indication"
            label="Indikation"
            onChange={this.handleChange}
            value={this.state.trial.indication}
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            disabled={this.state.locked}
            type="text"
          />
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Studientyp</InputLabel>
            <Select
              id="trial_type"
              value={ this.state.selectedTrialType }
              onChange={this.handleTrialTypeChange}
              options={trialTypeList}
              style={{width: "100%", zIndex: 999}}
              isDisabled={this.state.locked}
            />
          </FormControl>
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 998}}>
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Phase</InputLabel>
            <Select
              id="phase"
              value={ this.state.selectedPhase }
              onChange={this.handlePhaseChange}
              options={phaseList}
              style={{width: "100%"}}
              isDisabled={this.state.locked}
            />
          </FormControl>
          <TextField
            margin="dense"
            id="contact_name"
            label="Kontakt Name"
            onChange={this.handleChange}
            value={this.state.trial.contact_name}
            fullWidth
            variant="outlined"
            disabled={this.state.locked}
            type="string"
            style={{zIndex: 1}}
          />
          <TextField
            margin="dense"
            id="contact_email"
            label="Kontakt Email"
            onChange={this.handleChange}
            value={this.state.trial.contact_email}
            fullWidth
            variant="outlined"
            disabled={this.state.locked}
            type="email"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          onClick={(object) => this.props.handleSave({
            ...this.state.trial,
            trial_type: this.state.selectedTrialType.value,
            phase: this.state.selectedPhase.value,
            }
          )}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewTrialBoardTrialDialog;
