import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '../TextField';
import Button from '@mui/material/Button';

export default function ({ ...props }) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="text"
          multiline
          value={props.text}
          fullWidth
          variant="standard"
          style={{ zIndex: 1 }}
          InputProps={{
            readOnly: true
          }}
          type="string"
          size="small"/>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>
          Nein
        </Button>
        <Button
          onClick={() => props.handleConfirm(props.data)}
          color="primary">
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
}