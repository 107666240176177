import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Select from 'react-select';
// import {
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import { IconButton, Menu, MenuItem } from "@mui/material";
import MomentUtils from "@date-io/moment";
import moment from 'moment'
import "moment/locale/de";

moment.locale("de")

class NewCalendarEntryDialog extends React.Component {

  constructor(props) {
    super(props);
    const wholeDayEventList = ["vacation", "absent", "holiday", "planned_vacation", "school_holiday"];
    const staticTitleList = ["vacation", "absent", "planned_vacation"];
    this.state = { wholeDayEventList: wholeDayEventList, staticTitleList: staticTitleList, calendarEntry: {start_time: null, end_time: null, name: null, entry_type: null, whole_day_event: null } };
    this.form = React.createRef();
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newCalendarEntry = {...this.state.calendarEntry}
    newCalendarEntry[name] = value;
    currentComponent.setState({ calendarEntry: newCalendarEntry });
  };

  handleChangeDate = (date, name) => {
    // console.log(date, start_time);
    let newCalendarEntry = {...this.state.calendarEntry};
    // console.log(this.state.calendarEntry.entry_type);
    if (this.state.wholeDayEventList.includes(this.state.calendarEntry.entry_type)) {
      if (this.state.calendarEntry.entry_type == "holiday") {
        newCalendarEntry["start_time"] = moment(date).startOf("day").add(2, 'hour').toISOString().substr(0,10);
        newCalendarEntry["end_time"] = moment(date).endOf("day").subtract(1, 'seconds').toISOString().substr(0,10);
      } else {
        if (name == "start_time") {
          newCalendarEntry["start_time"] = moment(date).startOf("day").add(2, 'hour').toISOString().substr(0,10);
          if ((newCalendarEntry["end_time"] == null) || newCalendarEntry["end_time"].isBefore(newCalendarEntry["start_time"])) {
            newCalendarEntry["end_time"] = moment(date).endOf("day").subtract(1, 'seconds').toISOString().substr(0,10);
          }
        } else if (name == "end_time") {
          if (!date.isBefore(newCalendarEntry["start_time"])) {
            newCalendarEntry[name] = moment(date).endOf("day").subtract(1, 'seconds').toISOString().substr(0,10);
          } else {
            newCalendarEntry["start_time"] = moment(date).startOf("day").add(2, 'hour').toISOString().substr(0,10);
            newCalendarEntry[name] = moment(date).endOf("day").subtract(1, 'seconds').toISOString().substr(0,10);
          }
        }
      }
    } else {
      newCalendarEntry[name] = date;
      if (name == "start_time" && ((this.state.calendarEntry.end_time == null) || (moment(this.state.calendarEntry.end_time).isBefore(date)))) {
        newCalendarEntry["end_time"] = date;
      }
      if (name == "end_time" && ((this.state.calendarEntry.start_time == null) || (moment(this.state.calendarEntry.start_time).isAfter(date)))) {
        newCalendarEntry["start_time"] = date;
      }
    }
    this.setState({calendarEntry: newCalendarEntry});
  };

  handleCheckboxChange = (event, checked) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let obj = this.state.calendarEntry;
    obj[name] = checked;
    this.setState({calendarEntry: obj});
  }

  handleSelectChange = (event, meta) => {
    // console.log(event);
    let newCalendarEntry = {...this.state.calendarEntry};
    newCalendarEntry[meta.name] = event.value;
    // const staticTitleList = ["vacation", "absent", "planned_vacation"]
    // const wholeDayEventList = ["holiday", "school_holiday", "vacation", "absent", "planned_vacation"]
    if (this.state.wholeDayEventList.includes(event.value)){
      newCalendarEntry.whole_day_event = true;
    }
    if (this.state.staticTitleList.includes(event.value)){
      newCalendarEntry.name = event.label;
      this.setState({titleDisabled: true})
    } else {
      if (this.state.staticTitleList.includes(this.state.calendarEntry.entry_type)) {
        newCalendarEntry.name = "";
      }
      this.setState({titleDisabled: false})
    }
    // console.log(newCalendarEntry);
    this.setState({calendarEntry: newCalendarEntry});
  }

  handleSave = (state) => {
    let start_time = moment(this.state.calendarEntry.start_time).toJSON();
    let end_time = moment(this.state.calendarEntry.end_time).toJSON();
    this.props.handleSave(Object.assign({}, this.state.calendarEntry, {start_time: start_time, end_time: end_time, whole_day_event: this.state.calendarEntry.whole_day_event}));
    // this.setState({last_name: null, first_name: null, title: null, phone_number: null, mobile_number: null, fax_number: null, email: null, function: null});
  }

  render(props) {
    const { typeOptions } = this.props;
    const saveable = this.state.calendarEntry.start_time && this.state.calendarEntry.end_time && this.state.calendarEntry.entry_type && this.state.calendarEntry.name;
    const selectedType = typeOptions.find(to => to.value == this.state.calendarEntry.entry_type);
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">Neuen Kalendereintrag anlegen</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Typ</InputLabel>
              <Select
                autoFocus
                id="entry_type"
                name="entry_type"
                value={ selectedType }
                onChange={this.handleSelectChange}
                options={typeOptions}
                style={{width: "100%"}}
                error={!this.state.calendarEntry.entry_type}
              />
            </FormControl>
            { (!this.state.staticTitleList.includes(this.state.calendarEntry.entry_type)) &&
            <TextField
              margin="dense"
              id="name"
              label="Titel"
              onChange={this.handleChange}
              fullWidth
              variant="outlined"
              value={this.state.calendarEntry.name}
              disabled={this.state.titleDisabled}
              InputLabelProps={{
                shrink: (this.state.calendarEntry.name != null && this.state.calendarEntry.name != ""),
              }}
              error={((!this.state.calendarEntry.name) && this.state.calendarEntry.name != "")}
              type="string"
            />}
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              style={{zIndex: 997}}
              control={
                <Checkbox
                  checked={this.state.calendarEntry.whole_day_event}
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.wholeDayEventList.includes(this.state.calendarEntry.entry_type)}
                />
              }
              name="whole_day_event"
              label="Ganztägig"
            />
          </FormGroup>
          {/* <TextField
            margin="dense"
            id="start_time"
            label="Beginn"
            type="date"
            defaultValue={this.state.calendarEntry.start_time}
            fullWidth
            variant="outlined"
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={!this.state.calendarEntry.start_time}
            disabled={this.state.calendarEntry.locked}
          /> */}
          <FormGroup row>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                id="start_date"
                margin="dense"
                disableToolbar
                variant="outlined"
                label="Datum Beginn"
                value={this.state.calendarEntry.start_time}
                onChange={(date) => this.handleChangeDate(date, "start_time")}
                format="DD.MM.yyyy"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              { (!this.state.calendarEntry.whole_day_event) &&
              <KeyboardTimePicker
                margin="dense"
                id="start_time"
                label="Time picker"
                value={this.state.calendarEntry.start_time}
                onChange={(date) => this.handleChangeDate(date, "start_time")}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                format="HH:mm"
              />}
            </MuiPickersUtilsProvider>
          </FormGroup>
          { (!["holiday"].includes(this.state.calendarEntry.entry_type)) &&
          <FormGroup row>
            {/* <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                id="end_date"
                margin="dense"
                disableToolbar
                variant="outlined"
                label="Datum Ende"
                value={this.state.calendarEntry.end_time}
                onChange={(date) => this.handleChangeDate(date, "end_time")}
                format="DD.MM.yyyy"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              { (!this.state.calendarEntry.whole_day_event) &&
              <KeyboardTimePicker
                margin="dense"
                id="end_time"
                label="Time picker"
                value={this.state.calendarEntry.end_time}
                onChange={(date) => this.handleChangeDate(date, "end_time")}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                format="HH:mm"
              />}
            </MuiPickersUtilsProvider> */}
            {/* <TextField
              margin="dense"
              id="end_time"
              label="Ende"
              type="date"
              defaultValue={this.state.calendarEntry.end_time}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!this.state.calendarEntry.end_time}
              disabled={this.state.calendarEntry.locked}
            /> */}
            <TextField
              id="end_date"
              label="Datum Ende"
              margin="dense"
              variant="outlined"
              type="date"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{zIndex: 1}}
              value={this.state.calendarEntry.end_date}
              error={!this.state.calendarEntry.end_date}
            />
          </FormGroup>}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.handleSave(this.state.calendarEntry)}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewCalendarEntryDialog;
