import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';

import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function CapasFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly, selectOptions } = props;
  console.log(obj, readOnly, selectOptions["person_status"]);
  // const personStatusOptions = [{value: "in preparation", label: "in Vorbereitung", tooltip: "Antragsteller bereitet Antrag/Daten vor"}, {value: "checking", label: "zu prüfen durch DM", tooltip: "Datensatz muss von DM geprüft werden"}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltip: "Antragsteller muss Antrag überarbeiten"}, {value: "all complete", label: "Alles vollständig", tooltip: "Alle Angaben und Nachweise der Person sind vollständig"}, {value: "misc", label: "Sonstiges", tooltip: "Bitte wenden Sie sich an das DM für Fragen."}];

  return (
        <Grid container direction="row" justify="center" alignItems="top">
          <Grid item xs={6}>
          <Box
            component="form"
            // sx={{
            //   '& .MuiTextField-root': { m: 1, width: '25ch' },
            // }}
            noValidate
            // autoComplete="off"
          >
            <div>
            <>
              {/* <TextField
                autoFocus
                margin="dense"
                id="capaid"
                label="CAPAID"
                value={obj.capaid}
                // fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("capaid")}
                // error={!obj.capaid}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                // size="small"
              /> */}
                <TextField
                margin="dense"
                id="description"
                label="Beschreibung"
                value={obj.description}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("description")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="text"
                size="text"
              />
                  <TextField
                margin="dense"
                id="severity"
                label="Schweregrad"
                value={obj.severity}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("severity")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
            </>
            </div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            
            { (!props.reduced) &&
            <>
              <TextField
                margin="dense"
                id="resolution_date"
                label="Auflösung_Datum"
                value={obj.resolution_date}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("resolution_date")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="date"
                size="small"
              />
              { (props.admin) &&
              <TextField
                margin="dense"
                id="person_status"
                label="Status"
                value={obj.person_status}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("person_status")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="select"
                options={selectOptions["person_status"]}
                size="small"
                disabled={!props.admin}
              />}
            </>}
          </Grid>
          { (obj.person_status == "all complete" || obj.person_status == "misc") &&
            <Grid item xs={12}>
              <Typography style={{color: 'red', fontSize: '20px'}}>
                Hinweis: Daten können nur noch durch das Datenmanagement geändert werden.
              </Typography>
            </Grid>
          }
          { (obj.person_status != "all complete" && obj.person_status != "misc" && !props.admin) &&
            <Grid item xs={12}>
              <Typography style={{color: 'green', fontSize: '20px'}}>
                Danke, dass Sie diesen Vorfall gemeldet haben!
              </Typography>
            </Grid>
          }
        </Grid>
        
  );
}
