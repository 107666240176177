import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import Save from '@mui/icons-material/Save';
import AddIcon from "@mui/icons-material/Add";
import LockOpen from '@mui/icons-material/LockOpen';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

const styles = theme => ({

});

class ShowSodaTrial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {trial: props.trial, openDialogs: {fileUpload: false}, locked: true};
    // selectedTrialType: {value: props.trial.trial_type, label: props.trial.trial_type}, selectedPhase: {value: props.trial.phase, label: props.trial.phase},
    this.form = React.createRef();
  }

  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = event => {
    let currentComponent = this;
    // console.log(event.target.checked);
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let newTrial = {...this.state.trial}
    newTrial[name] = value;
    currentComponent.setState({ trial: newTrial });
  };

  handleChange2 = (event, checked) => {
    let currentComponent = this;
    let newTrial = {...this.state.trial}
    newTrial[event.target.name] = checked;
    currentComponent.setState({ trial: newTrial });
  }

  handleTrialTypeChange = (event) => {
    this.setState({selectedTrialType: event});
  }

  handlePhaseChange = (event) => {
    this.setState({selectedPhase: event});
  }

  handlePotentialPisChange = (event) => {
    const newPotentialPisIds = event.map(person => {
      return (
        person.value
      )
    });
    this.setState({selectedPotentialPis: newPotentialPisIds});
  }

  componentDidMount = (props) => {
    let currentComponent = this;
    if (this.props.trial.trial_type) {
      currentComponent.setState({selectedTrialType: {value: this.props.trial.trial_type, label: this.props.trial.trial_type}, selectedPhase: {value: this.props.trial.phase, label: this.props.trial.phase}});
    }
  }

  handleLockChange = event => {
    if (this.state.locked == false) {
      this.setState({locked: true})
    } else {
      this.setState({locked: false})
    }
  }

  handleNewSubmissionSave = event => {
    const currentComponent = this;
    fetch('/trial_board_submissions/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...this.state.trial,
        trial_type: this.state.selectedTrialType.value,
        phase: this.state.selectedPhase.value,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        const newTrial = data.trial;
        currentComponent.setState({ trial: newTrial, locked: true });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        // currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleSave = event => {
    const currentComponent = this;
    fetch('/trial_board_submissions/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ...this.state.trial,
        trial_type: this.state.selectedTrialType.value,
        phase: this.state.selectedPhase.value,
        people_ids: this.state.selectedPotentialPis,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        const newTrial = data.trial;
        currentComponent.setState({ trial: newTrial, locked: true });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        // currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleFileUploadOpen = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["fileUpload"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleFileUploadClose = event => {
    let newOpenDialogs = {...this.state.openDialogs};
    newOpenDialogs["fileUpload"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleFileUpload = (trial, event) => {
    const newTrial = trial;
    this.setState({trial: newTrial});
    this.handleFileUploadClose();
  }

  handleFileDelete = file => {
    const currentComponent = this;
    fetch('/trial_board_submissions/' + this.state.trial.id + '/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        var newTrial = data.trial;
        currentComponent.setState({ trial: newTrial });
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleTrialBoardSubmissionReceived = message => {
    console.log("received Trial Board");
    console.log(message);
    if (message.trial && message.trial.id == this.state.trial.id) {
      let trial = message.trial;
      this.setState({ trial: trial });
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.trial.short, {variant: 'success'});
    }
    if (message.deleted_trials && message.deleted_trials.includes(this.state.trial.id)) {
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' gelöscht: ' + this.state.trial.short, {variant: 'success'});
      window.location.assign('/trial_boards/index_trials');
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    const { classes, statuses } = this.props;
    const { trial } = this.state;
    const iitList = [{value: true, label: "IIT"}, {value: false, label: "IIS"}];
    const trialTypeList = [{value: 0, label: "Lokal"}, {value: 1, label: "Multizentrisch"}, {value: 9, label: "k.A."}];
    const trialStatusList = [ {value: 0, label: "in Beratung"}, {value: 1, label: "in Planung"}, {value: 2, label: "offen"}, {value: 3, label: "abgeschlossen"}, {value: 4, label: "abgebrochen"}, {value: 5, label: "unterbrochen"}, {value: 6, label: "Rekrutierung beendet"}, {value: 7, label: "Zur Archivierung freigegeben"}, {value: 8, label: "Archiviert"} ];
    // const trialTypeList = [ {value: "AMG", label: "AMG"}, {value: "AWB", label: "AWB"}, {value: "MPG", label: "MPG"}, {value: "Register", label: "Register"}, {value: "epidemiologisch", label: "epidemiologisch"}, {value: "sonstige", label: "sonstige"} ];
    const trialType = trialTypeList.find(tt => tt.value == trial.trial_type);
    const iit = iitList.find(ii => ii.value == trial.iit_trial);
    const status = trialStatusList.find(ts => ts.value == trial.status_id);
    return (
      <>
      <Grid container >
        <Grid item xs={4} style={{paddingTop: '0px'}}>
          <Grid container justify="center">
            <TextField
              margin="dense"
              id="name"
              label="Akronym"
              onChange={this.handleChange}
              value={trial.name}
              fullWidth
              variant="outlined"
              disabled={true}
              type="string"
            />
            <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Status</InputLabel>
              <Select
                id="status_id"
                value={ status }
                onChange={this.handleTrialStatusChange}
                options={trialStatusList}
                style={{width: "100%"}}
                isDisabled={true}
              />
            </FormControl>
            <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >Studientyp</InputLabel>
              <Select
                id="trial_type"
                value={ trialType }
                onChange={this.handleTrialTypeChange}
                options={trialTypeList}
                style={{width: "100%"}}
                isDisabled={true}
              />
            </FormControl>
            <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel filled animated shrink margin="dense" variant="outlined" >IIT/IST</InputLabel>
              <Select
                id="iit_trial"
                value={ iit }
                onChange={this.handleIitChange}
                options={iitList}
                style={{width: "100%"}}
                isDisabled={true}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{paddingTop: '0px'}}>
          <Grid container justify="center">
            <TextField
              margin="dense"
              id="num_patients_planned_all"
              label="Patienten Geplant"
              onChange={this.handleChange}
              value={trial.num_patients_planned_all}
              fullWidth
              variant="outlined"
              disabled={true}
              type="number"
            />
            <TextField
              margin="dense"
              id="num_patients_planned_hd"
              label="Patienten Geplant HD"
              onChange={this.handleChange}
              value={trial.num_patients_planned_hd}
              fullWidth
              variant="outlined"
              disabled={true}
              type="number"
            />
            <TextField
              margin="dense"
              id="num_patients_included"
              label="Patienten Eingeschlossen"
              onChange={this.handleChange}
              value={trial.num_patients_included}
              fullWidth
              variant="outlined"
              disabled={true}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid item xs={4} style={{paddingTop: '0px'}}>
          <Grid container justify="center">
            <TextField
              margin="dense"
              id="recording_date"
              label="Erfassungsdatum"
              type="date"
              defaultValue={trial.recording_date}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
            <TextField
              margin="dense"
              id="planned_init_date"
              label="Geplantes Initiierungsdatum"
              type="date"
              defaultValue={trial.planned_init_date}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
            <TextField
              margin="dense"
              id="ethics_commission_submission_date"
              label="Einreichung Ethikkommission"
              type="date"
              defaultValue={trial.ethics_commission_submission_date}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
            <TextField
              margin="dense"
              id="Ethikkommission Votum"
              label="Votum Ethikkommission"
              type="date"
              defaultValue={trial.ethics_commission_vote_date}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
            <TextField
              margin="dense"
              id="bob_submission_date"
              label="BOB Einreichung"
              type="date"
              defaultValue={trial.bob_submission_date}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
            <TextField
              margin="dense"
              id="bob_approval_date"
              label="BOB Genehmigung"
              type="date"
              defaultValue={trial.bob_approval_date}
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>
      </>
    )
  }
}

ShowSodaTrial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme(withSnackbar(ShowSodaTrial)));
