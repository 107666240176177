import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientVitalStatusFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            {/* <TextField
              margin="dense"
              id="pseudonym"
              label="Pseudonym"
              value={obj[0].pseudonym}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            /> */}
            <TextField
              margin="dense"
              id="date_latest_information"
              label="Datum letzte Info"
              value={obj[0].date_latest_information}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="date"
              size="small"
            />
            <TextField
              margin="dense"
              id="source_last_information"
              label="Quelle letzte Info"
              value={obj[0].source_last_information}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="vital_status"
              label="Vitalstatus"
              value={obj[0].vital_status}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="category_tumor_induced_death"
              label="Kategorie Tod tumorbedingt"
              value={obj[0].category_tumor_induced_death}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="date_of_death"
              label="Todesdatum"
              value={obj[0].date_of_death}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="date"
              size="small"
            />
            <TextField
              margin="dense"
              id="comment"
              label="Bemerkung"
              value={obj[0].comment}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
          </Grid>
        </Grid>
  );
}
