import React from 'react';
import TextField from './TextField.jsx';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import { FormGroup } from '@mui/material';
import Box from '@mui/material/Box';

export default function TrialCustomData(props) {
  const customFields = props.trial.custom_string_fields ? props.trial.custom_string_fields.map(csf => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
        <TextField
          margin="dense"
          id={csf.id}
          label={csf.label}
          value={csf.text}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={props.handleChange}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{ shrink: (csf.text != null) }}
          type="string"
          size="small"
        />
        { (!props.readOnly) &&
        <>
        <Tooltip title="Editieren" >
          <IconButton aria-label="Edit" onClick={() => props.handleEditOpen(csf)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Löschen" >
          <IconButton aria-label="Add" onClick={() => props.handleRemove(csf)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        </>}
      </Box>
    )
  }) : null;
  return (

        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <>
            {customFields}
          </>
        </div>
  );
}
