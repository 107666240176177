import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import TrialSelect from './TrialSelect.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function EcrfApplicationStatusFields(props) {
  const { obj, handleChange, handleSelectChange, readOnly, hiddenFields } = props;
  // const rolesOptions = [{value: "TestRolle1", label: "TestRolle1"}, {value: "TestRolle2", label: "TestRolle2"}, {value: "TestRolle3", label: "TestRolle3"}, {value: "TestRolle4", label: "TestRolle4"}]
  // const functionsOptions = [{value: "Prüfstelle", label: "Prüfstelle"}, {value: "TestFunction2", label: "TestFunction2"}, {value: "TestFunction3", label: "TestFunction3"}, {value: "TestFunction4", label: "TestFunction4"}]
  // const sitesOptions = [{value: "TestSite1", label: "TestSite1"}, {value: "TestSite2", label: "TestSite2"}, {value: "TestSite3", label: "TestSite3"}, {value: "TestSite4", label: "TestSite4"}]
  // const statusOptions = [{value: "applied", label: "Eingereicht", stepnr: 2}, {value: "granted", label: "Genehmigt", stepnr: 3}, {value: "denied", label: "Abgelehnt", stepnr: 3}, {value: "waiting", label: "Warten auf Antragsupload", stepnr: 1}]
  // const selectOptions = { role: rolesOptions, site: sitesOptions, function: functionsOptions, status: statusOptions}
  console.log(obj);
  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="ecrf_trial_configuration_id"
              label="Studie"
              value={obj.ecrf_trial_configuration_id}
              fullWidth
              handleChange={handleChangeTrial}
              // required={props.requiredFields.includes("short")}
              InputProps={{
                readOnly: readOnly,
              }}
              type="select"
              options={selectOptions["ecrf_trial_configuration_id"]}
              size="small"
            />
            <TextField
              margin="dense"
              id="ecrf_trial_function_id"
              label="Funktion in Studie"
              value={obj.ecrf_trial_function_id}
              fullWidth
              handleChange={handleChangeFunction}
              // required={props.requiredFields.includes("short")}
              InputProps={{
                readOnly: readOnly,
              }}
              type="select"
              options={functionsOptions}
              size="small"
            />
            <TextField
              margin="dense"
              id="site"
              label="Prüfstelle"
              value={obj.site}
              fullWidth
              variant="outlined"
              style={{zIndex: 988}}
              onChange={handleSelectChange}
              // required={props.requiredFields.includes("short")}
              InputProps={{
                readOnly: readOnly,
              }}
              type="select"
              options={props.selectOptions["site"]}
              size="small"
              disabled={obj.function != "Prüfstelle"}
            />
            <TextField
              margin="dense"
              id="role"
              label="Rolle in Studie"
              value={obj.role}
              fullWidth
              variant="outlined"
              style={{zIndex: 988}}
              onChange={handleSelectChange}
              // required={props.requiredFields.includes("short")}
              InputProps={{
                readOnly: readOnly,
              }}
              type="select"
              options={props.selectOptions["role"]}
              size="small"
            />
            <TextField
              margin="dense"
              id="date_submitted"
              label="Antragsdatum"
              value={obj.date_submitted}
              fullWidth
              variant="outlined"
              style={{zIndex: 988}}
              onChange={handleSelectChange}
              // required={props.requiredFields.includes("short")}
              InputProps={{
                readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("date_submitted"))),
              }}
              type="date"
              size="small"
            />
            <TextField
              margin="dense"
              id="status"
              label="Status"
              value={obj.status}
              fullWidth
              variant="outlined"
              style={{zIndex: 988}}
              onChange={handleSelectChange}
              // required={props.requiredFields.includes("short")}
              InputProps={{
                readOnly: (readOnly || (props.disabledFields && props.disabledFields.includes("date_submitted"))),
              }}
              type="select"
              options={props.selectOptions["status"]}
              size="small"
            />
            <TextField
              margin="dense"
              id="username"
              label="Benutzername"
              value={obj.username}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              required={props.requiredFields && props.requiredFields.includes("username")}
              // error={!obj.name}
              InputProps={{
                readOnly: readOnly,
              }}
              type="string"
              size="small"
              hidden={props.hiddenFields && props.hiddenFields.includes("username")}
            />
          </Grid>
        </Grid>
  );
}
