import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { withSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

// import InstitutionSelector from './InstitutionSelector.jsx'

class NewInstitutionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, name: this.props.name};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.setState({name: this.props.name});
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleSave = event => {
    this.props.handleSave({
      name: this.state.name,
      street: this.state.street,
      city: this.state.city,
      zip: this.state.zip,
      country: this.state.country,
      phone_number: this.state.phone_number,
      fax_number: this.state.fax_number,
      email: this.state.email,
      website: this.state.website}
    )
    this.setState({name: null, email: null, street: null, city: null, zip: null, country: null})
  }

  render(props) {
    const onlyNumber = /\d+/;
    const onlyNumberMatch = (this.state.zip && this.state.zip.toString().match(onlyNumber)) ? this.state.zip.toString().match(onlyNumber)[0] == this.state.zip : false;
    const zipIsOnlyNumber = (this.state.zip == null || this.state.zip == "" || onlyNumberMatch);
    const saveable = this.state.name && zipIsOnlyNumber;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Neue Institution im Adressbuch anlegen</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: green[500]}}>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.name}
                  error={!this.state.name}
                  InputLabelProps={{ shrink: (this.state.name != null) }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="street"
                  label="Straße und Hausnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.street}
                  // error={!this.state.street}
                  InputLabelProps={{ shrink: (this.state.street != null) }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="zip"
                  label="Postleitzahl"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.zip}
                  // error={!this.state.zip}
                  InputLabelProps={{ shrink: (this.state.zip != null) }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="city"
                  label="Stadt"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.city}
                  // error={!this.state.city}
                  InputLabelProps={{ shrink: (this.state.city != null) }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="country"
                  label="Land"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.country}
                  // error={!this.state.country}
                  InputLabelProps={{ shrink: (this.state.country != null) }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="E-Mail"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.email}
                  // error={!this.state.country}
                  InputLabelProps={{ shrink: (this.state.email != null) }}
                  type="email"
                />
                <TextField
                  margin="dense"
                  id="phone_number"
                  label="Telefonnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.phone_number}
                  // error={!this.state.country}
                  InputLabelProps={{ shrink: (this.state.phone_number != null) }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="fax_number"
                  label="Faxnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.fax_number}
                  // error={!this.state.country}
                  InputLabelProps={{ shrink: (this.state.fax_number != null) }}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="website"
                  label="Webseite"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.website}
                  // error={!this.state.country}
                  InputLabelProps={{ shrink: (this.state.website != null) }}
                  type="string"
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.handleSave({
            name: this.state.name,
            street: this.state.street,
            city: this.state.city,
            zip: this.state.zip,
            country: this.state.country,
            phone_number: this.state.phone_number,
            fax_number: this.state.fax_number,
            email: this.state.email,
            website: this.state.website,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withSnackbar(NewInstitutionDialog);
