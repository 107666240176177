import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { save } from 'save-file';

import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

import { Can } from './Can';

import TextField from './TextField.jsx';
import EnhancedTable from './EnhancedTable.jsx';


function handleFilters(peopleFilters, people_trials_associations) {
  let ret = people_trials_associations;
  if (peopleFilters["overview_show"] && ret.length > 0 && peopleFilters["overview_show"].value != null) {
    ret = ret.filter(person => ((person.overview_show == null && (peopleFilters["overview_show"]["value"] == true || peopleFilters["overview_show"]["value"] == null)) || person.overview_show == peopleFilters["overview_show"]["value"]));
  }
  if (peopleFilters["trial_role"] && peopleFilters["trial_role"].length > 0 && ret.length > 0) {
    const trial_roles = peopleFilters.trial_role.map(role => role.value);
    ret = ret.filter(person => trial_roles.includes(person.trial_role));
  }
  if (peopleFilters["left"] != null && ret.length > 0) {
    ret = ret.filter(person => ((person.left == null && peopleFilters["left"].value == false) || (person.left == peopleFilters["left"].value) || peopleFilters["left"].value == null));
  }
  if (peopleFilters["primary_contact"] != null && peopleFilters["primary_contact"].value != null && ret.length > 0) {
    // console.log(peopleFilters["primary_contact"]);
    ret = ret.filter(person => (person.primary_contact == peopleFilters["primary_contact"].value));
  }
  return ret;
}

export default function TrialPeople(props) {
  let data = "Geschlecht\tHerr_Frau\tAnrede\tVorname\tName\tTitel\tTelefon\tFax\teMail\tAdresse(n)\tFunktion\tprim. Ansprechpartner\tTags\tAusgeschieden\tDatum_Eintritt\tDatum_Austritt";
  let data2 = "Geschlecht\tHerr_Frau\tAnrede\tVorname\tName\tTitel\tTelefon\tFax\teMail\tAdresse(n)\tFunktion\tprim. Ansprechpartner\tTags\tAusgeschieden\tDatum_Eintritt\tDatum_Austritt";
  if (props.selectedObjects) {
    props.selectedObjects.forEach(person => {
      let herr_frau = "";
      let anrede = "";
      if (person.gender == "m" || person.gender == "M") {
        herr_frau = "Herr";
        anrede = "geehrter";
      } else if (person.gender == "w" || person.gender == "W") {
        herr_frau = "Frau";
        anrede = "geehrte";
      } else {
        herr_frau = "Frau/Herr";
        anrede = "geehrte(r)";
      }
      // const address = "Klinik\r\nAbteilung\r\n"
      const addresses = props.departments.map((department) => {
        let address = "";

        const institution = props.institutions.find(institution => institution.id == department.institution_id);
        if (institution) {
          address += institution.name + ", ";
        }
        address += department.name + ", ";
        address += department.street + ", " + department.zip + " " + department.city + "; ";
        return address;
      });
      data += "\r\n" + person.gender + "\t" + herr_frau + "\t" + anrede + "\t" + person.first_name + "\t" + person.last_name + "\t" + person.title + "\t" + person.phone_number + "\t" + person.fax_number+ "\t" + person.email+ "\t" + addresses.toString() + "\t" + person.trial_role + "\t" + person.primary_contact + "\t" + "\t" + person.left + "\t" + person.join_date + "\t" + person.leave_date;
      data2 += "\n" + person.gender + "\t" + herr_frau + "\t" + anrede + "\t" + person.first_name + "\t" + person.last_name + "\t" + person.title + "\t" + person.phone_number + "\t" + person.fax_number+ "\t" + person.email+ "\t" + addresses.toString() + "\t" + person.trial_role + "\t" + person.primary_contact + "\t" + "\t" + person.left + "\t" + person.join_date + "\t" + person.leave_date;
    })
  }
  const filtered_people_trials_associations = handleFilters(props.filters, props.people_trials_associations);
  // const textToCopy = props.selectedObjects.map(so => {
  //   let ret = "ZID\tInstitution\tGeschlecht\tHerr_Frau\tAnrede\tVorname\tName\tTitel\tTelefon\tFax\teMail\tAdresse\tFunktion\tprim. Ansprechpartner\tTags\tAusgeschieden\tDatum_Eintritt\tDatum_Austritt";

  //   let ret = "Titel\t" + this.state.trial.title + "\n";
  //   ret += "Akronym\t" + this.state.trial.short + "\n";
  //   if (this.state.trial.eudract_num) {
  //     ret += "EudraCT\t" + this.state.trial.eudract_num + "\n";
  //   }
  //   if (this.state.trial.bob_code) {
  //     ret += "BOB Vorlagenummer\t" + this.state.trial.bob_code + "\n";
  //   }
  //   if (this.state.trial.ek_code) {
  //     ret += "EK Aktenzeichen\t" + this.state.trial.ek_code + "\n";
  //   }
  //   if (this.state.trial.inspection_plan_code) {
  //     ret += "Prüfplan Code\t" + this.state.trial.inspection_plan_code + "\n";
  //   }
  //   if (this.state.trial.ctgov_code) {
  //     ret += "CT.gov Nr.\t" + this.state.trial.ctgov_code + "\n";
  //   }
  //   if (this.state.trial.comment) {
  //     ret += "Kommentar\t" + this.state.trial.comment;
  //   }
  //   return ret;
  // });
  return (
        // <div style={{backgroundColor: 'white', padding: '10px'}}>
          <EnhancedTable
            title={"Personen"}
            data={filtered_people_trials_associations}
            // singleSelect={false}
            multipleSelectable
            selectedIds={props.selectedIds}
            selectedObjects={props.selectedObjects}
            columns={props.columns}
            onRowsSelect={props.handleChange}
            handleMailTo={props.handleMailTo}
            handleInfoClick={props.handleInfoPersonClick}
            handleAddClick={props.handleAddOpen}
            handleFilterClick={props.handleFilterClick}
            handleRemoveClick={props.handleRemoveClick}
            handleDownloadClick={() => save(data, 'example.tsv')}
            textToCopy={data2}
            onFilterChange={props.onFilterChange}
            rowsPerPage={props.rowsPerPage ? props.rowsPerPage : 10}
            onDoubleClick={props.onDoubleClick}
            allData={props.people_trials_associations}
            orderBy="last_name"
            order="asc"
            selectable
            autoFill
            expanded={props.expanded}
            handleExpandChange={props.handleExpandChange}
            fullWidth
            filters={props.filters}
            superType="Trial"
            // missingRows={props.missingRows}
          />

        // </div>
  );
}
