import React from 'react';
import { withStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import { flexbox } from '@mui/system';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SiteSelector from './SiteSelector.jsx'
import DepartmentSelector from './DepartmentSelector.jsx'
// import EnhancedTable from './EnhancedTable.jsx';
import InfoPeopleTrialsAssociation from './InfoPeopleTrialsAssociation.jsx';
import InfoPerson from './InfoPerson.jsx';
import TextField from './TextField.jsx';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  MuiChip: {
    margin: 1,
  },
  content: {
      display: "flex",
      flexBasis: '100%',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-evenly',
  },
  label: {
    flex: '0 0 auto',
  },
  actions: {
    // color: theme.palette.text.secondary,
    align: "center",
  },
});

class InfoPersonTrialDialog extends React.Component {

  constructor(props) {
    super(props);
    // console.log("test");
    let selectedGender = {};
    if (this.props.selectedPerson.gender == "m") {
      selectedGender = {label: "Männlich", value: "m"}
    } else if (this.props.selectedPerson.gender == "w") {
      selectedGender = {label: "Weiblich", value: "w"}
    } else if (this.props.selectedPerson.gender == "?") {
      selectedGender = {label: "Unbekannt", value: "?"}
    }
    let departments = [];
    if (this.props.selectedPerson.sites_people_associations) {
      const department_ids = this.props.selectedPerson.sites_people_associations.filter(sites_people_association => {
        return sites_people_association.site_id == this.props.selectedSite.id;
      }).map(sites_people_association => sites_people_association.department_id);
      departments = this.props.selectedPerson.site_departments.filter(department => {
        return department_ids.includes(department.id);
      }).filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i);
    } else {
      departments = this.props.departments;
    }
    let salutation = "";
    if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
      salutation = "Sehr geehrter Herr";
    } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
      salutation = "Sehr geehrte Frau";
    } else if (this.props.selectedPerson.gender == "?") {
      salutation = "Sehr geehrte(r) Frau/Herr";
    }
    this.state = {open: this.props.open, departments: departments, addressSelection: {title: true, address: true, phone_number: true, mobile_number: true, institution: true, department: true, fax_number: true, name: true, email: true, salutation: true}, salutation: salutation, storyboardOpen: false};
    this.form = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPerson !== prevProps.selectedPerson) {
      let departments = [];
      if (this.props.selectedPerson.sites_people_associations) {
        const department_ids = this.props.selectedPerson.sites_people_associations.filter(sites_people_association => {
          return sites_people_association.site_id == this.props.selectedSite.id;
        }).map(sites_people_association => sites_people_association.department_id);
        departments = this.props.selectedPerson.site_departments.filter(department => {
          return department_ids.includes(department.id);
        }).filter((v, i, a) => a.map(e => e.id).indexOf(v.id) === i);
      } else {
        departments = this.props.departments;
      }
      let salutation = "";
      if (this.props.selectedPerson.gender == "m" || this.props.selectedPerson.gender == "M") {
        salutation = "Sehr geehrter Herr";
      } else if (this.props.selectedPerson.gender == "w" || this.props.selectedPerson.gender == "W") {
        salutation = "Sehr geehrte Frau";
      } else if (this.props.selectedPerson.gender == "?") {
        salutation = "Sehr geehrte(r) Frau/Herr";
      }
      let site_function = "";
      if (this.props.selectedPerson.site_function != null) {
        site_function = this.props.selectedPerson.site_function;
      }
      this.setState({ selectedPerson: this.props.selectedPerson, addressSelection: {title: true, address: true, phone_number: true, mobile_number: true, institution: true, department: true, fax_number: true, name: true, email: true, salutation: true }, salutation: salutation});
    }
  }

  // getDepartments = () => {
  //   let currentComponent = this;
  //   // departments = this.this.props.peopleDepartmentsAssociations.filter(assoc => assoc.person_id == this.props.selectedPerson.id).map(assoc => assoc.department_id);
  //   currentComponent.setState({ departments: this.props.departments });
  //   // let currentComponent = this;
  //   // // const departments = this.state.departments;
  //   // fetch('/people/'+this.props.selectedPerson.id+'/get_departments', {
  //   //   credentials: 'include',
  //   // })
  //   // .then(function(response){return response.json();})
  //   // .then(function(data){
  //   //   // console.log(data);
  //   //   currentComponent.setState({ departments: data.departments, institutions: data.institutions });
  //   // });
  // }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleChange2 = event => {
    let currentComponent = this;
    // const target = event.target;
    // const value = target.value;
    // const name = target.id;
    let newAddressSelection = Object.assign({}, this.state.addressSelection);
    // console.log(event.target.value);
    newAddressSelection[event.target.value] = newAddressSelection[event.target.value] ? false : true;
    currentComponent.setState({ addressSelection: newAddressSelection });
  };

  handleRemoveDepartment = (department_id) => {
    // console.log(department_id);
    let currentComponent = this;
    fetch('/people/'+this.props.selectedPerson.id+'/remove_from_department', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        department_id: department_id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        let newDepartments = [].concat(currentComponent.state.departments);
        const departmentIndex = newDepartments.findIndex(newDepartment => newDepartment.id == department_id);
        newDepartments.splice(departmentIndex,1);
        currentComponent.setState({ departments: newDepartments });
        currentComponent.props.enqueueSnackbar('Erfolgreich entfernt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  };

  handleDoubleClickDepartment = event => {
    console.log(event);
    console.log("doubleClick")
  };

  handleGenderChange = event => {
    this.setState({ selectedGender: event, gender: event.value });
  }

  handleClose = event => {
    // this.setState({lastName: this.props.selectedPerson.last_name, firstName: this.props.selectedPerson.first_name, title: this.props.selectedPerson.title, phoneNumber: this.props.selectedPerson.phone_number, email: this.props.selectedPerson.email, function: this.props.selectedPerson.function, mobileNumber: this.props.selectedPerson.mobile_number, faxNumber: this.props.selectedPerson.fax_number, unlocked: false, selectedGender: {value: this.props.selectedPerson.gender}});
    this.props.handleClose();
  }

  render(props) {
    const { classes } = this.props;
    const { departments } = this.state;
    const genderOptions = [
      {label: "Unbekannt", value: "?"},
      {label: "Männlich", value: "m"},
      {label: "Weiblich", value: "w"},
    ];
    // const saveable = this.state.selectedPerson.lastName && this.state.selectedPerson.firstName  && (this.state.selectedPerson.gender != "" && this.state.selectedPerson.gender != null);
    var departmentChips = null;
    var handleRemoveDepartment = null;
    let forwardDisabled = false;
    let backwardDisabled = false;
    if ((!(this.props.handleForwardClick)) || ((this.props.selectedPersonIndex + 1) >= this.props.numPeople )) {
      forwardDisabled = true;
    }
    if ((!(this.props.handleBackwardClick)) || this.props.selectedPersonIndex == 0) {
      backwardDisabled = true;
    }
    // const person_departments = selectedPerson

    // const name = (this.state.title ? this.state.title) +
    // storyboards = this.props.

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        fullWidth
        PaperProps={{style: {height: '900px'}}}
      >
        <DialogTitle id="form-dialog-title">
          {((this.props.selectedPerson.title != null) ? this.props.selectedPerson.title + " " : "") + this.props.selectedPerson.first_name + " " + this.props.selectedPerson.last_name}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={6} style={{backgroundColor: blue[500]}}>
              <InfoPeopleTrialsAssociation
                selectedPerson={this.props.selectedPerson}
                handleEditClick={this.props.handleEditPtaClick}
                handleClose={this.props.handleEditPtaClose}
              />
            </Grid>
            <Grid item xs={6} style={{backgroundColor: green[500]}}>
              <InfoPerson
                selectedPerson={this.props.selectedPerson}
                // departments={this.props.departments}
                institutions={this.props.institutions}
                departments={this.props.departments}
                handleEditClick={this.props.handleEditPersonClick}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center" width="100%">
            <div>
            </div>
            <div>
              {backwardDisabled ? (
                <IconButton aria-label="Zurück" disabled={backwardDisabled}>
                  <ArrowBackIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="Zurück" onClick={this.props.handleBackwardClick}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              {this.props.selectedPersonIndex + 1} von {this.props.numPeople}
              {forwardDisabled ? (
                  <IconButton aria-label="Forwärts" disabled={forwardDisabled}>
                    <ArrowForwardIcon />
                  </IconButton>
                ) : (
                  <IconButton aria-label="Forwärts" onClick={this.props.handleForwardClick}>
                    <ArrowForwardIcon />
                  </IconButton>
                )
              }
                </div>

            <Button onClick={this.handleClose} color="primary">
              Schließen
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}

InfoPersonTrialDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoPersonTrialDialog);
