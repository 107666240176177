import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { withSnackbar } from 'notistack';

import InstitutionSelector from './InstitutionSelector.jsx'
import NewInstitutionDialog from './NewInstitutionDialog.jsx';


class NewSiteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open, selectedInstitution: [], newInstitutionOpen: false, newInstitutionName: "", institutions: {}, comment: ""};
    this.form = React.createRef();
  }

  // handleSave = ( event, sites) => {
  //   this.setState({ open: false});
  //   fetch('/trials/'+this.props.trialId+'/site_selection/new_site', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       cost_center: this.state.costCenter,
  //       name: this.state.name,
  //       contact_email: this.state.contactEmail,
  //       street: this.state.street,
  //       city: this.state.city,
  //       zip: this.state.zip,
  //       country: this.state.country,
  //       institution_id: this.state.selectedInstitution.id,
  //     })
  //   })
  //   .then(function(response){return response.json();})
  //   // .then(function(data){
  //     // console.log(data);
  //     // var newSites = sites;
  //     // console.log(newSites);
  //
  //     // newSites.push(data.site);
  //
  //   // });
  //   this.props.handleSitesChange(site);
  // };

  componentDidMount(props) {
    let currentComponent = this;
    let institutionsList = [];
    fetch('/institutions/index_all', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({ institutions: data.institutions });
      // currentComponent.setState({ trials: data.trials });
    });
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleInstitutionChange = (event, selectedInstitutionObject) => {
    let currentComponent = this;
    // const target = event.target;
    // const value = target.value;
    // console.log(event);
    if (selectedInstitutionObject) {
      currentComponent.setState({ selectedInstitutionId: event.value})
      if (selectedInstitutionObject.city) {
        currentComponent.setState({ instDescription: selectedInstitutionObject.city + ", " + selectedInstitutionObject.name });
      } else {
        currentComponent.setState({ instDescription: selectedInstitutionObject.name });
      }
    }
  };

  handleNewInstitutionOpen = event => {
    // console.log(event);
    this.setState({ newInstitutionOpen: true, open: false, newInstitutionName: event});
  };

  handleNewInstitutionClose = event => {
    this.setState({ newInstitutionOpen: false, open: true});
  };

  handleNewInstitutionSave = institution => {
    let currentComponent = this;
    this.setState({ newInstitutionOpen: false, open: true});
    fetch('/institutions/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        name: institution.name,
        street: institution.street,
        city: institution.city,
        zip: institution.zip,
        country: institution.country,
        phone_number: institution.phone_number,
        fax_number: institution.fax_number,
        email: institution.email,
        website: institution.website,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        var institutions = [].concat(currentComponent.state.institutions);
        institutions.push(data.institution);
        // departments.push(data.department);
        currentComponent.setState({ institutions: institutions, selectedInstitutionId: data.institution.id, instDescription: data.institution.city + ", " + data.institution.name });
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    this.handleNewInstitutionClose();
    // this.forceUpdate();
  };

  handleSave = event => {
    this.props.handleSave({
      marvin_id: this.state.marvinId,
      name: this.state.name,
      inst_description: this.state.instDescription,
      show: 1,
      institution_id: this.state.selectedInstitutionId,
      comment: this.state.comment,
    })
    this.setState({marvinId: null, name: null, instDescription: null, selectedInstitutionId: null})
  }

  formatCreate = (inputValue) => {
    return (<p> {inputValue} anlegen</p>);
  };

  render(props) {
    const saveable = this.state.selectedInstitutionId && this.state.instDescription;
    return (
      <>
      <NewInstitutionDialog
        open={this.state.newInstitutionOpen}
        handleClose={this.handleNewInstitutionClose}
        handleSave={this.handleNewInstitutionSave}
        name={this.state.newInstitutionName}
      />
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{style: {height: '600px'}}}
      >
        <DialogTitle id="form-dialog-title">Neues Zentrum anlegen</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" margin="dense" fullWidth style={{zIndex: 999}}>
            <InputLabel
              filled
              animated
              shrink
              margin="dense"
              variant="outlined"
              error={!this.state.selectedInstitutionId}
            >
              Institution
            </InputLabel>
            <InstitutionSelector
              autoFocus
              trial={this.props.trialId}
              selectedInstitutionId={this.state.selectedInstitutionId}
              handleChange={this.handleInstitutionChange}
              id="institution_id"
              onCreateOption={this.handleNewInstitutionOpen}
              institutions={this.state.institutions}
            />
          </FormControl>
          <TextField
            margin="dense"
            id="instDescription"
            label="Zentrum Anzeigename"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.instDescription}
            error={!this.state.instDescription}
            style={{zIndex: 1}}
            InputLabelProps={{ shrink: true }}
            type="string"
          />
          <TextField
            margin="dense"
            id="marvinId"
            label="Zentrum ID"
            type="number"
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            value={this.state.marvinId}
            // error={isNaN(this.state.marvinId)}
            style={{zIndex: 1}}
          />
          <TextField
            id="comment"
            label="Kommentar"
            onChange={this.handleChange}
            multiline
            // className={classes.textField}
            margin="dense"
            variant="outlined"
            value={this.state.comment}
            fullWidth
            style={{zIndex: 1}}
            type="text"
          />
        </DialogContent>
        <DialogActions>
        <Button onClick={this.props.handleClose} color="primary">
          Abbrechen
        </Button>
          <Button
          disabled={!saveable}
          onClick={() => this.handleSave({
            marvin_id: this.state.marvinId,
            name: this.state.name,
            inst_description: this.state.instDescription,
            show: 1,
            institution_id: this.state.selectedInstitutionId,
            comment: this.state.comment,
          })}
          color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
  }
}

export default withSnackbar(NewSiteDialog);
