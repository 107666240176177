import React from 'react';
import { withSnackbar } from 'notistack';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import SiteSelector from './SiteSelector.jsx'


class NewDepartmentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: this.props.open};
    this.form = React.createRef();
  }

  componentDidMount(props) {
    if (this.props.selectedInstitution != null) {
      console.log(this.props.selectedInstitution);
      this.setState({zip: this.props.selectedInstitution.zip, street: this.props.selectedInstitution.street, city: this.props.selectedInstitution.city, country: this.props.selectedInstitution.country, open: this.props.open, inputValue: this.props.inputValue});
    } else if (this.props.selectedSite != null) {
      this.setState({zip: this.props.selectedSite.institution.zip, street: this.props.selectedSite.institution.street, city: this.props.selectedSite.institution.city, country: this.props.selectedSite.institution.country});
    }
  }

  // handleSave = event => {
  //   let currentComponent = this;
  //   this.setState({ open: false});
  //   fetch('/trials/'+this.props.trialId+'/site_selection/new_department', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       cost_center: this.state.costCenter,
  //       name: this.state.name,
  //       contact_email: this.state.contactEmail,
  //       street: this.state.street,
  //       city: this.state.city,
  //       zip: this.state.zip,
  //       country: this.state.country,
  //       site_id: this.props.selectedSite.id,
  //     })
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     console.log(data);
  //     var sites = currentComponent.state.sites;
  //     console.log(sites);
  //
  //     sites.push(data.site);
  //     currentComponent.setState({ sites: sites });
  //   });
  // };

  componentDidUpdate(prevProps) {
    if (this.props.inputValue != prevProps.inputValue || this.props.open != prevProps.open) {
      this.setState({inputValue: this.props.inputValue, open: this.props.open, name: this.props.inputValue});
    }
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  // handleSave = () => {
  //   let selectedInstitution = null;
  //   if (this.props.selectedInstitution) {
  //     selectedInstitution = this.props.selectedInstitution;
  //   } else if (this.props.selectedSite) {
  //     selectedInstitution = this.props.selectedSite.institution;
  //   }
  //   this.props.handleSave({cost_center: this.state.cost_center, name: this.state.name, street: this.state.street, city: this.state.city, zip: this.state.zip, country: this.state.country, object_id: this.props.selectedSite.id, phone_number: this.state.phone_number, fax_number: this.state.fax_number, email: this.state.email, website: this.state.website});
  //   this.setState({cost_center: null, name: null, street: null, city: null, zip: null, country: null, object_id: this.props.selectedSite.id,    phone_number: null,
  //   fax_number: null,
  //   email: null,
  //   website: null});
  // }

  handleSave = values => {
    // let selectedInstitution = null;
    if (this.props.selectedInstitution) {
      // selectedInstitution = this.props.selectedInstitution;
      let currentComponent = this;
      let newDepartments = [];
      fetch('/departments/create', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          cost_center: values.cost_center,
          name: values.name,
          contact_email: values.contact_email,
          street: values.street,
          city: values.city,
          zip: values.zip,
          country: values.country,
          phone_number: values.phone_number,
          fax_number: values.fax_number,
          email: values.email,
          website: values.website,
          institution_id: values.object_id,
          comment: values.comment,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        if (data.response == 1) {
          currentComponent.props.handleAddDepartment(data.department);
          currentComponent.props.handleClose();
          // currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
          // newDepartments = newDepartments.concat(currentComponent.state.departments, data.department);
          // console.log(departments);
          // currentComponent.setState({ departments: newDepartments });
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else if (this.props.selectedSite) {
      // selectedInstitution = this.props.selectedSite.institution;
      let currentComponent = this;
      let newDepartments = [];
      this.setState({ newDepartmentOpen: false});
      fetch('/trials/'+this.props.trialId+'/site_selection/new_department', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          cost_center: values.cost_center,
          name: values.name,
          email: values.email,
          street: values.street,
          city: values.city,
          zip: values.zip,
          country: values.country,
          phone_number: values.phone_number,
          fax_number: values.fax_number,
          website: values.website,
          site_id: values.object_id,
          comment: values.comment,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        if (data.response == 1) {
          // console.log(data);
          // var departments = currentComponent.state.departments;
          // console.log(departments);
          //
          // newDepartments = newDepartments.concat(currentComponent.state.departments, data.department);
          // console.log(departments);
          // currentComponent.setState({ departments: newDepartments });
          currentComponent.props.handleAddDepartment(data.department, data.assoc);
          // currentComponent.props.enqueueSnackbar('Erfolgreich erstellt.', {variant: 'success'});
          currentComponent.props.handleClose();
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
    // console.log(values);

    // this.updateDepartments(currentComponent.state.selectedDepartmentIds);
  };

  handleClose = () => {
    this.setState({open: false});
    if (this.props.selectedInstitution) {
      this.setState({zip: this.props.selectedInstitution.zip, street: this.props.selectedInstitution.street, city: this.props.selectedInstitution.city, country: this.props.selectedInstitution.country});
    } else if (this.props.selectedSite) {
      this.setState({zip: this.props.selectedSite.institution.zip, street: this.props.selectedSite.institution.street, city: this.props.selectedSite.institution.city, country: this.props.selectedSite.institution.country});
    }
    this.props.handleClose();
  }

  // handleSiteChange = event => {
  //   let currentComponent = this;
  //   const target = event.target;
  //   const value = target.value;
  //   console.log(value);
  //   currentComponent.setState({ selectedSite: value });
  // };

  render(props) {
    const onlyNumber = /\d+/;
    if (this.state.zip){
      console.log(this.state.zip, this.state.zip.toString().match(onlyNumber), (this.state.zip && this.state.zip.toString().match(onlyNumber)));
    }
    const onlyNumberMatch = (this.state.zip && this.state.zip.toString().match(onlyNumber)) ? this.state.zip.toString().match(onlyNumber)[0] == this.state.zip : false;
    const zipIsOnlyNumber = (this.state.zip == null || this.state.zip == "" || onlyNumberMatch);
    const saveable = this.state.name && zipIsOnlyNumber;
    // && this.state.street && !isNaN(this.state.zip) && this.state.city && this.state.country
    let object_id = -1;
    if (this.props.selectedSite) {
      object_id = this.props.selectedSite.id;
    } else if (this.props.selectedInstitution) {
      object_id = this.props.selectedInstitution.id;
    }
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Neue Abteilung im Adressbuch anlegen</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{backgroundColor: green[500]}}>
              <div style={{margin: "10px", backgroundColor: 'white', padding: '10px'}}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Name"
                  onChange={this.handleChange}
                  fullWidth
                  autoFocus
                  variant="outlined"
                  value={this.state.name}
                  error={!this.state.name}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="street"
                  label="Straße und Hausnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.street}
                  // error={!this.state.street}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="zip"
                  label="Postleitzahl"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.zip}
                  error={!zipIsOnlyNumber}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="city"
                  label="Stadt"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.city}
                  // error={!this.state.city}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="country"
                  label="Land"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.country}
                  // error={!this.state.country}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="E-Mail"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.email}
                  // error={!this.state.country}
                  type="email"
                />
                <TextField
                  margin="dense"
                  id="phone_number"
                  label="Telefonnummer"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.phone_number}
                  // error={!this.state.country}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="fax_number"
                  label="Land"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.fax_number}
                  // error={!this.state.country}
                  type="string"
                />
                <TextField
                  margin="dense"
                  id="website"
                  label="Webseite"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  value={this.state.website}
                  // error={!this.state.country}
                  type="string"
                />
                <TextField
                  id="comment"
                  label="Kommentar"
                  onChange={this.handleChange}
                  multiline
                  // className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={this.state.comment}
                  fullWidth
                  style={{zIndex: 1}}
                  type="text"
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent> 
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={!saveable}
            onClick={() => this.handleSave({object_id: object_id, cost_center: this.state.cost_center, email: this.state.email, name: this.state.name, street: this.state.street, city: this.state.city, zip: this.state.zip, country: this.state.country, phone_number: this.state.phone_number, fax_number: this.state.fax_number, website: this.state.website, comment: this.state.comment})}
            color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withSnackbar(NewDepartmentDialog);
