import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
import Switch from '@mui/material/Switch';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";

import { withSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import { Can } from './Can';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

import Moment from 'moment';

import EnhancedTable from './EnhancedTable.jsx';
import NewContractDialog from './NewContractDialog.jsx';
import InfoContractDialog from './InfoContractDialog.jsx';
import EditContractDialog from './EditContractDialog.jsx';
import NewNoteDialog from './NewNoteDialog.jsx';
import EditNoteDialog from './EditNoteDialog.jsx';
import EditTaskDialog from './EditTaskDialog.jsx';
import InfoTaskDialog from './InfoTaskDialog.jsx';
import NewTaskDialog from './NewTaskDialog.jsx';
import DeleteTaskConfirmationDialog from './DeleteTaskConfirmationDialog.jsx';

Moment.locale('de');

const styles = {
  TableCheckbox: {
    padding: 0,
  },
  root: {
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      head: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        height: 10,
      },
      paddingCheckbox: {
        width: 37,
        backgroundColor: 'FFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: "7px",
      },
      head: {
        height: "26px",
      },
    },
    MuiTableHead: {
      root: {
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        height: 30,
        paddingRight: 2,
      }
    },
    MuiDialog: {
      paper: {
        height: 800,
        width: 800,
      },
    },
    MuiPaper: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 10,
        marginBottom: 0,
      },
    },
    menu: {
      zIndex: 100
    },
  },
};

const contractColumns = [
  {
    id: "name",
    label: "Name",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "contract_type",
    label: "Typ",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "number_code",
    label: "Nr/Code",
    numeric: false,
    disablePadding: false,
    type: "string",
  },
  {
    id: "status",
    // secondary: "Datum (s)",
    label: "Status",
    numeric: false,
    disablePadding: false,
    type: "objectStatus",
  },
  {
    id: "expiry_date",
    label: "Ablaufdatum",
    numeric: false,
    disablePadding: false,
    type: "date",
  },
];

const noteColumns = [
    {
     id: "entry_date",
     label: "Datum",
     numeric: false,
     disablePadding: false,
     date: true,
     type: "date",
    },
    {
     id: "note",
     label: "Notiz",
     numeric: false,
     disablePadding: false,
    },
   ];

   const taskColumns = [
    {
      id: "title",
      label: "Titel",
      numeric: false,
      padding: 'none',
    },
    {
      id: "nextReminderDate",
      label: "Erinnerungsdatum",
      numeric: false,
      padding: 'none',
      date: true,
      type: "date",
    },
    {
      id: "date_due",
      label: "Fälligkeitsdatum",
      numeric: false,
      padding: 'none',
      date: true,
      type: "date",
    },
    {
      id: "description",
      label: "Beschreibung",
      numeric: false,
      padding: 'none',
      type: "textNoBreak",
    },
    {
      id: "statusText",
      label: "Status",
      numeric: false,
      padding: 'none',
      type: "objectStatus",
      filterable: true,
    },
    {
      id: "created_at",
      label: "Erstellungsdatum",
      numeric: false,
      padding: 'none',
      date: true,
      type: "date",
    },
    {
      id: "updated_at",
      label: "Letzte Änderung",
      numeric: false,
      padding: 'none',
      date: true,
      type: "date",
    },
  ];

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const task_id = urlParams.get('task_id');
    const contract_id = urlParams.get('contract_id');
    this.state = {trial: this.props.trial, selectedContracts: (contract_id) ? this.props.trial.contracts.filter(contract => contract.id == parseInt(contract_id)) : null, selectedContractIds: (contract_id) ? [parseInt(contract_id)] : null, selectedNotes: null, openDialogs: {newContract: false, editContract: false, newNote: false, editNote: false, infoContract: false, infoTask: (task_id) ? true : false, editTask: false, newTask: false}, multipleSelectMode: false, selectedTasks: [], selectedTaskIds: (task_id) ? [parseInt(task_id)] : [], taskFilters: {}, expandedTable: {"Verträge etc.": true, Notizen: true, "Merkzettel (Verträge etc.)": true}, user: props.user};
    // this.setState({trial: this.props.trial});
    this.form = React.createRef();
  }

  handleNewContractOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newContract"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoContractOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoContract"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditContractOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editContract"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewNoteOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newNote"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditNoteOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editNote"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewContractClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newContract"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoContractClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoContract"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditContractClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editContract"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewNoteClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newNote"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditNoteClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editNote"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleContractDoubleClick = (event, obj) => {
    this.setState({selectedContracts: [obj], selectedContractIds: [obj.id]});
    this.handleInfoContractOpen();
  }

  // handleNoteDoubleClick = (event, obj) => {
  //   this.setState({selectedNotes: [obj]});
  //   this.handleEditProcessStepOpen();
  // }

  handleNewContractSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/new_contract', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        contract: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
        currentComponent.handleNewContractClose()
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEditContractSave = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/update_contract', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        contract: obj,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
        currentComponent.handleEditContractClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteContract = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/delete_contracts', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        contract_ids: this.state.selectedContracts.map(con => con.id),
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
        // currentComponent.handleEditProcessStepClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  // handleNewNoteSave = obj => {
  //   // console.log(vals);
  //   let currentComponent = this;
  //   fetch('/trials/'+this.state.trial.id+'/new_process_step', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       process_instance_id: this.state.selectedContract.id,
  //       process_step: obj,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     if (data.response == 1) {
  //       currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
  //       currentComponent.handleNewProcessStepClose();
  //     } else {
  //       currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  // }

  // handleEditNoteSave = obj => {
  //   // console.log(vals);
  //   let currentComponent = this;
  //   fetch('/trials/'+this.state.trial.id+'/update_process_step', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       process_instance_id: this.state.selectedProcess.id,
  //       process_step: obj,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     if (data.response == 1) {
  //       currentComponent.props.enqueueSnackbar('Erfolgreich angelegt.', {variant: 'success'});
  //       currentComponent.handleEditProcessStepClose();
  //     } else {
  //       currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  // }

  handleDeleteNote = obj => {
    // console.log(vals);
    let currentComponent = this;
    fetch('/trials/'+this.state.trial.id+'/contracts/delete_notes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        contract_id: this.state.selectedContracts[0].id,
        note_ids: this.state.selectedNoteIds,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // this.handleDeleteProcessConfirmationDialogClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleContractChange = (rowData, allRowsSelected, allObjectsSelected) => {
    // const ProcessIds = allObjectsSelected.map(site => site.institution_id);
    // const selectedProcesses = this.state.processes.filter(institution => institutionIds.includes(institution.id));
    this.setState({
      selectedContracts: allObjectsSelected,
      selectedContractIds: allRowsSelected,
      selectedNotes: [],
      selectedNoteIds: [],
    });
    // this.updateDepartments(allRowsSelected);
  };

  handleNoteChange = (rowData, allRowsSelected, allObjectsSelected) => {
    // const ProcessIds = allObjectsSelected.map(site => site.institution_id);
    // const selectedProcesses = this.state.processes.filter(institution => institutionIds.includes(institution.id));
    // let newSelectedProcessStep = allObjectsSelected[0];
    // newSelectedProcessStep["date_deadline"] = ;
    this.setState({
      selectedNote: allObjectsSelected,
      selectedNoteIds: allRowsSelected,
    });
  };

  handleNoteDoubleClick = (event, obj) => {
    var allObjectsSelected = [obj];
    var selectedNoteIds = [obj.id];
    // var selectedNoteIndices = this.state.selectedContract[0].notes.findIndex(object => object.id === obj.id);
    this.setState({
      selectedNotes: allObjectsSelected,
      selectedNoteIds: selectedNoteIds,
    });
    this.handleEditNoteOpen();
  }

  handleNewNoteSave = (note) => {
    let currentComponent = this;
    // console.log(note);
    this.setState({ editNoteOpen: false});
    fetch('/trials/'+this.state.trial.id+'/contracts/new_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        contract_id: this.state.selectedContracts[0].id,
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newNotes = [].concat(currentComponent.state.notes);
        // const noteIndex = newNotes.findIndex(note => note.id === data.note.id );
        // newNotes[noteIndex] = data.note;
        // currentComponent.setState({ notes: newNotes });
        // // currentComponent.updateNotes(currentComponent.state.selectedDepartmentIds);
        currentComponent.handleNewNoteClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateNotes(this.state.selectedDepartmentIds);
  }

  handleEditNoteSave = (note) => {
    let currentComponent = this;
    // console.log(note);
    this.setState({ editNoteOpen: false});
    fetch('/trials/'+this.state.trial.id+'/contracts/update_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
        contract_id: this.state.selectedContracts[0].id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newNotes = [].concat(currentComponent.state.notes);
        // const noteIndex = newNotes.findIndex(note => note.id === data.note.id );
        // newNotes[noteIndex] = data.note;
        // currentComponent.setState({ notes: newNotes });
        // // currentComponent.updateNotes(currentComponent.state.selectedDepartmentIds);
        currentComponent.handleEditNoteClose();
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateNotes(this.state.selectedDepartmentIds);
  }

  handleMultipleSelectChange = event => {
    if (this.state.multipleSelectMode) {
      this.setState({multipleSelectMode: false});
    } else {
      this.setState({multipleSelectMode: true});
    }
  }

  handleRefresh = event => {
    let currentComponent = this;
    fetch('/trials/' + currentComponent.state.trial.id + '/contracts/refresh', {
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (currentComponent.state.selectedContracts && currentComponent.state.selectedContracts.length > 0) {
        const newSelectedContracts = [... data.trial.contracts.filter(contract => currentComponent.state.selectedContractIds.includes(contract.id))];
        const newSelectedContractIds = newSelectedContracts.map(contract => contract.id);
        if (currentComponent.state.selectedNotes && currentComponent.state.selectedContracts.length == 1 && currentComponent.state.selectedNotes.length > 0) {
          const newSelectedNotes = data.trial.contracts.map(contract => contract.polymorphic_notes.filter(note => currentComponent.state.selectedNoteIds.includes(note.id))).flatten();
          const newSelectedNoteIds = newSelectedNotes.map(note => note.id);
          currentComponent.setState({selectedNotes: newSelectedNotes, selectedNoteIds: newSelectedNoteIds});
        }
        currentComponent.setState({selectedContracts: newSelectedContracts, selectedContractIds: newSelectedContractIds});
      }
      currentComponent.setState({ trial: data.trial });
    });
  }

  handleNewTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewTaskClose = (event, obj) => {
    console.log("test");
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewTaskCloseOpenInfo = (obj) => {
    // console.log(event, obj);
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["newTask"] = false;
    newOpenDialogs["infoTask"] = true;
    this.setState({selectedTasks: [obj], selectedTaskIds: [obj.id]})
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleInfoTaskClose = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["infoTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditTaskOpen = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditTaskOpenAndSelect = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = true;
    this.setState({openDialogs: newOpenDialogs, selectedTasks: [obj], selectedTaskIds: [obj.id]});
  }

  handleEditTaskClose = (event, obj) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editTask"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleTaskDoubleClick = (event, obj) => {
    // console.log(obj);
    this.setState({selectedTasks: [obj], selectedTaskIds: [obj.id]});
    this.handleInfoTaskOpen();
  }

  // handleTaskLinkClick = (event, obj) => {
  //   console.log(event, obj);
  //   if (obj.taskable_type == "ProcessStep") {
  //     let taskable = obj.taskable;
  //     while (!taskable.is_main_step) {
  //       taskable = taskable.taskable;
  //     }
  //     const process_instance = taskable.process_instance;
  //     const processable = process_instance.processable;
  //     const process_dummiable = processable.process_dummiable;
  //     if (processable.process_dummiable_type == "Trial") {
  //       window.location.assign('/trials/' + process_dummiable.id + '/ethics_processes');
  //     }
  //     // let process_dummy = process_instance.process_dummy;
  //     // console.log(taskable, process_instance, processable, process_dummiable);
  //   } else if (obj.taskable_type == "User") {
  //     const taskable = obj.taskable;
  //     console.log(taskable);
  //   } else {
  //     console.log(taskable);
  //   }
  // }

  handleDuplicateTaskClick = (event) => {
    // console.log(event);
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/duplicate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task: currentComponent.state.selectedTasks[0],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich dupliziert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleMarkDoneClick = (event) => {
    // console.log(event);
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    // console.log("task id", currentComponent.state.selectedTasks[0].id);
    fetch('/tasks/' + currentComponent.state.selectedTasks[0].id + '/mark_done', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task: currentComponent.state.selectedTasks[0],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich als erledigt markiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteTasks = (event, obj) => {
    const currentComponent = this;
    // console.log(event, obj);
    // let newOpenDialogs = {... this.state.openDialogs};
    // newOpenDialogs["newTask"] = false;
    // this.setState({openDialogs: newOpenDialogs});
    fetch('/tasks/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        task_ids: currentComponent.state.selectedTasks.map(st => st.id),
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
        currentComponent.handleDeleteTaskConfirmationDialogClose();
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleTaskChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      selectedTasks: allObjectsSelected,
      selectedTaskIds: allRowsSelected,
    });
  };

  handleTasksFilterClick = filterOpen => {
    if (!filterOpen) {
      this.setState({taskFilters: {is_pm: {label: "nur meine", value: true}, status: [{label: "Offen", value: "open"}]}});
    }
  }

  handleTasksFilterChange = (filters, meta) => {
    if ((meta.action == "select-option") || (meta.action == "remove-value")) {
      let newTaskFilters = {...this.state.taskFilters};
      newTaskFilters[meta["name"]] = filters;
      this.setState({taskFilters: newTaskFilters});
    } else if (meta.action == "clear") {

      let newTaskFilters = {...this.state.taskFilters};
      delete newTaskFilters[meta["name"]];
      this.setState({taskFilters: newTaskFilters});
    }
  }

  handleDeleteTaskConfirmationDialogOpen = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["deleteTaskConfirmation"] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleDeleteTaskConfirmationDialogClose = event => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["deleteTaskConfirmation"] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  // handleTasksReceived = message => {
  //   console.log("Task received", message);
  //   let newTrial = {...this.state.trial};
  //   let newTasks = [].concat(this.state.trial.process_tasks);
  //   if (message.new_task) {
  //     newTasks.push(message.new_task);
  //     newTrial.process_tasks = newTasks;
  //     // this.setState({tasks: newTasks});
  //     this.props.enqueueSnackbar('Task wurde von Benutzer ' + message.username + ' erstellt: ' + message.new_task.title, {variant: 'success'});
  //   } else if (message.task) {
  //     let changedTaskIndex = newTasks.findIndex(task => task.id == message.task.id);
  //     if (this.state.selectedTasks && this.state.selectedTasks.map(st => st.id).includes(message.task.id)){
  //       const taskIndex = this.state.selectedTasks.findIndex(st => st.id == message.task.id);
  //       let newSelectedTasks = [].concat(this.state.selectedTasks);
  //       newSelectedTasks[taskIndex] = message.task;
  //       this.setState({selectedTasks: newSelectedTasks});
  //     }
  //     if (changedTaskIndex > -1) {
  //       newTasks[changedTaskIndex] = message.task;
  //       if (this.state.selectedTask.id == message.task.id) {
  //         this.setState({selectedTask: message.task});
  //       }
  //       newTrial.process_tasks = newTasks;
  //       // this.setState({tasks: newTasks});
  //       this.props.enqueueSnackbar('Task aktualisiert: ' + message.task.title, {variant: 'success'});
  //     }
  //   } else if (message.deleted_task_ids) {
  //     const deletedTaskNames = newTasks.filter(task => message.deleted_task_ids.includes(task.id)).map(task => task.title);
  //     newTasks = newTasks.filter(task => !message.deleted_task_ids.includes(task.id));
  //     newTrial.process_tasks = newTasks;
  //     // this.setState({tasks: newTasks});
  //     this.props.enqueueSnackbar('Tasks von Benutzer ' + message.username + ' gelöscht: ' + deletedTaskNames, {variant: 'success'});
  //   }
  //   this.setState({trial: newTrial});
  // }

  handleExpandChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({expandedTable: newExpandState});
  }

  handleExpandPaperChange = (val, title) => {
    let newExpandState = this.state.expandedTable
    newExpandState[title] = val;
    this.setState({expandedTable: newExpandState});
  }

  handleContractsReceived = message => {
    console.log("received Contracts");
    console.log(message);
    if (message.new_contract && message.new_contract.contractable_id == this.state.trial.id) {
      let newTrial = {... this.state.trial};
      newTrial.contracts.push(message.new_contract)
      // console.log(newTrial);
      this.setState({ trial: newTrial });
      this.props.enqueueSnackbar('Ein(e) neue(r) Vertrag/Versicherung wurde von Benutzer ' + message.username + ' angelegt: ' + message.new_contract.name, {variant: 'success'});
    }
    if (message.contract) {
      let newTrial = {... this.state.trial};
      let contractIndex = newTrial.contracts.findIndex(con => con.id == message.contract.id);
      newTrial.contracts[contractIndex] = message.contract;
      if (this.state.selectedContracts && this.state.selectedContracts.length > 0) {
        const selectedContractIndex = this.state.selectedContracts.findIndex(con => con.id == message.contract.id)
        if (selectedContractIndex != -1) {
          let newSelectedContracts = [... this.state.selectedContracts];
          newSelectedContracts[selectedContractIndex] = message.contract;
          this.setState({selectedContracts: newSelectedContracts});
        }
      }
      if (this.state.selectedNotes && this.state.selectedNotes.length > 0) {
        const newSelectedNotes = [... message.contract.polymorphic_notes.filter(note => this.state.selectedNoteIds.includes(note.id))];
        const newSelectedNoteIds = newSelectedNotes.map(note => note.id);
        this.setState({selectedNotes: newSelectedNotes, selectedNoteIds: newSelectedNoteIds});
      }
      this.setState({ trial: newTrial });
      this.props.enqueueSnackbar('Vertrag wurde von Benutzer ' + message.username + ' editiert: ' + message.contract.name, {variant: 'success'});
    }
    if (message.deleted_contract_ids) {
      let newTrial = {... this.state.trial};
      const deletedContractNames = this.state.trial.contracts.filter(con => message.deleted_contract_ids.includes(con.id)).map(con => con.name);
      newTrial.contracts = newTrial.contracts.filter(con => (!message.deleted_contract_ids.includes(con.id)));
      let newSelectedContracts = (this.state.selectedContracts.length > 0) ? this.state.selectedContracts.filter(con => (!message.deleted_contract_ids.includes(con.id))) : null;
      let newSelectedContractIds = newSelectedContracts.map(contrac => contract.id);
      this.setState({ trial: newTrial, selectedContracts: newSelectedContracts, selectedContractIds: newSelectedContractIds });
      this.props.enqueueSnackbar('Verträge wurden von Benutzer ' + message.username + ' gelöscht: ' + deletedContractNames[0], {variant: 'success'});
    }
  }

  handleConnected = event => {
    console.log("connected");
  }

  render() {
    // const { classes } = this.props;
    const { trial, openDialogs, selectedContracts, selectedContractIds, selectedNotes, selectedNoteIds, selectedTaskIds } = this.state;
    let handleNewTaskOpen = null;
    if (selectedContracts && selectedContracts.length == 1) {
      handleNewTaskOpen = this.handleNewTaskOpen;
    }
    // console.log(this.state.trial)
    const taskStatusList = [{value: "done", label: "Erledigt"}, {value: "open", label: "Offen"}];

    const contractTypeList = [{value: "Vertrag", label: "Vertrag"}, {value: "Versicherung", label: "Versicherung"}, {value: "Sonstiges", label: "Sonstiges"}];
    const statusList = [{value: "Entwurf", label: "Entwurf"}, {value: "Verhandlung", label: "Verhandlung"}, {value: "zur Unterschrift", label: "zur Unterschrift", color: "#f5b556"}, {value: "Geschlossen", label: "Geschlossen", color: "#76d658"}, {value: "Abgelaufen", label: "Abgelaufen", color: "#E29393"}, {value: "Gekündigt", label: "Gekündigt", color: "#E29393"}, {value: "Beantragt", label: "Beantragt", color: "#f5b556"}, {value: "Abgeschlossen", label: "Abgeschlossen", color: "#76d658"}, {value: "Internes Review", label: "Internes Review"}];

    const notes = (selectedContracts && selectedContracts.length == 1) ? selectedContracts[0].polymorphic_notes : [];
    const concatinatedTasks = (selectedContracts && selectedContracts.length > 0) ? [].concat(... selectedContracts.map(contract => contract.tasks)).filter(Boolean) : [].concat(... this.state.trial.contracts.map(contract => contract.tasks)).filter(Boolean);
    // concatinatedTasks = concatinatedTasks.filter(Boolean);
    const supplementedTasks = concatinatedTasks.map(task => {
      // console.log(task);
      if (task) {
        let ret = {... task};
        ret["statusText"] = taskStatusList.find(tsl => task.status == tsl.value).label;
        let closestReminderDate = Moment('2100-01-01');
        task.reminders.forEach(reminder => {
          if (Moment(closestReminderDate).isAfter(Moment(reminder.remind_when))) {
            closestReminderDate = reminder.remind_when;
          }
        })
        console.log(closestReminderDate);
        if (!Moment(closestReminderDate).isSame('2100-01-01')) {
          ret["nextReminderDate"] = Moment(closestReminderDate);
        } else {
          ret["nextReminderDate"] = null;
        }
        return ret;
      }
    })
    // if (selectedTask) {
    //   console.log(selectedTask.taskable_type, selectedTask.status, selectedTask.taskable.status)
    // }
    const filteredSupplementedTasks = supplementedTasks.filter(task => {
      var ret = true;
      if ((this.state.taskFilters.status != null) && !this.state.taskFilters.status.map(stat => stat.value).includes(task.status)) {
        ret = false;
      }
      // if (selectedContracts && !(selectedContracts.map(contract => contract.tasks.map(tas => tas.id).flat()).includes(task.id))) {
      //   console.log(selectedContracts.map(contract => contract.tasks.map(tas => tas.id).flat()).includes(task.id), "test", task.id);
      //   ret = false;
      // }
      return ret;
    })

    const supplementedContracts = this.state.trial.contracts.map(contract => {
      if (contract) {
        let ret = {... contract};
        const statusObj = statusList.find(status => status.value == contract.status)
        console.log(statusObj);
        ret["statusText"] = statusObj.label
        if (statusObj.color) {
          ret["statusColor"] = statusObj.color
        }
        return ret;
      }
    })
    console.log(supplementedContracts);
    
    const selectedTasks = filteredSupplementedTasks.filter(task => selectedTaskIds.includes(task.id));
    let notesTextToCopy = "";
    if (notes) {
      notes.forEach(sn => notesTextToCopy += Moment(sn.entry_date).format('DD.MM.YYYY') + "\t" + sn.note + "\n");
    }
    return (
        <Grid container >
          <ActionCableConsumer
            channel={{channel: "ContractsChannel"}}
            onReceived={this.handleContractsReceived}
            onConnected={this.handleConnected}
          />
          <Can I="update" a="Trial">
          { (openDialogs.infoContract && selectedContracts.length == 1) &&
            <>
              <InfoContractDialog
                obj={selectedContracts[0]}
                open={openDialogs.infoContract}
                selectedContract={selectedContracts[0]}
                selectedTasks={selectedTasks}
                taskStatusList={taskStatusList}
                handleSave={this.handleInfoContractSave}
                handleClose={this.handleInfoContractClose}
                statusList={statusList}
                contractTypeList={contractTypeList}
                handleEditContractClick={this.handleEditContractOpen}
                handleDoneClick={this.handleMarkDoneClick}
                handleDuplicateClick={this.handleDuplicateTaskClick}
                handleEditTaskOpen={this.handleEditTaskOpen}
                handleDeleteTaskConfirmationDialogOpen={this.handleDeleteTaskConfirmationDialogOpen}
                enqueueSnackbar={this.props.enqueueSnackbar}
                onTaskSelect={this.handleTaskChange}
                onTaskDoubleClick={this.handleTaskDoubleClick}
              />
            </>
          }
          { (openDialogs.deleteTaskConfirmation && selectedTasks.length > 0) &&
            <DeleteTaskConfirmationDialog
              open={openDialogs.deleteTaskConfirmation}
              handleSave={this.handleDeleteTasks}
              handleClose={this.handleDeleteTaskConfirmationDialogClose}
              selectedTasks={selectedTasks}
            />}
          { (openDialogs.newContract) &&
            <NewContractDialog
              open={openDialogs.newContract}
              handleSave={this.handleNewContractSave}
              handleClose={this.handleNewContractClose}
              statusList={statusList}
              contractTypeList={contractTypeList}
            />
          }
          { (openDialogs.editContract && selectedContracts.length == 1) &&
            <EditContractDialog
              open={openDialogs.editContract}
              selectedContract={selectedContracts[0]}
              handleSave={this.handleEditContractSave}
              handleClose={this.handleEditContractClose}
              statusList={statusList}
              contractTypeList={contractTypeList}
            />
          }
          { (openDialogs.editNote && selectedNotes.length == 1) &&
            <EditNoteDialog
              open={openDialogs.editNote}
              selectedNote={selectedNotes[0]}
              handleSave={this.handleEditNoteSave}
              handleClose={this.handleEditNoteClose}
            />
          }
          { (openDialogs.newNote) &&
            <NewNoteDialog
              open={openDialogs.newNote}
              handleSave={this.handleNewNoteSave}
              handleClose={this.handleNewNoteClose}
            />
          }
          </Can>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" name="position" row>
                {/* <FormControlLabel
                  value={this.state.multipleSelectMode}
                  control={<Switch color="primary" />}
                  label="Mehrfachauswahl-Modus"
                  labelPlacement="end"
                  onChange={this.handleMultipleSelectChange}
                /> */}
                <Tooltip title="Refresh">
                  <IconButton aria-label="Info" onClick={this.handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <Grid container justify="center">
              {/* <Paper> */}
                <EnhancedTable
                  title={"Verträge etc."}
                  data={supplementedContracts}
                  columns={contractColumns}
                  // singleSelect={!this.state.multipleSelectMode}
                  multipleSelectable
                  selectedIds={selectedContractIds}
                  selectedObjects={selectedContracts}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleContractChange(rowData, allRowsSelected, allObjectsSelected)}
                  handleAddClick={this.handleNewContractOpen}
                  // handleInfoClick={this.handleInfoSiteClick}
                  // handleEditClick={this.handleEditSiteClick}
                  // handleRemoveClick={this.handleRemoveSiteClick}
                  handleDeleteClick={this.handleDeleteContract}
                  // handleFilterClick={this.handleSitesFilterClick}
                  // filters={this.state.siteFilters}
                  rowsPerPage={50}
                  orderBy="id"
                  order="asc"
                  onDoubleClick={this.handleContractDoubleClick}
                  // markedIds={this.state.selectedDepartmentsSitesIds}
                  // onFilterChange={this.handleSitesFilterChange}
                  allData={supplementedContracts}
                  selectable
                  expanded={this.state.expandedTable["Verträge etc."]}
                  handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                />
              {/* </Paper> */}
            </Grid>
          </Grid>
          { (this.state.selectedContracts && this.state.selectedContracts.length == 1) &&
          <Grid item xs={6} >
            <Grid container justify="center">
              {/* <Paper> */}
                <EnhancedTable
                  title={"Notizen"}
                  data={(notes) ? notes : []}
                  columns={noteColumns}
                  // singleSelect={!this.state.multipleSelectMode}
                  multipleSelectable
                  selectedIds={selectedNoteIds}
                  selectedObjects={selectedNotes}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleNoteChange(rowData, allRowsSelected, allObjectsSelected)}
                  handleAddClick={this.handleNewNoteOpen}
                  // handleInfoClick={this.handleInfoSiteClick}
                  // handleEditClick={this.handleEditSiteClick}
                  // handleRemoveClick={this.handleRemoveSiteClick}
                  handleDeleteClick={this.handleDeleteNote}
                  // handleFilterClick={this.handleSitesFilterClick}
                  // filters={this.state.siteFilters}
                  rowsPerPage={50}
                  orderBy="entry_date"
                  order="desc"
                  onDoubleClick={this.handleNoteDoubleClick}
                  // markedIds={this.state.selectedDepartmentsSitesIds}
                  // onFilterChange={this.handleSitesFilterChange}
                  allData={notes}
                  selectable
                  expanded={this.state.expandedTable["Notizen"]}
                  handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                  textToCopy={notesTextToCopy}
                />
              {/* </Paper> */}
            </Grid>
          </Grid>}
          <Grid item xs={12} >
            <Typography style={{color: 'red', fontSize: '18px'}}>
              Hinweis: Zu Verträgen können Notizen geschrieben werden. Notizen werden erst bei Auswahl eines Vertrags eingeblendet.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="center">
              { (openDialogs.newTask && selectedContracts.length == 1) &&
              <NewTaskDialog
                open={openDialogs.newTask}
                enqueueSnackbar={this.props.enqueueSnackbar}
                // handleAddOpen={this.handleEditProcessStepOpen}
                // handleSave={this.handleEditProcessStepSave}
                handleClose={this.handleNewTaskClose}
                handleCloseOpenInfo={this.handleNewTaskClose}
                // selectedProcessStep={selectedProcessStep}
                // previousProcessStep={previousProcessStep}
                // stepStatusList={stepStatusList}
                taskStatusList={taskStatusList}
                taskableType="Contract"
                taskableId={selectedContracts[0].id}
                // selectableTaskStatusList={selectableTaskStatusList}
                // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              />}
              { (openDialogs.infoTask && selectedTasks.length == 1) &&
              <InfoTaskDialog
                obj={selectedTasks[0]}
                open={openDialogs.infoTask}
                enqueueSnackbar={this.props.enqueueSnackbar}
                // handleAddOpen={this.handleEditProcessStepOpen}
                // handleSave={this.handleEditProcessStepSave}
                handleClose={this.handleInfoTaskClose}
                // selectedProcessStep={selectedProcessStep}
                // previousProcessStep={previousProcessStep}
                // stepStatusList={stepStatusList}
                taskStatusList={taskStatusList}
                // selectableTaskStatusList={selectableTaskStatusList}
                // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              />}
              { (openDialogs.editTask && selectedTasks.length == 1) &&
              <EditTaskDialog
                obj={selectedTasks[0]}
                open={openDialogs.editTask}
                enqueueSnackbar={this.props.enqueueSnackbar}
                // handleAddOpen={this.handleEditProcessStepOpen}
                // handleSave={this.handleEditProcessStepSave}
                handleClose={this.handleEditTaskClose}
                // selectedProcessStep={selectedProcessStep}
                // previousProcessStep={previousProcessStep}
                // stepStatusList={stepStatusList}
                taskStatusList={taskStatusList}
                // selectableTaskStatusList={selectableTaskStatusList}
                // statusEditable={((!nextMainProcessStep) || (nextMainProcessStep.status == "active") || selectedProcessStep.status == "active")}
              />}
              <EnhancedTable
                title={"Merkzettel (Verträge etc.)"}
                objectName={"Task"}
                data={filteredSupplementedTasks}
                allData={supplementedTasks}
                columns={taskColumns}
                rowsPerPage={10}
                order="asc"
                orderBy="id"
                controller="tasks"
                autoFill
                expanded={this.state.expandedTable["Merkzettel (Verträge etc.)"]}
                handleExpandChange={this.handleExpandChange}
                selectable
                // singleSelect={true}
                multipleSelectable
                selectedIds={selectedTasks.map(st => st.id)}
                selectedObjects={selectedTasks}
                onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleTaskChange(rowData, allRowsSelected, allObjectsSelected)}
                filters={this.state.taskFilters}
                handleFilterClick={this.handleTasksFilterClick}
                onFilterChange={this.handleTasksFilterChange}
                handleAddClick={handleNewTaskOpen}
                handleDuplicateClick={(selectedTasks.length == 1 && selectedTasks[0].taskable_type != "ProcessStep") ? this.handleDuplicateTaskClick : null}
                handleDoneClick={(selectedTasks.length == 1 && ((selectedTasks[0].taskable_type != "ProcessStep") && (selectedTasks[0].status != "done") || (selectedTasks[0].taskable_type == "ProcessStep" && (selectedTasks[0].taskable.status == "active" )))) ? this.handleMarkDoneClick : null}
                // (selectedTask.taskable.status == "active" || selectedTask.status == "open")
                handleDeleteClick={(selectedTasks.length > 0) ? this.handleDeleteTaskConfirmationDialogOpen : null}
                onDoubleClick={this.handleTaskDoubleClick}
                // handleLinkClick={this.handleTaskLinkClick}
                superType="Trial"

              />
            </Grid>
          </Grid>
        </Grid>
    )
  }
}

Contracts.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(Contracts));
