import React from 'react';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import { makeStyles, createStyles } from '@mui/styles';
// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import AddCircle from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ListIcon from "@mui/icons-material/List";
import Grid from '@mui/material/Grid';
// import EditIcon from '@mui/icons-material/Edit';

import Milestones from './Milestones.jsx';
import MilestonesListDialog from './MilestonesListDialog.jsx';

const styles = theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
    MuiIconButton: {
      root: {
        padding: '2',
      },
    },
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.primary.main,
    align: "center",
    backGroundColor: "#007bff",
  },
  title: {
    display: "flex",
    // flex: '0 1 auto',
    // flex: 1,
    flexBasis: '100%',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 1,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    backGroundColor: "#007bff",
    // alignItems: 'flex-start',
  },
});

class MilestonesPaper extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {unlocked: false}
    this.state = {editOpen: false}
    this.form = React.createRef();
  }

  handleEditOpen = event => {
    this.setState({editOpen: true});
  }

  handleEditClose = event => {
    this.setState({editOpen: false});
  }

  render(props) {
    const { classes, unlocked } = this.props;
    // const classes = styles(this.props.theme);
    // console.log(classes);
    // const locklabel = {
    //   if (unlocked) {
    //     return (
    //       <LockOpen />
    //     )
    //   } else {
    //     return (
    //       <Lock />
    //     )
    //   }
    // }
    return (
      <>
      { (this.state.editOpen) &&
      <MilestonesListDialog
        open={this.state.editOpen}
        trial={this.props.trial}
        handleClose={this.handleEditClose}
        handleNewSave={this.props.handleNewSave}
        handleEditSave={this.props.handleEditSave}
        milestones={this.props.customMilestones}
        handleDelete={this.props.handleDelete}
      />}
      <Paper className={classes.root} style={{borderStyle: "solid", borderWidth: "1px"}}>
        <Grid container >
          <Grid item xs={12} >
            <Grid container justify="center" >
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Toolbar
                    className={classNames(classes.root)}
                    variant='dense'
                  >
                    <div className={classes.title}>
                      <Typography variant="h8" id="milestonesTitle" component="div" >
                        {(this.props.selectedSites.length == 1) ? "Milestones: " + this.props.selectedSites[0].inst_description : "Ein Zentrum auswählen"}
                      </Typography>
                      <div className={classes.actions}>
                        {(this.props.unlocked) &&
                          <Tooltip title="Editieren">
                            <IconButton aria-label="Edit" onClick={this.handleEditOpen}>
                              <ListIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.props.unlocked}
                              onChange={this.props.handleLockChange}
                              color="primary"
                            />
                          }
                          label= {this.props.unlocked ? <LockOpen /> : <Lock /> }
                          style={{marginRight: 0, alignSelf: 'flex-end'}}
                        />
                      </div>
                    </div>
                  </Toolbar>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="left">
                  <Milestones
                    selectedSites={this.props.selectedSites}
                    sites={this.props.sites}
                    handleChange={this.props.handleChange}
                    unlocked={this.props.unlocked}
                    customMilestones={this.props.customMilestones}
                    milestones={this.props.milestones}
                    standardMilestones={this.props.standardMilestones}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      </>
    )
  }
}

MilestonesPaper.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedSite: PropTypes.isRequired,
};

export default withStyles(styles)(withTheme(MilestonesPaper)) ;
